import { Pagination } from '@interfaces';
import { Purchase } from './purchase';
import { User } from './user-auth';

export enum DISCOUNT_CODE_TYPE {
  'PERCENTAGE' = 'PERCENTAGE',
  'AMOUNT' = 'AMOUNT',
}

export interface DiscountCode {
  id: string;
  isActive: boolean;
  code: string;
  hasDateConstraints: boolean;
  startDate: Date;
  endDate: Date;
  hasUsageConstraints: boolean;
  usageLimit: number;
  discountType: DISCOUNT_CODE_TYPE;
  discountAmount: number; // always in GROSZ PLN
  discountPercentage: number; // 0 - 99
  usedInPurchases: Purchase[]; // Those are unconfirmed usages -> should not be used
  usedInPurchasesConfirmed: Purchase[]; // Those are usages confirmed by transaction verification
  createdByUser: User;
  createdByUserId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateDiscountCodeOpts {
  isActive: boolean;
  code: string;
  hasDateConstraints: boolean;
  startDate?: Date;
  endDate?: Date;
  hasUsageConstraints: boolean;
  usageLimit?: number;
  discountType: DISCOUNT_CODE_TYPE;
  discountAmount?: number;
  discountPercentage?: number;
}

export interface EditDiscountCodeOpts {
  id: string;
  isActive: boolean;
  hasDateConstraints: boolean;
  hasUsageConstraints: boolean;
  startDate?: string;
  endDate?: string;
  usageLimit?: number;
}

export interface ValidatedDiscountCode {
  id: string;
  discountType: DISCOUNT_CODE_TYPE;
  discountAmount: number;
  discountPercentage: string;
}

export interface SearchDiscountCodesOpts extends Pagination {
  isActive?: boolean;
  query?: string;
  type?: DISCOUNT_CODE_TYPE;
}
