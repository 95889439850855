import { DiscountCode, Pagination } from '@interfaces';

export type DiscountCodesState = Readonly<{
  loading: boolean;
  discountCodes: DiscountCode[];
  discountCodesPagination: Pagination;

  discountCodeDetails: DiscountCode;
  checkIfCodeExistsInProgress: boolean;
}>;

export const defaultPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'createdAt',
  orderDir: 'desc',
};

export const initialState: DiscountCodesState = {
  loading: false,
  discountCodes: [],
  discountCodesPagination: { ...(defaultPagination as Pagination) },
  discountCodeDetails: null,
  checkIfCodeExistsInProgress: false,
};
