import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'customdate',
})
export class CustomDatePipe implements PipeTransform {
  public transform(value: Date | string | moment.Moment, format: string): any {
    if (moment.isMoment(value)) {
      return value.format(format);
    }

    const asMoment = moment.tz(value, 'Europe/Warsaw');
    if (!asMoment.isValid()) {
      return `Unable to format ${value} to moment date.`;
    }

    return asMoment.format(format);
  }
}
