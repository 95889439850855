import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { ManagementFacade } from '@state/management';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ForeignerDetailsGuard implements CanActivate {
  constructor(
    private router: Router,
    private managementFacade: ManagementFacade,
    private notifService: SnackbarService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const exitUrlTree = this.router.createUrlTree(['/']);
    const userId = route.params.userId;
    if (userId === 'new') {
      return of(true);
    }

    return this.managementFacade.getUser$(userId).pipe(
      take(1),
      map(user => {
        if (user?.id !== userId) {
          this.notifService.showError('USER_DOES_NOT_EXIST');
          return exitUrlTree;
        }
        return true;
      })
    );
  }
}
