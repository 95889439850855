import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { GetCompanyPayload } from '@interfaces';
import { CompanyFacade } from '@state/company';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable()
export class AllCompaniesGuard implements CanActivate {
  constructor(
    private router: Router,
    private companyFacade: CompanyFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const opts: GetCompanyPayload = {
      // @TODO: We should be able to load more than 500 companies
      // This logic is used often to load companies displayed in dropdown list for cases like:
      // --- Assigning foreigner to some company -> We should be able to see all companies in dropdown list
      // --- Filtering list of foreigners by company -> We should be able to see all companies in dropdown list
      // @TODO: Not loading all companies at once but loading only some chunk and then using filtering by name (search)
      // ---
      limit: 500,
      offset: 0,
      orderBy: 'name',
      orderDir: 'asc',
      isActive: true,
    };
    this.companyFacade.getCompanies(opts);
    return this.companyFacade.pagination$.pipe(
      filter(pagination => {
        if (!pagination || pagination.limit !== opts.limit) {
          return false;
        }
        return true;
      }),
      take(1),
      map(() => true)
    );
  }
}
