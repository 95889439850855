import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { GetAdminsPayload, GetOnlineUsersPayload, PaginatedResponse, SystemSettings, User } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class SystemSettingsHttpService {
  private API_URL = environment.API_URL;
  private defaultOpts = { withCredentials: true };

  constructor(private http: HttpClient) {}

  public getSystemSettings(): Observable<SystemSettings> {
    const url = `${this.API_URL}/system-settings`;
    return this.http.get<SystemSettings>(url);
  }

  public changePIN(newPin: string): Observable<SystemSettings> {
    const url = `${this.API_URL}/system-settings/pin`;
    const body = { pin: newPin };
    return this.http.post<SystemSettings>(url, body);
  }

  public changeMainNotificationRecipient(newEmail: string): Observable<SystemSettings> {
    const url = `${this.API_URL}/system-settings/main-notifications-recipient`;
    const body = { email: newEmail };
    return this.http.post<SystemSettings>(url, body);
  }

  public changeAdditionalRecipients(recipientsEmails: string[]): Observable<SystemSettings> {
    const url = `${this.API_URL}/system-settings/additional-notifications-recipients`;
    const body = { emails: recipientsEmails };
    return this.http.post<SystemSettings>(url, body);
  }

  public changeSecurityRecipients(recipientsEmails: string[]): Observable<SystemSettings> {
    const url = `${this.API_URL}/system-settings/security-recipients`;
    const body = { emails: recipientsEmails };
    return this.http.post<SystemSettings>(url, body);
  }

  public getOnlineUsers(payload: GetOnlineUsersPayload): Observable<PaginatedResponse<User>> {
    const url = `${this.API_URL}/system-settings/search-online-users`;
    return this.http.post<PaginatedResponse<User>>(url, payload);
  }

  public getAdmins(payload: GetAdminsPayload): Observable<PaginatedResponse<User>> {
    const url = `${this.API_URL}/users/search/admins`;
    return this.http.post<PaginatedResponse<User>>(url, payload);
  }

  public getAdmin(adminId: string): Observable<User> {
    const url = `${this.API_URL}/users/${adminId}`;
    return this.http.get<User>(url);
  }

  public createAdmin(payload: Partial<User>): Observable<{ user: User; emailSent: boolean }> {
    const url = `${this.API_URL}/users/admin`;
    return this.http.post<{ user: User; emailSent: boolean }>(url, payload);
  }

  public updateAdmin(payload: Partial<User>, adminId: string): Observable<User> {
    const url = `${this.API_URL}/users/${adminId}`;
    return this.http.patch<User>(url, payload);
  }

  public removeAdmin(adminId: string): Observable<User> {
    const url = `${this.API_URL}/users/admin/${adminId}`;
    return this.http.delete<User>(url);
  }

  public resendAdminActivation(email: string): Observable<{ emailSent: boolean }> {
    const url = `${this.API_URL}/users/admin/resend-activation`;
    return this.http.post<{ emailSent: boolean }>(url, { email });
  }

  public recreateAvatarsForAllUsers(): Observable<{ success: boolean }> {
    const url = `${this.API_URL}/user-assets/GLOBAL-AVATARS/recreate-all`;
    return this.http.get<{ success: boolean }>(url);
  }

  public setSelfRegistrationStatus(isActive: boolean): Observable<SystemSettings> {
    const url = `${this.API_URL}/system-settings/self-registration-status`;
    return this.http.post<SystemSettings>(url, { isActive });
  }

  public getSelfRegistrationStatus(): Observable<boolean> {
    const url = `${this.API_URL}/system-settings/self-registration-status`;
    return this.http.get<boolean>(url);
  }
}
