import Step from 'node_modules/shepherd.js/src/types/step';

import { TranslateService } from '@ngx-translate/core';

interface AvailableButtons {
  counter: Step.StepOptionsButton;
  next: Step.StepOptionsButton;
  back: Step.StepOptionsButton;
  cancel: Step.StepOptionsButton;
  done: Step.StepOptionsButton;
}

const gPrefix = 'NT.GUIDE';
const prefix = `${gPrefix}.FIELD-CONSULTANT-TABLE`;

export const getSteps = (t: TranslateService, currLang: string): Step.StepOptions[] => {
  const buttons: AvailableButtons = {
    counter: { text: '', action(): void {}, classes: 'counter-button' },
    next: {
      text: t.instant(`${gPrefix}.NEXT`),
      action(): void {
        this.next();
      },
      classes: 'standard-next-button',
    },
    back: {
      text: t.instant(`${gPrefix}.BACK`),
      action(): void {
        this.back();
      },
      classes: 'standard-back-button',
    },
    cancel: {
      text: t.instant(`${gPrefix}.CLOSE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-cancel-button',
    },
    done: {
      text: t.instant(`${gPrefix}.DONE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-done-button',
    },
  };

  const steps: Step.StepOptions[] = [
    {
      id: 'welcomeinthesystem',
      arrow: false,
      modalOverlayOpeningPadding: 40,
      modalOverlayOpeningRadius: 8,
      // attachTo: { element: '.noPreviousVisits-field label', on: 'right' },
      // cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.welcomeinthesystem.TITLE`),
      text: `${t.instant(`${prefix}.welcomeinthesystem.TEXT`)}`,
    },
    {
      id: 'tablewithvisitslist',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.visits-list-table', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.tablewithvisitslist.TITLE`),
      text: `${t.instant(`${prefix}.tablewithvisitslist.TEXT`)}`,
    },
    {
      id: 'datesperiodfilter',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.date-range-wrapper', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.datesperiodfilter.TITLE`),
      text: `${t.instant(`${prefix}.datesperiodfilter.TEXT`)}`,
    },
    {
      id: 'assistantfilter',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.assigned-to-me-filter', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.assistantfilter.TITLE`),
      text: `${t.instant(`${prefix}.assistantfilter.TEXT`)}`,
    },
    {
      id: 'sortingtable',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.mat-header-row', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.sortingtable.TITLE`),
      text: `${t.instant(`${prefix}.sortingtable.TEXT`)}`,
    },
    {
      id: 'showdetails',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.mat-row', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.showdetails.TITLE`),
      text: `${t.instant(`${prefix}.showdetails.TEXT`)}`,
    },
    {
      id: 'gotocalendar',
      arrow: true,
      modalOverlayOpeningPadding: 0,
      modalOverlayOpeningRadius: 40,
      attachTo: { element: '.field-consultant-calendar', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.gotocalendar.TITLE`),
      text: `${t.instant(`${prefix}.gotocalendar.TEXT`)}`,
    },
    {
      id: 'restartinfo',
      arrow: true,
      modalOverlayOpeningPadding: 3,
      modalOverlayOpeningRadius: 5,
      attachTo: { element: '.open-guide-button', on: 'left' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.restartinfo.TITLE`),
      text: `${t.instant(`${prefix}.restartinfo.TEXT`)}`,
    },
    {
      id: 'thatsall',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      cancelIcon: { enabled: false },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.thatsall.TITLE`),
      text: `${t.instant(`${prefix}.thatsall.TEXT`)}`,
    },
  ];

  const guideName = t.instant(`${prefix}.GUIDE_NAME`);
  return steps.map((step, currIndex, all) => {
    const formattedButtons: Step.StepOptionsButton[] = [
      { ...buttons.counter, text: `${guideName} ${currIndex + 1}/${all.length}` },
    ];

    if (currIndex === 0) {
      formattedButtons.push({ ...buttons.cancel });
    } else {
      formattedButtons.push({ ...buttons.back });
    }

    if (currIndex < all.length - 1) {
      formattedButtons.push({ ...buttons.next });
    } else {
      formattedButtons.push({ ...buttons.done });
    }

    return { ...step, buttons: formattedButtons };
  });
};
