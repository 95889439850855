import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ALLOWED_FILES_IN_CHAT, AvailableLanguages, FILE_SIZE_LIMIT, patterns } from '@constants';
import { environment } from '@environment';
import { AllDeviceInfo, CreateMessageInMyChat, InAppNotificationType, User, UserAsset } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@shared/analytics';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { RemoveTagsService } from '@shared/remove-tags/remove-tags.service';
import { ChatFacade } from '@state/chat';
import { InAppNotificationsFacade } from '@state/notifications';
import { UserAuthFacade } from '@state/user-auth';
import { isString } from 'lodash-es';
import { NzMessageService } from 'ng-zorro-antd/message';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chat-mobile',
  templateUrl: './chat-mobile.component.html',
  styleUrls: ['./chat-mobile.component.scss'],
})
export class ChatMobileComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('chatMessageInput') chatMessageInput: ElementRef;
  @ViewChild('chatMessagesContainer') chatMessagesContainer: ElementRef;
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef<HTMLElement>;

  public myself: User;

  public showLoader = true;

  public currentLang: AvailableLanguages;

  public loading$ = this.chatFacade.loading$;
  public chatMessages$ = this.chatFacade.chatMessages$;

  public deviceInfo: AllDeviceInfo;

  public attachmentsToAdd$ = this.chatFacade.attachmentsToAdd$.pipe(
    filter(items => !!items),
    map(items => {
      const container = document.getElementsByClassName('chat-inner-container')[0];
      if (!Object.values(items)?.length) {
        if (container?.classList.contains('attachments')) {
          container.classList.remove('attachments');
        }
        return null;
      }

      container.classList.add('attachments');

      return Object.values(items).map(({ error, loading, uploaded, userAsset }) => {
        return {
          fileOriginalName: userAsset?.fileOriginalName || 'MISSING-DATA',
          error,
          loading,
          uploaded,
          userAsset,
        };
      });
    })
  );

  public newMessageContent = '';
  public messageAttachments: string[] = [];

  public API_URL = environment.API_URL;

  constructor(
    private readonly userAuthFacade: UserAuthFacade,
    private readonly translateService: TranslateService,
    private readonly nzMessage: NzMessageService,
    private readonly inAppNotification: InAppNotificationsFacade,
    private readonly chatFacade: ChatFacade,
    private readonly removeTagsService: RemoveTagsService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly location: Location,
    private readonly analyticsService: AnalyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.currentLang = this.translateService.currentLang as AvailableLanguages;

    this.userAuthFacade.myself$
      .pipe(
        filter(myself => !!myself?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(async myself => {
        this.myself = myself;
      });

    this.userAuthFacade.myself$
      .pipe(
        filter(myself => !!myself?.id),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.chatFacade.getMyChat();
      });

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
    });

    this.toggleChatButtonVisibility(false);
    this.analyticsService.trackEvent('user_event', 'user_opened_chat');
  }

  async ngAfterViewInit(): Promise<void> {
    this.chatFacade.addChatMessageSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.newMessageContent = '';
      setTimeout(() => this.scrollToBottom(), 100);
    });

    this.attachmentsToAdd$.pipe(takeUntil(this.destroy$)).subscribe(attachments => {
      if (!attachments?.length) {
        this.messageAttachments = [];
        return;
      }

      this.messageAttachments = attachments
        .filter(attachment => attachment.uploaded)
        .map(attachment => attachment.userAsset.id);
    });

    this.chatFacade.getMyChatSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      setTimeout(() => this.scrollToBottom());
    });

    this.inAppNotification.newNotificationReceived$.pipe(takeUntil(this.destroy$)).subscribe(({ notification }) => {
      // interested only in this type
      if (InAppNotificationType.EMPLOYEE_SENT_MESSAGE_IN_CHAT !== notification.type) {
        return;
      }

      this.chatFacade.getMyChat();
    });

    setTimeout(() => (this.showLoader = false));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();

    this.toggleChatButtonVisibility(true);
  }

  public async uploadFiles($event: any): Promise<void> {
    const files = $event.target.files as FileList;
    const validatedFiles = [];
    const invalidFiles = [];
    const MAX_NUMBER_OF_FILES = 10;

    const existingItems = await lastValueFrom(this.attachmentsToAdd$.pipe(take(1), takeUntil(this.destroy$)));

    if (files.length > MAX_NUMBER_OF_FILES) {
      this.nzMessage.warning(this.translateService.instant('NT.YOU_CAN_UPLOAD_MAX_10_AT_A_TIME'), { nzDuration: 4500 });
    }

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      // If user managed to upload some wierd file
      // Let him - we don't care that app is not working now - his fault.
      if (!file?.size || !file?.type) {
        this.nzMessage.error(this.translateService.instant('NT3.CANNOT_READ_FILE_CORRECTLY', { fileName: file.name }), {
          nzDuration: 6500,
          nzPauseOnHover: true,
        });
        invalidFiles.push(file);
        continue;
      }

      if (file.size > FILE_SIZE_LIMIT) {
        this.nzMessage.error(
          this.translateService.instant('NT3.FILE_NOT_ADDED_BECAUSE_TOO_BIG', { fileName: file.name }),
          { nzDuration: 6500, nzPauseOnHover: true }
        );
        invalidFiles.push(file);
        continue;
      }

      if (!ALLOWED_FILES_IN_CHAT.includes(file.type.toLowerCase())) {
        this.nzMessage.error(
          this.translateService.instant('NT3.FILE_NOT_ADDED_BECAUSE_INVALID', { fileName: file.name }),
          { nzDuration: 6500, nzPauseOnHover: true }
        );
        invalidFiles.push(file);
        continue;
      }

      if (existingItems?.length) {
        const fileWithSuchNameUploaded = existingItems.find(
          ({ fileOriginalName, uploaded }) => uploaded && fileOriginalName === file.name
        );
        if (fileWithSuchNameUploaded) {
          continue;
        }
      }
      validatedFiles.push(file);

      // max 10 at a time
      if (validatedFiles.length === 10) {
        break;
      }
    }

    if (invalidFiles.length) {
      this.nzMessage.error(this.translateService.instant('NT3.SOME_OF_THE_FILES_NOT_ADDED'), {
        nzDuration: 8500,
        nzPauseOnHover: true,
      });
    }

    (this.fileUploadInput.nativeElement as any).value = '';
    if (!validatedFiles.length) {
      return;
    }

    validatedFiles.forEach(validatedFile => {
      this.chatFacade.uploadMessageAttachments({
        files: [{ file: validatedFile, fileOriginalName: validatedFile.name }],
      });
    });
  }

  public downloadMessageAttachment(messageId: string, attachmentId: string): void {
    this.chatFacade.downloadAttachmentFromChat(messageId, attachmentId);
  }

  public removeAsset(userAsset: UserAsset): void {
    this.chatFacade.removeAttachmentFromUploading({
      fileOriginalName: userAsset.fileOriginalName,
      userAssetId: userAsset.id,
    });
  }

  public submitMessage($event: any): void {
    let content = this.newMessageContent;

    if ($event.shiftKey) {
      return;
    }

    if (content?.length) {
      content = content.trim();
      content = this.removeTagsService.removeTags(content, ['h1', 'h2', 'h3', 'br', 'b', 'i', 'li', 'ul', 'ol']);
      if (content.length === 0 && this.messageAttachments.length === 0) {
        this.nzMessage.error(this.translateService.instant('NT3.WRONG_INPUT'), {
          nzDuration: 3000,
          nzPauseOnHover: true,
        });
      }
    }
    if (this.messageAttachments.length === 0 && (!content || !this.messageContentValid(this.newMessageContent))) {
      return;
    }

    const opts: CreateMessageInMyChat = {
      content,
      attachments: [...this.messageAttachments],
    };

    this.chatFacade.addChatMessage({ ...opts });
    this.analyticsService.trackEvent('user_event', 'user_sent_chat_message');
  }

  public scrollToBottom(behavior?: ScrollBehavior): void {
    const htmlElem = this.chatMessagesContainer?.nativeElement as HTMLElement;
    if (!htmlElem) {
      return;
    }

    const lastChatMessage = htmlElem.querySelector('.chat-message-wrapper:last-child');
    if (lastChatMessage) {
      lastChatMessage.scrollIntoView({ behavior: behavior || 'smooth', block: 'end' });
    }

    this.inAppNotification.markAllOfTypeAsRead({
      inAppNotificationType: InAppNotificationType.EMPLOYEE_SENT_MESSAGE_IN_CHAT,
      relatedForeignerId: this.myself.id,
    });
  }

  public messageContentValid(content: string): boolean {
    const pattern = patterns.CommentTextValidator;

    if (!isString(content)) {
      return false;
    }
    return !!(content as string).match(pattern);
  }

  public toggleChatButtonVisibility(status: boolean): void {
    const chatWrappers = document.getElementsByClassName('chat-button-wrapper');
    if (chatWrappers?.length !== 1) {
      return;
    }
    const chatWrapper = chatWrappers[0] as HTMLElement;
    if (status) {
      chatWrapper.style.display = 'inline';
    } else {
      chatWrapper.style.display = 'none';
    }
  }

  public goBack(): void {
    this.location.back();
  }
}
