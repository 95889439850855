import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Company,
  GetCompanyPayload,
  GetVisitsListOpts,
  PaginatedResponse,
  SearchOfficeVisitsOpts,
  UserProcess,
} from '@interfaces';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class VisitsCalendarHttpService {
  private API_URL = environment.API_URL;
  private defaultOpts = { withCredentials: true };

  constructor(private http: HttpClient) {}

  public searchVisits(payload: SearchOfficeVisitsOpts): Observable<UserProcess[]> {
    const url = `${this.API_URL}/office-visits-calendar/search-visits`;
    return this.http.post<UserProcess[]>(url, payload);
  }

  public getVisitsListBetweenDates(opts: GetVisitsListOpts): Observable<PaginatedResponse<Partial<UserProcess>>> {
    const url = `${this.API_URL}/office-visits-calendar/get-visits-list`;
    const { all, count, ...payload } = opts;
    return this.http.post<PaginatedResponse<Partial<UserProcess>>>(url, payload);
  }
}
