import { User, UserAsset } from '@interfaces';

export interface PreSurveyAttachment {
  id: string;
  preSurveyId: string;
  attachmentId: string;
  attachment: UserAsset;
}

export enum YES_NO {
  YES = 'YES',
  NO = 'NO',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export interface StayInOtherCountry {
  legalBasis: LEGAL_BASIS_OF_STAY_IN_OTHER_COUNTRY;
  country: string;
  entryDate: string;
  leaveDate: string;
}

export interface FinishedStudies {
  country: string;
  finishDate: string;
}

export enum LEGAL_BASIS_OF_STAY_IN_OTHER_COUNTRY {
  'TEMP_PERMIT' = 'TEMP_PERMIT',
  'PERM_STAY' = 'PERM_STAY',
  'LONG_RESI' = 'LONG_RESI',
  'BLUE_CARD' = 'BLUE_CARD',
}

export enum KINSHIP_DEGREE {
  WIFE = 'WIFE',
  HUSBAND = 'HUSBAND',
  DAUGHTER = 'DAUGHTER',
  SON = 'SON',
  CHILD = 'CHILD',
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  SIBLINGS = 'SIBLINGS',
}

export enum PERMITS {
  POLES_CARD = 'POLES_CARD',
  PARENT_PL_ORIGIN = 'PARENT_PL_ORIGIN',
  GRANDPARENT_PL_ORIGIN = 'GRANDPARENT_PL_ORIGIN',
  GREAT_GRANDPARENT_PL_ORIGIN = 'GREAT_GRANDPARENT_PL_ORIGIN',
}

export interface PreSurvey {
  id: string;
  currentlyInPoland: YES_NO | null;
  currentlyInPolandMonth: number | null;
  currentlyInPolandYear: number | null;
  polandLastEntryDate: string;
  residenceCityInPoland: string;
  currentlyInPolandComment: string | null;

  workingInPoland: YES_NO | null;
  workingInPolandMonth: number | null;
  workingInPolandYear: number | null;
  workingInPolandComment: string | null;

  hasExperienceOrEducation: YES_NO | null;
  hasExperienceOrEducationComment: string | null;

  studyingInPoland: YES_NO | null;
  studyingInPolandComment: string | null;

  hasFinishedStudies: YES_NO | null;
  finishedStudies: FinishedStudies[];

  hasFamilyForeignCitizenship: YES_NO | null;
  hasFamilyForeignCitizenshipComment: string | null;

  hasFamilyPolishCitizenship: YES_NO | null;
  familyPolishCitizenshipKinships: KINSHIP_DEGREE[];
  hasFamilyPolishCitizenshipComment: string | null;

  dependantOnFamily: YES_NO | null;
  dependantOnFamilyComment: string | null;

  hasStaysInOtherCountries: YES_NO | null;
  staysInOtherCountries: StayInOtherCountry[];

  hasCurrentCardOrFamily: YES_NO | null;
  currentCardOrFamily: string | null;

  hasNAWACertificate: YES_NO | null;

  hasProceedingsForTempPermit: YES_NO | null;
  hasProceedingsForTempPermitComment: string | null;

  hasPurposeOfStay: YES_NO | null;
  purposeOfStay: string | null;

  comments: string;

  attachedDocuments: PreSurveyAttachment[] | null;
  user?: Partial<User>;
  userId: string;

  verifiedAt?: Date;
  verifiedBy?: Partial<User> | null;
  verifiedById?: string | null;

  updatedBy?: Partial<User> | null;
  updatedById?: string | null;

  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date | null;
}

export interface UpdatePreSurveyOpts extends Partial<PreSurvey> {
  preSurveyId: string;
  userProcessId: string;
}

export interface UploadPreSurveyAttachmentOpts {
  preSurveyId: string;
  userProcessId: string;
  files: { file: File; fileOriginalName: string }[];
}
