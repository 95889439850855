import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { random } from 'lodash-es';

@Component({
  selector: 'app-refresh-token-modal',
  templateUrl: './refresh-token-modal.component.html',
  styleUrls: ['./refresh-token-modal.component.scss'],
})
export class RefreshTokenModalComponent implements OnInit, OnDestroy {
  public showUnlocked = false;
  public progressStep = 1;

  public interval: any;
  constructor(public dialogRef: MatDialogRef<RefreshTokenModalComponent>) {}

  ngOnInit(): void {
    this.progressStep = 1;
    let min = 1;
    let max = 10;
    this.interval = window.setInterval(() => {
      if (this.progressStep >= 80) {
        min = 1;
        max = 7;
      }

      const nextVal = this.progressStep + random(min, max);
      this.progressStep = nextVal > 99 ? 99 : nextVal;

      if (this.progressStep >= 99) {
        window.clearInterval(this.interval);
        // this.dialogRef.close();
        return;
      }
    }, 100);
  }

  ngOnDestroy(): void {
    window.clearInterval(this.interval);
  }
}
