import { createAction, props } from '@ngrx/store';
import { Office, SearchOfficeOpts, PaginatedResponse, CreateOfficeOpts, ToggleAssignedVoivodeship } from '@interfaces';

export const searchOffices = createAction('[Office] Search Offices', props<{ opts: SearchOfficeOpts }>());
export const searchOfficesSuccess = createAction(
  '[Office] Search Offices Successs',
  props<{ offices: PaginatedResponse<Office> }>()
);
export const searchOfficesError = createAction('[Office] Search Offices Error', props<{ errorMessage: any }>());

export const getOfficesSimpleList = createAction('[Office] Get Offices Simple List');
export const getOfficesSimpleListSuccess = createAction(
  '[Office] Get Offices Simple List Success',
  props<{ offices: Office[] }>()
);
export const getOfficesSimpleListError = createAction(
  '[Office] Get Offices Simple List Error',
  props<{ errorMessage: any }>()
);

export const getOffice = createAction('[Office] Get Office', props<{ officeId: string }>());
export const getOfficeSuccess = createAction('[Office] Get Office Success', props<{ office: Office }>());
export const getOfficeError = createAction('[Office] Get Office Error', props<{ errorMessage: any }>());

export const createOffice = createAction('[Office] Create Office', props<{ opts: CreateOfficeOpts }>());
export const createOfficeSuccess = createAction('[Office] Create Office Success', props<{ office: Office }>());
export const createOfficeError = createAction('[Office] Create Office Error', props<{ errorMessage: any }>());

export const updateOffice = createAction(
  '[Office] Update Office',
  props<{ officeId: string; opts: CreateOfficeOpts }>()
);
export const updateOfficeSuccess = createAction('[Office] Update Office Success', props<{ office: Office }>());
export const updateOfficeError = createAction('[Office] Update Office Error', props<{ errorMessage: any }>());

export const removeOffice = createAction('[Office] Remove Office', props<{ officeId: string }>());
export const removeOfficeSuccess = createAction('[Office] Remove Office Success', props<{ office: Office }>());
export const removeOfficeError = createAction('[Office] Remove Office Error', props<{ errorMessage: any }>());

export const restoreOffice = createAction('[Office] Restore Office', props<{ officeId: string }>());
export const restoreOfficeSuccess = createAction('[Office] Restore Office Success', props<{ office: Office }>());
export const restoreOfficeError = createAction('[Office] Restore Office Error', props<{ errorMessage: any }>());

export const toggleAssignedVoivodeship = createAction(
  '[Office] Toggle Assigned Voivodeship',
  props<{ opts: ToggleAssignedVoivodeship }>()
);
export const toggleAssignedVoivodeshipSuccess = createAction(
  '[Office] Toggle Assigned Voivodeship Success',
  props<{ office: Office }>()
);
export const toggleAssignedVoivodeshipError = createAction(
  '[Office] Toggle Assigned Voivodeship Error',
  props<{ errorMessage: any }>()
);
