import { createAction, props } from '@ngrx/store';

import {
  CreateProductOpts,
  GetAdminsPayload,
  GetOnlineUsersPayload,
  PaginatedResponse,
  Product,
  ProductHistory,
  SystemSettings,
  UpdateProductOpts,
  User,
} from '@interfaces';

export const getSystemSettings = createAction('[System-Settings] Get System Settings');
export const getSystemSettingsSuccess = createAction(
  '[System-Settings] Get System Settings Success',
  props<{ systemSettings: SystemSettings }>()
);
export const getSystemSettingsError = createAction(
  '[System-Settings] Get System Settings Error',
  props<{ errorMessage: string }>()
);

export const changePIN = createAction('[System-Settings] Change PIN', props<{ newPIN: string }>());
export const changePINSuccess = createAction(
  '[System-Settings] Change PIN Success',
  props<{ updatedSystemSettings: SystemSettings }>()
);
export const changePINError = createAction('[System-Settings] Change PIN Error', props<{ errorMessage: string }>());

export const changeMainNotificationsRecipient = createAction(
  '[System-Setttings] Change Main Notifications Recipient',
  props<{ newEmail: string }>()
);

export const changeMainNotificationsRecipientSuccess = createAction(
  '[System-Setttings] Change Main Notifications Recipient Success',
  props<{ updatedSystemSettings: SystemSettings }>()
);

export const changeMainNotificationsRecipientError = createAction(
  '[System-Setttings] Change Main Notifications Recipient Error',
  props<{ errorMessage: string }>()
);

export const changeAdditionalRecipients = createAction(
  '[System-Setttings] Change Additional Recipients',
  props<{ recipientsEmails: string[] }>()
);

export const changeAdditionalRecipientsSuccess = createAction(
  '[System-Setttings] Change Additional Recipients Success',
  props<{ updatedSystemSettings: SystemSettings }>()
);

export const changeAdditionalRecipientsError = createAction(
  '[System-Setttings] Change Additional Recipients Error',
  props<{ errorMessage: string }>()
);

export const changeSecurityRecipients = createAction(
  '[System-Setttings] Change Security Info Recipients',
  props<{ recipientsEmails: string[] }>()
);

export const changeSecurityRecipientsSuccess = createAction(
  '[System-Setttings] Change Security Info Recipients Success',
  props<{ updatedSystemSettings: SystemSettings }>()
);

export const changeSecurityRecipientsError = createAction(
  '[System-Setttings] Change Security Info Recipients Error',
  props<{ errorMessage: string }>()
);

export const getOnlineUsers = createAction(
  '[System-Settings] Get Online Users',
  props<{ payload: GetOnlineUsersPayload }>()
);
export const getOnlineUsersSuccess = createAction(
  '[System-Settings] Get Online Users Success',
  props<{ users: PaginatedResponse<User> }>()
);
export const getOnlineUsersError = createAction(
  '[System-Settings] Get Online Users Error',
  props<{ errorMessage: string }>()
);

export const getAdmins = createAction('[System-Settings] Get Admins', props<{ payload: GetAdminsPayload }>());
export const getAdminsSuccess = createAction(
  '[System-Settings] Get Admins Success',
  props<{ admins: PaginatedResponse<User> }>()
);
export const getAdminsError = createAction('[System-Settings] Get Admins Error', props<{ errorMessage: string }>());

export const getAdmin = createAction('[System-Settings] Get Admin data', props<{ adminId: string }>());
export const getAdminSuccess = createAction('[System-Settings] Get Admin data Success', props<{ admin: User }>());
export const getAdminError = createAction('[System-Settings] Get Admin data Error', props<{ errorMessage: string }>());

export const createAdmin = createAction('[System-Settings] Create Admin', props<{ payload: Partial<User> }>());
export const createAdminSuccess = createAction(
  '[System-Settings] Create Admin Success',
  props<{ createdAdmin: User }>()
);
export const createAdminError = createAction('[System-Settings] Create Admin Error', props<{ errorMessage: string }>());

export const updateAdmin = createAction(
  '[System-Settings] Update Admin',
  props<{ payload: Partial<User>; userId: string }>()
);
export const updateAdminSuccess = createAction(
  '[System-Settings] Update Admin Success',
  props<{ updatedAdmin: User }>()
);
export const updateAdminError = createAction('[System-Settings] Update Admin Error', props<{ errorMessage: string }>());

export const removeAdmin = createAction('[System-Settings] Remove Admin', props<{ userId: string }>());
export const removeAdminSuccess = createAction(
  '[System-Settings] Remove Admin Success',
  props<{ removedAdminId: string }>()
);
export const removeAdminError = createAction('[System-Settings] Remove Admin Error', props<{ errorMessage: string }>());

export const resendAdminActivation = createAction(
  '[System-Settings] Resend admin activation link',
  props<{ email: string }>()
);
export const resendAdminActivationSuccess = createAction(
  '[System-Settings] Resend admin activation link success',
  props<{ emailSent: boolean }>()
);
export const resendAdminActivationError = createAction(
  '[System-Settings] Resend admin activation link error',
  props<{ errorMessage: string }>()
);

export const recreateAvatarsForAllUsers = createAction('[System-Settings] Recreate avatars for all users');
export const recreateAvatarsForAllUsersSuccess = createAction(
  '[System-Settings] Recreate avatars for all users Success'
);
export const recreateAvatarsForAllUsersError = createAction('[System-Settings] Recreate avatars for all users Error');

export const setSelfRegistrationStatus = createAction(
  '[System-Settings] Set Self Registration Status',
  props<{ isActive: boolean }>()
);

export const setSelfRegistrationStatusSuccess = createAction(
  '[System-Settings] Set Self Registration Status Success',
  props<{ updatedSystemSettings: SystemSettings }>()
);

export const setSelfRegistrationStatusError = createAction(
  '[System-Settings] Set Self Registration Status Error',
  props<{ errorMessage: string }>()
);

export const getSelfRegistrationStatus = createAction('[System-Settings] Get Self Registration Status');
export const getSelfRegistrationStatusSuccess = createAction(
  '[System-Settings] Get Self Registration Status Success',
  props<{ isActive: boolean }>()
);
export const getSelfRegistrationStatusError = createAction(
  '[System-Settings] Get Self Registration Status Error',
  props<{ errorMessage: string }>()
);

export const createProduct = createAction('[Products] Create Product', props<{ opts: CreateProductOpts }>());
export const createProductSuccess = createAction('[Products] Create Product Success', props<{ product: Product }>());
export const createProductError = createAction('[Products] Create Product Error');

export const getProducts = createAction('[Products] Get Products');
export const getProductsSuccess = createAction('[Products] Get Products Success', props<{ products: Product[] }>());
export const getProductsError = createAction('[Products] Get Products Errors');

export const updateProduct = createAction('[Products] Update Product', props<{ opts: UpdateProductOpts }>());
export const updateProductSuccess = createAction(
  '[Products] Update Product Success',
  props<{ updatedProduct: Product }>()
);
export const updateProductError = createAction('[Products] Update Product Error', props<{ productId: string }>());

export const getProductHistory = createAction('[Products] Get Product Changes History', props<{ productId: string }>());
export const getProductHistorySuccess = createAction(
  '[Products] Get Product Changes History Success',
  props<{ productHistory: ProductHistory[] }>()
);
export const getProductHistoryError = createAction(
  '[Products] Get Product Changes History Error',
  props<{ productId: string }>()
);
