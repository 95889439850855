import { Office, Pagination } from '@interfaces';

export const defaultPagination = {
  all: 0,
  limit: 50,
  offset: 0,
  orderBy: 'city',
  orderDir: 'desc',
  count: 0,
};

export type OfficeState = Readonly<{
  loading: boolean;
  offices: Office[];
  pagination: Pagination;
  loadedOffice: Office | null;
  errorMessage: string | null;
}>;

export const initialState: OfficeState = {
  loading: false,
  offices: [],
  pagination: { ...(defaultPagination as Pagination) },
  loadedOffice: null,
  errorMessage: null,
};
