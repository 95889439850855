<div
  *ngIf="!userProcess.hasFullHandling && !userProcess.hasAssistantService"
  class="service-item basic-support"
  [class]="{
    large: mode === PurchaseTags.LARGE,
    small: mode === PurchaseTags.SMALL,
    icon_only: mode === PurchaseTags.ICON_ONLY
  }"
  [matTooltipDisabled]="mode !== PurchaseTags.ICON_ONLY"
  [matTooltip]="'PURCHASE.HAS_BASIC_SUPPORT' | translate">
  <mat-icon fontSet="material-symbols-outlined">draft</mat-icon>
  <ng-container *ngIf="mode !== PurchaseTags.ICON_ONLY">
    {{ 'PURCHASE.HAS_BASIC_SUPPORT' | translate }}
  </ng-container>
</div>
<div
  *ngIf="userProcess.hasFullHandling"
  class="service-item full-handling"
  [class]="{
    large: mode === PurchaseTags.LARGE,
    small: mode === PurchaseTags.SMALL,
    icon_only: mode === PurchaseTags.ICON_ONLY
  }"
  [matTooltipDisabled]="mode !== PurchaseTags.ICON_ONLY"
  [matTooltip]="'PURCHASE.HAS_FULL_HANDLING' | translate">
  <mat-icon fontSet="material-icons-outlined">star</mat-icon>
  <ng-container *ngIf="mode !== PurchaseTags.ICON_ONLY">
    {{ 'PURCHASE.HAS_FULL_HANDLING' | translate }}
  </ng-container>
</div>
<div
  *ngIf="!userProcess.hasFullHandling && userProcess.hasAssistantService"
  class="service-item assistant"
  [class]="{
    large: mode === PurchaseTags.LARGE,
    small: mode === PurchaseTags.SMALL,
    icon_only: mode === PurchaseTags.ICON_ONLY
  }"
  [matTooltipDisabled]="mode !== PurchaseTags.ICON_ONLY"
  [matTooltip]="'PURCHASE.HAS_ASSISTANT_SERVICE' | translate">
  <mat-icon fontSet="material-icons-outlined">person</mat-icon>
  <ng-container *ngIf="mode !== PurchaseTags.ICON_ONLY">
    {{ 'PURCHASE.HAS_ASSISTANT_SERVICE' | translate }}
  </ng-container>
</div>
