<form [formGroup]="formGroup" id="step3Form">
  <div class="step3-form-container">
    <div class="row" [class.mark-red-border]="controls.currentlyDetained.value === true && mode === 'employee'">
      <div class="standard-form-field radio-form-field currentlyDetained-field required-field">
        <label [class.invalid]="controls.currentlyDetained.touched && controls.currentlyDetained.invalid">
          {{ 'PERSONAL_DATA_FORM.CURRENTLY-DETAINED.MAIN-LABEL' | translate }} *
        </label>
        <mat-radio-group formControlName="currentlyDetained" [required]="true" (keydown)="firstInputKeydown($event)">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <ng-container *ngIf="controls.currentlyDetained.value === true">
      <!-- ====================================================================== 1 ============================= -->
      <div class="row" [class.mark-red-border]="controls.inGuardedCentre.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field inGuardedCentre-field required-field">
          <label [class.invalid]="controls.inGuardedCentre.touched && controls.inGuardedCentre.invalid">
            {{ 'NT2.DETAINED_IN_GUARDED_CENTRE' | translate }} *
          </label>
          <mat-radio-group formControlName="inGuardedCentre">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field three-fourth-field inGuardedCentreComment-field"
          *ngIf="controls.inGuardedCentre.value === true">
          <label [class.invalid]="controls.inGuardedCentreComment.touched && controls.inGuardedCentreComment.invalid">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="inGuardedCentreComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.inGuardedCentreComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.inGuardedCentreComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ====================================================================== 2 ============================= -->
      <div class="row" [class.mark-red-border]="controls.inDetentionCentre.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field inDetentionCentre-field required-field">
          <label [class.invalid]="controls.inDetentionCentre.touched && controls.inDetentionCentre.invalid">
            {{ 'NT2.IN_DETENTION_CENTRE' | translate }} *
          </label>
          <mat-radio-group formControlName="inDetentionCentre">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field three-fourth-field inDetentionCentreComment-field"
          *ngIf="controls.inDetentionCentre.value === true">
          <label
            [class.invalid]="controls.inDetentionCentreComment.touched && controls.inDetentionCentreComment.invalid">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="inDetentionCentreComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.inDetentionCentreComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.inDetentionCentreComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ====================================================================== 3 ============================= -->
      <div class="row" [class.mark-red-border]="controls.hasBanOnLeavingCountry.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field hasBanOnLeavingCountry-field required-field">
          <label [class.invalid]="controls.hasBanOnLeavingCountry.touched && controls.hasBanOnLeavingCountry.invalid">
            {{ 'NT2.HAS_BAN_ON_LEAVING_THE_COUNTRY' | translate }} *
          </label>
          <mat-radio-group formControlName="hasBanOnLeavingCountry">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field three-fourth-field hasBanOnLeavingCountryComment-field"
          *ngIf="controls.hasBanOnLeavingCountry.value === true">
          <label
            [class.invalid]="
              controls.hasBanOnLeavingCountryComment.touched && controls.hasBanOnLeavingCountryComment.invalid
            ">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="hasBanOnLeavingCountryComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.hasBanOnLeavingCountryComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.hasBanOnLeavingCountryComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ====================================================================== 4 ============================= -->
      <div
        class="row"
        [class.mark-red-border]="controls.hasSentenceOfImprisonment.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field hasSentenceOfImprisonment-field required-field">
          <label
            [class.invalid]="controls.hasSentenceOfImprisonment.touched && controls.hasSentenceOfImprisonment.invalid">
            {{ 'NT2.SENTENCED_IMPRISONMENT' | translate }} *
          </label>
          <mat-radio-group formControlName="hasSentenceOfImprisonment">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field three-fourth-field hasSentenceOfImprisonmentComment-field"
          *ngIf="controls.hasSentenceOfImprisonment.value === true">
          <label
            [class.invalid]="
              controls.hasSentenceOfImprisonmentComment.touched && controls.hasSentenceOfImprisonmentComment.invalid
            ">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="hasSentenceOfImprisonmentComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.hasSentenceOfImprisonmentComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.hasSentenceOfImprisonmentComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ====================================================================== 5 ============================= -->
      <div class="row" [class.mark-red-border]="controls.temporarilyArrested.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field temporarilyArrested-field required-field">
          <label [class.invalid]="controls.temporarilyArrested.touched && controls.temporarilyArrested.invalid">
            {{ 'NT2.TEMPORARILY_ARRESTED' | translate }} *
          </label>
          <mat-radio-group formControlName="temporarilyArrested">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field three-fourth-field temporarilyArrestedComment-field"
          *ngIf="controls.temporarilyArrested.value === true">
          <label
            [class.invalid]="
              controls.temporarilyArrestedComment.touched && controls.temporarilyArrestedComment.invalid
            ">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="temporarilyArrestedComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.temporarilyArrestedComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.temporarilyArrestedComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <div class="row">
      <div class="standard-form-field radio-form-field wasSentencedInPoland-field required-field">
        <label [class.invalid]="controls.wasSentencedInPoland.touched && controls.wasSentencedInPoland.invalid">
          {{ 'PERSONAL_DATA_FORM.WAS-SENTENCED-IN-POLAND.MAIN-LABEL' | translate }} *
        </label>
        <mat-radio-group formControlName="wasSentencedInPoland">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="standard-form-field three-fourth-field sentenceReason-field"
        *ngIf="controls.wasSentencedInPoland.value === true">
        <label
          for="applicantSentenceReason"
          [class.invalid]="controls.sentenceReason.touched && controls.sentenceReason.invalid">
          {{ 'PERSONAL_DATA_FORM.SENTENCE-REASON.MAIN-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            id="applicantSentenceReason"
            formControlName="sentenceReason"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"></textarea>
          <mat-error *ngIf="controls.sentenceReason.invalid">
            {{ 'PERSONAL_DATA_FORM.SENTENCE-REASON.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.sentenceReason) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="standard-form-field radio-form-field currentlySubjectOfProceedings-field required-field">
        <label
          [class.invalid]="
            controls.currentlySubjectOfProceedings.touched && controls.currentlySubjectOfProceedings.invalid
          ">
          {{ 'PERSONAL_DATA_FORM.CURRENTLY-SUBJECT-OF-PROCEEDINGS.MAIN-LABEL' | translate }} *
        </label>
        <mat-radio-group formControlName="currentlySubjectOfProceedings">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="standard-form-field three-fourth-field proceedingsReason-field"
        *ngIf="controls.currentlySubjectOfProceedings.value === true">
        <label
          for="applicantProceedingsReason"
          [class.invalid]="controls.proceedingsReason.touched && controls.proceedingsReason.invalid">
          {{ 'PERSONAL_DATA_FORM.PROCEEDINGS-REASON.MAIN-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            id="applicantProceedingsReason"
            formControlName="proceedingsReason"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"></textarea>
          <mat-error *ngIf="controls.proceedingsReason.invalid">
            {{ 'PERSONAL_DATA_FORM.PROCEEDINGS-REASON.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.proceedingsReason) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="standard-form-field radio-form-field isComplyingWithFiscalObligation-field required-field">
        <label
          [class.invalid]="
            controls.isComplyingWithFiscalObligation.touched && controls.isComplyingWithFiscalObligation.invalid
          ">
          {{ 'PERSONAL_DATA_FORM.IS-COMPLYING-WITH-FISCAL-OBLIGATION.MAIN-LABEL' | translate }} *
        </label>
        <mat-radio-group
          formControlName="isComplyingWithFiscalObligation"
          [required]="true"
          (keydown.Tab)="
            maritalStatus === 'Żonaty' || maritalStatus === 'Mężatka' ? undefined : emitLastFieldTabPressed($event)
          ">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <ng-container *ngIf="maritalStatus === 'Żonaty' || maritalStatus === 'Mężatka'">
      <nz-divider [nzText]="travelers" nzOrientation="center">
        <ng-template #travelers>
          <h5>{{ 'PERSONAL_DATA_FORM.SPOUSE_DATA' | translate }}</h5>
        </ng-template>
      </nz-divider>

      <div class="row">
        <div class="standard-form-field spouseName-field required-field">
          <label for="spouseNameInput" [class.invalid]="controls.spouseName.touched && controls.spouseName.invalid">
            {{ 'PERSONAL_DATA_FORM.NAME.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="spouseNameInput"
              type="text"
              maxlength="200"
              placeholder="{{ 'PERSONAL_DATA_FORM.NAME.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.spouseName" />
            <mat-error *ngIf="controls.spouseName.invalid">
              {{ 'PERSONAL_DATA_FORM.NAME.INFIELD-LABEL' | translate }}
              {{ getErrorMessage(controls.spouseName) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field spouseSurname-field required-field">
          <label
            for="spouseSurnameInput"
            [class.invalid]="controls.spouseSurname.touched && controls.spouseSurname.invalid">
            {{ 'PERSONAL_DATA_FORM.SURNAME.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="spouseSurnameInput"
              type="text"
              maxlength="200"
              placeholder="{{ 'PERSONAL_DATA_FORM.SURNAME.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.spouseSurname" />
            <mat-error *ngIf="controls.spouseSurname.invalid">
              {{ 'PERSONAL_DATA_FORM.SURNAME.INFIELD-LABEL' | translate }}
              {{ getErrorMessage(controls.spouseSurname) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field spousePreviousName-field">
          <label
            for="spousePreviousNameInput"
            [class.invalid]="controls.spousePreviousName.touched && controls.spousePreviousName.invalid">
            {{ 'PERSONAL_DATA_FORM.PREVIOUS-NAME.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="spousePreviousNameInput"
              type="text"
              maxlength="200"
              placeholder="{{ 'PERSONAL_DATA_FORM.PREVIOUS-NAME.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.spousePreviousName" />
            <mat-error *ngIf="controls.spousePreviousName.invalid">
              {{ 'PERSONAL_DATA_FORM.PREVIOUS-NAME.INFIELD-LABEL' | translate }}
              {{ getErrorMessage(controls.spousePreviousName) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field spousePreviousSurname-field">
          <label
            for="spousePreviousSurnameInput"
            [class.invalid]="controls.spousePreviousSurname.touched && controls.spousePreviousSurname.invalid">
            {{ 'PERSONAL_DATA_FORM.PREVIOUS-SURNAME.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="spousePreviousSurnameInput"
              type="text"
              maxlength="200"
              placeholder="{{ 'PERSONAL_DATA_FORM.PREVIOUS-SURNAME.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.spousePreviousSurname" />
            <mat-error *ngIf="controls.spousePreviousSurname.invalid">
              {{ 'PERSONAL_DATA_FORM.PREVIOUS-SURNAME.INFIELD-LABEL' | translate }}
              {{ getErrorMessage(controls.spousePreviousSurname) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="standard-form-field spouseDob-field required-field">
          <label for="spouseDobInput" [class.invalid]="controls.spouseDob.touched && controls.spouseDob.invalid">
            {{ 'PERSONAL_DATA_FORM.DATE-OF-BIRTH.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline" (click)="picker.open()">
            <input
              matInput
              (focus)="picker.open()"
              id="spouseDobInput"
              #spouseDobInput
              [matDatepicker]="picker"
              placeholder="{{ 'PERSONAL_DATA_FORM.DATE-OF-BIRTH.PLACEHOLDER' | translate }}"
              [min]="minValidDate"
              [max]="maxValidDate"
              [required]="true"
              [readonly]="true"
              [formControl]="controls.spouseDob" />
            <mat-error *ngIf="controls.spouseDob.invalid">
              {{ getErrorMessage(controls.spouseDob) }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              [startView]="'month'"
              [startAt]="'1985'"
              [calendarHeaderComponent]="pickerHeader"
              (closed)="spouseDobInput.blur()">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="standard-form-field spouseSex-field required-field">
          <label [class.invalid]="controls.spouseSex.touched && controls.spouseSex.invalid">
            {{ 'PERSONAL_DATA_FORM.SEX.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              [formControl]="controls.spouseSex"
              id="spouseSexInput"
              panelClass="standard-select"
              [required]="true">
              <mat-option *ngFor="let item of selectsValues.SEX" [value]="item.value">
                {{ item.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.spouseSex.touched && controls.spouseSex.invalid">
              {{ 'PERSONAL_DATA_FORM.SEX.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.spouseSex) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field citizenship-field required-field">
          <!-- Filtering out id: 616 -> Poland. Person that has Polish citizenship cannot apply for temp. permit -->
          <app-country-select
            class="country-select"
            [fieldControl]="controls.spouseCitizenship"
            [fieldKey]="'spouseCitizenship'"
            [isRequired]="true"
            [fieldLabelKey]="'CITIZENSHIP'">
          </app-country-select>
        </div>
      </div>

      <div class="row">
        <div class="standard-form-field spouseResidenceCountry-field">
          <app-country-select
            class="country-select"
            [fieldControl]="controls.spouseResidenceCountry"
            [fieldKey]="'spouseResidenceCountry'"
            [isRequired]="false"
            [fieldLabelKey]="'RESIDENCE-COUNTRY'">
          </app-country-select>
        </div>
        <div class="standard-form-field spouseResidencePostalCode-field">
          <label
            for="spouseResidencePostalCodeInput"
            [class.invalid]="controls.spouseResidencePostalCode.touched && controls.spouseResidencePostalCode.invalid">
            {{ 'PERSONAL_DATA_FORM.RESIDENCE-POSTAL-CODE.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="spouseResidencePostalCodeInput"
              type="text"
              maxLength="6"
              minLength="6"
              [mask]="'99-999'"
              placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-POSTAL-CODE.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.spouseResidencePostalCode" />
            <mat-error *ngIf="controls.spouseResidencePostalCode.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-POSTAL-CODE.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.spouseResidencePostalCode) }}
            </mat-error>
          </mat-form-field>
        </div>

        <ng-container *ngIf="controls.spouseResidenceCountry?.value?.toLowerCase() === 'polska'">
          <div class="standard-form-field spouseResidenceVoivodeshipDetailsId-field">
            <label
              [class.invalid]="
                controls.spouseResidenceVoivodeshipDetailsId.touched &&
                controls.spouseResidenceVoivodeshipDetailsId.invalid
              ">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-VOIVODESHIP-NAME.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline" *ngIf="voivodeships$ | async as voivodeships">
              <mat-select
                [formControl]="controls.spouseResidenceVoivodeshipDetailsId"
                id="spouseResidenceVoivodeshipDetailsId"
                panelClass="['standard-select', longer-select']">
                <mat-option *ngFor="let voivo of voivodeships" [value]="voivo.id">
                  {{ 'VOIVODESHIPS.' + voivo.key | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controls.spouseResidenceVoivodeshipDetailsId.invalid">
                {{ 'PERSONAL_DATA_FORM.RESIDENCE-VOIVODESHIP-NAME.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.spouseResidenceVoivodeshipDetailsId) }}
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="controls.spouseResidenceCountry?.value?.toLowerCase() !== 'polska'">
          <div class="standard-form-field spouseResidenceCity-field">
            <label
              for="spouseResidenceCityInput"
              [class.invalid]="controls.spouseResidenceCity.touched && controls.spouseResidenceCity.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-CITY.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="spouseResidenceCityInput"
                type="text"
                maxLength="200"
                minLength="1"
                placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-CITY.PLACEHOLDER' | translate }}"
                [required]="false"
                [formControl]="controls.spouseResidenceCity" />
              <mat-error *ngIf="controls.spouseResidenceCity.invalid">
                {{ 'PERSONAL_DATA_FORM.RESIDENCE-CITY.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.spouseResidenceCity) }}
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <ng-container *ngIf="controls.spouseResidenceCountry?.value?.toLowerCase() === 'polska'">
          <div class="standard-form-field spouseResidenceDistrictId-field">
            <label
              [class.invalid]="
                controls.spouseResidenceDistrictId.touched && controls.spouseResidenceDistrictId.invalid
              ">
              {{ 'NT.RESIDENCE_DISTRICT_LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline" *ngIf="districts$ | async as districts">
              <mat-select
                [formControl]="controls.spouseResidenceDistrictId"
                [disabled]="!controls.spouseResidenceVoivodeshipDetailsId?.value"
                id="spouseResidenceDistrictId"
                [panelClass]="['standard-select', 'longer-select']"
                appMatOptionHeight
                [appMatOptionHeightValue]="50"
                class="heightened-select">
                <mat-option *ngIf="loadingDistricts$ | async">
                  <mat-spinner [diameter]="25"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let district of districts" [value]="district.id">
                  {{ district.MOS_name.toUpperCase() }} ({{ district.MOS_extra_name.toUpperCase() }})
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controls.spouseResidenceDistrictId.invalid">
                {{ 'NT.RESIDENCE_DISTRICT_LABEL' | translate }}
                {{ getErrorMessage(controls.spouseResidenceDistrictId) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field spouserResidenceMunicipalityId-field">
            <label
              [class.invalid]="
                controls.spouserResidenceMunicipalityId.touched && controls.spouserResidenceMunicipalityId.invalid
              ">
              {{ 'NT.RESIDENCE_MUNICIPALITY_LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline" *ngIf="municips$ | async as municips">
              <mat-select
                [formControl]="controls.spouserResidenceMunicipalityId"
                [disabled]="!controls.spouseResidenceDistrictId?.value"
                id="spouserResidenceMunicipalityId"
                [panelClass]="['standard-select', 'longer-select']"
                appMatOptionHeight
                [appMatOptionHeightValue]="50">
                <mat-option *ngIf="loadingMunicips$ | async">
                  <mat-spinner [diameter]="25"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let municip of municips" [value]="municip.id">
                  {{ municip.MOS_name.toUpperCase() }} ({{ municip.MOS_type.toUpperCase() }})
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controls.spouserResidenceMunicipalityId.invalid">
                {{ 'NT.RESIDENCE_MUNICIPALITY_LABEL' | translate }}
                {{ getErrorMessage(controls.spouserResidenceMunicipalityId) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field spouseResidenceCityListedId-field">
            <label
              [class.invalid]="
                controls.spouseResidenceCityListedId.touched && controls.spouseResidenceCityListedId.invalid
              ">
              {{ 'NT.RESIDENCE_CITY_LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline" *ngIf="cities$ | async as cities">
              <mat-select
                [formControl]="controls.spouseResidenceCityListedId"
                [disabled]="!controls.spouserResidenceMunicipalityId?.value"
                id="spouseResidenceCityListedId"
                [panelClass]="['standard-select', 'longer-select']"
                appMatOptionHeight
                [appMatOptionHeightValue]="50">
                <mat-option *ngIf="loadingCities$ | async">
                  <mat-spinner [diameter]="25"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let city of cities" [value]="city.id">
                  {{ city.MOS_name.toUpperCase() }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controls.spouseResidenceCityListedId.invalid">
                {{ 'NT.RESIDENCE_CITY_LABEL' | translate }}
                {{ getErrorMessage(controls.spouseResidenceCityListedId) }}
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngIf="controls.spouseResidenceCountry?.value?.toLowerCase() !== 'polska'">
          <div class="standard-form-field spouseResidenceStreet-field">
            <label
              for="spouseResidenceStreetInput"
              [class.invalid]="controls.spouseResidenceStreet.touched && controls.spouseResidenceStreet.invalid">
              {{ 'INVOICE_DATA.STREET_NAME' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="spouseResidenceStreetInput"
                type="text"
                maxLength="200"
                minLength="1"
                placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-STREET.PLACEHOLDER' | translate }}"
                [required]="false"
                [formControl]="controls.spouseResidenceStreet" />
              <mat-icon
                #spouseResidenceStreetInput="matTooltip"
                (click)="$event.preventDefault(); $event.stopPropagation(); spouseResidenceStreetInput.toggle()"
                class="info-suffix-icon"
                matSuffix
                matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
                matTooltipClass="personalDataFormTooltip">
                info_outline
              </mat-icon>
              <mat-error *ngIf="controls.spouseResidenceStreet.invalid">
                {{ 'PERSONAL_DATA_FORM.RESIDENCE-STREET.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.spouseResidenceStreet) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field spouseResidenceHouseNumber-field">
            <label
              for="spouseResidenceHouseNumberInput"
              [class.invalid]="
                controls.spouseResidenceHouseNumber.touched && controls.spouseResidenceHouseNumber.invalid
              ">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="spouseResidenceHouseNumberInput"
                type="text"
                maxLength="20"
                minLength="1"
                placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.PLACEHOLDER' | translate }}"
                [required]="false"
                [formControl]="controls.spouseResidenceHouseNumber" />
              <mat-icon
                #spouseResidenceHouseNumberInput="matTooltip"
                (click)="$event.preventDefault(); $event.stopPropagation(); spouseResidenceHouseNumberInput.toggle()"
                class="info-suffix-icon"
                matSuffix
                matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
                matTooltipClass="personalDataFormTooltip">
                info_outline
              </mat-icon>
              <mat-error *ngIf="controls.spouseResidenceHouseNumber.invalid">
                {{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.spouseResidenceHouseNumber) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field spouseResidenceApartmentNumber-field">
            <label
              for="spouseResidenceApartmentNumberInput"
              [class.invalid]="
                controls.spouseResidenceApartmentNumber.touched && controls.spouseResidenceApartmentNumber.invalid
              ">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="spouseResidenceApartmentNumberInput"
                type="text"
                maxLength="20"
                placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.PLACEHOLDER' | translate }}"
                [required]="false"
                [formControl]="controls.spouseResidenceApartmentNumber"
                (keydown.Tab)="lastFieldTabPressed.emit(); $event.preventDefault(); $event.stopPropagation()" />
              <mat-icon
                #spouseResidenceApartmentNumberInput="matTooltip"
                (click)="
                  $event.preventDefault(); $event.stopPropagation(); spouseResidenceApartmentNumberInput.toggle()
                "
                class="info-suffix-icon"
                matSuffix
                matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
                matTooltipClass="personalDataFormTooltip">
                info_outline
              </mat-icon>
              <mat-error *ngIf="controls.spouseResidenceApartmentNumber.invalid">
                {{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.spouseResidenceApartmentNumber) }}
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </div>

      <div class="row" *ngIf="controls.spouseResidenceCountry?.value?.toLowerCase() === 'polska'">
        <div class="standard-form-field spouseResidenceStreet-field">
          <label
            for="spouseResidenceStreetInputPoland"
            [class.invalid]="controls.spouseResidenceStreet.touched && controls.spouseResidenceStreet.invalid">
            {{ 'INVOICE_DATA.STREET_NAME' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="spouseResidenceStreetInputPoland"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-STREET.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.spouseResidenceStreet" />
            <mat-icon
              #spouseResidenceStreetInputPoland="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); spouseResidenceStreetInputPoland.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.spouseResidenceStreet.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-STREET.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.spouseResidenceStreet) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field spouseResidenceHouseNumber-field">
          <label
            for="spouseResidenceHouseNumberInputPoland"
            [class.invalid]="
              controls.spouseResidenceHouseNumber.touched && controls.spouseResidenceHouseNumber.invalid
            ">
            {{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="spouseResidenceHouseNumberInputPoland"
              type="text"
              maxLength="20"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.spouseResidenceHouseNumber" />
            <mat-icon
              #spouseResidenceHouseNumberInputPoland="matTooltip"
              (click)="
                $event.preventDefault(); $event.stopPropagation(); spouseResidenceHouseNumberInputPoland.toggle()
              "
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.spouseResidenceHouseNumber.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.spouseResidenceHouseNumber) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field spouseResidenceApartmentNumber-field">
          <label
            for="spouseResidenceApartmentNumberInputPoland"
            [class.invalid]="
              controls.spouseResidenceApartmentNumber.touched && controls.spouseResidenceApartmentNumber.invalid
            ">
            {{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="spouseResidenceApartmentNumberInputPoland"
              type="text"
              maxLength="20"
              placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.spouseResidenceApartmentNumber"
              (keydown.Tab)="lastFieldTabPressed.emit(); $event.preventDefault(); $event.stopPropagation()" />
            <mat-icon
              #spouseResidenceApartmentNumberInputPoland="matTooltip"
              (click)="
                $event.preventDefault(); $event.stopPropagation(); spouseResidenceApartmentNumberInputPoland.toggle()
              "
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.spouseResidenceApartmentNumber.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.spouseResidenceApartmentNumber) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </div>
</form>
