import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, concatMap } from 'rxjs/operators';
import { parseError } from '@state/errors.parser';

import { InstructionItemHttpService } from '@core/http/instruction-item.http.service';
import * as itemsActions from './instruction-items.actions';

@Injectable()
export class InstructionItemsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly instrItemHttp: InstructionItemHttpService
  ) {}

  getAllInstructionItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.getAllInstructionItems),
      switchMap(() => {
        return this.instrItemHttp.getAllItems().pipe(
          map(instructionItems => {
            return itemsActions.getAllInstructionItemsSuccess({ instructionItems });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.getAllInstructionItems.type);
            return of(itemsActions.getAllInstructionItemsError({ errorMessage }));
          })
        );
      })
    )
  );

  getInstructionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.getInstructionItem),
      switchMap(({ id }) => {
        return this.instrItemHttp.getItem(id).pipe(
          map(instructionItem => {
            return itemsActions.getInstructionItemSuccess({ instructionItem });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.getInstructionItem.type);
            return of(itemsActions.getInstructionItemError({ errorMessage }));
          })
        );
      })
    )
  );

  createInstructionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.createInstructionItem),
      switchMap(({ opts }) => {
        return this.instrItemHttp.createInstructionItem(opts).pipe(
          map(createdInstructionItem => {
            return itemsActions.createInstructionItemSuccess({ createdInstructionItem });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.createInstructionItem.type);
            return of(itemsActions.createInstructionItemError({ errorMessage }));
          })
        );
      })
    )
  );

  updateInstructionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.updateInstructionItem),
      switchMap(({ opts }) => {
        return this.instrItemHttp.updateInstructionItemName(opts).pipe(
          map(updatedInstructionItem => {
            return itemsActions.updateInstructionItemSuccess({ updatedInstructionItem });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.updateInstructionItem.type);
            return of(itemsActions.updateInstructionItemError({ errorMessage }));
          })
        );
      })
    )
  );

  removeInstructionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.removeInstructionItem),
      switchMap(({ id }) => {
        return this.instrItemHttp.removeInstructionItem(id).pipe(
          map(() => {
            return itemsActions.removeInstructionItemSuccess({ removedInstructionItemId: id });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.removeInstructionItem.type);
            return of(itemsActions.removeInstructionItemError({ errorMessage }));
          })
        );
      })
    )
  );

  toggleItemsInProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.toggleItemsInProcess),
      switchMap(({ opts }) => {
        return this.instrItemHttp.toggleItemsInProcess(opts).pipe(
          map(updatedItemsInProcess => {
            return itemsActions.toggleItemsInProcessSuccess({ updatedItems: updatedItemsInProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.toggleItemsInProcess.type);
            return of(itemsActions.removeInstructionItemError({ errorMessage }));
          })
        );
      })
    )
  );

  updateItemsInProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.updateItemInProcess),
      concatMap(({ opts }) => {
        return this.instrItemHttp.updateItemInProcess(opts).pipe(
          map(updatedItemInProcess => {
            return itemsActions.updateItemInProcessSuccess({ updatedItemInProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.updateItemInProcess.type);
            return of(itemsActions.updateItemInProcessError({ errorMessage }));
          })
        );
      })
    )
  );

  searchItemsInProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.searchItemsInProcesses),
      switchMap(({ opts }) => {
        return this.instrItemHttp.searchItemsInProcesses(opts).pipe(
          map(itemsInProcesses => {
            return itemsActions.searchItemsInProcessesSuccess({ itemsInProcesses });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.searchItemsInProcesses.type);
            return of(itemsActions.searchItemsInProcessesError({ errorMessage }));
          })
        );
      })
    )
  );

  updateItemsOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.updateItemsOrder),
      switchMap(({ opts }) => {
        return this.instrItemHttp.updateItemsOrder(opts).pipe(
          map(itemsInProcess => {
            return itemsActions.updateItemsOrderSuccess({ itemsInProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.updateItemsOrder.type);
            return of(itemsActions.updateItemsOrderError({ errorMessage }));
          })
        );
      })
    )
  );

  getItemsListForCustomaization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.getItemsListForCustomization),
      switchMap(({ userProcessId }) => {
        return this.instrItemHttp.getItemsListForCustomization(userProcessId).pipe(
          map(itemsList => {
            return itemsActions.getItemsListForCustomizationSuccess({ itemsList });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.getItemsListForCustomization.type);
            return of(itemsActions.getItemsListForCustomizationError({ errorMessage }));
          })
        );
      })
    )
  );

  setUseCustomList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.setUseCustomList),
      switchMap(({ userProcessId, useCustomList }) => {
        return this.instrItemHttp.setUseCustomList(userProcessId, useCustomList).pipe(
          map(itemsList => {
            return itemsActions.getItemsListForCustomizationSuccess({ itemsList });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.setUseCustomList.type);
            return of(itemsActions.getItemsListForCustomizationError({ errorMessage }));
          })
        );
      })
    )
  );

  resetItemsInCustomItemsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.resetItemsInCustomItemsList),
      switchMap(({ userProcessId }) => {
        return this.instrItemHttp.resetItemsInCustomItemsList(userProcessId).pipe(
          map(itemsList => {
            return itemsActions.getItemsListForCustomizationSuccess({ itemsList });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.resetItemsInCustomItemsList.type);
            return of(itemsActions.getItemsListForCustomizationError({ errorMessage }));
          })
        );
      })
    )
  );

  setEnabledItemInCustomList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.setEnabledItemInCustomList),
      switchMap(({ userProcessId, enabled, itemId }) => {
        return this.instrItemHttp.setEnabledItemInCustomList({ enabled, itemId, userProcessId }).pipe(
          map(updatedItem => {
            return itemsActions.setEnabledItemInCustomListSuccess({ updatedItem });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.setEnabledItemInCustomList.type);
            return of(itemsActions.setEnabledItemInCustomListError({ errorMessage }));
          })
        );
      })
    )
  );

  setEnabledFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemsActions.setEnabledFees),
      switchMap(({ userProcessId, enabled }) => {
        return this.instrItemHttp.setEnabledFees({ enabled, userProcessId }).pipe(
          map(({ showFeesOnInstructionItemsList }) => {
            return itemsActions.setEnabledFeesSuccess({ userProcessId, showFeesOnInstructionItemsList });
          }),
          catchError(error => {
            const errorMessage = parseError(error, itemsActions.setEnabledFees.type);
            return of(itemsActions.setEnabledFeesError({ errorMessage }));
          })
        );
      })
    )
  );
}
