import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';

import { ConsumableCountry, CreateVisitAssistantOpts, Language, Process, VisitAssistant } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class CommonsHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public pingApi(): Observable<{ status: 'ok' }> {
    const url = `${this.API_URL}/common/api-healthcheck`;
    return this.http.get<{ status: 'ok' }>(url);
  }

  public getProcesses(): Observable<Process[]> {
    const url = `${this.API_URL}/processes`;
    return this.http.get<Process[]>(url);
  }

  public getCountries(): Observable<ConsumableCountry[]> {
    const url = `${this.API_URL}/voivodeships/countries`;
    return this.http.get<ConsumableCountry[]>(url);
  }

  public getCountriesEU(): Observable<ConsumableCountry[]> {
    const url = `${this.API_URL}/voivodeships/countriesEU`;
    return this.http.get<ConsumableCountry[]>(url);
  }

  public getLanguages(): Observable<Language[]> {
    const url = `${this.API_URL}/languages`;
    return this.http.get<Language[]>(url);
  }

  public getAllVisitAssistants(): Observable<VisitAssistant[]> {
    const url = `${this.API_URL}/visit-assistants`;
    return this.http.get<VisitAssistant[]>(url);
  }

  // returns updated list of all assistants
  public createVisitAssistant(opts: CreateVisitAssistantOpts): Observable<VisitAssistant> {
    const url = `${this.API_URL}/visit-assistants`;
    return this.http.post<VisitAssistant>(url, opts);
  }

  public removeAssistant(id: string): Observable<void> {
    const url = `${this.API_URL}/visit-assistants/${id}`;
    return this.http.delete<void>(url);
  }
}
