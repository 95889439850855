import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AppState } from '@state';
import {
  SearchDocument,
  UpdateDocumentEnabled,
  UpdateDocumentsBulk,
  UpdateVoivodeshipOpts,
  Voivodeship,
  VoivodeshipOffice,
} from '@interfaces';
import * as voivodeshipsActions from './voivodeships.actions';
import * as voivodeshipsSelectors from './voivodeships.selectors';

@Injectable()
export class VoivodeshipsFacade {
  public loading$ = this.store.select(voivodeshipsSelectors.selectLoading);
  public errorMessage$ = this.store.select(voivodeshipsSelectors.selectErrorMessage);
  public documents$ = this.store.select(voivodeshipsSelectors.selectVoivodeshipDocuments);

  public availableVoivodeships$ = this.store.select(voivodeshipsSelectors.selectVoivodeships);

  public getVoivoByZipcodeSuccess$ = this.actions.pipe(ofType(voivodeshipsActions.getVoivoByZipcodeSucces));
  public getVoivoByZipcodeError$ = this.actions.pipe(ofType(voivodeshipsActions.getVoivoByZipcodeError));

  public updateVoivodeshipSuccess$ = this.actions.pipe(ofType(voivodeshipsActions.updateVoivodeshipSuccess));

  public addVoivodeshipSuccess$ = this.actions.pipe(ofType(voivodeshipsActions.addVoivodeshipOfficeSuccess));
  public removeVoivodeshipSuccess$ = this.actions.pipe(ofType(voivodeshipsActions.removeVoivodeshipOfficeSuccess));

  public updateDocEnabledBulkSuccess$ = this.actions.pipe(ofType(voivodeshipsActions.updateDocEnabledBulkSuccess));
  public updateDocEnabledSuccess$ = this.actions.pipe(ofType(voivodeshipsActions.updateDocEnabledSuccess));

  public loadingDistricts$ = this.store.select(voivodeshipsSelectors.selectLoadingDistricts);
  public loadingMunicips$ = this.store.select(voivodeshipsSelectors.selectLoadingMunicips);
  public loadingCities$ = this.store.select(voivodeshipsSelectors.selectLoadingCities);

  public loadedDistricts$ = this.store.select(voivodeshipsSelectors.selectLoadedDistricts);
  public loadedMunicips$ = this.store.select(voivodeshipsSelectors.selectLoadedMunicips);
  public loadedCities$ = this.store.select(voivodeshipsSelectors.selectLoadedCities);

  public loadingVoivodeshipFileInstructionAssets$ = this.store.select(
    voivodeshipsSelectors.selectLoadingVoivodeshipFileInstructionAssets
  );

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getVoivodeships(): void {
    this.store.dispatch(voivodeshipsActions.getVoivodeships());
  }

  public getVoivodeships$(): Observable<Voivodeship[]> {
    return this.store.select(voivodeshipsSelectors.selectVoivodeships).pipe(
      tap(voivos => {
        if (!voivos || !voivos.length) {
          this.getVoivodeships();
        }
      }),
      filter(voivos => {
        return !(voivos === null || !voivos.length);
      })
    );
  }

  public getVoivoByZip(zipcode: string): void {
    this.store.dispatch(voivodeshipsActions.getVoivoByZipcode({ zipcode }));
  }

  public updateVoivodeship(voivoId: string, opts: UpdateVoivodeshipOpts): void {
    this.store.dispatch(voivodeshipsActions.updateVoivodeship({ voivoId, opts }));
  }

  public addVoivoOffice(opts: VoivodeshipOffice, voivoId: string): void {
    this.store.dispatch(voivodeshipsActions.addVoivodeshipOffice({ opts, voivoId }));
  }

  public editVoivoOffice(opts: VoivodeshipOffice, voivoId: string): void {
    this.store.dispatch(voivodeshipsActions.editVoivodeshipOffice({ opts, voivoId }));
  }

  public removeVoivoOffice(officeId: string, voivoId: string): void {
    this.store.dispatch(voivodeshipsActions.removeVoivodeshipOffice({ officeId, voivoId }));
  }

  public searchDocuments(opts: SearchDocument): void {
    this.store.dispatch(voivodeshipsActions.searchDocuments({ opts }));
  }

  public updateDocEnabled(opts: UpdateDocumentEnabled): void {
    this.store.dispatch(voivodeshipsActions.updateDocEnabled({ opts }));
  }

  public updateDocEnabledBulk(opts: UpdateDocumentsBulk): void {
    this.store.dispatch(voivodeshipsActions.updateDocEnabledBulk({ opts }));
  }

  public getDistricts(opts: { voivoId: string }): void {
    this.store.dispatch(voivodeshipsActions.getDistricts({ opts }));
  }

  public getMunicips(opts: { voivoId: string; districtId: string }): void {
    this.store.dispatch(voivodeshipsActions.getMunicips({ opts }));
  }

  public getCities(opts: { voivoId: string; districtId: string; municipId: string }): void {
    this.store.dispatch(voivodeshipsActions.getCities({ opts }));
  }

  public downloadVoivodeshipFileInstruction(opts: { voivoKey: string; lang: string }): void {
    this.store.dispatch(voivodeshipsActions.downloadVoivodeshipFileInstruction({ opts }));
  }

  public uploadVoivodeshipFileInstruction(opts: { voivoKey: string; lang: string; file: File }): void {
    this.store.dispatch(voivodeshipsActions.uploadVoivodeshipFileInstruction({ opts }));
  }
}
