import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { patterns } from '@constants';
import { AllDeviceInfo } from '@interfaces';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { RouterFacade } from '@state/router';
import { UserAuthFacade } from '@state/user-auth';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

const passwordsValidator = (formControl: AbstractControl): ValidationErrors | null => {
  const psswd = formControl.get('password')?.value;
  const confirmed = formControl.get('passwordConfirmation')?.value;
  return psswd === confirmed ? null : { noMatch: true };
};

const passwordConfirmationValidator = (formControl: AbstractControl): ValidationErrors | null => {
  const psswdFc = formControl.get('password');
  const confirmFc = formControl.get('passwordConfirmation');
  const psswd = psswdFc?.value;
  const confirmed = confirmFc?.value;
  const passwordsMatch = psswd === confirmed;

  if (!psswd?.length || !confirmed?.length) {
    return null;
  }

  if (!passwordsMatch) {
    confirmFc.markAsDirty();
    confirmFc.setErrors({ noMatch: true });
  }

  return passwordsMatch ? null : { noMatch: true };
};

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('errorMessageContainer') errorMessageContainer: ElementRef;

  public setPasswordForm: FormGroup<any> = this.fb.group(
    {
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100),
          Validators.pattern(patterns.HasUpperCaseLetter),
          Validators.pattern(patterns.HasSpecialCharacter),
        ]),
      ],
      passwordConfirmation: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100),
          Validators.pattern(patterns.HasUpperCaseLetter),
          Validators.pattern(patterns.HasSpecialCharacter),
        ],
        updateOn: 'change',
      }),
      password_reset_token: ['', Validators.compose([Validators.required])],
    },
    { validators: [passwordConfirmationValidator] }
  );

  public requestResetPasswordForm = this.fb.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
  });

  public MODES = {
    REQUEST: 'REQUEST-RESET',
    SET: 'SET-PASSWORD',
    REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  };
  public currentMode = this.MODES.REQUEST;
  public loading$ = this.userAuthFacade.loading$;
  public errorMessage$ = this.userAuthFacade.errorMessage$;
  public deviceInfo: AllDeviceInfo;

  public isPasswordVisible = false;
  public isPasswordConfirmVisible = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly snackService: SnackbarService,
    private readonly routerFacade: RouterFacade,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(queryParams => {
      this.userAuthFacade.resetErrorMessage();

      const { password_reset_token, requestSuccess } = queryParams;

      if (requestSuccess) {
        this.currentMode = this.MODES.REQUEST_SUCCESS;
        return;
      }

      if (password_reset_token) {
        this.currentMode = this.MODES.SET;
        this.setPasswordForm.patchValue({ password_reset_token });
        return;
      }

      this.currentMode = this.MODES.REQUEST;
      return;
    });
  }

  public requestPasswordReset(): void {
    if (this.requestResetPasswordForm.invalid) {
      return;
    }

    this.userAuthFacade.getPasswordResetLinkSuccess$.pipe(take(1)).subscribe(() => {
      this.snackService.showInfo('RESET_PASSWORD_PAGE.EMAIL_WITH_RESET_LINK_SENT');
      this.routerFacade.changeRoute({
        linkParams: [],
        extras: {
          queryParams: { requestSuccess: true },
        },
      });
    });

    this.userAuthFacade.getPasswordResetLink(this.requestResetPasswordForm.get('email')?.value);
    return;
  }

  public setNewPassword(): void {
    if (this.setPasswordForm.invalid) {
      return;
    }

    const psswdsValid = passwordsValidator(this.setPasswordForm);
    if (psswdsValid !== null) {
      this.setPasswordForm.setErrors(psswdsValid);
      return;
    }

    this.userAuthFacade.setNewPasswordSuccess$.pipe(take(1)).subscribe(() => {
      this.snackService.showInfo('AUTH.NEW-PASSWORD-SET-YOU-CAN-LOGIN');
      this.routerFacade.changeRoute({
        linkParams: ['login'],
      });
    });

    this.errorMessage$.pipe(take(1)).subscribe(errorMessage => {
      if (!errorMessage) {
        return;
      }
      setTimeout(
        () => (this.errorMessageContainer?.nativeElement as HTMLElement)?.scrollIntoView({ behavior: 'smooth' }),
        200
      );
    });
    this.userAuthFacade.setNewPassword(this.setPasswordForm.value);
    return;
  }

  ngOnDestroy(): void {
    this.userAuthFacade.resetErrorMessage();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
