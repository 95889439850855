import { DocumentType, ProcessDocument, UserFile } from './document';
import { User } from './user-auth';

export enum UserAssetType {
  'DOC_TO_REVIEW' = 'DOC_TO_REVIEW',
  'PROFILE_PHOTO' = 'PROFILE_PHOTO',
  'ATTACHMENT_IN_CONVERSATION' = 'ATTACHMENT_IN_CONVERSATION',
  'USER_STORAGE_FILE' = 'USER_STORAGE_FILE',
  'PRE_SURVEY_DOC' = 'PRE_SURVEY_DOC',
  'MAIN_REQUEST_DOCUMENT__LOCAL' = 'MAIN_REQUEST_DOCUMENT__LOCAL',
  'MAIN_REQUEST_DOCUMENT__MOS' = 'MAIN_REQUEST_DOCUMENT__MOS',
  'MAIN_REQUEST_DOCUMENT__INPOL' = 'MAIN_REQUEST_DOCUMENT__INPOL',
  'PURCHASE_DOCUMENT' = 'PURCHASE_DOCUMENT',
}

export interface UserAsset {
  type: UserAssetType;
  fileOriginalName?: string;
  fileName?: string;
  fileSize: number;
  userId: string;
  userProcessId: string | null;

  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface SingleFileUploadResult {
  uploaded: boolean;
  errorMessage: string;
  userAsset?: UserAsset;
}

export interface MultipleFilesUploadResult {
  [key: string]: SingleFileUploadResult;
}

export interface UploadDocumentToReviewOpts {
  userProcessId: string;
  files: { file: File; fileOriginalName: string }[];
}

export interface DownloadDocumentFromReviewOpts {
  userAssetId: string;
  fileOriginalName: string;
}

export interface FileOfDocument {
  file: UserFile;
  type: DocumentType;
  user: Partial<User>;
  documentName: ProcessDocument['name'];
  createdAt: Date;
  updatedAt: Date;
  userProcessId: string;
  userDocumentId: string;
  documentId: string;
}

export interface GetUserAssetsOpts {
  userId: string;
  sortBy: 'name' | 'date';
  sortDir: 'asc' | 'desc';
}

export interface RemoveAssetOpts {
  fileOriginalName: string;
  userAssetId: string;
}
