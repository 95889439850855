import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  City,
  District,
  Municipality,
  ProcessDocument,
  SearchDocument,
  UpdateDocumentEnabled,
  UpdateDocumentsBulk,
  UpdateVoivodeshipOpts,
  Voivodeship,
  VoivodeshipOffice,
} from '@interfaces';
import { environment } from '@environment';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class VoivodeshipsHttpService {
  private API_URL = environment.API_URL;
  private FULL_URL = `${this.API_URL}/voivodeships`;

  constructor(private http: HttpClient) {}

  private createFile(file: File): FormData {
    const data = new FormData();
    const datetime = moment().format('DDMMYYYY_HHmmss_SSS');
    data.append(`${datetime}`, file, file.name);
    return data;
  }

  public getVoivodeshipByZipcode(zipcode: string): Observable<Voivodeship> {
    const url = `${this.FULL_URL}/zipcode/${zipcode}`;
    return this.http.get<Voivodeship>(url);
  }

  public getVoivodeships(): Observable<Voivodeship[]> {
    const url = `${this.FULL_URL}`;
    return this.http.get<Voivodeship[]>(url);
  }

  public updateVoivodeship(voivoId: string, opts: UpdateVoivodeshipOpts): Observable<Voivodeship> {
    const url = `${this.FULL_URL}/${voivoId}`;
    return this.http.put<Voivodeship>(url, opts);
  }

  public addVoivodeshipOffice(opts: VoivodeshipOffice, voivoId: string): Observable<VoivodeshipOffice> {
    const url = `${this.FULL_URL}/${voivoId}/offices`;
    const { id, voivodeshipId, ...body } = opts;
    return this.http.post<VoivodeshipOffice>(url, body);
  }

  public editVoivodeshipOffice(opts: VoivodeshipOffice, voivoId: string): Observable<VoivodeshipOffice> {
    const url = `${this.FULL_URL}/${voivoId}/offices/${opts.id}`;
    const { id, voivodeshipId, ...body } = opts;
    return this.http.patch<VoivodeshipOffice>(url, body);
  }

  public removeVoivodeshipOffice(voivoId: string, officeId: string): Observable<{ success: boolean }> {
    const url = `${this.FULL_URL}/${voivoId}/offices/${officeId}`;
    return this.http.delete<{ success: boolean }>(url);
  }

  public searchVoivodeshipDocuments(opts: SearchDocument): Observable<ProcessDocument[]> {
    const url = `${this.API_URL}/documents/search`;
    const body = { ...opts };
    return this.http.post<ProcessDocument[]>(url, body);
  }

  public updateDocumentEnabled(opts: UpdateDocumentEnabled): Observable<ProcessDocument> {
    const url = `${this.API_URL}/documents/${opts.id}`;
    const body = { enabled: opts.enabled };
    return this.http.patch<ProcessDocument>(url, body);
  }

  public updateDocumentsBulkEnabled(opts: UpdateDocumentsBulk): Observable<ProcessDocument[]> {
    const url = `${this.API_URL}/documents/bulk/update-enabled/${opts.documentType}`;
    const body = [...(opts.documents || [])];
    return this.http.patch<ProcessDocument[]>(url, body);
  }

  public getDistricts(voivoId: string): Observable<District[]> {
    const url = `${this.FULL_URL}/voivodeship/${voivoId}/districts`;
    return this.http.get<District[]>(url);
  }

  public getMunicips(opts: { voivoId: string; districtId: string }): Observable<Municipality[]> {
    const { voivoId, districtId } = opts;
    const url = `${this.FULL_URL}/voivodeship/${voivoId}/district/${districtId}/municipalities`;
    return this.http.get<Municipality[]>(url);
  }

  public getCities(opts: { voivoId: string; districtId: string; municipId: string }): Observable<City[]> {
    const { districtId, municipId, voivoId } = opts;
    const url = `${this.FULL_URL}/voivodeship/${voivoId}/district/${districtId}/municipality/${municipId}/cities`;
    return this.http.get<City[]>(url);
  }

  public downloadVoivodeshipFileInstruction(opts: { voivoKey: string; lang: string }): Observable<any> {
    const { voivoKey, lang } = opts;
    const url = `${this.FULL_URL}/voivodeship/${voivoKey}/file-instruction/${lang}`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public uploadVoivodeshipFileInstruction(opts: {
    voivoKey: string;
    lang: string;
    file: File;
  }): Observable<Voivodeship[]> {
    const { voivoKey, lang, file } = opts;
    const url = `${this.FULL_URL}/voivodeship/${voivoKey}/file-instruction/${lang}`;
    const data = this.createFile(file);
    return this.http.post<Voivodeship[]>(url, data);
  }
}
