import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environment';
import { UserAuthFacade } from '@state/user-auth';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AnalyticsItem, Product, ProductInPurchase, Purchase } from '@interfaces';

declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  private GOOGLE_ANALYTICS_ENABLED = environment.GOOGLE_ANALYTICS_ENABLED;
  private GOOGLE_ANALYTICS_ID = environment.GOOGLE_ANALYTICS_GTAG;
  private GOOGLE_ANALYTICS_PAGE_TITLE = environment.PAGE_TITLE;

  private userID = 'Unknown';
  private userRole = 'Unknown';

  constructor(private userAuthFacade: UserAuthFacade) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public initGoogleAnalytics() {
    if (this.GOOGLE_ANALYTICS_ENABLED) {
      this.userAuthFacade.myself$.pipe(takeUntil(this.destroy$)).subscribe(myself => {
        if (!!myself?.id) {
          this.userID = myself.id;
          this.userRole = myself.role.name;
        } else {
          this.userID = 'Unknown';
          this.userRole = 'Unknown';
        }
      });

      const gtagScript = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.GOOGLE_ANALYTICS_ID;

      const gtagConfigScript = document.createElement('script');
      gtagConfigScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          dataLayer.push(arguments);
        }

        gtag('js', new Date());

        gtag('config', '${this.GOOGLE_ANALYTICS_ID}', {
          'send_page_view': false,
          'debug_mode': true,
        });

        gtag('consent', 'default', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied',
        });
      `;

      document.head.appendChild(gtagScript);
      document.head.appendChild(gtagConfigScript);
    } else {
      this.trackPurchase = (purchase: Purchase | Partial<Purchase>) => {};
      this.trackBeginCheckout = (purchase: Purchase | Partial<Purchase>, code?: string) => {};
      this.trackAddToCart = (purchase: Product) => {};
      this.trackRemoveFromCart = (purchase: Product) => {};
      this.trackViewItem = (purchase: Product) => {};
      this.trackEvent = (eventCategory: string, eventName: string, object?: object) => {};
    }
  }

  public allConsentGranted() {
    if (this.GOOGLE_ANALYTICS_ENABLED) {
      gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    }
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=pl&client_type=gtag#purchase
  public trackPurchase(purchase: Purchase | Partial<Purchase>) {
    const items: AnalyticsItem[] = this.generateItemsObject(purchase.products);
    gtag('event', 'purchase', {
      user_id: this.userID,
      user_role: this.userRole,
      page_title: this.GOOGLE_ANALYTICS_PAGE_TITLE,
      value: purchase.totalAmountPaid / 100,
      currency: 'PLN',
      transaction_id: purchase.id,
      items: items,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=pl&client_type=gtag#begin_checkout
  public trackBeginCheckout(purchase: Purchase | Partial<Purchase>, code?: string) {
    const items: AnalyticsItem[] = this.generateItemsObject(purchase.products);
    gtag('event', 'begin_checkout', {
      user_id: this.userID,
      user_role: this.userRole,
      page_title: this.GOOGLE_ANALYTICS_PAGE_TITLE,
      value: purchase.totalAmountToPay / 100,
      currency: 'PLN',
      coupon: code,
      items: items,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=pl&client_type=gtag#add_to_cart
  public trackAddToCart(purchase: Product) {
    const items: AnalyticsItem[] = this.generateItemsObject([purchase]);
    gtag('event', 'add_to_cart', {
      user_id: this.userID,
      user_role: this.userRole,
      page_title: this.GOOGLE_ANALYTICS_PAGE_TITLE,
      value: purchase.basePrice / 100,
      currency: 'PLN',
      items: items,
    });
  }

  public trackRemoveFromCart(purchase: Product) {
    const items: AnalyticsItem[] = this.generateItemsObject([purchase]);
    gtag('event', 'remove_from_cart', {
      user_id: this.userID,
      user_role: this.userRole,
      page_title: this.GOOGLE_ANALYTICS_PAGE_TITLE,
      value: purchase.basePrice / 100,
      currency: 'PLN',
      items: items,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=pl&client_type=gtag#view_item
  public trackViewItem(product: Product) {
    const items: AnalyticsItem[] = this.generateItemsObject([product]);
    gtag('event', 'view_item', {
      user_id: this.userID,
      user_role: this.userRole,
      page_title: this.GOOGLE_ANALYTICS_PAGE_TITLE,
      value: product.basePrice / 100,
      currency: 'PLN',
      items: items,
    });
  }

  public trackEvent(eventCategory: string, eventName: string, object?: object) {
    gtag('event', eventName, {
      user_id: this.userID,
      user_role: this.userRole,
      page_title: this.GOOGLE_ANALYTICS_PAGE_TITLE,
      event_category: eventCategory,
      event_label: eventName,
      ...object,
    });
  }

  public generateItemsObject(items: ProductInPurchase[] | Product[]): AnalyticsItem[] {
    if (!items) {
      return [];
    }

    return items.map((item: ProductInPurchase | Product) => {
      return {
        item_name: item.name.en,
        price: item.basePrice / 100,
        quantity: 'quantity' in item ? item.quantity : 1,
      };
    });
  }
}
