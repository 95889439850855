import { SupportChat, SupportChatMessage, SupportMessageAttachment, UserAsset } from '@interfaces';

export type ChatState = Readonly<{
  messages: SupportChatMessage[];
  chat: SupportChat;
  errorMessage: string;
  loading: boolean;
  loadingAttachments: boolean;
  messageAttachmentsToAdd: {
    // file.name -> name on the computer of uploader -> we will make sure that no duplicates are allowed
    [key: string]: {
      error: any;
      loading: boolean;
      uploaded: boolean;
      userAsset: UserAsset;
    };
  };
}>;

export const initialState: ChatState = {
  messages: null,
  chat: null,
  errorMessage: null,
  loading: false,
  loadingAttachments: false,
  messageAttachmentsToAdd: {},
};
