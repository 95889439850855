import { Pagination } from '@interfaces';
import { UserAsset } from './user-asset';
import { User } from './user-auth';

export interface SupportMessageAttachment {
  id: string;
  supportChatId: string;
  supportMessageId: string;
  attachmentId: string;

  attachment: UserAsset;
}

export interface SupportChatMessage {
  id: string;
  supportChatId: string;
  authorId: string;

  attachments: SupportMessageAttachment[];
  author: Partial<User>;
  content: string;

  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface SupportChat {
  id: string;

  supportedUser: Partial<User>;
  supportedUserId: string;

  marker: SupportChatMarker | null;
  markedBy: Partial<User> | null;
  markedById: string | null;
  markedAt: Date | string | null;

  readBySupportedUserAt: Date | string | null;
  readByEmployeeAt: Date | string | null;
  lastMessageAt: Date | string | null;

  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface SupportChatInList extends SupportChat {
  hasUnreadMessages: boolean;
}

export enum SupportChatMarker {
  ATTENTION_REQUIRED = 'ATTENTION_REQUIRED',
  ALREADY_HANDLED = 'ALREADY_HANDLED',
  COMEBACK_LATER = 'COMEBACK_LATER',
}

export enum SupportChatsOrder {
  LAST_MESSAGE_DATE = 'LAST_MESSAGE_DATE',
  USER_NAME = 'USER_NAME',
}

export interface FindSupportChatOpts {
  supportedUserId?: string;
  supportChatId?: string;
}

export interface FindSupportChatsOpts extends FindSupportChatOpts, Pagination {
  orderBy: SupportChatsOrder;
  onlyAssignedToMe: boolean;
  supportedUserName?: string;
}

export interface FindSupportMessageOpts {
  id?: string;
  authorId?: string;
  supportChatId?: string;
}

export interface MarkSupportChatOpts {
  supportChatId: string;
  marker: SupportChatMarker | null;
}

export interface CreateSupportMessageOpts {
  supportChatId: string;
  content: string;
  attachments?: string[];
}

export interface CreateMessageInMyChat {
  content: string;
  attachments?: string[];
}

export enum SUPPORT_CHAT_EVENTS {
  NEW_MESSAGE_IN_SUPPORT_CHAT = 'NEW_MESSAGE_IN_SUPPORT_CHAT',
  MESSAGE_EDITED_IN_SUPPORT_CHAT = 'MESSAGE_EDITED_IN_SUPPORT_CHAT',
  MESSAGE_REMOVED_IN_SUPPORT_CHAT = 'MESSAGE_REMOVED_IN_SUPPORT_CHAT',

  FOREIGNER_SENT_MESSAGE_IN_SUPPORT_CHAT = 'FOREIGNER_SENT_MESSAGE_IN_SUPPORT_CHAT',
  EMPLOYEE_ADDED_MARKER_TO_SUPPORT_CHAT = 'EMPLOYEE_ADDED_MARKER_TO_SUPPORT_CHAT',
  EMPLOYEE_MARKED_SUPPORT_CHAT_AS_UNREAD = 'EMPLOYEE_MARKED_SUPPORT_CHAT_AS_UNREAD',
  EMPLOYEE_READ_SUPPORT_CHAT = 'EMPLOYEE_READ_SUPPORT_CHAT',
}

export interface UploadMessageAttachmentsOpts {
  files: { file: File; fileOriginalName: string }[];
}

export interface DownloadMessageAttachmentOpts {
  messageId: string;
  attachmentId: string;
}
