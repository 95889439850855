import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, take, takeUntil } from 'rxjs/operators';

import { AvailableLanguages } from '@constants';
import { ConsumableCountry } from '@interfaces';
import { CommonsFacade } from '@state/commons';
import { ReplaySubject } from 'rxjs';

export interface CountrySelectOption {
  value: string;
  label: string;
}

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
})
export class CountrySelectComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @Input() fieldControl: FormControl;
  @Input() fieldKey: string;
  @Input() fieldLabelKey: string;
  @Input() isRequired: boolean;
  // Array of pseudoId -> you can find those in api -> modules -> common -> countries.ts
  @Input() filterOutIds?: number[];
  @Input() onlyUE?: boolean;

  public countriesList: CountrySelectOption[];
  public currentLang: string;
  private rawCountries: ConsumableCountry[];

  constructor(
    private readonly commonsFacade: CommonsFacade,
    private readonly translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentLang = this.translateService.currentLang;
    if (this.onlyUE) {
      this.rawCountries = await this.commonsFacade
        .getCountriesEU$()
        .pipe(
          take(1),
          map(countriesEU => {
            if (this.filterOutIds?.length) {
              return countriesEU.filter(countriesEU => !this.filterOutIds.includes(countriesEU.id));
            } else {
              return countriesEU;
            }
          })
        )
        .toPromise();
    } else {
      this.rawCountries = await this.commonsFacade
        .getCountries$()
        .pipe(
          take(1),
          map(countries => {
            if (this.filterOutIds?.length) {
              return countries.filter(country => !this.filterOutIds.includes(country.id));
            } else {
              return countries;
            }
          })
        )
        .toPromise();
    }

    this.translateService.onLangChange
      .pipe(startWith({ lang: this.currentLang }), takeUntil(this.destroy$))
      .subscribe(({ lang: langKey }) => {
        const langKeyTyped = langKey as AvailableLanguages;
        this.countriesList = this.rawCountries.map(consumableCountry => ({
          value: consumableCountry.name,
          label: consumableCountry.nameTranslations[langKeyTyped],
        }));
        this.currentLang = langKey;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // control.errors always contains max 1 error at a time
  public getErrorMessage(control: FormControl): string {
    const [validatorName, error]: [string, { errorKey: string; allowedVal: string }] = Object.entries(
      control.errors
    )[0];

    if (validatorName === 'required') {
      return this.translateService.instant(`FORM-VALIDATION.IS_REQUIRED`);
    }
    if (validatorName === 'pattern') {
      return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`);
    }

    return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`, { allowedVal: error.allowedVal });
  }
}
