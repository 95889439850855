import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { AnnexEffects, AnnexFacade, annexReducer, AnnexState } from './annex';
import { ChatEffects, ChatFacade, chatReducer, ChatState } from './chat';
import { CommentsEffects, CommentsFacade, commentsReducer, CommentsState } from './comments';
import { CommonseEffects, CommonsFacade, commonsReducer, CommonsState } from './commons';
import { CompanyEffects, CompanyFacade, companyReducer, CompanyState } from './company';
import {
  InstructionItemsEffects,
  InstructionItemsFacade,
  instructionItemsReducer,
  InstructionItemsState,
} from './instruction-items';
import { ManagementEffects, ManagementFacade, managementReducer, ManagementState } from './management';
import {
  InAppNotificationsEffects,
  InAppNotificationsFacade,
  inAppNotificationsReducer,
  InAppNotificationsState,
} from './notifications';
import { OfficeEffects, OfficeFacade, officeReducer, OfficeState } from './office';
import { ReviewEffects, ReviewFacade, reviewReducer, ReviewState } from './review';
import { RouterEffects, RouterFacade, RouterStateUrl } from './router';
import { SupportChatEffects, SupportChatFacade, supportChatReducer, SupportChatState } from './support-chat';
import {
  SystemSettingsEffects,
  SystemSettingsFacade,
  systemSettingsReducer,
  SystemSettingsState,
} from './system-settings';
import { UserAssetsEffects, UserAssetsFacade, userAssetsReducer, UserAssetsState } from './user-assets';
import { UserAuthEffects, UserAuthFacade, userAuthReducer, UserAuthState } from './user-auth';
import { UserFilesEffects, UserFilesFacade, userFilesReducer, UserFilesState } from './user-files';
import { UserProcessEffects, UserProcessFacade, userProcessReducer, UserProcessState } from './user-process';
import { UsersEffects, UsersFacade, usersReducer, UsersState } from './users';
import {
  VisitsCalendarEffects,
  VisitsCalendarFacade,
  visitsCalendarReducer,
  VisitsCalendarState,
} from './visits-calendar';
import { VoivodeshipsEffects, VoivodeshipsFacade, voivodeshipsReducer, VoivodeshipsState } from './voivodeships';

import { DiscountCodesEffects, DiscountCodesFacade, discountCodesReducer, DiscountCodesState } from './discount-codes';
import { PurchaseEffects, PurchaseFacade, purchaseReducer, PurchaseState } from './purchase';

export const reducers = {
  router: routerReducer,
  userAuth: userAuthReducer,
  company: companyReducer,
  users: usersReducer,
  userProcess: userProcessReducer,
  commons: commonsReducer,
  management: managementReducer,
  comments: commentsReducer,
  systemSettings: systemSettingsReducer,
  inAppNotifications: inAppNotificationsReducer,
  chat: chatReducer,
  voivodeships: voivodeshipsReducer,
  visitsCalendar: visitsCalendarReducer,
  instructionItems: instructionItemsReducer,
  office: officeReducer,
  review: reviewReducer,
  annex: annexReducer,
  supportChat: supportChatReducer,
  userFiles: userFilesReducer,
  userAssets: userAssetsReducer,
  purchase: purchaseReducer,
  discountCodes: discountCodesReducer,
};

export const effects = [
  RouterEffects,
  UserAuthEffects,
  CompanyEffects,
  UsersEffects,
  UserProcessEffects,
  CommonseEffects,
  ManagementEffects,
  CommentsEffects,
  SystemSettingsEffects,
  InAppNotificationsEffects,
  ChatEffects,
  VoivodeshipsEffects,
  VisitsCalendarEffects,
  InstructionItemsEffects,
  OfficeEffects,
  ReviewEffects,
  AnnexEffects,
  SupportChatEffects,
  UserFilesEffects,
  UserAssetsEffects,
  PurchaseEffects,
  DiscountCodesEffects,
];

export const facades = [
  RouterFacade,
  UserAuthFacade,
  CompanyFacade,
  UsersFacade,
  UserProcessFacade,
  CommonsFacade,
  ManagementFacade,
  CommentsFacade,
  SystemSettingsFacade,
  InAppNotificationsFacade,
  ChatFacade,
  VoivodeshipsFacade,
  VisitsCalendarFacade,
  InstructionItemsFacade,
  OfficeFacade,
  ReviewFacade,
  AnnexFacade,
  SupportChatFacade,
  UserFilesFacade,
  UserAssetsFacade,
  PurchaseFacade,
  DiscountCodesFacade,
];

export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  userAuth: UserAuthState;
  company: CompanyState;
  users: UsersState;
  userProcess: UserProcessState;
  commons: CommonsState;
  management: ManagementState;
  comments: CommentsState;
  systemSettings: SystemSettingsState;
  inAppNotifications: InAppNotificationsState;
  chat: ChatState;
  voivodeships: VoivodeshipsState;
  visitsCalendar: VisitsCalendarState;
  instructionItems: InstructionItemsState;
  office: OfficeState;
  review: ReviewState;
  annex: AnnexState;
  supportChat: SupportChatState;
  userFiles: UserFilesState;
  userAssets: UserAssetsState;
  purchase: PurchaseState;
  discountCodes: DiscountCodesState;
}
