import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AppState } from '@state';
import { DocumentComment } from '@interfaces';
import * as commentsActions from './comments.actions';
import * as commentsSelectors from './comments.selectors';

@Injectable()
export class CommentsFacade {
  public documentComments$ = this.store.select(commentsSelectors.selectDocumentComments);
  public loadingComments$ = this.store.select(commentsSelectors.selectLoadingComments);
  public errorMessage$ = this.store.select(commentsSelectors.selectErrorMessage);

  public addCommentSuccess$ = this.actions.pipe(ofType(commentsActions.addDocumentCommentSuccess));
  public editCommentSuccess$ = this.actions.pipe(ofType(commentsActions.editDocumentCommentSuccess));
  public getCommentsSuccess$ = this.actions.pipe(ofType(commentsActions.getDocumentCommentsSuccess));

  public markDocumentCommentsAsReadSuccess$ = this.actions.pipe(
    ofType(commentsActions.markDocumentCommentsAsReadSuccess)
  );

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getDocumentComments(userProcessId: string, userDocumentId: string): void {
    this.store.dispatch(commentsActions.getDocumentComments({ userDocumentId, userProcessId }));
  }

  public getDocumentComments$(userProcessId: string, userDocumentId: string): Observable<DocumentComment[]> {
    return this.store.select(commentsSelectors.selectDocumentComments).pipe(
      tap(documentComments => {
        // always fetch new!
        this.getDocumentComments(userProcessId, userDocumentId);
      }),
      filter(documentComments => {
        if (documentComments?.userDocumentId !== userDocumentId) {
          return false;
        }
        if (documentComments?.comments === null) {
          return false;
        }
        return true;
      }),
      map(documentComments => documentComments.comments)
    );
  }

  public addDocumentComment(comment: DocumentComment, userProcessId: string): void {
    this.store.dispatch(commentsActions.addDocumentComment({ comment, userProcessId }));
  }

  public editDocumentComment(comment: DocumentComment, userProcessId: string): void {
    this.store.dispatch(commentsActions.editDocumentComment({ comment, userProcessId }));
  }

  public removeDocumentComment(comment: DocumentComment, userProcessId: string): void {
    this.store.dispatch(commentsActions.removeDocumentComment({ comment, userProcessId }));
  }

  public markDocumentCommentsAsRead(userProcessId: string, userDocumentId: string): void {
    this.store.dispatch(commentsActions.markDocumentCommentsAsRead({ userDocumentId, userProcessId }));
  }
}
