import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { fullDateFormat, monthsKeys } from '@constants';
import * as moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerHeaderComponent<D> implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public monthsKeys = monthsKeys;
  monthIndexDisabled = [false, false, false, false, false, false, false, false, false, false, false, false];

  public years: number[];

  public selectedMonth: string;
  public selectedYear: number;

  private minDateAvailable: moment.Moment;
  private maxDateAvailable: moment.Moment;

  constructor(
    private readonly calendar: MatCalendar<moment.Moment>,
    private readonly dateAdapter: DateAdapter<moment.Moment>,
    @Inject(MAT_DATE_FORMATS) private readonly dateFormats: MatDateFormats,
    private readonly cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroy$)).subscribe(() => cdr.markForCheck());
  }

  ngOnInit(): void {
    let date = this.calendar.selected as unknown as moment.Moment;
    if (!date) {
      date = moment();
    }

    this.minDateAvailable = moment().subtract(70, 'years');
    if (this.calendar.minDate) {
      this.minDateAvailable = moment(this.calendar.minDate);
    }

    this.maxDateAvailable = moment().add(3, 'years');
    if (this.calendar.maxDate) {
      this.maxDateAvailable = moment(this.calendar.maxDate);
    }

    this.selectedMonth = monthsKeys[Number(date.get('month'))];
    this.selectedYear = date.get('y');
    this.years = this.generateYears(this.minDateAvailable.get('year'), this.maxDateAvailable.get('year'));

    this.calendar.activeDate = moment(date.format(fullDateFormat), fullDateFormat);
    this.markDisabledMonths();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private markDisabledMonths(): void {
    const activeDateYear = this.selectedYear;
    const maxYear = this.maxDateAvailable.get('year');

    if (activeDateYear < maxYear) {
      this.monthIndexDisabled = this.monthIndexDisabled.map((val, monthIndex) => false);
      return;
    }

    if (activeDateYear === maxYear) {
      const maxMonth = this.maxDateAvailable.get('month');
      this.monthIndexDisabled = this.monthIndexDisabled.map((val, monthIndex) => monthIndex > maxMonth);
      return;
    }

    if (activeDateYear > maxYear) {
      this.monthIndexDisabled = this.monthIndexDisabled.map((val, monthIndex) => true);
      return;
    }
  }

  private generateYears(minYear: number, maxYear: number): number[] {
    const years: number[] = [];
    for (let i = minYear; i <= maxYear; i++) {
      years.push(i);
    }
    return years;
  }

  public yearChanged(year: number): void {
    const selected = this.calendar?.selected as any;
    const activeDate = this.calendar?.activeDate as any;
    let momentDate = moment();

    if (activeDate) {
      momentDate = moment(activeDate);
    }
    if (selected) {
      momentDate = moment(selected);
    }
    momentDate.set('y', year);

    this.calendar.activeDate = moment(momentDate.format(fullDateFormat), fullDateFormat);
    this.markDisabledMonths();
  }

  public monthChanged(monthKey: string): void {
    const monthIndex = this.monthsKeys.findIndex(m => m === monthKey);
    const selected = this.calendar?.selected;
    const activeDate = this.calendar?.activeDate;
    let momentDate = moment() as any;

    if (activeDate) {
      momentDate = activeDate;
    }
    if (selected) {
      momentDate = selected;
    }

    momentDate.set('M', monthIndex);
    this.calendar.activeDate = moment(momentDate.format(fullDateFormat), fullDateFormat);
  }

  public previousClicked(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    this.selectedMonth = this.monthsKeys[this.calendar.activeDate.get('M')];
    this.selectedYear = this.calendar.activeDate.get('y');
  }

  public nextClicked(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
    this.selectedMonth = this.monthsKeys[this.calendar.activeDate.get('M')];
    this.selectedYear = this.calendar.activeDate.get('y');
  }
}
