import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { patterns } from '@constants';
import { FamilyMemberInPoland } from '@interfaces';
import { customDateValidator, isBooleanValidator, isIntegerValidator, isPeselValid } from '@shared/custom-validators';

export const getPersonalDataFormGroupOptsTempPremit = (fb: FormBuilder): FormGroup => {
  return fb.group({
    step1: fb.group(
      {
        name: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.pattern(patterns.PLNameValidator),
          ]),
        ],
        surname: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.pattern(patterns.PLNameValidator),
          ]),
        ],
        previousSurname: [
          null,
          Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLNameValidator)]),
        ],
        familyName: [
          null,
          Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLNameValidator)]),
        ],
        previousName: [
          null,
          Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLNameValidator)]),
        ],
        motherName: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.pattern(patterns.PLNameValidator),
          ]),
        ],
        motherSurname: [
          null,
          Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLNameValidator)]),
        ],
        motherMaidenName: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.pattern(patterns.PLNameValidator),
          ]),
        ],
        fatherName: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.pattern(patterns.PLNameValidator),
          ]),
        ],
        fatherSurname: [
          null,
          Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLNameValidator)]),
        ],
        dateOfBirth: [null, Validators.compose([Validators.required, customDateValidator])],
        sex: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(200)])],
        passportNumber: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(200),
            Validators.pattern(patterns.PLTextValidator),
          ]),
        ],
        placeOfBirth: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.pattern(patterns.PLTextValidator),
          ]),
        ],
        countryOfBirth: [null, Validators.compose([Validators.required, Validators.maxLength(200)])],
        citizenship: [null, Validators.compose([Validators.required, Validators.maxLength(200)])],
        nationality: [null, Validators.compose([Validators.required, Validators.maxLength(200)])],
      },
      { validators: [] }
    ),
    step2: fb.group({
      maritalStatus: [
        null,
        Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLTextValidator)]),
      ],
      education: [null, Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLTextValidator)])],
      descriptionHeight: [null, Validators.compose([Validators.required, isIntegerValidator({ min: 50, max: 300 })])],
      descriptionColourOfEyes: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(1000),
          Validators.pattern(patterns.PLTextValidator),
        ]),
      ],
      descriptionSpecialMarks: [
        null,
        Validators.compose([Validators.maxLength(1000), Validators.pattern(patterns.PLTextValidator)]),
      ],
      pesel: [
        null,
        Validators.compose([
          Validators.minLength(11),
          Validators.maxLength(11),
          (control: AbstractControl): ValidationErrors | null => {
            // we dont care about empty values here - required validator is for that
            if (!control.value) {
              return null;
            }
            const isPESELValid = isPeselValid(control.value);
            if (!isPESELValid) {
              return { wrongPesel: true } as ValidationErrors;
            }

            return null;
          },
        ]),
      ],
      telephoneNumber: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern(patterns.PhoneNumberValidator),
        ]),
      ],
      residenceCity: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.pattern(patterns.PLTextValidator),
        ]),
      ],
      residenceStreet: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.pattern(patterns.PLTextValidator),
        ]),
      ],
      residenceHouseNumber: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.pattern(patterns.PLTextValidator),
        ]),
      ],
      // residenceVoivodeshipName: [null, Validators.compose([
      //   Validators.required,
      //   Validators.maxLength(200),
      //   Validators.pattern(patterns.PLTextValidator),
      // ])],
      residenceCityListedId: [
        null,
        Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLTextValidator)]),
      ],
      residenceMunicipalityId: [
        null,
        Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLTextValidator)]),
      ],
      residenceDistrictId: [
        null,
        Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLTextValidator)]),
      ],
      residenceVoivodeshipDetailsId: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.pattern(patterns.PLTextValidator),
        ]),
      ],
      residenceApartmentNumber: [
        null,
        Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLTextValidator)]),
      ],
      residencePostalCode: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(6),
          Validators.minLength(5),
          Validators.pattern(patterns.ZipValidator),
        ]),
      ],
    }),
    step3: fb.group({
      meansOfSubsistence: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(1000),
          Validators.pattern(patterns.PLTextValidator),
        ]),
      ],
      meansOfSubsistenceSavingsAmount: [
        null,
        Validators.compose([
          Validators.maxLength(200),
          // Validators.pattern(patterns.PLNameValidator),
        ]),
      ],
      medicalInsurance: [
        null,
        Validators.compose([
          Validators.maxLength(1000),
          // Validators.pattern(patterns.PLTextValidator),
        ]),
      ],
      familyMembersOutsidePoland: [null, Validators.compose([Validators.required, isBooleanValidator])],
      applyingForExperience: [
        null,
        Validators.compose([
          Validators.maxLength(2000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],
      currentlyDetained: [null, Validators.compose([Validators.required, isBooleanValidator])],
      inGuardedCentre: [null, Validators.compose([isBooleanValidator])],
      inDetentionCentre: [null, Validators.compose([isBooleanValidator])],
      hasBanOnLeavingCountry: [null, Validators.compose([isBooleanValidator])],
      hasSentenceOfImprisonment: [null, Validators.compose([isBooleanValidator])],
      temporarilyArrested: [null, Validators.compose([isBooleanValidator])],

      inGuardedCentreComment: [
        null,
        Validators.compose([
          Validators.maxLength(2000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],
      inDetentionCentreComment: [
        null,
        Validators.compose([
          Validators.maxLength(2000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],
      hasBanOnLeavingCountryComment: [
        null,
        Validators.compose([
          Validators.maxLength(2000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],
      hasSentenceOfImprisonmentComment: [
        null,
        Validators.compose([
          Validators.maxLength(2000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],
      temporarilyArrestedComment: [
        null,
        Validators.compose([
          Validators.maxLength(2000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],

      wasSentencedInPoland: [null, Validators.compose([Validators.required, isBooleanValidator])],
      sentenceReason: [
        null,
        Validators.compose([
          Validators.maxLength(1000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],
      currentlySubjectOfProceedings: [null, Validators.compose([Validators.required, isBooleanValidator])],
      proceedingsReason: [
        null,
        Validators.compose([
          Validators.maxLength(1000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],
      liabilitiesFromVerdicts: [null, Validators.compose([Validators.required, isBooleanValidator])],
      liabilitiesDescription: [
        null,
        Validators.compose([
          Validators.maxLength(1000),
          // Validators.pattern(patterns.LatinAndCyrylicText),
        ]),
      ],
      // applyingForCompanyName: [null, Validators.compose([
      //   Validators.maxLength(1000),
      //   Validators.pattern(patterns.PLTextValidator),
      // ])],
      // applyingForJobTitle: [null, Validators.compose([
      //   Validators.maxLength(1000),
      //   Validators.pattern(patterns.PLTextValidator),
      // ])],
    }),
    step4: fb.group(
      {
        inPolandAtApplicationSubmition: [null, Validators.compose([Validators.required, isBooleanValidator])],
        currentlyLivingInPoland: [
          null,
          Validators.compose([
            // -- at start it should not be required
            // -- it will change when user changes values of currentlyLivingInPoland
            // Validators.required,
            isBooleanValidator,
          ]),
        ],
        legalBasisOfStay: [
          null,
          Validators.compose([
            // -- at start it should not be required
            // -- it will change when user changes values of currentlyLivingInPoland
            // Validators.required,
            // Validators.pattern(patterns.PLTextValidator)
            Validators.maxLength(1000),
          ]),
        ],
        legalBasisOfStayComment: ['Dowód tożsamości', Validators.compose([Validators.maxLength(1000)])],
        residenceVisaExpiryDate: [
          null,
          Validators.compose([
            // -- at start it should not be required
            // -- it will change when user changes values of currentlyLivingInPoland
            // Validators.required,
            customDateValidator,
          ]),
        ],
        lastEntryDate: [null, Validators.compose([customDateValidator])],
        lastYearEmploymentDate: [
          null,
          Validators.compose([Validators.maxLength(1000), Validators.pattern(patterns.PLTextValidator)]),
        ],
        noPreviousVisits: [null, Validators.compose([Validators.required, isBooleanValidator])],

        // previousVisits: [null, Validators.compose([
        //   Validators.maxLength(1000),
        //   Validators.pattern(patterns.PLTextValidator),
        // ])],
        previousVisitsArr: [[]],

        noLastTravelsOutsidePoland: [null, Validators.compose([Validators.required, isBooleanValidator])],
        lastTravelsOutsidePoland: [
          null,
          Validators.compose([
            Validators.maxLength(5000),
            // Validators.pattern(patterns.PLTextValidator),
          ]),
        ],
        // speakingLanguages: [null, Validators.compose([
        //   Validators.maxLength(1000),
        //   Validators.pattern(patterns.PLTextValidator),
        // ])],
      },
      { validators: [step4GroupValidator, step4GroupValidatorDates] }
    ),
    step5: fb.group(
      {
        noFamilyMembersInPoland: [null, Validators.compose([Validators.required, isBooleanValidator])],
        familyMembersInPoland: [[]],
      },
      { validators: [step5GroupValidator] }
    ),
  });
};

const requiredFamilyMembesKeys: (keyof FamilyMemberInPoland)[] = [
  'name',
  'surname',
  'sex',
  'dateOfBirth',
  'kinshipDegree',
  'citizenship',
  'residencePlace',
  'tempPermitApplying',
  'dependentOnTheForeigner',
];
export const getFamilyMemberFormGroup = (fb: FormBuilder): FormGroup => {
  return fb.group({
    name: [
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(200),
        Validators.pattern(patterns.PLNameValidator),
      ]),
    ],
    surname: [
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(200),
        Validators.pattern(patterns.PLNameValidator),
      ]),
    ],
    sex: [
      null,
      Validators.compose([Validators.required, Validators.maxLength(20), Validators.pattern(patterns.PLNameValidator)]),
    ],
    dateOfBirth: [null, Validators.compose([Validators.required, Validators.maxLength(20), customDateValidator])],
    kinshipDegree: [
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(200),
        Validators.pattern(patterns.PLNameValidator),
      ]),
    ],
    citizenship: [
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(200),
        Validators.pattern(patterns.PLNameValidator),
      ]),
    ],
    residencePlace: [
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(200),
        Validators.pattern(patterns.PLNameValidator),
      ]),
    ],
    tempPermitApplying: [
      null,
      Validators.compose([Validators.required, Validators.maxLength(3), Validators.pattern(patterns.PLNameValidator)]),
    ],
    dependentOnTheForeigner: [
      null,
      Validators.compose([Validators.required, Validators.maxLength(3), Validators.pattern(patterns.PLNameValidator)]),
    ],
  });
};

export const step4GroupValidator = (ac: AbstractControl): ValidationErrors | null => {
  const formGroup = ac as FormGroup;
  const noLastTravelsOutsidePoland = formGroup.controls.noLastTravelsOutsidePoland.value;
  const lastTravelsOutsidePoland = formGroup.controls.lastTravelsOutsidePoland.value;
  const noPreviousVisits = formGroup.controls.noPreviousVisits.value;
  const previousVisitsArr = formGroup.controls.previousVisitsArr.value;
  const legalBasisOfStay = formGroup.controls.legalBasisOfStay.value;
  const legalBasisOfStayComment = formGroup.controls.legalBasisOfStayComment.value;

  if (
    !noLastTravelsOutsidePoland &&
    lastTravelsOutsidePoland?.length &&
    !noPreviousVisits &&
    previousVisitsArr?.length
  ) {
    return null;
  }
  if (!noLastTravelsOutsidePoland && !lastTravelsOutsidePoland?.length) {
    return {
      lastTravelsOutsidePoland: 'No items in lastTravelsOutsidePoland AND noLastTravelsOutsidePoland is unchecked',
    };
  }
  if (!noPreviousVisits && !previousVisitsArr?.length) {
    return { previousVisitsArr: 'No items in previousVisitsArr AND noPreviousVisits is unchecked' };
  }

  if (legalBasisOfStay === 'inna podstawa' && !legalBasisOfStayComment?.length) {
    return { legalBasisOfStayComment: 'Comment for other legal basis of stay is required' };
  }

  return null;
};

export const step4GroupValidatorDates = (ac: AbstractControl): ValidationErrors | null => {
  const formGroup = ac as FormGroup;
  const lastEntryDate = formGroup.controls.lastEntryDate.value;
  const residenceVisaExpiryDate = formGroup.controls.residenceVisaExpiryDate.value;

  if (lastEntryDate && residenceVisaExpiryDate) {
    const lastEntryDateObj = new Date(lastEntryDate);
    const residenceVisaExpiryDateObj = new Date(residenceVisaExpiryDate);

    if (lastEntryDateObj > residenceVisaExpiryDateObj) {
      formGroup.get('residenceVisaExpiryDate')?.setErrors({ visaExpiredBeforeLastEntryDate: true });
      return { VISA_EXPIRED_BEFORE_LAST_ENTRY_DATE: true };
    } else {
      formGroup.get('residenceVisaExpiryDate')?.setErrors(null);
    }
  }

  return null;
};

export const step5GroupValidator = (ac: AbstractControl): ValidationErrors | null => {
  const formGroup = ac as FormGroup;
  const noFamilyMembersInPoland = formGroup.controls.noFamilyMembersInPoland.value;
  const familyMembersInPoland: Partial<FamilyMemberInPoland>[] = formGroup.controls.familyMembersInPoland.value;

  // if user checked noFamilyMembersInPoland - everything ok
  if (noFamilyMembersInPoland === true) {
    return null;
  }

  // if user un-checked noFamilyMembersInPoland and there are members added - Validate each familyMember
  if (!noFamilyMembersInPoland && familyMembersInPoland?.length) {
    const isValid = familyMembersInPoland.every(familyMember => {
      return requiredFamilyMembesKeys.every(key => {
        return familyMember[key] !== null && familyMember[key] !== undefined && familyMember[key] !== '';
      });
    });
    return isValid ? null : { familyMembersInPoland: 'INVALID_MEMBERS_DATA' };
  }

  if (noFamilyMembersInPoland === true && familyMembersInPoland?.length) {
    return { familyMembersInPoland: 'Checked noFamilyMembersInPoland but familyMembersInPoland contains items' };
  }

  if (noFamilyMembersInPoland === false && !familyMembersInPoland?.length) {
    return { familyMembersInPoland: 'No items in familyMembersInPoland AND noFamilyMembersInPoland is unchecked' };
  }

  return null;
};
