import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ReplaySubject, Subscription, merge } from 'rxjs';
import { debounceTime, map, take, takeUntil, tap } from 'rxjs/operators';

import { AvailableLanguages, fullDateFormat } from '@constants';
import { FamilyMemberInPoland } from '@interfaces';
import { DatepickerHeaderComponent } from '@shared/datepicker-header/datepicker-header.component';
import { getFamilyMemberFormGroup } from '@shared/personal-data-form/TEMP_PREMIT/form-fields.config';
import { ManagementFacade } from '@state/management';
import { UserProcessFacade } from '@state/user-process';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

export interface GroupStatus {
  showLoading: boolean;
  showSaved: boolean;
  showError: boolean;
}
@Component({
  selector: 'app-family-members-form-long-resi',
  templateUrl: './family-members-form-long-resi.component.html',
  styleUrls: ['./family-members-form-long-resi.component.scss'],
})
export class FamilyMembersFormLongResiComponent implements OnInit, OnDestroy {
  @ViewChild('mainMembersWrapper') mainWrapper: ElementRef;
  @Input() fieldControl: FormControl;
  @Output() lastFieldTabPressed: EventEmitter<Event> = new EventEmitter<Event>();

  public confirmModal?: NzModalRef;

  public membersArray: FormGroup[] = [];
  public currentLang: AvailableLanguages;
  public selectsValues: { [key: string]: { label: string; value: string }[] };

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public savedSuccess$ = merge(
    this.userProcessFacade.updatePersonalDetailsSuccess$,
    this.managementFacade.updatePersonalDetailsSuccess$
  );

  public savedError$ = merge(
    this.userProcessFacade.updatePersonalDetailsError$,
    this.managementFacade.updatePersonalDetailsError$
  );

  public groupOnValueChangeSubscriptions: Subscription[] = [];
  public groupStatus: GroupStatus[] = [];
  public currentDate = moment();
  public pickerHeader = DatepickerHeaderComponent;
  public minValidDate = moment().subtract(125, 'years');
  public maxValidDate = moment().subtract(1, 'day');

  constructor(
    private readonly fb: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly userProcessFacade: UserProcessFacade,
    private readonly managementFacade: ManagementFacade,
    private modal: NzModalService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang as AvailableLanguages;
    this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
      this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;
    });

    const members: FamilyMemberInPoland[] = this.fieldControl.value;

    members?.forEach((member: FamilyMemberInPoland) => {
      this.addNewMember(member);
    });

    // When no members - initialize form with 1 empty group
    if (this.membersArray.length === 0) {
      this.addNewMember();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getControl(name: string, groupIndex: number): FormControl {
    return this.membersArray[groupIndex].get(name) as FormControl;
  }

  public getNewMemberGroup(memberData?: FamilyMemberInPoland): {
    group: FormGroup;
    groupSubscription: Subscription;
    groupStatus: GroupStatus;
  } {
    const group = getFamilyMemberFormGroup(this.fb);
    const groupStatus: GroupStatus = { showLoading: false, showSaved: false, showError: false };

    if (memberData) {
      group.patchValue(memberData);
      group.get('dateOfBirth').setValue(moment(memberData.dateOfBirth, fullDateFormat));
      group.markAsTouched();
      group.markAsDirty();
      groupStatus.showSaved = group.valid;
      groupStatus.showError = false;
      groupStatus.showLoading = false;
    }

    // In Form for EU long resident we are strictly asking:
    // "Czy masz członków najbliższej rodziny, którzy SĄ NA TWOIM UTRZYMANIU",
    // so if any family member will be inserted in this form, he/she will definitely be dependent on the foreigner.
    // we have to set this manually because we do not display this select to the user!
    group.get('dependentOnTheForeigner').setValue('Tak');

    const groupSubscription = group.valueChanges
      .pipe(
        // skip(1),
        debounceTime(500),
        tap(() => (groupStatus.showSaved = false)),
        // filter(() => {
        //   const isGroupValid = group.valid;
        //   return isGroupValid;
        // }),
        // filter(() => {
        //   const containsNulls = Object.values(group.value).some(val => val === null || val === '');
        //   return containsNulls === false;
        // }),
        map(groupValue => {
          Object.entries(groupValue).forEach(([key, value]) => {
            const val = value as any;
            if (val?._isAMomentObject) {
              groupValue[key] = val.format(fullDateFormat);
            }
          });
          return groupValue;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        groupStatus.showLoading = true;
        groupStatus.showSaved = false;
        groupStatus.showError = false;
        this.updateMainMembersArray(groupStatus);
      });

    return { group, groupSubscription, groupStatus };
  }

  public updateMainMembersArray(groupStatus?: GroupStatus): void {
    if (groupStatus) {
      this.savedSuccess$.pipe(take(1)).subscribe(() => {
        groupStatus.showLoading = false;
        groupStatus.showError = false;
        groupStatus.showSaved = true;
      });
      this.savedError$.pipe(take(1)).subscribe(() => {
        groupStatus.showLoading = false;
        groupStatus.showError = true;
        groupStatus.showSaved = false;
      });
    }
    // updating original array inside main personal-details form
    // making sure that we're taking only valid groups
    const membersArray: Partial<FamilyMemberInPoland>[] = this.membersArray
      .filter(fg => fg !== null && !!Object.keys(fg.value)?.length)
      .map(formGroup => {
        // const containsNulls = Object.values(formGroup.value).some(val => val === null || val === '');
        // if (!formGroup.valid || containsNulls) { return null; }

        const withoutNullsAndEmpty: Partial<FamilyMemberInPoland> = {};
        const groupValue: Record<string, any> = formGroup.value;
        Object.entries(groupValue).forEach(([key, val]) => {
          if (val?._isAMomentObject) {
            groupValue[key] = val.format(fullDateFormat);
          }

          if (groupValue[key] !== null && groupValue[key] !== '') {
            (withoutNullsAndEmpty as any)[key] = groupValue[key];
          }
        });

        return withoutNullsAndEmpty;
      });

    // User should mark this intentionally - no automatic ticking this box
    // if (membersArray.length === 0) {
    //   this.noFamilyMembersInPoland.setValue(true, { emitEvent: false });
    // }

    this.fieldControl.setValue([...membersArray]);
  }

  public addNewMember(memberData?: FamilyMemberInPoland): void {
    const { group, groupSubscription, groupStatus } = this.getNewMemberGroup(memberData ? { ...memberData } : null);
    this.membersArray.push(group);
    this.groupOnValueChangeSubscriptions.push(groupSubscription);
    this.groupStatus.push(groupStatus);

    setTimeout(() => {
      const groupHtmlId = `#formGroupWrapper-${this.membersArray.length - 1}`;
      const groupElem = (this.mainWrapper.nativeElement as HTMLElement).querySelector(groupHtmlId);
      groupElem.scrollIntoView({ behavior: 'smooth', block: 'center' });

      if (!memberData) {
        Object.keys(group.controls).forEach(key => {
          group.controls[key].setErrors(null);
        });
      } else {
        Object.keys(group.controls).forEach(key => {
          group.controls[key].markAsTouched();
          group.controls[key].markAsDirty();
        });
      }
    });
  }

  public removeMember(formGroupIndex: number): void {
    this.membersArray.splice(formGroupIndex, 1);
    const sub = this.groupOnValueChangeSubscriptions[formGroupIndex];
    sub.unsubscribe();
    this.groupOnValueChangeSubscriptions.splice(formGroupIndex, 1);
    this.updateMainMembersArray();
    this.groupStatus.splice(formGroupIndex, 1);
  }

  // control.errors always contains max 1 error at a time
  // assumes that control.invalid === true and errors are {}
  public getErrorMessage(control: AbstractControl): string {
    const [validatorName, error]: [string, { errorKey: string; allowedVal: string }] = Object.entries(
      control.errors
    )[0];

    if (validatorName === 'required') {
      return this.translateService.instant(`FORM-VALIDATION.IS_REQUIRED`);
    }
    if (validatorName === 'pattern') {
      return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`);
    }

    return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`, { allowedVal: error.allowedVal });
  }

  showConfirm(formGroupIndex: number): void {
    const { name, surname } = this.membersArray[formGroupIndex].value;
    this.confirmModal = this.modal.confirm({
      nzTitle: this.translateService.instant('FAMILY_MEMBERS_FORM.CONFIRM_DELETE'),
      nzContent: (name || '') + ' ' + (surname || ''),
      nzOkText: this.translateService.instant('COMMON.YES'),
      nzCancelText: this.translateService.instant('COMMON.NO'),
      nzOnOk: () => {
        this.removeMember(formGroupIndex);
      },
    });
  }
}
