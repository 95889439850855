import { Injectable } from '@angular/core';
import { UrlTree, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { UserProcessStatus } from '@interfaces';
import { UserProcessFacade } from '@state/user-process';

@Injectable()
export class DocumentsGuard implements CanActivate {
  constructor(
    private userProcessFacade: UserProcessFacade,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.userProcessFacade.myProcess$.pipe(
      filter(myProcess => !!myProcess?.id),
      take(1),
      switchMap(userProcess => {
        const allowedStatuses = route.data?.allowedUserProcessStatuses as UserProcessStatus[];

        if (!allowedStatuses.includes(userProcess?.status)) {
          return of(this.router.createUrlTree(['/user-process']));
        }
        const currVoivoId = userProcess.personalDetails.residenceVoivodeshipDetailsId;

        return this.userProcessFacade.documentsList$.pipe(
          tap(documentsList => {
            if (!documentsList) {
              this.userProcessFacade.getListOfDocuments(userProcess.id);
              return;
            }

            const documentInWrongVoivo = !!documentsList.processDocs.find(
              procDoc => procDoc.voivodeshipId !== currVoivoId
            );
            if (documentInWrongVoivo) {
              this.userProcessFacade.getListOfDocuments(userProcess.id);
            }
          }),
          filter(documentsList => {
            if (!documentsList) {
              return false;
            }

            const documentInWrongVoivo = !!documentsList.processDocs.find(
              procDoc => procDoc.voivodeshipId !== currVoivoId
            );
            return !documentInWrongVoivo;
          }),
          take(1),
          map(documentsList => !!documentsList?.processDocs)
        );
      })
    );
  }
}
