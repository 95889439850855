import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import {
  InAppNotification,
  InAppNotificationType,
  MaintenanceInfo,
  MarkAllOfTypeAsReadOpts,
  PaginatedResponse,
  Pagination,
} from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationsHttpService {
  private API_URL = environment.API_URL;
  private FULLPATH = `${this.API_URL}/in-app-notifications`;

  constructor(private http: HttpClient) {}

  public getUnreadCount(): Observable<{ unreadCount: number }> {
    const url = `${this.FULLPATH}/my/unreadCount`;
    return this.http.get<{ unreadCount: number }>(url);
  }

  public getUnreadCountOfType(
    notificationType: InAppNotificationType,
    relatedForeignerId?: string
  ): Observable<{ unreadCount: number }> {
    const url = `${this.FULLPATH}/my/unreadCountOfType`;
    const body = { notificationType } as any;
    if (relatedForeignerId) {
      body.relatedForeignerId = relatedForeignerId;
    }
    return this.http.post<{ unreadCount: number }>(url, body);
  }

  public getMyShortlist(showOnlyUnread: boolean): Observable<PaginatedResponse<InAppNotification>> {
    const url = `${this.FULLPATH}/my/shortlist`;
    return this.http.get<PaginatedResponse<InAppNotification>>(url, { params: { showOnlyUnread } });
  }

  public getMyShortlistMore(
    showOnlyUnread: boolean,
    pagination: Pagination
  ): Observable<PaginatedResponse<InAppNotification>> {
    const url = `${this.FULLPATH}/my/shortlist/more`;
    const { all, count, ...correctPagination } = pagination;
    return this.http.post<PaginatedResponse<InAppNotification>>(
      url,
      { ...correctPagination },
      { params: { showOnlyUnread } }
    );
  }

  public markAsRead(notificationsIds: string[]): Observable<InAppNotification[]> {
    const url = `${this.FULLPATH}/my/markAsRead`;
    return this.http.patch<InAppNotification[]>(url, { notificationsIds });
  }

  public markAsUnread(notificationsIds: string[]): Observable<InAppNotification[]> {
    const url = `${this.FULLPATH}/my/markAsUnread`;
    return this.http.patch<InAppNotification[]>(url, { notificationsIds });
  }

  public markAllOfTypeAsRead(opts: MarkAllOfTypeAsReadOpts): Observable<{ unreadCount: number }> {
    const url = `${this.FULLPATH}/my/markAllOfTypeAsRead`;
    const body = { ...opts };
    return this.http.patch<{ unreadCount: number }>(url, body);
  }

  public markAllAsRead(): Observable<PaginatedResponse<InAppNotification>> {
    const url = `${this.FULLPATH}/my/markAllAsRead`;
    return this.http.patch<any>(url, {});
  }

  public setMaintenanceInfo(opts: MaintenanceInfo): Observable<MaintenanceInfo> {
    const url = `${this.FULLPATH}/maintenance-info`;
    const body = { ...opts };
    return this.http.post<MaintenanceInfo>(url, body);
  }

  public getMaintenanceInfo(): Observable<MaintenanceInfo> {
    const url = `${this.FULLPATH}/maintenance-info`;
    return this.http.get<MaintenanceInfo>(url);
  }
}
