import { Component, OnInit, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SupportChatFacade } from '@state/support-chat';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-chats-wrapper-desktop',
  templateUrl: './chats-wrapper-desktop.component.html',
  styleUrls: ['./chats-wrapper-desktop.component.scss'],
  animations: [
    trigger('showChatsPanel', [
      state(
        'false',
        style({
          transform: 'translateX(1px)',
        })
      ),
      state(
        'true',
        style({
          transform: 'translateX(-401px)',
        })
      ),
      transition('true <=> false', animate('400ms ease-in-out')),
    ]),
    trigger('nextToPanel', [
      state(
        'false',
        style({
          transform: 'translateX(400px)',
        })
      ),
      state(
        'true',
        style({
          transform: 'translateX(0)',
        })
      ),
      transition('true <=> false', animate('400ms ease-in-out')),
    ]),
  ],
})
export class ChatsWrapperDesktopComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public showChatsPanel = false;

  public openSupportChat$ = this.supportChatFacade.openSupportChat$;
  public unreadCount$ = this.supportChatFacade.myUnreadCount$;

  constructor(private readonly supportChatFacade: SupportChatFacade) {}

  ngOnInit(): void {
    this.supportChatFacade.getMyUnreadCount();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
