import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { FileOfDocument, GetUserAssetsOpts, PreSurveyAttachment, User, UserAsset } from '@interfaces';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAssetsHttpService {
  private API_URL = environment.API_URL;
  private FULL_URL = `${this.API_URL}/user-assets`;

  constructor(private http: HttpClient) {}

  public uploadDocumentToReview(opts: { userProcessId: string; file: File }): Observable<UserAsset> {
    const url = `${this.FULL_URL}/my-assets/user-process/${opts.userProcessId}/to-review`;
    const data = this.createDocumentFormData(opts.file);
    return this.http.post<UserAsset>(url, data);
  }

  public getMyDocumentsSentToReview(opts: { userProcessId: string }): Observable<UserAsset[]> {
    const url = `${this.FULL_URL}/my-assets/user-process/${opts.userProcessId}/in-review`;
    return this.http.get<UserAsset[]>(url);
  }

  public downloadMyAsset(opts: { userAssetId: string }): Observable<any> {
    const url = `${this.FULL_URL}/my-assets/${opts.userAssetId}`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public removeMyAsset(opts: { userAssetId: string }): Observable<{ fileOriginalName: string; userAssetId: string }> {
    const url = `${this.FULL_URL}/my-assets/${opts.userAssetId}`;
    return this.http.delete<{ fileOriginalName: string; userAssetId: string }>(url);
  }

  private createDocumentFormData(file: File): FormData {
    const data = new FormData();
    const datetime = moment().format('DDMMYYYY_HHmmss_SSS');
    data.append(`${datetime}`, file, file.name);
    return data;
  }

  public regenerateInitialsAvatar(): Observable<Partial<User>> {
    const url = `${this.FULL_URL}/my-avatar/regenerate`;
    return this.http.patch<Partial<User>>(url, {});
  }

  public uploadMessageAttachment(opts: { file: File }): Observable<UserAsset> {
    const url = `${this.FULL_URL}/my-assets/message-attachment`;
    const data = this.createDocumentFormData(opts.file);
    return this.http.post<UserAsset>(url, data);
  }

  public getUserFilesOfDocuments(opts: GetUserAssetsOpts): Observable<FileOfDocument[]> {
    const url = `${this.API_URL}/user-documents/${opts.userId}/documents-scans`;
    return this.http.post<FileOfDocument[]>(url, { ...opts });
  }

  public getUserAssets(opts: GetUserAssetsOpts): Observable<UserAsset[]> {
    const url = `${this.API_URL}/support-chat/user/${opts.userId}/search-assets`;
    return this.http.post<UserAsset[]>(url, { ...opts });
  }

  public downloadAnyAsset(opts: { userAssetId: string }): Observable<any> {
    const url = `${this.FULL_URL}/any-asset/${opts.userAssetId}`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public uploadPreSurveyAttachment(opts: {
    file: File;
    userProcessId: string;
    preSurveyId: string;
  }): Observable<PreSurveyAttachment> {
    const url = `${this.FULL_URL}/userProcess/${opts.userProcessId}/pre-survey/${opts.preSurveyId}/pre-survey-attachment`;
    const data = this.createDocumentFormData(opts.file);
    return this.http.post<PreSurveyAttachment>(url, data);
  }
}
