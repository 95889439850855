import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Annex, CreateAnnexOpts, GetAnnexesOpts, MOSSpeciality, RemoveAnnexOpts, UpdateAnnexOpts } from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnnexHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  private getAnnexBaseUrl = (userProcessId: string): string =>
    `${this.API_URL}/personal-details/user-process/${userProcessId}`;

  public getAnnexes(opts: GetAnnexesOpts): Observable<Annex[]> {
    const url = `${this.getAnnexBaseUrl(opts.userProcessId)}/annex`;
    return this.http.get<Annex[]>(url);
  }

  public createAnnex(opts: CreateAnnexOpts): Observable<Annex> {
    const url = `${this.getAnnexBaseUrl(opts.userProcessId)}/annex`;
    return this.http.post<Annex>(url, {});
  }

  public updateAnnex(opts: UpdateAnnexOpts): Observable<Annex> {
    const { userProcessId, annex, annexId } = opts;
    const url = `${this.getAnnexBaseUrl(userProcessId)}/annex/${annexId}`;
    return this.http.patch<Annex>(url, annex);
  }

  public removeAnnex(opts: RemoveAnnexOpts): Observable<Annex> {
    const { annexId, userProcessId } = opts;
    const url = `${this.getAnnexBaseUrl(userProcessId)}/annex/${annexId}`;
    return this.http.delete<Annex>(url);
  }

  public getSpecialities(): Observable<MOSSpeciality[]> {
    const url = `${this.API_URL}/common/mos-specialities`;
    return this.http.get<MOSSpeciality[]>(url);
  }
}
