import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { PermissionEnum, User } from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { UserAuthFacade } from '@state/user-auth';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private userAuthFacade: UserAuthFacade,
    private readonly notifService: SnackbarService,
    private readonly router: Router
  ) {}

  private hasRequiredPermissions(requiredPermissions: PermissionEnum[], user: User): boolean {
    if (!requiredPermissions || !requiredPermissions.length) {
      return true;
    }

    const userPermissions: PermissionEnum[] | undefined = user.role?.permissions?.map(permObj => permObj.key);
    if (!userPermissions) {
      console.error('Warning! No user permissions. Not allowing to enter the view');
      return false;
    }

    return requiredPermissions.every(permission => userPermissions.includes(permission));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const exitUrlTree = this.router.createUrlTree(['/']);
    const requiredPermissions = route.data?.requiredPermissions as PermissionEnum[];
    return this.userAuthFacade.getMyself$(state.url).pipe(
      take(1),
      switchMap(userFromStore => {
        const hasPermissions = this.hasRequiredPermissions(requiredPermissions, userFromStore);
        if (hasPermissions) {
          return of(true);
        }
        this.notifService.showError('ERRORS.NOT_AUTHORIZED_TO_ENTER');
        return of(exitUrlTree);
      })
    );
  }
}
