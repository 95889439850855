import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, filter, take, takeUntil, withLatestFrom } from 'rxjs';
import { Socket } from 'ngx-socket-io';

import { InAppNotificationsFacade } from '@state/notifications';
import {
  InAppNotification,
  MaintenanceInfo,
  SUPPORT_CHAT_EVENTS,
  SupportChat,
  SupportChatMessage,
  SupportChatsOrder,
  User,
} from '@interfaces';
import { SupportChatFacade } from '@state/support-chat';
import { UserAuthFacade } from '@state/user-auth';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService implements OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public myself: User;

  constructor(
    private readonly socket: Socket,
    private readonly inAppNotifications: InAppNotificationsFacade,
    private readonly supportChatFacade: SupportChatFacade,
    private readonly userAuthFacade: UserAuthFacade
  ) {}

  public init(): void {
    this.socket.connect();
    this.socket.on('notification', (notification: InAppNotification) => {
      this.inAppNotifications.addNewNotification({ ...notification });
    });

    this.socket.on('maintenance-info', (info: MaintenanceInfo) => {
      this.inAppNotifications.addReceivedMaintenanceInfo({ ...info });
    });

    this.listenToSupportChatEvents();

    this.userAuthFacade.myself$
      .pipe(
        filter(myself => !!myself?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(myself => {
        this.myself = myself;
      });
  }

  private listenToSupportChatEvents(): void {
    this.socket.on(SUPPORT_CHAT_EVENTS.NEW_MESSAGE_IN_SUPPORT_CHAT, (supportMessage: SupportChatMessage) => {
      this.supportChatFacade.openSupportChat$.pipe(take(1)).subscribe(openSupportChat => {
        // making sure that we wont add message to the wrong chat
        if (openSupportChat?.supportChat?.id !== supportMessage?.supportChatId) {
          return;
        }

        this.supportChatFacade.newMessageReceived(supportMessage);
      });
    });

    this.socket.on(SUPPORT_CHAT_EVENTS.MESSAGE_EDITED_IN_SUPPORT_CHAT, (supportMessage: SupportChatMessage) => {
      this.supportChatFacade.openSupportChat$.pipe(take(1)).subscribe(openSupportChat => {
        // making sure that we wont edit message in the wrong chat
        if (openSupportChat?.supportChat?.id !== supportMessage?.supportChatId) {
          return;
        }

        this.supportChatFacade.messageEditedByOther(supportMessage);
      });
    });

    this.socket.on(SUPPORT_CHAT_EVENTS.MESSAGE_REMOVED_IN_SUPPORT_CHAT, (supportMessage: SupportChatMessage) => {
      this.supportChatFacade.openSupportChat$.pipe(take(1)).subscribe(openSupportChat => {
        // making sure that we wont remove message from the wrong chat
        if (openSupportChat?.supportChat?.id !== supportMessage?.supportChatId) {
          return;
        }

        this.supportChatFacade.messageRemovedByOther(supportMessage);
      });
    });

    this.socket.on(SUPPORT_CHAT_EVENTS.EMPLOYEE_ADDED_MARKER_TO_SUPPORT_CHAT, (supportChat: SupportChat) => {
      this.supportChatFacade.updateSupportChat(supportChat);
    });

    this.socket.on(SUPPORT_CHAT_EVENTS.EMPLOYEE_MARKED_SUPPORT_CHAT_AS_UNREAD, (supportChat: SupportChat) => {
      if (this.myself?.id === supportChat.supportedUser.accountCreatedByEmployeeId) {
        this.supportChatFacade.debouncedGetMyUnreadCount();
      }

      this.supportChatFacade.updateSupportChat(supportChat);
    });

    this.socket.on(SUPPORT_CHAT_EVENTS.EMPLOYEE_READ_SUPPORT_CHAT, (supportChat: SupportChat) => {
      if (this.myself?.id === supportChat.supportedUser.accountCreatedByEmployeeId) {
        this.supportChatFacade.debouncedGetMyUnreadCount();
      }

      this.supportChatFacade.updateSupportChat(supportChat);
    });

    this.socket.on(SUPPORT_CHAT_EVENTS.FOREIGNER_SENT_MESSAGE_IN_SUPPORT_CHAT, (supportChat: SupportChat) => {
      this.supportChatFacade.pagination$
        .pipe(take(1), withLatestFrom(this.userAuthFacade.myself$.pipe(take(1))))
        .subscribe(([pagination, myself]) => {
          const userIsAssignedToMe = supportChat.supportedUser.accountCreatedByEmployeeId === myself.id;

          if (userIsAssignedToMe) {
            this.supportChatFacade.debouncedGetMyUnreadCount();
          }

          if (pagination.onlyAssignedToMe && !userIsAssignedToMe) {
            return;
          }

          if (pagination.orderBy === SupportChatsOrder.USER_NAME || pagination.supportedUserName) {
            this.supportChatFacade.updateSupportChat(supportChat);
            return;
          }

          if (pagination.orderBy === SupportChatsOrder.LAST_MESSAGE_DATE && !pagination.supportedUserName) {
            this.supportChatFacade.updateSupportChatAndPosition(supportChat);
            return;
          }
        });
    });
  }

  public close(): void {
    this.socket.removeAllListeners('notification');
    this.socket.removeAllListeners('maintenance-info');
    this.socket.removeAllListeners(SUPPORT_CHAT_EVENTS.NEW_MESSAGE_IN_SUPPORT_CHAT);
    this.socket.removeAllListeners(SUPPORT_CHAT_EVENTS.MESSAGE_EDITED_IN_SUPPORT_CHAT);
    this.socket.removeAllListeners(SUPPORT_CHAT_EVENTS.MESSAGE_REMOVED_IN_SUPPORT_CHAT);
    this.socket.removeAllListeners(SUPPORT_CHAT_EVENTS.EMPLOYEE_MARKED_SUPPORT_CHAT_AS_UNREAD);
    this.socket.removeAllListeners(SUPPORT_CHAT_EVENTS.EMPLOYEE_READ_SUPPORT_CHAT);
    this.socket.removeAllListeners(SUPPORT_CHAT_EVENTS.FOREIGNER_SENT_MESSAGE_IN_SUPPORT_CHAT);
    this.socket.removeAllListeners(SUPPORT_CHAT_EVENTS.EMPLOYEE_ADDED_MARKER_TO_SUPPORT_CHAT);
    this.socket.removeAllListeners();
    this.socket.disconnect();
  }

  public ngOnDestroy(): void {
    this.close();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
