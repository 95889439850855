export type Patterns = 'PLTextValidator' | 'PLNameValidator' | 'DateValidator' | 'ZipValidator';

export interface Validators {
  required: { val: boolean; errorKey?: string };
  max?: { val: number; errorKey: string };
  min?: { val: number; errorKey: string };
  maxLength?: { val: number; errorKey: string };
  minLength?: { val: number; errorKey: string };
  bool?: { val: boolean; errorKey: string };
  pattern?: { val: Patterns; errorKey: string };
  familyMembers?: boolean;
}
export interface ValidationConfig {
  [key: string]: Validators;
}

export interface PersonalDetailsError {
  fieldName: string; // one of PersonalDetails fields
  error: {
    errorKey: string;
    val: any;
  };
}

export interface PersonalDetailsValidationResult {
  errors: PersonalDetailsError[];
  familyMembersErrors: { [memberIndex: number]: PersonalDetailsError[] };
}

export interface ValidatePersonalDetailsResponse extends PersonalDetailsValidationResult {
  valid: boolean;
}
