import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import {
  GetEmployeesPayload,
  GetForeignersPayload,
  GetUsersPayload,
  PaginatedResponse,
  ReassignForeignersOpts,
  Role,
  SelfRegisterOpts,
  User,
} from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class UsersHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getForeigners(payload: GetForeignersPayload): Observable<PaginatedResponse<User>> {
    const url = `${this.API_URL}/users/search/foreigners`;
    return this.http.post<PaginatedResponse<User>>(url, payload);
  }

  public getEmployees(payload: GetEmployeesPayload): Observable<PaginatedResponse<User>> {
    const url = `${this.API_URL}/users/search/employees`;
    return this.http.post<PaginatedResponse<User>>(url, payload);
  }

  public getManagers(payload: GetUsersPayload): Observable<PaginatedResponse<User>> {
    const url = `${this.API_URL}/users/search/managers`;
    return this.http.post<PaginatedResponse<User>>(url, payload);
  }

  public getCompanyEmployees(payload: GetUsersPayload): Observable<PaginatedResponse<User>> {
    const url = `${this.API_URL}/users/search/company-employees`;
    return this.http.post<PaginatedResponse<User>>(url, payload);
  }

  public getUsers(payload: GetUsersPayload): Observable<PaginatedResponse<User>> {
    const url = `${this.API_URL}/users/search`;
    return this.http.post<PaginatedResponse<User>>(url, payload);
  }

  public getUser(userId: string): Observable<User> {
    const url = `${this.API_URL}/users/${userId}`;
    return this.http.get<User>(url);
  }

  // processIdToAssign
  public createForeigner(payload: Partial<User>): Observable<{ user: User; emailSent: boolean }> {
    const url = `${this.API_URL}/users/foreigner`;
    return this.http.post<{ user: User; emailSent: boolean }>(url, payload);
  }

  public createEmployee(payload: Partial<User>): Observable<{ user: User; emailSent: boolean }> {
    const url = `${this.API_URL}/users/employee`;
    return this.http.post<{ user: User; emailSent: boolean }>(url, payload);
  }

  public createManager(payload: Partial<User>): Observable<{ user: User; emailSent: boolean }> {
    const url = `${this.API_URL}/users/manager`;
    return this.http.post<{ user: User; emailSent: boolean }>(url, payload);
  }

  // TO DO; different endpoints for updating foreigner and employee
  public updateForeigner(payload: Partial<User>, userId: string): Observable<User> {
    return this.updateUser(payload, userId);
  }

  public updateEmployee(payload: Partial<User>, userId: string): Observable<User> {
    const url = `${this.API_URL}/users/employee/${userId}`;
    return this.http.patch<User>(url, payload);
  }

  // TO DO; different endpoints for updating foreigner and employee and manager
  public updateManager(payload: Partial<User>, userId: string): Observable<User> {
    return this.updateUser(payload, userId);
  }

  public updateUser(payload: Partial<User>, userId: string): Observable<User> {
    const url = `${this.API_URL}/users/${userId}`;
    return this.http.patch<User>(url, payload);
  }

  public removeForeigner(foreignerId: string): Observable<{ success: boolean }> {
    const url = `${this.API_URL}/management/foreigner/${foreignerId}`;
    return this.http.delete<{ success: boolean }>(url);
  }

  public restoreForeigner(foreignerId: string): Observable<Partial<User>> {
    const url = `${this.API_URL}/management/foreigner/${foreignerId}/restore`;
    return this.http.patch<Partial<User>>(url, {});
  }

  public restoreUser(userId: string): Observable<Partial<User>> {
    const url = `${this.API_URL}/users/restore-user/${userId}`;
    return this.http.patch<Partial<User>>(url, {});
  }

  public removeUser(userId: string): Observable<void> {
    const url = `${this.API_URL}/users/${userId}`;
    return this.http.delete<void>(url);
  }

  public removeManager(userId: string): Observable<void> {
    const url = `${this.API_URL}/users/company-managers/${userId}`;
    return this.http.delete<void>(url);
  }

  public resendForeignerActivation(email: string): Observable<{ emailSent: boolean }> {
    const url = `${this.API_URL}/users/foreigner/resend-activation`;
    return this.http.post<{ emailSent: boolean }>(url, { email });
  }

  public resendEmployeeActivation(email: string): Observable<{ emailSent: boolean }> {
    const url = `${this.API_URL}/users/employee/resend-activation`;
    return this.http.post<{ emailSent: boolean }>(url, { email });
  }

  public resendManagerActivation(email: string): Observable<{ emailSent: boolean }> {
    const url = `${this.API_URL}/users/manager/resend-activation`;
    return this.http.post<{ emailSent: boolean }>(url, { email });
  }

  public getEmployeeTypes(): Observable<Role[]> {
    const url = `${this.API_URL}/roles/find/employee-types`;
    return this.http.get<Role[]>(url);
  }

  public selfRegister(opts: SelfRegisterOpts): Observable<{ success: boolean }> {
    const url = `${this.API_URL}/pblc/register`;
    return this.http.post<{ success: boolean }>(url, { ...opts });
  }
  public getAllRoles(): Observable<Role[]> {
    const url = `${this.API_URL}/roles`;
    return this.http.get<Role[]>(url);
  }

  public reassignForeigners(opts: ReassignForeignersOpts): Observable<any> {
    const url = `${this.API_URL}/users/foreigners/reassign`;
    const body = { ...opts };
    return this.http.post<any>(url, body);
  }
}
