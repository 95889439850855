import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LoadingAnimationComponent } from '@shared/loading-animation/loading-animation.component';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingAnimationService implements OnDestroy {
  public dialogRef: MatDialogRef<any>;

  public loadingAnimationEmitter = new EventEmitter<void>();
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private dialog: MatDialog,
    private readonly translateService: TranslateService
  ) {}

  public createDialog(name: string, time: number, messages?: string[]): void {
    const existingDialog = this.dialog.getDialogById(name);

    if (messages === undefined) {
      messages = [
        this.translateService.instant('LOADING_ANIMATION_PROGRESS_BAR.TEXT1'),
        this.translateService.instant('LOADING_ANIMATION_PROGRESS_BAR.TEXT2'),
        this.translateService.instant('LOADING_ANIMATION_PROGRESS_BAR.TEXT3'),
        this.translateService.instant('LOADING_ANIMATION_PROGRESS_BAR.TEXT4'),
      ];
    }

    if (!existingDialog) {
      this.dialogRef = this.dialog.open(LoadingAnimationComponent, {
        width: '100vw',
        maxWidth: '100vw',
        maxHeight: '100vh',
        minHeight: '100vh',
        panelClass: [name],
        disableClose: true,
        autoFocus: false,
        enterAnimationDuration: '0ms',
        exitAnimationDuration: '0ms',
        backdropClass: name + '_backdrop',
        id: name,
        data: {
          time,
          messages,
        },
      });

      this.dialogRef
        .afterOpened()
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(() => {
          this.setDialogInFront(name);
          this.loadingAnimationEmitter.emit();
        });
    }
  }

  public isModalOpened(name: string): boolean {
    const existingDialog = this.dialog.getDialogById(name);
    return !!existingDialog;
  }

  public setDialogInFront(className: string): void {
    let element1: any = document.getElementsByClassName(className + '_backdrop');
    let element2: any = document.getElementsByClassName(className);

    // cdk-overlay-backdrop
    if (!element1?.length) {
      return;
    }
    element1 = element1[0] as HTMLElement;
    const parent1 = element1.parentNode as HTMLElement;
    if (parent1) {
      // cdk-overlay-container
      parent1.style.zIndex = String(10000);
    }
    element1.style.zIndex = String(100000);

    // cdk-global-overlay-wrapper
    if (!element2?.length) {
      return;
    }
    element2 = element2[0] as HTMLElement;
    const parent2 = element2.parentNode as HTMLElement;
    if (parent2) {
      parent2.style.zIndex = String(100000);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
