import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserProcess } from '@interfaces';

export enum PaidTags {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
  ICON_ONLY = 'ICON_ONLY',
}

@Component({
  selector: 'app-paid-tag',
  templateUrl: './paid-tag.component.html',
  styleUrls: ['./paid-tag.component.scss'],
})
export class PaidTagComponent implements OnChanges {
  @Input() userProcess: Partial<UserProcess>;
  @Input() mode: PaidTags = PaidTags.LARGE;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userProcess?.isFirstChange()) {
      return;
    }
    this.userProcess = changes.userProcess?.currentValue;
  }

  protected readonly PaidTags = PaidTags;
}
