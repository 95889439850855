import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { AllDeviceInfo } from '@interfaces';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { takeUntil } from 'rxjs/operators';
import { IntroModalComponent } from '@shared/intro-modal/intro-modal.component';
import { DeleteAccountModalComponent } from './delete-account-modal.component';

@Injectable({
  providedIn: 'root',
})
export class DeleteAccountModalService implements OnDestroy {
  public dialogRef: MatDialogRef<any>;
  public name = 'delete-account-modal';

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public deviceInfo: AllDeviceInfo;
  private dialogWidth = '850px';
  private dialogHeight = 'auto';

  public password: string;

  constructor(
    private dialog: MatDialog,
    private readonly deviceInfoService: DeviceInfoService
  ) {
    this.deviceInfo = this.deviceInfoService.getInfo();
    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
      if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
        this.dialogWidth = '95vw';
        this.dialogHeight = '95dvh';
      }
    });
    if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
      this.dialogWidth = '95vw';
      this.dialogHeight = '95dvh';
    }
  }

  public open(): MatDialogRef<IntroModalComponent> {
    this.dialogRef = this.dialog.open(DeleteAccountModalComponent, {
      width: this.dialogWidth,
      maxWidth: this.dialogWidth,
      height: this.dialogHeight,
      panelClass: ['delete-account-modal'],
      autoFocus: false,
      data: { password: this.password },
      id: this.name,
      backdropClass: this.name + '_backdrop',
    });

    return this.dialogRef;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
