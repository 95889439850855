import {
  ConsumableCountry,
  CreateVisitAssistantOpts,
  Language,
  Process,
  ProcessFee,
  VisitAssistant,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const pingApi = createAction('[Commons] Ping API');
export const pingApiSuccess = createAction('[Commons] Ping API Success');
export const pingApiError = createAction('[Commons] Ping API Error');

export const getCountriesList = createAction('[Commons] Get Countries list');
export const getCountriesListSuccess = createAction(
  '[Commons] Get Countries list Success',
  props<{ countriesList: ConsumableCountry[] }>()
);
export const getCountriesListError = createAction(
  '[Commons] Get Countries list Error',
  props<{ errorMessage: string }>()
);

export const getCountriesEUList = createAction('[Commons] Get Countries EU list');
export const getCountriesEUListSuccess = createAction(
  '[Commons] Get Countries EU list Success',
  props<{ countriesListEU: ConsumableCountry[] }>()
);
export const getCountriesEUListError = createAction(
  '[Commons] Get Countries EU list Error',
  props<{ errorMessage: string }>()
);

export const getLanguages = createAction('[Commons] Get Available Languages');
export const getLanguagesSuccess = createAction(
  '[Commons] Get Available Languages Success',
  props<{ languages: Language[] }>()
);
export const getLanguagesError = createAction('[Commons] Get Available Languages Error', props<{ error: any }>());

export const upsertLastReadStatusError = createAction(
  '[Commons] Upsert Last Read Status Error',
  props<{ errorMessage: string }>()
);

export const getAllVisitAssistants = createAction('[Commons] Get All Visit Assistants');
export const getAllVisitAssistantsSuccess = createAction(
  '[Commons] Get All Visit Assistants Success',
  props<{ visitAssistants: VisitAssistant[] }>()
);
export const getAllVisitAssistantsError = createAction(
  '[Commons] Get All Visit Assistants Error',
  props<{ errorMessage: string }>()
);

export const createVisitAssistant = createAction(
  '[Commons] Create Visit Assistant',
  props<{ opts: CreateVisitAssistantOpts }>()
);

export const createVisitAssistantSuccess = createAction(
  '[Commons] Create Visit Assistant Success',
  props<{ visitAssistant: VisitAssistant }>()
);
export const createVisitAssistantError = createAction(
  '[Commons] Create Visit Assistant Error',
  props<{ errorMessage: string }>()
);

export const removeAssistant = createAction('[Commons] Remove Assistant', props<{ visitAssistantId: string }>());
export const removeAssistantSuccess = createAction(
  '[Commons] Remove Assistant Success',
  props<{ removedVisitAssistantId: string }>()
);
export const removeAssistantError = createAction('[Commons] Remove Assistant Error', props<{ errorMessage: string }>());

export const getListOfAvailableProcesses = createAction('[User-Process] Get list of available processes');

export const getListOfAvailableProcessesSuccess = createAction(
  '[User-Process] Get list of available processes Success',
  props<{ processes: Process[] }>()
);

export const getListOfAvailableProcessesError = createAction(
  '[User-Process] Get list of available processes Error',
  props<{ errorMessage: string }>()
);

export const updateProcessFees = createAction(
  '[Commons] Update Process Fees',
  props<{ processId: string; fees: ProcessFee[] }>()
);
export const updateProcessFeesSuccess = createAction(
  '[Commons] Update Process Fees Success',
  props<{ updatedProcess: Process }>()
);
export const updateProcessFeesError = createAction(
  '[Commons] Update Process Fees Error',
  props<{ errorMessage: string }>()
);
