import { FEE_NAME, Office, UserProcessMode } from '@interfaces';

export interface City {
  id: string;
  municipalityId: string;
  districtId: string;
  voiodeshipId: string;
  MOS_id: string;
  MOS_name: string;
}

export interface District {
  id: string;
  voivodeshipId: string;
  MOS_id: string;
  MOS_name: string;
  MOS_extra_name: string;
}

export interface Municipality {
  id: string;
  districtId: string;
  voivodeshipId: string;
  MOS_id: string;
  MOS_name: string;
  MOS_id_tech: string;
  MOS_type_code: string;
  MOS_type: string;
}

export interface Voivodeship {
  id?: string;
  name: string;
  key: string;
  office_visit_available_in_days: number;
  offices: VoivodeshipOffice[];
  bankAccounts: VoivodeshipBankAccount[];
  supportingOfficeId?: string | null;
  supportingOffice?: Partial<Office> | null;
  supportedModes?: UserProcessMode[];
  instructionFile?: MultiLangFile;
}

export interface VoivodeshipOffice {
  id?: string;
  voivodeshipId?: string;
  name: string;
  street: string;
  extra: string;
  city: string;
  zipcode: string;
  mailingAdditionalInfo: string;
  mainMailingOffice: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface VoivodeshipBankAccount {
  iban: string;
  transferTitle: string;
  accountOwner: string;
  accountName: string;
  forPaymentOfFee: FEE_NAME;
}

export interface UpdateVoivodeshipOpts {
  office_visit_available_in_days?: number;
  bankAccounts?: VoivodeshipBankAccount[];
  supportingOfficeId?: string;
  supportedModes?: UserProcessMode[];
}

export enum VoivodeshipName {
  DOLNOSLASKIE = 'Dolnośląskie',
  KUJAWSKOPOMORSKIE = 'Kujawsko-pomorskie',
  LUBELSKIE = 'Lubelskie',
  LUBUSKIE = 'Lubuskie',
  LODZKIE = 'Łódzkie',
  MALOPOLSKIE = 'Małopolskie',
  MAZOWIECKIE = 'Mazowieckie',
  OPOLSKIE = 'Opolskie',
  PODKARPACKIE = 'Podkarpackie',
  PODLASKIE = 'Podlaskie',
  POMORSKIE = 'Pomorskie',
  SLASKIE = 'Śląskie',
  SWIETOKRZYSKIE = 'Świętokrzyskie',
  WARMINSKOMAZURSKIE = 'Warmińsko-mazurskie',
  WIELKOPOLSKIE = 'Wielkopolskie',
  ZACHODNIOPOMORSKIE = 'Zachodniopomorskie',
}

export interface MultiLangFile {
  pl: VoivodeshipFileInstructionFile;
  en: VoivodeshipFileInstructionFile;
  uk: VoivodeshipFileInstructionFile;
  ru: VoivodeshipFileInstructionFile;
}

export interface VoivodeshipFileInstructionFile {
  fileName: string;
  fileOriginalName: string;
  fileSize: number;
  fileLocation: string;
  fileMimeType: string;
  fileExtension: string;
}
