<form [formGroup]="formGroup" id="step5Form">
  <div class="step5-form-container">
    <div class="row">
      <div class="standard-form-field radio-form-field noFamilyMembersInPoland-field required-field">
        <label [class.invalid]="controls.noFamilyMembersInPoland.touched && controls.noFamilyMembersInPoland.invalid">
          {{ 'PERSONAL_DATA_FORM.HAS-FAMILY-MEMBERS-IN-POLAND-LONG-RESI.MAIN-LABEL' | translate }} *
        </label>
        <mat-radio-group
          formControlName="noFamilyMembersInPoland"
          (keydown)="firstInputKeydown($event)"
          (keydown.Tab)="controls.noFamilyMembersInPoland.value === true ? emitLastFieldTabPressed($event) : undefined"
          [required]="true">
          <!-- Inverted values because of label we're using -->
          <mat-radio-button [value]="false" id="applicantNoFamilyMembersInPoland-0">
            {{ 'COMMON.YES' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="true" id="applicantNoFamilyMembersInPoland-1">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div *ngIf="controls.noFamilyMembersInPoland.value === false" class="row">
      <app-family-members-form-long-resi
        [fieldControl]="controls.familyMembersInPoland"
        (lastFieldTabPressed)="emitLastFieldTabPressed($event)">
      </app-family-members-form-long-resi>
    </div>
  </div>
</form>
