import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { CreateMessageInMyChat, SupportChat, SupportChatMessage } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class ChatHttpService {
  private API_URL = environment.API_URL;
  private FULL_URL = `${this.API_URL}/my-support-chat`;

  constructor(private http: HttpClient) {}

  public getMyChat(): Observable<{ supportChat: SupportChat; messages: SupportChatMessage[] }> {
    const url = `${this.FULL_URL}`;
    return this.http.get<{ supportChat: SupportChat; messages: SupportChatMessage[] }>(url);
  }

  public addChatMessage(addMessageOpts: CreateMessageInMyChat): Observable<SupportChatMessage> {
    const url = `${this.FULL_URL}/messages/new`;
    return this.http.post<SupportChatMessage>(url, { ...addMessageOpts });
  }

  public downloadAttachmentFromChat(messageId: string, attachmentId: string): Observable<HttpResponse<Blob>> {
    const url = `${this.FULL_URL}/messages/${messageId}/attachment/${attachmentId}`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }
}
