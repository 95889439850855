<div class="title-container">
  <h2 mat-dialog-title>
    <span class="discussion-about">{{ 'APPLICATION.DISCUSSION-ABOUT' | translate }}</span>
    {{ data.docName | truncate: 40 : false }}
  </h2>
  <button mat-button (click)="closeDialog()" class="close-button-wrapper">
    <mat-icon class="close-button">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography" *ngIf="myself$ | async as myself" #dialogContent>
  <div class="comments-wrapper">
    <ng-container *ngIf="(allComments$ | async)?.length; else noCommentsMessage">
      <div *ngFor="let comment of allComments$ | async; index as commentIndex" class="comment-wrapper">
        <!-- <div class="comment-author-signature">
          {{ comment.author.name || comment.author.email }}
        </div> -->
        <div
          class="comment-content"
          matTooltip="{{ comment.author.name || comment.author.email }}"
          matTooltipClass="chatTooltip"
          matTooltipPosition="left"
          matTooltipShowDelay="450"
          [class.author-comments]="comment.author.id === myself.id"
          [class.during-edition]="editingComment?.id === comment.id"
          [class.interlocutor-comments]="comment.author.id !== myself.id"
          [innerHTML]="comment.content | linebreaks"></div>
        <div class="created-at-wrapper">
          <p class="created-at-content" [class.dark]="comment.author.id === myself.id">
            {{ comment.createdAt | date: 'dd-MM-yyyy HH:mm' }}
          </p>
        </div>

        <!-- EDIT & DELETE unavailable for foreigners -->
        <div
          class="actions-wrapper"
          *ngIf="myself.role.key !== ROLES_KEYS.Foreigner && comment.author.id === myself.id">
          <p *ngIf="editingComment?.id !== comment.id" class="comment-action" (click)="editComment(comment)">
            {{ 'COMMON.EDIT' | translate }}
            &nbsp; |
          </p>
          <p *ngIf="editingComment?.id === comment.id" class="comment-action" (click)="cancelEdit()">
            {{ 'DISCUSSION_MODAL.CANCEL-EDITING' | translate }}
            &nbsp; | &nbsp;
          </p>
          <p *ngIf="editingComment?.id !== comment.id" class="comment-action" (click)="removeComment(comment)">
            {{ 'COMMON.REMOVE' | translate }}
          </p>
        </div>
      </div>
    </ng-container>
    <ng-template #noCommentsMessage>
      <div class="no-messages-info">
        <h2 *ngIf="(loadingComments$ | async) === false">
          {{ 'CHAT.NO-MESSAGES-RELATED-THIS-DOCUMENT' | translate }}
        </h2>
      </div>
    </ng-template>
    <div class="loading-wrapper" *ngIf="loadingComments$ | async">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="chat-message-input-wrapper standard-form-field">
    <mat-form-field class="chat-message-content-input" appearance="outline">
      <textarea
        [appAutoFocus]="deviceInfo.deviceTypeDetected === 'DESKTOP'"
        matInput
        #commentInput
        id="chat-message-input-in-modal"
        #autosize="cdkTextareaAutosize"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="15"
        autocomplete="off"
        autocapitalize="off"
        [(ngModel)]="newCommentContent"
        (keydown.enter)="$event.preventDefault(); submitComment($event)"
        placeholder="{{ 'CHAT.ENTER_YOUR_MESSAGE' | translate }}"
        maxlength="1500"
        minlength="1"
        required>
      </textarea>
      <div class="mat-error" *ngIf="newCommentContent && !commentContentValid()">
        {{ 'CHAT.FORBIDDEN-CHARACTERS-USED' | translate }}
      </div>
    </mat-form-field>
    <button *ngIf="editingComment?.id" mat-icon-button aria-label="Cancel edit" (click)="cancelEdit()">
      <mat-icon class="cancel-edit-chatMessage-icon">close</mat-icon>
    </button>
    <button
      [disabled]="!newCommentContent || !commentContentValid()"
      mat-icon-button
      color="primary"
      aria-label="Submit"
      (click)="submitComment($event)">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</mat-dialog-actions>
