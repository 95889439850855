import { Process } from './process';
import { Voivodeship } from './voivodeship';

export interface InstructionItem {
  id?: string;
  key: string;
  name: { [key: string]: string };
  comment: { [key: string]: string } | null;
  numberOfCopies: { [key: string]: string } | null;
}

export interface CreateInstructionItemOpts {
  key: string;
  name: InstructionItem['name'];
  comment: InstructionItem['comment'];
  numberOfCopies: InstructionItem['numberOfCopies'];
}

export interface UpdateInstructionItemOpts {
  id: string;
  name: InstructionItem['name'];
  comment: InstructionItem['comment'];
  numberOfCopies: InstructionItem['numberOfCopies'];
}

export interface InstructionItemInProcess {
  id: string;
  indexOnTheList: number;
  enabled: boolean;
  comment: string;
  processId: string;
  voivodeshipId: string;
  instructionItemId: string;
  instructionItem: InstructionItem;
  process?: Pick<Process, 'key' | 'id'>;
  voivodeship?: Pick<Voivodeship, 'id' | 'key' | 'name'>;
}

export interface SearchItemsInProcessesOpts {
  processId: string;
  voivodeshipId?: string;
}

export interface UpdateItemInProcessOpts {
  id: string;
  indexOnTheList?: number;
  comment?: string;
}

export interface UpdateItemsOrderOpts {
  id: string;
  indexOnTheList: number;
}

export interface ToggleItemsInProcessOpts {
  itemsInProcessIds: string[];
  enabled: boolean;
}

export interface ItemInProcessGrouped {
  id: string;
  name: InstructionItem['name'];
  key: InstructionItem['key'];
  indexOnTheList: number;
  allEnabled: boolean;
  someEnabled: boolean;
  inVoivos: InstructionItemInProcess[];
}

export interface CustomInstructionItem {
  id: string;
  enabled: boolean;
  userProcessId: string;
  instructionItem: InstructionItem;
  instructionItemId?: string;
  indexOnTheList: number;
}

export interface UseCustomItemsListOpts {
  userProcessId: string;
  useCustomList: boolean;
}

export interface SetEnabledItemInCustomList {
  itemId: string;
  userProcessId: string;
  enabled: boolean;
}
