<form [formGroup]="formGroup" id="step2Form">
  <div class="step2-form-container">
    <div class="row">
      <div class="nowrap-container">
        <div class="standard-form-field maritalStatus-field required-field">
          <label [class.invalid]="controls.maritalStatus.touched && controls.maritalStatus.invalid">
            {{ 'PERSONAL_DATA_FORM.MARITAL-STATUS.MAIN-LABEL' | translate }}*
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              [formControl]="controls.maritalStatus"
              panelClass="standard-select"
              id="applicantMaritalStatus"
              (keydown)="firstInputKeydown($event)"
              required>
              <mat-option *ngFor="let item of selectsValues.MARITAL_STATUS" [value]="item.value">
                {{ item.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.maritalStatus.invalid">
              {{ 'PERSONAL_DATA_FORM.MARITAL-STATUS.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.maritalStatus) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field education-field required-field">
          <label [class.invalid]="controls.education.touched && controls.education.invalid">
            {{ 'PERSONAL_DATA_FORM.EDUCATION.MAIN-LABEL' | translate }}*
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              [formControl]="controls.education"
              id="applicantEducation"
              panelClass="standard-select"
              required>
              <mat-option *ngFor="let item of selectsValues.EDUCATION" [value]="item.value">
                {{ item.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.education.invalid">
              {{ 'PERSONAL_DATA_FORM.EDUCATION.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.education) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="nowrap-container">
        <div class="standard-form-field pesel-field">
          <label for="peselInput" [class.invalid]="controls.pesel.touched && controls.pesel.invalid">
            {{ 'PERSONAL_DATA_FORM.PESEL.INFIELD-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              #peselInput
              id="peselInput"
              placeholder="{{ 'PERSONAL_DATA_FORM.PESEL.PLACEHOLDER' | translate }}"
              [required]="false"
              formControlName="pesel" />
            <mat-error class="mat-error" *ngIf="controls.pesel.invalid">
              <!-- {{ 'PERSONAL_DATA_FORM.PESEL.INFIELD-LABEL' | translate }} -->
              {{ getErrorMessage(controls.pesel) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field thelephoneNumber-field required-field">
          <label
            for="telephoneNumberInput"
            [class.invalid]="controls.telephoneNumber.touched && controls.telephoneNumber.invalid">
            {{ 'PERSONAL_DATA_FORM.TELEPHONE-NUMBER.INFIELD-LABEL' | translate }}*
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              #telephoneNumberInput
              id="telephoneNumberInput"
              placeholder="{{ 'PERSONAL_DATA_FORM.TELEPHONE-NUMBER.PLACEHOLDER' | translate }}"
              [required]="true"
              formControlName="telephoneNumber" />
            <mat-error class="mat-error" *ngIf="controls.telephoneNumber.invalid">
              <!-- {{ 'PERSONAL_DATA_FORM.TELEPHONE-NUMBER.INFIELD-LABEL' | translate }} -->
              {{ getErrorMessage(controls.telephoneNumber) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="nowrap-container">
        <div class="standard-form-field descriptionHeight-field required-field">
          <label
            for="applicantDescriptionHeight"
            [class.invalid]="controls.descriptionHeight.touched && controls.descriptionHeight.invalid">
            {{ 'PERSONAL_DATA_FORM.DESCRIPTION-HEIGHT.MAIN-LABEL' | translate }}*
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantDescriptionHeight"
              type="number"
              min="50"
              max="280"
              [required]="true"
              placeholder="{{ 'PERSONAL_DATA_FORM.DESCRIPTION-HEIGHT.PLACEHOLDER' | translate }}"
              [formControl]="controls.descriptionHeight" />
            <mat-error *ngIf="controls.descriptionHeight.invalid">
              {{ 'PERSONAL_DATA_FORM.DESCRIPTION-HEIGHT.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.descriptionHeight) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field descriptionColourOfEyes-field required-field">
          <label [class.invalid]="controls.descriptionColourOfEyes.touched && controls.descriptionColourOfEyes.invalid">
            {{ 'PERSONAL_DATA_FORM.DESCRIPTION-COLOUR-OF-EYES.MAIN-LABEL' | translate }}*
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              [formControl]="controls.descriptionColourOfEyes"
              id="applicantDescriptionColourOfEyes"
              panelClass="standard-select"
              required>
              <mat-option *ngFor="let item of selectsValues.EYES" [value]="item.value">
                {{ item.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.descriptionColourOfEyes.invalid">
              {{ 'PERSONAL_DATA_FORM.DESCRIPTION-COLOUR-OF-EYES.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.descriptionColourOfEyes) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="standard-form-field widened-field descriptionSpecialMarks-field">
        <label
          for="applicantDescriptionSpecialMarks"
          [class.invalid]="controls.descriptionSpecialMarks.touched && controls.descriptionSpecialMarks.invalid">
          {{ 'PERSONAL_DATA_FORM.DESCRIPTION-SPECIAL-MARKS.MAIN-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="applicantDescriptionSpecialMarks"
            type="text"
            maxLength="200"
            minLength="1"
            placeholder="{{ 'PERSONAL_DATA_FORM.DESCRIPTION-SPECIAL-MARKS.PLACEHOLDER' | translate }}"
            [required]="false"
            [formControl]="controls.descriptionSpecialMarks" />
          <mat-error *ngIf="controls.descriptionSpecialMarks.invalid">
            {{ getErrorMessage(controls.descriptionSpecialMarks) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="nowrap-container">
        <div class="standard-form-field residenceStreet-field required-field">
          <label
            for="applicantResidenceStreet"
            [class.invalid]="controls.residenceStreet.touched && controls.residenceStreet.invalid">
            {{ 'PERSONAL_DATA_FORM.RESIDENCE-STREET.MAIN-LABEL' | translate }}*
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantResidenceStreet"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-STREET.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.residenceStreet" />
            <mat-icon
              #applicantResidenceStreet="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantResidenceStreet.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.residenceStreet.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-STREET.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.residenceStreet) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field residenceHouseNumber-field required-field">
          <label
            for="applicantResidenceHouseNumber"
            [class.invalid]="controls.residenceHouseNumber.touched && controls.residenceHouseNumber.invalid">
            {{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.MAIN-LABEL' | translate }}*
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantResidenceHouseNumber"
              type="text"
              maxLength="20"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.residenceHouseNumber" />
            <mat-icon
              #applicantResidenceHouseNumber="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantResidenceHouseNumber.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.residenceHouseNumber.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-HOUSE-NUMBER.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.residenceHouseNumber) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="standard-form-field residenceApartmentNumber-field">
        <label
          for="applicantResidenceApartmentNumber"
          [class.invalid]="controls.residenceApartmentNumber.touched && controls.residenceApartmentNumber.invalid">
          {{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.MAIN-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="applicantResidenceApartmentNumber"
            type="text"
            maxLength="20"
            minLength="1"
            placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.PLACEHOLDER' | translate }}"
            [required]="false"
            [formControl]="controls.residenceApartmentNumber" />
          <mat-icon
            #applicantResidenceApartmentNumber="matTooltip"
            (click)="$event.preventDefault(); $event.stopPropagation(); applicantResidenceApartmentNumber.toggle()"
            class="info-suffix-icon"
            matSuffix
            matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
            matTooltipClass="personalDataFormTooltip">
            info_outline
          </mat-icon>
          <mat-error *ngIf="controls.residenceApartmentNumber.invalid">
            {{ 'PERSONAL_DATA_FORM.RESIDENCE-APARTMENT-NUMBER.MAIN-LABEL' | translate }}
            {{ getErrorMessage(controls.residenceApartmentNumber) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="additional-border" [class.border-enabled]="mode === 'employee'">
      <div class="row extra-margin">
        <div class="nowrap-container">
          <div class="standard-form-field residenceCity-field required-field">
            <label
              for="applicantResidenceCity"
              [class.invalid]="controls.residenceCity.touched && controls.residenceCity.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-CITY.MAIN-LABEL' | translate }}*
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="applicantResidenceCity"
                type="text"
                maxLength="200"
                minLength="1"
                placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-CITY.PLACEHOLDER' | translate }}"
                [required]="true"
                [formControl]="controls.residenceCity" />
              <mat-error *ngIf="controls.residenceCity.invalid">
                {{ 'PERSONAL_DATA_FORM.RESIDENCE-CITY.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.residenceCity) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field residencePostalCode-field required-field">
            <label
              for="applicantResidencePostalCode"
              [class.invalid]="controls.residencePostalCode.touched && controls.residencePostalCode.invalid">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-POSTAL-CODE.MAIN-LABEL' | translate }}*
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="applicantResidencePostalCode"
                type="text"
                maxLength="6"
                minLength="6"
                [mask]="'99-999'"
                placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-POSTAL-CODE.PLACEHOLDER' | translate }}"
                [required]="true"
                [formControl]="controls.residencePostalCode" />
              <mat-error *ngIf="controls.residencePostalCode.invalid">
                {{ 'PERSONAL_DATA_FORM.RESIDENCE-POSTAL-CODE.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.residencePostalCode) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="nowrap-container">
          <div class="standard-form-field residenceVoivodeshipName-field required-field">
            <label
              [class.invalid]="
                controls.residenceVoivodeshipDetailsId.touched && controls.residenceVoivodeshipDetailsId.invalid
              ">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-VOIVODESHIP-NAME.MAIN-LABEL' | translate }}*
            </label>
            <mat-form-field appearance="outline" *ngIf="voivodeships$ | async as voivodeships">
              <mat-select
                [formControl]="controls.residenceVoivodeshipDetailsId"
                (keydown.Tab)="lastFieldTabPressed.emit(); $event.preventDefault(); $event.stopPropagation()"
                id="applicantResidenceVoivodeshipName"
                panelClass="['standard-select', longer-select']"
                required>
                <mat-option *ngFor="let voivo of voivodeships" [value]="voivo.id">
                  {{ 'VOIVODESHIPS.' + voivo.key | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controls.residenceVoivodeshipDetailsId.invalid">
                {{ 'PERSONAL_DATA_FORM.RESIDENCE-VOIVODESHIP-NAME.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.residenceVoivodeshipDetailsId) }}
              </mat-error>
            </mat-form-field>
          </div>
          <mat-spinner id="lookingForVoivodeshipSpinner" *ngIf="lookingForVoivodeship" diameter="25"></mat-spinner>
        </div>
      </div>
      <div class="row" *ngIf="mode === 'employee'">
        <div class="standard-form-field residenceDistrict-field">
          <label [class.invalid]="controls.residenceDistrictId.touched && controls.residenceDistrictId.invalid">
            {{ 'NT.RESIDENCE_DISTRICT_LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline" *ngIf="districts$ | async as districts">
            <mat-select
              [formControl]="controls.residenceDistrictId"
              [disabled]="!controls.residenceVoivodeshipDetailsId?.value"
              id="applicantResidenceDistrictId"
              [panelClass]="['standard-select', 'longer-select']"
              appMatOptionHeight
              [appMatOptionHeightValue]="50"
              class="heightened-select">
              <mat-option *ngIf="loadingDistricts$ | async">
                <mat-spinner [diameter]="25"></mat-spinner>
              </mat-option>
              <mat-option *ngFor="let district of districts" [value]="district.id">
                {{ district.MOS_name.toUpperCase() }} ({{ district.MOS_extra_name.toUpperCase() }})
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.residenceDistrictId.invalid">
              {{ 'NT.RESIDENCE_DISTRICT_LABEL' | translate }}
              {{ getErrorMessage(controls.residenceDistrictId) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field residenceMunicipality-field">
          <label [class.invalid]="controls.residenceMunicipalityId.touched && controls.residenceMunicipalityId.invalid">
            {{ 'NT.RESIDENCE_MUNICIPALITY_LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline" *ngIf="municips$ | async as municips">
            <mat-select
              [formControl]="controls.residenceMunicipalityId"
              [disabled]="!controls.residenceDistrictId?.value"
              id="applicantResidenceMunicipalityId"
              [panelClass]="['standard-select', 'longer-select']"
              appMatOptionHeight
              [appMatOptionHeightValue]="50">
              <mat-option *ngIf="loadingMunicips$ | async">
                <mat-spinner [diameter]="25"></mat-spinner>
              </mat-option>
              <mat-option *ngFor="let municip of municips" [value]="municip.id">
                {{ municip.MOS_name.toUpperCase() }} ({{ municip.MOS_type.toUpperCase() }})
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.residenceMunicipalityId.invalid">
              {{ 'NT.RESIDENCE_MUNICIPALITY_LABEL' | translate }}
              {{ getErrorMessage(controls.residenceMunicipalityId) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field residenceCityListed-field">
          <label [class.invalid]="controls.residenceCityListedId.touched && controls.residenceCityListedId.invalid">
            {{ 'NT.RESIDENCE_CITY_LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline" *ngIf="cities$ | async as cities">
            <mat-select
              [formControl]="controls.residenceCityListedId"
              [disabled]="!controls.residenceMunicipalityId?.value"
              id="applicantResidenceCityListedId"
              [panelClass]="['standard-select', 'longer-select']"
              (keydown.Tab)="lastFieldTabPressed.emit(); $event.preventDefault(); $event.stopPropagation()"
              appMatOptionHeight
              [appMatOptionHeightValue]="50">
              <mat-option *ngIf="loadingCities$ | async">
                <mat-spinner [diameter]="25"></mat-spinner>
              </mat-option>
              <mat-option *ngFor="let city of cities" [value]="city.id">
                {{ city.MOS_name.toUpperCase() }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.residenceCityListedId.invalid">
              {{ 'NT.RESIDENCE_CITY_LABEL' | translate }}
              {{ getErrorMessage(controls.residenceCityListedId) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="zip-warning-overflow-container" *ngIf="voivoIsBeeingUsed && !userACKDocumentsReset">
        <h4 class="mat-h4">{{ 'PERSONAL_DATA_FORM.IF-YOU-CHANGE-VOIVO-DOCUMENTS-RESET' | translate }}</h4>
        <button
          mat-raised-button
          color="primary"
          (click)="voivoChangeACK()"
          (keydown.Tab)="lastFieldTabPressed.emit(); $event.preventDefault(); $event.stopPropagation()">
          {{ 'APPLICATION.I-UNDERSTAND-AND-WANT-TO-PROCEED' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
