import { Action, createReducer, on } from '@ngrx/store';
import { ChatState, initialState } from './chat.state';
import * as chatActions from './chat.actions';

const reducer = createReducer(
  initialState,
  on(chatActions.getMyChat, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(chatActions.getMyChatSuccess, (state, { messages, supportChat }) => ({
    ...state,
    chat: structuredClone(supportChat),
    messages: structuredClone(messages),
    loading: false,
    errorMessage: null,
  })),
  on(chatActions.getMyChatError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(chatActions.addChatMessage, (state, {}) => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(chatActions.addChatMessageSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    messageAttachmentsInEdit: null,
    messageAttachmentsToAdd: {},
    messages: [...state.messages, structuredClone(message)],
  })),
  on(chatActions.addChatMessageError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(chatActions.uploadAttachments, (state, { opts: { files } }) => {
    const filesAsObj = {} as any;
    files.forEach(file => {
      filesAsObj[file.fileOriginalName] = {
        error: null,
        loading: true,
        uploaded: false,
        userAsset: file,
      };
    });

    return {
      ...state,
      loadingAttachments: true,
      messageAttachmentsToAdd: {
        ...state.messageAttachmentsToAdd,
        ...filesAsObj,
      },
    };
  }),
  on(chatActions.uploadAttachmentsSuccess, (state, { opts: { uploadedFiles } }) => {
    const inStateObj = {} as any;
    Object.entries(uploadedFiles).forEach(([key, fileUploadResult]) => {
      inStateObj[key] = {
        uploaded: fileUploadResult.uploaded,
        loading: false,
        error: fileUploadResult.errorMessage || null,
        userAsset: fileUploadResult.userAsset || null,
      };
    });
    return {
      ...state,
      loadingAttachments: false,
      messageAttachmentsToAdd: {
        ...state.messageAttachmentsToAdd,
        ...inStateObj,
      },
    };
  }),
  on(chatActions.uploadAttachmentsError, (state, { opts: { errorMessage } }) => {
    return {
      ...state,
      errorMessage,
      loadingAttachments: false,
    };
  }),

  on(chatActions.downloadAttachmentFromChat, (state, {}) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(chatActions.downloadAttachmentFromChatSuccess, chatActions.downloadAttachmentFromChatError, (state, {}) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(chatActions.removeAttachmentFromUploading, (state, { opts: { userAssetId, fileOriginalName } }) => {
    return {
      ...state,
      messageAttachmentsToAdd: {
        ...state.messageAttachmentsToAdd,
        [fileOriginalName]: {
          ...state.messageAttachmentsToAdd[fileOriginalName],
          loading: true,
          uploaded: false,
        },
      },
    };
  }),
  on(chatActions.removeAttachmentFromUploadingSuccess, (state, { opts: { userAssetId, fileOriginalName } }) => {
    const rItems = { ...state.messageAttachmentsToAdd };
    delete rItems[fileOriginalName];
    return {
      ...state,
      messageAttachmentsToAdd: { ...rItems },
    };
  }),
  on(chatActions.removeAttachmentFromUploadingError, (state, { opts: { userAssetId, fileOriginalName } }) => {
    return {
      ...state,
      messageAttachmentsToAdd: {
        ...state.messageAttachmentsToAdd,
        [fileOriginalName]: {
          ...state.messageAttachmentsToAdd[fileOriginalName],
          error: 'ERROR_REMOVING',
        },
      },
    };
  })
);

export function chatReducer(state: ChatState, action: Action): ChatState {
  return reducer(state, action);
}
