import { Action, createReducer, on } from '@ngrx/store';
import * as userAuthActions from './user-auth.actions';
import { UserAuthState, initialState } from './user-auth.state';

const reducer = createReducer(
  initialState,
  on(userAuthActions.activateAccount, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userAuthActions.activateAccountSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),
  on(userAuthActions.activateAccountError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userAuthActions.login, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userAuthActions.loginSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),
  on(userAuthActions.loginError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    myself: null,
    errorMessage,
  })),

  on(userAuthActions.logout, state => ({ ...state, loading: true })),
  on(userAuthActions.logoutSuccess, state => ({ ...state, loading: false, myself: null })),
  on(userAuthActions.logoutError, (state, errorMessage) => ({ ...state, loading: false, myself: null })),

  on(userAuthActions.getMyself, state => ({ ...state, loading: true })),
  on(userAuthActions.getMyselfSuccess, (state, { user }) => ({ ...state, loading: false, myself: user })),
  on(userAuthActions.getMyselfError, (state, errorMessage) => ({
    ...state,
    myself: null,
    loading: false,
  })),

  on(userAuthActions.updateAdminself, state => ({ ...state, loading: true })),
  on(userAuthActions.updateMyself, state => ({ ...state, loading: true })),
  on(userAuthActions.updateMyselfSuccess, (state, { updatedUser }) => ({
    ...state,
    loading: false,
    myself: state.myself ? { ...updatedUser, company: { ...state.myself.company } } : { ...updatedUser },
  })),
  on(userAuthActions.updateMyselfError, (state, errorMessage) => ({ ...state, loading: false })),

  on(userAuthActions.getPasswordResetLink, state => ({ ...state, loading: true, myself: null, errorMessage: null })),
  on(userAuthActions.getPasswordResetLinkSuccess, state => ({
    ...state,
    loading: false,
    myself: null,
    errorMessage: null,
  })),
  on(userAuthActions.getPasswordResetLinkError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    myself: null,
    errorMessage,
  })),

  on(userAuthActions.setNewPassword, state => ({ ...state, loading: true, myself: null, errorMessage: null })),
  on(userAuthActions.setNewPasswordSuccess, state => ({ ...state, loading: false, myself: null, errorMessage: null })),
  on(userAuthActions.setNewPasswordError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    myself: null,
    errorMessage,
  })),

  on(userAuthActions.regenerateInitialsAvatar, state => ({ ...state, loading: true })),
  on(userAuthActions.regenerateInitialsAvatarSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    myself: { ...state.myself, avatarUpdatedAt: user.avatarUpdatedAt },
  })),
  on(userAuthActions.regenerateInitialsAvatarError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(userAuthActions.updateMyInvoiceData, state => ({ ...state, loading: true, errorMessage: null })),
  on(userAuthActions.updateMyInvoiceDataSuccess, (state, { invoiceData }) => ({
    ...state,
    myself: { ...state.myself, invoiceData },
    loading: false,
    errorMessage: null,
  })),
  on(userAuthActions.updateMyInvoiceDataError, state => ({
    ...state,
    loading: false,
    errorMessage: 'ERROR_UPDATING_INVOICE_DATA',
  }))
);

export function userAuthReducer(state: UserAuthState | undefined, action: Action): UserAuthState {
  return reducer(state, action);
}
