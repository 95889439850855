import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { RefreshTokenModalComponent } from './refresh-token-modal.component';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenModalService {
  private DIALOG_ID = 'refresh-token-modal';

  constructor(private dialog: MatDialog) {}

  public isModalOpened(): boolean {
    const existingDialog = this.dialog.getDialogById(this.DIALOG_ID);
    return !!existingDialog;
  }

  public open(): MatDialogRef<RefreshTokenModalComponent> {
    const existingDialog = this.dialog.getDialogById(this.DIALOG_ID);

    // preventing from opening progress dialog twice at the same time
    if (existingDialog) {
      return existingDialog;
    }

    return this.dialog.open(RefreshTokenModalComponent, {
      width: '100vw',
      maxWidth: '100vw',
      maxHeight: '100vh',
      minHeight: '100vh',
      panelClass: ['refresh-token-modal'],
      autoFocus: false,
      id: 'refresh-token-modal',
    });
  }
}
