<div class="dialog-header" [class.with-shadow]="showTopbarShadow">
  <div class="left-column">
    <div class="item-name">
      {{ 'NT.PERSONALIZATION_OF_INSTRUCTION_ITEMS' | translate }}
    </div>
    <div class="application-info" *ngIf="deviceInfo.deviceTypeDetected !== 'PHONE'">
      {{ 'NT.APPLICATION_OF' | translate }}
      <b>{{ applicantName || '-' | truncate: 30 : false }}</b>
      {{ 'NT.IN_VOIVODESHIP' | translate }}
      <b> {{ voivo?.name || '-' | truncate: 30 : false }} </b>
      {{ 'NT.FROM_COMPANY' | translate }}
      <b> {{ companyName || '-' | truncate: 40 : false }}</b>
    </div>

    <div class="application-info" *ngIf="deviceInfo.deviceTypeDetected === 'PHONE'">
      <div>
        {{ 'NT.APPLICATION_OF' | translate }} <b>{{ applicantName || '-' | truncate: 30 : false }}</b>
      </div>
      <div>
        {{ 'NT.IN_VOIVODESHIP' | translate }} <b> {{ voivo?.name || '-' | truncate: 30 : false }} </b>
      </div>
      <div>
        {{ 'NT.FROM_COMPANY' | translate }} <b> {{ companyName || '-' | truncate: 40 : false }}</b>
      </div>
    </div>
    <div class="application-info"></div>
    <div class="application-info"></div>
  </div>
  <div class="right-column">
    <div class="close-wrapper">
      <button mat-icon-button aria-label="Close button" tabindex="-1">
        <mat-icon (click)="close()">close</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content #contentWrapper>
  <div class="list-header-controls">
    <div class="switch-wrapper">
      <div class="use-custom-list-switch-label">
        {{ 'NT.USE_LIST' | translate }}
      </div>
      <app-switch-option-pills
        [options]="switchPillsOptions"
        [selected]="usingCustomList"
        [disabled]="loading$ | async"
        (selectionChanged)="useCustomListChanged($event)">
      </app-switch-option-pills>
    </div>
    <div class="search-input-wrapper" *ngIf="usingCustomList && deviceInfo.deviceTypeDetected === 'DESKTOP'">
      <mat-form-field class="search-input">
        <input
          matInput
          type="text"
          autocomplete="off"
          [(ngModel)]="listFilterValue"
          [disabled]="loading$ | async"
          [placeholder]="'NT.ITEM_NAME' | translate" />
        <mat-icon *ngIf="listFilterValue?.length" matSuffix (click)="listFilterValue = ''">close</mat-icon>
        <mat-icon *ngIf="!listFilterValue?.length" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="reset-button-wrapper" *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'">
      <button
        *ngIf="usingCustomList"
        (click)="resetItemsOnTheList()"
        [disabled]="loading$ | async"
        mat-raised-button
        color="warn">
        {{ 'NT.RESET_ITEMS_ON_THE_LIST' | translate }}
      </button>
    </div>
  </div>

  <div class="search-input-wrapper" *ngIf="usingCustomList && deviceInfo.deviceTypeDetected !== 'DESKTOP'">
    <mat-form-field class="search-input">
      <input
        matInput
        type="text"
        autocomplete="off"
        [(ngModel)]="listFilterValue"
        [disabled]="loading$ | async"
        [placeholder]="'NT.ITEM_NAME' | translate" />
      <mat-icon *ngIf="listFilterValue?.length" matSuffix (click)="listFilterValue = ''">close</mat-icon>
      <mat-icon *ngIf="!listFilterValue?.length" matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="reset-button-wrapper" *ngIf="deviceInfo.deviceTypeDetected !== 'DESKTOP'">
    <button
      *ngIf="usingCustomList"
      (click)="resetItemsOnTheList()"
      [disabled]="loading$ | async"
      mat-raised-button
      color="warn">
      {{ 'NT.RESET_ITEMS_ON_THE_LIST' | translate }}
    </button>
  </div>

  <div class="items-list" *ngIf="itemsList?.length" [class.modifiable]="usingCustomList">
    <div class="list-item header-item">
      <div class="column column-toggle-button" *ngIf="usingCustomList"></div>
      <div class="column column-index" *ngIf="deviceInfo.deviceTypeDetected !== 'PHONE'">
        {{ 'NT.ITEM_NO' | translate }}
      </div>
      <div class="column column-name">{{ 'NT.ITEM_NAME' | translate }}</div>
      <div class="column column-comment">{{ 'NT.ITEM_COMMENTS' | translate }}</div>
      <div class="column column-numberOfCopies">{{ 'NT.ITEM_COPIES' | translate }}</div>
    </div>

    <div
      class="list-item"
      *ngFor="
        let item of itemsList | filterlist: { objKeyPath: 'instructionItem.name.' + currLang, val: listFilterValue };
        index as itemIndex
      "
      [class.disabled]="usingCustomList && !item.enabled">
      <div class="column column-toggle-button" *ngIf="usingCustomList">
        <mat-checkbox
          class="toggle-item-list-checkbox"
          [checked]="item.enabled"
          (change)="setItemEnabledInList($event, item)">
        </mat-checkbox>
      </div>
      <div class="column column-index" *ngIf="deviceInfo.deviceTypeDetected !== 'PHONE'">
        {{ itemIndex + 1 }}
      </div>
      <div class="column column-name">
        <span [innerHTML]="item.instructionItem.name[currLang]"></span>
        <mat-icon
          [matTooltip]="'NT.DOCUMENT_KEY' | translate: { key: item.instructionItem.key }"
          matTooltipClass="universalTooltip"
          svgIcon="info-outline">
        </mat-icon>
      </div>
      <div class="column column-comment" [innerHTML]="item.instructionItem.comment[currLang]"></div>
      <div class="column column-numberOfCopies" [innerHTML]="item.instructionItem.numberOfCopies[currLang]"></div>
    </div>
  </div>

  <ng-container *ngIf="!itemsList?.length && (loading$ | async) === false">
    <h1 class="no-items-info" [innerHTML]="'NT.UNABLE_TO_GENERATE_LIST_WITH_CURRENT_DATA' | translate"></h1>
  </ng-container>

  <div class="spinner-wrapper" *ngIf="loading$ | async">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
</mat-dialog-content>
