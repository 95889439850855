<div class="country-select-container country-select-{{ fieldKey }}">
  <label [class.invalid]="fieldControl.touched && fieldControl.invalid">
    {{ 'PERSONAL_DATA_FORM.' + fieldLabelKey + '.MAIN-LABEL' | translate }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </label>
  <ng-select
    appendTo="body"
    *ngIf="nationalitiesList?.length"
    id="nationalitySelect-{{ fieldKey }}"
    [formControl]="fieldControl"
    [items]="nationalitiesList"
    [required]="isRequired"
    dropdownPosition="bottom"
    bindLabel="label"
    bindValue="value"
    class="select-short-dropdown">
  </ng-select>
  <div class="ng-select-placeholder" *ngIf="!fieldControl.value">
    {{ 'PERSONAL_DATA_FORM.' + fieldLabelKey + '.PLACEHOLDER' | translate }}
  </div>
  <div class="ng-select-error" *ngIf="fieldControl.touched && fieldControl.invalid">
    {{ 'PERSONAL_DATA_FORM.' + fieldLabelKey + '.MAIN-LABEL' | translate }}
    {{ getErrorMessage(fieldControl) }}
  </div>
</div>
