import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalOpts } from './time-picker-modal.service';

@Component({
  selector: 'app-time-picker-modal',
  templateUrl: './time-picker-modal.component.html',
  styleUrls: ['./time-picker-modal.component.scss'],
})
export class TimePickerModalComponent implements OnInit {
  public HH: string;
  public MM: string;

  public hSteps: string[];
  public mSteps: string[];

  public currFocus: 'HH' | 'MM' = 'HH';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOpts,
    public dialogRef: MatDialogRef<TimePickerModalComponent>
  ) {}

  ngOnInit(): void {
    const { initVal, max, min, minuteSteps } = this.data;
    this.hSteps = [];
    this.mSteps = [];

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        this.close();
      }

      if (event.key === 'Right' || event.key === 'ArrowRight') {
        this.currFocus = this.currFocus === 'HH' ? 'MM' : 'HH';
      }
      if (event.key === 'Left' || event.key === 'ArrowLeft') {
        this.currFocus = this.currFocus === 'MM' ? 'HH' : 'HH';
      }
      if (event.key === 'Up' || event.key === 'ArrowUp') {
        this.stepUp(this.currFocus);
      }
      if (event.key === 'Down' || event.key === 'ArrowDown') {
        this.stepDown(this.currFocus);
      }
    });

    this.dialogRef.backdropClick().subscribe(event => {
      this.close();
    });

    for (let i = min; i <= max; i++) {
      const hAsString = i < 10 ? `0${i}` : `${i}`;
      this.hSteps.push(hAsString);
    }
    for (let i = 0; i <= 50; i = i + minuteSteps) {
      const mAsString = i < 10 ? `0${i}` : `${i}`;
      this.mSteps.push(mAsString);
    }

    if (initVal?.length === 5) {
      const [hh, mm] = initVal.split(':');
      this.HH = hh;
      this.MM = mm;
    } else {
      this.HH = this.hSteps[0];
      this.MM = this.mSteps[0];
    }
  }

  public stepUp(propName: 'HH' | 'MM'): void {
    this.currFocus = propName;

    if (propName === 'HH') {
      const ind = this.hSteps.indexOf(this.HH);
      if (ind === this.hSteps.length - 1) {
        this.HH = this.hSteps[0];
      } else {
        this.HH = this.hSteps[ind + 1];
      }
    }
    if (propName === 'MM') {
      const ind = this.mSteps.indexOf(this.MM);
      if (ind === this.mSteps.length - 1) {
        this.MM = this.mSteps[0];
      } else {
        this.MM = this.mSteps[ind + 1];
      }
    }
  }

  public stepDown(propName: 'HH' | 'MM'): void {
    this.currFocus = propName;

    if (propName === 'HH') {
      const ind = this.hSteps.indexOf(this.HH);
      if (ind === 0) {
        this.HH = this.hSteps[this.hSteps.length - 1];
      } else {
        this.HH = this.hSteps[ind - 1];
      }
    }

    if (propName === 'MM') {
      const ind = this.mSteps.indexOf(this.MM);
      if (ind === 0) {
        this.MM = this.mSteps[this.mSteps.length - 1];
      } else {
        this.MM = this.mSteps[ind - 1];
      }
    }
  }

  public close(): void {
    this.dialogRef.close(`${this.HH}:${this.MM}`);
  }
}
