import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserAuthState } from './user-auth.state';

export const selectUserAuthState = createFeatureSelector<UserAuthState>('userAuth');

export const selectMyself = createSelector(selectUserAuthState, state => state.myself);

export const selectLoading = createSelector(selectUserAuthState, state => state.loading);

export const selectErrorMessage = createSelector(selectUserAuthState, state => state.errorMessage);

// export const selectLastDetailsValidation = createSelector(
//   selectUserAuthState,
//   state => state.lastDetailsValidation,
// );
