<mat-dialog-content>
  <div class="close-wrapper">
    <button mat-icon-button aria-label="Close button" tabindex="-1">
      <mat-icon (click)="close()">close</mat-icon>
    </button>
  </div>
  <div class="text-wrapper">
    <h3 class="text">
      {{
        (sys === 'INPOL'
          ? 'CREDENTIALS_MODAL.PROVIDE_EMAIL_AND_PASSWORD_TO_INPOL_ACCOUNT'
          : 'CREDENTIALS_MODAL.PROVIDE_EMAIL_AND_PASSWORD_TO_MOS_ACCOUNT'
        ) | translate
      }}
    </h3>
  </div>

  <div class="credentials-fields-wrapper credentials-fields-for-{{ sys }}">
    <div class="field-wrapper field-wrapper-for-{{ sys }}" id="outer-container-login-for-{{ sys }}">
      <label for="sysCredentailsEmail-{{ sys }}" id="label-of-email-for-{{ sys }}">
        {{ (sys === 'MOS' ? 'LOGIN_PAGE.LOGIN' : 'LOGIN_PAGE.EMAIL') | translate }}
      </label>
      <mat-form-field appearance="outline">
        <input
          id="chrome-piece-of-shit-browser-{{ sys }}-email"
          type="email"
          name="{{ sys }}-email-dummy"
          style="display: none" />
        <input
          class="sysCredentials-{{ sys }}"
          id="sysCredentailsEmail-{{ sys }}"
          name="sysCredentailsEmail-{{ sys }}"
          matInput
          autocapitalize="off"
          autocomplete="one-time-code"
          autocorrect="off"
          autofocus=""
          role="combobox"
          spellcheck="false"
          placeholder="{{ 'CREDENTIALS_MODAL.ENTER_EMAIL' | translate }}"
          [(ngModel)]="email"
          type="{{ sys === 'INPOL' ? 'email' : 'text' }}"
          required
          autofocus />
        <mat-error *ngIf="!email?.length">
          {{ 'CREDENTIALS_MODAL.INVALID_EMAIL' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="field-wrapper field-wrapper-for-{{ sys }}" id="outer-container-psswd-for-{{ sys }}">
      <label for="sysCredentialsPassword-{{ sys }}">{{ 'CREDENTIALS_MODAL.PASSWORD' | translate }}</label>
      <mat-form-field appearance="outline">
        <input
          class="sysCredentials-{{ sys }}"
          id="sysCredentialsPassword-{{ sys }}"
          name="sysCredentialsPassword-{{ sys }}"
          autocapitalize="off"
          autocorrect="off"
          autofocus=""
          role="combobox"
          spellcheck="false"
          autocomplete="one-time-code"
          matInput
          placeholder="{{ 'CREDENTIALS_MODAL.ENTER_PASSWORD' | translate }}"
          [(ngModel)]="password"
          type="password"
          required />
        <input
          id="chrome-piece-of-shit-browser-{{ sys }}-password"
          type="password"
          name="{{ sys }}-password-dummy"
          style="display: none" />
        <mat-error *ngIf="!password?.length">
          {{ 'CREDENTIALS_MODAL.INVALID_PASSWORD' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="action-buttons">
    <button mat-raised-button class="cancel-button" (click)="close()">
      {{ 'COMMON.CANCEL' | translate }}
    </button>
    <button
      mat-raised-button
      class="confirm-button"
      color="primary"
      [disabled]="!email?.length || !password?.length"
      (click)="submitCredentials()">
      {{ (sys === 'INPOL' ? 'CREDENTIALS_MODAL.SEND_TO_INPOL' : 'CREDENTIALS_MODAL.SEND_TO_MOS') | translate }}
    </button>
  </div>
</mat-dialog-actions>
