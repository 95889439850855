import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { InAppNotificationType } from '@interfaces';
import { ReplaySubject } from 'rxjs';
import { UserAuthFacade } from '@state/user-auth';
import { InAppNotificationsFacade } from '@state/notifications';
import { RouterFacade } from '@state/router';

@Component({
  selector: 'app-chat-mobile-button',
  templateUrl: './chat-mobile-button.component.html',
  styleUrls: ['./chat-mobile-button.component.scss'],
})
export class ChatMobileButtonComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public hasAnyNewMessages: boolean;

  constructor(
    private readonly userAuthFacade: UserAuthFacade,
    private readonly inAppNotification: InAppNotificationsFacade,
    private readonly router: RouterFacade
  ) {}

  ngOnInit(): void {
    this.userAuthFacade.myself$
      .pipe(
        filter(myself => !!myself?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(async () => {
        const notificationType = InAppNotificationType.EMPLOYEE_SENT_MESSAGE_IN_CHAT;
        this.inAppNotification
          .getUnreadCountOfType$(notificationType, null)
          .pipe(delay(100), take(1), takeUntil(this.destroy$))
          .subscribe(unreadCount => {
            this.hasAnyNewMessages = unreadCount !== null && unreadCount > 0;
          });
      });

    this.inAppNotification.markAllOfTypeAsRead$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ inAppNotificationType }) => {
        if (inAppNotificationType === InAppNotificationType.EMPLOYEE_SENT_MESSAGE_IN_CHAT) {
          this.hasAnyNewMessages = false;
        }
      });
  }

  async ngAfterViewInit(): Promise<void> {
    this.inAppNotification.newNotificationReceived$.pipe(takeUntil(this.destroy$)).subscribe(({ notification }) => {
      if (InAppNotificationType.EMPLOYEE_SENT_MESSAGE_IN_CHAT !== notification.type) {
        return;
      }

      this.hasAnyNewMessages = true;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openMobileChat() {
    this.router.changeRoute({ linkParams: ['chat'] });
  }
}
