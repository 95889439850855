import { User } from '@interfaces';

export type UserAuthState = Readonly<{
  loading: boolean;
  myself: null | User;
  errorMessage: string | null;
}>;

export const initialState: UserAuthState = {
  loading: false,
  myself: null,
  errorMessage: null,
};
