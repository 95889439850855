import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { SUPPORTED_LANGS } from '@constants';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class I18nLazyloadedResolver implements Resolve<any> {
  private loadedLangs: { [module: string]: { [lang: string]: boolean } } = {};

  constructor(
    private translateService: TranslateService,
    private http: HttpClient
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const moduleNames: string[] = route.data.moduleNames;

    const langLoaders = moduleNames
      .map(moduleName => {
        if (!this.loadedLangs[moduleName]) {
          this.loadedLangs[moduleName] = {};
        }

        return SUPPORTED_LANGS.map(langKey => {
          if (this.loadedLangs[moduleName][langKey]) {
            return of(null);
          } else {
            const path = `assets/i18n/${moduleName}/${langKey}.json`;
            return this.http.get(path).pipe(
              map(translations => {
                this.translateService.setTranslation(langKey, translations, true);
                this.loadedLangs[moduleName][langKey] = true;
                // eslint-disable-next-line no-console
                console.info(`Language ${moduleName}/${langKey} loaded`);
              })
            );
          }
        });
      })
      .flat();

    return forkJoin(langLoaders).toPromise();
  }
}
