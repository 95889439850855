import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RemoveTagsService {
  constructor() {}

  private htmlTags = [
    'html',
    'head',
    'title',
    'meta',
    'link',
    'style',
    'script',
    'noscript',
    'base',
    'body',
    'article',
    'section',
    'nav',
    'aside',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'footer',
    'address',
    'main',
    'p',
    'hr',
    'pre',
    'blockquote',
    'ol',
    'ul',
    'li',
    'dl',
    'dt',
    'dd',
    'figure',
    'figcaption',
    'div',
    'a',
    'em',
    'strong',
    'small',
    's',
    'cite',
    'q',
    'dfn',
    'abbr',
    'data',
    'time',
    'code',
    'var',
    'samp',
    'kbd',
    'sub',
    'sup',
    'span',
    'i',
    'b',
    'mark',
    'ruby',
    'rt',
    'rp',
    'bdi',
    'bdo',
    'br',
    'wbr',
    'ins',
    'del',
    'picture',
    'source',
    'img',
    'iframe',
    'embed',
    'object',
    'param',
    'video',
    'audio',
    'track',
    'canvas',
    'map',
    'area',
    'svg',
    'math',
    'table',
    'caption',
    'colgroup',
    'col',
    'tbody',
    'thead',
    'tfoot',
    'tr',
    'td',
    'th',
    'form',
    'label',
    'input',
    'button',
    'select',
    'datalist',
    'optgroup',
    'option',
    'textarea',
    'fieldset',
    'legend',
    'details',
    'summary',
    'dialog',
    'menu',
    'menuitem',
    'script',
    'noscript',
    'template',
    'slot',
    'img',
    'iframe',
    'canvas',
    'math',
    'script',
    'svg',
  ];

  public removeTags(message: string, allowedTags: string[]): string {
    const allowedTagsSet = new Set(allowedTags);
    const regexPattern = `<(\\/)?(?!(${allowedTags.join('|')}))\\w+\\b[^>]*>`;
    const regex = new RegExp(regexPattern, 'gi');
    message = this.removeImgVideoTag(message, allowedTags);
    return message.replace(regex, match => {
      const tag = match.match(/<\/?(.*?)>/)![1];

      if (allowedTagsSet.has(tag)) {
        return match;
      } else {
        // Jeśli tag nie jest w dozwolonych, ale nie jest tagiem HTML, zwracamy go jako tekst.
        if (!this.htmlTags.includes(tag)) {
          return match;
        }
        // W przeciwnym razie, zwracamy pusty ciąg znaków, co oznacza usunięcie tagu HTML.
        return '';
      }
    });
  }

  public removeImgVideoTag(message: string, allowedTags: string[]): string {
    const imgRegex = /<img[^>]*>/gi;
    const videoRegex = /<video[^>]*>/gi;
    let newMessage: string = message;

    if (!allowedTags.includes('img')) {
      newMessage = newMessage.replace(imgRegex, '');
    }
    if (!allowedTags.includes('video')) {
      newMessage = newMessage.replace(videoRegex, '');
    }
    return newMessage;
  }
}
