import { Injectable } from '@angular/core';
import { GetAvailablePaymentMethodsOpts, NewPurchaseOpts, ValidatePurchaseOpts } from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import * as purchaseActions from './purchase.actions';
import * as selectors from './purchase.selectors';

@Injectable()
export class PurchaseFacade {
  public availableProducts$ = this.store.select(selectors.selectAvailableProducts);
  public loadingProducts$ = this.store.select(selectors.selectLoadingProducts);

  public loadingPaymentMethods$ = this.store.select(selectors.selectLoadingPaymentMethods);
  public availablePaymentMethods$ = this.store.select(selectors.selectAvailablePaymentMethods);

  public myPurchase$ = this.store.select(selectors.selectMyPurchase);

  public loadingPurchase$ = this.store.select(selectors.selectLoadingPurchase);
  public creatingPurchaseInProgress$ = this.store.select(selectors.selectCreatingPurchaseInProgress);

  public errorMessage$ = this.store.select(selectors.selectErrorMessage);

  public newPurchaseSuccess$ = this.actions.pipe(ofType(purchaseActions.newPurchaseSuccess));
  public newPurchaseError$ = this.actions.pipe(ofType(purchaseActions.newPurchaseError));
  public setPaymentStartedSuccess$ = this.actions.pipe(ofType(purchaseActions.setPaymentStartedSuccess));
  public setPaymentStartedError$ = this.actions.pipe(ofType(purchaseActions.setPaymentStartedError));

  public validatePurchaseSuccess$ = this.actions.pipe(ofType(purchaseActions.validatePurchaseSuccess));
  public validatePurchaseError$ = this.actions.pipe(ofType(purchaseActions.validatePurchaseError));

  public verifyPaymentSuccess$ = this.actions.pipe(ofType(purchaseActions.verifyPaymentSuccess));
  public verifyPaymentError$ = this.actions.pipe(ofType(purchaseActions.verifyPaymentError));

  public purchaseList$ = this.store.select(selectors.selectPurchaseList);
  public loadingPurchaseList$ = this.store.select(selectors.selectLoadingPurchaseList);

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getProducts(): void {
    this.store.dispatch(purchaseActions.getAvailableProducts());
  }

  public getAvailablePaymentMethods(opts: GetAvailablePaymentMethodsOpts): void {
    this.store.dispatch(purchaseActions.getAvailablePaymentMethods({ opts }));
  }

  public validatePurchase(opts: ValidatePurchaseOpts): void {
    this.store.dispatch(purchaseActions.validatePurchase({ opts }));
  }

  public newPurchase(opts: NewPurchaseOpts): void {
    this.store.dispatch(purchaseActions.newPurchase({ opts }));
  }

  public getMyPurchase(purchaseId: string): void {
    this.store.dispatch(purchaseActions.getMyPurchase({ purchaseId }));
  }

  public setPaymentStarted(purchaseId: string): void {
    this.store.dispatch(purchaseActions.setPaymentStarted({ purchaseId }));
  }

  public verifyPayment(purchaseId: string): void {
    this.store.dispatch(purchaseActions.verifyPayment({ purchaseId }));
  }

  public getMyPurchaseList(): void {
    this.store.dispatch(purchaseActions.getMyPurchaseList());
  }

  public getPurchaseListOfUser(buyerId: string): void {
    this.store.dispatch(purchaseActions.getPurchaseListOfUser({ buyerId }));
  }
}
