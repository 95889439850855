<ng-container *ngIf="userProcess as up">
  <ng-container *ngIf="!up.hasAssistantService && !up.hasFullHandling">
    {{ 'STATUS_BY_PURCHASED_SERVICE.BASIC_PROCESS_STATUS.' + up.status | translate }}
  </ng-container>
  <ng-container *ngIf="up.hasAssistantService && !up.hasFullHandling">
    {{ 'STATUS_BY_PURCHASED_SERVICE.ASSISTANT_PROCESS_STATUS.' + up.status | translate }}
  </ng-container>
  <ng-container *ngIf="up.hasAssistantService && up.hasFullHandling">
    {{ 'STATUS_BY_PURCHASED_SERVICE.FULL_PROCESS_STATUS.' + up.status | translate }}
  </ng-container>
</ng-container>
