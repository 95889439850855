<div class="title-container">
  <div class="row">
    <h2 mat-dialog-title>{{ 'NT.DOCUMENTS_SENT_FOR_FINAL_REVIEW' | translate }}</h2>
    <mat-spinner *ngIf="(loading$ | async) || (loadingItemsSentForReview$ | async)" [diameter]="35"> </mat-spinner>
    <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>
  <div class="row">
    <h3 class="subtitle">
      {{ 'NT.APPLICATION_OF' | translate }}
      <b>{{ data.userProcess.personalDetails.name || '' }} {{ data.userProcess.personalDetails.surname || '' }}</b>
    </h3>
  </div>
</div>

<mat-dialog-content class="mat-typography" #dialogContent>
  <div class="list-wrapper">
    <div
      class="list-item"
      *ngFor="let userAsset of data.reviewItems; index as userAssetIndex"
      (click)="openFile(userAsset)">
      <div class="list-item-index">{{ userAssetIndex + 1 }}.</div>
      <div class="list-item-name">
        <mat-icon svgIcon="document"></mat-icon>
        <span>{{ userAsset.fileOriginalName | truncate: 60 : false }}</span>
      </div>
      <div class="list-item-meta">
        <span>{{
          'NT.UPLOADED_AT' | translate: { uploadedAt: (userAsset.createdAt | date: 'dd.MM.yyyy HH:mm') }
        }}</span>
      </div>
    </div>
  </div>
  <div class="loading-items-wrapper" *ngIf="loadingItemsSentForReview$ | async">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="action-section-wrapper">
    <div class="comment-input-title">
      <span> {{ 'NT.REVIEW_ITEMS_COMMENT' | translate }}</span>
    </div>
    <div class="comment-input-wrapper">
      <mat-form-field class="comment-input" appearance="outline">
        <textarea
          [appAutoFocus]="deviceInfo.deviceTypeDetected === 'DESKTOP'"
          matInput
          #commentInput
          #autosize="cdkTextareaAutosize"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="6"
          autocomplete="off"
          autocapitalize="off"
          [(ngModel)]="comment"
          maxlength="1500"
          minlength="1">
        </textarea>
      </mat-form-field>
    </div>
    <div class="buttons-wrapper">
      <button mat-dialog-close mat-raised-button class="close-button">
        {{ 'COMMON.CLOSE' | translate }}
      </button>

      <button
        (click)="saveAndNotify(comment)"
        [disabled]="
          comment === data.userProcess.documentsReviewComment ||
          (comment === '' && data.userProcess.documentsReviewComment === null)
        "
        mat-raised-button
        color="primary"
        class="save-button">
        {{ 'NT.SAVE_AND_NOTIFY' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
