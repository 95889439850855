import { Action, createReducer, on } from '@ngrx/store';
import { initialState, VoivodeshipsState } from './voivodeships.state';
import * as voivodeshipsActions from './voivodeships.actions';

const reducer = createReducer(
  initialState,
  on(voivodeshipsActions.getVoivodeships, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),

  on(voivodeshipsActions.getVoivodeshipsSuccess, (state, { voivodeships }) => {
    const voivos = structuredClone(voivodeships);
    voivos.sort((voivoA, voivoB) => {
      const vA = voivoA.key.toLowerCase();
      const vB = voivoB.key.toLowerCase();
      if (vB < vA) {
        return 1;
      }
      return -1;
    });
    return {
      ...state,
      loading: false,
      errorMessage: null,
      availableVoivodeships: voivos,
    };
  }),

  on(voivodeshipsActions.getVoivodeshipsError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(
    voivodeshipsActions.updateVoivodeship,
    voivodeshipsActions.addVoivodeshipOffice,
    voivodeshipsActions.editVoivodeshipOffice,
    voivodeshipsActions.removeVoivodeshipOffice,
    state => ({ ...state, loading: true, errorMessage: null })
  ),
  on(voivodeshipsActions.updateVoivodeshipSuccess, (state, { updatedVoivodeship }) => {
    return {
      ...state,
      availableVoivodeships: state.availableVoivodeships.map(voivoInState =>
        voivoInState.id === updatedVoivodeship.id ? { ...updatedVoivodeship } : voivoInState
      ),
      loading: false,
      errorMessage: null,
    };
  }),
  on(
    voivodeshipsActions.updateVoivodeshipError,
    voivodeshipsActions.addVoivodeshipOfficeError,
    voivodeshipsActions.editVoivodeshipOfficeError,
    voivodeshipsActions.removeVoivodeshipOfficeError,
    (state, { errorMessage }) => ({ ...state, loading: false, errorMessage })
  ),

  on(voivodeshipsActions.addVoivodeshipOfficeSuccess, (state, { addedOffice, voivoId }) => {
    return {
      ...state,
      availableVoivodeships: state.availableVoivodeships.map(voivoInState =>
        voivoInState.id === voivoId
          ? {
              ...voivoInState,
              offices: [...voivoInState.offices, addedOffice],
            }
          : voivoInState
      ),
      loading: false,
      errorMessage: null,
    };
  }),
  on(voivodeshipsActions.editVoivodeshipOfficeSuccess, (state, { updatedOffice, voivoId }) => {
    return {
      ...state,
      availableVoivodeships: state.availableVoivodeships.map(voivoInState =>
        voivoInState.id === voivoId
          ? {
              ...voivoInState,
              offices: voivoInState.offices.map(office =>
                office.id === updatedOffice.id ? { ...updatedOffice } : office
              ),
            }
          : voivoInState
      ),
      loading: false,
      errorMessage: null,
    };
  }),
  on(voivodeshipsActions.removeVoivodeshipOfficeSuccess, (state, { officeId, voivoId }) => {
    return {
      ...state,
      availableVoivodeships: state.availableVoivodeships.map(voivoInState =>
        voivoInState.id === voivoId
          ? { ...voivoInState, offices: voivoInState.offices.filter(office => office.id !== officeId) }
          : voivoInState
      ),
      loading: false,
      errorMessage: null,
    };
  }),

  on(voivodeshipsActions.searchDocuments, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(voivodeshipsActions.searchDocumentsSuccess, (state, { documents }) => ({
    ...state,
    voivodeshipDocuments: documents,
    loading: false,
    errorMessage: null,
  })),
  on(voivodeshipsActions.searchDocumentsError, (state, { errorMessage, opts }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(voivodeshipsActions.updateDocEnabled, voivodeshipsActions.updateDocEnabledBulk, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(voivodeshipsActions.updateDocEnabledSuccess, (state, { updatedDoc }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    voivodeshipDocuments: state.voivodeshipDocuments.map(doc => (doc.id === updatedDoc.id ? { ...updatedDoc } : doc)),
  })),
  on(voivodeshipsActions.updateDocEnabledBulkSuccess, (state, { updatedDocs }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    voivodeshipDocuments: state.voivodeshipDocuments.map(doc => {
      const toReplace = updatedDocs.find(updatedDoc => updatedDoc.id === doc.id);
      return toReplace ? { ...toReplace } : { ...doc };
    }),
  })),
  on(voivodeshipsActions.updateDocEnabledError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(voivodeshipsActions.getDistricts, (state, { opts: { voivoId } }) => ({
    ...state,
    loadingDistricts: true,
    loadedDistricts: { forVoivoId: voivoId, districts: [] },
  })),
  on(voivodeshipsActions.getDistrictsSuccess, (state, { districts, voivoId }) => ({
    ...state,
    loadingDistricts: false,
    loadedDistricts: { forVoivoId: voivoId, districts },
  })),
  on(voivodeshipsActions.getMunicips, (state, { opts: { districtId } }) => ({
    ...state,
    loadingMunicips: true,
    loadedMunicips: { forDistrictId: districtId, municips: [] },
  })),
  on(voivodeshipsActions.getMunicipsSuccess, (state, { municips, districtId }) => ({
    ...state,
    loadingMunicips: false,
    loadedMunicips: { forDistrictId: districtId, municips },
  })),
  on(voivodeshipsActions.getCities, (state, { opts: { municipId } }) => ({
    ...state,
    loadingCities: true,
    loadedCities: { forMunicipId: municipId, cities: [] },
  })),
  on(voivodeshipsActions.getCitiesSuccess, (state, { municipId, cities }) => ({
    ...state,
    loadingCities: false,
    loadedCities: { forMunicipId: municipId, cities },
  })),
  on(voivodeshipsActions.downloadVoivodeshipFileInstruction, state => ({
    ...state,
    loadingVoivodeshipFileInstructionAssets: true,
  })),
  on(voivodeshipsActions.downloadVoivodeshipFileInstructionSuccess, state => ({
    ...state,
    loadingVoivodeshipFileInstructionAssets: false,
  })),
  on(voivodeshipsActions.downloadVoivodeshipFileInstructionError, state => ({
    ...state,
    loadingVoivodeshipFileInstructionAssets: false,
  })),
  on(voivodeshipsActions.uploadVoivodeshipFileInstruction, state => ({
    ...state,
    loadingVoivodeshipFileInstructionAssets: true,
  })),
  on(voivodeshipsActions.uploadVoivodeshipFileInstructionSuccess, (state, { availableVoivodeships }) => ({
    ...state,
    loadingVoivodeshipFileInstructionAssets: false,
    availableVoivodeships,
  })),
  on(voivodeshipsActions.uploadVoivodeshipFileInstructionError, state => ({
    ...state,
    loadingVoivodeshipFileInstructionAssets: false,
  }))
);

export function voivodeshipsReducer(state: VoivodeshipsState, action: Action): VoivodeshipsState {
  return reducer(state, action);
}
