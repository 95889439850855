import { City, District, Municipality, ProcessDocument, Voivodeship } from '@interfaces';

export type VoivodeshipsState = Readonly<{
  loading: boolean;
  errorMessage: string;
  availableVoivodeships: Voivodeship[];
  voivodeshipDocuments: ProcessDocument[];

  loadingDistricts: boolean;
  loadedDistricts: { forVoivoId: string; districts: District[] };

  loadingMunicips: boolean;
  loadedMunicips: { forDistrictId: string; municips: Municipality[] };

  loadingCities: boolean;
  loadedCities: { forMunicipId: string; cities: City[] };

  loadingVoivodeshipFileInstruction: boolean;
}>;

export const initialState: VoivodeshipsState = {
  loading: false,
  errorMessage: null,
  availableVoivodeships: null,
  voivodeshipDocuments: null,

  loadingDistricts: false,
  loadedDistricts: { forVoivoId: null, districts: [] },
  loadingMunicips: false,
  loadedMunicips: { forDistrictId: null, municips: [] },
  loadingCities: false,
  loadedCities: { forMunicipId: null, cities: [] },

  loadingVoivodeshipFileInstruction: false,
};
