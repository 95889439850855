import { UserAsset } from '@interfaces';

export type ReviewState = Readonly<{
  reviewItems: {
    // file.name -> name on the computer of uploader -> we will make sure that no duplicates are allowed
    [key: string]: {
      error: any;
      loading: boolean;
      uploaded: boolean;
      userAsset: UserAsset;
    };
  };

  userProcessId: string;
  loadingReviewItems: boolean;
  downloadInProgress: string | null; // fileOriginalName or null
}>;

export const initialState: ReviewState = {
  reviewItems: {},
  userProcessId: null,
  loadingReviewItems: false,
  downloadInProgress: null,
};
