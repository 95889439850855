import { CustomInstructionItem, InstructionItem, InstructionItemInProcess } from '@interfaces';

export type InstructionItemsState = Readonly<{
  allInstructionItems: InstructionItem[];
  loadedInstructionItem: InstructionItem;

  instructionItemsInProcess: InstructionItemInProcess[];

  itemsListForCustomization: (CustomInstructionItem | InstructionItemInProcess)[];

  loading: boolean;
  errorMessage: string;
}>;

export const initialState: InstructionItemsState = {
  allInstructionItems: null,
  loadedInstructionItem: null,
  instructionItemsInProcess: null,

  itemsListForCustomization: null,

  loading: false,
  errorMessage: null,
};
