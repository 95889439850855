<div class="members-wrapper" #mainMembersWrapper>
  <div class="member-row-wrapper" *ngFor="let formGroup of membersArray; index as formGroupIndex">
    <div class="member-row" [formGroup]="formGroup" [id]="'formGroupWrapper-' + formGroupIndex">
      <div class="standard-form-field member-name-field required-field" *ngIf="formGroup.get('name') as fCtrl">
        <label [for]="'familyMemberName' + formGroupIndex" [class.invalid]="fCtrl.touched && fCtrl.invalid">
          {{ 'PERSONAL_DATA_FORM.NAME.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <input
            [id]="'familyMemberName' + formGroupIndex"
            matInput
            [required]="true"
            placeholder="{{ 'PERSONAL_DATA_FORM.NAME.PLACEHOLDER' | translate }}"
            formControlName="name" />
          <mat-icon
            #familyMemberName="matTooltip"
            (click)="$event.preventDefault(); $event.stopPropagation(); familyMemberName.toggle()"
            class="info-suffix-icon"
            matSuffix
            matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
            matTooltipClass="personalDataFormTooltip">
            info_outline
          </mat-icon>
          <div class="mat-error" *ngIf="fCtrl.touched && fCtrl.invalid">
            {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
          </div>
        </mat-form-field>
      </div>
      <div class="standard-form-field member-surname-field required-field" *ngIf="formGroup.get('surname') as fCtrl">
        <label [for]="'familyMemberSurname' + formGroupIndex" [class.invalid]="fCtrl.touched && fCtrl.invalid">
          {{ 'PERSONAL_DATA_FORM.SURNAME.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <input
            [id]="'familyMemberSurname' + formGroupIndex"
            matInput
            [required]="true"
            placeholder="{{ 'PERSONAL_DATA_FORM.SURNAME.PLACEHOLDER' | translate }}"
            formControlName="surname" />
          <mat-icon
            #familyMemberSurname="matTooltip"
            (click)="$event.preventDefault(); $event.stopPropagation(); familyMemberSurname.toggle()"
            class="info-suffix-icon"
            matSuffix
            matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
            matTooltipClass="personalDataFormTooltip">
            info_outline
          </mat-icon>
          <div class="mat-error" *ngIf="fCtrl.touched && fCtrl.invalid">
            {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
          </div>
        </mat-form-field>
      </div>
      <div class="standard-form-field member-sex-field required-field" *ngIf="formGroup.get('sex') as fCtrl">
        <label [for]="'familyMemberSex' + formGroupIndex" [class.invalid]="fCtrl.touched && fCtrl.invalid">
          {{ 'PERSONAL_DATA_FORM.SEX.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            [id]="'familyMemberSex' + formGroupIndex"
            panelClass="standard-select"
            placeholder="{{ 'PERSONAL_DATA_FORM.SEX.PLACEHOLDER' | translate }}"
            formControlName="sex"
            [required]="true">
            <mat-option *ngFor="let item of selectsValues.SEX" [value]="item.value">
              {{ item.label }}
            </mat-option>
          </mat-select>
          <div class="mat-error" *ngIf="fCtrl.invalid && fCtrl.touched">
            {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
          </div>
        </mat-form-field>
      </div>
      <div class="standard-form-field member-dob-field required-field" *ngIf="formGroup.get('dateOfBirth') as fCtrl">
        <label [for]="'familyMemberDoB' + formGroupIndex" [class.invalid]="fCtrl.touched && fCtrl.invalid">
          {{ 'PERSONAL_DATA_FORM.DATE-OF-BIRTH.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline" (click)="dobPicker.open()">
          <input
            matInput
            [id]="'familyMemberDoB' + formGroupIndex"
            [matDatepicker]="dobPicker"
            (focus)="dobPicker.open()"
            placeholder="{{ 'PERSONAL_DATA_FORM.DATE-OF-BIRTH.PLACEHOLDER' | translate }}"
            (click)="$event.preventDefault()"
            [min]="minValidDate"
            [max]="maxValidDate"
            [required]="true"
            readonly
            formControlName="dateOfBirth" />
          <div class="mat-error" *ngIf="fCtrl.touched && fCtrl.invalid">
            {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
          </div>
          <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
          <mat-datepicker #dobPicker [startView]="'month'" [startAt]="'1990'" [calendarHeaderComponent]="pickerHeader">
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div class="standard-form-field citizenship-field required-field">
        <app-country-select
          class="country-select"
          [fieldControl]="getControl('citizenship', formGroupIndex)"
          [fieldKey]="'fm-member-' + formGroupIndex + '-citizenship'"
          [isRequired]="true"
          [fieldLabelKey]="'CITIZENSHIP'">
        </app-country-select>
      </div>
      <div class="standard-form-field residence-field required-field" *ngIf="formGroup.get('residencePlace') as fCtrl">
        <label [for]="'familyMemberResidencePlace' + formGroupIndex" [class.invalid]="fCtrl.touched && fCtrl.invalid">
          {{ 'PERSONAL_DATA_FORM.PLACE-OF-RESIDENCE.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <input
            [id]="'familyMemberResidencePlace' + formGroupIndex"
            matInput
            [required]="true"
            placeholder="{{ 'PERSONAL_DATA_FORM.PLACE-OF-RESIDENCE.PLACEHOLDER' | translate }}"
            formControlName="residencePlace" />
          <mat-icon
            #familyMemberResidencePlace="matTooltip"
            (click)="$event.preventDefault(); $event.stopPropagation(); familyMemberResidencePlace.toggle()"
            class="info-suffix-icon"
            matSuffix
            matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
            matTooltipClass="personalDataFormTooltip">
            info_outline
          </mat-icon>
          <div class="mat-error" *ngIf="fCtrl.touched && fCtrl.invalid">
            {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
          </div>
        </mat-form-field>
      </div>
      <div class="standard-form-field kinship-field required-field" *ngIf="formGroup.get('kinshipDegree') as fCtrl">
        <label [for]="'familyMemberKinshipDegree' + formGroupIndex" [class.invalid]="fCtrl.touched && fCtrl.invalid">
          {{ 'PERSONAL_DATA_FORM.KINSHIP-DEGREE.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            [id]="'familyMemberKinshipDegree' + formGroupIndex"
            formControlName="kinshipDegree"
            [required]="true"
            panelClass="standard-select"
            placeholder="{{ 'PERSONAL_DATA_FORM.KINSHIP-DEGREE.PLACEHOLDER' | translate }}">
            <mat-option *ngFor="let item of selectsValues.KINSHIP" [value]="item.value">
              {{ item.label }}
            </mat-option>
          </mat-select>
          <div class="mat-error" *ngIf="fCtrl.invalid && fCtrl.touched">
            {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
          </div>
        </mat-form-field>
      </div>
      <div
        class="standard-form-field tempPermit-field required-field"
        *ngIf="formGroup.get('tempPermitApplying') as fCtrl">
        <label
          [for]="'familyMemberTempPermitApplying' + formGroupIndex"
          [class.invalid]="fCtrl.touched && fCtrl.invalid">
          {{ 'PERSONAL_DATA_FORM.APPLYING-FOR-TEMPORARY-PERMIT.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            [id]="'familyMemberTempPermitApplying' + formGroupIndex"
            formControlName="tempPermitApplying"
            panelClass="standard-select"
            placeholder="{{ 'PERSONAL_DATA_FORM.APPLYING-FOR-TEMPORARY-PERMIT.PLACEHOLDER' | translate }}"
            [required]="true">
            <mat-option *ngFor="let item of selectsValues.YES_NO" [value]="item.value">
              {{ item.label }}
            </mat-option>
          </mat-select>
          <div class="mat-error" *ngIf="fCtrl.invalid && fCtrl.touched">
            {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
          </div>
        </mat-form-field>
      </div>
      <div
        class="standard-form-field dependent-field required-field"
        *ngIf="formGroup.get('dependentOnTheForeigner') as fCtrl">
        <label
          [for]="'familyMemberDependentOnTheForeigner' + formGroupIndex"
          [class.invalid]="fCtrl.touched && fCtrl.invalid">
          {{ 'PERSONAL_DATA_FORM.DEPENDENT-ON-FOREIGNER.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            [id]="'familyMemberDependentOnTheForeigner' + formGroupIndex"
            panelClass="standard-select"
            formControlName="dependentOnTheForeigner"
            placeholder="{{ 'PERSONAL_DATA_FORM.DEPENDENT-ON-FOREIGNER.PLACEHOLDER' | translate }}"
            [required]="true">
            <mat-option *ngFor="let item of selectsValues.YES_NO" [value]="item.value">
              {{ item.label }}
            </mat-option>
          </mat-select>
          <div class="mat-error" *ngIf="fCtrl.invalid && fCtrl.touched">
            {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
          </div>
        </mat-form-field>
      </div>
    </div>
    <div class="action-buttons" *ngIf="groupStatus[formGroupIndex] as groupStatus">
      <!-- Show add member button only under last member -->
      <button
        *ngIf="formGroupIndex === membersArray.length - 1"
        [disabled]="groupStatus.showLoading"
        (click)="addNewMember()"
        (keydown.Tab)="lastFieldTabPressed.emit($event)"
        class="standard-form-button add-member-button">
        {{ 'FAMILY_MEMBERS_FORM.ADD-NEW-MEMBER' | translate }}
      </button>

      <div
        class="remove-member-button-container"
        matTooltip="{{ 'APPLICATION.CANNOT-REMOVE-LAST-MEMBER' | translate }}"
        matTooltipClass="personalDataFormTooltip"
        [matTooltipDisabled]="membersArray.length > 1">
        <button
          mat-icon-button
          [disabled]="membersArray.length === 1"
          (click)="showConfirm(formGroupIndex)"
          color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <div class="loading-wrapper" *ngIf="groupStatus.showLoading">
        <mat-spinner id="personalDetailsFamilyMemberSavingLoading-{{ formGroupIndex }}" [diameter]="35"></mat-spinner>
      </div>

      <div class="notification-wrapper saved-notification saved-fm-{{ formGroupIndex }}" *ngIf="groupStatus.showSaved">
        <mat-icon svgIcon="check-outline"></mat-icon>
        <span class="text">{{ 'FAMILY_MEMBERS_FORM.MEMBER-SAVED-SUCCESFULLY' | translate }} </span>
      </div>

      <div
        class="notification-wrapper awaiting-notification"
        *ngIf="!groupStatus.showSaved && !groupStatus.showLoading && !groupStatus.showError">
        <mat-icon svgIcon="info-outline"></mat-icon>
        <span class="text">{{ 'COUNTRIES_VISITS_FORM.AWAITING-FOR-ALL-REQUIRED-DATA' | translate }} </span>
      </div>

      <div class="notification-wrapper error-notification" *ngIf="groupStatus.showError">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span class="text">{{ 'NT.ERROR_SAVING_FORM' | translate }} </span>
      </div>
    </div>
  </div>
</div>
