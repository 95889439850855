import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserAuthFacade } from '@state/user-auth';
import { PermissionEnum, User } from '@interfaces';

@Directive({
  selector: '[appUserDetailsLink]',
})
export class UserDetailsLinkDirective implements OnInit {
  @Input() requiredPermissions: PermissionEnum[] = [];

  constructor(
    private readonly userAuthFacade: UserAuthFacade,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.updateLinkState();
  }

  private async updateLinkState(): Promise<void> {
    const myself = await firstValueFrom(this.userAuthFacade.myself$.pipe(filter(myself => !!myself?.id)));

    const hasAccess = this.hasAllPermissions(myself, this.requiredPermissions);

    if (!hasAccess) {
      const element = this.el.nativeElement;

      this.renderer.addClass(element, 'non-clickable');
    }
  }

  private hasAllPermissions(user: User, requiredPermissions: PermissionEnum[]) {
    return requiredPermissions.every(permission =>
      user.role.permissions.some(userPermission => userPermission.key === permission)
    );
  }
}
