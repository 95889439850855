import { Injectable } from '@angular/core';
import { LocalStorage } from '@ng-idle/core';

@Injectable()
export class IdleStorage extends LocalStorage {
  private superKey = 'NE_';
  private transformedKeys: { [key: string]: string } = {
    'main.expiry': 'HCWD',
    'main.idling': 'IMDHCWD',
  };

  constructor() {
    super();
  }
  /*
   * Gets an item in the storage.
   *
   * @param value - The value to get.
   * @return The current value.
   */
  getItem(key: string): string | null {
    // tslint:disable-next-line: no-string-literal
    return this['storage'].getItem(`${this.superKey}${this.transformedKeys[key] || key}`);
  }

  /*
   * Removes an item in the storage.
   *
   * @param value - The value to remove.
   */
  removeItem(key: string): void {
    // tslint:disable-next-line: no-string-literal
    this['storage'].removeItem(`${this.superKey}${this.transformedKeys[key] || key}`);
  }

  /*
   * Sets an item in the storage.
   *
   * @param key - The key to set the value.
   * @param value - The value to set to the key.
   */
  setItem(key: string, data: string): void {
    // tslint:disable-next-line: no-string-literal
    this['storage'].setItem(`${this.superKey}${this.transformedKeys[key] || key}`, data);
  }

  /*
   * Represents the storage, commonly use for testing purposes.
   *
   * @param key - The key to set the value.
   * @param value - The value to set to the key.
   */
  _wrapped(): Storage {
    // tslint:disable-next-line: no-string-literal
    return this['storage'];
  }
}
