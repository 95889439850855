export interface P24_ERROR {
  data?: boolean;
  error: string;
  code?: number;
}

export interface P24_PAYMENT_METHOD {
  name: string;
  id: number;
  group: string;
  subgroup: string;
  status: boolean;
  imgUrl: string;
  mobileImgUrl: string;
  mobile: boolean;
  availabilityHours: {
    mondayToFriday: string;
    saturday: string;
    sunday: string;
  };
}

export interface P24_GET_PAYMENT_METHODS_RESPONSE {
  data: P24_PAYMENT_METHOD[];
  agreements: any[];
  responseCode: string;
}

export interface GetAvailablePaymentMethodsOpts {
  langKey: 'pl' | 'en';
  amount: number;
}
