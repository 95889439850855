import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupportChatState } from './support-chat.state';

const selectSupportChatState = createFeatureSelector<SupportChatState>('supportChat');

export const selectSupportChats = createSelector(selectSupportChatState, state => state.supportChats);
export const selectPagination = createSelector(selectSupportChatState, state => state.pagination);

export const selectUnreadCount = createSelector(selectSupportChatState, state => state.unreadCount);

export const selectLoadingSupportChats = createSelector(selectSupportChatState, state => state.loadingSupportChats);
export const selectLoadingMoreSupportChats = createSelector(
  selectSupportChatState,
  state => state.loadingMoreSupportChats
);

export const selectErrorMessage = createSelector(selectSupportChatState, state => state.errorMessage);

export const selectOpenSupportChat = createSelector(selectSupportChatState, state => state.openSupportChat);
export const selectLoadingOpenSupportChat = createSelector(
  selectSupportChatState,
  state => state.loadingOpenSupportChat
);

export const selectLoadingSupportChatUpdate = createSelector(
  selectSupportChatState,
  state => state.loadingSupportChatUpdate
);
