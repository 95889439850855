export enum GUIDE_NAME {
  'FIRST_TIME' = 'FIRST_TIME',
  'PRESURVEY_INTRO' = 'PRESURVEY_INTRO',
  'PROCESS_INTRO' = 'PROCESS_INTRO',
  'PERSONAL_DATA_FORM' = 'PERSONAL_DATA_FORM',
  'FORM_STEP_2' = 'FORM_STEP_2',
  'FORM_STEP_3' = 'FORM_STEP_3',
  'FORM_STEP_4' = 'FORM_STEP_4',
  'DOCUMENTS' = 'DOCUMENTS',
  'SUMMARY_VISIT_DATE_PICKER' = 'SUMMARY_VISIT_DATE_PICKER',
  'FIELD_CONSULTANT_TABLE' = 'FIELD_CONSULTANT_TABLE',
}

export const formStepToGuideName = [
  GUIDE_NAME.PERSONAL_DATA_FORM,
  GUIDE_NAME.FORM_STEP_2,
  GUIDE_NAME.FORM_STEP_3,
  GUIDE_NAME.FORM_STEP_4,
];

export interface GuideState {
  active: boolean;
}

export interface GuideFinishedOpts {
  guideName: GUIDE_NAME;
  cancelled: boolean;
  completed: boolean;
}
