import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CreateSupportMessageOpts,
  FindSupportChatsOpts,
  MarkSupportChatOpts,
  PaginatedResponse,
  SupportChat,
  SupportChatMessage,
} from '@interfaces';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class SupportChatHttpService {
  private API_URL = environment.API_URL;

  private BASE_PATH = `${this.API_URL}/support-chat`;

  constructor(private http: HttpClient) {}

  public createSupportChat(opts: { supportedUserId: string }): Observable<SupportChat> {
    const url = `${this.BASE_PATH}/new`;
    return this.http.post<SupportChat>(url, { ...opts });
  }

  public searchSupportChats(opts: FindSupportChatsOpts): Observable<PaginatedResponse<SupportChat>> {
    const url = `${this.BASE_PATH}/search`;
    const { all, count, ...correctOpts } = opts;
    return this.http.post<PaginatedResponse<SupportChat>>(url, { ...correctOpts });
  }

  public openSupportChat(
    supportChatId: string
  ): Observable<{ supportChat: SupportChat; messages: SupportChatMessage[] }> {
    const url = `${this.BASE_PATH}/${supportChatId}/open`;
    return this.http.get<{ supportChat: SupportChat; messages: SupportChatMessage[] }>(url);
  }

  public closeSupportChat(supportChatId: string): Observable<{ supportChat: SupportChat }> {
    const url = `${this.BASE_PATH}/${supportChatId}/close`;
    return this.http.get<{ supportChat: SupportChat }>(url);
  }

  public addSupportChatMarker(opts: MarkSupportChatOpts): Observable<SupportChat> {
    const { marker, supportChatId } = opts;
    const url = `${this.BASE_PATH}/${supportChatId}/marker`;
    return this.http.patch<SupportChat>(url, { marker });
  }

  public markSupportChatAsUnread(supportChatId: string): Observable<SupportChat> {
    const url = `${this.BASE_PATH}/${supportChatId}/mark-unread`;
    return this.http.get<SupportChat>(url);
  }

  public markSupportChatAsRead(supportChatId: string): Observable<SupportChat> {
    const url = `${this.BASE_PATH}/${supportChatId}/mark-read`;
    return this.http.get<SupportChat>(url);
  }

  public createSupportMessage(opts: CreateSupportMessageOpts): Observable<SupportChatMessage> {
    const { content, supportChatId, attachments } = opts;
    const url = `${this.BASE_PATH}/${supportChatId}/messages/new`;
    return this.http.post<SupportChatMessage>(url, { content, attachments });
  }

  public editSupportMessage(supportMessageId: string, opts: CreateSupportMessageOpts): Observable<SupportChatMessage> {
    const { content, supportChatId, attachments } = opts;
    const url = `${this.BASE_PATH}/${supportChatId}/messages/${supportMessageId}`;
    return this.http.patch<SupportChatMessage>(url, { content, attachments });
  }

  public removeSupportMessage(supportChatId: string, supportMessageId: string): Observable<{ success: boolean }> {
    const url = `${this.BASE_PATH}/${supportChatId}/messages/${supportMessageId}`;
    return this.http.delete<{ success: boolean }>(url);
  }

  public downloadAttachmentFromChat(
    supportChatId: string,
    messageId: string,
    attachmentId: string
  ): Observable<HttpResponse<Blob>> {
    const url = `${this.BASE_PATH}/${supportChatId}/messages/${messageId}/attachments/${attachmentId}`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public removeExistingMessageAttachment(opts: {
    supportChatId: string;
    supportMessageId: string;
    attachmentId: string;
  }): Observable<SupportChatMessage> {
    const { attachmentId, supportChatId, supportMessageId } = opts;
    const url = `${this.BASE_PATH}/${supportChatId}/messages/${supportMessageId}/attachments/${attachmentId}`;
    return this.http.delete<SupportChatMessage>(url);
  }

  public getMyUnreadCount(): Observable<{ count: number }> {
    const url = `${this.BASE_PATH}/my-unread-count`;
    return this.http.get<{ count: number }>(url);
  }

  public getTranscription(supportChatId: string): Observable<{ success: boolean }> {
    const url = `${this.BASE_PATH}/${supportChatId}/transcription`;
    return this.http.get<{ success: boolean }>(url);
  }
}
