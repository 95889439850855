import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from '@state';
import { CreateMessageInMyChat, UploadMessageAttachmentsOpts } from '@interfaces';
import * as chatActions from './chat.actions';
import * as chatSelectors from './chat.selectors';

@Injectable()
export class ChatFacade {
  public myChat$ = this.store.select(chatSelectors.selectChat);
  public chatMessages$ = this.store.select(chatSelectors.selectChatMessages);
  public attachmentsToAdd$ = this.store.select(chatSelectors.selectAttachmentsToAdd);

  public loading$ = this.store.select(chatSelectors.selectLoading);
  public loadingAttachments$ = this.store.select(chatSelectors.selectLoadingAttachments);
  public errorMessage$ = this.store.select(chatSelectors.selectErrorMessage);

  public addChatMessageSuccess$ = this.actions.pipe(ofType(chatActions.addChatMessageSuccess));
  public getMyChatSuccess$ = this.actions.pipe(ofType(chatActions.getMyChatSuccess));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getMyChat(): void {
    this.store.dispatch(chatActions.getMyChat());
  }

  public addChatMessage(opts: CreateMessageInMyChat): void {
    this.store.dispatch(chatActions.addChatMessage({ opts }));
  }

  public uploadMessageAttachments(opts: UploadMessageAttachmentsOpts): void {
    this.store.dispatch(chatActions.uploadAttachments({ opts }));
  }

  public downloadAttachmentFromChat(messageId: string, attachmentId: string): void {
    this.store.dispatch(chatActions.downloadAttachmentFromChat({ messageId, attachmentId }));
  }

  public removeAttachmentFromUploading(opts: { userAssetId: string; fileOriginalName: string }): void {
    this.store.dispatch(chatActions.removeAttachmentFromUploading({ opts }));
  }
}
