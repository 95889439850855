export interface ConsumableCountry {
  id: number;
  name: string; // always in PL -> used for all docs generation
  nationality: string; // always in PL -> used for all docs generation
  nameTranslations: {
    pl: string;
    en: string;
    uk: string;
    ru: string;
  };
  nationalityTranslations: {
    pl: string;
    en: string;
    uk: string;
    ru: string;
  };
  alpha2: string;
  alpha3: string;
}
