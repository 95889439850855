import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { AvailableLanguages } from '@constants';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { AnyTxtRecord } from 'dns';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject();

  public ofLabel = 'of';
  public getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };

  constructor(private translateService: TranslateService) {
    super();

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      const currentLang = langKey as AvailableLanguages;
      this.getAndInitTranslations(currentLang);
    });

    this.getAndInitTranslations(this.translateService.currentLang as AvailableLanguages);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getAndInitTranslations(currentLang: AvailableLanguages): void {
    const translation = this.translateService.translations[currentLang].PAGINATOR;
    this.itemsPerPageLabel = translation.ITEMS_PER_PAGE;
    this.nextPageLabel = translation.NEXT_PAGE;
    this.previousPageLabel = translation.PREVIOUS_PAGE;
    this.ofLabel = translation.OF_LABEL;
    this.changes.next();
  }
}
