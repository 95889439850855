import { DiscountCode, Product, User, UserAsset, UserProcess } from '@interfaces';

export interface PaymentInfo {
  p24_methodId: number;
  p24_orderId: number;
  currency: 'PLN';
  description: string;
  // transferLabel: string;
  email: string;
  language: string;

  p24_transactionInfo?: {
    statement?: string;
    dateOfTransaction?: string;
  };

  // Full P24 payment link with token
  paymentLink?: string | null;
  lastPaymentStatus: number;
}
export interface Purchase {
  id: string;

  token?: string | null; // only for employees
  externalId?: string | null; // only for employees - Exists only after starting payment process with P24
  user?: User;
  userId: string;
  products: ProductInPurchase[];
  totalAmountToPayBeforeDiscount: number; // In PLN Groszy if no discount - same as totalAmountToPay
  totalAmountToPay: number; // In PLN Groszy
  totalAmountPaid?: number; // In PLN Groszy
  status: PurchaseStatus;
  paymentInfo?: PaymentInfo | null;

  userProcesses?: Partial<UserProcess>[];
  purchaseDocument: Partial<UserAsset>;
  purchaseDocumentId: string;

  discountCode?: DiscountCode;
  discountCodeId?: string;

  discountCodeUsed?: DiscountCode;
  discountCodeUsedId?: string;
  discountCodeUsedSnapshot?: Partial<DiscountCode>;

  createdAt?: Date;
  updatedAt?: Date;
}

export interface PurchaseHistory {
  id: string;
  purchase?: Purchase;
  purchaseId: string;
  user?: User;
  userId: string;
  changeType: PurchaseChange;
  beforeChange: Purchase;
  afterChange: Purchase;
  changeMadeAt: Date;
}

export interface ProductInPurchase extends Product {
  quantity: number;
  startingTotalAmount?: number; // basePrice x quantity
  finalTotalAmount?: number; // (basePrice x quantity) - calculated discount -> price to pay for particular product in purchase
  assignedUserProcesses?: string[]; // array of id's of created processes after succesfull processing of purchase
}

export enum PurchaseStatus {
  OPENNED = 'OPENNED', // User agreed to all ToS and confirmed purchase 'Order with payment requirement'
  PAYMENT_STARTED = 'PAYMENT_STARTED', // User has been redirected to Payment-Operator or clicked 'Make payment'
  PAYMENT_MADE_AWAITING_VERIFICATION = 'PAYMENT_MADE_AWAITING_VERIFICATION', // User got back from Payment-Operator - payment not verified yet
  PAYMENT_TIMED_OUT = 'PAYMENT_TIMED_OUT', // User did not make payment in time limit
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED', // User cancelled making payment
  PAYMENT_ERROR = 'PAYMENT_ERROR', // Issue processing payments
  PAYMENT_VERIFIED = 'PAYMENT_VERIFIED', // Payment confirmed
  CLOSED = 'CLOSED', // purchase finished. no more modifications possible.
}

export enum PurchaseChange {
  PURCHASE_OPENNED = 'PURCHASE_OPENNED',
  PURCHASE_PRODUCTS_CHANGED = 'PURCHASE_PRODUCTS_CHANGED',
  PURCHASE_PAYMENT_STATUS_CHANGED = 'PURCHASE_PAYMENT_STATUS_CHANGED',
  PURCHASE_CLOSED = 'PURCHASE_CLOSED',
}

export interface NewPurchaseOpts {
  products: { productId: string; quantity: number }[];
  p24_methodId: number;
  discountCode?: string;
}

export interface ValidatePurchaseOpts {
  products: { productId: string; quantity: number }[];
  discountCode?: string;
}

export interface ValidateDiscountCodeOpts {
  code: string;
}

export enum ValidatePurchaseErrorMessage {
  INVALID_DISCOUNT_CODE = 'INVALID_DISCOUNT_CODE',
  INVALID_TOTAL_AMOUNT = 'INVALID_TOTAL_AMOUNT',
  DISCOUNT_CODE_UNUSABLE__DISCOUNT_TOO_BIG = 'DISCOUNT_CODE_UNUSABLE__DISCOUNT_TOO_BIG',
  DISCOUNT_TOO_BIG = 'DISCOUNT_TOO_BIG',
  INVALID_TOTAL_AMOUNT_BEFORE_DISCOUNT = 'INVALID_TOTAL_AMOUNT_BEFORE_DISCOUNT',
  NO_SUCH_PRODUCTS = 'NO_SUCH_PRODUCTS',
  CANNOT_FIND_PRODUCT = 'CANNOT_FIND_PRODUCT',
  PRODUCT_DEACTIVATED = 'PRODUCT_DEACTIVATED',
  DISCOUNT_MADE_PRODUCT_TOTAL_LESS_THAN_0 = 'DISCOUNT_MADE_PRODUCT_TOTAL_LESS_THAN_0',
  PURCHASE_VALIDATION_UNKNOWN_ERROR = 'PURCHASE_VALIDATION_UNKNOWN_ERROR',
}

export interface ValidatePurchaseError {
  status: number;
  message: string;
  data?: {
    // discount code - code
    code?: string;
    // min - minimum amount with which discount code might be used - in groszy
    min?: number;
  };
}
