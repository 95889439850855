import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from '@state';
import { GetUserAssetsOpts } from '@interfaces';

import * as actions from './user-files.actions';
import * as selectors from './user-files.selectors';

@Injectable()
export class UserFilesFacade {
  public loading$ = this.store.select(selectors.selectLoading);
  public downloadingFile$ = this.store.select(selectors.selectDownloadingFile);
  public filesOfDocuments$ = this.store.select(selectors.selectFilesOfDocuments);
  public userAssets$ = this.store.select(selectors.selectUserAssets);
  public sort$ = this.store.select(selectors.selectSort);
  public upload$ = this.store.select(selectors.selectUpload);

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getFilesOfDocuments(opts: GetUserAssetsOpts): void {
    this.store.dispatch(actions.getFilesOfUserDocuments({ opts }));
  }

  public getUserAssets(opts: GetUserAssetsOpts): void {
    this.store.dispatch(actions.getUserAssets({ opts }));
  }

  public downloadAsset(userAssetId: string): void {
    this.store.dispatch(actions.downloadAsset({ userAssetId }));
  }
}
