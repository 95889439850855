import { Pagination, Role, User } from '@interfaces';

export type UsersState = Readonly<{
  loading: boolean;
  loadedUsersData: User[];
  loadedUsersPagination: Pagination;
  errorMessage: string | null;
  loadedUserData: User | null;
  employeeTypes: Role[] | null;
  allRoles: Role[] | null;
  loadingEmployeeTypes: boolean;
  loadingAllRoles: boolean;

  reassignInProgress: boolean;
}>;

export const defaultPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'name',
  orderDir: 'asc',
};

export const initialState: UsersState = {
  loading: false,
  errorMessage: null,
  loadedUsersData: [],
  loadedUsersPagination: { ...(defaultPagination as Pagination) },
  loadedUserData: null,
  employeeTypes: null,
  allRoles: null,
  loadingEmployeeTypes: false,
  loadingAllRoles: false,

  reassignInProgress: false,
};
