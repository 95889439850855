import { DocumentComment } from '@interfaces';

export type CommentsState = Readonly<{
  errorMessage: string;
  loadingComments: boolean;
  documentComments: {
    userDocumentId: string | null;
    userProcessId: string | null;
    comments: DocumentComment[];
  };
}>;

export const initialState: CommentsState = {
  errorMessage: null,
  loadingComments: false,
  documentComments: {
    userDocumentId: null,
    userProcessId: null,
    comments: null,
  },
};
