import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from '@state';

import {
  CreateInstructionItemOpts,
  SearchItemsInProcessesOpts,
  ToggleItemsInProcessOpts,
  UpdateInstructionItemOpts,
  UpdateItemInProcessOpts,
  UpdateItemsOrderOpts,
} from '@interfaces';

import * as itemsActions from './instruction-items.actions';
import * as selectors from './instruction-items.selectors';

@Injectable()
export class InstructionItemsFacade {
  public loading$ = this.store.select(selectors.selectLoading);
  public errorMessage$ = this.store.select(selectors.selectErrorMessage);
  public allInstructionsItems$ = this.store.select(selectors.selectAllInstructionItems);
  public itemsInProcess$ = this.store.select(selectors.selectInstructionItemsInProcess);
  public loadedInstructionItem$ = this.store.select(selectors.selectloadedInstructionItem);

  public itemsListForCustomization$ = this.store.select(selectors.selectItemsListForCustomization);

  public createInstructionItemSuccess$ = this.actions.pipe(ofType(itemsActions.createInstructionItemSuccess));
  public updateInstructionItemSuccess$ = this.actions.pipe(ofType(itemsActions.updateInstructionItemSuccess));
  public removeInstructionItemSuccess$ = this.actions.pipe(ofType(itemsActions.removeInstructionItemSuccess));

  public toggleItemsSuccess$ = this.actions.pipe(ofType(itemsActions.toggleItemsInProcessSuccess));
  public updateItemInProcessSuccess$ = this.actions.pipe(ofType(itemsActions.updateItemInProcessSuccess));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getAllInstructionItems(): void {
    this.store.dispatch(itemsActions.getAllInstructionItems());
  }

  public getInstructionItem(id: string): void {
    this.store.dispatch(itemsActions.getInstructionItem({ id }));
  }

  public createInstructionItem(opts: CreateInstructionItemOpts): void {
    this.store.dispatch(itemsActions.createInstructionItem({ opts }));
  }

  public updateInstructionItem(opts: UpdateInstructionItemOpts): void {
    this.store.dispatch(itemsActions.updateInstructionItem({ opts }));
  }

  public removeInstructionItem(id: string): void {
    this.store.dispatch(itemsActions.removeInstructionItem({ id }));
  }

  public searchItemsInProcess(opts: SearchItemsInProcessesOpts): void {
    if (!opts.processId) {
      // DO NOT CALL searchItemsInProcess without processId in args
      // It will fetch more than 60MB of data!
      // There is (21.12.2022): 40 Documents x 40 Processes x 16 voivos = 25600 ENTITIES!
      // this.instrItemsFacade.searchItemsInProcess({});
      console.error('Tried to search instruction items in all processes - FORBIDDEN');
      return;
    }
    this.store.dispatch(itemsActions.searchItemsInProcesses({ opts }));
  }

  public toggleItemsInProcess(opts: ToggleItemsInProcessOpts): void {
    this.store.dispatch(itemsActions.toggleItemsInProcess({ opts }));
  }

  public updateItemInProcess(opts: UpdateItemInProcessOpts): void {
    this.store.dispatch(itemsActions.updateItemInProcess({ opts }));
  }

  public updateItemsOrder(opts: UpdateItemsOrderOpts[]): void {
    this.store.dispatch(itemsActions.updateItemsOrder({ opts }));
  }

  public setUseCustomList(opts: { userProcessId: string; useCustomList: boolean }): void {
    this.store.dispatch(itemsActions.setUseCustomList({ ...opts }));
  }

  public getItemsListForCustomaization(userProcessId: string): void {
    this.store.dispatch(itemsActions.getItemsListForCustomization({ userProcessId }));
  }

  public resetItemsInCustomItemsList(userProcessId: string): void {
    this.store.dispatch(itemsActions.resetItemsInCustomItemsList({ userProcessId }));
  }

  public setEnabledItemInCustomList(opts: { userProcessId: string; itemId: string; enabled: boolean }): void {
    this.store.dispatch(itemsActions.setEnabledItemInCustomList({ ...opts }));
  }

  public setEnabledFees(opts: { userProcessId: string; enabled: boolean }): void {
    this.store.dispatch(itemsActions.setEnabledFees({ ...opts }));
  }
}
