<form [formGroup]="formGroup" id="step4Form">
  <div class="step4-form-container">
    <div class="row">
      <div class="standard-form-field radio-form-field inPolandAtApplicationSubmition-field required-field">
        <label
          [class.invalid]="
            controls.inPolandAtApplicationSubmition.touched && controls.inPolandAtApplicationSubmition.invalid
          ">
          {{ 'NT.IN-POLAND-AT-APPLICATION-SUBMITION.INFIELD-LABEL' | translate }} *
          <mat-icon
            #inPolandAtApplicationSubmition="matTooltip"
            (click)="$event.preventDefault(); $event.stopPropagation(); inPolandAtApplicationSubmition.toggle()"
            matSuffix
            matTooltip="{{ 'NT.IN-POLAND-AT-APPLICATION-SUBMITION.TOOLTIP' | translate }}"
            matTooltipClass="personalDataFormTooltip">
            help_outline
          </mat-icon>
        </label>
        <mat-radio-group
          formControlName="inPolandAtApplicationSubmition"
          (keydown)="firstInputKeydown($event)"
          [required]="true">
          <mat-radio-button [value]="true" id="inPolandAtApplicationSubmition-0">
            {{ 'COMMON.YES' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" id="inPolandAtApplicationSubmition-1">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row" *ngIf="controls.inPolandAtApplicationSubmition.value === true">
      <div class="standard-form-field radio-form-field currentlyLivingInPoland-field required-field">
        <label [class.invalid]="controls.currentlyLivingInPoland.touched && controls.currentlyLivingInPoland.invalid">
          {{ 'PERSONAL_DATA_FORM.RESIDENCE-CURRENTLY-LIVING-IN-POLAND.INFIELD-LABEL' | translate }} *
        </label>
        <mat-radio-group formControlName="currentlyLivingInPoland" (keydown)="firstInputKeydown($event)">
          <mat-radio-button [value]="true" id="applicantCurrentlyLivingInPoland-0">
            {{ 'COMMON.YES' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" id="applicantCurrentlyLivingInPoland-1">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <ng-container
      *ngIf="
        controls.inPolandAtApplicationSubmition.value === true &&
        (controls.currentlyLivingInPoland.value === true || controls.currentlyLivingInPoland.value === false)
      ">
      <div class="row">
        <div class="standard-form-field widened-field legalBasisOfStay-field required-field">
          <label
            for="applicantLegalBasisOfStay"
            [class.invalid]="controls.legalBasisOfStay.touched && controls.legalBasisOfStay.invalid">
            {{
              controls.currentlyLivingInPoland.value === true
                ? ('PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY.MAIN-LABEL' | translate)
                : ('PERSONAL_DATA_FORM.PLANNED-LEGAL-BASIS-OF-STAY.MAIN-LABEL' | translate)
            }}
            *
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              id="applicantLegalBasisOfStay"
              panelClass="standard-select"
              placeholder="{{ 'PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY.PLACEHOLDER' | translate }}"
              formControlName="legalBasisOfStay">
              <mat-option *ngFor="let item of legalBasisOfStayList" [value]="item.value">
                {{ 'SELECTS_VALUES.STAY_LEGAL_BASIS_NEW.' + item.labelKey | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.legalBasisOfStay.invalid">
              {{ 'PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.legalBasisOfStay) }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="standard-form-field residenceVisaExpiryDate-field required-field">
          <label
            for="applicantResidenceVisaExpiryDatePickerNoCurrentlyInRP"
            [class.invalid]="
              (controls.residenceVisaExpiryDate.touched && controls.residenceVisaExpiryDate.invalid) ||
              formGroup.errors?.visaExpiredBeforeLastEntryDate
            ">
            {{ 'PERSONAL_DATA_FORM.RESIDENCE-VISA-EXPIRY-DATE.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline" (click)="residenceVisaExpiryDatePicker.open()">
            <input
              matInput
              id="applicantResidenceVisaExpiryDatePickerNoCurrentlyInRP"
              #applicantResidenceVisaExpiryDatePicker
              readonly
              (focus)="residenceVisaExpiryDatePicker.open()"
              [matDatepicker]="residenceVisaExpiryDatePicker"
              [min]="currentDate"
              [max]="maxValidDocumentExpiryDate"
              placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-VISA-EXPIRY-DATE.PLACEHOLDER' | translate }}"
              formControlName="residenceVisaExpiryDate" />
            <mat-error
              *ngIf="
                controls.residenceVisaExpiryDate.invalid &&
                !controls.residenceVisaExpiryDate.hasError('visaExpiredBeforeLastEntryDate')
              ">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-VISA-EXPIRY-DATE.INFIELD-LABEL' | translate }}
              {{ getErrorMessage(controls.residenceVisaExpiryDate) }}
            </mat-error>
            <mat-error
              *ngIf="
                controls.residenceVisaExpiryDate.invalid &&
                controls.residenceVisaExpiryDate.hasError('visaExpiredBeforeLastEntryDate')
              ">
              {{ getErrorMessage(controls.residenceVisaExpiryDate) }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="residenceVisaExpiryDatePicker"></mat-datepicker-toggle>
            <mat-datepicker
              #residenceVisaExpiryDatePicker
              [startView]="'month'"
              [startAt]="currentDate"
              [calendarHeaderComponent]="pickerHeader"
              (closed)="applicantResidenceVisaExpiryDatePicker.blur()">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="standard-form-field widened-field lastEntryDate-field required-field">
          <label
            for="applicantLastEntryDate"
            [class.invalid]="controls.lastEntryDate.touched && controls.lastEntryDate.invalid">
            {{
              controls.currentlyLivingInPoland.value === true
                ? ('PERSONAL_DATA_FORM.LAST-ENTRY-DATE.MAIN-LABEL' | translate)
                : ('PERSONAL_DATA_FORM.PLANNED-ENTRY-DATE.MAIN-LABEL' | translate)
            }}
            *
          </label>
          <mat-form-field appearance="outline" (click)="lastEntryDatePicker.open()">
            <input
              matInput
              id="applicantLastEntryDate"
              #applicantLastEntryDate
              readonly
              (focus)="lastEntryDatePicker.open()"
              [matDatepicker]="lastEntryDatePicker"
              placeholder="{{ 'PERSONAL_DATA_FORM.LAST-ENTRY-DATE.PLACEHOLDER' | translate }}"
              [min]="controls.currentlyLivingInPoland.value === true ? null : currentDate"
              [max]="controls.currentlyLivingInPoland.value === true ? currentDate : null"
              formControlName="lastEntryDate" />
            <mat-error *ngIf="controls.lastEntryDate.invalid">
              {{ getErrorMessage(controls.lastEntryDate) }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="lastEntryDatePicker"></mat-datepicker-toggle>
            <mat-datepicker
              #lastEntryDatePicker
              [startAt]="currentDate"
              [startView]="'month'"
              [calendarHeaderComponent]="pickerHeader"
              (closed)="applicantLastEntryDate.blur()">
            </mat-datepicker>
          </mat-form-field>
          <mat-error *ngIf="formGroup.errors?.VISA_EXPIRED_BEFORE_LAST_ENTRY_DATE">
            {{ 'FORM-VALIDATION.VISA_EXPIRED_BEFORE_LAST_ENTRY_DATE' | translate }}
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="standard-form-field widened-field purposeOfStay-field required-field">
          <label
            for="purposeOfStayInput"
            [class.invalid]="controls.purposeOfStay.touched && controls.purposeOfStay.invalid">
            {{ 'PERSONAL_DATA_FORM.PURPOSE-OF-STAY.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              id="purposeOfStayInput"
              panelClass="standard-select"
              placeholder="{{ 'PERSONAL_DATA_FORM.PURPOSE-OF-STAY.PLACEHOLDER' | translate }}"
              [required]="true"
              formControlName="purposeOfStay">
              <mat-option *ngFor="let item of purposeOfStayList" [value]="item">
                {{ 'PROCESSES.ORIG_PROCESSES.' + item | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.purposeOfStay.invalid">
              {{ 'PERSONAL_DATA_FORM.PURPOSE-OF-STAY.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.purposeOfStay) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <ng-container *ngIf="controls.legalBasisOfStay.value === 'VISA'">
        <div class="row">
          <div class="standard-form-field widened-field stayVisaType-field required-field">
            <label
              for="stayVisaTypeInput"
              [class.invalid]="controls.stayVisaType.touched && controls.stayVisaType.invalid">
              {{ 'PERSONAL_DATA_FORM.STAY-VISA-TYPE.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                id="stayVisaTypeInput"
                panelClass="standard-select"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-VISA-TYPE.PLACEHOLDER' | translate }}"
                formControlName="stayVisaType">
                <mat-option *ngFor="let item of visaTypes" [value]="item">
                  {{ 'NT4.VISA_TYPES.' + item | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controls.stayVisaType.invalid">
                {{ 'PERSONAL_DATA_FORM.STAY-VISA-TYPE.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.stayVisaType) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="standard-form-field stayVisaSeries-field required-field">
            <label
              for="stayVisaSeriesInput"
              [class.invalid]="controls.stayVisaSeries.touched && controls.stayVisaSeries.invalid">
              {{ 'PERSONAL_DATA_FORM.STAY-VISA-SERIES.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="stayVisaSeriesInput"
                type="text"
                minLength="1"
                maxLength="2"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-VISA-SERIES.PLACEHOLDER' | translate }}"
                [formControl]="controls.stayVisaSeries" />
              <mat-error *ngIf="controls.stayVisaSeries.invalid">
                {{ 'PERSONAL_DATA_FORM.STAY-VISA-SERIES.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.stayVisaSeries) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field stayVisaNumber-field required-field">
            <label
              for="stayVisaNumberInput"
              [class.invalid]="controls.stayVisaNumber.touched && controls.stayVisaNumber.invalid">
              {{ 'PERSONAL_DATA_FORM.STAY-VISA-NUMBER.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="stayVisaNumberInput"
                type="string"
                minlength="1"
                maxlength="10"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-VISA-NUMBER.PLACEHOLDER' | translate }}"
                [formControl]="controls.stayVisaNumber" />
              <mat-error *ngIf="controls.stayVisaNumber.invalid">
                {{ 'PERSONAL_DATA_FORM.STAY-VISA-NUMBER.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.stayVisaNumber) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="standard-form-field stayVisaIssueDate-field required-field">
            <label
              for="stayVisaIssueDateInput"
              [class.invalid]="controls.stayVisaIssueDate.touched && controls.stayVisaIssueDate.invalid">
              {{ 'PERSONAL_DATA_FORM.STAY-VISA-ISSUE-DATE.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline" (click)="stayVisaIssueDatePicker.open()">
              <input
                matInput
                (focus)="stayVisaIssueDatePicker.open()"
                id="stayVisaIssueDateInput"
                #applicantStayVisaIssueDate
                [matDatepicker]="stayVisaIssueDatePicker"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-VISA-ISSUE-DATE.PLACEHOLDER' | translate }}"
                [min]="minValidDate"
                [max]="maxValidDate"
                [readonly]="true"
                [formControl]="controls.stayVisaIssueDate" />
              <mat-error *ngIf="controls.stayVisaIssueDate.invalid">
                {{ getErrorMessage(controls.stayVisaIssueDate) }}
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="stayVisaIssueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker
                #stayVisaIssueDatePicker
                [startView]="'month'"
                [startAt]="'1985'"
                [calendarHeaderComponent]="pickerHeader"
                (closed)="applicantStayVisaIssueDate.blur()">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="standard-form-field stayVisaExpiryDate-field required-field">
            <label
              for="stayVisaExpiryDateInput"
              [class.invalid]="controls.stayVisaExpiryDate.touched && controls.stayVisaExpiryDate.invalid">
              {{ 'PERSONAL_DATA_FORM.STAY-VISA-EXPIRY-DATE.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline" (click)="stayVisaExpiryDatePicker.open()">
              <input
                matInput
                (focus)="stayVisaExpiryDatePicker.open()"
                id="stayVisaExpiryDateInput"
                #applicantStayVisaExpiryDate
                [matDatepicker]="stayVisaExpiryDatePicker"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-VISA-EXPIRY-DATE.PLACEHOLDER' | translate }}"
                [min]="minValidDate"
                [max]="maxValidFutureDate"
                [readonly]="true"
                [formControl]="controls.stayVisaExpiryDate" />
              <mat-error *ngIf="controls.stayVisaExpiryDate.invalid">
                {{ getErrorMessage(controls.stayVisaExpiryDate) }}
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="stayVisaExpiryDatePicker"></mat-datepicker-toggle>
              <mat-datepicker
                #stayVisaExpiryDatePicker
                [startView]="'month'"
                [startAt]="'1985'"
                [calendarHeaderComponent]="pickerHeader"
                (closed)="applicantStayVisaExpiryDate.blur()">
              </mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="standard-form-field stayVisaPeriodOfStay-field required-field">
            <label
              for="stayVisaPeriodOfStayInput"
              [class.invalid]="controls.stayVisaPeriodOfStay.touched && controls.stayVisaPeriodOfStay.invalid">
              {{ 'PERSONAL_DATA_FORM.STAY-VISA-PERIOD-OF-STAY.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="stayVisaPeriodOfStayInput"
                type="number"
                min="1"
                max="9999"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-VISA-PERIOD-OF-STAY.PLACEHOLDER' | translate }}"
                [formControl]="controls.stayVisaPeriodOfStay" />
              <mat-error *ngIf="controls.stayVisaPeriodOfStay.invalid">
                {{ 'PERSONAL_DATA_FORM.STAY-VISA-PERIOD-OF-STAY.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.stayVisaPeriodOfStay) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field stayVisaIssuingAuthority-field required-field">
            <label
              for="stayVisaIssuingAuthorityInput"
              [class.invalid]="controls.stayVisaIssuingAuthority.touched && controls.stayVisaIssuingAuthority.invalid">
              {{ 'PERSONAL_DATA_FORM.STAY-VISA-ISSUING-AUTHORITY.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="stayVisaIssuingAuthorityInput"
                type="text"
                minlength="1"
                maxlength="100"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-VISA-ISSUING-AUTHORITY.PLACEHOLDER' | translate }}"
                [formControl]="controls.stayVisaIssuingAuthority" />
              <mat-error *ngIf="controls.stayVisaIssuingAuthority.invalid">
                {{ 'PERSONAL_DATA_FORM.STAY-VISA-ISSUING-AUTHORITY.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.stayVisaIssuingAuthority) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="controls.legalBasisOfStay.value === 'SCHENGEN_COUNTRY_ISSUED_DOC'">
        <nz-divider [nzText]="travelers" nzOrientation="center">
          <ng-template #travelers>
            <h5>{{ 'PERSONAL_DATA_FORM.RESIDENCE_CARD_OR_DOCUMENT_AUTHORIZING' | translate }}</h5>
          </ng-template>
        </nz-divider>

        <div class="row">
          <div class="standard-form-field stayEntitlingDocumentIssueDate-field required-field">
            <label
              for="stayEntitlingDocumentIssueDateInput"
              [class.invalid]="
                controls.stayEntitlingDocumentIssueDate.touched && controls.stayEntitlingDocumentIssueDate.invalid
              ">
              {{ 'PERSONAL_DATA_FORM.STAY-VISA-ISSUE-DATE.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline" (click)="stayEntitlingDocumentIssueDatePicker.open()">
              <input
                matInput
                (focus)="stayEntitlingDocumentIssueDatePicker.open()"
                id="stayEntitlingDocumentIssueDateInput"
                #applicantStayEntitlingDocumentIssueDate
                [matDatepicker]="stayEntitlingDocumentIssueDatePicker"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-VISA-ISSUE-DATE.PLACEHOLDER' | translate }}"
                [min]="minValidDate"
                [max]="maxValidDate"
                [readonly]="true"
                [formControl]="controls.stayEntitlingDocumentIssueDate" />
              <mat-error *ngIf="controls.stayEntitlingDocumentIssueDate.invalid">
                {{ getErrorMessage(controls.stayEntitlingDocumentIssueDate) }}
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="stayEntitlingDocumentIssueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker
                #stayEntitlingDocumentIssueDatePicker
                [startView]="'month'"
                [startAt]="'1985'"
                [calendarHeaderComponent]="pickerHeader"
                (closed)="applicantStayEntitlingDocumentIssueDate.blur()">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="standard-form-field widened-field stayEntitlingDocumentIssuedBy-field required-field">
            <label
              for="stayEntitlingDocumentIssuedByInput"
              [class.invalid]="
                controls.stayEntitlingDocumentIssuedBy.touched && controls.stayEntitlingDocumentIssuedBy.invalid
              ">
              {{ 'PERSONAL_DATA_FORM.STAY-ENTITLING-DOCUMENT-ISSUED-BY.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="stayEntitlingDocumentIssuedByInput"
                type="text"
                minLength="1"
                maxLength="100"
                placeholder="{{ 'PERSONAL_DATA_FORM.STAY-ENTITLING-DOCUMENT-ISSUED-BY.PLACEHOLDER' | translate }}"
                [formControl]="controls.stayEntitlingDocumentIssuedBy" />
              <mat-error *ngIf="controls.stayEntitlingDocumentIssuedBy.invalid">
                {{ 'PERSONAL_DATA_FORM.STAY-ENTITLING-DOCUMENT-ISSUED-BY.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.stayEntitlingDocumentIssuedBy) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="controls.legalBasisOfStay.value !== 'VISA' && controls.legalBasisOfStay.value !== 'VISA_FREE'">
        <nz-divider [nzText]="travelers2" nzOrientation="center">
          <ng-template #travelers2>
            <h5>{{ 'PERSONAL_DATA_FORM.RESIDENCE_CARD_OR_DOCUMENT_AUTHORIZING_2' | translate }}</h5>
          </ng-template>
        </nz-divider>

        <div class="row">
          <div class="standard-form-field entryDocumentSeries-field">
            <label
              for="entryDocumentSeriesInput"
              [class.invalid]="controls.entryDocumentSeries.touched && controls.entryDocumentSeries.invalid">
              {{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-SERIES.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="entryDocumentSeriesInput"
                type="text"
                minLength="1"
                maxLength="2"
                placeholder="{{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-SERIES.PLACEHOLDER' | translate }}"
                [formControl]="controls.entryDocumentSeries" />
              <mat-error *ngIf="controls.entryDocumentSeries.invalid">
                {{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-SERIES.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.entryDocumentSeries) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field entryDocumentNumber-field">
            <label
              for="entryDocumentNumberInput"
              [class.invalid]="controls.entryDocumentNumber.touched && controls.entryDocumentNumber.invalid">
              {{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-NUMBER.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="entryDocumentNumberInput"
                type="text"
                minLength="1"
                maxLength="20"
                placeholder="{{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-NUMBER.PLACEHOLDER' | translate }}"
                [formControl]="controls.entryDocumentNumber" />
              <mat-error *ngIf="controls.entryDocumentNumber.invalid">
                {{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-NUMBER.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.entryDocumentNumber) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field entryDocumentIssueDate-field">
            <label
              for="entryDocumentIssueDateInput"
              [class.invalid]="controls.entryDocumentIssueDate.touched && controls.entryDocumentIssueDate.invalid">
              {{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-ISSUE-DATE.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline" (click)="entryDocumentIssueDatePicker.open()">
              <input
                matInput
                (focus)="entryDocumentIssueDatePicker.open()"
                id="entryDocumentIssueDateInput"
                #applicantEntryDocumentIssueDate
                [matDatepicker]="entryDocumentIssueDatePicker"
                placeholder="{{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-ISSUE-DATE.PLACEHOLDER' | translate }}"
                [min]="minValidDate"
                [max]="maxValidDate"
                [readonly]="true"
                [formControl]="controls.entryDocumentIssueDate" />
              <mat-error *ngIf="controls.entryDocumentIssueDate.invalid">
                {{ getErrorMessage(controls.entryDocumentIssueDate) }}
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="entryDocumentIssueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker
                #entryDocumentIssueDatePicker
                [startView]="'month'"
                [startAt]="'1985'"
                [calendarHeaderComponent]="pickerHeader"
                (closed)="applicantEntryDocumentIssueDate.blur()">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="standard-form-field entryDocumentExpiryDate-field">
            <label
              for="entryDocumentExpiryDateInput"
              [class.invalid]="controls.entryDocumentExpiryDate.touched && controls.entryDocumentExpiryDate.invalid">
              {{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-EXPIRY-DATE.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline" (click)="entryDocumentExpiryDatePicker.open()">
              <input
                matInput
                (focus)="entryDocumentExpiryDatePicker.open()"
                id="entryDocumentExpiryDateInput"
                #applicantEntryDocumentExpiryDate
                [matDatepicker]="entryDocumentExpiryDatePicker"
                placeholder="{{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-EXPIRY-DATE.PLACEHOLDER' | translate }}"
                [min]="minValidDate"
                [max]="maxValidFutureDate"
                [readonly]="true"
                [formControl]="controls.entryDocumentExpiryDate" />
              <mat-error *ngIf="controls.entryDocumentExpiryDate.invalid">
                {{ getErrorMessage(controls.entryDocumentExpiryDate) }}
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="entryDocumentExpiryDatePicker"></mat-datepicker-toggle>
              <mat-datepicker
                #entryDocumentExpiryDatePicker
                [startView]="'month'"
                [startAt]="'1985'"
                [calendarHeaderComponent]="pickerHeader"
                (closed)="applicantEntryDocumentExpiryDate.blur()">
              </mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="standard-form-field widened-field entryDocumentIssuingAuthority-field">
            <label
              for="entryDocumentIssuingAuthorityInput"
              [class.invalid]="
                controls.entryDocumentIssuingAuthority.touched && controls.entryDocumentIssuingAuthority.invalid
              ">
              {{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-ISSUING-AUTHORITY.MAIN-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="entryDocumentIssuingAuthorityInput"
                type="text"
                minLength="1"
                maxLength="100"
                placeholder="{{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-NUMBER.PLACEHOLDER' | translate }}"
                [formControl]="controls.entryDocumentIssuingAuthority" />
              <mat-error *ngIf="controls.entryDocumentIssuingAuthority.invalid">
                {{ 'PERSONAL_DATA_FORM.ENTRY-DOCUMENT-ISSUING-AUTHORITY.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.entryDocumentIssuingAuthority) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <nz-divider [nzText]="staysInPoland" nzOrientation="center">
      <ng-template #staysInPoland>
        <h5>{{ 'PERSONAL_DATA_FORM.STAYS_IN_POLAND' | translate }}</h5>
      </ng-template>
    </nz-divider>

    <div class="row">
      <div class="standard-form-field radio-form-field noPreviousVisits-field required-field">
        <label [class.invalid]="controls.noPreviousVisits.touched && controls.noPreviousVisits.invalid">
          {{ 'PERSONAL_DATA_FORM.NO-PREVIOUS-VISITS.MAIN-LABEL' | translate }} *
        </label>
        <mat-radio-group formControlName="noPreviousVisits" [required]="true">
          <mat-radio-button [value]="false"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row visits-row" *ngIf="controls.noPreviousVisits.value === false">
      <app-previous-visits-form-long-resi
        [previousVisitsArrFc]="controls.previousVisitsArr"
        [inPolandAtApplicationSubmition]="controls.inPolandAtApplicationSubmition"
        [lastEntryDateFc]="controls.lastEntryDate"
        [legalBasisOfStayFc]="controls.legalBasisOfStay"
        [legalBasisOfStayCommentFc]="controls.legalBasisOfStayComment"
        [currentlyLivingInPolandFc]="controls.currentlyLivingInPoland">
      </app-previous-visits-form-long-resi>
    </div>

    <div class="row">
      <div class="standard-form-field radio-form-field noLastTravelsOutsidePoland-field required-field">
        <label
          [class.invalid]="controls.noLastTravelsOutsidePoland.touched && controls.noLastTravelsOutsidePoland.invalid">
          {{ 'PERSONAL_DATA_FORM.LAST-TRAVELS-OUTSIDE-POLAND.MAIN-LABEL' | translate }} *
        </label>
        <!-- IMPORTANT about noLastTravelsOutsidePoland
            there is fuckup because of different label text!
            the field is 'noLastTravelsOutsidePoland' so it would mean that
            if noLastTravelsOutsidePoland === true then user DID NOT have any travels outside poland.
            BUT! !IMPORTANT! current label for this field is: 'Czy przekraczałeś granice krajów innych niż Polska w przeciągu ostatnich 5 lat?'
            so if user marks YES then it means that we have to set our field value to `false` as user DID HAVE TRAVELS OUTSIDE POLAND
          -->
        <mat-radio-group formControlName="noLastTravelsOutsidePoland" [required]="true">
          <mat-radio-button [value]="false"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row visits-row" *ngIf="controls.noLastTravelsOutsidePoland.value === false">
      <app-countries-visits-form-long-resi [lastTravelsOutsidePoland]="controls.lastTravelsOutsidePoland">
      </app-countries-visits-form-long-resi>
    </div>

    <div class="row">
      <div class="standard-form-field radio-form-field hasBlueCardStaysInEU-field required-field">
        <label [class.invalid]="controls.hasBlueCardStaysInEU.touched && controls.hasBlueCardStaysInEU.invalid">
          {{ 'PERSONAL_DATA_FORM.HAVE-BLUE-CARD-STAYS-IN-EU-BOOLEAN.MAIN-LABEL' | translate }} *
        </label>
        <mat-radio-group formControlName="hasBlueCardStaysInEU" [required]="true">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <ng-container *ngIf="controls.hasBlueCardStaysInEU.value === true">
      <div class="row">
        <div class="standard-form-field three-fourth-field blueCardStaysInEU-field radio-form-field required-field">
          <label
            for="blueCardStaysInEUInput"
            [class.invalid]="controls.blueCardStaysInEU.touched && controls.blueCardStaysInEU.invalid">
            {{ 'PERSONAL_DATA_FORM.HAVE-BLUE-CARD-STAYS-IN-EU.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              id="blueCardStaysInEUInput"
              type="text"
              minLength="1"
              maxLength="9999"
              placeholder="{{ 'PERSONAL_DATA_FORM.HAVE-BLUE-CARD-STAYS-IN-EU.PLACEHOLDER' | translate }}"
              [formControl]="controls.blueCardStaysInEU"></textarea>
            <mat-error *ngIf="controls.blueCardStaysInEU.invalid">
              {{ 'PERSONAL_DATA_FORM.HAVE-BLUE-CARD-STAYS-IN-EU.INFIELD-LABEL' | translate }}
              {{ getErrorMessage(controls.blueCardStaysInEU) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <div class="row">
      <div class="standard-form-field radio-form-field hasOtherLongTermPermits-field required-field">
        <label [class.invalid]="controls.hasOtherLongTermPermits.touched && controls.hasOtherLongTermPermits.invalid">
          {{ 'PERSONAL_DATA_FORM.OTHER-LONG-TERM-PERMITS-BOOLEAN.MAIN-LABEL' | translate }} *
        </label>
        <mat-radio-group
          formControlName="hasOtherLongTermPermits"
          (keydown.Tab)="controls.hasOtherLongTermPermits.value === true ? undefined : emitLastFieldTabPressed($event)"
          [required]="true">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <ng-container *ngIf="controls.hasOtherLongTermPermits.value === true">
      <div class="row">
        <div class="standard-form-field three-fourth-field otherLongTermPermits-field radio-form-field required-field">
          <label
            for="otherLongTermPermitsInput"
            [class.invalid]="controls.otherLongTermPermits.touched && controls.otherLongTermPermits.invalid">
            {{ 'PERSONAL_DATA_FORM.OTHER-LONG-TERM-PERMITS.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              id="otherLongTermPermitsInput"
              type="text"
              minLength="1"
              maxLength="9999"
              placeholder="{{ 'PERSONAL_DATA_FORM.OTHER-LONG-TERM-PERMITS.PLACEHOLDER' | translate }}"
              [formControl]="controls.otherLongTermPermits"
              (keydown.Tab)="lastFieldTabPressed.emit(); $event.preventDefault(); $event.stopPropagation()">
            </textarea>
            <mat-error *ngIf="controls.otherLongTermPermits.invalid">
              {{ 'PERSONAL_DATA_FORM.OTHER-LONG-TERM-PERMITS.INFIELD-LABEL' | translate }}
              {{ getErrorMessage(controls.otherLongTermPermits) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </div>
</form>
