import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OfficeState } from './office.state';

export const selectOfficeState = createFeatureSelector<OfficeState>('office');

export const selectOffices = createSelector(selectOfficeState, state => state.offices);

export const selectPagination = createSelector(selectOfficeState, state => state.pagination);

export const selectLoadedOffice = createSelector(selectOfficeState, state => state.loadedOffice);

export const selectLoading = createSelector(selectOfficeState, state => state.loading);

export const selectErrorMessage = createSelector(selectOfficeState, state => state.errorMessage);
