import { Company, Pagination, UserProcess } from '@interfaces';

export const defaultPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'name',
  orderDir: 'desc',
  count: 0,
};

export const employeesProcessesDefaultPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'updatedAt',
  orderDir: 'desc',
  count: 0,
};

export type CompanyState = Readonly<{
  loading: boolean;
  companies: Company[];
  pagination: Pagination;
  loadedCompany: Company | null;
  errorMessage: string | null;
  employeesProcesses: Partial<UserProcess>[];
  employeesProcessesPagination: Pagination;
}>;

export const initialState: CompanyState = {
  loading: false,
  companies: [],
  pagination: { ...(defaultPagination as Pagination) },
  loadedCompany: null,
  errorMessage: null,

  employeesProcesses: null,
  employeesProcessesPagination: { ...(employeesProcessesDefaultPagination as Pagination) },
};
