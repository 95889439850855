import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { Process, ProcessFee } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class ProcessHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getListOfAvailableProcesses(): Observable<Process[]> {
    const url = `${this.API_URL}/processes`;
    return this.http.get<Process[]>(url);
  }

  public updateProcessFees(processId: string, fees: ProcessFee[]): Observable<Process> {
    const url = `${this.API_URL}/processes/${processId}/fees`;
    return this.http.patch<Process>(url, fees);
  }

  public getProcessWithDocs(processId: string): Observable<Process> {
    const url = `${this.API_URL}/processes/${processId}/documents/management`;
    return this.http.get<Process>(url);
  }
}
