import { Component, OnDestroy, OnInit } from '@angular/core';
import { AvailableLanguages } from '@constants';
import { AllDeviceInfo } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-translations-banner',
  templateUrl: './translations-banner.component.html',
  styleUrls: ['./translations-banner.component.scss'],
})
export class TranslationsBannerComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public userLang: AvailableLanguages;
  public LINK_TARGET: string;

  public deviceTypeDetected: AllDeviceInfo['deviceTypeDetected'];

  constructor(
    private readonly translateService: TranslateService,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  public ngOnInit(): void {
    this.userLang = this.translateService.currentLang as AvailableLanguages;
    this.LINK_TARGET = this.translateService.instant('TRANSLATIONS_BANNER.URL');
    this.deviceTypeDetected = this.deviceInfoService.getInfo().deviceTypeDetected;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.userLang = langKey as AvailableLanguages;
      this.LINK_TARGET = this.translateService.instant('TRANSLATIONS_BANNER.URL');
    });

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceTypeDetected = info.deviceTypeDetected;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openTargetInNewPage(): void {
    window.open(this.LINK_TARGET, '_blank').focus();
  }
}
