import Step from 'node_modules/shepherd.js/src/types/step';

import { TranslateService } from '@ngx-translate/core';

interface AvailableButtons {
  counter: Step.StepOptionsButton;
  next: Step.StepOptionsButton;
  back: Step.StepOptionsButton;
  cancel: Step.StepOptionsButton;
  done: Step.StepOptionsButton;
}

const gPrefix = 'GUIDE';
const prefix = `${gPrefix}.FIRST-TIME`;

export const getSteps = (t: TranslateService, currLang: string): Step.StepOptions[] => {
  const buttons: AvailableButtons = {
    counter: { text: '', action(): void {}, classes: 'counter-button' },
    next: {
      text: t.instant(`${gPrefix}.NEXT`),
      action(): void {
        this.next();
      },
      classes: 'standard-next-button',
    },
    back: {
      text: t.instant(`${gPrefix}.BACK`),
      action(): void {
        this.back();
      },
      classes: 'standard-back-button',
    },
    cancel: {
      text: t.instant(`${gPrefix}.CLOSE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-cancel-button',
    },
    done: {
      text: t.instant(`${gPrefix}.DONE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-done-button',
    },
  };

  const steps: Step.StepOptions[] = [
    {
      id: 'applicationselect',
      arrow: true,
      modalOverlayOpeningPadding: 1,
      modalOverlayOpeningRadius: 7,
      attachTo: { element: '.application-select-wrapper .standard-form-field .mat-form-field', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.applicationselect.TITLE`),
      text: [t.instant(`${prefix}.applicationselect.TEXT`)],
    },
    {
      id: 'fontsize',
      arrow: true,
      modalOverlayOpeningPadding: 13,
      modalOverlayOpeningRadius: 10,
      attachTo: { element: '.change-font-size-button', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.fontsize.TITLE`),
      text: [t.instant(`${prefix}.fontsize.TEXT`)],
    },
    {
      id: 'lang',
      arrow: true,
      modalOverlayOpeningPadding: 7,
      modalOverlayOpeningRadius: 10,
      attachTo: { element: '.utils-buttons', on: 'bottom-end' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.lang.TITLE`),
      text: [t.instant(`${prefix}.lang.TEXT`)],
    },
    {
      id: 'help',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 15,
      attachTo: { element: '.open-help-button', on: 'bottom-start' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.help.TITLE`),
      text: [t.instant(`${prefix}.help.TEXT`)],
    },
    {
      id: 'notifications',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 15,
      attachTo: { element: '.open-notifications-button', on: 'bottom-start' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.notifications.TITLE`),
      text: `
      ${t.instant(`${prefix}.notifications.TEXT`)}
      <br><br>
      <div>${t.instant(`${prefix}.notifications.TEXT_2`)}<div>
      <div class="centered"><img src="/assets/img/unread-notifications.png" width="80px"></div>
      `,
    },
    {
      id: 'profile',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 15,
      attachTo: { element: '.profile-button', on: 'bottom-start' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.profile.TITLE`),
      text: [t.instant(`${prefix}.profile.TEXT`)],
    },
    {
      id: 'chat',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 31,
      attachTo: { element: '.open-chat-icon-wrapper', on: 'bottom-start' },
      showOn: () => !!document.getElementsByClassName('open-chat-icon-wrapper')?.length,
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.chat.TITLE`),
      text: [t.instant(`${prefix}.chat.TEXT`)],
    },
  ];

  const guideName = t.instant(`${prefix}.GUIDE_NAME`);
  return steps.map((step, currIndex, all) => {
    const formattedButtons: Step.StepOptionsButton[] = [
      { ...buttons.counter, text: `${guideName} ${currIndex + 1}/${all.length}` },
    ];

    if (currIndex === 0) {
      formattedButtons.push({ ...buttons.cancel });
    } else {
      formattedButtons.push({ ...buttons.back });
    }

    if (currIndex < all.length - 1) {
      formattedButtons.push({ ...buttons.next });
    } else {
      formattedButtons.push({ ...buttons.done });
    }

    return { ...step, buttons: formattedButtons };
  });
};
