import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'errorsFormatingAndTranslate',
})
export class ErrorsFormatingAndTranslatePipe implements PipeTransform {
  private currentLang: string;

  public defaultErrorMessage = 'VALIDATORS.DEFAULT';

  public errorNames: { [key: string]: string } = {
    invalidDateDDMMYYYY: 'VALIDATORS.INVALID_DATE_DDMMYYYY',
    invalidDateMMYYYY: 'VALIDATORS.INVALID_DATE_MMYYYY',
    invalidDateYYYY: 'VALIDATORS.INVALID_DATE_YYYY',
    providedDateAfterMaximumDate: 'VALIDATORS.PROVIDED_DATE_AFTER_MAXIMUM_DATE',
    providedDateBeforeMinimumDate: 'VALIDATORS.PROVIDED_DATE_BEFORE_MINIMUM_DATE',
    providedDateMustBeInThePast: 'VALIDATORS.DATE_MUST_BE_IN_THE_PAST',
    providedDateMustBeInTheFuture: 'VALIDATORS.DATE_MUST_BE_IN_THE_FUTURE',
    comparisonInvalid: 'VALIDATORS.COMPARISON_DATE',
  };
  constructor(private readonly translateService: TranslateService) {}

  transform(errors: { [key: string]: any }): string {
    if (!errors) {
      return '';
    }

    this.currentLang = this.translateService.currentLang;

    for (const errorName in errors) {
      if (this.errorNames.hasOwnProperty(errorName)) {
        return this.translateService.instant(this.errorNames[errorName]);
      }
    }
    return this.translateService.instant(this.defaultErrorMessage);
  }
}
