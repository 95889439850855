import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ConsumableCountry, CreateVisitAssistantOpts, Language, Process, ProcessFee } from '@interfaces';
import { AppState } from '@state';
import * as commonsActions from './commons.actions';
import * as commonsSelectors from './commons.selectors';

@Injectable()
export class CommonsFacade {
  public loading$ = this.store.select(commonsSelectors.selectLoading);
  public errorMessage$ = this.store.select(commonsSelectors.selectErrorMessage);

  public availableLanguages$ = this.store.select(commonsSelectors.selectLanguages);
  public availableProcesses$ = this.store.select(commonsSelectors.selectAvailableProcesses);
  public availableProcessesByCategory$ = this.store.select(commonsSelectors.selectAvailableProcessesByCategory);

  public visitAssistants$ = this.store.select(commonsSelectors.selectVisitAssistants);
  public loadingVisitAssistants$ = this.store.select(commonsSelectors.selectLoadingVisitAssistants);
  public createVisitAssistantSuccess$ = this.actions.pipe(ofType(commonsActions.createVisitAssistantSuccess));
  public removeVisitAssistantSuccess$ = this.actions.pipe(ofType(commonsActions.removeAssistantSuccess));

  public updateProcessFeeSuccess$ = this.actions.pipe(ofType(commonsActions.updateProcessFeesSuccess));

  public APIPingInProgress$ = this.store.select(commonsSelectors.selectAPIPingInProgress);
  public lastAPIPingSuccessfull$ = this.store.select(commonsSelectors.selectLastAPIPingSuccessful);
  public getLanguagesError$ = this.actions.pipe(ofType(commonsActions.getLanguagesError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public pingApi(): void {
    this.store.dispatch(commonsActions.pingApi());
  }

  public getListOfAvailableProcesses(): void {
    this.store.dispatch(commonsActions.getListOfAvailableProcesses());
  }

  public getListOfAvailableProcesses$(): Observable<Process[]> {
    return this.store.select(commonsSelectors.selectAvailableProcesses).pipe(
      tap(processes => {
        if (!processes?.length) {
          this.getListOfAvailableProcesses();
        }
      }),
      filter(processes => {
        if (processes === null || !processes.length) {
          return false;
        }
        return true;
      })
    );
  }

  public updateProcessFees(processId: string, fees: ProcessFee[]): void {
    this.store.dispatch(commonsActions.updateProcessFees({ processId, fees }));
  }

  public getLanguages$(): Observable<Language[]> {
    return this.store.select(commonsSelectors.selectLanguages).pipe(
      tap(languages => {
        if (!languages || !languages.length) {
          this.getLanguages();
        }
      }),
      filter(languages => {
        if (languages === null || !languages.length) {
          return false;
        }
        return true;
      })
    );
  }

  public getLanguages(): void {
    this.store.dispatch(commonsActions.getLanguages());
  }

  public getCountries(): void {
    this.store.dispatch(commonsActions.getCountriesList());
  }

  public getCountriesEU(): void {
    this.store.dispatch(commonsActions.getCountriesEUList());
  }

  public getCountries$(): Observable<ConsumableCountry[]> {
    return this.store.select(commonsSelectors.selectCountries).pipe(
      tap(countries => {
        if (!countries) {
          this.getCountries();
        }
      }),
      filter(countries => {
        if (!countries) {
          return false;
        }
        return true;
      })
    );
  }

  public getCountriesEU$(): Observable<ConsumableCountry[]> {
    return this.store.select(commonsSelectors.selectCountriesEU).pipe(
      tap(countriesEU => {
        if (!countriesEU) {
          this.getCountriesEU();
        }
      }),
      filter(countriesEU => {
        if (!countriesEU) {
          return false;
        }
        return true;
      })
    );
  }

  public getVisitAssistants(): void {
    this.store.dispatch(commonsActions.getAllVisitAssistants());
  }

  public createVisitAssistant(opts: CreateVisitAssistantOpts): void {
    this.store.dispatch(commonsActions.createVisitAssistant({ opts }));
  }

  public removeVisitAssistant(id: string): void {
    this.store.dispatch(commonsActions.removeAssistant({ visitAssistantId: id }));
  }
}
