import { FormControl } from '@angular/forms';
import {
  City,
  District,
  Municipality,
  PROCESS_TYPE,
  PROFESSION,
  TRAVEL_DOCUMENT_TYPE,
  User,
  VISA_TYPE,
  Voivodeship,
} from '@interfaces';

export interface PersonalDetails {
  id?: string;
  surname: string;
  previousSurname: string;
  familyName: string;
  name: string;
  previousName: string;
  fatherName: string;
  fatherSurname: string;
  motherName: string;
  motherSurname: string;
  motherMaidenName: string;
  dateOfBirth: string;
  sex: string;
  passportNumber: string;
  placeOfBirth: string;
  countryOfBirth: string;
  nationality: string;
  citizenship: string;
  maritalStatus: string;
  education: string;
  profession: PROFESSION;
  descriptionHeight: number;
  descriptionColourOfEyes: string;
  descriptionSpecialMarks: string;
  pesel: string;
  telephoneNumber?: string;
  travelDocumentType: TRAVEL_DOCUMENT_TYPE;
  travelDocumentSeries: string;
  travelDocumentNumber: string;
  travelDocumentIssueDate: string;
  travelDocumentExpiryDate: string;
  travelDocumentIssuingAuthority: string;
  travelDocumentNrOfPersonsEntered: number;
  residenceVoivodeshipDetails?: Voivodeship;

  residenceVoivodeshipDetailsId: string;
  residenceDistrict: District;
  residenceDistrictId: string | null;
  residenceMunicipality: Municipality;
  residenceMunicipalityId: string | null;
  residenceCityListed: City;
  residenceCityListedId: string | null;
  residenceCity: string;
  residenceStreet: string;
  residenceHouseNumber: string;
  residenceApartmentNumber: string;
  residencePostalCode: string;
  residenceLegalTitle: string;
  residenceVisaExpiryDate: string;
  currentlyLivingInPoland: boolean;
  inPolandAtApplicationSubmition: boolean;
  familyMembersOutsidePoland: boolean;
  legalBasisOfStay: string;
  legalBasisOfStayComment: string;
  lastEntryDate: string;
  purposeOfStay: PROCESS_TYPE;
  spouseSurname: string;
  spousePreviousSurname: string;
  spouseName: string;
  spousePreviousName: string;
  spouseDob: string;
  spouseSex: string;
  spouseCitizenship: string;
  spouseResidenceCity: string;
  spouseResidenceStreet: string;
  spouseResidenceHouseNumber: string;
  spouseResidenceApartmentNumber: string;
  spouseResidencePostalCode: string;
  spouseResidenceCountry: string;
  spouseResidenceVoivodeshipDetails: Voivodeship;
  spouseResidenceVoivodeshipDetailsId: string;
  spouseResidenceDistrict: District;
  spouseResidenceDistrictId: string | null;
  spouseResidenceMunicipality: Municipality;
  spouserResidenceMunicipalityId: string | null;
  spouseResidenceCityListed: City;
  spouseResidenceCityListedId: string | null;
  stayVisaType: VISA_TYPE;
  stayVisaSeries: string;
  stayVisaNumber: string;
  stayVisaIssueDate: string;
  stayVisaExpiryDate: string;
  stayVisaPeriodOfStay: number;
  stayVisaIssuingAuthority: string;
  stayEntitlingDocumentIssuedBy: string;
  stayEntitlingDocumentIssueDate: string;
  entryDocumentSeries: string;
  entryDocumentNumber: string;
  entryDocumentIssueDate: string;
  entryDocumentExpiryDate: string;
  entryDocumentIssuingAuthority: string;
  hasBlueCardStaysInEU: boolean;
  blueCardStaysInEU: string;
  hasOtherLongTermPermits: boolean;
  otherLongTermPermits: string;
  lastYearEmploymentDate: string;
  noFamilyMembersInPoland: boolean;
  familyMembersInPoland: FamilyMemberInPoland[];
  applyingForCompanyName: string;
  applyingForJobTitle: string;
  applyingForExperience: string;
  speakingLanguages: string;
  noPreviousVisits: boolean;
  previousVisitsArr: PreviousVisit[];
  noLastTravelsOutsidePoland: boolean;
  lastTravelsOutsidePoland: string;
  meansOfSubsistence: string;
  meansOfSubsistenceSavingsAmount: string;
  medicalInsurance: string;
  isComplyingWithFiscalObligation: boolean;
  rationaleOfTheApplication: string;

  currentlyDetained: boolean; // -----------------------------General field which is connected to more detailed fields about detainee -----------
  inGuardedCentre: boolean;
  inGuardedCentreComment: string;
  inDetentionCentre: boolean;
  inDetentionCentreComment: string;
  hasBanOnLeavingCountry: boolean;
  hasBanOnLeavingCountryComment: string;
  hasSentenceOfImprisonment: boolean;
  hasSentenceOfImprisonmentComment: string;
  temporarilyArrested: boolean;
  temporarilyArrestedComment: string;

  wasSentencedInPoland: boolean;
  sentenceReason: string;
  currentlySubjectOfProceedings: boolean;
  proceedingsReason: string;
  liabilitiesFromVerdicts: boolean;
  liabilitiesDescription: string;
  verified: boolean;
  verifiedAt?: Date | null;
  verifiedBy?: Partial<User> | null;
  createdAt?: string;
  updatedAt?: string;
  email?: string;
}

export enum LEGAL_BASIS_OF_STAY {
  'TEMP_PERMIT' = 'TEMP_PERMIT',
  'PERM_STAY' = 'PERM_STAY',
  'REFUGEE' = 'REFUGEE',
  'SUBSIDIARY_PROTECTION' = 'SUBSIDIARY_PROTECTION',
  'VISA_FREE' = 'VISA_FREE',
  'VISA' = 'VISA',
  'SCHENGEN_COUNTRY_ISSUED_DOC' = 'SCHENGEN_COUNTRY_ISSUED_DOC',
  'OTHER' = 'OTHER',
  'TOLERATED_STAY' = 'TOLERATED_STAY',
  'ASYLUM' = 'ASYLUM',
}

export class PreviousVisit {
  legalBasis?: LEGAL_BASIS_OF_STAY | null;
  legalBasisComment?: string | null;
  entryDate: string;
  leaveDate: string; // Date or 'OBECNIE'
}

export interface FamilyMemberInPoland {
  name: string;
  surname: string;
  sex: string;
  dateOfBirth: string;
  kinshipDegree: string;
  citizenship: string;
  residencePlace: string;
  tempPermitApplying: boolean;
  dependentOnTheForeigner: boolean;
}

export interface PersonalDetailsFieldConfig {
  required: boolean;
  fieldLabelKey: string; // for main-label & hints
  showOnlyWhenTrue?: string; // Show only when 'fieldName' is set to 'true'/'yes'/positive
  showInSeparateStep?: boolean; // Should this field have spearate step in mat-stepper?
  simpleSelectValues?: { [key: string]: string }[];
  customIndex?: number;
  fieldType:
    | 'text-input' // decides what kind of form-field will be displayed
    | 'address-form'
    | 'family-members-form'
    | 'names-form'
    | 'misc-form'
    | 'description-form'
    | 'varchar-input'
    | 'number-input'
    | 'bool-radio'
    | 'postal-code-input'
    | 'simple-select'
    | 'country-select'
    | 'date-input'
    | 'countries-visits-form';
}

export interface PersonalDetailsFormConfig {
  [key: string]: PersonalDetailsFieldConfig;
}

export interface FormFieldViewConfig extends PersonalDetailsFieldConfig {
  fieldKey: string;
  fieldControl: FormControl;
  stepState: 'number' | 'warn' | 'done';
}

export interface UpdatePersonalDetailsPayload {
  userId: string;
  userProcessId: string;
  personalDetails: PersonalDetails;
}

export interface SetPersonalDetailsVerifiedPayload {
  userId: string;
  userProcessId: string;
  verified: boolean;
}
