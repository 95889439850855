import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalOpts } from './blocking-modal.service';

@Component({
  selector: 'app-blocking-modal',
  templateUrl: './blocking-modal.component.html',
  styleUrls: ['./blocking-modal.component.scss'],
})
export class BlockingModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOpts,
    public dialogRef: MatDialogRef<BlockingModalComponent>
  ) {}
}
