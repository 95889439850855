import { Pagination, UserProcess } from '@interfaces';

export type VisitsCalendarState = Readonly<{
  loading: boolean;
  visits: UserProcess[];
  goToDateVisits: UserProcess[];
  errorMessage: string | null;
  visitsList: Partial<UserProcess>[];
  visitsListPagination: Pagination;
}>;

export const defaultPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'officeVisitDate',
  orderDir: 'asc',
};

export const initialState: VisitsCalendarState = {
  loading: false,
  visits: null,
  goToDateVisits: null,
  errorMessage: null,
  visitsList: null,
  visitsListPagination: { ...(defaultPagination as Pagination) },
};
