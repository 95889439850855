import { ProcessTypeByCategory } from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as commonsActions from './commons.actions';
import { CommonsState, initialState } from './commons.state';

const reducer = createReducer<CommonsState>(
  initialState,

  on(commonsActions.pingApi, state => ({
    ...state,
    APIPingInProgress: true,
  })),
  on(commonsActions.pingApiSuccess, state => ({
    ...state,
    APIPingInProgress: false,
    lastAPIPingSuccessful: true,
  })),
  on(commonsActions.pingApiError, state => ({
    ...state,
    APIPingInProgress: false,
    lastAPIPingSuccessful: false,
  })),

  on(commonsActions.getListOfAvailableProcesses, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(commonsActions.getListOfAvailableProcessesSuccess, (state, { processes }) => ({
    ...state,
    availableProcesses: processes,
    availableProcessesByCategory: processes.reduce((acc, process) => {
      const category = process.category;

      if (!category) {
        console.error('MISSING PROCESS.CATEGORY');
        return acc;
      }

      if (!acc[category]) {
        acc[category] = [];
      }

      acc[category] = [...acc[category], process];
      return acc;
    }, {} as ProcessTypeByCategory),
    loading: false,
    errorMessage: null,
  })),
  on(commonsActions.getListOfAvailableProcessesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(commonsActions.getCountriesList, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(commonsActions.getCountriesListSuccess, (state, { countriesList }) => ({
    ...state,
    loading: false,
    availableCountries: countriesList,
    errorMessage: null,
  })),
  on(commonsActions.getCountriesListError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(commonsActions.getCountriesEUList, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(commonsActions.getCountriesEUListSuccess, (state, { countriesListEU }) => ({
    ...state,
    loading: false,
    availableCountriesEU: countriesListEU,
    errorMessage: null,
  })),
  on(commonsActions.getCountriesEUListError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(commonsActions.getLanguages, state => ({ ...state, loading: true })),
  on(commonsActions.getLanguagesSuccess, (state, { languages }) => ({
    ...state,
    loading: false,
    availableLanguages: languages,
  })),
  on(commonsActions.getLanguagesError, (state, errorMessage) => ({ ...state, loading: false })),

  on(
    commonsActions.getAllVisitAssistants,
    commonsActions.createVisitAssistant,
    commonsActions.removeAssistant,
    state => ({
      ...state,
      loadingVisitAssistants: true,
      visitAssistantsError: null,
    })
  ),
  on(commonsActions.getAllVisitAssistantsSuccess, (state, { visitAssistants }) => ({
    ...state,
    loadingVisitAssistants: false,
    visitAssistantsError: null,
    visitAssistants,
  })),
  on(commonsActions.createVisitAssistantSuccess, (state, { visitAssistant }) => ({
    ...state,
    loadingVisitAssistants: false,
    visitAssistantsError: null,
    visitAssistants: state.visitAssistants ? [visitAssistant, ...state.visitAssistants] : [visitAssistant],
  })),
  on(commonsActions.removeAssistantSuccess, (state, { removedVisitAssistantId }) => ({
    ...state,
    loadingVisitAssistants: false,
    visitAssistantsError: null,
    visitAssistants: state.visitAssistants.filter(({ id }) => id !== removedVisitAssistantId),
  })),

  on(
    commonsActions.getAllVisitAssistantsError,
    commonsActions.createVisitAssistantError,
    commonsActions.removeAssistantError,
    (state, { errorMessage }) => ({
      ...state,
      loadingVisitAssistants: false,
      visitAssistantsError: errorMessage,
    })
  ),

  on(commonsActions.updateProcessFees, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(commonsActions.updateProcessFeesSuccess, (state, { updatedProcess }) => ({
    ...state,
    availableProcesses: state.availableProcesses?.map(proc => {
      if (proc.id === updatedProcess.id) {
        return { ...proc, fees: updatedProcess.fees };
      } else {
        return proc;
      }
    }),
    loading: false,
    errorMessage: null,
  })),
  on(commonsActions.updateProcessFeesError, (state, { errorMessage }) => ({
    ...state,
    loading: true,
    errorMessage,
  }))
);

export function commonsReducer(state: CommonsState, action: Action): CommonsState {
  return reducer(state, action);
}
