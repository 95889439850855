import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { CommonsFacade } from '@state/commons';
import { RouterFacade } from '@state/router';

import { UserAuthFacade } from '@state/user-auth';
import { UserProcessFacade } from '@state/user-process';

@Component({
  selector: 'app-user-process-form',
  templateUrl: './user-process-form.component.html',
  styleUrls: ['./user-process-form.component.scss'],
})
export class UserProcessFormComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public loading$ = this.userProcessFacade.loading$;
  public availableProcesses$ = this.commonsFacade.availableProcesses$;
  public myself$ = this.userAuthFacade.myself$;

  public form: FormGroup<any> = this.fb.group({
    // processId: [null, Validators.compose([Validators.required])],
    // purposeComment: ['', Validators.compose([Validators.maxLength(2000)])],
    userId: [null, Validators.compose([Validators.required])],
  });

  constructor(
    private readonly userAuthFacade: UserAuthFacade,
    private readonly commonsFacade: CommonsFacade,
    private readonly userProcessFacade: UserProcessFacade,
    private readonly router: RouterFacade,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.commonsFacade.getListOfAvailableProcesses();
    this.myself$
      .pipe(
        filter(myself => !!myself?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(myself => {
        this.form.get('userId').setValue(myself.id);
        // this.form.get('purposeComment').disable();
        this.form.updateValueAndValidity();
      });

    // this.form
    //   .get('processId')
    //   .valueChanges.pipe(withLatestFrom(this.commonsFacade.availableProcesses$), takeUntil(this.destroy$))
    //   .subscribe(([processIdSelected, availableProcesses]) => {
    //     const processSelected = Object.values(availableProcesses).find(proc => proc.id === processIdSelected);
    //     if (!processSelected) {
    //       return;
    //     }

    //     const purposeComment = processSelected.comment || '';
    //     this.form.get('purposeComment').setValue(purposeComment);

    //     if (processSelected.origProcessType !== 'PURP16_OTHER') {
    //       this.form.get('purposeComment').disable();
    //     } else {
    //       this.form.get('purposeComment').enable();
    //     }

    //     this.form.updateValueAndValidity();
    //   });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public submitForm(): void {
    if (this.form.invalid) {
      return;
    }

    this.userProcessFacade.addNewUserProcessSuccess$
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(({ addedUserProcess }) => {
        this.router.changeRoute({ linkParams: ['user-process', addedUserProcess.id] });
      });

    const { userId, ...payload } = this.form.value;
    this.userProcessFacade.addNewUserProcess(payload);
  }
}
