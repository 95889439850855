import { Action, createReducer, on } from '@ngrx/store';
import { CommentsState, initialState } from './comments.state';
import * as commentsActions from './comments.actions';

const reducer = createReducer(
  initialState,
  on(commentsActions.getDocumentComments, (state, { userDocumentId, userProcessId }) => ({
    ...state,
    loadingComments: true,
    errorMessage: null,
    documentComments: {
      userProcessId,
      userDocumentId,
      comments: null,
    },
  })),
  on(commentsActions.getDocumentCommentsSuccess, (state, { userDocumentId, documentComments, userProcessId }) => ({
    ...state,
    loadingComments: false,
    errorMessage: null,
    documentComments: {
      userProcessId,
      userDocumentId,
      comments: documentComments,
    },
  })),
  on(commentsActions.getDocumentCommentsError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingComments: false,
  })),

  on(commentsActions.addDocumentComment, (state, { comment, userProcessId }) => ({
    ...state,
    loadingComments: true,
    errorMessage: null,
  })),
  on(commentsActions.addDocumentCommentSuccess, (state, { createdDocumentComment, userProcessId }) => {
    return {
      ...state,
      loadingComments: false,
      errorMessage: null,
      documentComments: {
        ...state.documentComments,
        comments: state?.documentComments?.comments
          ? [...state.documentComments.comments, createdDocumentComment]
          : null,
      },
    };
  }),
  on(commentsActions.addDocumentCommentError, (state, { errorMessage }) => ({
    ...state,
    loadingComments: false,
    errorMessage,
  })),

  on(commentsActions.editDocumentComment, state => ({
    ...state,
    loadingComments: true,
    errorMessage: null,
  })),
  on(commentsActions.editDocumentCommentSuccess, (state, { editedDocumentComment, userProcessId }) => ({
    ...state,
    documentComments: {
      ...state.documentComments,
      comments: state.documentComments.comments.map(comment =>
        comment.id === editedDocumentComment.id ? editedDocumentComment : comment
      ),
    },
    loadingComments: false,
    errorMessage: null,
  })),
  on(commentsActions.editDocumentCommentError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingComments: false,
  })),

  on(commentsActions.removeDocumentComment, state => ({
    ...state,
    loadingComments: true,
    errorMessage: null,
  })),
  on(commentsActions.removeDocumentCommentSuccess, (state, { removedDocumentCommentId }) => ({
    ...state,
    documentComments: {
      ...state.documentComments,
      comments: state.documentComments.comments.filter(comment => comment.id !== removedDocumentCommentId),
    },
    loadingComments: false,
    errorMessage: null,
  })),
  on(commentsActions.removeDocumentCommentError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingComments: false,
  })),

  on(commentsActions.markDocumentCommentsAsRead, (state, { userProcessId, userDocumentId }) => ({
    ...state,
  })),
  on(commentsActions.markDocumentCommentsAsReadSuccess, (state, { userDocumentId, updatedComments }) => {
    if (state.documentComments.userDocumentId !== userDocumentId) {
      return { ...state };
    }

    return {
      ...state,
      documentComments: {
        ...state.documentComments,
        comments: updatedComments,
      },
    };
  }),
  on(commentsActions.markDocumentCommentsAsReadError, (state, { errorMessage }) => ({
    ...state,
  }))
);

export function commentsReducer(state: CommentsState, action: Action): CommentsState {
  return reducer(state, action);
}
