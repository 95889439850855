import { Component, Input } from '@angular/core';
import { UserProcess } from '@interfaces';

@Component({
  selector: 'app-user-process-status',
  templateUrl: './user-process-status.component.html',
  styleUrls: ['./user-process-status.component.scss'],
})
export class UserProcessStatusComponent {
  @Input() userProcess: UserProcess;

  constructor() {}
}
