import { Action, createReducer, on } from '@ngrx/store';
import { initialState, OfficeState, defaultPagination } from './office.state';
import * as officeActions from './office.actions';
import { Pagination } from '@interfaces';

const reducer = createReducer(
  initialState,

  on(officeActions.createOffice, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(officeActions.createOfficeSuccess, (state, { office }) => ({
    ...state,
    loading: false,
    loadedOffice: office,
    errorMessage: null,
  })),
  on(officeActions.createOfficeError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(officeActions.getOffice, state => ({
    ...state,
    loading: true,
    loadedOffice: null,
    errorMessage: null,
  })),
  on(officeActions.getOfficeSuccess, (state, { office }) => ({
    ...state,
    loading: false,
    loadedOffice: office,
    errorMessage: null,
  })),
  on(officeActions.getOfficeError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    loadedOffice: null,
  })),

  on(officeActions.searchOffices, state => ({
    ...state,
    loading: true,
    offices: [],
    errorMessage: null,
  })),
  on(officeActions.searchOfficesSuccess, (state, { offices }) => ({
    ...state,
    offices: offices.data,
    pagination: offices.pagination,
    loading: false,
    errorMessage: null,
  })),
  on(officeActions.searchOfficesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    offices: [],
    pagination: { ...(defaultPagination as Pagination) },
  })),

  on(officeActions.getOfficesSimpleList, state => ({
    ...state,
    loading: true,
    offices: [],
    errorMessage: null,
  })),
  on(officeActions.getOfficesSimpleListSuccess, (state, { offices }) => ({
    ...state,
    offices: offices,
    loading: false,
    errorMessage: null,
  })),
  on(officeActions.getOfficesSimpleListError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    offices: [],
  })),

  on(officeActions.updateOffice, officeActions.toggleAssignedVoivodeship, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(officeActions.updateOfficeSuccess, officeActions.toggleAssignedVoivodeshipSuccess, (state, { office }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    loadedOffice: office,
    offices: state.offices.map(officeInState => (officeInState.id === office.id ? office : officeInState)),
  })),
  on(officeActions.updateOfficeError, officeActions.toggleAssignedVoivodeshipError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(officeActions.removeOffice, officeActions.restoreOffice, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(officeActions.removeOfficeSuccess, officeActions.restoreOfficeSuccess, (state, { office }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    offices: state.offices.map(officeInState => (officeInState.id === office.id ? office : officeInState)),
  })),
  on(officeActions.removeOfficeError, officeActions.restoreOfficeError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  }))
);

export function officeReducer(state: OfficeState | undefined, action: Action): OfficeState {
  return reducer(state, action);
}
