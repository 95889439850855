import { GUIDE_NAME, PROCESS_CATEGORY } from '@interfaces';
import { getSteps as documentsGetSteps } from './documents';
import { getSteps as fieldConsultantTableGetSteps } from './field-consultant-table';
import { getSteps as firstTimeGetSteps } from './first-time';

import { getSteps as formStep2TempPremitGetSteps } from './TEMP_PREMIT/form-step-2';
import { getSteps as formStep3TempPremitGetSteps } from './TEMP_PREMIT/form-step-3';
import { getSteps as formStep4TempPremitGetSteps } from './TEMP_PREMIT/form-step-4';

import { getSteps as formStep2PermStayGetSteps } from './PERM_STAY/form-step-2';
import { getSteps as formStep3PermStayGetSteps } from './PERM_STAY/form-step-3';
import { getSteps as formStep4PermStayGetSteps } from './PERM_STAY/form-step-4';

import { getSteps as formStep2LongResiGetSteps } from './LONG_RESI/form-step-2';
import { getSteps as formStep3LongResiGetSteps } from './LONG_RESI/form-step-3';
import { getSteps as formStep4LongResiGetSteps } from './LONG_RESI/form-step-4';

import { getSteps as personalDataFormGetSteps } from './personal-data-form';
import { getSteps as presSurveyGetSteps } from './presurvey-intro';
import { getSteps as processIntroGetSteps } from './process-intro';
import { getSteps as summaryVisitDatePickerGetSteps } from './summary-visit-date-picker';
import { TranslateService } from '@ngx-translate/core';

type ProcessCategoryFn = (tService: TranslateService, lang: string) => any;

interface GuideSteps {
  default: ProcessCategoryFn;
  [key: string]: ProcessCategoryFn;
}

interface GuideStepsMap {
  [key: string]: GuideSteps;
}

export const getStepsByGuideName: GuideStepsMap = {
  [GUIDE_NAME.FIRST_TIME]: {
    default: firstTimeGetSteps,
  },
  [GUIDE_NAME.PRESURVEY_INTRO]: {
    default: presSurveyGetSteps,
  },
  [GUIDE_NAME.PROCESS_INTRO]: {
    default: processIntroGetSteps,
  },
  [GUIDE_NAME.PERSONAL_DATA_FORM]: {
    default: personalDataFormGetSteps,
  },
  [GUIDE_NAME.FORM_STEP_2]: {
    default: formStep2TempPremitGetSteps,
    [PROCESS_CATEGORY.TEMP_PREMIT]: formStep2TempPremitGetSteps,
    [PROCESS_CATEGORY.LONG_RESI]: formStep2LongResiGetSteps,
    [PROCESS_CATEGORY.PERM_STAY]: formStep2PermStayGetSteps,
  },
  [GUIDE_NAME.FORM_STEP_3]: {
    default: formStep3TempPremitGetSteps,
    [PROCESS_CATEGORY.TEMP_PREMIT]: formStep3TempPremitGetSteps,
    [PROCESS_CATEGORY.LONG_RESI]: formStep3LongResiGetSteps,
    [PROCESS_CATEGORY.PERM_STAY]: formStep3PermStayGetSteps,
  },
  [GUIDE_NAME.FORM_STEP_4]: {
    default: formStep4TempPremitGetSteps,
    [PROCESS_CATEGORY.TEMP_PREMIT]: formStep4TempPremitGetSteps,
    [PROCESS_CATEGORY.LONG_RESI]: formStep4LongResiGetSteps,
    [PROCESS_CATEGORY.PERM_STAY]: formStep4PermStayGetSteps,
  },
  [GUIDE_NAME.DOCUMENTS]: {
    default: documentsGetSteps,
  },
  [GUIDE_NAME.SUMMARY_VISIT_DATE_PICKER]: {
    default: summaryVisitDatePickerGetSteps,
  },
  [GUIDE_NAME.FIELD_CONSULTANT_TABLE]: {
    default: fieldConsultantTableGetSteps,
  },
};
