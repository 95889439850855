import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

@Pipe({
  name: 'filterlist',
  pure: false,
})
export class FilterListPipe implements PipeTransform {
  transform(items: any[], filter: { objKeyPath: string; val: string }): any {
    if (!items?.length || !filter?.objKeyPath || !filter?.val) {
      return items;
    }
    const { objKeyPath, val } = filter;
    const valLc = val.toLowerCase();

    return items.filter(item => {
      const valInObj = get(item, objKeyPath)?.toString().toLowerCase();
      return valInObj?.indexOf(valLc) !== -1;
    });
  }
}
