import { Action, createReducer, on } from '@ngrx/store';

import { Pagination, User } from '@interfaces';
import * as usersActions from './users.actions';
import { UsersState, defaultPagination, initialState } from './users.state';

const reducer = createReducer(
  initialState,
  on(
    usersActions.getEmployees,
    usersActions.getForeigners,
    usersActions.getManagers,
    usersActions.getCompanyEmployees,
    usersActions.getUsers,
    state => ({
      ...state,
      loading: true,
      errorMessage: null,
      loadedUsersData: [],
    })
  ),

  on(usersActions.getUsersSuccess, (state, { users }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    loadedUsersData: users.data,
    loadedUsersPagination: users.pagination,
  })),
  on(usersActions.getUsersError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    loadedUsersData: [],
    loadedUsersPagination: { ...(defaultPagination as Pagination) },
  })),

  on(usersActions.getUser, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(usersActions.getUserSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    loadedUserData: user,
  })),
  on(usersActions.getUserError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    loadedUserData: null,
  })),

  on(usersActions.createForeigner, usersActions.createEmployee, usersActions.createManager, state => ({
    ...state,
    errorMessage: null,
    loading: true,
  })),
  on(usersActions.createUserSuccess, (state, { createdUser }) => ({
    ...state,
    errorMessage: null,
    loading: false,
    loadedUserData: createdUser,
  })),
  on(usersActions.createUserError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(usersActions.updateEmployee, usersActions.updateForeigner, usersActions.updateManager, state => ({
    ...state,
    errorMessage: null,
    loading: true,
  })),
  on(usersActions.updateUserSuccess, (state, { updatedUser }) => ({
    ...state,
    errorMessage: null,
    loading: false,
    loadedUserData: updatedUser,
    // if updated user on loaded users list - update it
    loadedUsersData: state.loadedUsersData.map(user => (user.id === updatedUser.id ? updatedUser : user)),
  })),
  on(usersActions.updateUserError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),
  on(
    usersActions.removeEmployee,
    usersActions.removeForeigner,
    usersActions.removeManager,
    usersActions.restoreForeigner,
    usersActions.restoreUser,
    state => ({
      ...state,
      errorMessage: null,
      loading: true,
    })
  ),
  on(usersActions.removeUserSuccess, (state, { removedUserId }) => ({
    ...state,
    errorMessage: null,
    loading: false,
    // if removed user on list of loaded users - remove it
    loadedUsersData: state.loadedUsersData.filter(user => user.id !== removedUserId),
  })),
  on(usersActions.restoreUserSuccess, (state, { restoredUser }) => ({
    ...state,
    errorMessage: null,
    loading: false,
    // if restored user on list of loaded users - update it
    loadedUsersData: state.loadedUsersData.map(user => {
      return user.id === restoredUser.id ? (restoredUser as User) : user;
    }),
  })),
  on(usersActions.removeUserError, usersActions.restoreUserError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(
    usersActions.resendForeignerActivation,
    usersActions.resendEmployeeActivation,
    usersActions.resendManagerActivation,
    state => ({
      ...state,
      errorMessage: null,
      loading: true,
    })
  ),
  on(usersActions.resendActivationSuccess, state => ({
    ...state,
    errorMessage: null,
    loading: false,
  })),
  on(usersActions.resendActivationError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(usersActions.getEmployeeTypes, state => ({
    ...state,
    errorMessage: null,
    employeeTypes: null,
    loadingEmployeeTypes: true,
  })),
  on(usersActions.getEmployeeTypesSuccess, (state, { employeeTypes }) => ({
    ...state,
    errorMessage: null,
    loadingEmployeeTypes: false,
    employeeTypes,
  })),
  on(usersActions.getEmployeeTypesError, state => ({
    ...state,
    errorMessage: null,
    loadingEmployeeTypes: false,
  })),

  on(usersActions.selfRegister, state => ({
    ...state,
    errorMessage: null,
    loading: true,
  })),
  on(usersActions.selfRegisterSuccess, state => ({
    ...state,
    errorMessage: null,
    loading: false,
  })),
  on(usersActions.selfRegisterError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),
  on(usersActions.getAllRoles, state => ({
    ...state,
    errorMessage: null,
    employeeTypes: null,
    loadingAllRoles: true,
  })),
  on(usersActions.getAllRolesSuccess, (state, { allRoles }) => ({
    ...state,
    errorMessage: null,
    loadingAllRoles: false,
    allRoles,
  })),
  on(usersActions.getAllRolesError, state => ({
    ...state,
    errorMessage: null,
    loadingAllRoles: false,
  })),

  on(usersActions.reassignForeigners, state => ({
    ...state,
    reassignInProgress: true,
  })),
  on(usersActions.reassignForeignersSuccess, state => ({
    ...state,
    reassignInProgress: false,
  })),
  on(usersActions.reassignForeignersError, state => ({
    ...state,
    reassignInProgress: false,
  }))
);

export function usersReducer(state: UsersState | undefined, action: Action): UsersState {
  return reducer(state, action);
}
