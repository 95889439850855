export const blueCardCountries = [
  'Grecja',
  'Chorwacja',
  'Węgry',
  'Austria',
  'Belgia',
  'Bułgaria',
  'Włochy',
  'Litwa',
  'Luksemburg',
  'Łotwa',
  'Malta',
  'Polska',
  'Portugalia',
  'Rumunia',
  'Szwecja',
  'Słowenia',
  'Holandia',
  'Cypr',
  'Czechy',
  'Niemcy',
  'Estonia',
  'Hiszpania',
  'Francja',
  'Finlandia',
];
