import * as companyActions from './company/company.actions';
import * as managementActions from './management/management.actions';
import * as ssActions from './system-settings/system-settings.actions';
import * as userAuthActions from './user-auth/user-auth.actions';
import * as userProcessActions from './user-process/user-process.actions';
import * as usersActions from './users/users.actions';

export interface ActionsErrors {
  [key: string]: {
    400?: string;
    401?: string;
    404?: string;
    500?: string;
    409?: string;
    0: string;
    429?: string;
  };
}

const getGenericMessages = (instanceName: string, method: string) => {
  const INSTANCE = instanceName.toUpperCase();
  const METHOD = method.toUpperCase();
  return {
    500: `ERRORS.${METHOD}-${INSTANCE}-SERVER-ERROR`,
    400: `ERRORS.BAD-REQUEST-${METHOD}-${INSTANCE}`,
    404: `ERRORS.NO-SUCH-${INSTANCE}`,
    0: `ERRORS.UNKNOWN-ERROR-${METHOD}-${INSTANCE}`,
    409: `ERRORS.${INSTANCE}-ALREADY-EXISTS`,
    401: `ERRORS.YOURE-NOT-AUTHORIZED-TO-${METHOD}-${INSTANCE}`,
    429: `ERRORS.TOO-MANY-CHANGES-PLEASE-WAIT`,
  };
};

const actionsErrors: ActionsErrors = {
  [companyActions.getCompany.type]: getGenericMessages('company', 'get'),
  [companyActions.getCompanies.type]: getGenericMessages('companies', 'get'),
  [usersActions.createEmployee.type]: getGenericMessages('employee', 'create'),
  [usersActions.updateEmployee.type]: getGenericMessages('employee', 'update'),
  [usersActions.removeEmployee.type]: getGenericMessages('employee', 'remove'),
  [usersActions.createForeigner.type]: getGenericMessages('foreigner', 'create'),
  [usersActions.updateForeigner.type]: getGenericMessages('foreigner', 'update'),
  [usersActions.removeForeigner.type]: getGenericMessages('foreigner', 'remove'),
  [usersActions.getForeigners.type]: getGenericMessages('foreigners', 'get'),
  [usersActions.getEmployees.type]: getGenericMessages('employees', 'get'),
  [usersActions.getUsers.type]: getGenericMessages('users', 'get'),
  [usersActions.getUser.type]: getGenericMessages('user', 'get'),
  [userAuthActions.getPasswordResetLink.type]: getGenericMessages('password-reset-link', 'get'),
  [userAuthActions.setNewPassword.type]: getGenericMessages('new-password', 'set'),
  [usersActions.resendEmployeeActivation.type]: getGenericMessages('activation-email', 'resend'),
  [usersActions.resendForeignerActivation.type]: getGenericMessages('activation-email', 'resend'),
  [userProcessActions.getUserProcess.type]: getGenericMessages('user-process', 'get'),
  [userAuthActions.activateAccount.type]: {
    400: 'ERRORS.INVALID-PASSWORD-OR-ACTIVATION-LINK-INVALID',
    401: 'ERRORS.INVALID-PASSWORD-OR-ACTIVATION-LINK-INVALID',
    500: 'ERRORS.SERVER-EXPERIENCED-ISSUES',
    0: 'ERRORS.UNKNOWN-ERROR',
  },
  [userAuthActions.login.type]: {
    401: 'ERRORS.UNAUTHORIZED',
    500: 'ERRORS.SERVER-UNAVAILABLE',
    0: 'ERRORS.UNKNOWN-ERROR',
  },
  [companyActions.updateCompany.type]: {
    400: 'ERRORS.INVALID-DATA-PROVIDED',
    500: 'ERRORS.SERVER-UNAVAILABLE',
    0: 'ERRORS.UNKNOWN-ERROR',
  },
  [managementActions.updatePersonalDetails.type]: {
    ...getGenericMessages('personal-details', 'update'),
    429: 'ERRORS.TOO-MANY-CHANGES-PLEASE-WAIT',
  },
  [ssActions.removeAdmin.type]: {
    ...getGenericMessages('admin', 'create'),
    ...getGenericMessages('admin', 'update'),
    400: 'ERRORS.CANNOT-REMOVE-ADMIN',
  },
  [managementActions.searchUsersProcesses.type]: {
    400: 'ERRORS.INVALID-DATA-PROVIDED',
    500: 'ERRORS.SERVER-UNAVAILABLE',
    0: 'ERRORS.UNKNOWN-ERROR',
    429: 'ERRORS.TOO-MANY-CHANGES-PLEASE-WAIT',
  },
};

export const parseError = (error: any, actionType: string): string => {
  // if nested error.message - assume that its our message from API
  if (error?.error?.message) {
    return `ERRORS.${error.error.message.toString().trim().toUpperCase()}`;
  }

  const errorsForAction: any = actionsErrors[actionType];
  if (!errorsForAction) {
    return 'ERRORS.UNKNOWN-ERROR';
  }

  const statusCode = error.status ? (error.status as number) : 0;
  const message = errorsForAction[statusCode];
  if (!message) {
    return 'ERRORS.UNKNOWN-ERROR';
  }

  return errorsForAction[statusCode];
};
