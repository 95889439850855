import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CompanyHttpService } from '@core/http/company.http.service';

import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { parseError } from '@state/errors.parser';
import * as routerActions from '@state/router/router.actions';
import * as companyActions from './company.actions';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private http: CompanyHttpService,
    private snackService: SnackbarService
  ) {}

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.getCompanies),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.getCompanies(rest).pipe(
          map(response => companyActions.getCompaniesSuccess({ companies: response })),
          catchError(error => {
            const errorMessage = parseError(error, companyActions.getCompanies.type);
            return of(companyActions.getCompaniesError({ errorMessage }));
          })
        );
      })
    )
  );

  getCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.getCompany),
      switchMap(({ companyId }) => {
        return this.http.getCompany(companyId).pipe(
          map(company => companyActions.getCompanySuccess({ company })),
          catchError(error => {
            const errorMessage = parseError(error, companyActions.getCompany.type);
            return of(companyActions.getCompaniesError({ errorMessage }));
          })
        );
      })
    )
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.createCompany),
      switchMap(({ company }) => {
        return this.http.createCompany(company).pipe(
          map(createdCompany => {
            this.snackService.showInfo('COMPANY_CREATED');
            return companyActions.createCompanySuccess({ createdCompany });
          }),
          catchError(error => {
            this.snackService.showError('COMPANY_CREATION_ERROR');
            const errorMessage = parseError(error, companyActions.createCompany.type);
            return of(companyActions.createCompanyError({ errorMessage }));
          })
        );
      })
    )
  );

  createCompanySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.createCompanySuccess),
      map(() => routerActions.changeRoute({ linkParams: ['/management/companies-list'] }))
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.updateCompany),
      switchMap(({ company, companyId }) => {
        return this.http.updateCompany(companyId, company).pipe(
          map(updatedCompany => {
            return companyActions.updateCompanySuccess({ updatedCompany });
          }),
          catchError(error => {
            const errorMessage = parseError(error, companyActions.updateCompany.type);
            return of(companyActions.updateCompanyError({ errorMessage }));
          })
        );
      })
    )
  );

  updateCompanyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.updateCompanyError),
        map(() => {
          this.snackService.showError('COMPANY-UPDATE-ERROR');
        })
      ),
    { dispatch: false }
  );

  updateCompanySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.updateCompanySuccess),
        map(() => {
          this.snackService.showInfo('COMPANY-UPDATE-SUCCESS');
        })
      ),
    { dispatch: false }
  );

  setIsActiveCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.setIsActiveCompany),
      switchMap(({ companyId, isActive }) => {
        return this.http.setIsActiveCompany(companyId, isActive).pipe(
          map(updatedCompany => companyActions.setIsActiveCompanySuccess({ updatedCompany })),
          catchError(error => {
            const errorMessage = parseError(error, companyActions.setIsActiveCompany.type);
            return of(companyActions.setIsActiveCompanyError({ errorMessage }));
          })
        );
      })
    )
  );
  setIsActiveCompanyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.setIsActiveCompanyError),
        map(() => {
          this.snackService.showError('COMPANY-UPDATE-ERROR');
        })
      ),
    { dispatch: false }
  );

  setIsActiveCompanySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.setIsActiveCompanySuccess),
        map(() => {
          this.snackService.showInfo('COMPANY-UPDATE-SUCCESS');
        })
      ),
    { dispatch: false }
  );

  // removeCompany$ = createEffect(() => this.actions$.pipe(
  //   ofType(companyActions.removeCompany),
  //   switchMap(({ companyId }) => {
  //     return this.http.removeCompany(companyId).pipe(
  //       map(() => companyActions.removeCompanySuccess({ removedCompanyId: companyId })),
  //       catchError(error => {
  //         const errorMessage = parseError(error, companyActions.removeCompany.type);
  //         return of(companyActions.updateCompanyError({ errorMessage }));
  //       }),
  //     );
  //   }),
  // ));

  getCompanyEmployeesProcesses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.getCompanyEmployeesProcesses),
      switchMap(({ opts }) => {
        const { all, count, ...payload } = opts;
        return this.http.getCompanyEmployeesProcesses(payload).pipe(
          map(employeesProcesses => companyActions.getCompanyEmployeesProcessesSuccess({ employeesProcesses })),
          catchError(error => {
            const errorMessage = parseError(error, companyActions.getCompanyEmployeesProcesses.type);
            return of(companyActions.getCompanyEmployeesProcessesError({ errorMessage }));
          })
        );
      })
    )
  );
}
