<div class="wrapper">
  <div
    class="arrow-wrapper left"
    (click)="previousClicked()"
    (keydown.space)="previousClicked()"
    (keydown.enter)="previousClicked()"
    tabindex="0">
    <mat-icon>chevron_left</mat-icon>
  </div>
  <div class="standard-form-field month-select">
    <mat-select
      [(ngModel)]="selectedMonth"
      (ngModelChange)="monthChanged($event)"
      [panelClass]="['standard-select']"
      [disableOptionCentering]="true">
      <mat-option
        *ngFor="let monthKey of monthsKeys; index as monthIndex"
        [value]="monthKey"
        [disabled]="monthIndexDisabled[monthIndex]">
        {{ 'MONTHS.' + monthKey | translate }}
      </mat-option>
    </mat-select>
  </div>

  <div class="standard-form-field year-select">
    <mat-select
      [(ngModel)]="selectedYear"
      (ngModelChange)="yearChanged($event)"
      [panelClass]="['standard-select']"
      [disableOptionCentering]="true">
      <mat-option *ngFor="let year of years" [value]="year">
        {{ year }}
      </mat-option>
    </mat-select>
  </div>

  <div
    class="arrow-wrapper right"
    (click)="nextClicked()"
    (keydown.space)="nextClicked()"
    (keydown.enter)="nextClicked()"
    tabindex="0">
    <mat-icon>chevron_right</mat-icon>
  </div>
</div>
