import { createAction, props } from '@ngrx/store';
import {
  CreateMessageInMyChat,
  MultipleFilesUploadResult,
  SupportChat,
  SupportChatMessage,
  UploadMessageAttachmentsOpts,
} from '@interfaces';

export const getMyChat = createAction('[My-Chat] Get My Chat');
export const getMyChatSuccess = createAction(
  '[My-Chat] Get My Chat Success',
  props<{ supportChat: SupportChat; messages: SupportChatMessage[] }>()
);
export const getMyChatError = createAction('[My-Chat] Get My Chat Error', props<{ errorMessage: any }>());

export const addChatMessage = createAction('[My-Chat] Add Chat Message', props<{ opts: CreateMessageInMyChat }>());
export const addChatMessageSuccess = createAction(
  '[My-Chat] Add Chat Message Success',
  props<{ message: SupportChatMessage }>()
);
export const addChatMessageError = createAction('[My-Chat] Add Chat Message Error', props<{ errorMessage: any }>());

export const uploadAttachments = createAction(
  '[My-Chat] Upload Message Attachments',
  props<{ opts: UploadMessageAttachmentsOpts }>()
);
export const uploadAttachmentsSuccess = createAction(
  '[My-Chat] Upload Message Attachments Success',
  props<{ opts: { uploadedFiles: MultipleFilesUploadResult } }>()
);
export const uploadAttachmentsError = createAction(
  '[My-Chat] Upload Message Attachments Error',
  props<{ opts: { errorMessage: any } }>()
);

export const downloadAttachmentFromChat = createAction(
  '[My-Chat] Download Message Attachment',
  props<{ messageId: string; attachmentId: string }>()
);
export const downloadAttachmentFromChatSuccess = createAction('[My-Chat] Download Message Attachment Success');
export const downloadAttachmentFromChatError = createAction('[My-Chat] Download Message Attachment Error');

export const removeAttachmentFromUploading = createAction(
  '[My-Chat] Remove Attachment From message',
  props<{ opts: { userAssetId: string; fileOriginalName: string } }>()
);
export const removeAttachmentFromUploadingSuccess = createAction(
  '[My-Chat] Remove Attachment From message Success',
  props<{ opts: { userAssetId: string; fileOriginalName: string } }>()
);
export const removeAttachmentFromUploadingError = createAction(
  '[My-Chat] Remove Attachment From message Error',
  props<{ opts: { userAssetId: string; errorMessage: any; fileOriginalName: string } }>()
);
