import { Pagination, User, Voivodeship } from '@interfaces';

export interface Office {
  id?: string;
  name: string;
  addressStreet: string;
  addressExtraInfo: { [key: string]: string };
  recipientForPost?: { [key: string]: string };
  addressZipcode: string;
  addressCity: string;
  telephoneNumber: string;

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;

  assignedVoivodeships?: Partial<Voivodeship>[];
  assignedEmployees?: Partial<User>[];
}

export interface SearchOfficeOpts extends Pagination {
  voivodeshipsIds?: string[];
  showDeleted?: boolean;
}

export interface CreateOfficeOpts {
  name: string;
  addressStreet: string;
  addressExtraInfo: { [key: string]: string };
  recipientForPost?: { [key: string]: string };
  addressZipcode: string;
  addressCity: string;
  telephoneNumber: string;
}

export interface ToggleAssignedVoivodeship {
  officeId: string;
  voivodeshipId: string;
  assigned: boolean;
}
