import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import { AvailableLanguages, fullDateFormat, fullDateFormatTimestamp } from '@constants';
import { PROCESS_CATEGORY, PersonalDetails, User, UserProcess } from '@interfaces';
import { LayoutService } from '@layout/layout.service';
import { getPersonalDataFormGroupOptsLongResi } from '@shared/personal-data-form/LONG_RESI/form-fields.config';
import { getPersonalDataFormGroupOptsPermStay } from '@shared/personal-data-form/PERM_STAY/form-fields.config';
import { getPersonalDataFormGroupOptsTempPremit } from '@shared/personal-data-form/TEMP_PREMIT/form-fields.config';
import { pick } from 'lodash-es';
import * as moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';

type DateFormats = {
  [key: string]: string;
};

type DefaultValuesFormats = {
  [key: string]: any;
};

@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrls: ['./personal-data-form.component.scss'],
})
export class PersonalDataFormComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  public PROCESS_CATEGORY = PROCESS_CATEGORY;

  @Input() user: User;
  @Input() userProcess: UserProcess;
  @Input() currentLang: AvailableLanguages;
  @Input() loadingUserProcess: boolean;
  @Input() errorMessage: string;
  @Input() mode: 'foreigner' | 'employee';

  @Output() updateDetails = new EventEmitter<PersonalDetails>();
  @Output() confirmDetails = new EventEmitter<void>();
  @Output() confirmVoivoChange = new EventEmitter<void>();
  @Output() toggleVerificationOfPersonalDetails = new EventEmitter();

  public correctlyFilledSteps: number = 0;
  public personalDataForm: FormGroup;
  public percentageOfCompletness: number = 0;
  public totalStepsInForm: number;
  public categoryForm: PROCESS_CATEGORY;

  public datesFields = [
    'dateOfBirth',
    'lastEntryDate',
    'residenceVisaExpiryDate',
    'travelDocumentIssueDate',
    'travelDocumentExpiryDate',
    'stayVisaIssueDate',
    'stayVisaExpiryDate',
    'entryDocumentIssueDate',
    'entryDocumentExpiryDate',
    'stayEntitlingDocumentIssueDate',
    'spouseDob',
  ];

  public dateFormatsForFields: DateFormats = {
    ['dateOfBirth']: fullDateFormat,
    ['lastEntryDate']: fullDateFormat,
    ['residenceVisaExpiryDate']: fullDateFormat,
    ['travelDocumentIssueDate']: fullDateFormatTimestamp,
    ['travelDocumentExpiryDate']: fullDateFormatTimestamp,
    ['stayVisaIssueDate']: fullDateFormatTimestamp,
    ['stayVisaExpiryDate']: fullDateFormatTimestamp,
    ['entryDocumentIssueDate']: fullDateFormatTimestamp,
    ['entryDocumentExpiryDate']: fullDateFormatTimestamp,
    ['stayEntitlingDocumentIssueDate']: fullDateFormatTimestamp,
    ['spouseDob']: fullDateFormat,
  };

  // This is causing serious problems.
  // Default values should be set at API when creating personalDetails in DB
  // NOT IN FE
  // public defaultValuesFields = ['noLastTravelsOutsidePoland'];

  // public defaultValues: DefaultValuesFormats = {
  //   ['noLastTravelsOutsidePoland']: false,
  // };

  constructor(
    private readonly layoutService: LayoutService,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.categoryForm = null;
    this.layoutService.showProgressBar$.next(false);
    this.layoutService.progressPercentage$.next(0);
    this.layoutService.showBottomFixedBar$.next(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setupForm(): void {
    let formGroup: FormGroup;

    const procCategory = this.userProcess?.process?.category;

    if (procCategory === PROCESS_CATEGORY.TEMP_PREMIT) {
      formGroup = getPersonalDataFormGroupOptsTempPremit(this.fb);
      this.totalStepsInForm = 5;
    }
    if (procCategory === PROCESS_CATEGORY.LONG_RESI) {
      formGroup = getPersonalDataFormGroupOptsLongResi(this.fb);
      this.totalStepsInForm = 6;
    }
    if (procCategory === PROCESS_CATEGORY.PERM_STAY) {
      formGroup = getPersonalDataFormGroupOptsPermStay(this.fb);
      this.totalStepsInForm = 6;
    }

    this.correctlyFilledSteps = 0;

    // Fill form with nested groups
    Object.values(formGroup.controls).forEach((stepGroup: any) => {
      const group = stepGroup as FormGroup;
      const keysInGroup = Object.keys(group.controls);
      const personalDetailsPickedProps = pick(this.userProcess.personalDetails, keysInGroup);

      group.setValue(personalDetailsPickedProps);

      if (this.hasAnyFieldAnyValue(personalDetailsPickedProps)) {
        group.markAsTouched();
      }

      // Set default values if they exist\
      // This is causing serious problems.
      // Default values should be set at API when creating personalDetails in DB
      // NOT IN FE
      // this.defaultValuesFields.forEach((defaultValueFieldName: string) => {
      //   if (keysInGroup.includes(defaultValueFieldName)) {
      //     const fieldVal = this.userProcess.personalDetails[defaultValueFieldName as keyof PersonalDetails] as
      //       | string
      //       | null;
      //     if (fieldVal === null) {
      //       group.get(defaultValueFieldName).setValue(this.defaultValues[defaultValueFieldName]);
      //     }
      //   }
      // });

      // Unfortunately each date field needs to be `.setValue` separately because of using moment
      this.datesFields.forEach((dateFieldName: string) => {
        if (keysInGroup.includes(dateFieldName)) {
          const fieldVal = this.userProcess.personalDetails[dateFieldName as keyof PersonalDetails] as string | null;
          const dateFormat: string | undefined = this.dateFormatsForFields[dateFieldName];
          if (fieldVal && dateFormat) {
            if (dateFormat) {
              group.get(dateFieldName).setValue(moment(fieldVal, dateFormat));
            }
          }
        }
      });

      if (group.valid) {
        this.correctlyFilledSteps += 1;
      }
    });

    this.personalDataForm = formGroup;
    this.categoryForm = this.userProcess.process.category;
    this.percentageOfCompletness = Math.ceil((this.correctlyFilledSteps / this.totalStepsInForm) * 100);
    setTimeout(() => {
      this.layoutService.progressPercentage$.next(this.percentageOfCompletness);
      this.layoutService.showProgressBar$.next(true);

      if (this.mode === 'foreigner') {
        this.layoutService.showBottomFixedBar$.next(true);
      }
    });
  }

  private hasAnyFieldAnyValue(personalDetailsPickedProps: Partial<PersonalDetails>): boolean {
    return Object.values(personalDetailsPickedProps).some(val => {
      if (Array.isArray(val) && val.length > 0) {
        return true;
      }

      return val !== '' && val !== undefined && val !== null;
    });
  }
}
