import { Action, createReducer, on } from '@ngrx/store';
import { initialState, UserFilesState } from './user-files.state';
import * as userFilesActions from './user-files.actions';

const reducer = createReducer(
  initialState,
  on(userFilesActions.getUserAssets, userFilesActions.getFilesOfUserDocuments, state => ({
    ...state,
    userAssets: [],
    filesOfDocuments: [],
    loading: true,
  })),

  on(userFilesActions.getFilesOfUserDocumentsSuccess, (state, { filesOfDocuments, getOpts }) => ({
    ...state,
    filesOfDocuments,
    sort: { sortBy: getOpts.sortBy, sortDir: getOpts.sortDir },
    loading: false,
  })),
  on(userFilesActions.getUserAssetsSuccess, (state, { userAssets, getOpts }) => ({
    ...state,
    userAssets,
    sort: { sortBy: getOpts.sortBy, sortDir: getOpts.sortDir },
    loading: false,
  })),

  on(userFilesActions.getFilesOfUserDocumentsError, userFilesActions.getUserAssetsError, state => ({
    ...state,
    loading: false,
  })),

  on(userFilesActions.downloadAsset, (state, { userAssetId }) => ({
    ...state,
    downloadingFile: userAssetId,
  })),
  on(userFilesActions.downloadAssetSuccess, userFilesActions.downloadAssetError, state => ({
    ...state,
    downloadingFile: null,
  }))
);

export function userFilesReducer(state: UserFilesState, action: Action): UserFilesState {
  return reducer(state, action);
}
