import { Action, createReducer, on } from '@ngrx/store';
import * as dcActions from './discount-codes.actions';
import { DiscountCodesState, initialState } from './discount-codes.state';

const reducer = createReducer(
  initialState,

  on(
    dcActions.createDiscountCode,
    dcActions.editDiscountCode,
    dcActions.searchDiscountCodes,
    dcActions.setActiveDiscountCode,
    dcActions.getDiscountCodeDetails,
    state => ({
      ...state,
      loading: true,
    })
  ),

  on(dcActions.checkIfDiscountCodeExists, state => ({
    ...state,
    checkIfCodeExistsInProgress: true,
  })),
  on(dcActions.checkIfDiscountCodeExistsSuccess, dcActions.checkIfDiscountCodeExistsError, state => ({
    ...state,
    checkIfCodeExistsInProgress: false,
  })),

  on(dcActions.searchDiscountCodesSuccess, (state, { discountCodes }) => ({
    ...state,
    loading: false,
    discountCodes: discountCodes.data,
    discountCodesPagination: discountCodes.pagination,
  })),

  on(dcActions.getDiscountCodeDetailsSuccess, (state, { discountCode }) => ({
    ...state,
    loading: false,
    discountCodeDetails: discountCode,
  })),

  on(dcActions.createDiscountCodeSuccess, dcActions.editDiscountCodeSuccess, (state, { discountCode }) => ({
    ...state,
    loading: false,
  })),

  on(dcActions.setActiveDiscountCodeSuccess, (state, { discountCode }) => ({
    ...state,
    loading: false,
    discountCodes: state.discountCodes.map(dc => (dc.id === discountCode.id ? structuredClone(discountCode) : dc)),
  })),
  on(
    dcActions.createDiscountCodeError,
    dcActions.editDiscountCodeError,
    dcActions.searchDiscountCodesError,
    dcActions.setActiveDiscountCodeError,
    dcActions.getDiscountCodeDetailsError,
    state => ({
      ...state,
      loading: false,
    })
  )
);

export function discountCodesReducer(state: DiscountCodesState | undefined, action: Action): DiscountCodesState {
  return reducer(state, action);
}
