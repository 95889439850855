import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AvailableLanguages, defaultLanguages } from '@constants';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { CommonsFacade } from '@state/commons';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import {
  NewAccountFormGroup,
  newAccountFormFields,
  wholeFormValidator,
  GDPRValidator,
} from './new-account-form.config';

import { LocalStorageService } from '@core/local-storage.service';
import { AllDeviceInfo, Language, SelfRegisterOpts } from '@interfaces';
import { SystemSettingsFacade } from '@state/system-settings';
import { UsersFacade } from '@state/users';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss'],
})
export class NewAccountComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public APIAvailable: boolean;
  public selfRegisterActive: boolean;
  public deviceInfo: AllDeviceInfo;
  public currentUserLang: AvailableLanguages;

  public loading$ = this.usersFacade.loading$;
  public loadingSelfRegisterActive$ = this.systemSettingsFacade.loading$;
  public loadingLangs$ = this.commonsFacade.loading$;
  public errorMessage$ = this.usersFacade.errorMessage$;

  public newAccountForm: FormGroup<NewAccountFormGroup>;
  public registrationFinished = false;
  public lastUsedData: SelfRegisterOpts;

  public labelW = { xs: 8, sm: 8, md: 8, lg: 8, xl: 8, xxl: 8 };
  public inputW = { xs: 10, sm: 15, md: 15, lg: 16, xl: 14, xxl: 14 };

  constructor(
    private readonly usersFacade: UsersFacade,
    private readonly commonsFacade: CommonsFacade,
    private readonly systemSettingsFacade: SystemSettingsFacade,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly fb: FormBuilder,
    private readonly ls: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.commonsFacade
      .getLanguages$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(availableLanguages => {
        const { referalCode } = this.activatedRoute.snapshot.queryParams;
        // setup user lang with languages fetched from API
        this.setupUserLang([...availableLanguages]);

        this.newAccountForm = this.fb.group<NewAccountFormGroup>(newAccountFormFields, {
          validators: [wholeFormValidator, GDPRValidator],
          // updateOn: 'blur',
        });
        this.newAccountForm.get('langKey').setValue(this.translateService.currentLang);
        if (referalCode) {
          this.newAccountForm.patchValue({ referalCode });
        }
      });

    this.deviceInfo = this.deviceInfoService.getInfo();
    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.commonsFacade.lastAPIPingSuccessfull$.pipe(takeUntil(this.destroy$)).subscribe(APIPingSuccess => {
      if (!APIPingSuccess) {
        // if API connection failed - setup langs with default Languages hardcoded
        this.setupUserLang([...defaultLanguages]);
        this.APIAvailable = false;
        this.selfRegisterActive = false;
        return;
      }
      this.APIAvailable = true;
    });

    this.systemSettingsFacade.selfRegisterActive$.pipe(takeUntil(this.destroy$)).subscribe(isActive => {
      this.selfRegisterActive = isActive;
    });
    this.systemSettingsFacade.getSelfRegistrationStatus();
  }

  ngAfterViewInit(): void {
    this.usersFacade.selfRegisterSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.registrationFinished = true;
    });
    this.errorMessage$.pipe(takeUntil(this.destroy$)).subscribe(errorMessage => {
      if (errorMessage === 'ACCOUNT_WITH_SUCH_EMAIL_EXIST') {
        this.newAccountForm.get('email').setErrors({ emailTaken: true }, { emitEvent: true });
        this.newAccountForm.get('email').markAsDirty();
        this.newAccountForm.updateValueAndValidity();
      }

      if (errorMessage === 'SELF_REGISTRATION_CURRENTLY_UNAVAILABLE') {
        this.newAccountForm.get('referalCode').setErrors({ serverError: true }, { emitEvent: true });
        this.newAccountForm.get('referalCode').markAsDirty();
        this.newAccountForm.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this.registrationFinished = false;
    this.newAccountForm.reset();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public userLangChanged($event: Language): void {
    this.currentUserLang = $event.key as AvailableLanguages;
    this.newAccountForm.get('langKey').setValue(this.currentUserLang);
  }

  public sendForm(): void {
    // Do not allow to send multiple registration requests at 1 session
    // IDK how user might be able to manage to do that but just in case.
    if (this.registrationFinished) {
      return;
    }
    this.newAccountForm.markAllAsTouched();
    this.newAccountForm.updateValueAndValidity();

    const { email, langKey, name, surname, hasAcceptedGDPR } = this.newAccountForm.value;
    const selfRegisterOpts: SelfRegisterOpts = {
      email: email.trim(),
      langKey,
      name: `${name.trim()} ${surname.trim()}`.trim(),
      hasAcceptedGDPR,
    };

    this.lastUsedData = { ...selfRegisterOpts };
    this.usersFacade.selfRegister(selfRegisterOpts);
  }

  private setupUserLang(langs: Language[]): void {
    const { langKey } = this.activatedRoute.snapshot.queryParams;

    // lang from query params is most important
    const langInQueryParams = langs.find(({ key }) => key === langKey);

    // if no lang in query params then localStorage
    const langInLS = this.ls.getUserLang();

    // if no lang in Query & no in LS use default
    const defaultLang = langs.find(({ key }) => key === 'en');

    // 1. langInQuery 2. langInLS 3. defaultLang
    const langToUse = langInQueryParams?.key ? langInQueryParams : langInLS?.key ? langInLS : defaultLang;

    this.ls.setUserLang(langToUse);
    this.translateService.use(langToUse.key);
    this.currentUserLang = langToUse.key as AvailableLanguages;
  }
}
