import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarComponent } from './snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  // CSS style for those classes in global styles.scss
  private panelClassesByMessageType = {
    warning: 'warning-snack',
    info: 'info-snack',
    error: 'error-snack',
  };

  private defaultPanelClass = 'bridge-snack';
  private defaultDurationInfo = 4000;
  private defaultDurationWarning = 6000;
  private defaultDurationError = 6000;
  private snackRef: MatSnackBarRef<SnackBarComponent>;

  constructor(
    private readonly snack: MatSnackBar,
    private readonly translateService: TranslateService
  ) {}

  public showWarning(messageKey: string, translateParams?: any): void {
    const message = this.translateService.instant(messageKey, translateParams);
    this.open(message, 'OK', {
      panelClass: this.panelClassesByMessageType.warning,
      duration: this.defaultDurationWarning,
    });
  }

  public showInfo(messageKey: string, translateParams?: any): void {
    const message = this.translateService.instant(messageKey, translateParams);
    this.open(message, 'OK', {
      panelClass: this.panelClassesByMessageType.info,
      duration: this.defaultDurationInfo,
    });
  }

  public showError(messageKey: string, translateParams?: any): void {
    const message = this.translateService.instant(messageKey, translateParams);
    this.open(message, 'OK', {
      panelClass: this.panelClassesByMessageType.error,
      duration: this.defaultDurationError,
    });
  }

  private open(message: string, action?: string, config?: MatSnackBarConfig<any>): void {
    const allPanelClasses = [this.defaultPanelClass];
    if (config?.panelClass) {
      allPanelClasses.push(config?.panelClass as string);
    }

    this.snackRef = this.snack.openFromComponent(SnackBarComponent, {
      data: { message },
      panelClass: allPanelClasses,
      duration: config?.duration ? config.duration : 6000,
    });
  }

  public close(): void {
    this.snackRef?.dismiss();
  }
  public closeAll(): void {
    this.snack?.dismiss();
  }
}
