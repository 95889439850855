<div class="chats-list-wrapper" [@showChatsPanel]="showChatsPanel">
  <div class="chats-list-toggle-button" (click)="showChatsPanel = !showChatsPanel">
    <nz-badge [nzCount]="unreadCount$ | async" [nzStandalone]="true"> </nz-badge>
    <mat-icon *ngIf="showChatsPanel">chevron_right</mat-icon>
    <mat-icon *ngIf="!showChatsPanel">chevron_left</mat-icon>
  </div>

  <div class="chats-inner-wrapper">
    <mat-tab-group style="height: 100%" [contentTabIndex]="-1" [disablePagination]="true" [disableRipple]="true">
      <mat-tab label="{{ 'NT3.SUPPORT' | translate }}" [disabled]="!showChatsPanel">
        <ng-template matTabContent>
          <app-support-chat-desktop></app-support-chat-desktop>
        </ng-template>
      </mat-tab>

      <mat-tab [disabled]="true" nzTooltipTitle="Coming soon" nzTooltipPlacement="top" [disabled]="!showChatsPanel">
        <ng-template mat-tab-label>
          <label matTooltip="Coming soon">
            {{ 'NT3.PRIVATE_CHATS' | translate }}
          </label>
        </ng-template>
        <ng-template matTabContent>
          <div class="coming-soon">
            <p>COMING SOON</p>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<app-support-chat-window-desktop *ngIf="openSupportChat$ | async" [@nextToPanel]="showChatsPanel">
</app-support-chat-window-desktop>
