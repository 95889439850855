<div class="layout-wrapper" *ngIf="myself$ | async as myself">
  <!------------------------->
  <!-- DESKTOP LEFT COLUMN -->
  <!------------------------->

  <div *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'; else MobileBlock" class="column column-left">
    <div class="logo-container">
      <app-company-logo (logoClicked)="logoClicked()"></app-company-logo>
    </div>
    <ng-container *ngIf="(myProcesses$ | async)?.length > 0">
      <app-application-selector
        class="application-selector-container"
        [myProcesses$]="myProcesses$"
        [myProcess]="myProcess"
        [addingNewProcess]="addingNewProcess"
        [myself$]="myself$"
        (applicationChanged)="applicationChanged($event)">
      </app-application-selector>
    </ng-container>
    <div class="margin-setter" *ngIf="!isForeigner"></div>
    <app-sidebar-menu
      class="menu-items-container"
      [sideMenuItems]="sideMenuItems"
      [isForeigner]="isForeigner"
      [myProcess]="myProcess"
      [addingNewProcess]="addingNewProcess">
    </app-sidebar-menu>
  </div>

  <!------------------------>
  <!-- MOBILE LEFT COLUMN -->
  <!------------------------>

  <ng-template #MobileBlock>
    <nz-drawer
      [nzClosable]="false"
      [nzVisible]="showSidebar"
      [nzPlacement]="'left'"
      [nzWidth]="sidebarWidth"
      [nzBodyStyle]="{
        padding: '0'
      }"
      (nzOnClose)="toggleSidebar()">
      <ng-container *nzDrawerContent>
        <div class="column column-left" appSwipe (swipeLeft)="toggleSidebar()">
          <button id="close-button-container_left" mat-icon-button (click)="toggleSidebar()">
            <mat-icon>menu</mat-icon>
          </button>
          <div class="utils-buttons-wrapper">
            <app-utils-buttons></app-utils-buttons>
          </div>
          <ng-container *ngIf="(myProcesses$ | async)?.length > 0">
            <app-application-selector
              class="application-selector-container"
              [myProcesses$]="myProcesses$"
              [myProcess]="myProcess"
              [addingNewProcess]="addingNewProcess"
              [myself$]="myself$"
              (applicationChanged)="applicationChanged($event)">
            </app-application-selector>
          </ng-container>
          <app-sidebar-menu
            class="menu-items-container"
            [sideMenuItems]="sideMenuItems"
            [isForeigner]="isForeigner"
            [myProcess]="myProcess"
            [addingNewProcess]="addingNewProcess">
          </app-sidebar-menu>
        </div>
      </ng-container>
    </nz-drawer>
  </ng-template>

  <!------------------>
  <!-- RIGHT COLUMN -->
  <!------------------>

  <div class="column column-right">
    <div class="shout-container" *ngIf="(maintenanceInfo$ | async)?.maintenanceUpcoming">
      <div
        class="shout-message"
        *ngIf="(maintenanceInfo$ | async)?.message as message"
        [innerHTML]="message[currentLang]"></div>
    </div>

    <div class="topbar" [class.has-shadow]="showTopbarShadow">
      <div class="row">
        <button
          *ngIf="deviceInfo.deviceTypeDetected !== 'DESKTOP'"
          id="close-button-container_right"
          mat-icon-button
          (click)="toggleSidebar()">
          <mat-icon>menu</mat-icon>
        </button>
        <div *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'" class="utils-buttons-wrapper">
          <app-utils-buttons></app-utils-buttons>
        </div>
        <div class="percentages-container" *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP' && showProgressBar">
          <div matTooltip="{{ 'LAYOUT.COMPLETION-PERCENTAGE' | translate }}" matTooltipClass="personalDataFormTooltip">
            <span class="progress-percent-text">{{ progressPercentage }} %</span>
          </div>
        </div>
        <div class="menu-buttons">
          <!-- Only for administrators buttons -->
          <button *ngIf="isAdministrator" mat-button [routerLink]="['/administration/system-settings']">
            <mat-icon fontSet="material-icons-outlined">construction</mat-icon>
          </button>
          <!-- ADMINs buttons END -->

          <button
            *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP' && isForeigner"
            mat-button
            disableRipple
            [matMenuTriggerFor]="helpmenu"
            class="open-help-button">
            <mat-icon fontSet="material-icons-outlined">question_mark</mat-icon>
          </button>
          <mat-menu #helpmenu="matMenu">
            <button mat-menu-item (click)="openGuide(GUIDE_NAME.FIRST_TIME)" *ngIf="isForeigner">
              <mat-icon class="regular-text-color" fontSet="material-icons-outlined">live_help</mat-icon>
              {{ 'GUIDE.RESTART_GLOBAL_GUIDE' | translate }}
            </button>
          </mat-menu>
          <button
            (click)="showNotificationsWindow()"
            class="open-notifications-button"
            [class.has-unread-notifications]="unreadNotificationsCount > 0"
            [class.received-notification]="newNotificationReceived"
            mat-button>
            <mat-icon
              class="open-notifications-icon"
              [matBadgeHidden]="unreadNotificationsCount === 0"
              matBadgePosition="before"
              matBadgeSize="medium"
              [matBadgeColor]="'warn'"
              fontSet="material-icons-outlined"
              [matBadge]="unreadNotificationsCount"
              >notifications
            </mat-icon>
          </button>

          <button mat-button disableRipple [matMenuTriggerFor]="topbarmenu" class="profile-button">
            <mat-icon *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'" fontSet="material-icons-outlined">
              person
            </mat-icon>
            <mat-icon *ngIf="deviceInfo.deviceTypeDetected !== 'DESKTOP'" fontSet="material-icons-outlined">
              settings
            </mat-icon>
          </button>
          <mat-menu #topbarmenu="matMenu" [class]="'main-user-menu'">
            <button mat-menu-item (click)="goTo('/user-settings')" class="user-profile-menu-item">
              <mat-icon class="regular-text-color">person</mat-icon>
              <span>{{ myself.name }}</span>
              <span class="menu-item-office-city" *ngIf="shouldShowOffice && myself.office?.addressCity">
                {{ myself.office.addressCity }}, {{ myself.office.addressStreet }}
              </span>
              <span
                class="menu-item-office-city"
                *ngIf="shouldShowOffice && !myself.office?.addressCity"
                [class.missing-assigned-office]="shouldShowOffice && !myself.office?.addressCity"
                [matTooltip]="'NT.CONTACT_ADMINISTRATOR_TO_ASSIGN_OFFICE' | translate"
                matTooltipClass="universalTooltip">
                {{ 'NT.OFFICE_NOT_ASSIGNED' | translate }}
              </span>
            </button>
            <!-- Profile button (the one above) directs user to settings  -->
            <!-- <button mat-menu-item (click)="goTo('/user-settings')">
              <mat-icon class="regular-text-color">settings</mat-icon>
              {{ 'PROFILE.SETTINGS' | translate }}
            </button> -->
            <button mat-menu-item (click)="logout()">
              <mat-icon class="regular-text-color">logout</mat-icon>
              {{ 'PROFILE.LOGOUT' | translate }}
            </button>
            <button mat-menu-item>
              <span class="version-text"> Version {{ version }}</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="row row-progress-bar" *ngIf="showProgressBar">
        <mat-progress-bar mode="determinate" [value]="progressPercentage"></mat-progress-bar>
      </div>
      <div class="percentages-container" *ngIf="deviceInfo.deviceTypeDetected !== 'DESKTOP' && showProgressBar">
        <div matTooltip="{{ 'LAYOUT.COMPLETION-PERCENTAGE' | translate }}" matTooltipClass="personalDataFormTooltip">
          <span class="progress-percent-text">{{ progressPercentage }} %</span>
        </div>
      </div>
    </div>
    <div *ngIf="infoBarState.show" class="top-info-bar" [ngClass]="infoBarState.type" (click)="infoBarClicked()">
      <ng-container *ngIf="infoBarState.type === 'logout-warning'; else normalInfo">
        <span
          class="text-content"
          [innerHTML]="'TOP_BAR.LOGOUT_WARNING' | translate: { timeLeft: infoBarState.timeLeft }">
        </span>
      </ng-container>

      <ng-template #normalInfo>
        <mat-icon
          *ngIf="infoBarState.type === 'info' && deviceInfo.deviceTypeDetected !== 'PHONE'"
          class="icon-info"
          svgIcon="info-outline"></mat-icon>
        <div class="text-content" [innerHTML]="infoBarState.message | translate: infoBarState.translateParams"></div>
        <!-- <mat-icon *ngIf="infoBarState.action === 'PAGE_REFRESH'"
                  (click)="infoBarClicked()"
                  class="icon-refresh"
                  svgIcon="refresh">
        </mat-icon> -->
      </ng-template>
    </div>

    <app-notifications *ngIf="showNotifications" (closeNotifications)="showNotifications = false"></app-notifications>

    <div
      class="content"
      #contentWrapper
      [class.with-bottom-bar]="showBottomFixedBar"
      [class.with-progress-indicator]="showProgressBar"
      [class.with-info-bar]="infoBarState.show">
      <router-outlet></router-outlet>

      <!-- Foreinger uses app-chat -->
      <ng-container *ngIf="isForeigner">
        <app-chat-desktop *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'"></app-chat-desktop>
        <app-chat-mobile-button *ngIf="deviceInfo.deviceTypeDetected !== 'DESKTOP'"></app-chat-mobile-button>
      </ng-container>

      <!-- Employees/admins use support-chat/private-convos -->
      <ng-container *ngIf="userShouldSeeChatsList">
        <app-chats-wrapper-desktop *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'"></app-chats-wrapper-desktop>
        <app-chats-wrapper-mobile *ngIf="deviceInfo.deviceTypeDetected !== 'DESKTOP'"></app-chats-wrapper-mobile>
      </ng-container>
    </div>
  </div>
</div>

<app-cookie-banner></app-cookie-banner>
