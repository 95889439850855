import { Injectable } from '@angular/core';
import { CreateAnnexOpts, GetAnnexesOpts, MOSSpeciality, RemoveAnnexOpts, UpdateAnnexOpts } from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import * as annexActions from './annex.actions';
import * as annexSelectors from './annex.selectors';
import { Observable, filter, tap } from 'rxjs';

@Injectable()
export class AnnexFacade {
  public annexesInUserProcess$ = this.store.select(annexSelectors.selectAnnexesInUserProcess);
  public loading$ = this.store.select(annexSelectors.selectLoading);
  public errorMessage$ = this.store.select(annexSelectors.selectErrorMessage);
  public specialities$ = this.store.select(annexSelectors.selectSpecialities);
  public loadingSpecialities$ = this.store.select(annexSelectors.selectLoadingSpecialities);

  public createAnnexSuccess$ = this.actions.pipe(ofType(annexActions.createAnnexSuccess));
  public updateAnnexSuccess$ = this.actions.pipe(ofType(annexActions.updateAnnexSuccess));
  public removeAnnexSuccess$ = this.actions.pipe(ofType(annexActions.removeAnnexSuccess));
  public removeAnnexError$ = this.actions.pipe(ofType(annexActions.removeAnnexError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getAnnexes(opts: GetAnnexesOpts): void {
    this.store.dispatch(annexActions.getAnnexes({ opts }));
  }

  public createAnnex(opts: CreateAnnexOpts): void {
    this.store.dispatch(annexActions.createAnnex({ opts }));
  }

  public updateAnnex(opts: UpdateAnnexOpts): void {
    this.store.dispatch(annexActions.updateAnnex({ opts }));
  }

  public removeAnnex(opts: RemoveAnnexOpts): void {
    this.store.dispatch(annexActions.removeAnnex({ opts }));
  }

  public getSpecialities(): void {
    this.store.dispatch(annexActions.getSpecialities());
  }

  public getSpecialities$(): Observable<MOSSpeciality[]> {
    return this.store.select(annexSelectors.selectSpecialities).pipe(
      tap(entities => {
        if (!entities?.length) {
          this.getSpecialities();
        }
      }),
      filter(entities => {
        if (!entities?.length) {
          return false;
        }
        return true;
      })
    );
  }
}
