import { P24_PAYMENT_METHOD, Product, Purchase } from '@interfaces';

export type PurchaseState = Readonly<{
  loadingAvailableProducts: boolean;
  loadingPurchase: boolean;

  creatingPurchaseInProgress: boolean;

  availableProducts: Product[];
  loadingPaymentMethods: boolean;
  availablePaymentMethods: P24_PAYMENT_METHOD[];
  myPurchase: Purchase | Partial<Purchase>;

  loadingPurchaseList: boolean;
  purchaseList: Purchase[];

  errorMessage: string | null;
}>;

export const initialState: PurchaseState = {
  loadingAvailableProducts: false,
  loadingPurchase: false,
  creatingPurchaseInProgress: false,

  availableProducts: [],
  loadingPaymentMethods: false,
  availablePaymentMethods: [],
  myPurchase: null,

  loadingPurchaseList: false,
  purchaseList: [],

  errorMessage: null,
};
