import { Component } from '@angular/core';

@Component({
  selector: 'app-account-deleted',
  templateUrl: './account-deleted.component.html',
  styleUrls: ['./account-deleted.component.scss'],
})
export class AccountDeletedComponent {
  constructor() {}
}
