import {
  FindSupportChatsOpts,
  Pagination,
  SupportChat,
  SupportChatInList,
  SupportChatMessage,
  SupportChatsOrder,
  UserAsset,
} from '@interfaces';

export type SupportChatState = Readonly<{
  // supportChatId: SupportChat;
  supportChats: { [key: string]: SupportChatInList };
  pagination: FindSupportChatsOpts;

  unreadCount: number;
  openSupportChat: {
    supportChat: SupportChat | SupportChatInList;
    messages: SupportChatMessage[];
    messageAttachmentsToAdd: {
      // file.name -> name on the computer of uploader -> we will make sure that no duplicates are allowed
      [key: string]: {
        error: any;
        loading: boolean;
        uploaded: boolean;
        userAsset: UserAsset;
      };
    };
  };
  loadingSupportChats: boolean;
  loadingMoreSupportChats: boolean;
  loadingOpenSupportChat: boolean;
  loadingSupportChatUpdate: string | null;
  sendingMessage: boolean;

  errorMessage: string;
}>;

export const defaultPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: SupportChatsOrder.LAST_MESSAGE_DATE,
  orderDir: 'asc',
};

export const initialState: SupportChatState = {
  supportChats: {},
  pagination: {
    ...(defaultPagination as Pagination),
    onlyAssignedToMe: true,
    orderBy: SupportChatsOrder.LAST_MESSAGE_DATE,
    supportChatId: null,
    supportedUserId: null,
    supportedUserName: null,
  },
  unreadCount: 0,
  openSupportChat: null,
  loadingOpenSupportChat: false,
  loadingSupportChats: false,
  loadingMoreSupportChats: false,
  loadingSupportChatUpdate: null,
  sendingMessage: false,
  errorMessage: null,
};
