import {
  GetAvailablePaymentMethodsOpts,
  NewPurchaseOpts,
  P24_PAYMENT_METHOD,
  Product,
  Purchase,
  ValidatePurchaseError,
  ValidatePurchaseOpts,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const getAvailableProducts = createAction('[My-Purchase] Get Available Products');
export const getAvailableProductsSuccess = createAction(
  '[My-Purchase] Get Available Products Success',
  props<{ products: Product[] }>()
);
export const getAvailableProductsError = createAction('[My-Purchase] Get Available Products Error');

export const validatePurchase = createAction(
  '[My-Purchase] Validate Purchase',
  props<{ opts: ValidatePurchaseOpts }>()
);
export const validatePurchaseSuccess = createAction(
  '[My-Purchase] Validate Purchase Success',
  props<{ purchase: Partial<Purchase> }>()
);
export const validatePurchaseError = createAction(
  '[My-Purchase] Validate Purchase Error',
  props<{ error: ValidatePurchaseError }>()
);

export const getAvailablePaymentMethods = createAction(
  '[My-Purchase] Get Available Payment Methods',
  props<{ opts: GetAvailablePaymentMethodsOpts }>()
);
export const getAvailablePaymentMethodsSuccess = createAction(
  '[My-Purchase] Get Available Payment Methods Success',
  props<{ paymentMethods: P24_PAYMENT_METHOD[] }>()
);
export const getAvailablePaymentMethodsError = createAction('[My-Purchase] Get Available Payment Methods Error');

export const newPurchase = createAction('[My-Purchase] New Purchase', props<{ opts: NewPurchaseOpts }>());
export const newPurchaseSuccess = createAction('[My-Purchase] New Purchase Success', props<{ purchase: Purchase }>());
export const newPurchaseError = createAction('[My-Purchase] New Purchase Error');

export const getMyPurchase = createAction('[My-Purchase] Get My Purchase', props<{ purchaseId: string }>());
export const getMyPurchaseSuccess = createAction(
  '[My-Purchase] Get My Purchase Success',
  props<{ purchase: Purchase }>()
);
export const getMyPurchaseError = createAction('[My-Purchase] Get My Purchase Error');

export const setPaymentStarted = createAction('[My-Purchase] Set Payment Started', props<{ purchaseId: string }>());
export const setPaymentStartedSuccess = createAction(
  '[My-Purchase] Set Payment Started Success',
  props<{ updatedPurchase: Partial<Purchase> }>()
);
export const setPaymentStartedError = createAction(
  '[My-Purchase] Set Payment Started Error',
  props<{ errorMessage: any }>()
);

export const verifyPayment = createAction(
  '[My-Purchase] Verify payment and process purchase',
  props<{ purchaseId: string }>()
);
export const verifyPaymentSuccess = createAction(
  '[My-Purchase] Verify payment and process purchase Success',
  props<{ updatedPurchase: Partial<Purchase> }>()
);
export const verifyPaymentError = createAction(
  '[My-Purchase] Verify payment and process purchase Error',
  props<{ errorMessage: any }>()
);

export const getMyPurchaseList = createAction('[My-Purchase] Get List');
export const getMyPurchaseListSuccess = createAction(
  '[My-Purchase] Get List Success',
  props<{ purchaseList: Purchase[] }>()
);
export const getMyPurchaseListError = createAction('[My-Purchase] Get List Error');

export const getPurchaseListOfUser = createAction(
  '[User-Purchase] Get Purchase list of user',
  props<{ buyerId: string }>()
);
export const getPurchaseListOfUserSuccess = createAction(
  '[User-Purchase] Get Purchase list of user Success',
  props<{ purchaseList: Purchase[] }>()
);
export const getPurchaseListOfUserError = createAction('[User-Purchase] Get Purchase list of user Error');
