import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioButton } from '@angular/material/radio';
import { AvailableLanguages } from '@constants';
import { TRAVEL_DOCUMENT_TYPE } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerHeaderComponent } from '@shared/datepicker-header/datepicker-header.component';
import { VoivodeshipsFacade } from '@state/voivodeships';
import * as moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { isBooleanValidator } from '@shared/custom-validators';

@Component({
  selector: 'app-perm-stay-step3',
  templateUrl: './perm-stay-step3.component.html',
  styleUrls: ['./perm-stay-step3.component.scss'],
})
export class PermStayStep3Component implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() mode: 'foreigner' | 'employee';
  @Input() currSpouseResidenceVoivodeshipId: string;
  @Input() currSpouseResidenceDistrictId: string;
  @Input() currSpouseResidenceMunicipalityId: string;
  @Input() maritalStatus: string;
  @Input() formGroup: FormGroup;
  @Output() firstInputShiftTabPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() lastFieldTabPressed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren('topRadioButtons') topRadioButtons: QueryList<MatRadioButton>;

  public controls: { [key: string]: FormControl } = {};
  public currentLang: AvailableLanguages;
  public selectsValues: { [key: string]: { label: string; value: string }[] };

  public minValidDate = moment().subtract(125, 'years');
  public maxValidDate = moment().subtract(1, 'day');
  public maxValidFutureDate = moment().subtract(1, 'day').add(30, 'years');
  public travelDocumentTypes = Object.values(TRAVEL_DOCUMENT_TYPE);

  public loadingDistricts$ = this.voivodeshipsFacade.loadingDistricts$;
  public loadingMunicips$ = this.voivodeshipsFacade.loadingMunicips$;
  public loadingCities$ = this.voivodeshipsFacade.loadingCities$;

  public voivodeships$ = this.voivodeshipsFacade.getVoivodeships$();

  public districts$ = this.voivodeshipsFacade.loadedDistricts$.pipe(
    // filter(({ forVoivoId }) => forVoivoId === this.controls?.residenceVoivodeshipDetailsId?.value),
    map(({ forVoivoId, districts }) =>
      forVoivoId === this.controls?.spouseResidenceVoivodeshipDetailsId?.value ? districts : []
    ),
    takeUntil(this.destroy$)
  );

  public municips$ = this.voivodeshipsFacade.loadedMunicips$.pipe(
    // filter(({ forDistrictId }) => forDistrictId === this.controls?.residenceDistrictId?.value),
    map(({ forDistrictId, municips }) =>
      forDistrictId === this.controls?.spouseResidenceDistrictId?.value ? municips : []
    ),
    takeUntil(this.destroy$)
  );

  public cities$ = this.voivodeshipsFacade.loadedCities$.pipe(
    // filter(({ forMunicipId }) => forMunicipId === this.controls?.residenceMunicipalityId?.value),
    map(({ forMunicipId, cities }) =>
      forMunicipId === this.controls?.spouserResidenceMunicipalityId?.value ? cities : []
    ),
    takeUntil(this.destroy$)
  );

  constructor(
    private readonly translateService: TranslateService,
    private readonly voivodeshipsFacade: VoivodeshipsFacade
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang as AvailableLanguages;
    this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
      this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;
    });

    Object.keys(this.formGroup.controls).forEach(fieldName => {
      this.controls[fieldName] = this.formGroup.controls[fieldName] as FormControl;
    });

    const relatedFields = [
      ['wasSentencedInPoland', 'sentenceReason'],
      ['currentlySubjectOfProceedings', 'proceedingsReason'],
      ['inGuardedCentre', 'inGuardedCentreComment'],
      ['inDetentionCentre', 'inDetentionCentreComment'],
      ['hasBanOnLeavingCountry', 'hasBanOnLeavingCountryComment'],
      ['hasSentenceOfImprisonment', 'hasSentenceOfImprisonmentComment'],
      ['temporarilyArrested', 'temporarilyArrestedComment'],
    ];

    relatedFields.forEach(([controlee, controlled]) => {
      if (this.controls[controlee].value === false) {
        this.controls[controlled].disable();
      }

      this.controls[controlee].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
        if (!val) {
          this.controls[controlled].disable();
        } else {
          this.controls[controlled].enable();
        }
      });
    });

    const bools = [
      'inGuardedCentre',
      'inDetentionCentre',
      'hasBanOnLeavingCountry',
      'hasSentenceOfImprisonment',
      'temporarilyArrested',
    ];
    this.controls.currentlyDetained.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      bools.forEach(fieldName => {
        if (val === true) {
          this.controls[fieldName].setValidators(Validators.compose([Validators.required, isBooleanValidator]));
        }
        if (!val) {
          this.controls[fieldName].setValue(null);
          this.controls[fieldName].setValidators(Validators.compose([isBooleanValidator]));
        }
        this.controls[fieldName].updateValueAndValidity();
      });
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.hasAnyFieldAnyValue(this.formGroup.controls)) {
        this.formGroup.markAllAsTouched();
      }
      this.handleMOSFields();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public firstInputKeydown($event: KeyboardEvent): void {
    if (!$event.shiftKey) {
      return;
    }

    if ($event.key?.toLowerCase() !== 'tab') {
      return;
    }

    this.firstInputShiftTabPressed.emit();
  }

  public emitLastFieldTabPressed($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.lastFieldTabPressed.emit();
  }

  // control.errors always contains max 1 error at a time
  // assumes that control.invalid === true and errors are {}
  public getErrorMessage(control: FormControl): string {
    const [validatorName, error]: [string, { errorKey: string; allowedVal: string }] = Object.entries(
      control.errors
    )[0];

    if (validatorName === 'required') {
      return this.translateService.instant(`FORM-VALIDATION.IS_REQUIRED`);
    }
    if (validatorName === 'pattern') {
      return this.translateService.instant(`FORM-VALIDATION.WRONG_FORMAT`);
    }
    if (validatorName === 'max') {
      return this.translateService.instant(`FORM-VALIDATION.VALUE_TOO_BIG`);
    }
    if (validatorName === 'min') {
      return this.translateService.instant(`FORM-VALIDATION.VALUE_TOO_SMALL`);
    }

    return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`, { allowedVal: error.allowedVal });
  }

  private handleMOSFields(): void {
    const voivoIdFC = this.controls.spouseResidenceVoivodeshipDetailsId;
    const districtFC = this.controls.spouseResidenceDistrictId;
    const municipFC = this.controls.spouserResidenceMunicipalityId;
    const cityListedFC = this.controls.spouseResidenceCityListedId;

    if (voivoIdFC.value) {
      this.voivodeshipsFacade.getDistricts({
        voivoId: voivoIdFC.value,
      });
    }
    if (districtFC.value) {
      this.voivodeshipsFacade.getMunicips({
        voivoId: voivoIdFC.value,
        districtId: districtFC.value,
      });
    }
    if (municipFC.value) {
      this.voivodeshipsFacade.getCities({
        voivoId: voivoIdFC.value,
        districtId: districtFC.value,
        municipId: municipFC.value,
      });
    }

    voivoIdFC.valueChanges
      .pipe(
        filter(voivoId => !!voivoId && voivoId !== this.currSpouseResidenceVoivodeshipId),
        takeUntil(this.destroy$)
      )
      .subscribe(currVoivoId => {
        districtFC.setValue(null);
        municipFC.setValue(null);
        cityListedFC.setValue(null);

        this.voivodeshipsFacade.getDistricts({ voivoId: currVoivoId });
      });

    districtFC.valueChanges
      .pipe(
        filter(districtId => !!districtId && districtId !== this.currSpouseResidenceDistrictId),
        takeUntil(this.destroy$)
      )
      .subscribe(currDistrictId => {
        municipFC.setValue(null);
        cityListedFC.setValue(null);

        this.voivodeshipsFacade.getMunicips({
          voivoId: voivoIdFC.value,
          districtId: currDistrictId,
        });
      });

    municipFC.valueChanges
      .pipe(
        filter(municipId => !!municipId && municipId !== this.currSpouseResidenceMunicipalityId),
        takeUntil(this.destroy$)
      )
      .subscribe(currMunicipId => {
        cityListedFC.setValue(null);

        this.voivodeshipsFacade.getCities({
          voivoId: voivoIdFC.value,
          districtId: districtFC.value,
          municipId: currMunicipId,
        });
      });
  }

  private hasAnyFieldAnyValue(controls: { [key: string]: AbstractControl }): boolean {
    return Object.keys(controls).some(controlName => {
      const val = controls[controlName].value;

      if (Array.isArray(val) && val.length > 0) {
        return true;
      }

      return val !== '' && val !== undefined && val !== null;
    });
  }

  protected readonly pickerHeader = DatepickerHeaderComponent;
}
