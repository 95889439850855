import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { CreateProductOpts, Product, ProductHistory, UpdateProductOpts } from 'src/app/_interfaces/product';

@Injectable({
  providedIn: 'root',
})
export class ProductsHttpService {
  private API_URL = environment.API_URL;
  private FULL_URL = `${this.API_URL}/products`;

  constructor(private http: HttpClient) {}

  public getProducts(): Observable<Product[]> {
    const url = `${this.FULL_URL}`;
    return this.http.get<Product[]>(url);
  }

  public getProductHistory(productId: string): Observable<ProductHistory[]> {
    const url = `${this.FULL_URL}/${productId}/changes-history`;
    return this.http.get<ProductHistory[]>(url);
  }

  public updateProduct(opts: UpdateProductOpts): Observable<Product> {
    const { productId, product } = opts;
    return this.http.patch<Product>(`${this.FULL_URL}/${productId}`, { ...product });
  }

  public createProduct(opts: CreateProductOpts): Observable<Product> {
    const { product } = opts;
    return this.http.post<Product>(`${this.FULL_URL}/new`, { ...product });
  }
}
