<form [formGroup]="formGroup" id="step6Form">
  <div class="step6-form-container">
    <div class="row">
      <div class="standard-form-field three-fourth-field rationaleOfTheApplication-field required-field">
        <label
          for="rationaleOfTheApplicationInput"
          [class.invalid]="controls.rationaleOfTheApplication.touched && controls.rationaleOfTheApplication.invalid">
          {{ 'PERSONAL_DATA_FORM.RATIONALE-OF-THE-APPLICATION.MAIN-LABEL' | translate }} *
        </label>

        <mat-form-field appearance="outline">
          <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="10"
            id="rationaleOfTheApplicationInput"
            type="text"
            minLength="1"
            maxLength="9999"
            (keydown)="firstInputKeydown($event)"
            [required]="true"
            placeholder="{{ 'PERSONAL_DATA_FORM.RATIONALE-OF-THE-APPLICATION.PLACEHOLDER' | translate }}"
            [formControl]="controls.rationaleOfTheApplication"></textarea>
          <mat-error *ngIf="controls.rationaleOfTheApplication.invalid">
            {{ 'PERSONAL_DATA_FORM.RATIONALE-OF-THE-APPLICATION.MAIN-LABEL' | translate }}
            {{ getErrorMessage(controls.rationaleOfTheApplication) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- <ng-container *ngIf="mode === 'employee'">
      <div class="row">
        <ng-container *ngIf="!userProcess?.personalDetails?.verified; else unVerifyButton">
          <button class="toggle-button toggle-button-inactive" (click)="toggleVerificationOfPersonalDetails.emit()">
            {{ 'PERSONAL_DATA_FORM.VERIFY_USER_DETAILS' | translate }}
          </button>
        </ng-container>
        <ng-template #unVerifyButton>
          <button class="toggle-button toggle-button-active" (click)="toggleVerificationOfPersonalDetails.emit()">
            {{ 'PERSONAL_DATA_FORM.UNVERIFY_USER_DETAILS' | translate }}
          </button>
        </ng-template>
      </div>
      <div class="row">
        <div class="supported-modes">
          <div class="supported-modes-label">{{ 'NT.USER_PROCESS_MODE' | translate }}:</div>
          <div class="gen-pills-container-form">
            <span
              *ngFor="let mode of UserProcessModes"
              class="pill {{ mode.toLowerCase() }}"
              (click)="setUserProcessMode(mode)"
              matTooltip="{{ 'NT.MODE_NOT_SUPPORTED_IN_SELECTED_VOIVODESHIP' | translate }}"
              matTooltipClass="universalTooltip"
              [matTooltipDisabled]="voivodeshipSupportedModes.includes(mode)"
              [class.enabled]="userProcess.mode === mode"
              [class.disallowed]="!voivodeshipSupportedModes.includes(mode)">
              {{ 'NT.USER_PROCESS_MODES.' + mode | translate }}
            </span>
          </div>
        </div>
      </div>
    </ng-container> -->
  </div>
</form>
