<div class="product-card" [class.highlighted]="product.isHighlighted" [class.selected]="isSelected">
  <nz-ribbon [nzText]="'PURCHASE.BEST_VALUE' | translate" *ngIf="product.isHighlighted" nzColor="green"></nz-ribbon>
  <div
    class="product-name"
    [class.long-name]="product.name[currLang]?.length >= 22 && product.name[currLang]?.length < 28"
    [class.extra-long-name]="product.name[currLang]?.length >= 28">
    {{ product.name[currLang] }}
  </div>
  <nz-divider></nz-divider>
  <div class="product-description" [innerHTML]="product.description ? product.description[currLang] : ''"></div>
  <span nz-icon nzType="check" nzTheme="outline" class="selected-product-icon" *ngIf="isSelected"></span>
  <div class="product-price">
    {{ product.basePrice / 100 }} zł
    <span *ngIf="priceSuffix?.length" class="product-price-suffix" [innerHTML]="priceSuffix"></span>
  </div>
</div>
