import { CalendarEvent, Pagination } from '@interfaces';

export interface SearchOfficeVisitsOpts {
  startDate: string;
  endDate: string;
  visitAssistantId?: string | null;
  voivodeshipsIds?: string[];
  onlyMyOfficeVisits?: boolean;
}

export interface GetVisitsListOpts extends Pagination {
  startDate: string;
  endDate: string;
  visitAssistantId?: string | null;
  showOnlyMyOfficeVisits: boolean;
  visitsType: CalendarEvent['eventType'];
  voivodeshipsIds?: string[];
}
