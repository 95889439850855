import { Injectable, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { get as lodashGet } from 'lodash-es';
import { CachedTranslations } from '@interfaces';
import { AvailableLanguages } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class TranslateUtilsService {
  constructor(private readonly translateService: TranslateService) {
    this.availableLangs = translateService.getLangs() as AvailableLanguages[];
  }

  private translationsObject: CachedTranslations;
  private availableLangs: AvailableLanguages[];

  public async saveAllTranslations(): Promise<any> {
    this.translationsObject = (
      await Promise.all(
        this.availableLangs.map(langKey => {
          return lastValueFrom(
            this.translateService.getTranslation(langKey).pipe(
              take(1),
              map(translations => ({ langKey, translations }))
            )
          );
        })
      )
    ).reduce((accumulator, currLangObj) => {
      accumulator[currLangObj.langKey] = currLangObj.translations;
      return accumulator;
    }, {} as any);
  }

  public getTranslations(key: string): { [key: string]: string } {
    const translationsObject: CachedTranslations = {} as CachedTranslations;

    this.availableLangs.forEach((language: AvailableLanguages) => {
      translationsObject[language] = lodashGet(this.translationsObject[language], key);
    });
    return translationsObject;
  }
}
