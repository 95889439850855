import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonsState } from './commons.state';

export const selectCommonsState = createFeatureSelector<CommonsState>('commons');

export const selectLastAPIPingSuccessful = createSelector(selectCommonsState, state => state.lastAPIPingSuccessful);
export const selectAPIPingInProgress = createSelector(selectCommonsState, state => state.APIPingInProgress);

export const selectAvailableProcesses = createSelector(selectCommonsState, state => state.availableProcesses);
export const selectAvailableProcessesByCategory = createSelector(
  selectCommonsState,
  state => state.availableProcessesByCategory
);

export const selectLoading = createSelector(selectCommonsState, state => state.loading);

export const selectErrorMessage = createSelector(selectCommonsState, state => state.errorMessage);

export const selectCountries = createSelector(selectCommonsState, state => state.availableCountries);
export const selectCountriesEU = createSelector(selectCommonsState, state => state.availableCountriesEU);

export const selectLanguages = createSelector(selectCommonsState, state => state.availableLanguages);

export const selectVisitAssistants = createSelector(selectCommonsState, state => state.visitAssistants);
export const selectLoadingVisitAssistants = createSelector(selectCommonsState, state => state.loadingVisitAssistants);
export const selectVisitAssistantsError = createSelector(selectCommonsState, state => state.visitAssistantsError);
