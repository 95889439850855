import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  AddNewUserProcessOpts,
  AssignProcessTypeOpts,
  ChangeUserDocStatusOpts,
  ChangeUserProcessStatusOpts,
  ChangeUserProcessTypeOpts,
  DeleteVisitDateOpts,
  DocumentComment,
  DocumentsGenerationStatus,
  EditUserProcess,
  EmailHTMLNotification,
  ListedUserProcess,
  PaginatedResponse,
  ProcessDocumentsList,
  SearchProcessesOfUser,
  SearchUsersProcessesPayload,
  SendToInpolRequestOpts,
  SendToMosRequestOpts,
  SetPersonalDetailsVerifiedPayload,
  SetVisitDateOpts,
  UpdateForeignerDocumentQuestionAnswerPayload,
  UpdatePersonalDetailsPayload,
  UpdateUserProcessServicesOpts,
  UserAsset,
  UserDocument,
  UserDocumentQuestionAnswer,
  UserProcessChange,
  UserProcessChangeType,
  ValidationConfig,
} from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManagementHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public searchUsersProcesses(payload: SearchUsersProcessesPayload): Observable<PaginatedResponse<ListedUserProcess>> {
    const url = `${this.API_URL}/management/user-processes/search`;
    return this.http.post<PaginatedResponse<ListedUserProcess>>(url, payload);
  }

  public searchProcessesOfUser(
    payloadWithUserId: SearchProcessesOfUser
  ): Observable<PaginatedResponse<ListedUserProcess>> {
    const { userId, ...payload } = payloadWithUserId;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/search-user-processes`;
    return this.http.post<PaginatedResponse<ListedUserProcess>>(url, payload);
  }

  public addNewUserProcess(payloadWithUserId: AddNewUserProcessOpts): Observable<ListedUserProcess> {
    const { userId, ...payload } = payloadWithUserId;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/new`;
    return this.http.post<ListedUserProcess>(url, payload);
  }

  public assignProcessType(opts: AssignProcessTypeOpts): Observable<ListedUserProcess> {
    const { userProcessId, foreignerId, ...payload } = opts;
    const url = `${this.API_URL}/management/user/${foreignerId}/user-processes/${userProcessId}/assign-process-type`;
    return this.http.patch<ListedUserProcess>(url, payload);
  }

  public getUserProcess({
    userId,
    userProcessId,
  }: {
    userId: string;
    userProcessId: string;
  }): Observable<ListedUserProcess> {
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}`;
    return this.http.get<ListedUserProcess>(url);
  }

  public removeUserProcess(userProcessId: string): Observable<void> {
    const url = `${this.API_URL}/management/user-process/${userProcessId}`;
    return this.http.delete<void>(url);
  }

  public restoreUserProcess(userProcessId: string): Observable<ListedUserProcess> {
    const url = `${this.API_URL}/management/user-process/${userProcessId}/restore`;
    return this.http.patch<ListedUserProcess>(url, {});
  }

  public updateUserProcessBasicData(
    userProcessId: string,
    payloadWithUserId: EditUserProcess
  ): Observable<ListedUserProcess> {
    const { userId, ...payload } = payloadWithUserId;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/update-basic-data`;
    return this.http.patch<ListedUserProcess>(url, payload);
  }

  public getPersonalDetailsValidationConfig(
    foreignerId: string,
    userProcessId: string
  ): Observable<{ members: ValidationConfig; all: ValidationConfig }> {
    const url = `${this.API_URL}/management/user/${foreignerId}/user-processes/${userProcessId}/get-personal-details-validation-config`;
    return this.http.get<{ members: ValidationConfig; all: ValidationConfig }>(url);
  }

  public updatePersonalDetailsInUserProcess(payload: UpdatePersonalDetailsPayload): Observable<ListedUserProcess> {
    const { personalDetails, userId, userProcessId } = payload;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/update-personal-details`;
    return this.http.patch<ListedUserProcess>(url, personalDetails);
  }

  public setPersonalDetailsVerification(payload: SetPersonalDetailsVerifiedPayload): Observable<ListedUserProcess> {
    const { userId, userProcessId, verified } = payload;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/set-personal-details-verified`;
    return this.http.patch<ListedUserProcess>(url, { verified });
  }
  public confirmVoivoChange(userId: string, userProcessId: string): Observable<boolean> {
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/confirm-voivo-change`;
    return this.http.get<boolean>(url);
  }

  public getUserProcessDocuments({
    userId,
    userProcessId,
  }: {
    userId: string;
    userProcessId: string;
  }): Observable<ProcessDocumentsList> {
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/processDocuments`;
    return this.http.get<ProcessDocumentsList>(url);
  }

  public setUserDocumentStatus(opts: ChangeUserDocStatusOpts): Observable<UserDocument> {
    const { foreignerId, status, userDocId, userProcessId } = opts;
    const url = `${this.API_URL}/management/user/${foreignerId}/user-processes/${userProcessId}/user-document/${userDocId}/status`;
    const body = { status };
    return this.http.put<UserDocument>(url, body);
  }

  public changeUserProcessType({
    userId,
    newUserProcessId,
    userProcessId,
  }: ChangeUserProcessTypeOpts): Observable<ListedUserProcess> {
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/processType`;
    return this.http.patch<ListedUserProcess>(url, { newUserProcessId });
  }

  public changeUserProcessStatus({
    userId,
    newUserProcessStatus,
    userProcessId,
  }: ChangeUserProcessStatusOpts): Observable<ListedUserProcess> {
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/processStatus`;
    return this.http.patch<ListedUserProcess>(url, { newUserProcessStatus });
  }

  public generateDocumentsNow(foreignerId: string, userProcessId: string): Observable<void> {
    const url = `${this.API_URL}/management/user/${foreignerId}/user-processes/${userProcessId}/generate-documents-now`;
    return this.http.get<void>(url);
  }

  public getDocumentsGenerationStatus(userId: string, userProcessId: string): Observable<DocumentsGenerationStatus> {
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/documents-generation-status`;
    return this.http.get<DocumentsGenerationStatus>(url);
  }

  public printAllDocumentsOfForeinger(userId: string, userProcessId: string): Observable<any> {
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/print-all-documents`;
    return this.http.get(url, { responseType: 'blob' });
  }

  public areDocsGenerated(userId: string, userProcessId: string): Observable<{ generated: boolean }> {
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/are-docs-generated`;
    return this.http.get<{ generated: boolean }>(url);
  }

  public getDocumentComments(userDocumentId: string, userProcessId: string): Observable<DocumentComment[]> {
    const url = `${this.API_URL}/document-comments/document/${userDocumentId}/userProcess/${userProcessId}`;
    return this.http.get<DocumentComment[]>(url);
  }

  public addDocumentComment(payload: DocumentComment, userProcessId: string): Observable<DocumentComment> {
    const url = `${this.API_URL}/document-comments/document/${payload.userDocumentId}/userProcess/${userProcessId}`;
    const body = { content: payload.content };
    return this.http.post<DocumentComment>(url, body);
  }

  public updateForeignerDocumentQuestionAnswer(
    payload: UpdateForeignerDocumentQuestionAnswerPayload
  ): Observable<UserDocumentQuestionAnswer> {
    const { userProcessId, userDocumentId, userDocumentAnswerId, answer } = payload;
    const url = `${this.API_URL}/user-documents/${userProcessId}/${userDocumentId}/${userDocumentAnswerId}`;
    const body = { answer };
    return this.http.patch<UserDocumentQuestionAnswer>(url, body);
  }

  public sendDataToInpol(opts: SendToInpolRequestOpts): Observable<any> {
    const { userId, userProcessId, withCredentials } = opts;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/send-to-inpol`;
    const body = withCredentials ? { withCredentials } : {};
    return this.http.post(url, body);
  }

  public sendDataToMos(opts: SendToMosRequestOpts): Observable<any> {
    const { userId, userProcessId, withCredentials } = opts;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/send-to-mos`;
    const body = withCredentials ? { withCredentials } : {};
    return this.http.post(url, body);
  }

  public setVisitDate(opts: SetVisitDateOpts): Observable<ListedUserProcess> {
    const { userId, userProcessId, ...body } = opts;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/set-visit-date`;

    return this.http.post<ListedUserProcess>(url, body);
  }

  public deleteVisitDate(opts: DeleteVisitDateOpts): Observable<ListedUserProcess> {
    const { userId, userProcessId, visitDate } = opts;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/delete-visit-date`;
    const body = { visitDate };
    return this.http.delete<ListedUserProcess>(url, { body });
  }

  public getUserProcessChanges(
    userProcessId: string,
    changeType?: UserProcessChangeType,
    changeAuthorId?: string
  ): Observable<UserProcessChange[]> {
    const url = `${this.API_URL}/uph/search`;
    const body = { userProcessId } as any;
    if (changeType) {
      body.changeType = changeType;
    }
    if (changeAuthorId) {
      body.changeAuthorId = changeAuthorId;
    }

    return this.http.post<UserProcessChange[]>(url, body);
  }

  public downloadErrorScreenshot(screenshotPath: string): Observable<any> {
    const url = `${this.API_URL}/uph/download/error-screenshot`;
    return this.http.post(url, { screenshotPath }, { responseType: 'blob' });
  }

  public downloadRecording(recordingPath: string): Observable<any> {
    const url = `${this.API_URL}/uph/download/recording`;
    return this.http.post(url, { recordingPath }, { responseType: 'blob' });
  }

  public getItemsSentForFinalReview(opts: { userProcessId: string }): Observable<UserAsset[]> {
    const url = `${this.API_URL}/user-assets/user-process/${opts.userProcessId}/documents-sent-to-review`;
    return this.http.get<UserAsset[]>(url);
  }

  public downloadItemSentForFinalReview(opts: { userProcessId: string; userAssetId: string }): Observable<any> {
    const url = `${this.API_URL}/user-assets/user-process/${opts.userProcessId}/document-sent-for-final-review/${opts.userAssetId}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  public updateDocumentsReviewComment(opts: {
    userProcessId: string;
    userId: string;
    comment: string;
  }): Observable<ListedUserProcess> {
    const { comment, userId, userProcessId } = opts;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/update-documents-review-comment`;
    const body = { documentsReviewComment: comment };
    return this.http.patch<ListedUserProcess>(url, body);
  }

  public updateIncludedServices(opts: UpdateUserProcessServicesOpts): Observable<ListedUserProcess> {
    const { userId, userProcessId, payload } = opts;
    const url = `${this.API_URL}/management/user/${userId}/user-processes/${userProcessId}/update-services`;
    return this.http.patch<ListedUserProcess>(url, payload);
  }

  public isAnyProcessOfMineMissingAssistant(): Observable<boolean> {
    const url = `${this.API_URL}/management/user-processes/is-any-of-mine-missing-assistant`;
    return this.http.get<boolean>(url);
  }

  public sendFollowUpEmail(userId: string, forcedMessageContent: EmailHTMLNotification): Observable<boolean> {
    const url = `${this.API_URL}/users/foreigner/${userId}/send-follow-up-email`;
    return this.http.post<boolean>(url, forcedMessageContent);
  }
}
