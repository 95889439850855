import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  GetAvailablePaymentMethodsOpts,
  NewPurchaseOpts,
  P24_GET_PAYMENT_METHODS_RESPONSE,
  Product,
  Purchase,
  ValidatePurchaseOpts,
} from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PurchaseHttpService {
  private API_URL = environment.API_URL;
  private MY_FULL_URL = `${this.API_URL}/my-purchase`;

  constructor(private http: HttpClient) {}

  public getAvailableProducts(): Observable<Product[]> {
    const url = `${this.MY_FULL_URL}/available-products`;
    return this.http.get<Product[]>(url);
  }

  public getAvailablePaymentMethods(
    opts: GetAvailablePaymentMethodsOpts
  ): Observable<P24_GET_PAYMENT_METHODS_RESPONSE['data']> {
    const url = `${this.MY_FULL_URL}/available-payment-methods`;
    return this.http.get<P24_GET_PAYMENT_METHODS_RESPONSE['data']>(url, { params: { ...opts } });
  }

  public validatePurchase(opts: ValidatePurchaseOpts): Observable<Partial<Purchase>> {
    const url = `${this.MY_FULL_URL}/validate`;
    return this.http.post<Partial<Purchase>>(url, opts);
  }

  public newPurchase(opts: NewPurchaseOpts): Observable<Purchase> {
    const url = `${this.MY_FULL_URL}/new`;
    return this.http.post<Purchase>(url, opts);
  }

  public getMyPurchase(purchaseId: string): Observable<Purchase> {
    const url = `${this.MY_FULL_URL}/${purchaseId}/status`;
    return this.http.get<Purchase>(url);
  }

  public setPaymentStarted(purchaeId: string): Observable<Partial<Purchase>> {
    const url = `${this.MY_FULL_URL}/${purchaeId}/payment-start`;
    return this.http.patch<Partial<Purchase>>(url, {});
  }

  public verifyPayment(purchaseId: string): Observable<Partial<Purchase>> {
    const url = `${this.MY_FULL_URL}/${purchaseId}/verify-payment`;
    return this.http.get<Partial<Purchase>>(url);
  }

  public getPurchaseList(): Observable<Purchase[]> {
    const url = `${this.MY_FULL_URL}/list`;
    return this.http.get<Purchase[]>(url);
  }

  public getPurchaseListOfUser(buyerId: string): Observable<Purchase[]> {
    const url = `${this.API_URL}/purchase/list-of/${buyerId}`;
    return this.http.get<Purchase[]>(url);
  }
}
