import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { UserAuthHttpService } from '@core/http/user-auth.http.service';

import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { UserAssetsHttpService } from '@core/http/user-assets.http.service';
import { LocalStorageService } from '@core/local-storage.service';
import { SessionTimeoutService } from '@core/session-timeout.service';
import { environment } from '@environment';
import { Language } from '@interfaces';
import { LayoutService } from '@layout/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingAnimationService } from '@shared/loading-animation/loading-animation.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { parseError } from '@state/errors.parser';
import { RouterFacade } from '@state/router';
import * as routerActions from '@state/router/router.actions';
import * as userAuthActions from './user-auth.actions';
import { AnalyticsService } from '@shared/analytics';

@Injectable()
export class UserAuthEffects {
  constructor(
    private actions$: Actions,
    private http: UserAuthHttpService,
    private userAssetsHttp: UserAssetsHttpService,
    private translateService: TranslateService,
    private routerFacade: RouterFacade,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly snackService: SnackbarService,
    private readonly sessionTimeoutService: SessionTimeoutService,
    private readonly ls: LocalStorageService,
    private readonly dialogRef: MatDialog,
    private readonly layoutService: LayoutService,
    private readonly loadingAnimationService: LoadingAnimationService,
    private readonly analyticsService: AnalyticsService
  ) {}

  activateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.activateAccount),
      switchMap(({ payload }) => {
        return this.http.activateAccount(payload).pipe(
          map(({ emailSent }) => {
            this.analyticsService.trackEvent('user_event', 'user_activated_account');
            return userAuthActions.activateAccountSuccess({ emailSent });
          }),
          catchError(error => {
            const errorMessage = parseError(error, userAuthActions.activateAccount.type);
            return of(userAuthActions.activateAccountError({ errorMessage }));
          })
        );
      })
    )
  );

  activateAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthActions.activateAccountSuccess),
        tap(({}) => {
          return this.routerFacade.changeRoute({
            linkParams: ['login'],
            extras: {
              queryParams: {
                message: 'ACTIVATE.ACCOUNT-ACTIVATED-YOU-CAN-NOW-LOGIN-USING-YOUR-PASSWORD',
              },
            },
          });
        })
      ),
    { dispatch: false }
  );

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthActions.login),
        switchMap(({ payload, redirect }) => {
          return this.http.loginRequest(payload).pipe(
            map(() => {
              this.loadingAnimationService.createDialog('loading-animation', environment.autAnimationTime || 5);
              this.analyticsService.trackEvent('user_event', 'user_logged_in');
              return userAuthActions.loginSuccess({ redirect });
            }),
            catchError(error => {
              const errorMessage = parseError(error, userAuthActions.login.type);
              return of(userAuthActions.loginError({ errorMessage }));
            })
          );
        })
      ),
    { dispatch: true }
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.loginSuccess),
      switchMap(({ redirect }) => {
        return of(userAuthActions.getMyself({ redirect }));
      })
    )
  );

  getMyself$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.getMyself),
      switchMap(({ redirect }) => {
        return this.http.getMyself().pipe(
          map(user => {
            const userLangInDb: Language = user.language;
            const userLangInLs = this.ls.getUserLang();
            const userLangToUse = userLangInLs ? userLangInLs : userLangInDb;
            this.translateService.use(userLangToUse.key);
            this.ls.setUserLang(userLangToUse);

            // IF User language stored in Local Storage (manipulated by utils buttons)
            // is different than the one in Database
            // then you have to update the one in database immediately at login!

            if (userLangToUse.key === 'pl') {
              this.dateAdapter.setLocale('pl-PL');
            }
            if (userLangToUse.key !== 'pl') {
              this.dateAdapter.setLocale('en-GB');
            }
            return userAuthActions.getMyselfSuccess({ user });
          }),
          catchError(error => {
            return of(userAuthActions.getMyselfError({ error, redirect }));
          })
        );
      })
    )
  );

  getMyselfError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.getMyselfError),
      map(({ redirect }) => {
        this.closeAllOnLogout();
        return routerActions.changeRoute({ linkParams: ['login'], extras: { queryParams: { redirect } } });
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.logout),
      switchMap(opts => {
        return this.http.logout().pipe(
          map(() => {
            this.analyticsService.trackEvent('user_event', 'user_logged_out');
            return userAuthActions.logoutSuccess(opts);
          }),
          catchError(error => of(userAuthActions.logoutError({ error })))
        );
      })
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.logoutSuccess),
      map(opts => {
        if (opts.wasUnauthorized) {
          this.snackService.showError('ERRORS.UNAUTHORIZED-PLEASE-LOGIN');
        }
        this.closeAllOnLogout();

        if (opts.sessionTimedOutLogout) {
          return routerActions.changeRoute({ linkParams: ['login'], extras: { queryParams: { safeLogout: true } } });
        }
        return routerActions.changeRoute({ linkParams: ['login'] });
      })
    )
  );

  logoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.logoutError),
      map(() => {
        this.closeAllOnLogout();
        return routerActions.changeRoute({ linkParams: ['login'] });
      })
    )
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.deleteAccount),
      switchMap(payload => {
        return this.http.deleteAccount(payload.password).pipe(
          map(({ success, message }) => {
            if (success === true) {
              this.closeAllOnLogout();
              this.analyticsService.trackEvent('user_event', 'user_deleted_account');
              return userAuthActions.deleteAccountSuccess();
            }
            return userAuthActions.deleteAccountError({ error: message });
          }),
          catchError(() => of(userAuthActions.deleteAccountError({ error: 'UNKNOWN_ERROR' })))
        );
      })
    )
  );

  deleteAccount$Success$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.deleteAccountSuccess),
      map(() => {
        this.closeAllOnLogout();
        return routerActions.changeRoute({ linkParams: ['account-deleted'] });
      })
    )
  );

  updateMyself$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.updateMyself),
      switchMap(({ payload }) => {
        return this.http.updateMyself(payload).pipe(
          map(updatedUser => userAuthActions.updateMyselfSuccess({ updatedUser })),
          catchError(error => of(userAuthActions.updateMyselfError({ error })))
        );
      })
    )
  );

  updateMyInvoiceData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.updateMyInvoiceData),
      switchMap(({ invoiceData }) => {
        return this.http.updateMyInvoiceData(invoiceData).pipe(
          map(invoiceData => userAuthActions.updateMyInvoiceDataSuccess({ invoiceData })),
          catchError(() => {
            this.snackService.showError('ERRORS.ERROR_UPDATING_INVOICE_DATA');
            return of(userAuthActions.updateMyInvoiceDataError());
          })
        );
      })
    )
  );

  updateAdminself$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.updateAdminself),
      switchMap(({ userId, payload }) => {
        return this.http.updateAdminself(userId, payload).pipe(
          map(updatedUser => userAuthActions.updateMyselfSuccess({ updatedUser })),
          catchError(error => of(userAuthActions.updateMyselfError({ error })))
        );
      })
    )
  );

  getPasswordResetLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.getPasswordResetLink),
      switchMap(({ email }) => {
        return this.http.getPasswordResetLink(email).pipe(
          map(({ emailResult }) => {
            if (emailResult === true) {
              this.analyticsService.trackEvent('user_event', 'user_sent_password_change_request');
              return userAuthActions.getPasswordResetLinkSuccess({ emailResult });
            }
            return userAuthActions.getPasswordResetLinkError({ errorMessage: 'ERROR_SENDING_RESET_PASSWORD_LINK' });
          }),
          catchError(error => {
            const errorMessage = parseError(error, userAuthActions.getPasswordResetLink.type);
            return of(userAuthActions.getPasswordResetLinkError({ errorMessage }));
          })
        );
      })
    )
  );

  setNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.setNewPassword),
      switchMap(({ payload }) => {
        return this.http.setNewPassword(payload).pipe(
          map(({ success }) => userAuthActions.setNewPasswordSuccess({ success })),
          catchError(error => {
            const errorMessage = parseError(error, userAuthActions.setNewPassword.type);
            return of(userAuthActions.setNewPasswordError({ errorMessage }));
          })
        );
      })
    )
  );

  regenerateInitialsAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.regenerateInitialsAvatar),
      switchMap(() => {
        return this.userAssetsHttp.regenerateInitialsAvatar().pipe(
          map(user => userAuthActions.regenerateInitialsAvatarSuccess({ user })),
          catchError(error => {
            const errorMessage = parseError(error, userAuthActions.regenerateInitialsAvatar.type);
            return of(userAuthActions.regenerateInitialsAvatarError({ errorMessage }));
          })
        );
      })
    )
  );

  private closeAllOnLogout(): void {
    this.sessionTimeoutService.stopIdleWatch();
    this.layoutService.closeInfoBar();
    this.dialogRef.closeAll();
  }
}
