import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserProcessHttpService } from '@core/http/user-process.http.service';
import { SaveUserDocumentPayload, UserProcessStatus } from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { parseError } from '@state/errors.parser';

import { TranslateService } from '@ngx-translate/core';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { GetFileService } from '@shared/get-file/get-file.service';
import { LoadingAnimationService } from '@shared/loading-animation/loading-animation.service';
import { RouterFacade } from '@state/router';
import * as routerActions from '@state/router/router.actions';
import * as userProcessActions from './user-process.actions';

@Injectable()
export class UserProcessEffects {
  constructor(
    private actions$: Actions,
    private http: UserProcessHttpService,
    private notifService: SnackbarService,
    private readonly routerFacade: RouterFacade,
    private readonly message: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly loadingAnimationService: LoadingAnimationService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly getFileService: GetFileService
  ) {}

  getMyProcesses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.getMyProcesses),
      switchMap(() => {
        return this.http.getMyProcesses().pipe(
          map(myProcesses => {
            return userProcessActions.getMyProcessesSuccess({ myProcesses });
          }),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.getMyProcesses.type);
            return of(userProcessActions.getMyProcessesError({ errorMessage }));
          })
        );
      })
    )
  );

  getMyProcessDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.getMyProcessDetails),
      switchMap(({ userProcessId }) => {
        return this.http.getMyProcessDetails(userProcessId).pipe(
          map(myProcess => userProcessActions.getMyProcessDetailsSuccess({ myProcess })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.getMyProcessDetails.type);
            this.routerFacade.changeRoute({ linkParams: ['/'] });
            return of(userProcessActions.getMyProcessDetailsError({ errorMessage }));
          })
        );
      })
    )
  );

  addNewUserProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.addNewUserProcess),
      switchMap(({ payload }) => {
        return this.http.addNewUserProcess(payload).pipe(
          map(addedUserProcess => userProcessActions.addNewUserProcessSuccess({ addedUserProcess })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.addNewUserProcess.type);
            return of(userProcessActions.addNewUserProcessError({ errorMessage }));
          })
        );
      })
    )
  );

  pickProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.pickProcess),
      switchMap(({ userProcessId, processId }) => {
        return this.http.pickProcess(userProcessId, processId).pipe(
          map(userProcess => {
            return userProcessActions.pickProcessSuccess({ userProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.pickProcess.type);
            return of(userProcessActions.pickProcessError({ errorMessage }));
          })
        );
      })
    )
  );

  updatePersonalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.updatePersonalDetails),
      switchMap(({ personalDetails, userProcessId }) => {
        return this.http.updatePersonalDetails(userProcessId, personalDetails).pipe(
          map(updatedUserProcess =>
            userProcessActions.updatePersonalDetailsSuccess({ updatedUserProcess, userProcessId })
          ),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.updatePersonalDetails.type);
            return of(userProcessActions.updatePersonalDetailsError({ errorMessage }));
          })
        );
      })
    )
  );

  updatePreSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.updatePreSurvey),
      switchMap(({ opts }) => {
        return this.http.updatePreSurvey(opts).pipe(
          map(preSurvey =>
            userProcessActions.updatePreSurveySuccess({
              preSurvey,
              userId: opts.userId,
              userProcessId: opts.userProcessId,
            })
          ),
          catchError(() => {
            return of(userProcessActions.updatePreSurveyError());
          })
        );
      })
    )
  );

  sendPreSurveyForVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.sendPreSurveyForVerification),
      switchMap(({ opts }) => {
        return this.http.sendPreSurveyForVerification(opts).pipe(
          map(userProcess => userProcessActions.sendPreSurveyForVerificationSuccess({ userProcess })),
          catchError(() => {
            return of(userProcessActions.sendPreSurveyForVerificationError());
          })
        );
      })
    )
  );

  confirmDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.confirmPersonalDetails),
      switchMap(({ userProcessId }) => {
        return this.http.confirmDetails(userProcessId).pipe(
          map(validationResult =>
            userProcessActions.confirmPersonalDetailsSuccess({ userProcessId, validationResult })
          ),
          catchError(() =>
            of(
              userProcessActions.confirmPersonalDetailsError({
                errorMessage: 'ERROR_CONFIRMING_PERSONAL_DETAILS_IN_USER_PROCESS',
              })
            )
          )
        );
      })
    )
  );

  startCompletingDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.startCompletingDocuments),
      switchMap(({ userProcessId }) => {
        return this.http.startCompletingDocuments(userProcessId).pipe(
          map(updatedUserProcess => userProcessActions.startCompletingDocumentsSuccess({ updatedUserProcess })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.startCompletingDocuments.type);
            return of(userProcessActions.startCompletingDocumentsError({ errorMessage }));
          })
        );
      })
    )
  );

  startCompletingDocumentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.startCompletingDocumentsSuccess),
      map(({ updatedUserProcess }) => {
        const { status, id: userProcessId } = updatedUserProcess;
        const linkParams = [`/user-process/${userProcessId}`];
        if (status !== UserProcessStatus.COMPLETING_DOCUMENTS) {
          this.notifService.showError('UNKNOWN_ERROR_WHILE_UPDATING_PROCESS_FROM_DETAILS');
          console.warn(
            'Something went wrong with assigning correct process&status. Returned userProcess: ',
            updatedUserProcess
          );
          linkParams.push(`user-details`);
        } else {
          linkParams.push(`documents`);
        }

        return routerActions.changeRoute({ linkParams });
      })
    )
  );

  sendAppToReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.sendAppToReview),
      switchMap(({ userProcessId }) => {
        return this.http.sendApplicationToReview(userProcessId).pipe(
          switchMap(updatedUserProcess => [
            userProcessActions.sendAppToReviewSuccess({ updatedUserProcess }),
            userProcessActions.getDocumentsList({ userProcessId: updatedUserProcess.id }),
          ]),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.sendAppToReview.type);
            return of(userProcessActions.sendAppToReviewError({ errorMessage }));
          })
        );
      })
    )
  );

  setReadinessForVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.setReadinessForVisit),
      switchMap(({ opts }) => {
        return this.http.setReadinessForVisit(opts).pipe(
          map(userProcess => {
            return userProcessActions.setReadinessForVisitSuccess({ userProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.setReadinessForVisit.type);
            return of(userProcessActions.setReadinessForVisitError({ errorMessage }));
          })
        );
      })
    )
  );

  getDocumentsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.getDocumentsList),
      switchMap(({ userProcessId }) => {
        let spinner: string;
        if (!this.loadingAnimationService.isModalOpened('loading-animation')) {
          spinner = this.message.loading(this.translateService.instant('PROCESS_DOCUMENT.LOADING_DOCUMENTS'), {
            nzDuration: 0,
          }).messageId;
        }
        return this.http.getDocumentsList(userProcessId).pipe(
          map(documentsList => {
            this.message.remove(spinner);
            return userProcessActions.getDocumentsListSuccess({ documentsList });
          }),
          catchError(error => {
            this.message.remove(spinner);
            this.notifService.showError('MISSING_PROCESS_DOCUMENTS');
            const errorMessage = parseError(error, userProcessActions.getDocumentsList.type);
            return of(userProcessActions.getDocumentsListError({ errorMessage }));
          })
        );
      })
    )
  );
  addFilesToMyDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.addFilesToMyDocument),
      switchMap(({ opts }) => {
        return this.http.addFilesToMyDocument(opts).pipe(
          map(userDocument => userProcessActions.addFilesToMyDocumentSuccess({ userDocument })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.addFilesToMyDocument.type);
            this.notifService.showError(errorMessage);
            return of(userProcessActions.addFilesToMyDocumentError());
          })
        );
      })
    )
  );

  answerToMyDocQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.answerToMyDocQuestion),
      switchMap(({ opts }) => {
        return this.http.answerToMyDocQuestion(opts).pipe(
          map(userDocument => userProcessActions.answerToMyDocQuestionSuccess({ userDocument })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.answerToMyDocQuestion.type);
            this.notifService.showError(errorMessage);
            return of(userProcessActions.answerToMyDocQuestionError());
          })
        );
      })
    )
  );

  saveUserDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.saveUserDocument),
      switchMap(({ saveDocumentPayload, userDocId }) => {
        let resultStream;
        if (!userDocId) {
          resultStream = this.http.addDocument(saveDocumentPayload);
        }
        if (userDocId) {
          resultStream = this.http.updateDocument(userDocId, saveDocumentPayload);
        }

        return resultStream.pipe(
          switchMap(userDocument => {
            const actionsToDispatch = [userProcessActions.saveUserDocumentSuccess({ userDocument })];

            // if no userDocId - user created completely new document (new step)
            // he's allowed to do that only with addtional documents
            if (!userDocId) {
              actionsToDispatch.push(userProcessActions.createNewAdditionalDocumentSuccess({ userDocument }) as any);
            }

            return actionsToDispatch;
          }),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.saveUserDocument.type);
            return of(userProcessActions.saveUserDocumentError({ errorMessage }));
          })
        );
      })
    )
  );

  downloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.downloadFile),
      switchMap(({ userProcessId, userDocumentId, filename }) => {
        return this.http.downloadFile(userProcessId, userDocumentId, filename).pipe(
          map(file => {
            const deviceInfo = this.deviceInfoService.getInfo();
            if (deviceInfo.deviceTypeDetected === 'DESKTOP') {
              const newWindow = window.open();

              newWindow.addEventListener('DOMContentLoaded', () => (newWindow.document.title = filename));
              newWindow.document.write(`
								<style>body {margin: 0 !important;}</style>
								<iframe src="${URL.createObjectURL(
                  file
                )}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen>
								</iframe>
              `);
              newWindow.document.title = filename;
              // window.open(window.URL.createObjectURL(file), '_blank');
              return userProcessActions.downloadFileSuccess();
            } else {
              const url = window.URL.createObjectURL(file);
              const anchor = document.createElement('a');
              anchor.download = filename;
              anchor.href = url;
              anchor.click();
              anchor.remove();

              return userProcessActions.downloadFileSuccess();
            }
          }),
          catchError(() => {
            // Show notifciation with error message
            // const errorMessage = parseError(error, userProcessActions.downloadFile.type);
            return of(userProcessActions.downloadFileError());
          })
        );
      })
    )
  );

  removeFileOfUserDoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.removeFileOfUserDoc),
      switchMap(({ userProcessId, userDocumentId, filename }) => {
        return this.http.removeFileOfUserDoc(userProcessId, userDocumentId, filename).pipe(
          map(({ updatedUserDoc }) => {
            return userProcessActions.removeFileOfUserDocSuccess({ updatedUserDoc });
          }),
          catchError(error => {
            // Show notifciation with error message
            this.notifService.showError('ERRORS.UNABLE_TO_DELETE_FILE');
            const errorMessage = parseError(error, userProcessActions.removeFileOfUserDoc.type);
            return of(userProcessActions.removeFileOfUserDocError({ errorMessage }));
          })
        );
      })
    )
  );

  removeUserDoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.removeUserDoc),
      switchMap(({ userProcessId, userDocumentId }) => {
        return this.http.removeDocument(userProcessId, userDocumentId).pipe(
          map(() => {
            return userProcessActions.removeUserDocSuccess({ removedUserDocId: userDocumentId });
          }),
          catchError(error => {
            // Show notifciation with error message
            const errorMessage = parseError(error, userProcessActions.removeUserDoc.type);
            return of(userProcessActions.removeUserDocError({ errorMessage }));
          })
        );
      })
    )
  );

  saveUserAdditionalDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.saveAdditionalDocs),
      switchMap(({ additionalDocs }) => {
        return forkJoin(
          additionalDocs.files.map(additionalDoc => {
            let stream;
            const saveDocumentPayload: SaveUserDocumentPayload = {
              userProcessId: additionalDocs.userProcessId,
              documentId: additionalDocs.documentId,
              comments: additionalDoc.comments ? additionalDoc.comments : '',
              files: additionalDoc.files,
              willNotUploadDocument: false,
            };

            if (additionalDoc.userDocId) {
              stream = this.http.updateDocument(additionalDoc.userDocId, saveDocumentPayload);
            } else {
              stream = this.http.addDocument(saveDocumentPayload);
            }

            return stream;
          })
        ).pipe(
          map(savedUserDocuments => userProcessActions.saveAdditionalDocsSuccess({ savedUserDocuments })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.saveAdditionalDocs.type);
            return of(userProcessActions.saveAdditionalDocsError({ errorMessage }));
          })
        );
      })
    )
  );

  getDocumentsForSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.getDocumentsForSummary),
      switchMap(({ userProcessId }) => {
        return this.http.getDocumentsForSummary(userProcessId).pipe(
          map(summaryDocuments => userProcessActions.getDocumentsForSummarySuccess({ summaryDocuments })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.getDocumentsForSummary.type);
            return of(userProcessActions.getDocumentsForSummaryError({ errorMessage }));
          })
        );
      })
    )
  );

  downloadForPrintAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.downloadForPrintAll),
      switchMap(({ userProcessId }) => {
        return this.http.downloadForPrintAll(userProcessId).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return userProcessActions.downloadForPrintAllSuccess();
            } else {
              return userProcessActions.downloadForPrintAllError();
            }
          }),
          catchError(() => {
            this.notifService.showError('NT3.ERROR_DOWNLOADING_ATTACHMENT');
            return of(userProcessActions.downloadForPrintAllError());
          })
        );
      })
    )
  );

  downloadInstructions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.downloadInstructions),
      switchMap(({ userProcessId }) => {
        return this.http.downloadInstructions(userProcessId).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return userProcessActions.downloadInstructionsSuccess();
            } else {
              return userProcessActions.downloadInstructionsError();
            }
          }),
          catchError(() => {
            this.notifService.showError('NT3.ERROR_DOWNLOADING_ATTACHMENT');
            return of(userProcessActions.downloadInstructionsError());
          })
        );
      })
    )
  );

  saveMyDocumentsSigningMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.saveDocumentsSigningMode),
      switchMap(({ userProcessId, opts }) => {
        return this.http.saveDocumentsSigningMode(userProcessId, opts).pipe(
          map(userProcess => userProcessActions.saveDocumentsSigningModeSuccess({ userProcess })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.saveDocumentsSigningMode.type);
            return of(userProcessActions.saveDocumentsSigningModeError({ userProcessId, errorMessage }));
          })
        );
      })
    )
  );

  saveMyAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.saveMyAvailability),
      switchMap(({ userProcessId, availability }) => {
        return this.http.saveMyAvailability(userProcessId, availability).pipe(
          map(({ updatedUserProcess, newUserProcessStatus }) =>
            userProcessActions.saveMyAvailabilitySuccess({
              userProcessId,
              newUserProcessStatus,
              updatedUserProcess,
            })
          ),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.saveMyAvailability.type);
            return of(userProcessActions.saveMyAvailabilityError({ userProcessId, errorMessage }));
          })
        );
      })
    )
  );

  confirmVoivoChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.confirmVoivoChange),
      switchMap(({ userProcessId }) => {
        return this.http.confirmVoivoChange(userProcessId).pipe(
          map(() => userProcessActions.confirmVoivoChangeSuccess({ userProcessId })),
          catchError(error => {
            const errorMessage = parseError(error, userProcessActions.confirmVoivoChange.type);
            this.notifService.showWarning('UNABLE_TO_CONFIRM_VOIVO_CHANGE');
            return of(userProcessActions.confirmVoivoChangeError({ userProcessId, errorMessage }));
          })
        );
      })
    )
  );

  setAppointmentDateAndPlace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessActions.setAppointmentDateAndPlace),
      switchMap(({ opts }) => {
        return this.http.setAppointmentDateAndPlace(opts).pipe(
          map(userProcess =>
            userProcessActions.setAppointmentDateAndPlaceSuccess({
              userProcess,
            })
          ),
          catchError(error => {
            const errorMssg = this.translateService.instant('APPOINTMENT_INSTRUCTIONS.SAVING_ERROR');
            this.message.error(errorMssg, { nzDuration: 4000 });
            return of(userProcessActions.setAppointmentDateAndPlaceError());
          })
        );
      })
    )
  );
}
