import Step from 'node_modules/shepherd.js/src/types/step';

import { TranslateService } from '@ngx-translate/core';

interface AvailableButtons {
  counter: Step.StepOptionsButton;
  next: Step.StepOptionsButton;
  back: Step.StepOptionsButton;
  cancel: Step.StepOptionsButton;
  done: Step.StepOptionsButton;
}

const gPrefix = 'GUIDE';
const prefix = `${gPrefix}.SUMMARY-VISIT-DATE-PICKER`;

export const getSteps = (t: TranslateService, currLang: string): Step.StepOptions[] => {
  const buttons: AvailableButtons = {
    counter: { text: '', action(): void {}, classes: 'counter-button' },
    next: {
      text: t.instant(`${gPrefix}.NEXT`),
      action(): void {
        this.next();
      },
      classes: 'standard-next-button',
    },
    back: {
      text: t.instant(`${gPrefix}.BACK`),
      action(): void {
        this.back();
      },
      classes: 'standard-back-button',
    },
    cancel: {
      text: t.instant(`${gPrefix}.CLOSE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-cancel-button',
    },
    done: {
      text: t.instant(`${gPrefix}.DONE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-done-button',
    },
  };

  const steps: Step.StepOptions[] = [
    {
      id: 'officevisitexplanation',
      arrow: false,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      // attachTo: { element: '.noPreviousVisits-field', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.officevisitexplanation.TITLE`),
      text: `
      ${t.instant(`${prefix}.officevisitexplanation.TEXT`)}
      <br>
      ${t.instant(`${prefix}.officevisitexplanation.TEXT_2`)}
      <br><br>
      ${t.instant(`${prefix}.officevisitexplanation.TEXT_3`)}
      <br>

      <br>
      <sub>${t.instant(`${prefix}.officevisitexplanation.TEXT_4`)}</sub>
      <br>
      <sub>${t.instant(`${prefix}.officevisitexplanation.TEXT_5`)}</sub>
      <br>
      `,
    },
    {
      id: 'modeselectorexplanation',
      arrow: true,
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 6,
      attachTo: { element: '.mode-selector-field .availability-mode-radio-group', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.modeselectorexplanation.TITLE`),
      text: `
      ${t.instant(`${prefix}.modeselectorexplanation.TEXT`)}
      <br><br>
      ${t.instant(`${prefix}.modeselectorexplanation.TEXT_2`)}
      <br><br>
      ${t.instant(`${prefix}.modeselectorexplanation.TEXT_3`)}
      <br>
      `,
    },
    {
      id: 'mode1',
      arrow: true,
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 6,
      attachTo: { element: '.dates-enter-field', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.mode1.TITLE`),
      text: `
      ${t.instant(`${prefix}.mode1.TEXT`)}
      <br>
      <div class='gif-wrapper bigger-gif'>
        <img src='https://storage.googleapis.com/public-bridge-assets/visit-date-selector-opt1.gif'
        alt='gif showing how to select range of dates for office visit'>
      </div>
      `,
    },
    {
      id: 'mode2',
      arrow: true,
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 6,
      attachTo: { element: '.dates-enter-field', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.mode2.TITLE`),
      text: `
      ${t.instant(`${prefix}.mode2.TEXT`)}
      <br>
      <div class='gif-wrapper bigger-gif'>
        <img src='https://storage.googleapis.com/public-bridge-assets/visit-date-selector-opt2.gif'
        alt='gif showing how to select particular dates for office visit'>
      </div>
      `,
    },
    // {
    //   id: 'mode3',
    //   arrow: true,
    //   modalOverlayOpeningPadding: 6,
    //   modalOverlayOpeningRadius: 6,
    //   attachTo: { element: '.dates-enter-field', on: 'top' },
    //   cancelIcon: { enabled: true },
    //   highlightClass: 'highlight',
    //   scrollTo: false,
    //   title: t.instant(`${prefix}.mode3.TITLE`),
    //   text: `
    //   ${t.instant(`${prefix}.mode3.TEXT`)}
    //   <br><br>
    //   ${t.instant(`${prefix}.mode3.TEXT_2`)}
    //   <br>
    //   <div class='gif-wrapper bigger-gif'>
    //     <img src='https://storage.googleapis.com/public-bridge-assets/visit-date-selector-opt3.gif'
    //     alt='gif showing what to do when sending application with post'>
    //   </div>
    //   `
    // },
    {
      id: 'aftermodeselect',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 6,
      attachTo: { element: '.availability-save-button', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.aftermodeselect.TITLE`),
      text: `
      ${t.instant(`${prefix}.aftermodeselect.TEXT`)}
      <br><br>
      ${t.instant(`${prefix}.aftermodeselect.TEXT_2`)}
      <br><br>
      `,
    },
  ];

  const guideName = t.instant(`${prefix}.GUIDE_NAME`);
  return steps.map((step, currIndex, all) => {
    const formattedButtons: Step.StepOptionsButton[] = [
      { ...buttons.counter, text: `${guideName} ${currIndex + 1}/${all.length}` },
    ];

    if (currIndex === 0) {
      formattedButtons.push({ ...buttons.cancel });
    } else {
      formattedButtons.push({ ...buttons.back });
    }

    if (currIndex < all.length - 1) {
      formattedButtons.push({ ...buttons.next });
    } else {
      formattedButtons.push({ ...buttons.done });
    }

    return { ...step, buttons: formattedButtons };
  });
};
