<nz-tabset
  *ngIf="
    annexes?.length &&
    (countries$ | async)?.length &&
    (voivodeships$ | async)?.length &&
    (specialities$ | async)?.length
  "
  [(nzSelectedIndex)]="selectedAnnexIndex"
  nzType="editable-card"
  class="additional-class"
  (nzAdd)="createAnnex()">
  <ng-container *ngFor="let annex of annexes; index as annexIndex">
    <ng-template #tabTitle>
      {{ tabNameByAnnexId[annex.id] || '' }}
    </ng-template>

    <ng-template #deleteEntity>
      <span (click)="removeAnnex(annex)" nz-icon nzType="delete" nzTheme="outline"> </span>
    </ng-template>

    <nz-tab [nzTitle]="tabTitle" nzClosable [nzCloseIcon]="deleteEntity">
      <app-annex-form
        [userProcess]="userProcess"
        [annex]="annex"
        (annexChanged)="annexChanged($event)"
        [voivodeships]="voivodeships$ | async"
        [countries]="countries$ | async"
        [specialities]="specialities$ | async">
      </app-annex-form>
    </nz-tab>
  </ng-container>
</nz-tabset>

<nz-empty
  *ngIf="(loading$ | async) === false && annexes?.length === 0"
  nzNotFoundImage="simple"
  [nzNotFoundContent]="contentTpl"
  [nzNotFoundFooter]="footerTpl">
  <ng-template #contentTpl>
    <span>{{ 'NT2.NO_ENTITIES_ADDED_IN_ANNEX1' | translate }}</span>
  </ng-template>
  <ng-template #footerTpl>
    <button nz-button nzType="primary" (click)="createAnnex()">
      {{ 'NT2.CREATE_NEW_ENTITY' | translate }}
    </button>
  </ng-template>
</nz-empty>

<div
  class="loading-wrapper"
  *ngIf="(loading$ | async) || (loadingV$ | async) || (loadingC$ | async) || (loadingS$ | async)">
  <mat-spinner [diameter]="50" id="updateIndicatorLoading"></mat-spinner>
</div>
