import { AvailableLanguages } from '@constants';
import { UserDocument } from './document';
import { ChangeRoutePayload } from './router';
import { User } from './user-auth';
import { UserProcess } from './user-process';

export enum InAppNotificationType {
  // NOTIFICATIONS that will be sent to EMPLOYEE ----------------------------
  FOREIGNER_SENT_PRE_SURVEY_FOR_VERIFICATION = 'FOREIGNER_SENT_PRE_SURVEY_FOR_VERIFICATION',
  FOREIGNER_SENT_APPLICATION_TO_VERIFICATION = 'FOREIGNER_SENT_APPLICATION_TO_VERIFICATION',
  FOREIGNER_ADDED_DOCUMENT_COMMENT = 'FOREIGNER_ADDED_DOCUMENT_COMMENT',
  FOREIGNER_SET_AVAILABILITY = 'FOREIGNER_SET_AVAILABILITY',
  DOCUMENTS_GENERATION_SUCCESS = 'DOCUMENTS_GENERATION_SUCCESS',
  DOCUMENTS_SENT_TO_EXTERNAL_SYSTEM_AND_GENERATED = 'DOCUMENTS_SENT_TO_EXTERNAL_SYSTEM_AND_GENERATED',
  DOCUMENTS_GENERATION_ERROR = 'DOCUMENTS_GENERATION_ERROR',
  INPOL_SENDING_SUCCESS = 'INPOL_SENDING_SUCCESS',
  INPOL_SENDING_ERROR = 'INPOL_SENDING_ERROR',

  MOS_SENDING_SUCCESS = 'MOS_SENDING_SUCCESS',
  MOS_SENDING_ERROR = 'MOS_SENDING_ERROR',
  FOREIGNER_HAS_BEEN_ASSIGNED_TO_YOU = 'FOREIGNER_HAS_BEEN_ASSIGNED_TO_YOU',
  FOREIGNER_SENT_MESSAGE_IN_CHAT = 'FOREIGNER_SENT_MESSAGE_IN_CHAT',
  FOREIGNER_CHANGED_PERSONAL_DETAILS = 'FOREIGNER_CHANGED_PERSONAL_DETAILS',
  FOREIGNER_SENDS_APPLICATION_WITH_POST = 'FOREIGNER_SENDS_APPLICATION_WITH_POST',
  FOREIGNER_APPLICATION_MODE_HAS_CHANGED = 'FOREIGNER_APPLICATION_MODE_HAS_CHANGED',
  FOREIGNER_PICKED_SIGNING_MODE = 'FOREIGNER_PICKED_SIGNING_MODE',
  FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_MODE = 'FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_MODE',
  FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_DATE = 'FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_DATE',
  FOREIGNER_SET_IMMIGRATION_OFFICE_VISIT_DATE = 'FOREIGNER_SET_IMMIGRATION_OFFICE_VISIT_DATE',
  FOREIGNER_CHANGED_IMMIGRATION_OFFICE_VISIT_DATE = 'FOREIGNER_CHANGED_IMMIGRATION_OFFICE_VISIT_DATE',

  // Notification displayed all employees assigned to the office
  FOREIGNER_PLANNING_TO_COME_TO_THE_OFFICE = 'FOREIGNER_PLANNING_TO_COME_TO_THE_OFFICE',
  FOREIGNER_CHANGED_DATE_OF_COMPANY_OFFICE_VISIT = 'FOREIGNER_CHANGED_DATE_OF_COMPANY_OFFICE_VISIT',
  FOREIGNER_CANCELLED_VISIT_IN_COMPANY_OFFICE = 'FOREIGNER_CANCELLED_VISIT_IN_COMPANY_OFFICE',

  FOREIGNER_CONFIRMED_READINESS_FOR_VISIT = 'FOREIGNER_CONFIRMED_READINESS_FOR_VISIT',
  FOREIGNER_CANCELLED_READINESS_FOR_VISIT = 'FOREIGNER_CANCELLED_READINESS_FOR_VISIT',
  FOREIGNER_ADDED_ITEMS_TO_REVIEW = 'FOREIGNER_ADDED_ITEMS_TO_REVIEW',

  // Notification displayed to single employee
  FOREIGNER_PLANNING_TO_COME_TO_OTHER_OFFICE = 'FOREIGNER_PLANNING_TO_COME_TO_OTHER_OFFICE',
  FOREIGNER_CHANGED_DATE_OF_OTHER_COMPANY_OFFICE_VISIT = 'FOREIGNER_CHANGED_DATE_OF_OTHER_COMPANY_OFFICE_VISIT',
  FOREIGNER_CANCELLED_VISIT_IN_OTHER_COMPANY_OFFICE = 'FOREIGNER_CANCELLED_VISIT_IN_OTHER_COMPANY_OFFICE',

  // NOTIFICATIONS that will be sent to FOREIGNER ----------------------------
  // EMPLOYEE_CHANGED_DOCUMENT_STATUS = 'EMPLOYEE_CHANGED_DOCUMENT_STATUS',
  EMPLOYEE_ADDED_DOCUMENT_COMMENT = 'EMPLOYEE_ADDED_DOCUMENT_COMMENT',
  APPLICATION_STATUS_CHANGED = 'APPLICATION_STATUS_CHANGED',
  APPLICATION_STATUS_CHANGED_WITH_TYPE = 'APPLICATION_STATUS_CHANGED_WITH_TYPE',
  DOCUMENTS_LIST_CHANGED = 'DOCUMENTS_LIST_CHANGED',
  EMPLOYEE_SENT_MESSAGE_IN_CHAT = 'EMPLOYEE_SENT_MESSAGE_IN_CHAT',
  EMPLOYEE_CANCELLED_OFFICE_APPOINTMENT = 'EMPLOYEE_CANCELLED_OFFICE_APPOINTMENT',
  APPLICATION_MODE_CHANGED = 'APPLICATION_MODE_CHANGED',
  NEW_FINAL_VERIFICATION_COMMENTS = 'NEW_FINAL_VERIFICATION_COMMENTS',
  CHANGED_FINAL_VERIFICATION_COMMENTS = 'CHANGED_FINAL_VERIFICATION_COMMENTS',
  REASSIGNED_FOREIGNERS_TO_YOU = 'REASSIGNED_FOREIGNERS_TO_YOU',
  NEW_APPLICATION_HAS_BEEN_ADDED = 'NEW_APPLICATION_HAS_BEEN_ADDED',
  NEW_APPLICATION_HAS_BEEN_ADDED_WITH_TYPE = 'NEW_APPLICATION_HAS_BEEN_ADDED_WITH_TYPE',
  APPLICATION_HAS_BEEN_DELETED = 'APPLICATION_HAS_BEEN_DELETED',
}

export interface InAppNotificationData {
  causedBy: string | null; // name+surname or email of user causing the change (author) or null if system
  additionalInfo: { [key: string]: any }; // any additional data specific to particular notification type
}

export interface InAppNotification {
  id: string;
  read: boolean;
  readDate: string;
  type: InAppNotificationType;
  data: InAppNotificationData;
  recipientId: string;
  recipient: User;
  relatedForeignerId: string;
  relatedForeigner: User;
  relatedUserProcessId: string | null;
  relatedUserProcess?: UserProcess | null;
  relatedUserDocumentId: string | null;
  relatedUserDocument?: UserDocument | null;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;

  stacked?: boolean;
  stackSize?: number;
  stackedIds?: string[];
  clickable?: boolean;
  foreignerName?: string;
  documentName?: string;
  origError?: string;
  linkParams?: ChangeRoutePayload | null;
}

export interface MarkAllOfTypeAsReadOpts {
  inAppNotificationType: InAppNotificationType;
  relatedUserDocumentId?: string;
  relatedUserProcessId?: string;
  // only when employee
  relatedForeignerId?: string;
}

export interface MarkAllOfTypeAsReadBulkOpts {
  inAppNotificationTypes: InAppNotificationType[];
  relatedUserDocumentId?: string;
  relatedUserProcessId?: string;
  // only when employee
  relatedForeignerId?: string;
}

export interface MaintenanceInfo {
  maintenanceUpcoming: boolean;
  message: { [key in AvailableLanguages]: string };
}

export interface EmailHTMLNotification {
  subject: string;
  body_as_text: string;
  body_as_html: EmailHTMLBody;
}

export interface EmailHTMLBody {
  title?: string;
  subtitle?: string;
  cta_text?: string;
  cta_button_text?: string;
  cta_button_link?: string;
  cta_button_not_working_text?: string;
  sub?: string;

  extra_info?: string;
  office_visit_scheduled_to?: string;
  office_located_at?: string;
  subtitle2?: string;

  assistant_assigned_no_info?: string;
  assistant_assigned_contact_us?: string;
  assistant_assigned_person_info?: string;
}
