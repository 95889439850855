import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioButton } from '@angular/material/radio';
import { AvailableLanguages } from '@constants';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerHeaderComponent } from '@shared/datepicker-header/datepicker-header.component';
import * as moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isBooleanValidator } from '@shared/custom-validators';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-long-resi-step3',
  templateUrl: './long-resi-step3.component.html',
  styleUrls: ['./long-resi-step3.component.scss'],
})
export class LongResiStep3Component implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() mode: 'foreigner' | 'employee';
  @Input() formGroup: FormGroup;
  @Output() firstInputShiftTabPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() lastFieldTabPressed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren('topRadioButtons') topRadioButtons: QueryList<MatRadioButton>;

  public controls: { [key: string]: FormControl } = {};
  public currentLang: AvailableLanguages;
  public selectsValues: { [key: string]: { label: string; value: string }[] };

  public minValidDate = moment().subtract(125, 'years');
  public maxValidDate = moment().subtract(1, 'day');

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang as AvailableLanguages;
    this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
      this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;
    });

    Object.keys(this.formGroup.controls).forEach(fieldName => {
      this.controls[fieldName] = this.formGroup.controls[fieldName] as FormControl;
    });

    const relatedFields = [
      ['wasSentencedInPoland', 'sentenceReason'],
      ['currentlySubjectOfProceedings', 'proceedingsReason'],
      ['inGuardedCentre', 'inGuardedCentreComment'],
      ['inDetentionCentre', 'inDetentionCentreComment'],
      ['hasBanOnLeavingCountry', 'hasBanOnLeavingCountryComment'],
      ['hasSentenceOfImprisonment', 'hasSentenceOfImprisonmentComment'],
      ['temporarilyArrested', 'temporarilyArrestedComment'],
    ];

    relatedFields.forEach(([controlee, controlled]) => {
      if (this.controls[controlee].value === false) {
        this.controls[controlled].disable();
      }

      this.controls[controlee].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
        if (!val) {
          this.controls[controlled].disable();
        } else {
          this.controls[controlled].enable();
        }
      });
    });

    const bools = [
      'inGuardedCentre',
      'inDetentionCentre',
      'hasBanOnLeavingCountry',
      'hasSentenceOfImprisonment',
      'temporarilyArrested',
    ];
    this.controls.currentlyDetained.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      bools.forEach(fieldName => {
        if (val === true) {
          this.controls[fieldName].setValidators(Validators.compose([Validators.required, isBooleanValidator]));
        }
        if (!val) {
          this.controls[fieldName].setValue(null);
          this.controls[fieldName].setValidators(Validators.compose([isBooleanValidator]));
        }
        this.controls[fieldName].updateValueAndValidity();
      });
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.hasAnyFieldAnyValue(this.formGroup.controls)) {
        this.formGroup.markAllAsTouched();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public firstInputKeydown($event: KeyboardEvent): void {
    if (!$event.shiftKey) {
      return;
    }

    if ($event.key?.toLowerCase() !== 'tab') {
      return;
    }

    this.firstInputShiftTabPressed.emit();
  }

  public emitLastFieldTabPressed($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.lastFieldTabPressed.emit();
  }

  // control.errors always contains max 1 error at a time
  // assumes that control.invalid === true and errors are {}
  public getErrorMessage(control: FormControl): string {
    const [validatorName, error]: [string, { errorKey: string; allowedVal: string }] = Object.entries(
      control.errors
    )[0];

    if (validatorName === 'required') {
      return this.translateService.instant(`FORM-VALIDATION.IS_REQUIRED`);
    }
    if (validatorName === 'pattern') {
      return this.translateService.instant(`FORM-VALIDATION.WRONG_FORMAT`);
    }
    if (validatorName === 'max') {
      return this.translateService.instant(`FORM-VALIDATION.VALUE_TOO_BIG`);
    }
    if (validatorName === 'min') {
      return this.translateService.instant(`FORM-VALIDATION.VALUE_TOO_SMALL`);
    }

    return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`, { allowedVal: error.allowedVal });
  }

  private hasAnyFieldAnyValue(controls: { [key: string]: AbstractControl }): boolean {
    return Object.keys(controls).some(controlName => {
      const val = controls[controlName].value;

      if (Array.isArray(val) && val.length > 0) {
        return true;
      }

      return val !== '' && val !== undefined && val !== null;
    });
  }

  protected readonly pickerHeader = DatepickerHeaderComponent;
}
