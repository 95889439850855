import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from './company.state';

export const selectCompanyState = createFeatureSelector<CompanyState>('company');

export const selectCompanies = createSelector(selectCompanyState, state => state.companies);

export const selectPagination = createSelector(selectCompanyState, state => state.pagination);

export const selectCompany = createSelector(selectCompanyState, state => state.loadedCompany);

export const selectLoading = createSelector(selectCompanyState, state => state.loading);

export const selectErrorMessage = createSelector(selectCompanyState, state => state.errorMessage);

export const selectEmployeesProcesses = createSelector(selectCompanyState, state => state.employeesProcesses);

export const selectEmployeesProcessesPagination = createSelector(
  selectCompanyState,
  state => state.employeesProcessesPagination
);
