import { Injectable } from '@angular/core';
import { GetVisitsListOpts, SearchOfficeVisitsOpts } from '@interfaces';
import { Actions } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import * as visitsActions from './visits-calendar.actions';
import * as visitsSelectors from './visits-calendar.selectors';

@Injectable()
export class VisitsCalendarFacade {
  public visits$ = this.store.select(visitsSelectors.selectVisits);
  public goToDateVisits$ = this.store.select(visitsSelectors.selectGoToDateVisits);
  public loading$ = this.store.select(visitsSelectors.selectLoading);
  public errorMessage$ = this.store.select(visitsSelectors.selectErrorMessage);

  public visitsList$ = this.store.select(visitsSelectors.selectVisitsList);
  public visitsListPagination$ = this.store.select(visitsSelectors.selectVisitsListPagination);

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public searchVisits(opts: SearchOfficeVisitsOpts): void {
    this.store.dispatch(visitsActions.searchVisits({ opts }));
  }

  public searchGotoDateVisits(opts: SearchOfficeVisitsOpts): void {
    this.store.dispatch(visitsActions.searchGotoDateVisits({ opts }));
  }

  public getVisitsListBetweenDates(opts: GetVisitsListOpts): void {
    this.store.dispatch(visitsActions.getVisitsList({ opts }));
  }
}
