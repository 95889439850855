import { MultipleFilesUploadResult, RemoveAssetOpts, UploadPreSurveyAttachmentOpts } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const downloadMyAsset = createAction('[User-Assets] Download My Asset', props<{ userAssetId: string }>());
export const downloadMyAssetSuccess = createAction(
  '[User-Assets] Download My Asset Success',
  props<{ userAssetId: string }>()
);
export const downloadMyAssetError = createAction(
  '[User-Assets] Download My Asset Error',
  props<{ userAssetId: string }>()
);

export const removeMyAsset = createAction('[User-Assets] Remove My Asset', props<{ opts: RemoveAssetOpts }>());
export const removeMyAssetSuccess = createAction(
  '[User-Assets] Remove My Asset Success',
  props<{ opts: RemoveAssetOpts }>()
);
export const removeMyAssetError = createAction(
  '[User-Assets] Remove My Asset Error',
  props<{ errorRemovingAssetId: string }>()
);

export const uploadPreSurveyAttachments = createAction(
  '[User-Assets] Upload Pre Survey Attachments',
  props<{ opts: UploadPreSurveyAttachmentOpts }>()
);

export const uploadPreSurveyAttachmentsSuccess = createAction(
  '[User-Assets] Upload Pre Survey Attachments Success',
  props<{
    opts: {
      userProcessId: string;
      preSurveyId: string;
      uploadedFiles: MultipleFilesUploadResult;
    };
  }>()
);
export const uploadPreSurveyAttachmentsError = createAction('[User-Assets] Upload Pre Survey Attachments Error');
