import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserFilesState } from './user-files.state';

export const selectUserFilesState = createFeatureSelector<UserFilesState>('userFiles');

export const selectLoading = createSelector(selectUserFilesState, state => state.loading);
export const selectDownloadingFile = createSelector(selectUserFilesState, state => state.downloadingFile);

export const selectFilesOfDocuments = createSelector(selectUserFilesState, state => state.filesOfDocuments);

export const selectUserAssets = createSelector(selectUserFilesState, state => state.userAssets);

export const selectSort = createSelector(selectUserFilesState, state => state.sort);

export const selectUpload = createSelector(selectUserFilesState, state => state.upload);
