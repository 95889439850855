import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import { switchMap, map, catchError, concatMap } from 'rxjs/operators';

import {} from '@interfaces';
import { parseError } from '@state/errors.parser';
import { NotificationsHttpService } from '@core/http/notifications.http.service';

import * as notificationsActions from './notifications.actions';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';

@Injectable()
export class InAppNotificationsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly notificationsHttp: NotificationsHttpService,
    private readonly snackService: SnackbarService
  ) {}

  getUnreadCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.getUnreadCount),
      switchMap(() => {
        return this.notificationsHttp.getUnreadCount().pipe(
          map(unreadCount => {
            return notificationsActions.getUnreadCountSuccess({ ...unreadCount });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.getUnreadCount.type);
            return of(notificationsActions.getUnreadCountError({ errorMessage }));
          })
        );
      })
    )
  );

  getUnreadCountOfType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.getUnreadCountOfType),
      switchMap(({ notificationType, relatedForeignerId }) => {
        return this.notificationsHttp.getUnreadCountOfType(notificationType, relatedForeignerId).pipe(
          map(unreadCount => {
            return notificationsActions.getUnreadCountOfTypeSuccess({
              notificationType,
              ...unreadCount,
              relatedForeignerId,
            });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.getUnreadCountOfType.type);
            return of(
              notificationsActions.getUnreadCountOfTypeError({ errorMessage, notificationType, relatedForeignerId })
            );
          })
        );
      })
    )
  );

  getShortlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.getShortlist),
      switchMap(({ showOnlyUnread }) => {
        return this.notificationsHttp.getMyShortlist(showOnlyUnread).pipe(
          map(paginatedShortlist => {
            return notificationsActions.getShortlistSuccess({ shortlist: paginatedShortlist });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.getShortlist.type);
            return of(notificationsActions.getShortlistError({ errorMessage }));
          })
        );
      })
    )
  );

  getShortlistMore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.getShortlistMore),
      switchMap(({ showOnlyUnread, pagination }) => {
        return this.notificationsHttp.getMyShortlistMore(showOnlyUnread, pagination).pipe(
          map(paginatedShortlist => {
            return notificationsActions.getShortlistMoreSuccess({ shortlist: paginatedShortlist });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.getShortlistMore.type);
            return of(notificationsActions.getShortlistMoreError({ errorMessage }));
          })
        );
      })
    )
  );

  markAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markAsRead),
      switchMap(({ notificationsIds }) => {
        return this.notificationsHttp.markAsRead(notificationsIds).pipe(
          map(updatedNotifications => {
            return notificationsActions.markAsReadSuccess({ updatedNotifications });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.markAsRead.type);
            return of(notificationsActions.markAsReadError({ errorMessage, notificationsIds }));
          })
        );
      })
    )
  );

  markAsUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markAsUnread),
      switchMap(({ notificationsIds }) => {
        return this.notificationsHttp.markAsUnread(notificationsIds).pipe(
          map(updatedNotifications => {
            return notificationsActions.markAsUnreadSuccess({ updatedNotifications });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.markAsUnread.type);
            return of(notificationsActions.markAsUnreadError({ errorMessage, notificationsIds }));
          })
        );
      })
    )
  );

  markAllAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markAllAsRead),
      switchMap(() => {
        return this.notificationsHttp.markAllAsRead().pipe(
          map(updatedShortlist => {
            return notificationsActions.markAllAsReadSuccess({ updatedShortlist });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.markAllAsRead.type);
            return of(notificationsActions.markAllAsReadError({ errorMessage }));
          })
        );
      })
    )
  );

  markAllOfTypeAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markAllOfTypeAsRead),
      concatMap(({ type, ...opts }) => {
        return this.notificationsHttp.markAllOfTypeAsRead(opts).pipe(
          map(unreadCount => {
            return notificationsActions.markAllOfTypeAsReadSuccess({ ...unreadCount });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.markAllOfTypeAsRead.type);
            return of(notificationsActions.markAllOfTypeAsReadError({ errorMessage }));
          })
        );
      })
    )
  );

  markAllOfTypeAsReadBulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markAllOfTypeAsReadBulk),
      concatMap(({ type, inAppNotificationTypes, ...opts }) => {
        const streams = inAppNotificationTypes.map(inAppNotificationType => {
          const singleOpts = { ...opts, inAppNotificationType };
          return this.notificationsHttp.markAllOfTypeAsRead({ ...singleOpts });
        });

        return forkJoin(streams).pipe(
          map((unreadCounts: any) => {
            const lastUnreadCount = unreadCounts.pop();
            return notificationsActions.markAllOfTypeAsReadSuccess({ unreadCount: lastUnreadCount.unreadCount });
          }),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.markAllOfTypeAsRead.type);
            return of(notificationsActions.markAllOfTypeAsReadError({ errorMessage }));
          })
        );
      })
    )
  );

  setMaintenanceInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.setMaintenanceInfo),
      switchMap(({ opts }) => {
        return this.notificationsHttp.setMaintenanceInfo(opts).pipe(
          map(result => notificationsActions.setMaintenanceSuccess({ result })),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.setMaintenanceInfo.type);
            this.snackService.showError('MAINTENANCE_SHOUT_FAILED');
            return of(notificationsActions.setMaintenanceError({ errorMessage }));
          })
        );
      })
    )
  );

  getMaintenanceInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.getMaintenanceInfo),
      switchMap(() => {
        return this.notificationsHttp.getMaintenanceInfo().pipe(
          map(result => notificationsActions.getMaintenanceInfoSuccess({ result })),
          catchError(error => {
            const errorMessage = parseError(error, notificationsActions.getMaintenanceInfo.type);
            this.snackService.showError('FETCHING_MAINTENANCE_INFO_FAILED');
            return of(notificationsActions.getMaintenanceInfoError({ errorMessage }));
          })
        );
      })
    )
  );
}
