<div class="notifications-main-container" #notificationsMainWindow>
  <div class="notifications-list" #notificationsList>
    <div class="list-header">
      <span *ngIf="deviceInfo.deviceTypeDetected !== 'PHONE'" class="mark-all" (click)="markAllAsRead()">{{
        'IN_APP_NOTIFICATIONS.MARK_ALL_AS_READ' | translate
      }}</span>

      <mat-icon *ngIf="deviceInfo.deviceTypeDetected === 'PHONE'" class="mark-all-icon" (click)="markAllAsRead()"
        >done_all
      </mat-icon>

      <h1>{{ 'IN_APP_NOTIFICATIONS.NOTIFICATIONS' | translate }}</h1>
      <div class="buttons">
        <button class="standard-form-button" (click)="toggleShowOnlyUnread()" [class.button-toggled]="!showOnlyUnread">
          {{ 'IN_APP_NOTIFICATIONS.ALL' | translate }}
        </button>
        <button class="standard-form-button" (click)="toggleShowOnlyUnread()" [class.button-toggled]="showOnlyUnread">
          {{ 'IN_APP_NOTIFICATIONS.UNREAD' | translate }}
        </button>
      </div>
    </div>

    <div class="loading-wrapper" *ngIf="loading$ | async">
      <mat-spinner [diameter]="55"></mat-spinner>
    </div>
    <ng-container *ngIf="shortlist?.length; else noNotifications">
      <div
        *ngFor="let notification of shortlist; index as notificationIndex; trackBy: trackByFn"
        class="notification-item"
        [class.not-read]="!notification.read"
        [class.not-clickable]="!notification.clickable">
        <a
          *ngIf="notification.linkParams"
          class="link-without-style"
          [routerLink]="notification.linkParams.linkParams"
          [queryParams]="notification.linkParams.extras?.queryParams">
          <ng-container
            [ngTemplateOutlet]="notificationContent"
            [ngTemplateOutletContext]="{ notification: notification }">
          </ng-container>
        </a>

        <ng-container
          *ngIf="!notification.linkParams"
          [ngTemplateOutlet]="notificationContent"
          [ngTemplateOutletContext]="{ notification: notification }">
        </ng-container>
      </div>
    </ng-container>
    <div class="loading-more" *ngIf="loadingMore$ | async">
      <mat-spinner class="loading-more-spinner" [diameter]="35"></mat-spinner>
    </div>
    <div class="no-more-notifications" *ngIf="allNotificationsLoaded">
      <p>{{ 'IN_APP_NOTIFICATIONS.NO_MORE_NOTIFICATIONS' | translate }}</p>
    </div>
  </div>
  <ng-template #noNotifications>
    <div class="no-notifications-message">
      <h1>{{ 'IN_APP_NOTIFICATIONS.NO_NOTIFICATIONS' | translate }}</h1>
    </div>
  </ng-template>
</div>

<ng-template #notificationContent let-notification="notification">
  <div
    class="row content-row"
    (click)="notification.clickable && notificationClick(notification, $event)"
    matTooltip="{{
      'IN_APP_NOTIFICATIONS.YOU_CANT_SEE_DETAILS_BECAUSE_WRONG_PROCESS_STATUS'
        | translate
          : {
              foreignerName: notification.foreignerName,
              document: notification.relatedUserDocument?.document,
              relatedUserProcess: notification.relatedUserProcess
            }
    }}"
    matTooltipClass="universalTooltip"
    [matTooltipDisabled]="notification.clickable"
    matTooltipPosition="left">
    <div
      [ngSwitch]="notification.type"
      class="column column-icon"
      [class.multi-icons]="notification.type === InAppNotificationType.DOCUMENTS_SENT_TO_EXTERNAL_SYSTEM_AND_GENERATED">
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_SET_AVAILABILITY"
        class="foreigner-set-availability-icon">
        calendar_today
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_SENT_PRE_SURVEY_FOR_VERIFICATION"
        class="foreigner-sent-application-to-verification-icon">
        pending_actions
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_SENT_APPLICATION_TO_VERIFICATION"
        class="foreigner-sent-application-to-verification-icon">
        pending_actions
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_ADDED_DOCUMENT_COMMENT"
        class="foreigner-sent-message-in-chat-icon">
        mms
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.EMPLOYEE_ADDED_DOCUMENT_COMMENT"
        class="employee-sent-message-in-chat-icon">
        mms
      </mat-icon>
      <!-- <mat-icon *ngSwitchCase="InAppNotificationType.EMPLOYEE_CHANGED_DOCUMENT_STATUS"
                class="employee-changed-document-status-icon">
        description
      </mat-icon> -->
      <mat-icon
        *ngSwitchCase="InAppNotificationType.APPLICATION_STATUS_CHANGED"
        class="application-status-changed-icon">
        difference
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.APPLICATION_STATUS_CHANGED_WITH_TYPE"
        class="application-status-changed-icon">
        difference
      </mat-icon>
      <mat-icon *ngSwitchCase="InAppNotificationType.DOCUMENTS_LIST_CHANGED" class="documents-list-changed-icon">
        edit_document
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.DOCUMENTS_GENERATION_SUCCESS"
        class="documents-generation-success-icon">
        print
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.DOCUMENTS_SENT_TO_EXTERNAL_SYSTEM_AND_GENERATED"
        fontSet="material-icons-outlined"
        class="inpol-sending-success-icon">
        domain_verification
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.DOCUMENTS_SENT_TO_EXTERNAL_SYSTEM_AND_GENERATED"
        class="documents-generation-success-icon">
        print
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.DOCUMENTS_GENERATION_ERROR"
        class="documents-generation-error-icon">
        print_disabled
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.INPOL_SENDING_SUCCESS"
        fontSet="material-icons-outlined"
        class="inpol-sending-success-icon">
        domain_verification
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.INPOL_SENDING_ERROR"
        class="inpol-sending-error-icon material-icons-outlined">
        block
      </mat-icon>

      <mat-icon
        *ngSwitchCase="InAppNotificationType.MOS_SENDING_SUCCESS"
        fontSet="material-icons-outlined"
        class="inpol-sending-success-icon">
        domain_verification
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.MOS_SENDING_ERROR"
        class="inpol-sending-error-icon material-icons-outlined">
        block
      </mat-icon>

      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_HAS_BEEN_ASSIGNED_TO_YOU"
        class="foreigner-assigned-icon">
        person
      </mat-icon>
      <mat-icon *ngSwitchCase="InAppNotificationType.FOREIGNER_SENT_MESSAGE_IN_CHAT" class="new-chat-message-icon">
        forum
      </mat-icon>
      <mat-icon *ngSwitchCase="InAppNotificationType.EMPLOYEE_SENT_MESSAGE_IN_CHAT" class="new-chat-message-icon">
        forum
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CHANGED_PERSONAL_DETAILS"
        class="changed-personal-details-icon">
        warning
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.EMPLOYEE_CANCELLED_OFFICE_APPOINTMENT"
        class="appointment-cancelled-icon">
        event_busy_outline
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_SENDS_APPLICATION_WITH_POST"
        class="foreigner-sends-application-with-post">
        mark_as_unread
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_APPLICATION_MODE_HAS_CHANGED"
        class="objective-icon"
        fontSet="material-icons-outlined">
        priority_high
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.APPLICATION_MODE_CHANGED"
        class="inpol-sending-error-icon"
        fontSet="material-icons-outlined">
        integration_instructions
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_PICKED_SIGNING_MODE"
        class="new-chat-message-icon"
        fontSet="material-icons-outlined">
        draw
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_MODE"
        class="changed-personal-details-icon"
        fontSet="material-icons-outlined">
        draw
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_DATE"
        class="changed-personal-details-icon"
        fontSet="material-icons-outlined">
        calendar_month
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_PLANNING_TO_COME_TO_THE_OFFICE"
        fontSet="material-icons-outlined"
        class="primary-icon">
        calendar_month
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_SET_IMMIGRATION_OFFICE_VISIT_DATE"
        fontSet="material-icons-outlined"
        class="primary-icon">
        calendar_month
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_PLANNING_TO_COME_TO_OTHER_OFFICE"
        fontSet="material-icons-outlined"
        class="primary-icon">
        calendar_month
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CHANGED_DATE_OF_COMPANY_OFFICE_VISIT"
        fontSet="material-icons-outlined"
        class="objective-icon">
        calendar_month
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CHANGED_IMMIGRATION_OFFICE_VISIT_DATE"
        fontSet="material-icons-outlined"
        class="objective-icon">
        calendar_month
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CHANGED_DATE_OF_OTHER_COMPANY_OFFICE_VISIT"
        fontSet="material-icons-outlined"
        class="objective-icon">
        calendar_month
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CANCELLED_VISIT_IN_COMPANY_OFFICE"
        fontSet="material-icons-outlined"
        class="negative-icon">
        cancel
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CANCELLED_VISIT_IN_OTHER_COMPANY_OFFICE"
        fontSet="material-icons-outlined"
        class="negative-icon">
        cancel
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CONFIRMED_READINESS_FOR_VISIT"
        fontSet="material-icons-outlined"
        class="positive-icon">
        how_to_reg
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_CANCELLED_READINESS_FOR_VISIT"
        fontSet="material-icons-outlined"
        class="negative-icon">
        cancel
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.FOREIGNER_ADDED_ITEMS_TO_REVIEW"
        fontSet="material-icons-outlined"
        class="action-icon">
        attach_file_add
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.NEW_FINAL_VERIFICATION_COMMENTS"
        fontSet="material-icons-outlined"
        class="action-icon">
        speaker_notes
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.CHANGED_FINAL_VERIFICATION_COMMENTS"
        fontSet="material-icons-outlined"
        class="action-icon">
        speaker_notes
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.REASSIGNED_FOREIGNERS_TO_YOU"
        fontSet="material-icons-outlined"
        class="action-icon">
        groups
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.NEW_APPLICATION_HAS_BEEN_ADDED"
        fontSet="material-icons-outlined"
        class="new-application-has-been-added-icon">
        post_add
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.NEW_APPLICATION_HAS_BEEN_ADDED_WITH_TYPE"
        fontSet="material-icons-outlined"
        class="new-application-has-been-added-icon">
        post_add
      </mat-icon>
      <mat-icon
        *ngSwitchCase="InAppNotificationType.APPLICATION_HAS_BEEN_DELETED"
        fontSet="material-icons-outlined"
        class="application-has-been-removed">
        delete
      </mat-icon>
    </div>
    <div class="column column-text">
      <p
        class="text notification"
        [innerHTML]="
          'IN_APP_NOTIFICATIONS.' + notification.type
            | translate
              : {
                  foreignerName: notification.foreignerName,
                  document: notification.relatedUserDocument?.document,
                  documentName: notification.documentName,
                  relatedUserProcess: notification.relatedUserProcess,
                  origError: notification.origError,
                  externalSystemName: notification.data?.additionalInfo?.mergingJobOpts?.externalSystemName || null,
                  signingDate: notification.data?.additionalInfo?.signingDate,
                  signingMode: notification.data?.additionalInfo?.signingMode,
                  additionalInfo: notification.data?.additionalInfo
                }
            | sanitizeHtml
            | truncate: 140 : false
        "></p>
      <div class="meta-wrapper">
        <p
          *ngIf="!notification.read"
          (click)="$event.stopPropagation(); $event.preventDefault(); markAsRead(notification)"
          class="action-button">
          {{ 'IN_APP_NOTIFICATIONS.MARK_AS_READ' | translate }}
        </p>
        <p
          *ngIf="notification.read"
          (click)="$event.stopPropagation(); $event.preventDefault(); markAsUnread(notification)"
          class="action-button">
          {{ 'IN_APP_NOTIFICATIONS.MARK_AS_UNREAD' | translate }}
        </p>
        <p class="date">{{ notification.createdAt | date: 'dd-MM-yyyy HH:mm' }}</p>
      </div>
      <!-- <span *ngIf="notification.stacked" class="stacked-indicator-text">({{notification.stackSize + 1 }})</span> -->
    </div>
    <div class="column column-read-indicator">
      <div
        *ngIf="loadingChangesIn !== notification.id; else showLoadingChanges"
        [matBadgeHidden]="!notification.stacked"
        matBadgePosition="before"
        matBadgeSize="medium"
        [matBadgeColor]="notification.read ? 'accent' : 'warn'"
        [matBadge]="notification.stackSize"
        class="unread-icon"
        [class.hide-dot]="notification.read || notification.stacked"></div>
      <ng-template #showLoadingChanges>
        <div>
          <mat-spinner [diameter]="15"></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
