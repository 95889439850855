<div class="utils-buttons" [class.light-theme]="theme === 'LIGHT'" [class.dark-theme]="theme === 'DARK'">
  <div class="text-button change-font-size-button" (click)="changeFontSize()">aA</div>
  <div class="lang-label">{{ 'LANGUAGE.PICK_LANGUAGE' | translate }}:</div>
  <ng-container *ngIf="availableLanguages?.length && currentUserLang?.key">
    <div
      *ngFor="let lang of availableLanguages; index as langIndex"
      class="text-button"
      [class.active]="currentUserLang.key === lang.key"
      (click)="changeLangTo(lang)">
      {{ 'LANGUAGE.' + lang.key.toUpperCase() + '_SHORT' | translate }}
    </div>
  </ng-container>
  <!-- <div class="text-button" [class.active]="currentUserLang === 'en'" (click)="changeLangTo('en')">
    {{'COMMON.LOGIN_PAGE.EN' | translate }}
  </div> -->
</div>
