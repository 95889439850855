<form [formGroup]="formGroup" id="step3Form">
  <div class="step3-form-container">
    <div class="row">
      <div class="standard-form-field radio-form-field meansOfSubsistenceField required-field">
        <label
          for="applicantMeansOfSubsistence"
          [class.invalid]="controls.meansOfSubsistence.touched && controls.meansOfSubsistence.invalid">
          {{ 'PERSONAL_DATA_FORM.MEANS-OF-SUBSISTENCE.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group
          id="applicantMeansOfSubsistence"
          formControlName="meansOfSubsistence"
          (keydown)="firstInputKeydown($event)"
          [required]="true">
          <mat-radio-button
            *ngFor="let meanObj of selectsValues.MEANS_OF_SUBSISTENCE; index as radioButtonIndex"
            id="meansOfSubsistence-{{ radioButtonIndex }}"
            [value]="meanObj.value">
            {{ meanObj.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row" *ngIf="controls.meansOfSubsistence.value === 'Oszczędności'">
      <div class="standard-form-field full-width-field meansOfSubsistenceSavingsAmount-field required-field">
        <label
          for="meansOfSubsistenceSavingsAmountInput"
          [class.invalid]="
            controls.meansOfSubsistenceSavingsAmount.touched && controls.meansOfSubsistenceSavingsAmount.invalid
          ">
          {{ 'PERSONAL_DATA_FORM.MEANS-OF-SUBSISTENCE-SAVINGS-AMOUNT.MAIN-LABEL' | translate }}*
          <br />
          {{ 'PERSONAL_DATA_FORM.MEANS-OF-SUBSISTENCE-SAVINGS-AMOUNT.NEXT-LINE-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="meansOfSubsistenceSavingsAmountInput"
            type="text"
            maxLength="200"
            minLength="1"
            placeholder="{{ 'PERSONAL_DATA_FORM.MEANS-OF-SUBSISTENCE-SAVINGS-AMOUNT.PLACEHOLDER' | translate }}"
            [required]="false"
            [formControl]="controls.meansOfSubsistenceSavingsAmount" />
          <mat-error *ngIf="controls.meansOfSubsistenceSavingsAmount.invalid">
            {{ 'PERSONAL_DATA_FORM.MEANS-OF-SUBSISTENCE-SAVINGS-AMOUNT.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.meansOfSubsistenceSavingsAmount) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="standard-form-field radio-form-field medicalInsurance-field required-field">
        <label
          for="applicantMedicalInsurance"
          [class.invalid]="controls.medicalInsurance.touched && controls.medicalInsurance.invalid">
          {{ 'PERSONAL_DATA_FORM.MEDICAL-INSURANCE.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group id="applicantMedicalInsurance" formControlName="medicalInsurance" [required]="true">
          <mat-radio-button *ngFor="let insuranceObj of selectsValues.MEDICAL_INSURANCE" [value]="insuranceObj.value">
            {{ insuranceObj.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="standard-form-field radio-form-field familyMembersOutsidePoland-field required-field">
        <label
          for="applicantFamilyMembersOutsidePoland"
          [class.invalid]="controls.familyMembersOutsidePoland.touched && controls.familyMembersOutsidePoland.invalid">
          {{ 'PERSONAL_DATA_FORM.FAMILY-MEMBERS-OUTSIDE-POLAND.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group
          id="applicantFamilyMembersOutsidePoland"
          formControlName="familyMembersOutsidePoland"
          [required]="true">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="standard-form-field full-width-field applyingForExperience-field required-field">
        <label
          for="applicantApplyingForExperience"
          [class.invalid]="controls.applyingForExperience.touched && controls.applyingForExperience.invalid">
          {{ 'PERSONAL_DATA_FORM.APPLYING-FOR-EXPERIENCE.UPPER-LABEL' | translate }}
          <br />
          {{ 'PERSONAL_DATA_FORM.APPLYING-FOR-EXPERIENCE.MAIN-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            id="applicantApplyingForExperience"
            formControlName="applyingForExperience"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="8"
            cdkAutosizeMaxRows="8"></textarea>
          <mat-icon
            (click)="$event.preventDefault(); $event.stopPropagation()"
            matSuffix
            customTooltip
            [contentTemplate]="applyingForExperienceTooltipTemplate">
            help_outline
          </mat-icon>
          <mat-error *ngIf="controls.applyingForExperience.invalid">
            {{ 'PERSONAL_DATA_FORM.APPLYING-FOR-EXPERIENCE.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.applyingForExperience) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" [class.mark-red-border]="controls.currentlyDetained.value === true && mode === 'employee'">
      <div class="standard-form-field radio-form-field currentlyDetained-field required-field">
        <label [class.invalid]="controls.currentlyDetained.touched && controls.currentlyDetained.invalid">
          {{ 'PERSONAL_DATA_FORM.CURRENTLY-DETAINED.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group id="applicantCurrentlyDetained" formControlName="currentlyDetained" [required]="true">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <ng-container *ngIf="controls.currentlyDetained.value === true">
      <!-- ====================================================================== 1 ============================= -->
      <div class="row" [class.mark-red-border]="controls.inGuardedCentre.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field inGuardedCentre-field required-field">
          <label [class.invalid]="controls.inGuardedCentre.touched && controls.inGuardedCentre.invalid">
            {{ 'NT2.DETAINED_IN_GUARDED_CENTRE' | translate }}*
          </label>
          <mat-radio-group formControlName="inGuardedCentre">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field full-width-field inGuardedCentreComment-field"
          *ngIf="controls.inGuardedCentre.value === true">
          <label [class.invalid]="controls.inGuardedCentreComment.touched && controls.inGuardedCentreComment.invalid">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="inGuardedCentreComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.inGuardedCentreComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.inGuardedCentreComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ====================================================================== 2 ============================= -->
      <div class="row" [class.mark-red-border]="controls.inDetentionCentre.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field inDetentionCentre-field required-field">
          <label [class.invalid]="controls.inDetentionCentre.touched && controls.inDetentionCentre.invalid">
            {{ 'NT2.IN_DETENTION_CENTRE' | translate }}*
          </label>
          <mat-radio-group formControlName="inDetentionCentre">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field full-width-field inDetentionCentreComment-field"
          *ngIf="controls.inDetentionCentre.value === true">
          <label
            [class.invalid]="controls.inDetentionCentreComment.touched && controls.inDetentionCentreComment.invalid">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="inDetentionCentreComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.inDetentionCentreComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.inDetentionCentreComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ====================================================================== 3 ============================= -->
      <div class="row" [class.mark-red-border]="controls.hasBanOnLeavingCountry.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field hasBanOnLeavingCountry-field required-field">
          <label [class.invalid]="controls.hasBanOnLeavingCountry.touched && controls.hasBanOnLeavingCountry.invalid">
            {{ 'NT2.HAS_BAN_ON_LEAVING_THE_COUNTRY' | translate }}*
          </label>
          <mat-radio-group formControlName="hasBanOnLeavingCountry">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field full-width-field hasBanOnLeavingCountryComment-field"
          *ngIf="controls.hasBanOnLeavingCountry.value === true">
          <label
            [class.invalid]="
              controls.hasBanOnLeavingCountryComment.touched && controls.hasBanOnLeavingCountryComment.invalid
            ">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="hasBanOnLeavingCountryComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.hasBanOnLeavingCountryComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.hasBanOnLeavingCountryComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ====================================================================== 4 ============================= -->
      <div
        class="row"
        [class.mark-red-border]="controls.hasSentenceOfImprisonment.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field hasSentenceOfImprisonment-field required-field">
          <label
            [class.invalid]="controls.hasSentenceOfImprisonment.touched && controls.hasSentenceOfImprisonment.invalid">
            {{ 'NT2.SENTENCED_IMPRISONMENT' | translate }}*
          </label>
          <mat-radio-group formControlName="hasSentenceOfImprisonment">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field full-width-field hasSentenceOfImprisonmentComment-field"
          *ngIf="controls.hasSentenceOfImprisonment.value === true">
          <label
            [class.invalid]="
              controls.hasSentenceOfImprisonmentComment.touched && controls.hasSentenceOfImprisonmentComment.invalid
            ">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="hasSentenceOfImprisonmentComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.hasSentenceOfImprisonmentComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.hasSentenceOfImprisonmentComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ====================================================================== 5 ============================= -->
      <div class="row" [class.mark-red-border]="controls.temporarilyArrested.value === true && mode === 'employee'">
        <div class="standard-form-field radio-form-field temporarilyArrested-field required-field">
          <label [class.invalid]="controls.temporarilyArrested.touched && controls.temporarilyArrested.invalid">
            {{ 'NT2.TEMPORARILY_ARRESTED' | translate }}*
          </label>
          <mat-radio-group formControlName="temporarilyArrested">
            <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
            <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="standard-form-field full-width-field temporarilyArrestedComment-field"
          *ngIf="controls.temporarilyArrested.value === true">
          <label
            [class.invalid]="
              controls.temporarilyArrestedComment.touched && controls.temporarilyArrestedComment.invalid
            ">
            {{ 'NT2.COMMENTS' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              formControlName="temporarilyArrestedComment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="9"></textarea>
            <mat-error *ngIf="controls.temporarilyArrestedComment.invalid">
              {{ 'NT2.COMMENTS' | translate }}
              {{ getErrorMessage(controls.temporarilyArrestedComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="standard-form-field radio-form-field wasSentencedInPoland-field required-field">
        <label
          for="applicantWasSentencedInPoland"
          [class.invalid]="controls.wasSentencedInPoland.touched && controls.wasSentencedInPoland.invalid">
          {{ 'PERSONAL_DATA_FORM.WAS-SENTENCED-IN-POLAND.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group id="applicantWasSentencedInPoland" formControlName="wasSentencedInPoland" [required]="true">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="standard-form-field full-width-field sentenceReason-field"
        *ngIf="controls.wasSentencedInPoland.value === true">
        <label
          for="applicantSentenceReason"
          [class.invalid]="controls.sentenceReason.touched && controls.sentenceReason.invalid">
          {{ 'PERSONAL_DATA_FORM.SENTENCE-REASON.MAIN-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            id="applicantSentenceReason"
            formControlName="sentenceReason"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"></textarea>
          <mat-error *ngIf="controls.sentenceReason.invalid">
            {{ 'PERSONAL_DATA_FORM.SENTENCE-REASON.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.sentenceReason) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="standard-form-field radio-form-field currentlySubjectOfProceedings-field required-field">
        <label
          for="applicantCurrentlySubjectOfProceedings"
          [class.invalid]="
            controls.currentlySubjectOfProceedings.touched && controls.currentlySubjectOfProceedings.invalid
          ">
          {{ 'PERSONAL_DATA_FORM.CURRENTLY-SUBJECT-OF-PROCEEDINGS.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group
          id="applicantCurrentlySubjectOfProceedings"
          formControlName="currentlySubjectOfProceedings"
          [required]="true">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="standard-form-field full-width-field proceedingsReason-field"
        *ngIf="controls.currentlySubjectOfProceedings.value === true">
        <label
          for="applicantProceedingsReason"
          [class.invalid]="controls.proceedingsReason.touched && controls.proceedingsReason.invalid">
          {{ 'PERSONAL_DATA_FORM.PROCEEDINGS-REASON.MAIN-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            id="applicantProceedingsReason"
            formControlName="proceedingsReason"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"></textarea>
          <mat-error *ngIf="controls.proceedingsReason.invalid">
            {{ 'PERSONAL_DATA_FORM.PROCEEDINGS-REASON.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.proceedingsReason) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="standard-form-field radio-form-field liabilitiesFromVerdicts-field required-field">
        <label
          for="applicantLiabilitiesFromVerdicts"
          [class.invalid]="controls.liabilitiesFromVerdicts.touched && controls.liabilitiesFromVerdicts.invalid">
          {{ 'PERSONAL_DATA_FORM.LIABILITIES-FROM-VERDICTS.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group
          id="applicantLiabilitiesFromVerdicts"
          formControlName="liabilitiesFromVerdicts"
          (keydown.Tab)="controls.liabilitiesFromVerdicts.value !== true ? emitLastFieldTabPressed($event) : undefined"
          [required]="true">
          <mat-radio-button [value]="true"> {{ 'COMMON.YES' | translate }} </mat-radio-button>
          <mat-radio-button [value]="false"> {{ 'COMMON.NO' | translate }} </mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="standard-form-field full-width-field liabilitiesDescription-field"
        *ngIf="controls.liabilitiesFromVerdicts.value === true">
        <label
          for="applicantLiabilitiesDescription"
          [class.invalid]="controls.liabilitiesDescription.touched && controls.liabilitiesDescription.invalid">
          {{ 'PERSONAL_DATA_FORM.LIABILITIES-DESCRIPTION.MAIN-LABEL' | translate }}
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            (keydown.Tab)="emitLastFieldTabPressed($event)"
            id="applicantLiabilitiesDescription"
            formControlName="liabilitiesDescription"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"></textarea>
          <mat-error *ngIf="controls.liabilitiesDescription.invalid">
            {{ 'PERSONAL_DATA_FORM.LIABILITIES-DESCRIPTION.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.liabilitiesDescription) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>

<ng-template #applyingForExperienceTooltipTemplate>
  <div class="customTooltip" style="display: flex; flex-direction: column">
    <h3>{{ 'PERSONAL_DATA_FORM.EXAMPLE' | translate }}</h3>
    <span translate="EXPERIENCE_TOOLTIP.ROW_1"></span>
    <span translate="EXPERIENCE_TOOLTIP.ROW_2"></span>
    <span translate="EXPERIENCE_TOOLTIP.ROW_3"></span>
    <span translate="EXPERIENCE_TOOLTIP.ROW_4"></span>
    <span translate="EXPERIENCE_TOOLTIP.ROW_5"></span>
  </div>
</ng-template>
