import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  AddFileToMyDocOpts,
  AddNewUserProcessOpts,
  AnswerToMyDocQuestionOpts,
  AvailabilityForOfficeVisit,
  PersonalDetails,
  ProcessDocumentsList,
  SaveDocumentsSigningModeOpts,
  SaveUserAdditionalDocuments,
  SaveUserDocumentPayload,
  SetAppointmentDateAndPlaceOpts,
  UpdatePreSurveyOpts,
} from '@interfaces';
import { AppState } from '@state';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import * as userProcessActions from './user-process.actions';
import * as userProcessSelectors from './user-process.selectors';

@Injectable()
export class UserProcessFacade {
  public myProcesses$ = this.store.select(userProcessSelectors.selectMyProcesses);
  public myProcess$ = this.store.select(userProcessSelectors.selectMyProcess);

  public addNewUserProcessSuccess$ = this.actions.pipe(ofType(userProcessActions.addNewUserProcessSuccess));

  public updatePreSurveySuccess$ = this.actions.pipe(ofType(userProcessActions.updatePreSurveySuccess));
  public updatePreSurveyError$ = this.actions.pipe(ofType(userProcessActions.updatePreSurveyError));

  public sendPreSurveyForVerificationSuccess = this.actions.pipe(
    ofType(userProcessActions.sendPreSurveyForVerificationSuccess)
  );
  public sendPreSurveyForVerificationError = this.actions.pipe(
    ofType(userProcessActions.sendPreSurveyForVerificationError)
  );
  public updatePersonalDetailsSuccess$ = this.actions.pipe(ofType(userProcessActions.updatePersonalDetailsSuccess));
  public updatePersonalDetailsError$ = this.actions.pipe(ofType(userProcessActions.updatePersonalDetailsError));
  public confirmPersonalDetailsSuccess$ = this.actions.pipe(ofType(userProcessActions.confirmPersonalDetailsSuccess));

  public documentsList$ = this.store.select(userProcessSelectors.selectProcessDocuments);

  public loading$ = this.store.select(userProcessSelectors.selectLoading);
  public errorMessage$ = this.store.select(userProcessSelectors.selectErrorMessage);
  public downloadingUserDocumentFile$ = this.store.select(userProcessSelectors.selectDownloadingUserDocumentFile);

  public summaryDocuments$ = this.store.select(userProcessSelectors.selectSummaryDocuments);

  public pickProcessSuccess$ = this.actions.pipe(ofType(userProcessActions.pickProcessSuccess));
  public userDocumentSaveSuccess$ = this.actions.pipe(ofType(userProcessActions.saveUserDocumentSuccess));
  public createAdditionalDocSuccess$ = this.actions.pipe(ofType(userProcessActions.createNewAdditionalDocumentSuccess));
  public removeFileOfUserDocSuccess$ = this.actions.pipe(ofType(userProcessActions.removeFileOfUserDocSuccess));
  public saveUserDocumentSuccess$ = this.actions.pipe(ofType(userProcessActions.saveUserDocumentSuccess));
  public removeUserDocSuccess$ = this.actions.pipe(ofType(userProcessActions.removeUserDocSuccess));
  public sendAppToReviewSuccess$ = this.actions.pipe(ofType(userProcessActions.sendAppToReviewSuccess));

  public saveDocumentsSigningModeSuccess$ = this.actions.pipe(
    ofType(userProcessActions.saveDocumentsSigningModeSuccess)
  );
  public saveMyAvailabilitySuccess$ = this.actions.pipe(ofType(userProcessActions.saveMyAvailabilitySuccess));

  public setAppointmentDateAndPlaceSuccess$ = this.actions.pipe(
    ofType(userProcessActions.setAppointmentDateAndPlaceSuccess)
  );
  public setAppointmentDateAndPlaceError$ = this.actions.pipe(
    ofType(userProcessActions.setAppointmentDateAndPlaceError)
  );

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getMyProcesses(): void {
    this.store.dispatch(userProcessActions.getMyProcesses());
  }
  public getMyProcessDetails(userProcessId: string): void {
    this.store.dispatch(userProcessActions.getMyProcessDetails({ userProcessId }));
  }

  public addNewUserProcess(payload: AddNewUserProcessOpts): void {
    this.store.dispatch(userProcessActions.addNewUserProcess({ payload }));
  }

  public pickProcess(userProcessId: string, processId: string): void {
    this.store.dispatch(userProcessActions.pickProcess({ userProcessId, processId }));
  }

  public updatePreSurvey(opts: UpdatePreSurveyOpts): void {
    this.store.dispatch(userProcessActions.updatePreSurvey({ opts }));
  }

  public sendPreSurveyForVerification(opts: UpdatePreSurveyOpts): void {
    this.store.dispatch(userProcessActions.sendPreSurveyForVerification({ opts }));
  }

  public updatePersonalDetails(userProcessId: string, personalDetails: PersonalDetails): void {
    this.store.dispatch(userProcessActions.updatePersonalDetails({ userProcessId, personalDetails }));
  }

  public confirmPersonalDetails(userProcessId: string): void {
    this.store.dispatch(userProcessActions.confirmPersonalDetails({ userProcessId }));
  }

  public startCompletingDocuments(userProcessId: string): void {
    this.store.dispatch(userProcessActions.startCompletingDocuments({ userProcessId }));
  }

  public sendAppToReview(userProcessId: string): void {
    this.store.dispatch(userProcessActions.sendAppToReview({ userProcessId }));
  }

  public getListOfDocuments(userProcessId: string): void {
    this.store.dispatch(userProcessActions.getDocumentsList({ userProcessId }));
  }

  public getListOfDocuments$(userProcessId: string): Observable<ProcessDocumentsList> {
    return this.store.select(userProcessSelectors.selectProcessDocuments).pipe(
      tap(processDocumentsList => {
        if (!processDocumentsList?.processDocs?.length) {
          this.getListOfDocuments(userProcessId);
          return;
        }

        if (processDocumentsList.userDocs?.length && processDocumentsList.userDocs[0].userProcessId !== userProcessId) {
          this.getListOfDocuments(userProcessId);
          return;
        }
      }),
      filter(processDocumentsList => {
        if (!processDocumentsList?.processDocs?.length) {
          return false;
        }

        if (processDocumentsList.userDocs?.length && processDocumentsList.userDocs[0].userProcessId !== userProcessId) {
          return false;
        }

        return true;
      })
    );
  }

  public addFilesToMyDocument(opts: AddFileToMyDocOpts): void {
    this.store.dispatch(userProcessActions.addFilesToMyDocument({ opts }));
  }

  public answerToMyDocQuestion(opts: AnswerToMyDocQuestionOpts): void {
    this.store.dispatch(userProcessActions.answerToMyDocQuestion({ opts }));
  }

  public saveUserDocument(saveDocumentPayload: SaveUserDocumentPayload, userDocId?: string): void {
    this.store.dispatch(userProcessActions.saveUserDocument({ saveDocumentPayload, userDocId }));
  }
  public downloadFile(userProcessId: string, userDocumentId: string, filename: string | null): void {
    this.store.dispatch(userProcessActions.downloadFile({ userProcessId, userDocumentId, filename }));
  }
  public removeFileOfUserDoc(userProcessId: string, userDocumentId: string, filename: string): void {
    this.store.dispatch(userProcessActions.removeFileOfUserDoc({ userProcessId, userDocumentId, filename }));
  }
  public removeUserDocument(userProcessId: string, userDocumentId: string): void {
    this.store.dispatch(userProcessActions.removeUserDoc({ userProcessId, userDocumentId }));
  }
  public saveAddditionalDocs(additionalDocs: SaveUserAdditionalDocuments): void {
    this.store.dispatch(userProcessActions.saveAdditionalDocs({ additionalDocs }));
  }

  public getDocumentsForSummary(userProcessId: string): void {
    this.store.dispatch(userProcessActions.getDocumentsForSummary({ userProcessId }));
  }

  public downloadForPrintAll(userProcessId: string): void {
    this.store.dispatch(userProcessActions.downloadForPrintAll({ userProcessId }));
  }
  public downloadInstructions(userProcessId: string): void {
    this.store.dispatch(userProcessActions.downloadInstructions({ userProcessId }));
  }

  public saveMyDocumentsSigningMode(userProcessId: string, opts: SaveDocumentsSigningModeOpts): void {
    this.store.dispatch(userProcessActions.saveDocumentsSigningMode({ userProcessId, opts }));
  }

  public saveMyAvailability(userProcessId: string, availability: AvailabilityForOfficeVisit): void {
    this.store.dispatch(userProcessActions.saveMyAvailability({ availability, userProcessId }));
  }

  public confirmVoivoChange(userProcessId: string): void {
    this.store.dispatch(userProcessActions.confirmVoivoChange({ userProcessId }));
  }

  public setReadinessForVisit(opts: { userProcessId: string; ready: boolean }): void {
    this.store.dispatch(userProcessActions.setReadinessForVisit({ opts }));
  }

  public setAppointmentDateAndPlace(opts: SetAppointmentDateAndPlaceOpts): void {
    this.store.dispatch(userProcessActions.setAppointmentDateAndPlace({ opts }));
  }
}
