<form [formGroup]="formGroup" id="step1Form">
  <div class="step1-form-container">
    <div class="row">
      <div class="nowrap-container">
        <div class="standard-form-field name-field required-field">
          <label [class.invalid]="controls.name.touched && controls.name.invalid" for="applicantName">
            {{ 'PERSONAL_DATA_FORM.NAME.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantName"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.NAME.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.name" />
            <mat-icon
              #applicantName="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantName.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.NAME.TOOLTIP' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.name.invalid">
              {{ 'PERSONAL_DATA_FORM.NAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.name) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field previousName-field">
          <label
            for="applicantPreviousName"
            [class.invalid]="controls.previousName.touched && controls.previousName.invalid">
            {{ 'PERSONAL_DATA_FORM.PREVIOUS-NAME.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantPreviousName"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.PREVIOUS-NAME.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.previousName" />
            <mat-icon
              #applicantPreviousName="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantPreviousName.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.previousName.invalid">
              {{ 'PERSONAL_DATA_FORM.PREVIOUS-NAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.previousName) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="nowrap-container">
        <div class="standard-form-field surname-field required-field">
          <label for="applicantSurname" [class.invalid]="controls.surname.touched && controls.surname.invalid">
            {{ 'PERSONAL_DATA_FORM.SURNAME.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantSurname"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.SURNAME.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.surname" />
            <mat-icon
              #applicantSurname="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantSurname.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.SURNAME.TOOLTIP' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.surname.invalid">
              {{ 'PERSONAL_DATA_FORM.SURNAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.surname) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field previousSurname-field">
          <label
            for="applicantPreviousSurname"
            [class.invalid]="controls.previousSurname.touched && controls.previousSurname.invalid">
            {{ 'PERSONAL_DATA_FORM.PREVIOUS-SURNAME.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantPreviousSurname"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.PREVIOUS-SURNAME.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.previousSurname" />
            <mat-icon
              #applicantPreviousSurname="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantPreviousSurname.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.previousSurname.invalid">
              {{ 'PERSONAL_DATA_FORM.PREVIOUS-SURNAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.previousSurname) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="nowrap-container">
        <div class="standard-form-field familyName-field">
          <label for="applicantFamilyName" [class.invalid]="controls.familyName.touched && controls.familyName.invalid">
            {{ 'PERSONAL_DATA_FORM.FAMILY-NAME.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantFamilyName"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.FAMILY-NAME.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.familyName" />
            <mat-icon
              #applicantFamilyName="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantFamilyName.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.FAMILY-NAME.TOOLTIP' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              help_outline
            </mat-icon>
            <mat-error *ngIf="controls.familyName.invalid">
              {{ 'PERSONAL_DATA_FORM.FAMILY-NAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.familyName) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field fatherName-field required-field">
          <label for="applicantFatherName" [class.invalid]="controls.fatherName.touched && controls.fatherName.invalid">
            {{ 'PERSONAL_DATA_FORM.FATHER-NAME.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantFatherName"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.FATHER-NAME.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.fatherName" />
            <mat-icon
              #applicantFatherName="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantFatherName.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.fatherName.invalid">
              {{ 'PERSONAL_DATA_FORM.FATHER-NAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.fatherName) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="nowrap-container">
        <div class="standard-form-field fatherSurname-field">
          <label
            for="applicantFatherSurname"
            [class.invalid]="controls.fatherSurname.touched && controls.fatherSurname.invalid">
            {{ 'PERSONAL_DATA_FORM.FATHER-SURNAME.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantFatherSurname"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.FATHER-SURNAME.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.fatherSurname" />
            <mat-icon
              #applicantFatherSurname="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantFatherSurname.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.fatherSurname.invalid">
              {{ 'PERSONAL_DATA_FORM.FATHER-SURNAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.fatherSurname) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field motherName-field required-field">
          <label for="applicantMotherName" [class.invalid]="controls.motherName.touched && controls.motherName.invalid">
            {{ 'PERSONAL_DATA_FORM.MOTHER-NAME.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantMotherName"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.MOTHER-NAME.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.motherName" />
            <mat-icon
              #applicantMotherName="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantMotherName.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.motherName.invalid">
              {{ 'PERSONAL_DATA_FORM.MOTHER-NAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.motherName) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="nowrap-container">
        <div class="standard-form-field motherSurname-field">
          <label
            for="applicantMotherSurname"
            [class.invalid]="controls.motherSurname.touched && controls.motherSurname.invalid">
            {{ 'PERSONAL_DATA_FORM.MOTHER-SURNAME.MAIN-LABEL' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantMotherSurname"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.MOTHER-SURNAME.PLACEHOLDER' | translate }}"
              [required]="false"
              [formControl]="controls.motherSurname" />
            <mat-icon
              #applicantMotherSurname="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantMotherSurname.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.motherSurname.invalid">
              {{ 'PERSONAL_DATA_FORM.MOTHER-SURNAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.motherSurname) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field motherMaidenName-field required-field">
          <label
            for="applicantMotherMaidenName"
            [class.invalid]="controls.motherMaidenName.touched && controls.motherMaidenName.invalid">
            {{ 'PERSONAL_DATA_FORM.MOTHER-MAIDEN-NAME.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantMotherMaidenName"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.MOTHER-MAIDEN-NAME.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.motherMaidenName" />
            <mat-icon
              #applicantMotherMaidenName="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantMotherMaidenName.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.MOTHER-MAIDEN-NAME.TOOLTIP' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              help_outline
            </mat-icon>
            <mat-error *ngIf="controls.motherMaidenName.invalid">
              {{ 'PERSONAL_DATA_FORM.MOTHER-MAIDEN-NAME.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.motherMaidenName) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="nowrap-container">
        <div class="standard-form-field sex-field required-field">
          <label [class.invalid]="controls.sex.touched && controls.sex.invalid">
            {{ 'PERSONAL_DATA_FORM.SEX.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="controls.sex" id="applicantSex" panelClass="standard-select" [required]="true">
              <mat-option *ngFor="let item of selectsValues.SEX" [value]="item.value">
                {{ item.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.sex.touched && controls.sex.invalid">
              {{ 'PERSONAL_DATA_FORM.SEX.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.sex) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field dateOfBirth-field required-field">
          <label
            for="applicantDateOfBirth"
            [class.invalid]="controls.dateOfBirth.touched && controls.dateOfBirth.invalid">
            {{ 'PERSONAL_DATA_FORM.DATE-OF-BIRTH.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline" (click)="picker.open()">
            <input
              matInput
              (focus)="picker.open()"
              id="applicantDateOfBirth"
              #applicantDateOfBirth
              [matDatepicker]="picker"
              placeholder="{{ 'PERSONAL_DATA_FORM.DATE-OF-BIRTH.PLACEHOLDER' | translate }}"
              [min]="minValidDate"
              [max]="maxValidDate"
              [required]="true"
              [readonly]="true"
              [formControl]="controls.dateOfBirth" />
            <mat-error *ngIf="controls.dateOfBirth.invalid">
              {{ getErrorMessage(controls.dateOfBirth) }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              [startView]="'month'"
              [startAt]="'1985'"
              [calendarHeaderComponent]="pickerHeader"
              (closed)="applicantDateOfBirth.blur()">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="nowrap-container">
        <div class="standard-form-field placeOfBirth-field required-field">
          <label
            for="applicantPlaceOfBirth"
            [class.invalid]="controls.placeOfBirth.touched && controls.placeOfBirth.invalid">
            {{ 'PERSONAL_DATA_FORM.PLACE-OF-BIRTH.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantPlaceOfBirth"
              type="text"
              maxLength="200"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.PLACE-OF-BIRTH.PLACEHOLDER' | translate }}"
              [required]="true"
              [formControl]="controls.placeOfBirth" />
            <mat-icon
              #applicantPlaceOfBirth="matTooltip"
              (click)="$event.preventDefault(); $event.stopPropagation(); applicantPlaceOfBirth.toggle()"
              class="info-suffix-icon"
              matSuffix
              matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
              matTooltipClass="personalDataFormTooltip">
              info_outline
            </mat-icon>
            <mat-error *ngIf="controls.placeOfBirth.invalid">
              {{ 'PERSONAL_DATA_FORM.PLACE-OF-BIRTH.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.placeOfBirth) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="standard-form-field countryOfBirth-field required-field">
          <app-country-select
            class="country-select"
            [fieldControl]="controls.countryOfBirth"
            [fieldKey]="'countryOfBirth'"
            [isRequired]="true"
            [fieldLabelKey]="'COUNTRY-OF-BIRTH'">
          </app-country-select>
        </div>
      </div>
      <div class="nowrap-container">
        <div class="standard-form-field citizenship-field required-field">
          <!-- Filtering out id: 616 -> Poland. Person that has Polish citizenship cannot apply for temp. permit -->
          <app-country-select
            class="country-select"
            [fieldControl]="controls.citizenship"
            [fieldKey]="'citizenship'"
            [isRequired]="true"
            [filterOutIds]="[616]"
            [fieldLabelKey]="'CITIZENSHIP'">
          </app-country-select>
        </div>
        <div class="standard-form-field nationality-field required-field">
          <app-nationality-select
            class="country-select"
            [fieldControl]="controls.nationality"
            [fieldKey]="'nationality'"
            [isRequired]="true"
            [fieldLabelKey]="'NATIONALITY'">
          </app-nationality-select>
        </div>
      </div>
      <div class="row">
        <div class="nowrap-container">
          <div class="standard-form-field maritalStatus-field required-field">
            <label [class.invalid]="controls.maritalStatus.touched && controls.maritalStatus.invalid">
              {{ 'PERSONAL_DATA_FORM.MARITAL-STATUS.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                [formControl]="controls.maritalStatus"
                panelClass="standard-select"
                id="applicantMaritalStatus"
                [required]="true">
                <mat-option *ngFor="let item of selectsValues.MARITAL_STATUS" [value]="item.value">
                  {{ item.label }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controls.maritalStatus.invalid">
                {{ 'PERSONAL_DATA_FORM.MARITAL-STATUS.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.maritalStatus) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field education-field required-field">
            <label [class.invalid]="controls.education.touched && controls.education.invalid">
              {{ 'PERSONAL_DATA_FORM.EDUCATION.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                [formControl]="controls.education"
                id="applicantEducation"
                panelClass="standard-select"
                [required]="true">
                <mat-option *ngFor="let item of selectsValues.EDUCATION" [value]="item.value">
                  {{ item.label }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controls.education.invalid">
                {{ 'PERSONAL_DATA_FORM.EDUCATION.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.education) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="nowrap-container">
          <div class="standard-form-field passportNumber-field required-field">
            <label
              for="applicantPassportNumber"
              [class.invalid]="controls.passportNumber.touched && controls.passportNumber.invalid">
              {{ 'PERSONAL_DATA_FORM.PASSPORT-NUMBER.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                id="applicantPassportNumber"
                type="text"
                maxLength="200"
                minLength="1"
                placeholder="{{ 'PERSONAL_DATA_FORM.PASSPORT-NUMBER.PLACEHOLDER' | translate }}"
                [required]="true"
                [formControl]="controls.passportNumber" />
              <mat-icon
                #applicantPassportNumber="matTooltip"
                (click)="$event.preventDefault(); $event.stopPropagation(); applicantPassportNumber.toggle()"
                class="info-suffix-icon"
                matSuffix
                matTooltip="{{ 'PERSONAL_DATA_FORM.USE_ONLY_LATIN' | translate }}"
                matTooltipClass="personalDataFormTooltip">
                info_outline
              </mat-icon>
              <mat-error *ngIf="controls.passportNumber.invalid">
                {{ 'PERSONAL_DATA_FORM.PASSPORT-NUMBER.MAIN-LABEL' | translate }}
                {{ getErrorMessage(controls.passportNumber) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="standard-form-field pesel-field">
            <label for="peselInput" [class.invalid]="controls.pesel.touched && controls.pesel.invalid">
              {{ 'PERSONAL_DATA_FORM.PESEL.INFIELD-LABEL' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                #peselInput
                id="peselInput"
                placeholder="{{ 'PERSONAL_DATA_FORM.PESEL.PLACEHOLDER' | translate }}"
                [required]="false"
                formControlName="pesel" />
              <mat-error class="mat-error" *ngIf="controls.pesel.invalid">
                <!-- {{ 'PERSONAL_DATA_FORM.PESEL.INFIELD-LABEL' | translate }} -->
                {{ getErrorMessage(controls.pesel) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <nz-divider [nzText]="travelers" nzOrientation="center">
      <ng-template #travelers>
        <h5>{{ 'PERSONAL_DATA_FORM.TRAVEL_DOCUMENT' | translate }}</h5>
      </ng-template>
    </nz-divider>
    <div class="row">
      <div class="standard-form-field widened-field travelDocumentType-field required-field">
        <label
          for="travelDocumentType"
          [class.invalid]="controls.travelDocumentType.touched && controls.travelDocumentType.invalid">
          {{ 'PERSONAL_DATA_FORM.TRAVEL_DOCUMENT_TYPE.MAIN-LABEL' | translate }} *
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            [formControl]="controls.travelDocumentType"
            id="travelDocumentType"
            panelClass="standard-select"
            [required]="true">
            <mat-option *ngFor="let item of travelDocumentTypes" [value]="item">
              {{ 'NT4.TRAVEL_DOCUMENT_TYPES.' + item | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="controls.travelDocumentType.touched && controls.travelDocumentType.invalid">
            {{ 'PERSONAL_DATA_FORM.TRAVEL_DOCUMENT_TYPE.MAIN-LABEL' | translate }}
            {{ getErrorMessage(controls.travelDocumentType) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="standard-form-field travelDocumentSeries-field required-field">
        <label
          for="travelDocumentSeriesInput"
          [class.invalid]="controls.travelDocumentSeries.touched && controls.travelDocumentSeries.invalid">
          {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-SERIES.MAIN-LABEL' | translate }} *
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="travelDocumentSeriesInput"
            type="text"
            maxLength="2"
            minLength="1"
            placeholder="{{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-SERIES.PLACEHOLDER' | translate }}"
            [required]="true"
            [formControl]="controls.travelDocumentSeries" />
          <mat-error *ngIf="controls.travelDocumentSeries.invalid">
            {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-SERIES.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.travelDocumentSeries) }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="standard-form-field travelDocumentNumber-field required-field">
        <label
          for="travelDocumentNumberInput"
          [class.invalid]="controls.travelDocumentNumber.touched && controls.travelDocumentNumber.invalid">
          {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-NUMBER.MAIN-LABEL' | translate }} *
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="travelDocumentNumberInput"
            type="text"
            maxLength="100"
            minLength="1"
            placeholder="{{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-NUMBER.PLACEHOLDER' | translate }}"
            [required]="true"
            [formControl]="controls.travelDocumentNumber" />
          <mat-error *ngIf="controls.travelDocumentNumber.invalid">
            {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-NUMBER.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.travelDocumentNumber) }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="standard-form-field widened-field travelDocumentNrOfPersonsEntered-field required-field">
        <label
          for="travelDocumentNrOfPersonsEnteredInput"
          [class.invalid]="
            controls.travelDocumentNrOfPersonsEntered.touched && controls.travelDocumentNrOfPersonsEntered.invalid
          ">
          {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-NR-OF-PERSONS-ENTERED.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="travelDocumentNrOfPersonsEnteredInput"
            type="number"
            max="20"
            placeholder="{{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-NR-OF-PERSONS-ENTERED.PLACEHOLDER' | translate }}"
            [required]="true"
            [formControl]="controls.travelDocumentNrOfPersonsEntered" />
          <mat-error
            *ngIf="
              controls.travelDocumentNrOfPersonsEntered.invalid &&
              !(
                controls.travelDocumentNrOfPersonsEntered.hasError('max') ||
                controls.travelDocumentNrOfPersonsEntered.hasError('min')
              )
            ">
            {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-NR-OF-PERSONS-ENTERED.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.travelDocumentNrOfPersonsEntered) }}
          </mat-error>
          <mat-error
            *ngIf="
              controls.travelDocumentNrOfPersonsEntered.hasError('max') ||
              controls.travelDocumentNrOfPersonsEntered.hasError('min')
            ">
            {{ getErrorMessage(controls.travelDocumentNrOfPersonsEntered) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="standard-form-field travelDocumentIssueDate-field required-field">
        <label
          for="travelDocumentIssueDateInput"
          [class.invalid]="controls.travelDocumentIssueDate.touched && controls.travelDocumentIssueDate.invalid">
          {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-ISSUE-DATE.MAIN-LABEL' | translate }} *
        </label>
        <mat-form-field appearance="outline" (click)="travelDocumentIssueDatePicker.open()">
          <input
            matInput
            (focus)="travelDocumentIssueDatePicker.open()"
            id="travelDocumentIssueDateInput"
            #applicantIssueDate
            [matDatepicker]="travelDocumentIssueDatePicker"
            placeholder="{{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-ISSUE-DATE.PLACEHOLDER' | translate }}"
            [min]="minValidDate"
            [max]="maxValidDate"
            [required]="true"
            [readonly]="true"
            [formControl]="controls.travelDocumentIssueDate" />
          <mat-error *ngIf="controls.travelDocumentIssueDate.invalid">
            {{ getErrorMessage(controls.travelDocumentIssueDate) }}
          </mat-error>
          <mat-datepicker-toggle matSuffix [for]="travelDocumentIssueDatePicker"></mat-datepicker-toggle>
          <mat-datepicker
            #travelDocumentIssueDatePicker
            [startView]="'month'"
            [startAt]="'1985'"
            [calendarHeaderComponent]="pickerHeader"
            (closed)="applicantIssueDate.blur()">
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div class="standard-form-field travelDocumentExpiryDate-field required-field">
        <label
          for="travelDocumentExpiryDateInput"
          [class.invalid]="controls.travelDocumentExpiryDate.touched && controls.travelDocumentExpiryDate.invalid">
          {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-EXPIRY-DATE.MAIN-LABEL' | translate }} *
        </label>
        <mat-form-field appearance="outline" (click)="travelDocumentExpiryDatePicker.open()">
          <input
            matInput
            (focus)="travelDocumentExpiryDatePicker.open()"
            id="travelDocumentExpiryDateInput"
            #applicantExpiryDate
            [matDatepicker]="travelDocumentExpiryDatePicker"
            placeholder="{{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-EXPIRY-DATE.PLACEHOLDER' | translate }}"
            [min]="minValidDate"
            [max]="maxValidFutureDate"
            [required]="true"
            [readonly]="true"
            [formControl]="controls.travelDocumentExpiryDate" />
          <mat-error *ngIf="controls.travelDocumentExpiryDate.invalid">
            {{ getErrorMessage(controls.travelDocumentExpiryDate) }}
          </mat-error>
          <mat-datepicker-toggle matSuffix [for]="travelDocumentExpiryDatePicker"></mat-datepicker-toggle>
          <mat-datepicker
            #travelDocumentExpiryDatePicker
            [startView]="'month'"
            [startAt]="'1985'"
            [calendarHeaderComponent]="pickerHeader"
            (closed)="applicantExpiryDate.blur()">
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div class="standard-form-field widened-field travelDocumentIssuingAuthority-field required-field">
        <label
          for="travelDocumentIssuingAuthorityInput"
          [class.invalid]="
            controls.travelDocumentIssuingAuthority.touched && controls.travelDocumentIssuingAuthority.invalid
          ">
          {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-ISSUING-AUTHORITY.MAIN-LABEL' | translate }}*
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="travelDocumentIssuingAuthorityInput"
            type="text"
            maxLength="100"
            placeholder="{{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-ISSUING-AUTHORITY.PLACEHOLDER' | translate }}"
            [required]="true"
            [formControl]="controls.travelDocumentIssuingAuthority"
            (keydown.Tab)="lastFieldTabPressed.emit(); $event.preventDefault(); $event.stopPropagation()" />
          <mat-error *ngIf="controls.travelDocumentIssuingAuthority.invalid">
            {{ 'PERSONAL_DATA_FORM.TRAVEL-DOCUMENT-ISSUING-AUTHORITY.INFIELD-LABEL' | translate }}
            {{ getErrorMessage(controls.travelDocumentIssuingAuthority) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
