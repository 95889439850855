import { Pagination, SystemSettings, User } from '@interfaces';
import { Product, ProductHistory } from 'src/app/_interfaces/product';

export const defaultPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'surname',
  orderDir: 'desc',
  count: 0,
};

export type SystemSettingsState = Readonly<{
  loading: boolean;
  errorMessage: string | null;
  systemSettings: SystemSettings;
  loadingAdmins: boolean;
  loadedAdmins: User[];
  loadedAdminsPagination: Pagination;
  loadedAdminData: User | null;
  selfRegisterActive: boolean;

  products: Product[];
  productHistory: ProductHistory[];
  loadingOnlineUsers: boolean;
  loadedOnlineUsers: User[];
  loadedOnlineUsersPagination: Pagination;
  loadedOnlineUserData: User | null;
}>;

export const initialState: SystemSettingsState = {
  loading: false,
  errorMessage: null,
  systemSettings: null,
  loadingAdmins: false,
  loadedAdmins: [],
  loadedAdminsPagination: { ...(defaultPagination as Pagination) },
  loadedAdminData: null,
  selfRegisterActive: false,
  products: [],
  productHistory: [],
  loadingOnlineUsers: false,
  loadedOnlineUsers: [],
  loadedOnlineUsersPagination: { ...(defaultPagination as Pagination) },
  loadedOnlineUserData: null,
};
