<mat-dialog-content>
  <div class="close-wrapper">
    <button mat-icon-button aria-label="Close button" tabindex="-1">
      <mat-icon (click)="close()">close</mat-icon>
    </button>
  </div>
  <div class="company-logo-wrapper" *ngIf="(myself$ | async)?.company?.brandingLogo; else SimpleIcon">
    <app-company-logo></app-company-logo>
  </div>
  <ng-template #SimpleIcon>
    <div class="info-icon-wrapper">
      <img src="https://kartapobytu.app/kp/info-img.png" />
    </div>
  </ng-template>

  <div class="message-wrapper" *ngIf="!hasAssistantService && !hasFullHandling">
    <h1 [innerHTML]="'MODE_CHANGED_MODAL.BASIC.YOUR_APPLICATION_IN_POST_MODE' | translate"></h1>
    <h1 [innerHTML]="'MODE_CHANGED_MODAL.BASIC.VISIT_DATE_EXPLANATION' | translate"></h1>
    <h1 [innerHTML]="'MODE_CHANGED_MODAL.BASIC.MORE_INSTRUCTIONS' | translate"></h1>
  </div>

  <div class="message-wrapper" *ngIf="hasAssistantService && !hasFullHandling">
    <h1 [innerHTML]="'MODE_CHANGED_MODAL.ASSISTANT.YOUR_APPLICATION_IN_POST_MODE' | translate"></h1>
    <h1 [innerHTML]="'MODE_CHANGED_MODAL.ASSISTANT.VISIT_DATE_EXPLANATION' | translate"></h1>
    <h1 [innerHTML]="'MODE_CHANGED_MODAL.ASSISTANT.MORE_INSTRUCTIONS' | translate"></h1>
  </div>

  <div class="message-wrapper" *ngIf="hasFullHandling">
    <h1 [innerHTML]="'MODE_CHANGED_MODAL.FULL_HANDLING.YOUR_APPLICATION_IN_POST_MODE' | translate"></h1>
    <h1 [innerHTML]="'MODE_CHANGED_MODAL.FULL_HANDLING.CONSULTANT_CHANGED_MODE_TO_POST_MODE' | translate"></h1>
  </div>
</mat-dialog-content>
