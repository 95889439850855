<div
  class="service-item"
  [class]="{
    large: mode === PaidTags.LARGE,
    small: mode === PaidTags.SMALL,
    icon_only: mode === PaidTags.ICON_ONLY,
    'process-created-manually': !userProcess.purchaseId,
    'process-paid': userProcess.purchaseId
  }"
  [matTooltipDisabled]="mode !== PaidTags.ICON_ONLY"
  [matTooltip]="
    userProcess.purchaseId
      ? ('MANAGEMENT.APPLICATION_PAID' | translate)
      : ('MANAGEMENT.APPLICATION_CREATED_MANUALLY' | translate)
  ">
  <mat-icon fontSet="material-icons-outlined">{{ userProcess.purchaseId ? 'paid' : 'money_off' }}</mat-icon>
  <ng-container *ngIf="mode !== PaidTags.ICON_ONLY">
    {{
      userProcess.purchaseId
        ? ('MANAGEMENT.APPLICATION_PAID' | translate)
        : ('MANAGEMENT.APPLICATION_CREATED_MANUALLY' | translate)
    }}
  </ng-container>
</div>
