import { createAction, props } from '@ngrx/store';
import {
  CreateInstructionItemOpts,
  CustomInstructionItem,
  InstructionItem,
  InstructionItemInProcess,
  SearchItemsInProcessesOpts,
  ToggleItemsInProcessOpts,
  UpdateInstructionItemOpts,
  UpdateItemInProcessOpts,
  UpdateItemsOrderOpts,
} from '@interfaces';

export const getAllInstructionItems = createAction('[Instruction-Item] Get All Instruction Items');
export const getAllInstructionItemsSuccess = createAction(
  '[Instruction-Item] Get All Instruction Items Success',
  props<{ instructionItems: InstructionItem[] }>()
);
export const getAllInstructionItemsError = createAction(
  '[Instruction-Item] Get All Instruction Items Error',
  props<{ errorMessage: string }>()
);

export const getInstructionItem = createAction('[Instruction-Item] Get Instruction Item', props<{ id: string }>());
export const getInstructionItemSuccess = createAction(
  '[Instruction-Item] Get Instruction Item Success',
  props<{ instructionItem: InstructionItem }>()
);
export const getInstructionItemError = createAction(
  '[Instruction-Item] Get Instruction Item Error',
  props<{ errorMessage: string }>()
);

export const createInstructionItem = createAction(
  '[Instruction-Item] Create Instruction item',
  props<{ opts: CreateInstructionItemOpts }>()
);
export const createInstructionItemSuccess = createAction(
  '[Instruction-Item] Create Instruction item Success',
  props<{ createdInstructionItem: InstructionItem }>()
);
export const createInstructionItemError = createAction(
  '[Instruction-Item] Add Instruction item Error',
  props<{ errorMessage: string }>()
);

export const updateInstructionItem = createAction(
  '[Instruction-Item] Update Instruction item',
  props<{ opts: UpdateInstructionItemOpts }>()
);
export const updateInstructionItemSuccess = createAction(
  '[Instruction-Item] Update Instruction item Success',
  props<{ updatedInstructionItem: InstructionItem }>()
);
export const updateInstructionItemError = createAction(
  '[Instruction-Item] Update Instruction item Error',
  props<{ errorMessage: string }>()
);

export const removeInstructionItem = createAction(
  '[Instruction-Item] Remove Instruction item',
  props<{ id: string }>()
);
export const removeInstructionItemSuccess = createAction(
  '[Instruction-Item] Remove Instruction item Success',
  props<{ removedInstructionItemId: string }>()
);
export const removeInstructionItemError = createAction(
  '[Instruction-Item] Remove Instruction item Error',
  props<{ errorMessage: string }>()
);

export const toggleItemsInProcess = createAction(
  '[Instruction-Item] Toggle Items In Process',
  props<{ opts: ToggleItemsInProcessOpts }>()
);
export const toggleItemsInProcessSuccess = createAction(
  '[Instruction-Item] Toggle Items In Process Success',
  props<{ updatedItems: InstructionItemInProcess[] }>()
);
export const toggleItemsInProcessError = createAction(
  '[Instruction-Item] Toggle Items In Process Error',
  props<{ errorMessage: string }>()
);

export const updateItemInProcess = createAction(
  '[Instruction-Item] Update Instruction Item in Process',
  props<{ opts: UpdateItemInProcessOpts }>()
);
export const updateItemInProcessSuccess = createAction(
  '[Instruction-Item] Update Instruction Item in Process Success',
  props<{ updatedItemInProcess: InstructionItemInProcess }>()
);
export const updateItemInProcessError = createAction(
  '[Instruction-Item] Update Instruction Item in Process Error',
  props<{ errorMessage: string }>()
);

export const searchItemsInProcesses = createAction(
  '[Instruction-Item] Search Instruction Items in Processes',
  props<{ opts: SearchItemsInProcessesOpts }>()
);
export const searchItemsInProcessesSuccess = createAction(
  '[Instruction-Item] Search Instruction Items in Processes Success',
  props<{ itemsInProcesses: InstructionItemInProcess[] }>()
);
export const searchItemsInProcessesError = createAction(
  '[Instruction-Item] Search Instruction Items in Processes Error',
  props<{ errorMessage: string }>()
);

export const updateItemsOrder = createAction(
  '[Instruction-Item] Update Items Order',
  props<{ opts: UpdateItemsOrderOpts[] }>()
);
export const updateItemsOrderSuccess = createAction(
  '[Instruction-Item] Update Items Order Success',
  props<{ itemsInProcess: InstructionItemInProcess[] }>()
);
export const updateItemsOrderError = createAction(
  '[Instruction-Item] Update Items Order Error',
  props<{ errorMessage: string }>()
);

export const getItemsListForCustomization = createAction(
  '[Instruction-Items-List] Get Items list for customization',
  props<{ userProcessId: string }>()
);
export const getItemsListForCustomizationSuccess = createAction(
  '[Instruction-Items-List] Get Items list for customization Success',
  props<{ itemsList: CustomInstructionItem[] | InstructionItemInProcess[] }>()
);
export const getItemsListForCustomizationError = createAction(
  '[Instruction-Items-List] Get Items list for customization Error',
  props<{ errorMessage: any }>()
);

// returns same as getItemsListForCustomizationSuccess | getItemsListForCustomizationError
export const setUseCustomList = createAction(
  '[Instruction-Items-List] Set use custom list',
  props<{ userProcessId: string; useCustomList: boolean }>()
);
// returns same as getItemsListForCustomizationSuccess | getItemsListForCustomizationError
export const resetItemsInCustomItemsList = createAction(
  '[Instruction-Items-List] Reset items on custom list',
  props<{ userProcessId: string }>()
);

export const setEnabledItemInCustomList = createAction(
  '[Instruction-Items-List] Set enabled item on custom items list',
  props<{ userProcessId: string; itemId: string; enabled: boolean }>()
);
export const setEnabledItemInCustomListSuccess = createAction(
  '[Instruction-Items-List] Set enabled item on custom items list Success',
  props<{ updatedItem: CustomInstructionItem }>()
);
export const setEnabledItemInCustomListError = createAction(
  '[Instruction-Items-List] Set enabled item on custom items list Error',
  props<{ errorMessage: any }>()
);

export const setEnabledFees = createAction(
  '[Instruction-Items-List] Set Enabled Fees in custom items list',
  props<{ userProcessId: string; enabled: boolean }>()
);
export const setEnabledFeesSuccess = createAction(
  '[Instruction-Items-List] Set Enabled Fees in custom items list Success',
  props<{ userProcessId: string; showFeesOnInstructionItemsList: boolean }>()
);
export const setEnabledFeesError = createAction(
  '[Instruction-Items-List] Set Enabled Fees in custom items list Error',
  props<{ errorMessage: any }>()
);
