import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company, GetCompanyEmployeesProcesses, GetCompanyPayload, PaginatedResponse, UserProcess } from '@interfaces';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyHttpService {
  private API_URL = environment.API_URL;
  private defaultOpts = { withCredentials: true };

  constructor(private http: HttpClient) {}

  public getCompanies(payload: GetCompanyPayload): Observable<PaginatedResponse<Company>> {
    const url = `${this.API_URL}/companies/search`;
    return this.http.post<PaginatedResponse<Company>>(url, payload);
  }

  public getCompany(companyId: string): Observable<Company> {
    const url = `${this.API_URL}/companies/${companyId}`;
    return this.http.get<Company>(url);
  }

  public createCompany(company: Company): Observable<Company> {
    const url = `${this.API_URL}/companies`;
    return this.http.post<Company>(url, company);
  }

  public updateCompany(companyId: string, company: Company): Observable<Company> {
    const url = `${this.API_URL}/companies/${companyId}`;
    return this.http.patch<Company>(url, company, this.defaultOpts);
  }

  public removeCompany(companyId: string): Observable<{ success: boolean }> {
    const url = `${this.API_URL}/companies/${companyId}`;
    return this.http.delete<{ success: boolean }>(url, this.defaultOpts);
  }

  public setIsActiveCompany(companyId: string, isActive: boolean): Observable<Company> {
    const url = `${this.API_URL}/companies/${companyId}/${isActive}`;
    return this.http.get<Company>(url);
  }

  public getCompanyEmployeesProcesses(
    payload: GetCompanyEmployeesProcesses
  ): Observable<PaginatedResponse<Partial<UserProcess>>> {
    const url = `${this.API_URL}/managers/employees-processes/search`;
    return this.http.post<PaginatedResponse<Partial<UserProcess>>>(url, payload);
  }
}
