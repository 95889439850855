import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AvailableLanguages } from '@constants';
import { User, UserProcess, UserProcessMode, UserProcessModes } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ManagementFacade } from '@state/management';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'app-long-resi-step6',
  templateUrl: './long-resi-step6.component.html',
  styleUrls: ['./long-resi-step6.component.scss'],
})
export class LongResiStep6Component implements OnInit, AfterViewInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() mode: 'foreigner' | 'employee';
  @Input() user: User;
  @Input() userProcess: UserProcess;
  @Output() firstInputShiftTabPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() lastFieldTabPressed: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() toggleVerificationOfPersonalDetails: EventEmitter<void> = new EventEmitter();

  public controls: { [key: string]: FormControl } = {};
  public currentLang: AvailableLanguages;

  public UserProcessModes = UserProcessModes;
  public voivodeshipSupportedModes = [...this.UserProcessModes];

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly translateService: TranslateService,
    private readonly confirmation: ConfirmationModalService,
    private readonly managementFacade: ManagementFacade
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang as AvailableLanguages;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
    });

    Object.keys(this.formGroup.controls).forEach(fieldName => {
      this.controls[fieldName] = this.formGroup.controls[fieldName] as FormControl;
    });

    if (this.userProcess?.personalDetails?.residenceVoivodeshipDetails?.supportedModes) {
      this.voivodeshipSupportedModes = this.userProcess.personalDetails.residenceVoivodeshipDetails.supportedModes;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.hasAnyFieldAnyValue(this.formGroup.controls)) {
        this.formGroup.markAllAsTouched();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public firstInputKeydown($event: KeyboardEvent): void {
    if (!$event.shiftKey) {
      return;
    }

    if ($event.key?.toLowerCase() !== 'tab') {
      return;
    }

    this.firstInputShiftTabPressed.emit();
  }

  public emitLastFieldTabPressed($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.lastFieldTabPressed.emit($event);
  }

  // control.errors always contains max 1 error at a time
  // assumes that control.invalid === true and errors are {}
  public getErrorMessage(control: FormControl): string {
    const [validatorName, error]: [string, { errorKey: string; allowedVal: string }] = Object.entries(
      control.errors
    )[0];

    if (validatorName === 'required') {
      return this.translateService.instant(`FORM-VALIDATION.IS_REQUIRED`);
    }
    if (validatorName === 'pattern') {
      return this.translateService.instant(`FORM-VALIDATION.WRONG_FORMAT`);
    }

    return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`, { allowedVal: error.allowedVal });
  }

  public async setUserProcessMode(modeToSet: UserProcessMode): Promise<void> {
    // allowed only for employees
    if (this.mode !== 'employee') {
      return;
    }

    const currMode = this.userProcess?.mode;
    if (!currMode || currMode === modeToSet || !this.voivodeshipSupportedModes.includes(modeToSet)) {
      return;
    }

    this.confirmation
      .open({
        confirmationToTranslate: 'NT.ARE_YOU_SURE_CHANGE_USER_PROCESS_MODE',
        translateParams: { mode: this.translateService.instant('NT.USER_PROCESS_MODES.' + modeToSet) },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.managementFacade.updateUserProcessBasicData(this.userProcess.id, {
          userId: this.userProcess.userId,
          mode: modeToSet,
        });
      });
  }

  private hasAnyFieldAnyValue(controls: { [key: string]: AbstractControl }): boolean {
    return Object.keys(controls).some(controlName => {
      const val = controls[controlName].value;

      if (Array.isArray(val) && val.length > 0) {
        return true;
      }

      return val !== '' && val !== undefined && val !== null;
    });
  }
}
