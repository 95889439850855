<div class="outer-wrapper">
  <div
    class="banner-wrapper"
    *ngIf="userLang && LINK_TARGET"
    (click)="openTargetInNewPage()"
    [class.fix-ru]="userLang === 'ru'"
    [class.fix-uk]="userLang === 'uk'"
    [class.fix-en]="userLang === 'en'"
    [class.fix-pl]="userLang === 'pl'">
    <div class="column align-end">
      <video muted [autoplay]="true" autoplay loop onloadedmetadata="this.muted = true" oncanplay="this.play()">
        <source [src]="'https://kartapobytu.app/kp/anim-simple-translations-fixed-bg.mp4'" type="video/mp4" />
      </video>
    </div>
    <div class="column align-start">
      <div class="slogan-container" [innerHTML]="'TRANSLATIONS_BANNER.SLOGAN' | translate"></div>
      <div
        class="disclaimer-container"
        *ngIf="deviceTypeDetected === 'DESKTOP' || deviceTypeDetected === 'TABLET'"
        [innerHTML]="'TRANSLATIONS_BANNER.DISCLAIMER' | translate"></div>
    </div>
  </div>
  <div
    class="disclaimer-container"
    *ngIf="deviceTypeDetected === 'PHONE'"
    [innerHTML]="'TRANSLATIONS_BANNER.DISCLAIMER' | translate"></div>
</div>
