import {
  CreateDiscountCodeOpts,
  DiscountCode,
  EditDiscountCodeOpts,
  PaginatedResponse,
  SearchDiscountCodesOpts,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const searchDiscountCodes = createAction(
  '[Discount Codes] Search Discount Codes',
  props<{ opts: SearchDiscountCodesOpts }>()
);
export const searchDiscountCodesSuccess = createAction(
  '[Discount Codes] SearchDiscount Codes Success',
  props<{ discountCodes: PaginatedResponse<DiscountCode> }>()
);
export const searchDiscountCodesError = createAction('[Discount Codes] Search Discount Codes Error');

export const getDiscountCodeDetails = createAction(
  '[Discount Codes] Get Discount Code Details',
  props<{ discountCodeId: string }>()
);
export const getDiscountCodeDetailsSuccess = createAction(
  '[Discount Codes] Get Discount Code Details Success',
  props<{ discountCode: DiscountCode }>()
);
export const getDiscountCodeDetailsError = createAction('[Discount Codes] Get Discount Code Details Error');

export const checkIfDiscountCodeExists = createAction(
  '[Discount Codes] Check if discount code exists',
  props<{ code: string }>()
);
export const checkIfDiscountCodeExistsSuccess = createAction(
  '[Discount Codes] Check if discount code exists success',
  props<{ exists: boolean }>()
);
export const checkIfDiscountCodeExistsError = createAction('[Discount Codes] Check if discount code exists error');

export const createDiscountCode = createAction(
  '[Discount Codes] Create Discount Code',
  props<{ opts: CreateDiscountCodeOpts }>()
);
export const createDiscountCodeSuccess = createAction(
  '[Discount Codes] Create Discount Code Success',
  props<{ discountCode: DiscountCode }>()
);
export const createDiscountCodeError = createAction('[Discount Codes] Create Discount Code Error');

export const editDiscountCode = createAction(
  '[Discount Codes] Edit Discount Code',
  props<{ opts: EditDiscountCodeOpts }>()
);
export const editDiscountCodeSuccess = createAction(
  '[Discount Codes] Edit Discount Code Success',
  props<{ discountCode: DiscountCode }>()
);
export const editDiscountCodeError = createAction('[Discount Codes] Edit Discount Code Error');

export const setActiveDiscountCode = createAction(
  '[Discount Codes] Set active discount code',
  props<{ discountCodeId: string; setActive: 'activate' | 'deactivate' }>()
);
export const setActiveDiscountCodeSuccess = createAction(
  '[Discount Codes] Set active discount code Success',
  props<{ discountCode: DiscountCode }>()
);
export const setActiveDiscountCodeError = createAction('[Discount Codes] Set active discount code Error');
