<div class="page-wrapper" *ngIf="myself$ | async as foreigner">
  <div class="form-container">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="head-message">
          <h1>{{ 'APPLICATION.ADD_NEW_APPLICATION' | translate }}</h1>
        </div>
      </div>
      <div class="form-row">
        <div class="head-message">
          {{ 'PRE_SURVEY.CREATION_FORM.HEAD_MESSAGE' | translate }}
        </div>
      </div>
      <!-- <div class="form-row">
          <div *ngIf="availableProcesses$ | async as availableProcesses" class="applicationType-field">
            <div class="info-label">
              <label class="ng-standard-select-label">{{ 'NEW_APPLICATION_FORM.APPLICATION_TYPE' | translate }}</label>
              <mat-icon
                class="info-suffix-icon"
                matSuffix
                matTooltip="{{ 'NEW_APPLICATION_FORM.CANNOT_CHANGE_IT_LATER_EXPLANATION' | translate }}"
                matTooltipClass="personalDataFormTooltip">
                info_outline
              </mat-icon>
            </div>
            <div class="ng-form-group">
              <ng-select
                [searchable]="false"
                appearance="legacy"
                formControlName="processId"
                [ngClass]="['user_process_form-select']"
                required>
                <ng-option *ngFor="let process of availableProcesses; index as processIndex" [value]="process.id">
                  {{ processIndex + 1 }}. {{ 'PROCESSES.' + process.key | translate }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div> -->
      <!-- <div class="form-row">
          <div class="applicationType-field">
            <div class="info-label">
              <label class="ng-standard-select-label">
                {{ 'NEW_APPLICATION_FORM.APPLICATION_PURPOSE_COMMENT' | translate }}
              </label>
              <mat-icon
                class="info-suffix-icon"
                matSuffix
                matTooltip="{{ 'NEW_APPLICATION_FORM.PURPOSE_COMMMENT_MUST_BE_IN_POLISH' | translate }}"
                matTooltipClass="personalDataFormTooltip">
                info_outline
              </mat-icon>
            </div>
            <div class="ng-form-group">
              <input
                *ngIf="form.get('purposeComment').disabled; else elseBlock"
                class="user_process_form-input"
                placeholder="{{ 'NEW_APPLICATION_FORM.NOT_USED_WITH_THIS_PROCESS_TYPE' | translate }}"
                type="text"
                formControlName="purposeComment" />
              <ng-template #elseBlock>
                <input class="user_process_form-input" type="text" formControlName="purposeComment" />
              </ng-template>
            </div>
          </div>
        </div> -->
      <div class="form-column submit-column">
        <button
          (click)="submitForm()"
          [disabled]="(form.dirty && form.invalid) || (loading$ | async)"
          mat-raised-button
          class="submit-button"
          color="accent">
          {{ 'NEW_APPLICATION_FORM.SUBMIT' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
