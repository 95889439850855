import { Component, EventEmitter, Output } from '@angular/core';
import { UserAuthFacade } from '@state/user-auth';

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
})
export class CompanyLogoComponent {
  public myself$ = this.userAuthFacade.myself$;

  @Output() logoClicked: EventEmitter<void> = new EventEmitter();

  constructor(private readonly userAuthFacade: UserAuthFacade) {}
}
