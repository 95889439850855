import { createAction, props } from '@ngrx/store';
import { Annex, CreateAnnexOpts, GetAnnexesOpts, MOSSpeciality, RemoveAnnexOpts, UpdateAnnexOpts } from '@interfaces';

export const getAnnexes = createAction('[Annex] Get Annexes in User Process', props<{ opts: GetAnnexesOpts }>());
export const getAnnexesSuccess = createAction(
  '[Annex] Get Annexes in User Process Success',
  props<{ annexes: Annex[]; userProcessId: string }>()
);
export const getAnnexesError = createAction(
  '[Annex] Get Annexes in User Process Error',
  props<{ errorMessage: any }>()
);

export const createAnnex = createAction('[Annex] Create Annex in User Process', props<{ opts: CreateAnnexOpts }>());
export const createAnnexSuccess = createAction(
  '[Annex] Create Annex in User Process Success',
  props<{ annex: Annex; userProcessId: string }>()
);
export const createAnnexError = createAction(
  '[Annex] Create Annex in User Process Error',
  props<{ errorMessage: any }>()
);

export const updateAnnex = createAction('[Annex] Update Annex In User Process', props<{ opts: UpdateAnnexOpts }>());
export const updateAnnexSuccess = createAction(
  '[Annex] Update Annex In User Process Success',
  props<{ annex: Annex; userProcessId: string }>()
);
export const updateAnnexError = createAction(
  '[Annex] Update Annex In User Process Error',
  props<{ errorMessage: any }>()
);

export const removeAnnex = createAction('[Annex] Remove Annex in User Process', props<{ opts: RemoveAnnexOpts }>());
export const removeAnnexSuccess = createAction(
  '[Annex] Remove Annex in User Process Success',
  props<{ annexId: string; userProcessId: string }>()
);
export const removeAnnexError = createAction(
  '[Annex] Remove Annex in User Process Error',
  props<{ errorMessage: any }>()
);

export const getSpecialities = createAction('[Annex] Get Specialities');

export const getSpecialitiesSuccess = createAction(
  '[Annex] Get Specialities Success',
  props<{ specialities: MOSSpeciality[] }>()
);

export const getSpecialitiesError = createAction('[Annex] Get Specialities Error', props<{ errorMessage: any }>());
