import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AvailableLanguages, SUPPORTED_LANGS } from '@constants';
import {
  AllDeviceInfo,
  CustomInstructionItem,
  InstructionItem,
  InstructionItemInProcess,
  User,
  UserProcess,
  Voivodeship,
} from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { InstructionItemsFacade } from '@state/instruction-items';

import { ModalOpts, ModalResult } from './instructions-personalization-modal.service';
import { ReplaySubject, map, take, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { debounce } from 'lodash-es';
import { DeviceInfoService } from '@shared/device-info/device-info.service';

@Component({
  selector: 'app-instructions-personalization-modal',
  templateUrl: './instructions-personalization-modal.component.html',
  styleUrls: ['./instructions-personalization-modal.component.scss'],
})
export class InstructionsPersonalizationModalComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('contentWrapper') contentWrapper: ElementRef;

  public loading$ = this.itemsFacade.loading$;
  public itemsList: (CustomInstructionItem | InstructionItemInProcess)[] = [];

  public availableLangs = SUPPORTED_LANGS;
  public currLang: AvailableLanguages;

  // VIEW helpers
  public applicantName: string;
  public userName: string;
  public companyName: string;
  public voivo: Voivodeship | null;
  public usingCustomList: boolean = false;
  public showFees: boolean = true;

  public listFilterValue = '';
  public switchPillsOptions = [
    { label: 'NT.USE_CUSTOM_INSTRUCTION_ITEMS_LIST', value: true },
    { label: 'NT.USE_DEFAULT_INSTRUCTION_ITEMS_LIST', value: false },
  ];

  public showTopbarShadow = false;

  public deviceInfo: AllDeviceInfo;

  private paymentsItem: InstructionItem = {
    id: 'PAYMENTS-ITEM',
    key: '999_PAYMENTS_ITEM',
    name: {
      pl: 'Potwierdzenie opłat skarbowych',
      en: 'Proof of stamp duty payment',
      uk: 'Proof of stamp duty payment',
      ru: 'Proof of stamp duty payment',
    },
    comment: {
      pl: '--- UZUPEŁNIANE W WYGENEROWANYM DOKUMENCIE --- ',
      en: '--- FILLED IN GENERATED DOCUMENT ---',
      uk: '--- FILLED IN GENERATED DOCUMENT ---',
      ru: '--- FILLED IN GENERATED DOCUMENT ---',
    },
    numberOfCopies: {
      pl: '1x oryginał potwierdzenia z banku/poczty, lub wydruk potwierdzenia zapłaty z bankowości online.',
      en: '1x original confirmation from the bank/post office, or a printed confirmation of payment from online banking.',
      uk: '1x original confirmation from the bank/post office, or a printed confirmation of payment from online banking.',
      ru: '1x original confirmation from the bank/post office, or a printed confirmation of payment from online banking.',
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOpts,
    public dialogRef: MatDialogRef<InstructionsPersonalizationModalComponent, ModalResult>,
    private readonly itemsFacade: InstructionItemsFacade,
    private readonly snackService: SnackbarService,
    private readonly translateService: TranslateService,
    private readonly confirmationService: ConfirmationModalService,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.currLang = this.translateService.currentLang as AvailableLanguages;
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(langChangeEvent => {
      this.currLang = langChangeEvent.lang as AvailableLanguages;
    });
    const { userProcess, foreigner } = this.data;
    if (!userProcess?.id || !userProcess.personalDetails || !foreigner?.id || !foreigner?.name) {
      this.snackService.showError('MISSING_REQUIRED_DATA');
      return;
    }

    this.itemsFacade.itemsListForCustomization$
      .pipe(
        map(itemsList => {
          if (!itemsList?.length) {
            return itemsList;
          }

          const newList = [...itemsList];
          newList.sort((itemA, itemB) => {
            return itemA.indexOnTheList - itemB.indexOnTheList;
          });

          newList.push({
            id: 'PAYMENTS-ITEM',
            enabled: this.showFees,
            indexOnTheList: 999,
            instructionItem: { ...this.paymentsItem },
          } as any as CustomInstructionItem);

          return newList;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(itemsList => {
        this.itemsList = itemsList;
      });

    this.setupBasicData(userProcess, foreigner);
    this.itemsFacade.getItemsListForCustomaization(this.data.userProcess.id);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    const htmlElem = this.contentWrapper?.nativeElement as HTMLElement;
    // ensure no duplicate eventListeners
    // we cannot do this in ngOnDestroy because elem. dos not exist at that point
    if (htmlElem.removeAllListeners) {
      htmlElem.removeAllListeners('scroll');
    }

    const debouncedOnScroll = debounce((event: Event) => {
      const { scrollTop } = event.target as HTMLElement;
      this.showTopbarShadow = scrollTop > 0;
    }, 10);

    // making sure that it exists
    // if not - do not interrupt user - this is only some fancy shadow below topbar
    if (htmlElem.addEventListener) {
      htmlElem.addEventListener('scroll', debouncedOnScroll);
    }
  }

  public close(): void {
    this.dialogRef.close({ usingCustomInstructionItemsList: this.usingCustomList });
  }

  public save(): void {
    this.dialogRef.close({ usingCustomInstructionItemsList: this.usingCustomList });
  }

  public useCustomListChanged($event: { label: string; value: boolean }): void {
    this.listFilterValue = '';
    this.usingCustomList = $event.value;
    this.itemsFacade.setUseCustomList({ userProcessId: this.data.userProcess.id, useCustomList: $event.value });
  }

  public setItemEnabledInList($event: MatCheckboxChange, item: CustomInstructionItem | InstructionItemInProcess): void {
    // this is fake instruction-item - hoax to have possibility to show/hide fees item in the instructions pdf
    if (item.id === 'PAYMENTS-ITEM' || item.instructionItem.id === 'PAYMENTS-ITEM') {
      this.showFees = $event.checked;
      this.itemsFacade.setEnabledFees({ enabled: $event.checked, userProcessId: this.data.userProcess.id });
      item.enabled = this.showFees;
      return;
    }

    this.itemsFacade.setEnabledItemInCustomList({
      enabled: $event.checked,
      itemId: item.id,
      userProcessId: this.data.userProcess.id,
    });
  }

  public resetItemsOnTheList(): void {
    this.listFilterValue = '';
    this.confirmationService
      .open({
        confirmationToTranslate: 'NT.ALL_ITEMS_WILL_GET_INITIAL_VALUE_ARE_YOU_SURE',
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        const userProcessId = this.data.userProcess.id;
        this.showFees = true;
        this.itemsFacade.resetItemsInCustomItemsList(userProcessId);
        this.itemsFacade.setEnabledFees({ userProcessId, enabled: true });
      });
  }

  private setupBasicData(userProcess: UserProcess, user: User): void {
    const { name, surname } = userProcess.personalDetails;
    this.applicantName = '';

    if (name) {
      this.applicantName = name;
    }
    if (surname) {
      this.applicantName = `${this.applicantName} ${surname}`;
    }
    if (!this.applicantName?.length) {
      this.applicantName = userProcess.name;
    }

    this.listFilterValue = '';
    this.userName = user.name;
    this.companyName = user.company.name;
    this.voivo = userProcess.personalDetails.residenceVoivodeshipDetails || null;
    this.usingCustomList = userProcess.usingCustomInstructionItemsList;
    this.showFees = userProcess.showFeesOnInstructionItemsList;
  }
}
