import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SystemSettingsState } from './system-settings.state';

export const selectSystemSettingsState = createFeatureSelector<SystemSettingsState>('systemSettings');
export const selectSystemSettings = createSelector(selectSystemSettingsState, state => state.systemSettings);

export const selectLoadingAdmins = createSelector(selectSystemSettingsState, state => state.loadingAdmins);
export const selectLoadedAdmins = createSelector(selectSystemSettingsState, state => state.loadedAdmins);
export const selectLoadedAdminData = createSelector(selectSystemSettingsState, state => state.loadedAdminData);
export const selectLoading = createSelector(selectSystemSettingsState, state => state.loading);

export const selectErrorMessage = createSelector(selectSystemSettingsState, state => state.errorMessage);

export const selectLoadedAdminsPagination = createSelector(
  selectSystemSettingsState,
  state => state.loadedOnlineUsersPagination
);

export const selectSelfRegisterActive = createSelector(selectSystemSettingsState, state => state.selfRegisterActive);

export const selectProducts = createSelector(selectSystemSettingsState, state => state.products);
export const selectProductHistory = createSelector(selectSystemSettingsState, state => state.productHistory);

export const selectLoadedOnlineUsersPagination = createSelector(
  selectSystemSettingsState,
  state => state.loadedOnlineUsersPagination
);
export const selectLoadingOnlineUsers = createSelector(selectSystemSettingsState, state => state.loadingOnlineUsers);
export const selectLoadedOnlineUsers = createSelector(selectSystemSettingsState, state => state.loadedOnlineUsers);
