import {
  ActivateAccountPayload,
  InvoiceData,
  LoginErrorPayload,
  LoginPayload,
  SetNewPasswordPayload,
  UpdateAdminselfPayload,
  UpdateMyselfPayload,
  User,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const activateAccount = createAction(
  '[User-Auth] Activate Account',
  props<{ payload: ActivateAccountPayload }>()
);
export const activateAccountSuccess = createAction(
  '[User-Auth] Activate Account Success',
  props<{ emailSent: boolean }>()
);
export const activateAccountError = createAction(
  '[User-Auth] Activate Account Error',
  props<{ errorMessage: string }>()
);

export const login = createAction('[User-Auth] Login', props<{ payload: LoginPayload; redirect: string | null }>());
export const loginSuccess = createAction('[User-Auth] Login Success', props<{ redirect: string | null }>());
export const loginError = createAction('[User-Auth] Login Error', props<LoginErrorPayload>());

export const logout = createAction('[User-Auth] Logout', props<{ wasUnauthorized?: boolean }>());
export const logoutSuccess = createAction(
  '[User-Auth] Logout Success',
  props<{
    wasUnauthorized?: boolean;
    sessionTimedOutLogout?: boolean;
  }>()
);
export const logoutError = createAction('[User-Auth] Logout Error', props<{ error: any }>());

export const deleteAccount = createAction('[User-Auth] Delete account', props<{ password: string }>());
export const deleteAccountSuccess = createAction('[User-Auth] Delete account Success');
export const deleteAccountError = createAction('[User-Auth] Delete account Error', props<{ error: any }>());

export const getMyself = createAction('[User-Auth] Get Myself', props<{ redirect?: string }>());
export const getMyselfSuccess = createAction('[User-Auth] Get Myself Success', props<{ user: User }>());
export const getMyselfError = createAction('[User-Auth] Get Myself Error', props<{ redirect?: string; error: any }>());

export const updateMyself = createAction('[User-Auth] Update Myself', props<{ payload: UpdateMyselfPayload }>());
export const updateAdminself = createAction(
  '[User-Auth] Update Adminself',
  props<{ userId: string; payload: UpdateAdminselfPayload }>()
);
export const updateMyselfSuccess = createAction('[User-Auth] Update Myself Success', props<{ updatedUser: User }>());
export const updateMyselfError = createAction('[User-Auth] Update Myself Error', props<{ error: any }>());

export const getPasswordResetLink = createAction('[User-Auth] Get Password Reset Link', props<{ email: string }>());
export const getPasswordResetLinkSuccess = createAction(
  '[User-Auth] Get Password Reset Link Success',
  props<{ emailResult: boolean }>()
);
export const getPasswordResetLinkError = createAction(
  '[User-Auth] Get Password Reset Link Error',
  props<{ errorMessage: string }>()
);

export const setNewPassword = createAction('[User-Auth] Set New Password', props<{ payload: SetNewPasswordPayload }>());
export const setNewPasswordSuccess = createAction(
  '[User-Auth] Set New Password Success',
  props<{ success: boolean }>()
);
export const setNewPasswordError = createAction(
  '[User-Auth] Set New Password Error',
  props<{ errorMessage: string }>()
);

export const regenerateInitialsAvatar = createAction('[User-Auth] Regenerate Initials Avatar');
export const regenerateInitialsAvatarSuccess = createAction(
  '[User-Auth] Regenerate Initials Avatar Success',
  props<{ user: Partial<User> }>()
);
export const regenerateInitialsAvatarError = createAction(
  '[User-Auth] Regenerate Initials Avatar Error',
  props<{ errorMessage: any }>()
);

export const updateMyInvoiceData = createAction(
  '[User-Auth] Update My Invoice Data',
  props<{ invoiceData: InvoiceData }>()
);
export const updateMyInvoiceDataSuccess = createAction(
  '[User-Auth] Update My Invoice Data Success',
  props<{ invoiceData: InvoiceData }>()
);
export const updateMyInvoiceDataError = createAction('[User-Auth] Update My Invoice Data Error');
