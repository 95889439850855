import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { sanitize } from 'dompurify';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any): any {
    let sanitizedContent = sanitize(value, { ALLOWED_ATTR: [], ALLOWED_TAGS: ['br'], KEEP_CONTENT: true }).replace(
      /(?:\r\n|\r|\n)/g,
      '<br>'
    );
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
