import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { DownloadDocumentFromReviewOpts, UploadDocumentToReviewOpts } from '@interfaces';
import { AppState } from '@state';
import * as reviewActions from './review.actions';
import * as reviewSelectors from './review.selectors';

@Injectable()
export class ReviewFacade {
  public reviewItems$ = this.store.select(reviewSelectors.selectReviewItems);
  public loadingReviewItems$ = this.store.select(reviewSelectors.selectReviewItemsLoading);
  public downloadInProgress$ = this.store.select(reviewSelectors.selectDownloadInProgress);

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getMyDocumentsSentToReview(userProcessId: string): void {
    this.store.dispatch(reviewActions.getMyDocumentsSentToReview({ opts: { userProcessId } }));
  }

  public uploadDocumentToReview(opts: UploadDocumentToReviewOpts): void {
    this.store.dispatch(reviewActions.uploadDocumentToReview({ opts }));
  }

  public downloadDocumentFromReview(opts: DownloadDocumentFromReviewOpts): void {
    this.store.dispatch(reviewActions.downloadDocumentFromReview({ opts }));
  }

  public removeMyFile(opts: { userAssetId: string; fileOriginalName: string }): void {
    this.store.dispatch(reviewActions.removeMyFile({ opts }));
  }
}
