import { Action, createReducer, on } from '@ngrx/store';
import * as systemSettingsActions from './system-settings.actions';
import { SystemSettingsState, initialState } from './system-settings.state';

const reducer = createReducer(
  initialState,

  on(
    systemSettingsActions.getSystemSettings,
    systemSettingsActions.changePIN,
    systemSettingsActions.changeMainNotificationsRecipient,
    systemSettingsActions.changeAdditionalRecipients,
    systemSettingsActions.getAdmin,
    systemSettingsActions.removeAdmin,
    systemSettingsActions.updateAdmin,
    systemSettingsActions.createAdmin,
    systemSettingsActions.resendAdminActivation,
    systemSettingsActions.changeSecurityRecipients,
    systemSettingsActions.recreateAvatarsForAllUsers,
    systemSettingsActions.setSelfRegistrationStatus,
    systemSettingsActions.getSelfRegistrationStatus,
    systemSettingsActions.getProducts,
    systemSettingsActions.updateProduct,
    systemSettingsActions.createProduct,
    state => ({
      ...state,
      loading: true,
      errorMessage: null,
    })
  ),

  on(systemSettingsActions.getSystemSettingsSuccess, (state, { systemSettings }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    systemSettings: { ...systemSettings },
  })),

  on(systemSettingsActions.getSelfRegistrationStatusSuccess, (state, { isActive }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    selfRegisterActive: isActive,
  })),

  on(
    systemSettingsActions.changePINSuccess,
    systemSettingsActions.changeMainNotificationsRecipientSuccess,
    systemSettingsActions.changeAdditionalRecipientsSuccess,
    systemSettingsActions.changeSecurityRecipientsSuccess,
    systemSettingsActions.setSelfRegistrationStatusSuccess,
    (state, { updatedSystemSettings }) => ({
      ...state,
      loading: false,
      errorMessage: null,
      systemSettings: { ...updatedSystemSettings },
    })
  ),

  on(systemSettingsActions.getAdminSuccess, (state, { admin }) => ({
    ...state,
    loadedAdminData: admin,
    loading: false,
    errorMessage: null,
  })),

  on(systemSettingsActions.updateAdminSuccess, (state, { updatedAdmin }) => ({
    ...state,
    loadedAdminData: updatedAdmin,
    loadedAdmins: state.loadedAdmins.map(admin => (admin.id === updatedAdmin.id ? updatedAdmin : admin)),
    loading: false,
    errorMessage: null,
  })),

  on(systemSettingsActions.createAdminSuccess, (state, { createdAdmin }) => ({
    ...state,
    loadedAdminData: createdAdmin,
    loading: false,
    errorMessage: null,
  })),

  on(systemSettingsActions.removeAdminSuccess, (state, { removedAdminId }) => ({
    ...state,
    loadedAdmins: state.loadedAdmins.filter(admin => admin.id !== removedAdminId),
    loading: false,
    errorMessage: null,
  })),

  on(systemSettingsActions.resendAdminActivationSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),

  on(
    systemSettingsActions.getSystemSettingsError,
    systemSettingsActions.changePINError,
    systemSettingsActions.changeMainNotificationsRecipientError,
    systemSettingsActions.changeAdditionalRecipientsError,
    systemSettingsActions.getAdminError,
    systemSettingsActions.removeAdminError,
    systemSettingsActions.updateAdminError,
    systemSettingsActions.createAdminError,
    systemSettingsActions.resendAdminActivationError,
    systemSettingsActions.changeSecurityRecipientsError,
    systemSettingsActions.setSelfRegistrationStatusError,
    systemSettingsActions.getSelfRegistrationStatusError,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    })
  ),

  on(systemSettingsActions.getOnlineUsers, state => ({
    ...state,
    loadingAdmins: true,
    errorMessage: null,
  })),

  on(systemSettingsActions.getOnlineUsersSuccess, (state, { users }) => {
    return {
      ...state,
      loadedOnlineUsers: users.data,
      loadedOnlineUsersPagination: users.pagination,
      loadingOnlineUsers: false,
      errorMessage: null,
    };
  }),

  on(systemSettingsActions.getOnlineUsersError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingOnlineUsers: false,
  })),

  on(systemSettingsActions.getAdmins, state => ({
    ...state,
    loadingAdmins: true,
    errorMessage: null,
  })),

  on(systemSettingsActions.getAdminsSuccess, (state, { admins }) => ({
    ...state,
    loadedAdmins: admins.data,
    loadedAdminsPagination: admins.pagination,
    loadingAdmins: false,
    errorMessage: null,
  })),

  on(systemSettingsActions.getAdminsError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingAdmins: false,
  })),

  on(systemSettingsActions.recreateAvatarsForAllUsersSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),
  on(systemSettingsActions.recreateAvatarsForAllUsersError, state => ({
    ...state,
    loading: false,
    errorMessage: 'UANBLE_TO_RECREATE',
  })),

  on(systemSettingsActions.getProductsSuccess, (state, { products }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    products: structuredClone(products),
  })),
  on(systemSettingsActions.getProductsError, state => ({
    ...state,
    loading: false,
  })),
  on(systemSettingsActions.createProductSuccess, (state, { product }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    products: [structuredClone(product), ...(state.products || [])],
  })),
  on(systemSettingsActions.updateProductError, systemSettingsActions.createProductError, state => ({
    ...state,
    loading: false,
  })),
  on(systemSettingsActions.updateProductSuccess, (state, { updatedProduct }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    products: state.products.map(productInstate =>
      productInstate.id === updatedProduct.id ? structuredClone(updatedProduct) : productInstate
    ),
  })),
  on(systemSettingsActions.updateProductError, state => ({
    ...state,
    loading: false,
  })),
  on(systemSettingsActions.getProductHistory, (state, { productId }) => ({
    ...state,
    loading: true,
    errorMessage: null,
    productHistory: [],
  })),
  on(systemSettingsActions.getProductHistorySuccess, (state, { productHistory }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    productHistory: structuredClone(productHistory),
  })),
  on(systemSettingsActions.getProductHistoryError, (state, { productId }) => ({
    ...state,
    loading: false,
    productHistory: [],
  }))
);

export function systemSettingsReducer(state: SystemSettingsState | undefined, action: Action): SystemSettingsState {
  return reducer(state, action);
}
