import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from '@state';

import { RemoveAssetOpts, UploadPreSurveyAttachmentOpts } from '@interfaces';
import * as assetsActions from './user-assets.actions';
import * as selectors from './user-assets.selectors';

@Injectable()
export class UserAssetsFacade {
  public loading$ = this.store.select(selectors.selectLoading);
  public downloadingFile$ = this.store.select(selectors.selectDownloadingFile);

  public uploadPreSurveyAttachmentsSuccess$ = this.actions.pipe(
    ofType(assetsActions.uploadPreSurveyAttachmentsSuccess)
  );
  public uploadPreSurveyAttachmentsError$ = this.actions.pipe(ofType(assetsActions.uploadPreSurveyAttachmentsError));

  public removeAssetSuccess$ = this.actions.pipe(ofType(assetsActions.removeMyAssetSuccess));
  public removeAssetError$ = this.actions.pipe(ofType(assetsActions.removeMyAssetError));

  public downloadSuccess$ = this.actions.pipe(ofType(assetsActions.downloadMyAssetSuccess));
  public downloadError$ = this.actions.pipe(ofType(assetsActions.downloadMyAssetError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public downloadMyAsset(userAssetId: string): void {
    this.store.dispatch(assetsActions.downloadMyAsset({ userAssetId }));
  }
  public removeMyAsset(opts: RemoveAssetOpts): void {
    this.store.dispatch(assetsActions.removeMyAsset({ opts }));
  }
  public uploadPreSurveyAttachments(opts: UploadPreSurveyAttachmentOpts): void {
    this.store.dispatch(assetsActions.uploadPreSurveyAttachments({ opts }));
  }
}
