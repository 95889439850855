import { createAction, props } from '@ngrx/store';
import {
  CreateSupportMessageOpts,
  FindSupportChatsOpts,
  MarkSupportChatOpts,
  MultipleFilesUploadResult,
  PaginatedResponse,
  SupportChat,
  SupportChatMessage,
  UploadMessageAttachmentsOpts,
} from '@interfaces';

export const searchSupportChats = createAction(
  '[Support-Chat] Search Support Chats',
  props<{ opts: FindSupportChatsOpts; inBackground?: boolean }>()
);
export const searchSupportChatsSuccess = createAction(
  '[Support-Chat] Search Support Chats Success',
  props<{ supportChats: PaginatedResponse<SupportChat> }>()
);
export const searchSupportChatsError = createAction(
  '[Support-Chat] Search Support Chats Error',
  props<{ errorMessage: any }>()
);
export const loadMoreSupportChats = createAction(
  '[Support-Chat] Load More Support Chats',
  props<{ opts: FindSupportChatsOpts }>()
);
export const loadMoreSupportChatsSuccess = createAction(
  '[Support-Chat] Load More Support Chats Success',
  props<{ supportChats: PaginatedResponse<SupportChat> }>()
);
export const loadMoreSupportChatsError = createAction(
  '[Support-Chat] Load More Support Chats Error',
  props<{ errorMessage: any }>()
);

export const createSupportChat = createAction(
  '[Support-Chat] Create Support Chat',
  props<{ supportedUserId: string }>()
);
export const createSupportChatSuccess = createAction(
  '[Support-Chat] Create Support Chat Success',
  props<{ supportChat: SupportChat }>()
);
export const createSupportChatError = createAction(
  '[Support-Chat] Create Support Chat Error',
  props<{ errorMessage: any }>()
);

export const openSupportChat = createAction('[Support-Chat] Open Support Chat', props<{ supportChatId: string }>());
export const openSupportChatSuccess = createAction(
  '[Support-Chat] Open Support Chat Success',
  props<{ supportChat: SupportChat; messages: SupportChatMessage[] }>()
);
export const openSupportChatError = createAction(
  '[Support-Chat] Open Support Chat Error',
  props<{ supportChatId: string; errorMessage: any }>()
);

export const closeSupportChat = createAction('[Support-Chat] Close Support Chat', props<{ supportChatId: string }>());
export const closeSupportChatSuccess = createAction(
  '[Support-Chat] Close Support Chat Success',
  props<{ supportChat: SupportChat }>()
);
export const closeSupportChatError = createAction(
  '[Support-Chat] Close Support Chat Error',
  props<{ supportChatId: string; errorMessage: any }>()
);

export const createSupportMessage = createAction(
  '[Support-Chat] Create Support Message',
  props<{ opts: CreateSupportMessageOpts }>()
);
export const createSupportMessageSuccess = createAction(
  '[Support-Chat] Create Support Message Success',
  props<{ supportMessage: SupportChatMessage }>()
);
export const createSupportMessageError = createAction(
  '[Support-Chat] Create Support Message Error',
  props<{ errorMessage: any }>()
);

export const editSupportMessage = createAction(
  '[Support-Chat] Edit Support Message',
  props<{ supportMessageId: string; opts: CreateSupportMessageOpts }>()
);
export const editSupportMessageSuccess = createAction(
  '[Support-Chat] Edit Support Message Success',
  props<{ supportMessage: SupportChatMessage }>()
);
export const editSupportMessageError = createAction(
  '[Support-Chat] Edit Support Message Error',
  props<{ errorMessage: any }>()
);

export const removeSupportMessage = createAction(
  '[Support-Chat] Remove Support Message',
  props<{ supportChatId: string; supportMessageId: string }>()
);
export const removeSupportMessageSuccess = createAction(
  '[Support-Chat] Remove Support Message Success',
  props<{ removedSupportMessageId: string }>()
);
export const removeSupportMessageError = createAction(
  '[Support-Chat] Remove Support Message Error',
  props<{ errorMessage: any }>()
);

export const addSupportChatMarker = createAction(
  '[Support-Chat] Add Marker Support Chat',
  props<{ opts: MarkSupportChatOpts }>()
);
export const addSupportChatMarkerSuccess = createAction(
  '[Support-Chat] Add Marker Support Chat Success',
  props<{ supportChat: SupportChat }>()
);
export const addSupportChatMarkerError = createAction(
  '[Support-Chat] Add Marker Support Chat Error',
  props<{ errorMessage: any }>()
);

export const markSupportChatAsUnread = createAction(
  '[Support-Chat] Mark Support Chat As Unread',
  props<{ supportChatId: string }>()
);
export const markSupportChatAsUnreadSuccess = createAction(
  '[Support-Chat] Mark Support Chat As Unread Success',
  props<{ supportChat: SupportChat }>()
);
export const markSupportChatAsUnreadError = createAction(
  '[Support-Chat] Mark Support Chat As Unread Error',
  props<{ errorMessage: any }>()
);

export const markSupportChatAsRead = createAction(
  '[Support-Chat] Mark Support Chat As Read',
  props<{ supportChatId: string }>()
);
export const markSupportChatAsReadSuccess = createAction(
  '[Support-Chat] Mark Support Chat As Read Success',
  props<{ supportChat: SupportChat }>()
);
export const markSupportChatAsReadError = createAction(
  '[Support-Chat] Mark Support Chat As Read Error',
  props<{ errorMessage: any }>()
);

export const updateSupportChat = createAction(
  '[Support-Chat] Update Support Chat',
  props<{ supportChat: SupportChat }>()
);

export const updateSupportChatAndPosition = createAction(
  '[Support-Chat] Update Support And its position on the list',
  props<{ supportChat: SupportChat }>()
);

export const downloadAttachmentFromChat = createAction(
  '[Support-Chat] Download Message Attachment',
  props<{ supportChatId: string; messageId: string; attachmentId: string }>()
);
export const downloadAttachmentFromChatSuccess = createAction('[Support-Chat] Download Message Attachment Success');
export const downloadAttachmentFromChatError = createAction('[Support-Chat] Download Message Attachment Error');

export const uploadAttachments = createAction(
  '[Support-Chat] Upload Message Attachments',
  props<{ opts: UploadMessageAttachmentsOpts }>()
);
export const uploadAttachmentsSuccess = createAction(
  '[Support-Chat] Upload Message Attachments Success',
  props<{ opts: { uploadedFiles: MultipleFilesUploadResult } }>()
);
export const uploadAttachmentsError = createAction(
  '[Support-Chat] Upload Message Attachments Error',
  props<{ opts: { errorMessage: any } }>()
);

export const removeAttachmentFromUploading = createAction(
  '[Support-Chat] Remove Attachment From message',
  props<{ opts: { userAssetId: string; fileOriginalName: string } }>()
);
export const removeAttachmentFromUploadingSuccess = createAction(
  '[Support-Chat] Remove Attachment From message Success',
  props<{ opts: { userAssetId: string; fileOriginalName: string } }>()
);
export const removeAttachmentFromUploadingError = createAction(
  '[Support-Chat] Remove Attachment From message Error',
  props<{ opts: { userAssetId: string; errorMessage: any; fileOriginalName: string } }>()
);

export const removeExistingAttachment = createAction(
  '[Support-Chat] Remove Existing Attachment',
  props<{ opts: { supportChatId: string; supportMessageId: string; attachmentId: string } }>()
);
export const removeExistingAttachmentSuccess = createAction(
  '[Support-Chat] Remove Existing Attachment Success',
  props<{ opts: { supportChatId: string; supportMessageId: string; removedAttachmentId: string } }>()
);
export const removeExistingAttachmentError = createAction(
  '[Support-Chat] Remove Existing Attachment Error',
  props<{ opts: { errorMessage: any } }>()
);

export const getMyUnreadCount = createAction('[Support-Chat] Get My Unread Count');
export const getMyUnreadCountSuccess = createAction(
  '[Support-Chat] Get My Unread Count Success',
  props<{ count: number }>()
);
export const getMyUnreadCountError = createAction('[Support-Chat] Get My Unread Count Error');

export const getTranscription = createAction('[Support-Chat] Get Transcription', props<{ supportChatId: string }>());
export const getTranscriptionSuccess = createAction(
  '[Support-Chat] Get Transcription Success',
  props<{ success: boolean }>()
);
export const getTranscriptionError = createAction(
  '[Support-Chat] Get Transcription Error',
  props<{ opts: { errorMessage: any } }>()
);
