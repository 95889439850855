import { Action, createReducer, on } from '@ngrx/store';
import { initialState, VisitsCalendarState } from './visits-calendar.state';
import * as visitsActions from './visits-calendar.actions';

const reducer = createReducer(
  initialState,

  on(visitsActions.searchVisits, visitsActions.getVisitsList, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),

  on(visitsActions.searchGotoDateVisits, visitsActions.getVisitsList, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),

  on(visitsActions.searchVisitsSuccess, (state, { usersProcesses }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    visits: usersProcesses,
  })),

  on(visitsActions.searchGotoDateVisitsSuccess, (state, { usersProcesses }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    goToDateVisits: usersProcesses,
  })),

  on(visitsActions.getVisitsListSuccess, (state, { visitsList }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    visitsList: visitsList.data,
    visitsListPagination: visitsList.pagination,
  })),

  on(visitsActions.searchVisitsError, visitsActions.getVisitsListError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  }))
);

export function visitsCalendarReducer(state: VisitsCalendarState | undefined, action: Action): VisitsCalendarState {
  return reducer(state, action);
}
