<ng-container [formGroup]="form">
  <ng-container *ngIf="(fp !== 'place_' && form.get(fp + 'addressOutsideCountry').value === false) || fp === 'place_'">
    <div nz-row [nzGutter]="16">
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="{{ fp }}addressVoivodeshipId">
            {{ 'NT2.VOIVODESHIP' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="{{ fp }}addressVoivodeshipId"
              nzPlaceHolder="{{ 'NT2.SELECT_VOIVODESHIP' | translate }}"
              nzNotFoundContent="{{ 'NT2.NO_VOIVODESHIPS_LOADED' | translate }}"
              nzShowSearch>
              <nz-option *ngFor="let voivo of voivodeships" [nzValue]="voivo.id" [nzLabel]="voivo.name"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="{{ fp }}addressDistrictId">
            {{ 'NT2.DISTRICT' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="{{ fp }}addressDistrictId"
              [nzDisabled]="!form.get(fp + 'addressVoivodeshipId')?.value"
              nzPlaceHolder=" {{ 'NT2.SELECT_DISTRICT' | translate }} "
              nzNotFoundContent="{{ 'NT2.NO_DISTRICTS_LOADED' | translate }}"
              [nzLoading]="loadingDistricts"
              nzShowSearch>
              <nz-option
                *ngFor="let district of districts"
                [nzValue]="district.id"
                [nzLabel]="district.MOS_name.toUpperCase() + ' (' + district.MOS_extra_name + ')'">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="{{ fp }}addressMunicipalityId">
            {{ 'NT2.MUNICIPALITY' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="{{ fp }}addressMunicipalityId"
              [nzDisabled]="!form.get(fp + 'addressDistrictId')?.value"
              nzPlaceHolder=" {{ 'NT2.SELECT_MUNICIPALITY' | translate }} "
              nzNotFoundContent="{{ 'NT2.NO_MUNICIPALITIES_LOADED' | translate }}"
              [nzLoading]="loadingMunicips"
              nzShowSearch>
              <nz-option
                *ngFor="let municip of municips"
                [nzValue]="municip.id"
                [nzLabel]="municip.MOS_name.toUpperCase()">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="{{ fp }}addressCityId">
            {{ 'NT2.CITY' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="{{ fp }}addressCityId"
              [nzDisabled]="!form.get(fp + 'addressMunicipalityId')?.value"
              nzPlaceHolder=" {{ 'NT2.SELECT_CITY' | translate }} "
              nzNotFoundContent="{{ 'NT2.NO_CITIES_LOADED' | translate }}"
              [nzLoading]="loadingCities"
              nzShowSearch>
              <nz-option *ngFor="let city of cities" [nzValue]="city.id" [nzLabel]="city.MOS_name.toUpperCase()">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="16">
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="{{ fp }}addressZipCode">
            {{ 'NT2.ZIP_CODE' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [mask]="'99-999'" formControlName="{{ fp }}addressZipCode" id="{{ fp }}addressZipCode" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="{{ fp }}addressStreet">
            {{ 'NT2.STREET' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="{{ fp }}addressStreet" id="{{ fp }}addressStreet" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="{{ fp }}addressHouseNumber">
            {{ 'NT2.HOUSE_NUMBER' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input
              nz-input
              [maxlength]="15"
              formControlName="{{ fp }}addressHouseNumber"
              id="{{ fp }}addressHouseNumber" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="{{ fp }}addressApartmentNumber">
            {{ 'NT2.APARTMENT_NUMBER' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input
              nz-input
              [maxlength]="15"
              formControlName="{{ fp }}addressApartmentNumber"
              id="{{ fp }}addressApartmentNumber" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="fp !== 'place_' && form.get(fp + 'addressOutsideCountry').value === true">
    <div nz-row [nzGutter]="16">
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="{{ fp }}externalAddressCountry">
            {{ 'NT2.COUNTRY' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="{{ fp }}externalAddressCountry"
              nzPlaceHolder="{{ 'NT2.SELECT_COUNTRY' | translate }}"
              nzNotFoundContent="{{ 'NT2.NO_COUNTRIES_LOADED' | translate }}"
              nzShowSearch>
              <nz-option
                *ngFor="let country of countries"
                [nzValue]="country.name"
                [nzLabel]="country.nameTranslations[currLang]">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="{{ fp }}externalAddressCity">
            {{ 'NT2.CITY' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <input nz-input formControlName="{{ fp }}externalAddressCity" id="{{ fp }}externalAddressCity" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="{{ fp }}externalAddressStreet">
            {{ 'NT2.STREET' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <input nz-input formControlName="{{ fp }}externalAddressStreet" id="{{ fp }}externalAddressStreet" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="16">
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="{{ fp }}externalAddressZipCode">
            {{ 'NT2.ZIP_CODE' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <input
              nz-input
              [maxlength]="10"
              formControlName="{{ fp }}externalAddressZipCode"
              id="{{ fp }}externalAddressZipCode" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="{{ fp }}externalAddressHouseNumber">
            {{ 'NT2.HOUSE_NUMBER' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <input
              nz-input
              [maxlength]="15"
              formControlName="{{ fp }}externalAddressHouseNumber"
              id="{{ fp }}externalAddressHouseNumber" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="{{ fp }}externalAddressApartmentNumber">
            {{ 'NT2.APARTMENT_NUMBER' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <input
              nz-input
              [maxlength]="15"
              formControlName="{{ fp }}externalAddressApartmentNumber"
              id="{{ fp }}externalAddressApartmentNumber" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>

  <ng-template #inputStandardErrors let-control>
    <ng-container *ngIf="control.hasError('maxlength')">{{ 'NT2.MAX_LENGTH_REACHED' | translate }}</ng-container>
    <!-- <ng-container *ngIf="control.hasError('minlength')">MinLength is 6</ng-container> -->
    <ng-container *ngIf="control.hasError('required')"> {{ 'NT2.FIELD_REQUIRED' | translate }}</ng-container>
  </ng-template>
</ng-container>
