import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, concatMap } from 'rxjs/operators';

import { VoivodeshipsHttpService } from '@core/http/voivodeships.http.service';
import * as voivodeshipsActions from './voivodeships.actions';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { GetFileService } from '@shared/get-file/get-file.service';

@Injectable()
export class VoivodeshipsEffects {
  constructor(
    private actions$: Actions,
    private http: VoivodeshipsHttpService,
    private readonly notification: SnackbarService,
    private readonly getFileService: GetFileService
  ) {}

  getVoivodeships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.getVoivodeships),
      switchMap(() => {
        return this.http.getVoivodeships().pipe(
          map(voivodeships => voivodeshipsActions.getVoivodeshipsSuccess({ voivodeships })),
          catchError(() => of(voivodeshipsActions.getVoivodeshipsError({ errorMessage: 'ERROR_FETCHING_VOIVO_LIST' })))
        );
      })
    )
  );

  getVoivoByZipcode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.getVoivoByZipcode),
      switchMap(({ zipcode }) => {
        return this.http.getVoivodeshipByZipcode(zipcode).pipe(
          map(voivodeship => voivodeshipsActions.getVoivoByZipcodeSucces({ voivodeship })),
          catchError(() => of(voivodeshipsActions.getVoivoByZipcodeError({ errorMessage: 'VOIVO_NOT_VOUND' })))
        );
      })
    )
  );

  updateVoivodeship$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.updateVoivodeship),
      switchMap(({ voivoId, opts }) => {
        return this.http.updateVoivodeship(voivoId, opts).pipe(
          map(updatedVoivodeship => voivodeshipsActions.updateVoivodeshipSuccess({ updatedVoivodeship })),
          catchError(() => {
            this.notification.showError('ERRORS.UNKNOWN-ERROR-UPDATING-VOIVO');
            return of(voivodeshipsActions.updateVoivodeshipError({ errorMessage: 'ERROR_UPDATING_VOIVODESHIP' }));
          })
        );
      })
    )
  );

  addVoivodeshipOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.addVoivodeshipOffice),
      switchMap(({ opts, voivoId }) => {
        return this.http.addVoivodeshipOffice(opts, voivoId).pipe(
          map(addedOffice => voivodeshipsActions.addVoivodeshipOfficeSuccess({ voivoId, addedOffice })),
          catchError(() => {
            this.notification.showError('ERRORS.UNKNOWN-ERROR-ADDING-VOIVO-OFFICE');
            return of(
              voivodeshipsActions.addVoivodeshipOfficeError({ voivoId, errorMessage: 'ERROR_UPDATING_VOIVODESHIP' })
            );
          })
        );
      })
    )
  );

  editVoivodeshipOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.editVoivodeshipOffice),
      concatMap(({ opts, voivoId }) => {
        return this.http.editVoivodeshipOffice(opts, voivoId).pipe(
          map(updatedOffice => voivodeshipsActions.editVoivodeshipOfficeSuccess({ voivoId, updatedOffice })),
          catchError(() => {
            this.notification.showError('ERRORS.UNKNOWN-ERROR-EDITING-VOIVO-OFFICE');
            return of(
              voivodeshipsActions.editVoivodeshipOfficeError({ voivoId, errorMessage: 'ERROR_UPDATING_VOIVODESHIP' })
            );
          })
        );
      })
    )
  );

  removeVoivodeshipOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.removeVoivodeshipOffice),
      switchMap(({ officeId, voivoId }) => {
        return this.http.removeVoivodeshipOffice(voivoId, officeId).pipe(
          map(() => voivodeshipsActions.removeVoivodeshipOfficeSuccess({ voivoId, officeId })),
          catchError(() => {
            this.notification.showError('ERRORS.UNKNOWN-ERROR-REMOVING-VOIVO-OFFICE');
            return of(
              voivodeshipsActions.removeVoivodeshipOfficeError({
                voivoId,
                officeId,
                errorMessage: 'ERROR_UPDATING_VOIVODESHIP',
              })
            );
          })
        );
      })
    )
  );

  searchDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.searchDocuments),
      concatMap(({ opts }) => {
        return this.http.searchVoivodeshipDocuments(opts).pipe(
          map(documents => voivodeshipsActions.searchDocumentsSuccess({ documents })),
          catchError(() => {
            this.notification.showError('ERRORS.ERROR-SEARCHING-VOIVO-DOCUMENTS');
            return of(voivodeshipsActions.searchDocumentsError({ opts, errorMessage: 'ERROR_SEARCHING_DOCUMENTS' }));
          })
        );
      })
    )
  );

  updatedDocEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.updateDocEnabled),
      concatMap(({ opts }) => {
        return this.http.updateDocumentEnabled(opts).pipe(
          map(updatedDoc => voivodeshipsActions.updateDocEnabledSuccess({ updatedDoc })),
          catchError(() => {
            this.notification.showError('ERRORS.UNKNOWN-ERROR-UPDATING-ENABLED-DOC');
            return of(voivodeshipsActions.updateDocEnabledError({ errorMessage: 'ERROR_UPDATING_VOIVODESHIP' }));
          })
        );
      })
    )
  );

  updateDocEnabledBulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.updateDocEnabledBulk),
      concatMap(({ opts }) => {
        return this.http.updateDocumentsBulkEnabled(opts).pipe(
          map(updatedDocs => voivodeshipsActions.updateDocEnabledBulkSuccess({ updatedDocs })),
          catchError(() => {
            this.notification.showError('ERRORS.UNKNOWN-ERROR-UPDATING-ENABLED-DOC');
            return of(voivodeshipsActions.updateDocEnabledError({ errorMessage: 'ERROR_UPDATING_VOIVODESHIP' }));
          })
        );
      })
    )
  );

  getDistricts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.getDistricts),
      switchMap(({ opts }) => {
        const { voivoId } = opts;
        return this.http.getDistricts(voivoId).pipe(
          map(districts => voivodeshipsActions.getDistrictsSuccess({ districts, voivoId })),
          catchError(() => {
            this.notification.showError('ERROR_FETCHING_DISTRICTS');
            return of(voivodeshipsActions.getDistrictsSuccess({ voivoId, districts: [] }));
          })
        );
      })
    )
  );

  getMunicips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.getMunicips),
      switchMap(({ opts }) => {
        const { districtId } = opts;
        return this.http.getMunicips(opts).pipe(
          map(municips => voivodeshipsActions.getMunicipsSuccess({ districtId, municips })),
          catchError(() => {
            this.notification.showError('ERROR_FETCHING_MUNICIPALITIES');
            return of(voivodeshipsActions.getMunicipsSuccess({ districtId, municips: [] }));
          })
        );
      })
    )
  );

  getCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.getCities),
      switchMap(({ opts }) => {
        const { municipId } = opts;
        return this.http.getCities(opts).pipe(
          map(cities => voivodeshipsActions.getCitiesSuccess({ cities, municipId })),
          catchError(() => {
            this.notification.showError('ERROR_FETCHING_CITIES');
            return of(voivodeshipsActions.getCitiesSuccess({ municipId, cities: [] }));
          })
        );
      })
    )
  );

  downloadVoivodeshipFileInstruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.downloadVoivodeshipFileInstruction),
      switchMap(({ opts }) => {
        return this.http.downloadVoivodeshipFileInstruction(opts).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return voivodeshipsActions.downloadVoivodeshipFileInstructionSuccess();
            } else {
              this.notification.showError('VOIVODESHIP_FILE_INSTRUCTION.DOWNLOADING_ERROR');
              return voivodeshipsActions.downloadVoivodeshipFileInstructionError();
            }
          }),
          catchError(() => {
            this.notification.showError('VOIVODESHIP_FILE_INSTRUCTION.DOWNLOADING_ERROR');
            return of(voivodeshipsActions.downloadVoivodeshipFileInstructionError());
          })
        );
      })
    )
  );

  uploadVoivodeshipFileInstruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voivodeshipsActions.uploadVoivodeshipFileInstruction),
      switchMap(({ opts }) => {
        return this.http.uploadVoivodeshipFileInstruction(opts).pipe(
          map(availableVoivodeships => {
            this.notification.showInfo('VOIVODESHIP_FILE_INSTRUCTION.UPLOADING_SUCCESS');
            return voivodeshipsActions.uploadVoivodeshipFileInstructionSuccess({ availableVoivodeships });
          }),
          catchError(() => {
            this.notification.showError('VOIVODESHIP_FILE_INSTRUCTION.UPLOADING_ERROR');
            return of(voivodeshipsActions.uploadVoivodeshipFileInstructionError());
          })
        );
      })
    )
  );
}
