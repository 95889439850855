import { ConsumableCountry, Language, Process, ProcessTypeByCategory, VisitAssistant } from '@interfaces';

export type CommonsState = Readonly<{
  loading: boolean;
  errorMessage: string;

  availableProcesses: Process[] | null;
  availableProcessesByCategory: ProcessTypeByCategory | null;
  availableCountries: ConsumableCountry[];
  availableCountriesEU: ConsumableCountry[];
  availableLanguages: Language[];

  visitAssistants: VisitAssistant[];
  loadingVisitAssistants: boolean;
  visitAssistantsError: string;

  lastAPIPingSuccessful: boolean;
  APIPingInProgress: boolean;
}>;

export const initialState: CommonsState = {
  loading: false,
  errorMessage: null,

  availableCountries: null,
  availableCountriesEU: null,
  availableProcesses: null,
  availableProcessesByCategory: null,
  availableLanguages: null,

  visitAssistants: null,
  loadingVisitAssistants: false,
  visitAssistantsError: null,

  lastAPIPingSuccessful: true,
  APIPingInProgress: false,
};
