import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VoivodeshipsState } from './voivodeships.state';

export const selectVoivodeshipsState = createFeatureSelector<VoivodeshipsState>('voivodeships');

export const selectLoading = createSelector(selectVoivodeshipsState, state => state.loading);

export const selectErrorMessage = createSelector(selectVoivodeshipsState, state => state.errorMessage);

export const selectVoivodeships = createSelector(selectVoivodeshipsState, state => state.availableVoivodeships);

export const selectVoivodeshipDocuments = createSelector(selectVoivodeshipsState, state => state.voivodeshipDocuments);

export const selectLoadingDistricts = createSelector(selectVoivodeshipsState, state => state.loadingDistricts);
export const selectLoadingMunicips = createSelector(selectVoivodeshipsState, state => state.loadingMunicips);
export const selectLoadingCities = createSelector(selectVoivodeshipsState, state => state.loadingCities);

export const selectLoadedDistricts = createSelector(selectVoivodeshipsState, state => state.loadedDistricts);
export const selectLoadedMunicips = createSelector(selectVoivodeshipsState, state => state.loadedMunicips);
export const selectLoadedCities = createSelector(selectVoivodeshipsState, state => state.loadedCities);

export const selectLoadingVoivodeshipFileInstructionAssets = createSelector(
  selectVoivodeshipsState,
  state => state.loadingVoivodeshipFileInstruction
);
