import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VisitsCalendarState } from './visits-calendar.state';

export const selectVisitsCalendarState = createFeatureSelector<VisitsCalendarState>('visitsCalendar');

export const selectVisits = createSelector(selectVisitsCalendarState, state => state.visits);

export const selectGoToDateVisits = createSelector(selectVisitsCalendarState, state => state.goToDateVisits);

export const selectLoading = createSelector(selectVisitsCalendarState, state => state.loading);

export const selectErrorMessage = createSelector(selectVisitsCalendarState, state => state.errorMessage);

export const selectVisitsList = createSelector(selectVisitsCalendarState, state => state.visitsList);
export const selectVisitsListPagination = createSelector(
  selectVisitsCalendarState,
  state => state.visitsListPagination
);
