import { Injectable } from '@angular/core';
import { UrlTree, Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, map, filter, take, withLatestFrom } from 'rxjs/operators';

import { UserAuthFacade } from '@state/user-auth';
import { ROLES_KEYS } from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { UserProcessFacade } from '@state/user-process';

@Injectable()
export class RequiredDataGuard implements CanActivate {
  constructor(
    private userAuthFacade: UserAuthFacade,
    private userProcessFacade: UserProcessFacade,
    private snackService: SnackbarService,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.userAuthFacade.getMyself$(null).pipe(
      withLatestFrom(
        this.userProcessFacade.myProcess$.pipe(
          filter(myProcess => !!myProcess?.id),
          take(1)
        )
      ),
      take(1),
      switchMap(([user, userProcess]) => {
        // For foreigners only
        // If in some magic way not foreigner uses this guard - go to the root
        if (user.role?.key !== ROLES_KEYS.Foreigner) {
          console.error('THIS_GUARD_SHOULD_NOT_BE_EXECUTED_RIGHT_NOW');
          return of(this.router.createUrlTree(['']));
        }

        const resultStream = this.userProcessFacade.confirmPersonalDetailsSuccess$.pipe(
          take(1),
          map(({ validationResult: { valid } }) => {
            if (valid) {
              return true;
            }

            this.snackService.showError('APPLICATION.PLEASE-FILL-PROFILE-BEFORE-PROCEEDING');
            return this.router.createUrlTree(['user-process', userProcess.id, 'personal-details']);
          })
        );
        this.userProcessFacade.confirmPersonalDetails(userProcess.id);

        return resultStream;
      })
    );
  }
}
