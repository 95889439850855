import { BrandingType, Pagination, UserProcessStatus } from '@interfaces';

export interface Company {
  id?: string;
  email: string;
  name: string;
  branding: BrandingType;
  brandingLogo?: string;
  NIP?: string;
  address?: string;
  description?: string;
  ownerName?: string;
  purchaseEnabled: boolean;
  forceContactDataInApplication: boolean;
  forcedContactEmail?: string;
  forcedContactTelephoneNumber?: string;
  usingCustomAddress?: boolean;
  customAddressCity?: string;
  customAddressStreet?: string;
  customAddressZipCode?: string;
  customAddressTelephoneNumber?: string;
  customAddressExtraInfo?: { [key: string]: string };
  customRecipientForPost?: { [key: string]: string };
  isActive?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface GetCompanyPayload extends Pagination {
  query?: string;
  isActive?: boolean;
}

export interface GetCompanyEmployeesProcesses extends Pagination {
  status?: UserProcessStatus;
  query?: string;
}
