import { Injectable, NgZone } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { UserAuthFacade } from '@state/user-auth';
import { LayoutService } from '@layout/layout.service';
import { RefreshTokenModalService } from '@shared/refresh-token-modal/refresh-token-modal.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  // IN SECONDS
  private SHOW_WARNING_AFTER_SECS = 60 * 30; // 30m      - after this time of inactivity show topbar with countdown
  private AFTER_WARNING_COUNTDOWN_SECS = 90; // 1m 30s   - countdown time in topbar - when finishes - logout
  private PING_INTERVAL_SECS = 60 * 65; // 1h 5m    - ping 'myself' in this time intervals (when constantly active)

  constructor(
    private readonly refreshTokenModal: RefreshTokenModalService,
    private readonly layoutService: LayoutService,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly idle: Idle,
    private readonly keepalive: Keepalive,
    private readonly ngZone: NgZone
  ) {}

  public setupIdleWatch(): void {
    this.idle.setIdle(this.SHOW_WARNING_AFTER_SECS);
    this.idle.setTimeout(this.AFTER_WARNING_COUNTDOWN_SECS);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.ngZone.run(() => {
        this.layoutService.openInfoBar({ type: 'logout-warning' });
      });
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.ngZone.run(() => {
        this.layoutService.closeInfoBar();
      });
    });
    this.idle.onTimeoutWarning.subscribe(countdown => {
      this.ngZone.run(() => {
        const timeLeft = this.formatTime(countdown);
        this.layoutService.updateInfoBar({ timeLeft });
      });
    });
    this.idle.onTimeout.subscribe(() => {
      this.ngZone.run(() => {
        this.layoutService.closeInfoBar();
        this.userAuthFacade.logout({ sessionTimedOutLogout: true });
      });
    });

    this.keepalive.interval(this.PING_INTERVAL_SECS);
    this.keepalive.onPing.subscribe(() => {
      this.ngZone.run(() => {
        // If dialog already exists (opened) - previous refresh in progress
        // we don't want to duplicate refreshes
        const dialogAlreadyOpened = this.refreshTokenModal.isModalOpened();
        if (dialogAlreadyOpened) {
          return;
        }

        const dialogRef = this.refreshTokenModal.open();
        setTimeout(() => {
          this.userAuthFacade.getMyselfSuccess$.pipe(take(1)).subscribe(() => {
            dialogRef.close();
          });
          this.userAuthFacade.getMyself('');
        }, 2500);
      });
    });
  }

  public startIdleWatch(): void {
    this.idle.watch();

    // eslint-disable-next-line
    console.info(`
    Idle started with
      warning:  ${this.SHOW_WARNING_AFTER_SECS}s
      timeout:  ${this.AFTER_WARNING_COUNTDOWN_SECS}s
      ping:     ${this.PING_INTERVAL_SECS}s
    `);
  }

  public stopPing(): void {
    this.keepalive.stop();
  }

  public destroyPing(): void {
    this.keepalive.onPing.unsubscribe();
    this.keepalive.onPingResponse.unsubscribe();
  }

  public stopIdleWatch(): void {
    this.idle.stop();
  }

  public destroyIdleWatch(): void {
    this.idle.clearInterrupts();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
    this.idle.onTimeout.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
  }

  private formatTime(seconds: number): string {
    if (seconds < 60) {
      return `00:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    if (seconds === 60) {
      return `01:00`;
    }

    const minutes = Math.floor(seconds / 60);
    const secondsLeft = Math.floor(seconds % 60);

    const minutesFormatted = `${minutes < 10 ? `0${minutes}` : minutes}`;
    const secondsFormatted = `${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`;
    return `${minutesFormatted}:${secondsFormatted}`;
  }
}
