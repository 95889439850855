import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateOfficeOpts, Office, PaginatedResponse, SearchOfficeOpts, ToggleAssignedVoivodeship } from '@interfaces';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class OfficesHttpService {
  private API_URL = environment.API_URL;
  private FULL_URL = `${this.API_URL}/offices`;

  constructor(private http: HttpClient) {}

  public getOfficesSimpleList(): Observable<Office[]> {
    const url = `${this.FULL_URL}`;
    return this.http.get<Office[]>(url);
  }

  public searchOffices(opts: SearchOfficeOpts): Observable<PaginatedResponse<Office>> {
    const url = `${this.FULL_URL}/search`;
    return this.http.post<PaginatedResponse<Office>>(url, opts);
  }

  public getOffice(officeId: string): Observable<Office> {
    const url = `${this.FULL_URL}/${officeId}`;
    return this.http.get<Office>(url);
  }

  public createOffice(opts: CreateOfficeOpts): Observable<Office> {
    const url = `${this.FULL_URL}`;
    return this.http.post<Office>(url, opts);
  }

  public updateOffice(officeId: string, office: CreateOfficeOpts): Observable<Office> {
    const url = `${this.FULL_URL}/${officeId}`;
    return this.http.patch<Office>(url, office);
  }

  public removeOffice(officeId: string): Observable<Office> {
    const url = `${this.FULL_URL}/${officeId}`;
    return this.http.delete<Office>(url);
  }

  public restoreOffice(officeId: string): Observable<Office> {
    const url = `${this.FULL_URL}/${officeId}/restore`;
    return this.http.patch<Office>(url, {});
  }

  public toggleAssignedVovoideship(opts: ToggleAssignedVoivodeship): Observable<Office> {
    const url = `${this.FULL_URL}/${opts.officeId}/assigned-voivodeships`;
    return this.http.patch<Office>(url, opts);
  }
}
