import { createAction, props } from '@ngrx/store';
import { DocumentComment } from '@interfaces';

export const getDocumentComments = createAction(
  '[Comments] Get Document Comments',
  props<{ userProcessId: string; userDocumentId: string }>()
);
export const getDocumentCommentsSuccess = createAction(
  '[Comments] Get Document Comments Success',
  props<{ documentComments: DocumentComment[]; userDocumentId: string; userProcessId: string }>()
);
export const getDocumentCommentsError = createAction(
  '[Comments] Get Document Comments Error',
  props<{ errorMessage: string }>()
);

export const addDocumentComment = createAction(
  '[Comments] Add Document Comment',
  props<{ comment: DocumentComment; userProcessId: string }>()
);
export const addDocumentCommentSuccess = createAction(
  '[Comments] Add Document Comment Success',
  props<{ createdDocumentComment: DocumentComment; userProcessId: string }>()
);
export const addDocumentCommentError = createAction(
  '[Comments] Add Document Comment Error',
  props<{ errorMessage: string }>()
);

export const editDocumentComment = createAction(
  '[Comments] Edit Document Comment',
  props<{ comment: DocumentComment; userProcessId: string }>()
);

export const editDocumentCommentSuccess = createAction(
  '[Comments] Edit Document Comment Success',
  props<{ editedDocumentComment: DocumentComment; userProcessId: string }>()
);

export const editDocumentCommentError = createAction(
  '[Comments] Edit Document Comment Error',
  props<{ errorMessage: string }>()
);

export const removeDocumentComment = createAction(
  '[Comments] Remove Document Comment',
  props<{ comment: DocumentComment; userProcessId: string }>()
);

export const removeDocumentCommentSuccess = createAction(
  '[Comments] Remove Document Comment Success',
  props<{ removedDocumentCommentId: string; userProcessId: string }>()
);

export const removeDocumentCommentError = createAction(
  '[Comments] Remove Document Comment Error',
  props<{ errorMessage: string }>()
);

export const markDocumentCommentsAsRead = createAction(
  '[Comments] Mark Document Comments As Read',
  props<{ userDocumentId: string; userProcessId: string }>()
);

export const markDocumentCommentsAsReadSuccess = createAction(
  '[Comments] Mark Document Comments As Read Success',
  props<{ userDocumentId: string; userProcessId: string; updatedComments: DocumentComment[] }>()
);

export const markDocumentCommentsAsReadError = createAction(
  '[Comments] Mark Document Comments As Read Error',
  props<{ errorMessage: string }>()
);

export const readerAnyNewCommentsForceCheck = createAction(
  '[Comments] Has Reader Any New Comments In Process Force-Check',
  props<{
    readerId: string;
    userProcessId: string;
    userDocumentId: string;
    updatedComments: DocumentComment[];
  }>()
);
