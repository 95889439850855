import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { patterns } from '@constants';
import { AllDeviceInfo, DocumentComment, InAppNotificationType, ROLES_KEYS, User } from '@interfaces';
import { CommentsFacade } from '@state/comments';
import { InAppNotificationsFacade } from '@state/notifications';
import { RouterFacade } from '@state/router';
import { UserAuthFacade } from '@state/user-auth';
import { isString } from 'lodash-es';
import { ReplaySubject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ConfirmationModalService } from '../confirmation-modal/confirmation-modal.service';
import { PopupOpts } from './discussion-modal.service';
import { DeviceInfoService } from '@shared/device-info/device-info.service';

@Component({
  selector: 'app-discussion-modal',
  templateUrl: './discussion-modal.component.html',
  styleUrls: ['./discussion-modal.component.scss'],
})
export class DiscussionModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('dialogContent') dialogContent: ElementRef;
  @ViewChild('commentInput') commentInput: ElementRef;

  public myself$ = this.userAuthFacade.myself$;
  public isForeigner = true;
  public myself: User = null;
  public allComments$ = this.commentsFacade.documentComments$.pipe(
    filter(documentComments => documentComments.comments !== null),
    map(documentComments => documentComments.comments)
  );
  public loadingComments$ = this.commentsFacade.loadingComments$;

  public ROLES_KEYS = ROLES_KEYS;
  public newCommentContent = '';
  public editingComment: DocumentComment = null;

  public deviceInfo: AllDeviceInfo;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupOpts,
    public dialogRef: MatDialogRef<DiscussionModalComponent>,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly commentsFacade: CommentsFacade,
    private readonly confirmation: ConfirmationModalService,
    private readonly inAppNotifications: InAppNotificationsFacade,
    private readonly routerFacade: RouterFacade,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.allComments$
      .pipe(
        filter(comments => !!comments?.length),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        // Mark comments as read at dialog open
        this.commentsFacade.markDocumentCommentsAsRead(this.data.userProcessId, this.data.userDocumentId);
      });

    this.myself$
      .pipe(
        filter(myself => !!myself?.role),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(myself => {
        this.myself = myself;
        this.isForeigner = myself?.role.key === ROLES_KEYS.Foreigner;
        const relatedForeignerId = this.isForeigner ? myself.id : this.data.userId;
        const notifType = this.isForeigner
          ? InAppNotificationType.EMPLOYEE_ADDED_DOCUMENT_COMMENT
          : InAppNotificationType.FOREIGNER_ADDED_DOCUMENT_COMMENT;

        // mark notifications related to this document as read
        this.inAppNotifications.markAllOfTypeAsRead({
          relatedForeignerId,
          inAppNotificationType: notifType,
          relatedUserDocumentId: this.data.userDocumentId,
          relatedUserProcessId: this.data.userProcessId,
        });
      });

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        if (this.editingComment) {
          this.cancelEdit();
          return;
        }

        if (this.newCommentContent) {
          this.commentInput.nativeElement.blur();
          return;
        }

        this.closeDialog();
      }
    });

    this.dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });

    this.commentsFacade.addCommentSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.newCommentContent = '';
      this.scrollToBottom();
    });

    this.commentsFacade.editCommentSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.newCommentContent = '';
      this.editingComment = null;
      this.commentInput.nativeElement.blur();
    });

    this.commentsFacade.getDocumentComments(this.data.userProcessId, this.data.userDocumentId);
  }

  ngAfterViewInit(): void {
    // Initial scroll to bottom at the opening of chat window
    this.allComments$
      .pipe(
        filter(comments => !!comments?.length),
        take(1)
      )
      .subscribe(() => {
        this.scrollToBottom();
      });

    this.inAppNotifications.newNotificationReceived$.pipe(takeUntil(this.destroy$)).subscribe(({ notification }) => {
      if (!this.myself) {
        return;
      }
      const { type, relatedForeignerId, relatedUserDocumentId, relatedUserProcessId } = notification;

      if (
        (this.isForeigner && type !== InAppNotificationType.EMPLOYEE_ADDED_DOCUMENT_COMMENT) ||
        (!this.isForeigner && type !== InAppNotificationType.FOREIGNER_ADDED_DOCUMENT_COMMENT)
      ) {
        return;
      }
      if (
        (this.isForeigner && relatedForeignerId !== this.myself.id) ||
        (!this.isForeigner && relatedForeignerId !== this.data.userId)
      ) {
        return;
      }

      if (relatedUserProcessId !== this.data.userProcessId || relatedUserDocumentId !== this.data.userDocumentId) {
        return;
      }

      setTimeout(() => {
        this.commentsFacade.getCommentsSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
          this.scrollToBottom();
          this.inAppNotifications.markAsRead([notification.id]);
        });

        this.commentsFacade.getDocumentComments(relatedUserProcessId, relatedUserDocumentId);
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.routerFacade.changeRoute({
      linkParams: [],
      extras: { queryParams: {} },
    });
  }

  public submitComment($event: any): void {
    if ($event.shiftKey) {
      return;
    }

    if (this.newCommentContent?.length) {
      this.newCommentContent = this.newCommentContent.trim();
    }
    if (!this.newCommentContent || !this.commentContentValid()) {
      return;
    }

    if (!this.editingComment) {
      this.commentsFacade.addDocumentComment(
        { content: this.newCommentContent, userDocumentId: this.data.userDocumentId },
        this.data.userProcessId
      );
    }

    if (this.editingComment && !this.isForeigner) {
      this.commentsFacade.editDocumentComment(
        { ...this.editingComment, content: this.newCommentContent },
        this.data.userProcessId
      );
    }
  }

  public cancelEdit(): void {
    this.editingComment = null;
    this.newCommentContent = '';
    this.commentInput.nativeElement.blur();
  }

  public editComment(comment: DocumentComment): void {
    this.editingComment = comment;
    this.newCommentContent = comment.content;
    this.commentInput.nativeElement.focus();
  }

  public removeComment(comment: DocumentComment): void {
    this.confirmation
      .open({
        confirmationToTranslate: 'APPLICATION.ARE-YOU-SURE-REMOVE-COMMENT',
        translateParams: {
          commentTrimmed: `${comment.content.slice(0, 50)}...`,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.commentsFacade.removeDocumentComment(comment, this.data.userProcessId);
      });
  }

  public scrollToBottom(): void {
    setTimeout(() => {
      const htmlElem = this.dialogContent?.nativeElement as HTMLElement;
      const scrollHeight = htmlElem?.scrollHeight || 0;
      htmlElem?.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    });
  }

  public commentContentValid(): boolean {
    const pattern = patterns.CommentTextValidator;

    if (!isString(this.newCommentContent)) {
      return false;
    }
    return !!(this.newCommentContent as string).match(pattern);
  }

  public closeDialog(): void {
    // Mark comments as read at dialog close
    this.commentsFacade.markDocumentCommentsAsRead(this.data.userProcessId, this.data.userDocumentId);
    this.dialogRef.close();
  }
}
