export enum TRAVEL_DOCUMENT_TYPE {
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  TRAVEL_DOCUMENT = 'TRAVEL_DOCUMENT',
  NOCOUNTRY_TRAVEL_DOCUMENT = 'NOCOUNTRY_TRAVEL_DOCUMENT',
  REFUGEE_TRAVEL_DOCUMENT = 'REFUGEE_TRAVEL_DOCUMENT',
  PERM_STAY_DOCUMENT = 'PERM_STAY_DOCUMENT',
  PERSONAL_ID_DOCUMENT = 'PERSONAL_ID_DOCUMENT',
  GENEVIAN_TRAVEL_DOCUMENT = 'GENEVIAN_TRAVEL_DOCUMENT',
  OTHER = 'OTHER',
  FAMILY_MEMBER_RESIDENCE_CARD = 'FAMILY_MEMBER_RESIDENCE_CARD',
  RESIDENCE_CARD = 'RESIDENCE_CARD',
  UE_FAMILY_MEMBER_PERM_STAY_CARD = 'UE_FAMILY_MEMBER_PERM_STAY_CARD',
  WB_FAMILY_MEMBER_PERM_STAY_CARD = 'WB_FAMILY_MEMBER_PERM_STAY_CARD',
  SAILING_BOOK = 'SAILING_BOOK',
  LAISEZ_PASSER = 'LAISEZ_PASSER',
  DOMESTIC_LAISEZ_PASSER = 'DOMESTIC_LAISEZ_PASSER',
  ONZ_LAISEZ_PASSER = 'ONZ_LAISEZ_PASSER',
  FOREIGNER_PASSPORT = 'FOREIGNER_PASSPORT',
  DIPLOMATIC_PASSPORT = 'DIPLOMATIC_PASSPORT',
  GROUP_PASSPORT = 'GROUP_PASSPORT',
  OFFICIAL_PASSPORT = 'OFFICIAL_PASSPORT',
  WORK_PASSPORT = 'WORK_PASSPORT',
  SPECIAL_PASSPORT = 'SPECIAL_PASSPORT',
  TEMP_PASSPORT = 'TEMP_PASSPORT',
  ORDINARY_PASSPORT = 'ORDINARY_PASSPORT',
  PL_TRAVEL_DOCUMENT_FOR_FOREIGNER = 'PL_TRAVEL_DOCUMENT_FOR_FOREIGNER',
  PL_ID_DOC_FOREIGNER = 'PL_ID_DOC_FOREIGNER',
  TEMP_IDENTITY_CERT_FOREIGNER = 'TEMP_IDENTITY_CERT_FOREIGNER',
  TEMP_PL_TRAVEL_DOC = 'TEMP_PL_TRAVEL_DOC',
  INVITATION = 'INVITATION',
  FOREIGNER_HUMANTRAFFICKING = 'HUMANTRAFFICKING_FOREIGNER',
  ENTRY_HUMANTRAFFICKING = 'ENTRY_HUMANTRAFFICKING',
  UE_REGISTERED_CITIZEN = 'UE_REGISTERED_CITIZEN',
  WB_REGISTERED_CITIZEN = 'WB_REGISTERED_CITIZEN',
  MRG_PERM = 'MRG_PERM',
  TOLERATED_STAY = 'TOLERATED_STAY',
}
