import * as moment from 'moment-timezone';
import { Action, createReducer, on } from '@ngrx/store';

import { SupportChat, SupportChatInList } from '@interfaces';
import { SupportChatState, initialState } from './support-chat.state';
import * as actions from './support-chat.actions';

const arrayToObj = (arr: SupportChat[]): { [key: string]: SupportChatInList } => {
  if (!arr?.length) {
    return {};
  }

  return arr.reduce(
    (mainObj, supportChat) => {
      mainObj[supportChat.id] = supportChatToSupportChatInList(supportChat);
      return mainObj;
    },
    {} as { [key: string]: SupportChatInList }
  );
};

const supportChatToSupportChatInList = (supportChat: SupportChat): SupportChatInList => {
  const { lastMessageAt, readByEmployeeAt } = structuredClone(supportChat);
  let hasUnreadMessages = false;

  if (!readByEmployeeAt && lastMessageAt) {
    hasUnreadMessages = true;
  }

  if (readByEmployeeAt && lastMessageAt) {
    hasUnreadMessages = moment(lastMessageAt).isAfter(moment(readByEmployeeAt));
  }
  return {
    ...supportChat,
    hasUnreadMessages,
  };
};

const reducer = createReducer(
  initialState,
  on(actions.searchSupportChats, (state, { opts, inBackground }) => ({
    ...state,
    pagination: { ...state.pagination, ...opts },
    loadingSupportChats: !inBackground,
    errorMessage: null,
  })),
  on(actions.searchSupportChatsSuccess, (state, { supportChats: { data, pagination } }) => ({
    ...state,
    pagination: { ...state.pagination, ...(pagination as any) },
    supportChats: { ...arrayToObj(data) },
    loadingSupportChats: false,
    errorMessage: null,
  })),
  on(actions.searchSupportChatsError, (state, { errorMessage }) => ({
    ...state,
    loadingSupportChats: false,
    errorMessage,
  })),

  on(actions.loadMoreSupportChats, (state, { opts }) => ({
    ...state,
    pagination: { ...state.pagination, ...opts },
    loadingMoreSupportChats: true,
    errorMessage: null,
  })),
  on(actions.loadMoreSupportChatsSuccess, (state, { supportChats: { data, pagination } }) => ({
    ...state,
    pagination: { ...state.pagination, ...(pagination as any) },
    supportChats: { ...state.supportChats, ...arrayToObj(data) },
    loadingMoreSupportChats: false,
    errorMessage: null,
  })),
  on(actions.loadMoreSupportChatsError, (state, { errorMessage }) => ({
    ...state,
    loadingMoreSupportChats: false,
    errorMessage,
  })),

  on(actions.addSupportChatMarker, (state, { opts }) => ({
    ...state,
    loadingSupportChatUpdate: opts.supportChatId,
    errorMessage: null,
  })),
  on(actions.addSupportChatMarkerSuccess, (state, { supportChat }) => ({
    ...state,
    supportChats: {
      ...structuredClone(state.supportChats),
      [supportChat.id]: supportChatToSupportChatInList(supportChat),
    },
    loadingSupportChatUpdate: null,
    errorMessage: null,
  })),
  on(actions.addSupportChatMarkerError, (state, { errorMessage }) => ({
    ...state,
    loadingSupportChatUpdate: null,
    errorMessage,
  })),

  on(actions.markSupportChatAsUnread, (state, { supportChatId }) => ({
    ...state,
    loadingSupportChatUpdate: supportChatId,
    errorMessage: null,
  })),
  on(actions.markSupportChatAsUnreadSuccess, (state, { supportChat }) => {
    const newSupportChats = structuredClone(state.supportChats);
    newSupportChats[supportChat.id] = { ...supportChatToSupportChatInList(supportChat) };
    return {
      ...state,
      supportChats: newSupportChats,
      loadingSupportChatUpdate: null,
      errorMessage: null,
    };
  }),
  on(actions.markSupportChatAsUnreadError, (state, { errorMessage }) => ({
    ...state,
    loadingSupportChatUpdate: null,
    errorMessage,
  })),

  on(actions.markSupportChatAsRead, (state, { supportChatId }) => ({
    ...state,
    loadingSupportChatUpdate: supportChatId,
    errorMessage: null,
  })),
  on(actions.markSupportChatAsReadSuccess, (state, { supportChat }) => {
    const newSupportChats = structuredClone(state.supportChats);
    newSupportChats[supportChat.id] = { ...supportChatToSupportChatInList(supportChat) };
    return {
      ...state,
      supportChats: newSupportChats,
      loadingSupportChatUpdate: null,
      errorMessage: null,
    };
  }),
  on(actions.markSupportChatAsReadError, (state, { errorMessage }) => ({
    ...state,
    loadingSupportChatUpdate: null,
    errorMessage,
  })),

  on(actions.openSupportChat, (state, { supportChatId }) => ({
    ...state,
    loadingOpenSupportChat: true,
    loadingSupportChatUpdate: supportChatId,
    errorMessage: null,
  })),
  on(actions.openSupportChatSuccess, (state, { messages, supportChat }) => ({
    ...state,
    openSupportChat: {
      messageAttachmentsToAdd: {},
      messages: structuredClone(messages),
      supportChat: structuredClone(supportChat),
    },
    loadingOpenSupportChat: false,
    loadingSupportChatUpdate: null,
    errorMessage: null,
  })),
  on(actions.openSupportChatError, (state, { errorMessage }) => ({
    ...state,
    loadingOpenSupportChat: false,
    loadingSupportChatUpdate: null,
    errorMessage,
  })),

  on(actions.closeSupportChat, (state, { supportChatId }) => ({
    ...state,
    loadingOpenSupportChat: true,
    loadingSupportChatUpdate: supportChatId,
    errorMessage: null,
  })),
  on(actions.closeSupportChatSuccess, state => ({
    ...state,
    openSupportChat: null,
    loadingOpenSupportChat: false,
    loadingSupportChatUpdate: null,
    errorMessage: null,
  })),
  on(actions.closeSupportChatError, (state, { errorMessage }) => ({
    ...state,
    loadingOpenSupportChat: false,
    loadingSupportChatUpdate: null,
    errorMessage,
  })),

  on(actions.createSupportMessage, state => ({
    ...state,
    sendingMessage: true,
    errorMessage: null,
  })),

  on(actions.createSupportMessageSuccess, (state, { supportMessage }) => {
    if (state.openSupportChat?.supportChat?.id === supportMessage.supportChatId) {
      return {
        ...state,
        openSupportChat: {
          ...state.openSupportChat,
          messageAttachmentsToAdd: {},
          messages: [...state.openSupportChat.messages, structuredClone(supportMessage)],
        },
        sendingMessage: false,
        errorMessage: null,
      };
    } else {
      return {
        ...state,
        sendingMessage: false,
        errorMessage: null,
      };
    }
  }),

  on(actions.editSupportMessage, state => ({
    ...state,
    loadingOpenSupportChat: true,
    errorMessage: null,
  })),
  on(actions.editSupportMessageSuccess, (state, { supportMessage }) => {
    return {
      ...state,
      openSupportChat: {
        ...state.openSupportChat,
        messageAttachmentsToAdd: {},
        messages: state.openSupportChat.messages.map(msg =>
          msg.id === supportMessage.id ? { ...supportMessage } : { ...msg }
        ),
      },
      loadingOpenSupportChat: false,
      errorMessage: null,
    };
  }),
  on(actions.editSupportMessageError, (state, { errorMessage }) => ({
    ...state,
    loadingOpenSupportChat: false,
    errorMessage,
  })),

  on(actions.removeSupportMessage, state => ({
    ...state,
    loadingOpenSupportChat: true,
    errorMessage: null,
  })),
  on(actions.removeSupportMessageSuccess, (state, { removedSupportMessageId }) => {
    return {
      ...state,
      openSupportChat: {
        ...state.openSupportChat,
        messages: state.openSupportChat.messages.filter(msg => msg.id !== removedSupportMessageId),
      },
      loadingOpenSupportChat: false,
      errorMessage: null,
    };
  }),
  on(actions.removeSupportMessageError, (state, { errorMessage }) => ({
    ...state,
    loadingOpenSupportChat: false,
    errorMessage,
  })),

  on(actions.updateSupportChat, (state, { supportChat }) => {
    const existOnTheList = state.supportChats[supportChat.id];
    if (!existOnTheList) {
      return state;
    }

    const updatedList = structuredClone(state.supportChats);
    updatedList[supportChat.id] = supportChatToSupportChatInList(supportChat);
    return { ...state, supportChats: updatedList };
  }),

  on(actions.updateSupportChatAndPosition, (state, { supportChat }) => {
    const existOnTheList = state.supportChats[supportChat.id];
    const updatedList = structuredClone(state.supportChats);
    if (existOnTheList) {
      delete updatedList[supportChat.id];
    }

    return {
      ...state,
      pagination: {
        ...state.pagination,
        offset: existOnTheList ? state.pagination.offset : state.pagination.offset + 1,
      },
      supportChats: {
        [supportChat.id]: supportChatToSupportChatInList(supportChat),
        ...updatedList,
      },
    };
  }),

  on(actions.uploadAttachments, (state, { opts: { files } }) => {
    const filesAsObj = {} as any;
    files.forEach(file => {
      filesAsObj[file.fileOriginalName] = {
        error: null,
        loading: true,
        uploaded: false,
        userAsset: file,
      };
    });

    return {
      ...state,
      loadingOpenSupportChat: true,
      openSupportChat: {
        ...state.openSupportChat,
        messageAttachmentsToAdd: {
          ...state.openSupportChat.messageAttachmentsToAdd,
          ...filesAsObj,
        },
      },
    };
  }),
  on(actions.uploadAttachmentsSuccess, (state, { opts: { uploadedFiles } }) => {
    const inStateObj = {} as any;
    Object.entries(uploadedFiles).forEach(([key, fileUploadResult]) => {
      inStateObj[key] = {
        uploaded: fileUploadResult.uploaded,
        loading: false,
        error: fileUploadResult.errorMessage || null,
        userAsset: fileUploadResult.userAsset || null,
      };
    });
    return {
      ...state,
      loadingOpenSupportChat: false,
      openSupportChat: {
        ...state.openSupportChat,
        messageAttachmentsToAdd: {
          ...state.openSupportChat.messageAttachmentsToAdd,
          ...inStateObj,
        },
      },
    };
  }),
  on(actions.uploadAttachmentsError, (state, { opts: { errorMessage } }) => {
    return {
      ...state,
      errorMessage,
      loadingOpenSupportChat: false,
    };
  }),

  on(actions.downloadAttachmentFromChat, (state, {}) => {
    return {
      ...state,
      loadingOpenSupportChat: true,
    };
  }),
  on(actions.downloadAttachmentFromChatSuccess, actions.downloadAttachmentFromChatError, (state, {}) => {
    return {
      ...state,
      loadingOpenSupportChat: false,
    };
  }),

  on(actions.removeAttachmentFromUploading, (state, { opts: { fileOriginalName } }) => {
    return {
      ...state,
      openSupportChat: {
        ...state.openSupportChat,
        messageAttachmentsToAdd: {
          ...state.openSupportChat.messageAttachmentsToAdd,
          [fileOriginalName]: {
            ...state.openSupportChat.messageAttachmentsToAdd[fileOriginalName],
            loading: true,
            uploaded: false,
          },
        },
      },
    };
  }),
  on(actions.removeAttachmentFromUploadingSuccess, (state, { opts: { fileOriginalName } }) => {
    const rItems = { ...state.openSupportChat.messageAttachmentsToAdd };
    delete rItems[fileOriginalName];
    return {
      ...state,
      openSupportChat: {
        ...state.openSupportChat,
        messageAttachmentsToAdd: { ...rItems },
      },
    };
  }),
  on(actions.removeAttachmentFromUploadingError, (state, { opts: { fileOriginalName } }) => {
    return {
      ...state,
      openSupportChat: {
        ...state.openSupportChat,
        messageAttachmentsToAdd: {
          ...state.openSupportChat.messageAttachmentsToAdd,
          [fileOriginalName]: {
            ...state.openSupportChat.messageAttachmentsToAdd[fileOriginalName],
            error: 'ERROR_REMOVING',
          },
        },
      },
    };
  }),

  on(actions.removeExistingAttachment, (state, {}) => {
    return {
      ...state,
      loadingOpenSupportChat: true,
    };
  }),
  on(actions.removeExistingAttachmentSuccess, state => {
    return {
      ...state,
      loadingOpenSupportChat: false,
    };
  }),
  on(actions.removeExistingAttachmentError, (state, { opts: { errorMessage } }) => {
    return {
      ...state,
      loadingOpenSupportChat: false,
      errorMessage,
    };
  }),

  on(actions.getTranscription, (state, {}) => {
    return {
      ...state,
      loadingSupportChats: true,
    };
  }),
  on(actions.getTranscriptionSuccess, state => {
    return {
      ...state,
      loadingSupportChats: false,
    };
  }),
  on(actions.getTranscriptionError, (state, { opts: { errorMessage } }) => {
    return {
      ...state,
      loadingSupportChats: false,
      errorMessage,
    };
  }),

  on(actions.getMyUnreadCountSuccess, (state, { count }) => {
    return { ...state, unreadCount: count };
  }),

  // this actions refetches all support chats with current filter options
  // and opens created support chat
  // @TODO user should not be able to create support chats because +
  // + system creates support chat automatically for foreigner that activates account
  // + system cares that everey activated foreigner has access to support-chat (has its own support chat)
  on(actions.createSupportChat, (state, {}) => ({
    ...state,
    loadingSupportChats: true,
  }))
);

export function supportChatReducer(state: SupportChatState, action: Action): SupportChatState {
  return reducer(state, action);
}
