import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SystemSettingsHttpService } from '@core/http/system-settings.http.service';

import { ProductsHttpService } from '@core/http/products.http.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { parseError } from '@state/errors.parser';

import * as systemSettingsActions from './system-settings.actions';

@Injectable()
export class SystemSettingsEffects {
  constructor(
    private actions$: Actions,
    private http: SystemSettingsHttpService,
    private productsHttp: ProductsHttpService,
    private snackService: SnackbarService
  ) {}

  getSystemSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.getSystemSettings),
      switchMap(() => {
        return this.http.getSystemSettings().pipe(
          map(systemSettings => systemSettingsActions.getSystemSettingsSuccess({ systemSettings })),
          catchError(() =>
            of(systemSettingsActions.getSystemSettingsError({ errorMessage: 'ERROR_FETCHING_SYSTEM_SETTINGS' }))
          )
        );
      })
    )
  );

  changePIN$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.changePIN),
      switchMap(({ newPIN }) => {
        return this.http.changePIN(newPIN).pipe(
          map(updatedSystemSettings => systemSettingsActions.changePINSuccess({ updatedSystemSettings })),
          catchError(() => of(systemSettingsActions.changePINError({ errorMessage: 'ERROR_CHANGING_PIN' })))
        );
      })
    )
  );

  getSelfRegistrationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.getSelfRegistrationStatus),
      switchMap(() => {
        return this.http.getSelfRegistrationStatus().pipe(
          map(isActive => systemSettingsActions.getSelfRegistrationStatusSuccess({ isActive })),
          catchError(() =>
            of(systemSettingsActions.getSelfRegistrationStatusError({ errorMessage: 'ERROR_FETCHING_SELF_REG_STATUS' }))
          )
        );
      })
    )
  );

  setSelfRegistrationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.setSelfRegistrationStatus),
      switchMap(({ isActive }) => {
        return this.http.setSelfRegistrationStatus(isActive).pipe(
          map(updatedSystemSettings =>
            systemSettingsActions.setSelfRegistrationStatusSuccess({ updatedSystemSettings })
          ),
          catchError(() =>
            of(
              systemSettingsActions.setSelfRegistrationStatusError({
                errorMessage: 'ERROR_SETTING_SELF_REGISTRATION_STATUS',
              })
            )
          )
        );
      })
    )
  );

  changeMainNotificationsRecipient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.changeMainNotificationsRecipient),
      switchMap(({ newEmail }) => {
        return this.http.changeMainNotificationRecipient(newEmail).pipe(
          map(updatedSystemSettings =>
            systemSettingsActions.changeMainNotificationsRecipientSuccess({ updatedSystemSettings })
          ),
          catchError(() =>
            of(
              systemSettingsActions.changeMainNotificationsRecipientError({
                errorMessage: 'ERROR_CHANGING_MAIN_NOTIFICATIONS_RECIPIENT',
              })
            )
          )
        );
      })
    )
  );

  changeAdditionalRecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.changeAdditionalRecipients),
      switchMap(({ recipientsEmails }) => {
        return this.http.changeAdditionalRecipients(recipientsEmails).pipe(
          map(updatedSystemSettings =>
            systemSettingsActions.changeAdditionalRecipientsSuccess({ updatedSystemSettings })
          ),
          catchError(() =>
            of(
              systemSettingsActions.changeAdditionalRecipientsError({
                errorMessage: 'ERROR_CHANGING_ADDITIONAL_RECIPIENTS',
              })
            )
          )
        );
      })
    )
  );

  changeSecurityRecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.changeSecurityRecipients),
      switchMap(({ recipientsEmails }) => {
        return this.http.changeSecurityRecipients(recipientsEmails).pipe(
          map(updatedSystemSettings =>
            systemSettingsActions.changeSecurityRecipientsSuccess({ updatedSystemSettings })
          ),
          catchError(() =>
            of(
              systemSettingsActions.changeSecurityRecipientsError({
                errorMessage: 'ERROR_CHANGING_SECURITY_RECIPIENTS',
              })
            )
          )
        );
      })
    )
  );

  getOnlineUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.getOnlineUsers),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.getOnlineUsers(rest).pipe(
          map(users => {
            return systemSettingsActions.getOnlineUsersSuccess({ users });
          }),
          catchError(error => {
            const errorMessage = parseError(error, systemSettingsActions.getOnlineUsers.type);
            this.snackService.showError(errorMessage);
            return of(systemSettingsActions.getOnlineUsersError({ errorMessage }));
          })
        );
      })
    )
  );

  getAdmins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.getAdmins),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.getAdmins(rest).pipe(
          map(admins => {
            return systemSettingsActions.getAdminsSuccess({ admins });
          }),
          catchError(error => {
            const errorMessage = parseError(error, systemSettingsActions.getAdmins.type);
            this.snackService.showError(errorMessage);
            return of(systemSettingsActions.getAdminsError({ errorMessage }));
          })
        );
      })
    )
  );

  getAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.getAdmin),
      switchMap(({ adminId }) => {
        return this.http.getAdmin(adminId).pipe(
          map(admin => {
            return systemSettingsActions.getAdminSuccess({ admin });
          }),
          catchError(error => {
            const errorMessage = parseError(error, systemSettingsActions.getAdmin.type);
            this.snackService.showError(errorMessage);
            return of(systemSettingsActions.getAdminError({ errorMessage }));
          })
        );
      })
    )
  );

  createAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.createAdmin),
      switchMap(({ payload }) => {
        return this.http.createAdmin(payload).pipe(
          map(({ user, emailSent }) => {
            if (emailSent) {
              this.snackService.showInfo('AUTH.ACTIVATION-EMAIL-SENT');
            } else {
              this.snackService.showError('EMPLOYEES.ACCOUNT-CREATED-BUT-EMAIL-SENDING-FAILED');
            }
            return systemSettingsActions.createAdminSuccess({ createdAdmin: user });
          }),
          catchError(error => {
            const errorMessage = parseError(error, systemSettingsActions.createAdmin.type);
            this.snackService.showError(errorMessage);
            return of(systemSettingsActions.createAdminError({ errorMessage }));
          })
        );
      })
    )
  );

  updateAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.updateAdmin),
      switchMap(({ payload, userId }) => {
        return this.http.updateAdmin(payload, userId).pipe(
          map(updatedAdmin => {
            return systemSettingsActions.updateAdminSuccess({ updatedAdmin });
          }),
          catchError(error => {
            const errorMessage = parseError(error, systemSettingsActions.updateAdmin.type);
            this.snackService.showError(errorMessage);
            return of(systemSettingsActions.updateAdminError({ errorMessage }));
          })
        );
      })
    )
  );

  removeAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.removeAdmin),
      switchMap(({ userId }) => {
        return this.http.removeAdmin(userId).pipe(
          map(() => {
            this.snackService.showInfo('ADMINISTRATION.ADMINS.ADMIN-REMOVED');
            return systemSettingsActions.removeAdminSuccess({ removedAdminId: userId });
          }),
          catchError(error => {
            const errorMessage = parseError(error, systemSettingsActions.removeAdmin.type);
            this.snackService.showError(errorMessage);
            return of(systemSettingsActions.removeAdminError({ errorMessage }));
          })
        );
      })
    )
  );

  resendAdminActivation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.resendAdminActivation),
      switchMap(({ email }) => {
        return this.http.resendAdminActivation(email).pipe(
          map(({ emailSent }) => systemSettingsActions.resendAdminActivationSuccess({ emailSent })),
          catchError(error => {
            const errorMessage = parseError(error, systemSettingsActions.resendAdminActivation.type);
            return of(systemSettingsActions.resendAdminActivationError({ errorMessage }));
          })
        );
      })
    )
  );

  recreateAvatarsForAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.recreateAvatarsForAllUsers),
      switchMap(() => {
        return this.http.recreateAvatarsForAllUsers().pipe(
          map(() => systemSettingsActions.recreateAvatarsForAllUsersSuccess()),
          catchError(() => {
            return of(systemSettingsActions.recreateAvatarsForAllUsersError());
          })
        );
      })
    )
  );

  getProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.getProducts),
      switchMap(() => {
        return this.productsHttp.getProducts().pipe(
          map(products => systemSettingsActions.getProductsSuccess({ products })),
          catchError(() => {
            this.snackService.showError('ADMINISTRATION.PRODUCTS-DETAILS.ERROR_LOADING_PRODUCTS');
            return of(systemSettingsActions.getProductsError());
          })
        );
      })
    )
  );

  createProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.createProduct),
      switchMap(({ opts }) => {
        return this.productsHttp.createProduct(opts).pipe(
          map(product => systemSettingsActions.createProductSuccess({ product })),
          catchError(() => {
            this.snackService.showError('ADMINISTRATION.PRODUCTS-DETAILS.ERROR_CREATING_PRODUCT');
            return of(systemSettingsActions.createProductError());
          })
        );
      })
    )
  );

  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.updateProduct),
      switchMap(({ opts }) => {
        return this.productsHttp.updateProduct(opts).pipe(
          map(updatedProduct => systemSettingsActions.updateProductSuccess({ updatedProduct })),
          catchError(() => {
            this.snackService.showError('NT4.ERROR_UPDATING_PRODUCT');
            return of(systemSettingsActions.updateProductError({ productId: opts.productId }));
          })
        );
      })
    )
  );
  getProductHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemSettingsActions.getProductHistory),
      switchMap(({ productId }) => {
        return this.productsHttp.getProductHistory(productId).pipe(
          map(productHistory => systemSettingsActions.getProductHistorySuccess({ productHistory })),
          catchError(() => {
            this.snackService.showError('NT4.ERROR_LOADING_PRODUCT_CHANGES_HISTORY');
            return of(systemSettingsActions.getProductHistoryError({ productId }));
          })
        );
      })
    )
  );
}
