<ng-container *ngIf="categoryForm === PROCESS_CATEGORY.TEMP_PREMIT">
  <app-temp-premit-form
    [user]="user"
    [userProcess]="userProcess"
    [currentLang]="currentLang"
    [loadingUserProcess]="loadingUserProcess"
    [errorMessage]="errorMessage"
    [mode]="mode"
    (updateDetails)="updateDetails.emit($event)"
    (confirmVoivoChange)="confirmVoivoChange.emit($event)"
    (toggleVerificationOfPersonalDetails)="toggleVerificationOfPersonalDetails.emit($event)"
    (confirmDetails)="confirmDetails.emit($event)"
    [correctlyFilledSteps]="correctlyFilledSteps"
    [personalDataForm]="personalDataForm"
    [percentageOfCompletness]="percentageOfCompletness"
    [totalStepsInForm]="totalStepsInForm"
    [dateFormatsForFields]="dateFormatsForFields">
  </app-temp-premit-form>
</ng-container>

<ng-container *ngIf="categoryForm === PROCESS_CATEGORY.LONG_RESI">
  <app-long-resi-form
    [user]="user"
    [userProcess]="userProcess"
    [currentLang]="currentLang"
    [loadingUserProcess]="loadingUserProcess"
    [errorMessage]="errorMessage"
    [mode]="mode"
    (updateDetails)="updateDetails.emit($event)"
    (confirmVoivoChange)="confirmVoivoChange.emit($event)"
    (toggleVerificationOfPersonalDetails)="toggleVerificationOfPersonalDetails.emit($event)"
    (confirmDetails)="confirmDetails.emit($event)"
    [correctlyFilledSteps]="correctlyFilledSteps"
    [personalDataForm]="personalDataForm"
    [percentageOfCompletness]="percentageOfCompletness"
    [totalStepsInForm]="totalStepsInForm"
    [dateFormatsForFields]="dateFormatsForFields">
  </app-long-resi-form>
</ng-container>

<ng-container *ngIf="categoryForm === PROCESS_CATEGORY.PERM_STAY">
  <app-perm-stay-form
    [user]="user"
    [userProcess]="userProcess"
    [currentLang]="currentLang"
    [loadingUserProcess]="loadingUserProcess"
    [errorMessage]="errorMessage"
    [mode]="mode"
    (updateDetails)="updateDetails.emit($event)"
    (confirmVoivoChange)="confirmVoivoChange.emit($event)"
    (toggleVerificationOfPersonalDetails)="toggleVerificationOfPersonalDetails.emit($event)"
    (confirmDetails)="confirmDetails.emit($event)"
    [correctlyFilledSteps]="correctlyFilledSteps"
    [personalDataForm]="personalDataForm"
    [percentageOfCompletness]="percentageOfCompletness"
    [totalStepsInForm]="totalStepsInForm"
    [dateFormatsForFields]="dateFormatsForFields">
  </app-perm-stay-form>
</ng-container>
