<mat-dialog-content>
  <div class="title-wrapper">
    <h1>
      Urządzenie nie obsługiwane
      <br />
      Device not supported
      <br />
      Пристрій не підтримується
      <br />
      Устройство не поддерживается
    </h1>
  </div>

  <div class="message-wrapper">
    <p>
      Niestety, aplikacja obecnie nie obsługuje urządzeń o rozdzielczości mniejszej niż 960px.<br />
      Jest nam niezmiernie przykro.
      <br /><br />
      Unfortunately, the app currently does not support devices with a resolution lower than 960px.<br />
      We are extremely sorry.
      <br /><br />
      На жаль, програма наразі не підтримує пристрої з роздільною здатністю нижче 960 пікселів.<br />
      Нам дуже шкода.
      <br /><br />
      К сожалению, в настоящее время приложение не поддерживает устройства с разрешением ниже 960 пикселей.<br />
      Нам очень жаль.
    </p>
  </div>
</mat-dialog-content>
