<div class="chats-list-toggle-button open" (click)="toggleChat()">
  <nz-badge [nzCount]="unreadCount$ | async" [nzStandalone]="true"></nz-badge>
  <mat-icon *ngIf="!showChatsPanel">chevron_left</mat-icon>
</div>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="showChatsPanel"
  [nzPlacement]="'right'"
  [nzWidth]="'100vw'"
  (nzOnClose)="toggleChat()"
  [nzBodyStyle]="{
    padding: '0'
  }"
  [nzMask]="true"
  [nzMaskClosable]="true">
  <ng-container *nzDrawerContent>
    <div class="chats-list-wrapper">
      <div class="chats-inner-wrapper">
        <button mat-icon-button (click)="toggleChat()" class="chats-close-button">
          <mat-icon>close</mat-icon>
        </button>
        <mat-tab-group style="height: 100%">
          <mat-tab label="{{ 'NT3.SUPPORT' | translate }}">
            <ng-template matTabContent>
              <app-support-chat-mobile (openningSupportChatEventEmitter)="toggleChatWindow()"></app-support-chat-mobile>
            </ng-template>
          </mat-tab>

          <mat-tab [disabled]="true" nzTooltipTitle="Coming soon" nzTooltipPlacement="top">
            <ng-template mat-tab-label>
              <label matTooltip="Coming soon">
                {{ 'NT3.PRIVATE_CHATS' | translate }}
              </label>
            </ng-template>
            <ng-template matTabContent>
              <div class="coming-soon">
                <p>COMING SOON</p>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </ng-container>
</nz-drawer>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="showChatsPanelWindow"
  [nzPlacement]="'right'"
  [nzWidth]="'100vw'"
  (nzOnClose)="toggleChatWindow()"
  [nzBodyStyle]="{
    padding: '0'
  }"
  [nzMask]="true"
  [nzMaskClosable]="true"
  [nzZIndex]="1001">
  <ng-container *nzDrawerContent>
    <app-support-chat-window-mobile
      *ngIf="openSupportChat$ | async"
      (openningSupportChatEventEmitter)="toggleChatWindow($event)"></app-support-chat-window-mobile>
  </ng-container>
</nz-drawer>
