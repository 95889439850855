// Names must be unique
// SVG code cannot contain fill or stroke color defined - must be set to currentColor
// adding next item in this object will automatically register new icon which might be used with:
// <mat-icon svgIcon="iconName"></mat-icon>

export const icons: { [key: string]: string } = {
  trash: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.66675 10L6.66675 8" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round"/>
  <path d="M9.33325 10L9.33325 8" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round"/>
  <path d="M2 4.6665H14V4.6665C13.3787 4.6665 13.0681 4.6665 12.8231 4.768C12.4964 4.90332 12.2368 5.16289 12.1015 5.48959C12 5.73462 12 6.04525 12 6.6665V10.6665C12 11.9236 12 12.5521 11.6095 12.9426C11.219 13.3332 10.5904 13.3332 9.33333 13.3332H6.66667C5.40959 13.3332 4.78105 13.3332 4.39052 12.9426C4 12.5521 4 11.9236 4 10.6665V6.6665C4 6.04525 4 5.73462 3.89851 5.48959C3.76318 5.16289 3.50362 4.90332 3.17691 4.768C2.93188 4.6665 2.62126 4.6665 2 4.6665V4.6665Z" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round"/>
  <path d="M6.71202 2.24706C6.78798 2.17618 6.95538 2.11355 7.18824 2.06888C7.4211 2.02421 7.70641 2 7.99992 2C8.29343 2 8.57874 2.02421 8.8116 2.06888C9.04446 2.11355 9.21185 2.17618 9.28782 2.24706" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round"/>
  </svg>,`,

  send: `<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.42092 1.77051C8.0196 1.8419 7.46145 2.02587 6.60935 2.30991L3.25013 3.42964C2.97273 3.52211 2.74233 3.59926 2.55109 3.66681C2.74233 3.73437 2.97273 3.81152 3.25013 3.90399L3.50671 3.98951C3.55346 4.0051 3.59966 4.02044 3.64531 4.0356C4.43926 4.29925 5.06842 4.50818 5.53031 4.97008C5.99221 5.43197 6.20114 6.06113 6.46479 6.85508C6.47995 6.90073 6.4953 6.94693 6.51088 6.99368L6.5964 7.25026C6.68887 7.52766 6.76602 7.75806 6.83357 7.9493C6.90113 7.75806 6.97828 7.52766 7.07075 7.25026L8.19049 3.89104C8.47452 3.03894 8.65849 2.48079 8.72989 2.07947C8.76366 1.88959 8.76155 1.79107 8.75505 1.74534C8.70932 1.73884 8.6108 1.73673 8.42092 1.77051ZM8.78894 1.75489C8.78833 1.75538 8.78366 1.75412 8.77676 1.7496C8.7861 1.75213 8.78955 1.75439 8.78894 1.75489ZM8.75079 1.72363C8.74627 1.71673 8.74501 1.71206 8.74551 1.71145C8.746 1.71084 8.74826 1.71429 8.75079 1.72363ZM8.15821 0.293691C8.6886 0.199339 9.33354 0.187514 9.82321 0.677183C10.3129 1.16685 10.3011 1.81179 10.2067 2.34218C10.1137 2.86524 9.89229 3.5292 9.63259 4.30814L9.61351 4.36539L8.49377 7.7246C8.48809 7.74166 8.48242 7.75865 8.47678 7.77558C8.29531 8.32016 8.13534 8.80018 7.97041 9.13921C7.83102 9.42575 7.50923 10.0023 6.83358 10.0023C6.15792 10.0023 5.83613 9.42575 5.69674 9.13921C5.53181 8.80018 5.37185 8.32016 5.19037 7.77559C5.18473 7.75866 5.17907 7.74166 5.17338 7.7246L5.08785 7.46802C4.75369 6.46553 4.6489 6.20998 4.46965 6.03074C4.29041 5.85149 4.03486 5.7467 3.03237 5.41254L2.77579 5.32701C2.75874 5.32133 2.74175 5.31567 2.72482 5.31003C2.18024 5.12855 1.70021 4.96858 1.36118 4.80365C1.07464 4.66426 0.498047 4.34247 0.498047 3.66681C0.498047 2.99116 1.07464 2.66937 1.36118 2.52998C1.70021 2.36505 2.18024 2.20508 2.72481 2.02361C2.74174 2.01797 2.75874 2.0123 2.77579 2.00662L6.135 0.88688C6.15416 0.880494 6.17326 0.874129 6.19228 0.867787C6.9712 0.608097 7.63516 0.386738 8.15821 0.293691Z" fill="white"/>
  </svg>`,

  factcheck: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
  <path stroke="currentColor" d="M160-120q-33 0-56.5-23.5T80-200v-560q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v560q0 33-23.5 56.5T800-120H160Zm0-80h640v-560H160v560Zm40-80h200v-80H200v80Zm382-80 198-198-57-57-141 142-57-57-56 57 113 113Zm-382-80h200v-80H200v80Zm0-160h200v-80H200v80Zm-40 400v-560 560Z"/>
  </svg>`,

  'check-outline': `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="9" cy="9" r="7.5" stroke="currentColor" stroke-width="1.25"/>
  <path d="M8.16667 10.6667L6.5 9" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
  <path d="M8.16667 10.6667L11.5 6.5" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
  </svg>`,

  refresh: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.66666 12.6667L6.24239 12.2424L5.81813 12.6667L6.24239 13.0909L6.66666 12.6667ZM8.90906 9.57574L6.24239 12.2424L7.09092 13.0909L9.75759 10.4243L8.90906 9.57574ZM6.24239 13.0909L8.90906 15.7576L9.75759 14.9091L7.09092 12.2424L6.24239 13.0909Z" fill="currentColor"/>
  <path d="M3.95854 10.3335C3.44489 9.44384 3.23916 8.40955 3.37325 7.39104C3.50733 6.37254 3.97375 5.42674 4.70016 4.70033C5.42656 3.97393 6.37236 3.50751 7.39087 3.37342C8.40937 3.23933 9.44366 3.44506 10.3333 3.95871C11.223 4.47236 11.9183 5.26521 12.3114 6.21431C12.7046 7.1634 12.7735 8.2157 12.5076 9.20799C12.2418 10.2003 11.6559 11.0771 10.8409 11.7025C10.0259 12.3279 9.02728 12.6668 7.99999 12.6668" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"/>
  </svg>`,

  'info-outline': `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="9" cy="9" r="7.5" stroke="currentColor" stroke-width="1.25"/>
  <path d="M9.41668 5.25016C9.41668 5.48028 9.23013 5.66683 9.00001 5.66683C8.76989 5.66683 8.58334 5.48028 8.58334 5.25016C8.58334 5.02004 8.76989 4.8335 9.00001 4.8335C9.23013 4.8335 9.41668 5.02004 9.41668 5.25016Z" fill="currentColor" stroke="currentColor" stroke-width="0.833333"/>
  <path d="M9 13.1665V8.1665" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,

  person: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="9" cy="5.25" r="3.15" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"/>
  <path d="M3.15412 14.6063C3.59061 11.9136 6.27214 10.5 9 10.5V10.5C11.7279 10.5 14.4094 11.9136 14.8459 14.6063C14.8924 14.8936 14.9303 15.1922 14.9569 15.5008C15.0042 16.0511 14.5523 16.5 14 16.5H4C3.44772 16.5 2.99579 16.0511 3.04312 15.5008C3.06967 15.1922 3.10755 14.8936 3.15412 14.6063Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"/>
  </svg>`,

  document: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.87868 2.25H6.75C5.33579 2.25 4.62868 2.25 4.18934 2.68934C3.75 3.12868 3.75 3.83579 3.75 5.25V12.75C3.75 14.1642 3.75 14.8713 4.18934 15.3107C4.62868 15.75 5.33579 15.75 6.75 15.75H11.25C12.6642 15.75 13.3713 15.75 13.8107 15.3107C14.25 14.8713 14.25 14.1642 14.25 12.75V6.62132C14.25 6.31476 14.25 6.16148 14.1929 6.02365C14.1358 5.88582 14.0274 5.77743 13.8107 5.56066L10.9393 2.68934C10.7226 2.47257 10.6142 2.36418 10.4764 2.30709C10.3385 2.25 10.1852 2.25 9.87868 2.25Z" stroke="currentColor" stroke-width="1.2"/>
  <path d="M6.75 9.75L11.25 9.75" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"/>
  <path d="M6.75 12.75L9.75 12.75" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"/>
  <path d="M9.75 2.25V5.25C9.75 5.95711 9.75 6.31066 9.96967 6.53033C10.1893 6.75 10.5429 6.75 11.25 6.75H14.25" stroke="currentColor" stroke-width="1.2"/>
  </svg>
  `,

  printer: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.4999 8.99967H12.611C13.1292 8.99967 13.3883 8.99967 13.5892 8.90598C13.8023 8.80662 13.9735 8.63537 14.0729 8.42229C14.1666 8.22137 14.1666 7.96229 14.1666 7.44412V7.44412C14.1666 6.40779 14.1666 5.88962 13.9792 5.48777C13.7805 5.06162 13.438 4.71911 13.0118 4.52039C12.61 4.33301 12.0918 4.33301 11.0555 4.33301H6.56658C4.93887 4.33301 4.12501 4.33301 3.57461 4.77872C3.466 4.86667 3.36691 4.96576 3.27896 5.07437C2.83325 5.62477 2.83325 6.43863 2.83325 8.06634V8.06634C2.83325 8.47327 2.83325 8.67673 2.94468 8.81433C2.96667 8.84149 2.99144 8.86626 3.01859 8.88825C3.15619 8.99967 3.35966 8.99967 3.76658 8.99967H4.49992" stroke="currentColor"/>
  <path d="M4.83325 12.9733L4.83325 8.33366C4.83325 7.39085 4.83325 6.91944 5.12615 6.62655C5.41904 6.33366 5.89044 6.33366 6.83325 6.33366L10.1666 6.33366C11.1094 6.33366 11.5808 6.33366 11.8737 6.62655C12.1666 6.91944 12.1666 7.39085 12.1666 8.33366L12.1666 12.9733C12.1666 13.2898 12.1666 13.4481 12.0628 13.5229C11.9589 13.5978 11.8088 13.5477 11.5085 13.4476L10.3403 13.0582C10.2544 13.0296 10.2114 13.0153 10.1671 13.0166C10.1227 13.0179 10.0807 13.0347 9.99653 13.0683L8.68561 13.5927C8.59391 13.6294 8.54806 13.6477 8.49992 13.6477C8.45177 13.6477 8.40592 13.6294 8.31422 13.5927L7.00331 13.0683C6.91917 13.0347 6.8771 13.0179 6.83277 13.0166C6.78845 13.0153 6.74546 13.0296 6.6595 13.0582L5.49137 13.4476C5.19105 13.5477 5.0409 13.5978 4.93707 13.5229C4.83325 13.4481 4.83325 13.2898 4.83325 12.9733Z" stroke="currentColor"/>
  <path d="M6.83325 9L9.49992 9" stroke="currentColor" stroke-linecap="round"/>
  <path d="M6.83325 11L10.1666 11" stroke="currentColor" stroke-linecap="round"/>
  <path d="M12.1666 4.33366V4.33366C12.1666 3.52625 12.1666 3.12254 12.0394 2.80242C11.8564 2.34187 11.4917 1.97713 11.0312 1.79417C10.711 1.66699 10.3073 1.66699 9.49992 1.66699H7.49992C6.6925 1.66699 6.2888 1.66699 5.96868 1.79417C5.50813 1.97713 5.14339 2.34187 4.96043 2.80242C4.83325 3.12254 4.83325 3.52625 4.83325 4.33366V4.33366" stroke="currentColor"/>
  </svg>
  `,

  datafile: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 493.474 493.474" xml:space="preserve" width="64px" height="64px" fill="currentColor" stroke="currentColor">
  <g stroke-width="0"></g>
  <g stroke-linecap="round" stroke-linejoin="round"></g>
  <g> <g> <g>
  <path stroke="currentColor" fill="currentColor" d="M314.284,314.029c-30.563,23.814-55.479,53.227-80.686,82.352 c-10.786-11.428-20.596-23.559-26.917-38.184c-7.154-16.676-34.342,7.584-27.895,22.598c8.817,20.387,24.519,36.965,39.977,52.584 c6.305,6.416,17.138-0.832,21.491-5.793c27.652-31.398,53.448-64.443,86.638-90.305 C342.816,324.894,333.261,299.242,314.284,314.029z"></path>
  <path stroke="currentColor" fill="currentColor" d="M402.266,35.399h-76.354c-5.358-5.895-13.04-9.679-21.632-9.679h-29.412v-4.984 C274.867,9.946,260.415,0,246.738,0c-13.678,0-28.13,9.946-28.13,20.736v4.984h-29.413c-8.592,0-16.273,3.784-21.632,9.679H91.176 c-8.099,0-14.594,6.554-14.594,14.619v428.829c0,8.049,6.479,14.627,14.594,14.627h311.091c8.081,0,14.626-6.527,14.626-14.627 V50.018C416.891,41.953,410.346,35.399,402.266,35.399z M387.607,464.238H105.787V64.636h42.285v0.003h13.47 c3.971,11.538,14.772,19.892,27.668,19.892h57.528h57.528c12.896,0,23.697-8.354,27.668-19.892h13.47v-0.003h42.204 L387.607,464.238L387.607,464.238z">
  </path>
  <rect x="133.1" y="114.818" stroke="currentColor" fill="currentColor" width="227.273" height="22.729"></rect>
  <rect x="133.1" y="164.818" stroke="currentColor" fill="currentColor" width="227.273" height="22.729"></rect>
  <rect x="133.1" y="214.818" stroke="currentColor" fill="currentColor" width="227.273" height="22.729"></rect>
  <rect x="133.1" y="264.818" stroke="currentColor" fill="currentColor" width="227.273" height="22.729"></rect>
  </g> </g> </g>
  </svg>`,
};
