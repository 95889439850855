import { Action, createReducer, on } from '@ngrx/store';
import { initialState, AnnexState } from './annex.state';
import * as annexActions from './annex.actions';

const reducer = createReducer(
  initialState,

  on(annexActions.getAnnexes, (state, { opts }) => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(annexActions.getAnnexesSuccess, (state, { annexes, userProcessId }) => ({
    ...state,
    annexesInUserProcess: { userProcessId, annexes },
    loading: false,
    errorMessage: null,
  })),
  on(annexActions.getAnnexesError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(annexActions.createAnnex, (state, { opts }) => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(annexActions.createAnnexSuccess, (state, { annex, userProcessId }) => ({
    ...state,
    annexesInUserProcess: {
      annexes: [...state.annexesInUserProcess.annexes, annex],
      userProcessId,
    },
    loading: false,
    errorMessage: null,
  })),
  on(annexActions.createAnnexError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(annexActions.updateAnnex, (state, { opts }) => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(annexActions.updateAnnexSuccess, (state, { annex }) => ({
    ...state,
    annexesInUserProcess: {
      annexes: state.annexesInUserProcess.annexes.map(a1 => (a1.id === annex.id ? annex : a1)),
      userProcessId: state.annexesInUserProcess.userProcessId,
    },
    loading: false,
    errorMessage: null,
  })),
  on(annexActions.updateAnnexError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(annexActions.removeAnnex, (state, { opts }) => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(annexActions.removeAnnexSuccess, (state, { annexId }) => ({
    ...state,
    annexesInUserProcess: {
      annexes: state.annexesInUserProcess.annexes.filter(a1 => a1.id !== annexId),
      userProcessId: state.annexesInUserProcess.userProcessId,
    },
    loading: false,
    errorMessage: null,
  })),
  on(annexActions.removeAnnexError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(annexActions.getSpecialities, (state, {}) => ({
    ...state,
    loadingSpecialities: true,
    errorMessage: null,
  })),
  on(annexActions.getSpecialitiesSuccess, (state, { specialities }) => ({
    ...state,
    loadingSpecialities: false,
    errorMessage: null,
    specialities,
  })),
  on(annexActions.getSpecialitiesError, (state, { errorMessage }) => ({
    ...state,
    loadingSpecialities: false,
    errorMessage,
  }))
);

export function annexReducer(state: AnnexState | undefined, action: Action): AnnexState {
  return reducer(state, action);
}
