import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class GTagManagerService {
  private GOOGLE_TAG_ENABLED = environment.GOOGLE_TAG_ENABLED;
  private GOOGLE_TAG_CONTAINER_ID = environment.GOOGLE_TAG_CONTAINER_ID;

  public addGoogleTagIfEnabled(): void {
    if (!this.GOOGLE_TAG_ENABLED || !this.GOOGLE_TAG_CONTAINER_ID?.length) {
      return;
    }

    const headScriptEl = document.createElement('script');
    headScriptEl.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${this.GOOGLE_TAG_CONTAINER_ID}');`;

    const bodyScriptEl = document.createElement('noscript');
    const bodyInnerEl = document.createElement('iframe');
    bodyInnerEl.src = `https://www.googletagmanager.com/ns.html?id=${this.GOOGLE_TAG_CONTAINER_ID}`;
    bodyInnerEl.style.display = 'none';
    bodyInnerEl.style.visibility = 'hidden';
    bodyInnerEl.height = '0';
    bodyInnerEl.width = '0';
    bodyScriptEl.append(bodyInnerEl);

    document.head.prepend(headScriptEl);
    document.body.prepend(bodyScriptEl);
  }
}
