import Step from 'node_modules/shepherd.js/src/types/step';

import { TranslateService } from '@ngx-translate/core';

interface AvailableButtons {
  counter: Step.StepOptionsButton;
  next: Step.StepOptionsButton;
  back: Step.StepOptionsButton;
  cancel: Step.StepOptionsButton;
  done: Step.StepOptionsButton;
}

const gPrefix = 'GUIDE';
const prefix = `${gPrefix}.FORM-STEP-4`;

export const getSteps = (t: TranslateService, currLang: string): Step.StepOptions[] => {
  const buttons: AvailableButtons = {
    counter: { text: '', action(): void {}, classes: 'counter-button' },
    next: {
      text: t.instant(`${gPrefix}.NEXT`),
      action(): void {
        this.next();
      },
      classes: 'standard-next-button',
    },
    back: {
      text: t.instant(`${gPrefix}.BACK`),
      action(): void {
        this.back();
      },
      classes: 'standard-back-button',
    },
    cancel: {
      text: t.instant(`${gPrefix}.CLOSE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-cancel-button',
    },
    done: {
      text: t.instant(`${gPrefix}.DONE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-done-button',
    },
  };

  const steps: Step.StepOptions[] = [
    {
      id: 'staystravelsinfo1',
      arrow: false,
      modalOverlayOpeningPadding: 40,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.noPreviousVisits-field label', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      beforeShowPromise: (): Promise<any> => {
        return new Promise((resolve, reject) => {
          const el = document.getElementsByClassName('noPreviousVisits-field')?.item(0);
          const scrollable = document.getElementsByClassName('content')?.item(0);

          if (!el || !scrollable) {
            return reject();
          }

          window.setTimeout(() => {
            scrollable.scrollTo({ behavior: 'smooth', top: el.scrollTop + 200 });
            resolve(true);
          }, 200);
        });
      },
      title: t.instant(`${prefix}.staystravelsinfo1.TITLE`),
      text: `${t.instant(`${prefix}.staystravelsinfo1.TEXT`)}
      <div class="gif-wrapper bigger-gif">
        <img src='https://storage.googleapis.com/public-bridge-assets/stays-travels.gif' alt="Stays travels">
      </div>`,
    },
    {
      id: 'staystravelsinfo2',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.noLastTravelsOutsidePoland-field', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      beforeShowPromise: (): Promise<any> => {
        return new Promise((resolve, reject) => {
          const el = document.getElementsByClassName('noLastTravelsOutsidePoland-field')?.item(0);
          const scrollable = document.getElementsByClassName('content')?.item(0);

          if (!el || !scrollable) {
            return reject();
          }

          window.setTimeout(() => {
            scrollable.scrollTo({ behavior: 'smooth', top: el.scrollTop + 650 });
            resolve(true);
          }, 200);
        });
      },
      title: t.instant(`${prefix}.staystravelsinfo2.TITLE`),
      text: `${t.instant(`${prefix}.staystravelsinfo2.TEXT`)}`,
    },
  ];

  const guideName = t.instant(`${prefix}.GUIDE_NAME`);
  return steps.map((step, currIndex, all) => {
    const formattedButtons: Step.StepOptionsButton[] = [
      { ...buttons.counter, text: `${guideName} ${currIndex + 1}/${all.length}` },
    ];

    if (currIndex === 0) {
      formattedButtons.push({ ...buttons.cancel });
    } else {
      formattedButtons.push({ ...buttons.back });
    }

    if (currIndex < all.length - 1) {
      formattedButtons.push({ ...buttons.next });
    } else {
      formattedButtons.push({ ...buttons.done });
    }

    return { ...step, buttons: formattedButtons };
  });
};
