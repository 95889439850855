import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import {
  ActivateAccountPayload,
  InvoiceData,
  Language,
  SetNewPasswordPayload,
  UpdateAdminselfPayload,
  UpdateMyselfPayload,
  User,
} from '@interfaces';

import { AppState } from '@state';
import * as userAuthActions from './user-auth.actions';
import * as userAuthSelectors from './user-auth.selectors';

@Injectable()
export class UserAuthFacade {
  public myself$ = this.store.select(userAuthSelectors.selectMyself);

  public loading$ = this.store.select(userAuthSelectors.selectLoading);
  public errorMessage$ = this.store.select(userAuthSelectors.selectErrorMessage);

  public userUpdateSuccess$ = this.actions.pipe(ofType(userAuthActions.updateMyselfSuccess));
  public activateAccountSuccess$ = this.actions.pipe(ofType(userAuthActions.activateAccountSuccess));

  public userUpdateError$ = this.actions.pipe(ofType(userAuthActions.updateMyselfError));

  public getPasswordResetLinkSuccess$ = this.actions.pipe(ofType(userAuthActions.getPasswordResetLinkSuccess));
  public setNewPasswordSuccess$ = this.actions.pipe(ofType(userAuthActions.setNewPasswordSuccess));

  public loginSuccess$ = this.actions.pipe(ofType(userAuthActions.loginSuccess));
  public getMyselfSuccess$ = this.actions.pipe(ofType(userAuthActions.getMyselfSuccess));
  public updateMyInvoiceDataSuccess$ = this.actions.pipe(ofType(userAuthActions.updateMyInvoiceDataSuccess));
  public updateMyInvoiceDataError$ = this.actions.pipe(ofType(userAuthActions.updateMyInvoiceDataError));

  public deleteAccountSuccess$ = this.actions.pipe(ofType(userAuthActions.deleteAccountSuccess));
  public deleteAccountError$ = this.actions.pipe(ofType(userAuthActions.deleteAccountError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public activateAccount(payload: ActivateAccountPayload): void {
    this.store.dispatch(userAuthActions.activateAccount({ payload }));
  }

  public login(email: string, password: string, userLangId: Language['id'], redirect: string | null): void {
    this.store.dispatch(userAuthActions.login({ payload: { email, password, userLangId }, redirect }));
  }

  public logout(opts?: { wasUnauthorized?: boolean; sessionTimedOutLogout?: boolean }): void {
    this.store.dispatch(userAuthActions.logout(opts));
  }

  public deleteAccount(password: string): void {
    this.store.dispatch(userAuthActions.deleteAccount({ password }));
  }

  public getMyself(redirect: string | null): void {
    this.store.dispatch(userAuthActions.getMyself({ redirect }));
  }

  public getMyself$(redirect: string | null): Observable<User> {
    return this.store.select(userAuthSelectors.selectMyself).pipe(
      tap(myself => {
        if (!myself || !myself.role?.key) {
          this.getMyself(redirect);
        }
      }),
      filter(myself => {
        if (!myself || !myself.role?.key) {
          return false;
        }
        return true;
      })
    );
  }

  public updateMyself(payload: UpdateMyselfPayload): void {
    this.store.dispatch(userAuthActions.updateMyself({ payload }));
  }

  public updateMyInvoiceData(invoiceData: InvoiceData): void {
    this.store.dispatch(userAuthActions.updateMyInvoiceData({ invoiceData }));
  }

  public updateAdminself(userId: string, payload: UpdateAdminselfPayload): void {
    this.store.dispatch(userAuthActions.updateAdminself({ userId, payload }));
  }

  public getPasswordResetLink(email: string): void {
    this.store.dispatch(userAuthActions.getPasswordResetLink({ email }));
  }

  public setNewPassword(payload: SetNewPasswordPayload): void {
    this.store.dispatch(userAuthActions.setNewPassword({ payload }));
  }

  public resetErrorMessage(): void {
    this.store.dispatch(userAuthActions.getPasswordResetLinkError({ errorMessage: null }));
  }

  public regenerateInitialsAvatar(): void {
    this.store.dispatch(userAuthActions.regenerateInitialsAvatar());
  }
}
