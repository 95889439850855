import { Injectable } from '@angular/core';
import { CreateDiscountCodeOpts, EditDiscountCodeOpts, SearchDiscountCodesOpts } from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import * as dcActions from './discount-codes.actions';
import * as dcSelectors from './discount-codes.selectors';

@Injectable()
export class DiscountCodesFacade {
  public loading$ = this.store.select(dcSelectors.selectLoading);

  public discountCodes$ = this.store.select(dcSelectors.selectDiscountCodes);
  public discountCodesPagination$ = this.store.select(dcSelectors.selectDiscountCodesPagination);
  public discountCodeDetails$ = this.store.select(dcSelectors.selectDiscountCodeDetails);
  public checkIfCodeExistsInProgress$ = this.store.select(dcSelectors.checkIfCodeExistsInProgress);

  public createDiscountCodeError$ = this.actions.pipe(ofType(dcActions.createDiscountCodeError));
  public createDiscountCodeSuccess$ = this.actions.pipe(ofType(dcActions.createDiscountCodeSuccess));
  public editDiscountCodeError$ = this.actions.pipe(ofType(dcActions.editDiscountCodeError));
  public editDiscountCodeSuccess$ = this.actions.pipe(ofType(dcActions.editDiscountCodeSuccess));
  public getDiscountCodeDetailsError$ = this.actions.pipe(ofType(dcActions.getDiscountCodeDetailsError));

  public checkIfCodeExistsSuccess$ = this.actions.pipe(ofType(dcActions.checkIfDiscountCodeExistsSuccess));
  public checkIfCodeExistsError$ = this.actions.pipe(ofType(dcActions.checkIfDiscountCodeExistsError));

  public dcError$ = this.actions.pipe(
    ofType(dcActions.createDiscountCodeError, dcActions.setActiveDiscountCodeError, dcActions.searchDiscountCodesError)
  );

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public searchDiscountCodes(opts: SearchDiscountCodesOpts): void {
    this.store.dispatch(dcActions.searchDiscountCodes({ opts }));
  }

  public getDiscountCodeDetails(discountCodeId: string): void {
    this.store.dispatch(dcActions.getDiscountCodeDetails({ discountCodeId }));
  }

  public checkIfCodeExists(code: string): void {
    this.store.dispatch(dcActions.checkIfDiscountCodeExists({ code }));
  }

  public createDiscountCode(opts: CreateDiscountCodeOpts): void {
    this.store.dispatch(dcActions.createDiscountCode({ opts }));
  }
  public editDiscountCode(opts: EditDiscountCodeOpts): void {
    this.store.dispatch(dcActions.editDiscountCode({ opts }));
  }
  public setActiveDiscountCode(discountCodeId: string, setActive: 'deactivate' | 'activate'): void {
    this.store.dispatch(dcActions.setActiveDiscountCode({ discountCodeId, setActive }));
  }
}
