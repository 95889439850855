import { Process } from '@interfaces';
import { Question } from './question';

export const ADDITIONAL_DOC_KEY = 'ADDITIONAL_DOCUMENTS';
export const ADDITIONAL_DOC_NAME_QUESTION_KEY = 'DOC_NAME';

export interface ProcessDocumentsList {
  processDocs: ProcessDocument[];
  userDocs: UserDocument[];
}

export interface SummaryDocuments {
  generated: UserDocument[];
  uploaded: UserDocument[];
}

export interface ExtendedProcessDocsList extends ProcessDocumentsList {
  userDocsForView: { [key: string]: UserDocument };
  userAdditionalDocs: UserDocument[];
  additionalProcDoc: ProcessDocument;
}

export interface ProcessDocument {
  id: string;
  // if 'false' will be ignored everywhere
  enabled: boolean;
  name: { [key: string]: string };
  key: string;
  type: DocumentType;
  template: string | null;
  isFillablePdf: boolean;
  isMainRequestDoc: boolean;
  processId: string;
  process?: Process;
  voivodeshipId: string;
  documentQuestions: DocumentQuestion[];
  documentOrder: number;
}

export interface DocumentQuestion {
  id: string;
  questionOrder: number;
  key: string;
  question: Question;
  documentId: string;
  questionId: string;
}

export interface UserFile {
  filename: string;
  filemimetype: string;
}

export interface UserDocument {
  id?: string;
  documentComments?: DocumentComment[] | null;
  documentId: string;
  files: UserFile[];
  userDocumentAnswers: UserDocumentQuestionAnswer[];
  userProcessId: string;
  document?: ProcessDocument;
  updatedAt?: string;
  createdAt?: string;
  status?: UserDocumentStatus;
  // when asking for documents in documents-step
  hasNewMessages?: boolean;
  willNotUploadDocument: boolean;
}

export interface UserDocumentQuestionAnswer {
  answer: string;
  documentQuestionId: string;
  id?: string;
  userDocumentId?: string;
}

export interface AddFileToMyDocOpts {
  userProcessId: string;
  userDocumentId: string;
  files: File[];
}

export interface AnswerToMyDocQuestionOpts {
  questionAnswer: UserDocumentQuestionAnswer;
  userProcessId: string;
  userDocumentId: string;
}

/**
 * Used for POST + PUT
 * file, comments, answers OPTIONAL
 */
export interface SaveUserDocumentPayload {
  userProcessId: string;
  documentId: string;
  comments?: string;
  files?: any[];
  willNotUploadDocument: boolean;
  answers?: { documentQuestionId: string; answer: string }[];
}

export interface SaveUserAdditionalDocuments {
  userProcessId: string;
  documentId: string;
  files: {
    files?: any[];
    userDocId?: string;
    comments?: string;
  }[];
}

export interface DocumentComment {
  content: string;
  userDocumentId: string;

  // only when fetching already created document
  id?: string;
  createdAt?: string;
  authorId?: string;
  userProcessId?: string;
  authorIsForeigner?: boolean;
  readByAssignedEmployeeAt?: Date | null;
  readBy?: string[];
  author?: {
    id: string;
    email: string;
    name: string;
  };
}

export enum UserDocumentStatus {
  /**
   * UserDocument has been created for foreigne and now
   * foreigner might upload file & update answers & add comments in discussion
   * This is the start state of the document
   */
  IN_PREPARATION = 'IN_PREPARATION',

  /**
   * User saved document and sent it for review
   * User cannot change anything in document now
   */
  SENT_FOR_REVIEW = 'SENT_FOR_REVIEW',

  /**
   * Employee reviewed document+questions+comments and accepted it
   * User cannot change anything in document now
   */
  ACCEPTED = 'ACCEPTED',

  /**
   * Employee rejected document.
   * User should change it and send it for review again
   */
  REJECTED = 'REJECTED',
}

export enum DocumentType {
  /**
   * Documents which user has to provide to us. Usually scan&upload to app.
   * These documents needs to be verified by employees.
   */
  PROVIDED_FROM_USER = 'PROVIDED_FROM_USER',

  /**
   * Documents which are going to be generated by the system
   * basing on the user data & pre-filled template stored in the system
   * (also matched to the voivodeship and process type).
   * These doucments are not verified by employees.
   * These documents are only visible by the use when he prints everything at the finish of the process.
   */
  TO_GENERATE_FOR_USER = 'TO_GENERATE_FOR_USER',
}

export interface ChangeUserDocStatusOpts {
  foreignerId: string;
  userProcessId: string;
  userDocId: string;
  status: UserDocumentStatus;
}

export interface SearchDocument {
  voivodeshipId?: string;
  processId?: string;
  type?: DocumentType;
}

export interface ProcessDocumentGrouped {
  key: string;
  name: ProcessDocument['name'];
  type: DocumentType;
  docs: ProcessDocument[];
  documentOrder?: number;
  allEnabled: boolean;
  someEnabled: boolean;
  disabledForChanges: boolean;
}

export interface UpdateDocumentEnabled {
  id: string;
  enabled: boolean;
}

export interface UpdateDocumentsBulk {
  documentType: DocumentType;
  documents: UpdateDocumentEnabled[];
}

export interface UpdateForeignerDocumentQuestionAnswerPayload {
  userProcessId: string;
  userDocumentId: string;
  userDocumentAnswerId: string;
  answer: string | null;
}
