import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface ShowChatOpts {
  show: boolean;
  relatedForeignerId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public showChat$: ReplaySubject<ShowChatOpts> = new ReplaySubject<ShowChatOpts>(1);

  constructor() {}
}
