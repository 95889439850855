import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState } from './users.state';

export const selectUsersState = createFeatureSelector<UsersState>('users');

export const selectLoadedUsers = createSelector(selectUsersState, state => state.loadedUsersData);

export const selectLoadedUser = createSelector(selectUsersState, state => state.loadedUserData);

export const selectLoadedPagination = createSelector(selectUsersState, state => state.loadedUsersPagination);

export const selectLoading = createSelector(selectUsersState, state => state.loading);

export const selectErrorMessage = createSelector(selectUsersState, state => state.errorMessage);

export const selectEmployeeTypes = createSelector(selectUsersState, state => state.employeeTypes);
export const selectAllRoles = createSelector(selectUsersState, state => state.allRoles);
export const selectLoadingEmployeeTypes = createSelector(selectUsersState, state => state.loadingEmployeeTypes);

export const selectReassignInProgress = createSelector(selectUsersState, state => state.reassignInProgress);
