import * as moment from 'moment-timezone';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PopupOpts } from './visit-date-modal.service';
import { DatepickerHeaderComponent } from '@shared/datepicker-header/datepicker-header.component';
import { InAppNotificationsFacade } from '@state/notifications';
import {
  AvailabilityMode,
  InAppNotificationType,
  Pagination,
  ROLES_KEYS,
  SetVisitDateOpts,
  User,
  VoivodeshipOffice,
} from '@interfaces';
import { VoivodeshipsFacade } from '@state/voivodeships';
import { CommonsFacade } from '@state/commons';
import { UsersFacade } from '@state/users';

@Component({
  selector: 'app-visit-date-modal',
  templateUrl: './visit-date-modal.component.html',
  styleUrls: ['./visit-date-modal.component.scss'],
})
export class VisitDateModalComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('dialogContent') dialogContent: ElementRef;

  public visitDate: moment.Moment = null;
  public visitTime: string = null;
  public currentDate = moment();
  public pickerHeader = DatepickerHeaderComponent;
  public offices: VoivodeshipOffice[] = [];

  public AvailabilityMode = AvailabilityMode;

  public selectedVoivodeshipOfficeId: string;
  public visitAssistant: Partial<User> = null;
  public officeVisitReservationNumber: string = null;
  public updateStatusAndNotifyForeigner = false;

  public loadingEmployees$ = this.usersFacade.loading$;
  public employees$ = this.usersFacade.users$;
  public ROLES_KEYS = ROLES_KEYS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupOpts,
    public dialogRef: MatDialogRef<VisitDateModalComponent, SetVisitDateOpts>,
    private readonly confirmationModal: ConfirmationModalService,
    private readonly inAppNotification: InAppNotificationsFacade,
    private readonly voivodehipsFacade: VoivodeshipsFacade,
    private readonly commonsFacade: CommonsFacade,
    private readonly usersFacade: UsersFacade
  ) {}

  ngOnInit(): void {
    const {
      userProcess: {
        immigrationOfficeVisitDate,
        selectedVoivodeshipOfficeId,
        personalDetails,
        visitAssistant,
        officeVisitReservationNumber,
      },
      markNotificationOnSave,
    } = this.data;

    this.usersFacade
      .getEmployeeTypes$()
      .pipe(take(1))
      .subscribe(employeeTypes => {
        this.usersFacade.getEmployees({
          offset: 0,
          limit: 100,
          orderBy: 'user.name',
          orderDir: 'desc',
          roles: employeeTypes.map(role => role.id),
        } as Pagination);
      });

    const voivoId = personalDetails?.residenceVoivodeshipDetails?.id;
    if (voivoId) {
      this.voivodehipsFacade
        .getVoivodeships$()
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(voivos => {
          const voivo = voivos.find(({ id }) => id === voivoId);
          if (voivo) {
            this.offices = voivo.offices;
          }
        });
    } else {
      console.warn('NO_VOIVOID_CANT_FETCH_OFFICES');
    }

    if (visitAssistant) {
      this.visitAssistant = visitAssistant;
    }

    if (selectedVoivodeshipOfficeId) {
      this.selectedVoivodeshipOfficeId = selectedVoivodeshipOfficeId;
    }
    if (officeVisitReservationNumber) {
      this.officeVisitReservationNumber = officeVisitReservationNumber;
    }

    this.updateStatusAndNotifyForeigner = markNotificationOnSave;

    if (immigrationOfficeVisitDate) {
      this.visitDate = moment(immigrationOfficeVisitDate);
      this.visitTime = this.visitDate.format('HHmm');
    }

    this.inAppNotification.markAllOfTypeAsRead({
      inAppNotificationType: InAppNotificationType.FOREIGNER_SET_AVAILABILITY,
      relatedForeignerId: this.data.userProcess.userId,
      relatedUserProcessId: this.data.userProcess.id,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public compareUsers(userA: Partial<User> | null, userB: Partial<User> | null): boolean {
    return userA?.id === userB?.id;
  }

  public setDateFromChip(date: string): void {
    this.visitDate = moment(date, 'DD.MM.yyyy');
  }

  public submitDate(): void {
    const willNotify = this.updateStatusAndNotifyForeigner;
    this.confirmationModal
      .open({
        confirmationToTranslate: willNotify
          ? 'VISIT-DATE-MODAL.DO_YOU_CONFIRM_WITH_NOTIFICATION'
          : 'VISIT-DATE-MODAL.DO_YOU_CONFIRM_NO_NOTIFICATION',
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        // visitTime.length <= 4 because here we are using ng-mask
        if (!this.visitDate || !this.visitTime || this.visitTime.length < 4 || !this.visitAssistant?.id) {
          return;
        }

        if (!this.selectedVoivodeshipOfficeId) {
          return;
        }

        const visitTime = this.visitTime ? `${this.visitTime.slice(0, 2)}:${this.visitTime.slice(2, 4)}` : '';
        this.visitDate.set('h', parseInt(this.visitTime.slice(0, 2), 10));
        this.visitDate.set('m', parseInt(this.visitTime.slice(2, 4), 10));

        this.dialogRef.close({
          immigrationOfficeVisitDate: this.visitDate.toISOString(),
          selectedVoivodeshipOfficeId: this.selectedVoivodeshipOfficeId,
          assistantId: this.visitAssistant.id,
          notifyForeigner: this.updateStatusAndNotifyForeigner,
          updateStatus: this.updateStatusAndNotifyForeigner,
          officeVisitReservationNumber: this.officeVisitReservationNumber,
          userId: this.data.userProcess.userId,
          userProcessId: this.data.userProcess.id,
        });
      });
  }
}
