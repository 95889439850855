import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort, SortDirection } from '@angular/material/sort';
import { AvailableLanguages } from '@constants';
import { AllDeviceInfo, UserProcessChange, UserProcessChangeType } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { ManagementFacade } from '@state/management';
import { UserAuthFacade } from '@state/user-auth';
import { UserFilesFacade } from '@state/user-files';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ReplaySubject, takeUntil } from 'rxjs';
import { PopupOpts } from './changes-history-modal.service';

@Component({
  selector: 'app-changes-history-modal',
  templateUrl: './changes-history-modal.component.html',
  styleUrls: ['./changes-history-modal.component.scss'],
})
export class ChangesHistoryModalComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('dialogContent') dialogContent: ElementRef;

  public myself$ = this.userAuthFacade.myself$;
  public changesList: UserProcessChange[];

  public currentSortDir = 'desc' as SortDirection;
  public currentSortBy = 'createdAt';
  public columnsToDisplay: string[];

  public currLang: AvailableLanguages;
  public deviceInfo: AllDeviceInfo;

  public positiveChanges = [
    UserProcessChangeType.SENDING_TO_INPOL_SUCCESS,
    UserProcessChangeType.SENDING_TO_MOS_SUCCESS,
  ];
  public negativeChanges = [UserProcessChangeType.SENDING_TO_INPOL_ERROR, UserProcessChangeType.SENDING_TO_MOS_ERROR];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupOpts,
    public dialogRef: MatDialogRef<ChangesHistoryModalComponent>,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly managementFacade: ManagementFacade,
    private readonly translateService: TranslateService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly userFilesFacade: UserFilesFacade,
    private readonly nzMessage: NzMessageService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.columnsToDisplay = this.getColumnsToDisplay();

    this.currLang = this.translateService.currentLang as AvailableLanguages;
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(langChangeEvent => {
      this.currLang = langChangeEvent.lang as AvailableLanguages;
    });
    this.sortData({ active: 'createdAt', direction: 'desc' }, this.data.changes);

    this.userFilesFacade.downloadingFile$.pipe(takeUntil(this.destroy$)).subscribe(downloadingAssetId => {
      if (downloadingAssetId) {
        this.nzMessage.loading('');
      } else {
        this.nzMessage.remove();
      }
    });
  }

  ngOnDestroy(): void {
    this.nzMessage.remove();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public sortData(sort: Sort, data?: UserProcessChange[]): void {
    this.currentSortBy = sort.active as keyof UserProcessChange;
    this.currentSortDir = sort.direction;
    const factor = sort.direction === 'desc' ? 1 : -1;
    const newChangesList = data ? [...data] : [...this.changesList];
    newChangesList.sort((changeA, changeB) => {
      const changeAField = changeA[this.currentSortBy as keyof UserProcessChange];
      const changeBField = changeB[this.currentSortBy as keyof UserProcessChange];
      return changeAField < changeBField ? 1 * factor : -1 * factor;
    });
    this.changesList = newChangesList;
  }

  public openErrorScreenshot(screenshotPath: string): void {
    this.managementFacade.downloadErrorScreenshot(screenshotPath);
  }

  public openINPOLRecording(recordingPath: string): void {
    this.managementFacade.downloadINPOLRecording(recordingPath);
  }

  public scrollToBottom(): void {
    const htmlElem = this.dialogContent.nativeElement as HTMLElement;
    const scrollHeight = htmlElem.scrollHeight;
    htmlElem.scrollTo({ top: scrollHeight, behavior: 'smooth' });
  }

  public getColumnsToDisplay(): string[] {
    if (this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      return ['index', 'createdAt', 'type', 'changeAuthor', 'details'];
    }
    if (this.deviceInfo.deviceTypeDetected === 'TABLET') {
      return ['index', 'createdAt', 'type', 'changeAuthor'];
    }
    if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
      return ['createdAt', 'type', 'changeAuthor'];
    }
    return [];
  }

  public downloadAsset(userAssetId: string): void {
    this.userFilesFacade.downloadAsset(userAssetId);
  }
}
