<div class="outer-wrapper">
  <div
    class="banner-wrapper"
    *ngIf="userLang && LINK_TARGET"
    (click)="openTargetInNewPage()"
    [class.fix-ru]="userLang === 'ru'"
    [class.fix-uk]="userLang === 'uk'"
    [class.fix-en]="userLang === 'en'"
    [class.fix-pl]="userLang === 'pl'">
    <div class="row space-around">
      <div class="slogan-container" [innerHTML]="'INSURANCE_BANNER.SLOGAN' | translate"></div>
      <img src="https://kartapobytu.app/img/597-trimmed.png" class="bg-container" />
    </div>
    <div class="row align-center" *ngIf="deviceTypeDetected === 'DESKTOP' || deviceTypeDetected === 'TABLET'">
      <div class="disclaimer-container" [innerHTML]="'INSURANCE_BANNER.DISCLAIMER' | translate"></div>
    </div>
  </div>
  <div class="row align-center" *ngIf="deviceTypeDetected === 'PHONE'">
    <div class="disclaimer-container" [innerHTML]="'INSURANCE_BANNER.DISCLAIMER' | translate"></div>
  </div>
</div>
