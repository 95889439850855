import { Pagination, InAppNotification, InAppNotificationType, MaintenanceInfo } from '@interfaces';

export type InAppNotificationsState = Readonly<{
  loading: boolean;
  errorMessage: string;
  shortlist: InAppNotification[];
  shortlistPagination: Pagination;
  unreadCount: number;
  unreadCountOfType: {
    notificationType: InAppNotificationType;
    unreadCount: number;
    relatedForeignerId: string | null;
  };
  loadingMore: boolean;
  maintenance: MaintenanceInfo;
  loadingMaintenance: boolean;
}>;

export const defaultPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'createdAt',
  orderDir: 'desc',
};

export const initialState: InAppNotificationsState = {
  loading: false,
  errorMessage: null,
  shortlist: [],
  shortlistPagination: { ...(defaultPagination as Pagination) },
  unreadCount: 0,
  unreadCountOfType: {
    notificationType: null,
    unreadCount: null,
    relatedForeignerId: null,
  },
  loadingMore: false,
  maintenance: {
    maintenanceUpcoming: false,
    message: null,
  },
  loadingMaintenance: false,
};
