import { ProcessDocument } from './document';

export interface Process {
  id: string;
  key: string;
  origProcessType: PROCESS_TYPE;
  indexOnTheList: number;
  comment: string | null;
  category: PROCESS_CATEGORY;
  fees: ProcessFee[];
  documents?: ProcessDocument[];
}
export interface ProcessTypeByCategory {
  [key: string]: Process[];
}

export enum PROCESS_TYPE {
  'PURP1_PAID-ACTIVITY' = 'PURP1_PAID-ACTIVITY',
  'PURP2_PAID-ACTIVITY-HIGH-QUALIFICATIONS' = 'PURP2_PAID-ACTIVITY-HIGH-QUALIFICATIONS',
  'PURP3_PAID-ACTIVITY-DELEGATED' = 'PURP3_PAID-ACTIVITY-DELEGATED',
  'PURP4_BUSINESS-ACTIVITY' = 'PURP4_BUSINESS-ACTIVITY',
  'PURP5_STUDIES' = 'PURP5_STUDIES',
  'PURP6_RESEARCH-OR-DEVELOPMENT' = 'PURP6_RESEARCH-OR-DEVELOPMENT',
  'PURP7_RESEARCHER-MOBILITY' = 'PURP7_RESEARCHER-MOBILITY',
  'PURP8_TRAINING' = 'PURP8_TRAINING',
  'PURP9_EVS-PARTICIPATION' = 'PURP9_EVS-PARTICIPATION',
  'PURP10_STAY-WITH-CITIZEN' = 'PURP10_STAY-WITH-CITIZEN',
  'PURP11_STAY-WITH-FOREIGNER' = 'PURP11_STAY-WITH-FOREIGNER',
  'PURP12_RESEARCHER-FAMILY-MEMBER-MOBILITY' = 'PURP12_RESEARCHER-FAMILY-MEMBER-MOBILITY',
  'PURP13_HUMAN-TRAFFICKING' = 'PURP13_HUMAN-TRAFFICKING',
  'PURP14_SHORT-TERM-STAY' = 'PURP14_SHORT-TERM-STAY',
  'PURP15_EXTENSION' = 'PURP15_EXTENSION',
  'PURP16_OTHER' = 'PURP16_OTHER',
  'PERM_STAY_1_PROCESS_TYPE_1' = 'PERM_STAY_1_PROCESS_TYPE_1',
  'PERM_STAY_1_PROCESS_TYPE_2' = 'PERM_STAY_1_PROCESS_TYPE_2',
  'PERM_STAY_1_PROCESS_TYPE_3' = 'PERM_STAY_1_PROCESS_TYPE_3',
  'PERM_STAY_1_PROCESS_TYPE_4' = 'PERM_STAY_1_PROCESS_TYPE_4',
  'PERM_STAY_1_PROCESS_TYPE_5' = 'PERM_STAY_1_PROCESS_TYPE_5',
  'PERM_STAY_1_PROCESS_TYPE_6' = 'PERM_STAY_1_PROCESS_TYPE_6',
  'PERM_STAY_1_PROCESS_TYPE_7' = 'PERM_STAY_1_PROCESS_TYPE_7',
  'PERM_STAY_1_PROCESS_TYPE_8' = 'PERM_STAY_1_PROCESS_TYPE_8',
  'PERM_STAY_1_PROCESS_TYPE_9' = 'PERM_STAY_1_PROCESS_TYPE_9',
  'LONG_RESI_1_PROCESS_TYPE_1' = 'LONG_RESI_1_PROCESS_TYPE_1',
}

export enum PROCESS_CATEGORY {
  // Temporary residence permit application (process)
  TEMP_PREMIT = 'TEMP_PREMIT',

  // Permament stay permit applictaion (process)
  PERM_STAY = 'PERM_STAY',

  // Long resident (rezydent długoterminowy) application (process)
  LONG_RESI = 'LONG_RESI',

  // // Work permit (pozwolenie na pracę) application (process)
  // WORK_PERMIT = 'WORK_PERMIT',
}

export interface ProcessFee {
  key: FEE_NAME;
  amount: string;
}

export type FEE_NAME = 'APPLICATION_FEE' | 'RESIDENCE_CARD_FEE' | 'CARD_FEE';
export const availableFees: FEE_NAME[] = ['APPLICATION_FEE', 'RESIDENCE_CARD_FEE', 'CARD_FEE'];
