import { createAction, props } from '@ngrx/store';
import { MultipleFilesUploadResult, UploadDocumentToReviewOpts, UserAsset } from '@interfaces';

export const getMyDocumentsSentToReview = createAction(
  '[Review] Get My Documents Sent To Review',
  props<{ opts: { userProcessId: string } }>()
);
export const getMyDocumentsSentToReviewSuccess = createAction(
  '[Review] Get My Documents Sent To Review Success',
  props<{ opts: { userProcessId: string; userAssets: UserAsset[] } }>()
);
export const getMyDocumentsSentToReviewError = createAction(
  '[Review] Get My Documents Sent To Review Error',
  props<{ opts: { userProcessId: string; errorMessage: any } }>()
);

export const uploadDocumentToReview = createAction(
  '[Review] Upload Document To Review',
  props<{ opts: UploadDocumentToReviewOpts }>()
);
export const uploadDocumentToReviewSuccess = createAction(
  '[Review] Upload Document To Review Success',
  props<{
    opts: {
      userProcessId: string;
      uploadedFiles: MultipleFilesUploadResult;
    };
  }>()
);
export const uploadDocumentToReviewError = createAction(
  '[Review] Upload Document To Review Error',
  props<{ opts: { userProcessId: string; errorMessage: any } }>()
);

export const downloadDocumentFromReview = createAction(
  '[Review] Download Document',
  props<{ opts: { userAssetId: string; fileOriginalName: string } }>()
);
export const downloadDocumentFromReviewSuccess = createAction(
  '[Review] Download Document Success',
  props<{ opts: { userAssetId: string; fileOriginalName: string } }>()
);
export const downloadDocumentFromReviewError = createAction(
  '[Review] Download Document Error',
  props<{ opts: { userAssetId: string; fileOriginalName: string; errorMessage?: any } }>()
);

export const removeMyFile = createAction(
  '[Review] Remove my file',
  props<{ opts: { userAssetId: string; fileOriginalName: string } }>()
);
export const removeMyFileSuccess = createAction(
  '[Review] Remove my file Success',
  props<{ opts: { userAssetId: string; fileOriginalName: string } }>()
);
export const removeMyFileError = createAction(
  '[Review] Remove my file Error',
  props<{ opts: { userAssetId: string; fileOriginalName: string; errorMessage: any } }>()
);
