import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSetTabIndex]',
})
export class SetTabIndexDirective {
  constructor(private el: ElementRef) {
    setTimeout(() => {
      (this.el.nativeElement as HTMLElement).setAttribute('tabindex', '-1');
    }, 500);
  }
}
