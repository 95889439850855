import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnnexState } from './annex.state';

export const selectAnnexState = createFeatureSelector<AnnexState>('annex');

export const selectAnnexesInUserProcess = createSelector(selectAnnexState, state => state.annexesInUserProcess);

export const selectLoading = createSelector(selectAnnexState, state => state.loading);

export const selectErrorMessage = createSelector(selectAnnexState, state => state.errorMessage);

export const selectSpecialities = createSelector(selectAnnexState, state => state.specialities);

export const selectLoadingSpecialities = createSelector(selectAnnexState, state => state.loadingSpecialities);
