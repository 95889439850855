import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { VisitsCalendarHttpService } from '@core/http/visits-calendar.http.service';

import * as visitsActions from './visits-calendar.actions';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { parseError } from '@state/errors.parser';

@Injectable()
export class VisitsCalendarEffects {
  constructor(
    private actions$: Actions,
    private http: VisitsCalendarHttpService,
    private snackService: SnackbarService
  ) {}

  searchVisits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(visitsActions.searchVisits),
      switchMap(({ opts }) => {
        return this.http.searchVisits(opts).pipe(
          map(usersProcesses => visitsActions.searchVisitsSuccess({ usersProcesses })),
          catchError(error => {
            const errorMessage = parseError(error, visitsActions.searchVisits.type);
            this.snackService.showError(errorMessage);
            return of(visitsActions.searchVisitsError({ errorMessage }));
          })
        );
      })
    )
  );

  searchGotoDateVisits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(visitsActions.searchGotoDateVisits),
      switchMap(({ opts }) => {
        return this.http.searchVisits(opts).pipe(
          map(usersProcesses => visitsActions.searchGotoDateVisitsSuccess({ usersProcesses })),
          catchError(error => {
            const errorMessage = parseError(error, visitsActions.searchGotoDateVisits.type);
            this.snackService.showError(errorMessage);
            return of(visitsActions.searchVisitsError({ errorMessage }));
          })
        );
      })
    )
  );

  getVisitsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(visitsActions.getVisitsList),
      switchMap(({ opts }) => {
        return this.http.getVisitsListBetweenDates(opts).pipe(
          map(paginatedUserProcesses => visitsActions.getVisitsListSuccess({ visitsList: paginatedUserProcesses })),
          catchError(error => {
            const errorMessage = parseError(error, visitsActions.getVisitsList.type);
            this.snackService.showError(errorMessage);
            return of(visitsActions.getVisitsListError({ errorMessage }));
          })
        );
      })
    )
  );
}
