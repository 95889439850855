import { ProcessDocumentsList, SummaryDocuments, UserProcess, ValidatePersonalDetailsResponse } from '@interfaces';

export type UserProcessState = Readonly<{
  myProcesses: UserProcess[];
  loadingMyProcesses: boolean;

  myProcess: UserProcess;
  loadingProcess: boolean;
  lastDetailsValidation: ValidatePersonalDetailsResponse | null;

  // Used in stepper in user-documents
  processDocuments: ProcessDocumentsList | null;
  // Used in summary view for foreigner
  summaryDocuments: SummaryDocuments;

  loading: boolean;
  errorMessage: string | null;
  downloadingUserDocumentFile: {
    userDocumentId: string;
    filename: string;
  };
}>;

export const initialState: UserProcessState = {
  loadingMyProcesses: false,
  myProcesses: null,

  loadingProcess: false,
  myProcess: null,
  lastDetailsValidation: null,

  processDocuments: null,
  summaryDocuments: null,

  loading: false,
  errorMessage: null,
  downloadingUserDocumentFile: {
    userDocumentId: null,
    filename: null,
  },
};
