import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AvailableLanguages } from '@constants';
import {
  LEGAL_BASIS_OF_STAY,
  PROCESS_TYPE,
  UserProcess,
  UserProcessMode,
  UserProcessModes,
  VISA_TYPE,
} from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { DatepickerHeaderComponent } from '@shared/datepicker-header/datepicker-header.component';
import { ManagementFacade } from '@state/management';
import * as moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-perm-stay-step4',
  templateUrl: './perm-stay-step4.component.html',
  styleUrls: ['./perm-stay-step4.component.scss'],
})
export class PermStayStep4Component implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() formGroup: FormGroup;
  @Input() mode: 'foreigner' | 'employee';
  @Input() userProcess: UserProcess;
  @Output() firstInputShiftTabPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() lastFieldTabPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleVerificationOfPersonalDetails: EventEmitter<void> = new EventEmitter();

  public UserProcessModes = UserProcessModes;
  public voivodeshipSupportedModes = [...this.UserProcessModes];

  public controls: { [key: string]: FormControl } = {};
  public currentLang: AvailableLanguages;
  public currentDate = moment();
  public pickerHeader = DatepickerHeaderComponent;
  public selectsValues: { [key: string]: { label: string; value: string }[] };

  public minValidDate = moment().subtract(125, 'years');
  public maxValidDate = moment().subtract(1, 'day');
  public maxValidFutureDate = moment().subtract(1, 'day').add(30, 'years');
  public maxValidDocumentExpiryDate = moment().add(5, 'years');

  public visaTypes = Object.values(VISA_TYPE);
  public legalBasisOfStayList = [
    {
      labelKey: 'VISA_FREE_REGIME',
      value: LEGAL_BASIS_OF_STAY.VISA_FREE,
    },
    {
      labelKey: 'VISA',
      value: LEGAL_BASIS_OF_STAY.VISA,
    },
    {
      labelKey: 'TEMPORARY_RESIDENCE_PERMIT',
      value: LEGAL_BASIS_OF_STAY.TEMP_PERMIT,
    },
    {
      labelKey: 'REFUGEE_STATUS',
      value: LEGAL_BASIS_OF_STAY.REFUGEE,
    },
    {
      labelKey: 'TOLERATED_STATUS',
      value: LEGAL_BASIS_OF_STAY.TOLERATED_STAY,
    },
    {
      labelKey: 'ASYLUM_STATUS',
      value: LEGAL_BASIS_OF_STAY.ASYLUM,
    },
    {
      labelKey: 'DOCUMENT_ENTITLING_ENTRY_AND_STAY_ISSUED_BY_ANOTHER_SCHENGEN_STATE',
      value: LEGAL_BASIS_OF_STAY.SCHENGEN_COUNTRY_ISSUED_DOC,
    },
  ];

  public purposeOfStayList = [
    'SETTLE_DOWN',
    PROCESS_TYPE['PURP1_PAID-ACTIVITY'],
    PROCESS_TYPE['PURP2_PAID-ACTIVITY-HIGH-QUALIFICATIONS'],
    PROCESS_TYPE['PURP3_PAID-ACTIVITY-DELEGATED'],
    PROCESS_TYPE['PURP4_BUSINESS-ACTIVITY'],
    PROCESS_TYPE['PURP5_STUDIES'],
    PROCESS_TYPE['PURP10_STAY-WITH-CITIZEN'],
    PROCESS_TYPE['PURP11_STAY-WITH-FOREIGNER'],
    PROCESS_TYPE['PURP16_OTHER'],
  ];

  constructor(
    private readonly translateService: TranslateService,
    private readonly confirmation: ConfirmationModalService,
    private readonly managementFacade: ManagementFacade
  ) {}

  ngOnInit(): void {
    const controls: any = {};
    this.currentLang = this.translateService.currentLang as AvailableLanguages;
    this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
      this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;
    });

    Object.keys(this.formGroup.controls).forEach(fieldName => {
      controls[fieldName] = this.formGroup.controls[fieldName] as FormControl;
      if (fieldName === 'rationaleOfTheApplication') {
        controls[fieldName].value = 'Wniosek o udzielenie pobytu stałego';
      }
    });
    this.controls = controls;

    if (this.userProcess?.personalDetails?.residenceVoivodeshipDetails?.supportedModes) {
      this.voivodeshipSupportedModes = this.userProcess.personalDetails.residenceVoivodeshipDetails.supportedModes;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.hasAnyFieldAnyValue(this.formGroup.controls)) {
        this.formGroup.markAllAsTouched();
      }

      this.formGroup.controls.currentlyLivingInPoland.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
        this.currentlyLivingInPolandAutoFilling(value);
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public firstInputKeydown($event: KeyboardEvent): void {
    if (!$event.shiftKey) {
      return;
    }

    if ($event.key?.toLowerCase() !== 'tab') {
      return;
    }

    this.firstInputShiftTabPressed.emit();
  }

  public async setUserProcessMode(modeToSet: UserProcessMode): Promise<void> {
    // allowed only for employees
    if (this.mode !== 'employee') {
      return;
    }

    const currMode = this.userProcess?.mode;
    if (!currMode || currMode === modeToSet || !this.voivodeshipSupportedModes.includes(modeToSet)) {
      return;
    }

    this.confirmation
      .open({
        confirmationToTranslate: 'NT.ARE_YOU_SURE_CHANGE_USER_PROCESS_MODE',
        translateParams: { mode: this.translateService.instant('NT.USER_PROCESS_MODES.' + modeToSet) },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.managementFacade.updateUserProcessBasicData(this.userProcess.id, {
          userId: this.userProcess.userId,
          mode: modeToSet,
        });
      });
  }

  public emitLastFieldTabPressed($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.lastFieldTabPressed.emit();
  }

  public removeLastEntryDate($event: any): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.controls.lastEntryDate.setValue(null);
  }

  // control.errors always contains max 1 error at a time
  // assumes that control.invalid === true and errors are {}
  public getErrorMessage(control: FormControl): string {
    const [validatorName, error]: [string, { errorKey: string; allowedVal: string }] = Object.entries(
      control.errors
    )[0];

    if (validatorName === 'required') {
      return this.translateService.instant(`FORM-VALIDATION.IS_REQUIRED`);
    }
    if (validatorName === 'pattern') {
      return this.translateService.instant(`FORM-VALIDATION.WRONG_FORMAT`);
    }
    if (validatorName === 'visaExpiredBeforeLastEntryDate') {
      return this.translateService.instant(`FORM-VALIDATION.VISA_EXPIRED`);
    }
    if (validatorName === 'providedDateMustBeInTheFuture') {
      return this.translateService.instant(`FORM-VALIDATION.DATE_MUST_BE_IN_THE_FUTURE`);
    }
    if (validatorName === 'providedDateBeforeMinimumDate') {
      return this.translateService.instant(`FORM-VALIDATION.PROVIDED_DATE_BEFORE_MINIMUM_DATE`);
    }
    if (validatorName === 'providedDateMustBeInThePast') {
      return this.translateService.instant(`FORM-VALIDATION.DATE_MUST_BE_IN_THE_PAST`);
    }
    if (validatorName === 'providedDateAfterMaximumDate') {
      return this.translateService.instant(`FORM-VALIDATION.PROVIDED_DATE_AFTER_MAXIMUM_DATE`);
    }

    return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`, { allowedVal: error.allowedVal });
  }

  private hasAnyFieldAnyValue(controls: { [key: string]: AbstractControl }): boolean {
    return Object.keys(controls).some(controlName => {
      const val = controls[controlName].value;

      if (Array.isArray(val) && val.length > 0) {
        return true;
      }

      return val !== '' && val !== undefined && val !== null;
    });
  }

  public currentlyLivingInPolandAutoFilling(currentlyLivingInPolandValue: boolean): void {
    const previousVisitsArr = this.formGroup.controls.previousVisitsArr;

    if (
      currentlyLivingInPolandValue === false &&
      previousVisitsArr?.value?.length === 1 &&
      previousVisitsArr?.value[0]?.leaveDate === 'OBECNIE'
    ) {
      previousVisitsArr.setValue(null);
      return;
    }
  }
}
