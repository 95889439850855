<div class="wrapper" [@minimized]="isMinimized" *ngIf="openSupportChat$ | async as openSupportChat">
  <ng-template #indicatorTemplate><span nz-icon nzType="loading"></span></ng-template>

  <div class="title-bar" (click)="titleBarClicked()">
    <div class="column">
      <nz-spin
        [nzIndicator]="indicatorTemplate"
        [nzSpinning]="loadingOpenSupportChat$ | async"
        class="chat-item-avatar-loading-indicator">
        <div class="avatar">
          <mat-icon fontSet="material-icons-outlined" *ngIf="!openSupportChat.supportChat.supportedUser.avatarUpdatedAt"
            >account_circle</mat-icon
          >
          <img
            [src]="API_URL + '/user-assets/avatar/' + openSupportChat.supportChat.supportedUser.id"
            *ngIf="openSupportChat.supportChat.supportedUser.avatarUpdatedAt"
            alt="User Avatar" />
        </div>
      </nz-spin>
      <span class="user-name">
        {{ openSupportChat.supportChat.supportedUser.name }}
      </span>
    </div>
    <div class="column">
      <button nz-button nzType="text" class="title-bar-button" (click)="minimizeWindow($event)">
        <mat-icon fontSet="material-icons-outlined">minimize</mat-icon>
      </button>
      <button
        nz-button
        nzType="text"
        class="title-bar-button"
        (click)="closeWindow($event, openSupportChat.supportChat.id)">
        <mat-icon fontSet="material-icons-outlined">close</mat-icon>
      </button>
    </div>
  </div>

  <div class="messages-section" #messagesContainer>
    <div
      *ngIf="(loadingOpenSupportChat$ | async) === false && !openSupportChat?.messages?.length"
      class="no-messages-info">
      {{ 'NT3.EMPTY_SUPPORT_CHAT' | translate }}
    </div>

    <div
      *ngFor="let message of openSupportChat.messages; index as messageIndex"
      class="message-wrapper"
      [class.to-the-right]="message.authorId === myselfId">
      <div
        class="message-author"
        nz-tooltip
        [nzTooltipTitle]="message.author.name"
        *ngIf="message.authorId !== myselfId">
        <mat-icon *ngIf="!message.author.avatarUpdatedAt" fontSet="material-icons-outlined"> account_circle </mat-icon>
        <img
          *ngIf="message.author.avatarUpdatedAt"
          class="author-avatar"
          [src]="API_URL + '/user-assets/avatar/' + message.author.id"
          alt="User Avatar" />
      </div>
      <div class="content-wrapper">
        <div
          class="message-content"
          [class.author-message]="message.authorId === myselfId"
          [class.in-edit]="editingMessage?.id === message.id"
          [class.interlocutor-message]="message.authorId !== myselfId"
          [class.is-employee]="message?.author?.role?.key && employeesRoles.includes(message.author.role.key)">
          <div [innerHTML]="message.content | linebreaks"></div>
          <div *ngIf="message.attachments?.length" class="message-attachments-wrapper">
            <div
              *ngFor="let attachment of message.attachments; index as messageAttachmentIndex"
              (click)="downloadMessageAttachment(message, attachment.id)"
              class="message-attachment-item">
              <span class="attachment-file-icon" nz-icon nzType="paper-clip" nzTheme="outline"></span>
              <span class="attachment-file-name">{{ attachment.attachment.fileOriginalName }}</span>
            </div>
          </div>
        </div>
        <div class="message-meta">
          <ng-container *ngIf="isEmployee && message.authorId === myselfId">
            <button
              *ngIf="editingMessage?.id !== message.id"
              (click)="startEditingMessage(message)"
              nz-button
              nzType="text"
              nzSize="small"
              nzShape="round">
              {{ 'COMMON.EDIT' | translate }}
            </button>
            <button
              *ngIf="editingMessage?.id === message.id"
              (click)="stopEditingMessage()"
              nz-button
              nzType="text"
              nzSize="small"
              nzShape="round">
              {{ 'COMMON.CANCEL' | translate }}
            </button>
            <button
              *ngIf="editingMessage?.id !== message.id"
              (click)="removeMessage(message)"
              nz-button
              nzType="text"
              nzSize="small"
              nzShape="round">
              {{ 'COMMON.REMOVE' | translate }}
            </button>
          </ng-container>
          <span class="creation-date">{{ message.createdAt | date: 'dd.MM.yyyy HH:mm' }}</span>
        </div>
      </div>
      <div
        class="message-author"
        nz-tooltip
        [nzTooltipTitle]="message.author.name"
        *ngIf="message.authorId === myselfId">
        <mat-icon *ngIf="!message.author.avatarUpdatedAt" fontSet="material-icons-outlined"> account_circle </mat-icon>
        <img
          *ngIf="message.author.avatarUpdatedAt"
          class="author-avatar"
          [src]="API_URL + '/user-assets/avatar/' + message.author.id"
          alt="User Avatar" />
      </div>
    </div>
  </div>

  <div class="input-section">
    <textarea
      nz-input
      (keydown.enter)="sendMessage(openSupportChat.supportChat, $event)"
      (keydown.escape)="editingMessage && stopEditingMessage()"
      [(ngModel)]="messageContent"
      [disabled]="loadingOpenSupportChat$ | async"
      placeholder="{{ 'NT3.TYPE_YOUR_MESSAGE_HERE' | translate }}"
      [nzAutosize]="{ minRows: 2, maxRows: 6 }"
      #messageInput>
    </textarea>
    <div class="input-buttons">
      <button
        (click)="sendMessage(openSupportChat.supportChat, null)"
        [disabled]="messageContent.length === 0 && messageAttachments.length === 0"
        class="send-message-button"
        nz-button
        nzType="text"
        nzSize="default"
        nzShape="circle">
        <span nz-icon nzType="send" nzTheme="outline"></span>
      </button>

      <button
        (click)="fileUploadInput.click()"
        class="add-attachment-button"
        nz-button
        nzType="text"
        nzSize="default"
        nzShape="circle">
        <nz-badge
          [nzCount]="(messageAttachments?.length || 0) + (existingMessageAttachments?.length || 0)"
          nzSize="small">
          <span nz-icon nzType="paper-clip" nzTheme="outline"></span>
        </nz-badge>
      </button>
    </div>
  </div>
  <div class="attachments-section-wrapper" *ngIf="(attachmentsToAdd$ | async) || existingMessageAttachments?.length">
    <div class="attachments-list-label">
      {{ 'NT3.ATTACHMENTS' | translate }}
    </div>
    <div class="attachments-list">
      <div *ngFor="let item of attachmentsToAdd$ | async; index as attachmentIndex" class="attachment-asset">
        <ng-container *ngIf="item.uploaded && item.userAsset?.id">
          <span class="blued-text">{{ item.fileOriginalName }}</span>
          <mat-icon (click)="removeAsset(item.userAsset)" class="negative">close</mat-icon>
        </ng-container>
        <ng-container *ngIf="item.loading">
          <span class="in-progress-text">
            {{ item.fileOriginalName }}
          </span>
          <mat-spinner [diameter]="25" color="primary"></mat-spinner>
        </ng-container>
        <ng-container *ngIf="item.error">
          <span class="error-text">{{ item.fileOriginalName }}</span>
          <mat-icon fontSet="material-icons-outlined" class="error-icon">error</mat-icon>
        </ng-container>
      </div>
      <ng-container *ngIf="editingMessage && existingMessageAttachments?.length">
        <div
          *ngFor="let messageAttachment of existingMessageAttachments; index as messageAttachmentIndex"
          class="attachment-asset">
          <span class="blued-text">{{ messageAttachment.attachment.fileOriginalName }}</span>
          <mat-icon (click)="removeExistingMessageAttachment(messageAttachment)" class="negative">close</mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
  <input
    (change)="uploadFiles($event)"
    #fileUploadInput
    type="file"
    class="file-input-field"
    multiple="true"
    id="attachments-input" />
</div>
