import { User } from './user-auth';
import { City, District, Municipality, Voivodeship } from './voivodeship';

export interface Annex {
  id: string;
  workplace_usingPersonName: boolean;
  workplace_name: string;
  workplace_surname: string;

  workplace_addressOutsideCountry: boolean;

  workplace_addressVoivodeship: Voivodeship | null;
  workplace_addressVoivodeshipId: string;
  workplace_addressDistrict: District | null;
  workplace_addressDistrictId: string | null;
  workplace_addressMunicipality: Municipality;
  workplace_addressMunicipalityId: string | null;
  workplace_addressCity: City | null;
  workplace_addressCityId: string | null;
  workplace_addressStreet: string;
  workplace_addressHouseNumber: string;
  workplace_addressApartmentNumber: string;
  workplace_addressZipCode: string;

  workplace_externalAddressCountry: string;
  workplace_externalAddressCity: string;
  workplace_externalAddressStreet: string;
  workplace_externalAddressHouseNumber: string;
  workplace_externalAddressApartmentNumber: string;
  workplace_externalAddressZipCode: string;
  workplace_legalBasisOfBusiness: string;
  workplace_pesel: string;
  workplace_regon: string;

  hasSeparateEmployer: boolean;
  employer_usingPersonName: boolean;
  employer_name: string;
  employer_surname: string;
  employer_addressOutsideCountry: boolean;
  employer_addressVoivodeship: Voivodeship | null;
  employer_addressVoivodeshipId: string | null;
  employer_addressDistrict: District | null;
  employer_addressDistrictId: string | null;
  employer_addressMunicipality: Municipality | null;
  employer_addressMunicipalityId: string | null;
  employer_addressCity: City | null;
  employer_addressCityId: string | null;
  employer_addressStreet: string;
  employer_addressHouseNumber: string;
  employer_addressApartmentNumber: string;
  employer_addressZipCode: string;

  // only when outside country address
  employer_externalAddressCountry: string;
  employer_externalAddressCity: string;
  employer_externalAddressStreet: string;
  employer_externalAddressHouseNumber: string;
  employer_externalAddressApartmentNumber: string;
  employer_externalAddressZipCode: string;

  employer_legalBasisOfBusiness: string;
  employer_pesel: string;
  employer_regon: string;

  position: string[] | null;
  positionName: string | null;
  place_addressVoivodeshipId: string;
  place_addressVoivodeship: Voivodeship;
  place_addressDistrictId: string | null;
  place_addressDistrict: District;
  place_addressMunicipalityId: string | null;
  place_addressMunicipality: Municipality;
  place_addressCityId: string | null;
  place_addressCity: City;
  place_addressStreet: string;
  place_addressHouseNumber: string;
  place_addressApartmentNumber: string;
  place_addressZipCode: string;
  legalBasisOfPaidActivity: string;
  contractType: 'EMPLOYMENT' | 'CIVIL_LAW'; // 'EMPLOYMENT' | 'CIVIL_LAW'
  workingTime: number;
  workHoursPerWeek: number;
  typeOfPayGross: 'MONTHLY' | 'YEARLY' | 'HOURLY';
  grossAmount: number;
  mainDuties: string;

  periodOfWorkFrom: Date | string;
  periodOfWorkTo: Date | string;
  qualificationsType: string;
  qualificationsDocument: string;

  hasBlueCard: boolean;
  blueCardCountry: string;
  blueCardCountryPeriodOfStay: string;
  blueCardSerial: string;
  blueCardNumber: string;
  blueCardDateOfIssue: Date | string;
  blueCardExpiryDate: Date | string;
  blueCardIssuer: string;

  verified?: boolean;
  verifiedAt?: Date;

  userProcessId: string;
  verifiedBy?: User | null;
  verifiedById: string | null;

  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface GetAnnexesOpts {
  userProcessId: string;
}

export interface CreateAnnexOpts {
  userProcessId: string;
}

export interface UpdateAnnexOpts {
  userProcessId: string;
  annexId: string;
  annex: Partial<Annex>;
}

export interface RemoveAnnexOpts {
  userProcessId: string;
  annexId: string;
}

export interface MOSSpeciality {
  idslstanowiskorodzajpracy: number;
  kod: string;
  nazwa: string;
  parentId: string | null;
  children: MOSSpeciality[];
  isLeaf?: boolean;
  value?: string;
  label?: string;
}
