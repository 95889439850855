import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllDeviceInfo, SetVisitDateOpts, UserProcess } from '@interfaces';

import { VisitDateModalComponent } from './visit-date-modal.component';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

export interface PopupOpts {
  userProcess: UserProcess;
  markNotificationOnSave: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VisitDateModalService implements OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public deviceInfo: AllDeviceInfo;
  private dialogWidth = '45vw';

  constructor(
    private dialog: MatDialog,
    private readonly deviceInfoService: DeviceInfoService
  ) {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
      if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
        this.dialogWidth = '95vw';
      }
    });

    if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
      this.dialogWidth = '95vw';
    }
  }

  public open(opts: PopupOpts): MatDialogRef<VisitDateModalComponent, SetVisitDateOpts> {
    return this.dialog.open(VisitDateModalComponent, {
      autoFocus: false,
      width: this.dialogWidth,
      maxWidth: this.dialogWidth,
      data: { ...opts },
      disableClose: false,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
