import { Permission } from '@interfaces';

export interface Role {
  id: string;
  name: string;
  key: string;
  permissions: Permission[];
}

export enum ROLES_KEYS {
  Admin = 'SUPER_ADMIN',

  // Employees
  Team_Leader = 'TEAM_LEADER',
  Employee = 'EMPLOYEE',
  Field_Consultant = 'FIELD_CONSULTANT',

  // Foreigners = customers
  Foreigner = 'FOREIGNER',

  // Managers = customers / customers proxy
  Employer = 'EMPLOYER',
}
