import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { parseError } from '@state/errors.parser';

import { SupportChatHttpService } from '@core/http/support-chat.http.service';
import { UserAssetsHttpService } from '@core/http/user-assets.http.service';
import { SingleFileUploadResult, UserAsset } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as supportChatActions from './support-chat.actions';
import { GetFileService } from '@shared/get-file/get-file.service';

@Injectable()
export class SupportChatEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly supportChatHttp: SupportChatHttpService,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly assetsHttp: UserAssetsHttpService,
    private readonly getFileService: GetFileService
  ) {}

  searchSupportChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.searchSupportChats),
      switchMap(({ opts }) => {
        return this.supportChatHttp.searchSupportChats(opts).pipe(
          map(supportChats => {
            return supportChatActions.searchSupportChatsSuccess({ supportChats });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_SEARCHING_CHATS'));
            const errorMessage = parseError(error, supportChatActions.searchSupportChats.type);
            return of(supportChatActions.searchSupportChatsError({ errorMessage }));
          })
        );
      })
    )
  );

  loadMoreSupportChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.loadMoreSupportChats),
      switchMap(({ opts }) => {
        return this.supportChatHttp.searchSupportChats(opts).pipe(
          map(supportChats => {
            return supportChatActions.loadMoreSupportChatsSuccess({ supportChats });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_LOADING_MORE_CHATS'));
            const errorMessage = parseError(error, supportChatActions.loadMoreSupportChats.type);
            return of(supportChatActions.loadMoreSupportChatsError({ errorMessage }));
          })
        );
      })
    )
  );

  openSupportChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.openSupportChat),
      switchMap(({ supportChatId }) => {
        return this.supportChatHttp.openSupportChat(supportChatId).pipe(
          map(({ messages, supportChat }) => {
            return supportChatActions.openSupportChatSuccess({ messages, supportChat });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_OPENNING_CHAT'));
            const errorMessage = parseError(error, supportChatActions.openSupportChat.type);
            return of(supportChatActions.openSupportChatError({ errorMessage, supportChatId }));
          })
        );
      })
    )
  );

  closeSupportChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.closeSupportChat),
      switchMap(({ supportChatId }) => {
        return this.supportChatHttp.closeSupportChat(supportChatId).pipe(
          map(({ supportChat }) => {
            return supportChatActions.closeSupportChatSuccess({ supportChat });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_CLOSING_CHAT_CANNOT_OPEN_ANOTHER'));

            const errorMessage = parseError(error, supportChatActions.closeSupportChat.type);
            return of(supportChatActions.closeSupportChatError({ errorMessage, supportChatId }));
          })
        );
      })
    )
  );

  createSupportMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.createSupportMessage),
      switchMap(({ opts }) => {
        return this.supportChatHttp.createSupportMessage(opts).pipe(
          map(supportMessage => {
            return supportChatActions.createSupportMessageSuccess({ supportMessage });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_SENDING_MESSAGE'));

            const errorMessage = parseError(error, supportChatActions.createSupportMessage.type);
            return of(supportChatActions.createSupportMessageError({ errorMessage }));
          })
        );
      })
    )
  );

  editSupportMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.editSupportMessage),
      switchMap(({ opts, supportMessageId }) => {
        return this.supportChatHttp.editSupportMessage(supportMessageId, opts).pipe(
          map(supportMessage => {
            return supportChatActions.editSupportMessageSuccess({ supportMessage });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_EDITING_MESSAGE'));

            const errorMessage = parseError(error, supportChatActions.editSupportMessage.type);
            return of(supportChatActions.editSupportMessageError({ errorMessage }));
          })
        );
      })
    )
  );

  removeSupportMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.removeSupportMessage),
      switchMap(({ supportChatId, supportMessageId }) => {
        return this.supportChatHttp.removeSupportMessage(supportChatId, supportMessageId).pipe(
          map(() => {
            return supportChatActions.removeSupportMessageSuccess({ removedSupportMessageId: supportMessageId });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_REMOVING_MESSAGE'));

            const errorMessage = parseError(error, supportChatActions.removeSupportMessage.type);
            return of(supportChatActions.removeSupportMessageError({ errorMessage }));
          })
        );
      })
    )
  );

  addSupportChatMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.addSupportChatMarker),
      switchMap(({ opts }) => {
        return this.supportChatHttp.addSupportChatMarker(opts).pipe(
          map(supportChat => {
            return supportChatActions.addSupportChatMarkerSuccess({ supportChat });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_MARKING_CHAT'));

            const errorMessage = parseError(error, supportChatActions.addSupportChatMarker.type);
            return of(supportChatActions.addSupportChatMarkerError({ errorMessage }));
          })
        );
      })
    )
  );

  markSupportChatAsUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.markSupportChatAsUnread),
      switchMap(({ supportChatId }) => {
        return this.supportChatHttp.markSupportChatAsUnread(supportChatId).pipe(
          map(supportChat => {
            return supportChatActions.markSupportChatAsUnreadSuccess({ supportChat });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_MARKING_CHAT_AS_UNREAD'));

            const errorMessage = parseError(error, supportChatActions.markSupportChatAsUnread.type);
            return of(supportChatActions.markSupportChatAsUnreadError({ errorMessage }));
          })
        );
      })
    )
  );

  markSupportChatAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.markSupportChatAsRead),
      switchMap(({ supportChatId }) => {
        return this.supportChatHttp.markSupportChatAsRead(supportChatId).pipe(
          map(supportChat => {
            return supportChatActions.markSupportChatAsReadSuccess({ supportChat });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_MARKING_CHAT_AS_READ'));

            const errorMessage = parseError(error, supportChatActions.markSupportChatAsRead.type);
            return of(supportChatActions.markSupportChatAsReadError({ errorMessage }));
          })
        );
      })
    )
  );

  downloadAttachmentFromChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.downloadAttachmentFromChat),
      switchMap(({ supportChatId, messageId, attachmentId }) => {
        return this.supportChatHttp.downloadAttachmentFromChat(supportChatId, messageId, attachmentId).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return supportChatActions.downloadAttachmentFromChatSuccess();
            } else {
              return supportChatActions.downloadAttachmentFromChatError();
            }
          }),
          catchError(() => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_DOWNLOADING_ATTACHMENT'));
            return of(supportChatActions.downloadAttachmentFromChatError());
          })
        );
      })
    )
  );

  uploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.uploadAttachments),
      concatMap(({ opts }) => {
        const { files } = opts;
        const resultsObj: { [key: string]: Observable<SingleFileUploadResult> } = files.reduce(
          (acc, currFile) => {
            acc[currFile.fileOriginalName] = this.assetsHttp.uploadMessageAttachment({ file: currFile.file }).pipe(
              map(uploadResult => {
                return { errorMessage: null, uploaded: true, userAsset: uploadResult };
              }),
              catchError(error => {
                const mssg = this.translateService.instant('NT2.ERROR_UPLOADING_FILE', {
                  fileName: currFile.fileOriginalName,
                });
                this.nzMessage.error(mssg);
                const errorMessage = parseError(error, supportChatActions.uploadAttachments.type);
                return of({
                  errorMessage,
                  uploaded: false,
                  userAsset: { fileOriginalName: currFile.fileOriginalName } as UserAsset,
                });
              })
            );
            return acc;
          },
          {} as { [key: string]: Observable<SingleFileUploadResult> }
        );

        return forkJoin(resultsObj).pipe(
          map(uploadedFiles => {
            return supportChatActions.uploadAttachmentsSuccess({ opts: { uploadedFiles } });
          }),
          catchError(err => {
            const errorMessage = parseError(err, supportChatActions.uploadAttachments.type);
            return of(supportChatActions.uploadAttachmentsError({ opts: { errorMessage } }));
          })
        );
      })
    )
  );

  removeAttachmentFromUploading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.removeAttachmentFromUploading),
      switchMap(({ opts }) => {
        return this.assetsHttp.removeMyAsset(opts).pipe(
          map(() => {
            return supportChatActions.removeAttachmentFromUploadingSuccess({ opts });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT2.ERROR_REMOVING_FILE'));
            const errorMessage = parseError(error, supportChatActions.removeAttachmentFromUploading.type);
            return of(supportChatActions.removeAttachmentFromUploadingError({ opts: { ...opts, errorMessage } }));
          })
        );
      })
    )
  );

  removeExistingAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.removeExistingAttachment),
      switchMap(({ opts }) => {
        return this.supportChatHttp.removeExistingMessageAttachment({ ...opts }).pipe(
          map(() => {
            const { attachmentId, supportChatId, supportMessageId } = opts;
            return supportChatActions.removeExistingAttachmentSuccess({
              opts: { supportChatId, supportMessageId, removedAttachmentId: attachmentId },
            });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_REMOVING_ATTACHMENT'));
            const errorMessage = parseError(error, supportChatActions.removeExistingAttachment.type);
            return of(supportChatActions.removeExistingAttachmentError({ opts: { errorMessage } }));
          })
        );
      })
    )
  );

  getMyUnreadCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.getMyUnreadCount),
      switchMap(() => {
        return this.supportChatHttp.getMyUnreadCount().pipe(
          map(({ count }) => {
            return supportChatActions.getMyUnreadCountSuccess({ count });
          }),
          catchError(() => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_FETCHING_UNREAD_COUNT'));
            return of(supportChatActions.getMyUnreadCountError());
          })
        );
      })
    )
  );

  getTranscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportChatActions.getTranscription),
      switchMap(({ supportChatId }) => {
        return this.supportChatHttp.getTranscription(supportChatId).pipe(
          map(({ success }) => {
            return supportChatActions.getTranscriptionSuccess({ success });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('MANAGEMENT.CHAT.GET_TRANSCRIPTION_ERROR'));
            const errorMessage = parseError(error, supportChatActions.getTranscription.type);
            return of(supportChatActions.getTranscriptionError({ opts: { errorMessage } }));
          })
        );
      })
    )
  );
}
