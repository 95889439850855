import { createAction, props } from '@ngrx/store';
import { GetVisitsListOpts, PaginatedResponse, SearchOfficeVisitsOpts, UserProcess } from '@interfaces';

export const searchVisits = createAction('[Visits-Calendar] Search Visits', props<{ opts: SearchOfficeVisitsOpts }>());
export const searchGotoDateVisits = createAction(
  '[Visits-Calendar] Search Visits to "Go to visits"',
  props<{ opts: SearchOfficeVisitsOpts }>()
);

export const searchVisitsSuccess = createAction(
  '[Visits-Calendar] Search Visits Success',
  props<{ usersProcesses: UserProcess[] }>()
);

export const searchGotoDateVisitsSuccess = createAction(
  '[Visits-Calendar] Search Visits to "Go to visits" Success',
  props<{ usersProcesses: UserProcess[] }>()
);

export const searchVisitsError = createAction(
  '[Visits-Calendar] Search Visits Error',
  props<{ errorMessage: string }>()
);

export const getVisitsList = createAction('[Visits-Calendar] Get Visits List', props<{ opts: GetVisitsListOpts }>());

export const getVisitsListSuccess = createAction(
  '[Visits-Calendar] Get Visits List Success',
  props<{ visitsList: PaginatedResponse<Partial<UserProcess>> }>()
);

export const getVisitsListError = createAction(
  '[Visits-Calendar] Get Visits List Error',
  props<{ errorMessage: string }>()
);
