import { Action, createReducer, on } from '@ngrx/store';
import { initialState, InAppNotificationsState } from './notifications.state';
import * as notificationsActions from './notifications.actions';

const reducer = createReducer(
  initialState,
  on(
    notificationsActions.getShortlist,
    notificationsActions.markAllAsRead,
    notificationsActions.markAllOfTypeAsRead,
    state => ({
      ...state,
      loading: true,
      errorMessage: null,
    })
  ),
  on(notificationsActions.getShortlistMore, state => ({
    ...state,
    errorMessage: null,
    loadingMore: true,
  })),
  on(notificationsActions.getShortlistSuccess, (state, { shortlist }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    shortlist: [...shortlist.data],
    shortlistPagination: { ...shortlist.pagination },
  })),
  on(notificationsActions.getShortlistMoreSuccess, (state, { shortlist }) => ({
    ...state,
    loadingMore: false,
    errorMessage: null,
    shortlist: [...state.shortlist, ...shortlist.data],
    shortlistPagination: {
      ...shortlist.pagination,
      count: state.shortlistPagination.count + shortlist.pagination.count,
    },
  })),

  on(
    notificationsActions.getUnreadCountSuccess,
    notificationsActions.markAllOfTypeAsReadSuccess,
    (state, { unreadCount }) => {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        unreadCount,
      };
    }
  ),
  on(notificationsActions.newNotificationReceived, (state, { notification }) => ({
    ...state,
    shortlist: [{ ...notification }, ...state.shortlist],
    shortlistPagination: {
      ...state.shortlistPagination,
      count: state.shortlistPagination.count + 1,
      all: state.shortlistPagination.all + 1,
    },
  })),
  on(
    notificationsActions.markAsReadSuccess,
    notificationsActions.markAsUnreadSuccess,
    (state, { updatedNotifications }) => {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        shortlist: state.shortlist.map(oldNotification => {
          const newNotification = updatedNotifications.find(({ id }) => id === oldNotification.id);
          return newNotification ? { ...newNotification } : oldNotification;
        }),
      };
    }
  ),
  on(notificationsActions.markAllAsReadSuccess, (state, { updatedShortlist }) => {
    return {
      ...state,
      loading: false,
      errorMessage: null,
      shortlist: [...updatedShortlist.data],
      shortlistPagination: { ...updatedShortlist.pagination },
    };
  }),

  on(
    notificationsActions.getUnreadCountOfTypeSuccess,
    (state, { unreadCount, notificationType, relatedForeignerId }) => ({
      ...state,
      loading: false,
      errorMessage: null,
      unreadCountOfType: {
        notificationType,
        unreadCount,
        relatedForeignerId,
      },
    })
  ),

  on(
    notificationsActions.getShortlistError,
    notificationsActions.markAsReadError,
    notificationsActions.markAsUnreadError,
    notificationsActions.markAllAsReadError,
    notificationsActions.getUnreadCountError,
    notificationsActions.getUnreadCountOfTypeError,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    })
  ),
  on(notificationsActions.getShortlistMoreError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingMore: false,
  })),

  on(notificationsActions.getMaintenanceInfo, notificationsActions.setMaintenanceInfo, (state, {}) => ({
    ...state,
    loadingMaintenance: true,
  })),
  on(
    notificationsActions.getMaintenanceInfoError,
    notificationsActions.setMaintenanceError,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      loadingMaintenance: false,
    })
  ),

  on(
    notificationsActions.getMaintenanceInfoSuccess,
    notificationsActions.setMaintenanceSuccess,
    notificationsActions.addReceivedMaintenanceInfo,
    (state, { result }) => ({
      ...state,
      loadingMaintenance: false,
      maintenance: { ...result },
    })
  )
);

export function inAppNotificationsReducer(state: InAppNotificationsState, action: Action): InAppNotificationsState {
  return reducer(state, action);
}
