import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MODAL_MESSAGE, ModalOpts } from './intro-modal.service';
import { UserAuthFacade } from '@state/user-auth';

@Component({
  selector: 'app-intro-modal',
  templateUrl: './intro-modal.component.html',
  styleUrls: ['./intro-modal.component.scss'],
})
export class IntroModalComponent {
  public additionalMessage = '';

  public MODAL_MESSAGE = MODAL_MESSAGE;

  public myself$ = this.userAuthFacade.myself$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOpts,
    public dialogRef: MatDialogRef<IntroModalComponent>,
    private readonly userAuthFacade: UserAuthFacade
  ) {}

  public close(returnVal?: any): void {
    this.dialogRef.close(returnVal);
  }
}
