import { hasForeignerUnreadCommentsInDocument } from '@core/document-comments-utils';
import { Action, createReducer, on } from '@ngrx/store';
import * as commentsActions from '@state/comments/comments.actions';
import * as userProcessActions from './user-process.actions';
import { UserProcessState, initialState } from './user-process.state';

const reducer = createReducer(
  initialState,

  on(userProcessActions.getMyProcesses, state => ({
    ...state,
    loadingMyProcesses: true,
    errorMessage: null,
  })),
  on(userProcessActions.getMyProcessesSuccess, (state, { myProcesses }) => ({
    ...state,
    myProcesses,
    loadingMyProcesses: false,
    errorMessage: null,
  })),
  on(userProcessActions.getMyProcessesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingMyProcesses: false,
  })),

  on(userProcessActions.getMyProcessDetails, state => ({
    ...state,
    myProcess: null,
    loadingProcess: true,
    processDocuments: null,
    errorMessage: null,
  })),
  on(userProcessActions.getMyProcessDetailsSuccess, (state, { myProcess }) => ({
    ...state,
    myProcess,
    loadingProcess: false,
    errorMessage: null,
  })),
  on(userProcessActions.getMyProcessDetailsError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingProcess: false,
  })),

  on(userProcessActions.addNewUserProcess, (state, {}) => ({
    ...state,
    loading: true,
    loadingMyProcesses: true,
    errorMessage: null,
  })),
  on(userProcessActions.addNewUserProcessSuccess, (state, { addedUserProcess }) => ({
    ...state,
    myProcesses: [...state.myProcesses, addedUserProcess],
    processDocuments: null,
    loadingMyProcesses: false,
    loading: false,
    errorMessage: null,
  })),
  on(userProcessActions.addNewUserProcessError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loadingMyProcesses: false,
    errorMessage,
  })),

  on(userProcessActions.pickProcess, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.pickProcessSuccess, (state, { userProcess: myProcess }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    myProcess: { ...myProcess },
  })),
  on(userProcessActions.pickProcessError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userProcessActions.updatePreSurvey, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.updatePreSurveySuccess, (state, { preSurvey, userId, userProcessId }) => ({
    ...state,
    myProcess:
      userProcessId === state.myProcess?.id
        ? {
            ...state.myProcess,
            preSurvey: {
              ...state.myProcess.preSurvey,
              ...preSurvey,
            },
          }
        : { ...state.myProcess },
    myProcesses: state.myProcesses.map(processInState => {
      return processInState.id === userProcessId
        ? {
            ...processInState,
            preSurvey: { ...processInState.preSurvey, ...preSurvey },
          }
        : processInState;
    }),
    loading: false,
    errorMessage: null,
  })),
  on(userProcessActions.updatePreSurveyError, state => ({
    ...state,
    loading: false,
  })),

  on(userProcessActions.sendPreSurveyForVerification, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.sendPreSurveyForVerificationSuccess, (state, { userProcess }) => ({
    ...state,
    myProcess: userProcess.id === state.myProcess?.id ? { ...userProcess } : state.myProcess,
    myProcesses: state.myProcesses.map(processInState => {
      return processInState.id === userProcess.id ? { ...userProcess } : processInState;
    }),
    loading: false,
    errorMessage: null,
  })),
  on(userProcessActions.sendPreSurveyForVerificationError, state => ({
    ...state,
    loading: false,
  })),

  on(userProcessActions.updatePersonalDetails, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.updatePersonalDetailsSuccess, (state, { updatedUserProcess }) => ({
    ...state,
    myProcess: { ...updatedUserProcess },
    myProcesses: state.myProcesses.map(userProcessInState => {
      return userProcessInState.id === updatedUserProcess.id ? updatedUserProcess : userProcessInState;
    }),
    loading: false,
    errorMessage: null,
  })),
  on(userProcessActions.updatePersonalDetailsError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userProcessActions.confirmPersonalDetails, state => ({
    ...state,
    loading: true,
    errorMessage: null,
    lastDetailsValidation: null,
  })),
  on(userProcessActions.confirmPersonalDetailsSuccess, (state, { validationResult }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    lastDetailsValidation: validationResult,
  })),
  on(userProcessActions.confirmPersonalDetailsError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    lastDetailsValidation: null,
  })),

  on(userProcessActions.startCompletingDocuments, userProcessActions.sendAppToReview, state => ({
    ...state,
    processDocuments: null,
    loading: true,
    errorMessage: null,
  })),
  on(
    userProcessActions.startCompletingDocumentsSuccess,
    userProcessActions.sendAppToReviewSuccess,
    (state, { updatedUserProcess }) => ({
      ...state,
      myProcess: updatedUserProcess,
      myProcesses: state.myProcesses.map(userProcess => {
        return userProcess.id === updatedUserProcess.id ? updatedUserProcess : userProcess;
      }),
      loading: false,
      errorMessage: null,
    })
  ),
  on(
    userProcessActions.startCompletingDocumentsError,
    userProcessActions.sendAppToReviewError,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    })
  ),

  on(userProcessActions.getDocumentsList, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.getDocumentsListSuccess, (state, { documentsList }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    processDocuments: documentsList,
  })),
  on(userProcessActions.getDocumentsListError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),
  on(
    userProcessActions.addFilesToMyDocument,
    userProcessActions.answerToMyDocQuestion,
    userProcessActions.saveUserDocument,
    state => ({
      ...state,
      loading: true,
      errorMessage: null,
    })
  ),
  on(
    userProcessActions.answerToMyDocQuestionSuccess,
    userProcessActions.addFilesToMyDocumentSuccess,
    userProcessActions.saveUserDocumentSuccess,
    (state, { userDocument }) => {
      const docExistedBefore = state.processDocuments.userDocs.find(doc => doc.id === userDocument.id);
      let updatedUserDocs = [];
      if (docExistedBefore) {
        updatedUserDocs = state.processDocuments.userDocs.map(doc => (doc.id === userDocument.id ? userDocument : doc));
      } else {
        updatedUserDocs = [...state.processDocuments.userDocs, userDocument];
      }
      return {
        ...state,
        loading: false,
        processDocuments: {
          processDocs: [...state.processDocuments.processDocs],
          userDocs: updatedUserDocs,
        },
        errorMessage: null,
      };
    }
  ),
  on(userProcessActions.answerToMyDocQuestionError, userProcessActions.addFilesToMyDocumentError, state => ({
    ...state,
    loading: false,
  })),
  on(userProcessActions.saveUserDocumentError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userProcessActions.downloadFile, (state, { userDocumentId, filename }) => ({
    ...state,
    loading: true,
    errorMessage: null,
    downloadingUserDocumentFile: { userDocumentId, filename },
  })),
  on(userProcessActions.downloadFileSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
    downloadingUserDocumentFile: { userDocumentId: null, filename: null },
  })),
  on(userProcessActions.downloadFileError, state => ({
    ...state,
    loading: false,
    downloadingUserDocumentFile: { userDocumentId: null, filename: null },
  })),

  on(userProcessActions.removeUserDoc, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.removeUserDocSuccess, (state, { removedUserDocId }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    processDocuments: {
      processDocs: [...state.processDocuments.processDocs],
      userDocs: state.processDocuments.userDocs.filter(userDoc => userDoc.id !== removedUserDocId),
    },
  })),
  on(userProcessActions.removeUserDocError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userProcessActions.removeFileOfUserDoc, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.removeFileOfUserDocSuccess, (state, { updatedUserDoc }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    processDocuments: {
      processDocs: [...state.processDocuments.processDocs],
      userDocs: state.processDocuments.userDocs.map(userDoc => {
        if (userDoc.id === updatedUserDoc.id) {
          return updatedUserDoc;
        }
        return userDoc;
      }),
    },
  })),
  on(userProcessActions.removeFileOfUserDocError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userProcessActions.saveAdditionalDocs, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.saveAdditionalDocsSuccess, (state, { savedUserDocuments }) => {
    const additionalDocId = savedUserDocuments[0].documentId;
    const userNormalDocs = state.processDocuments.userDocs.filter(userDoc => userDoc.documentId !== additionalDocId);
    const userAdditionalDocs = [
      ...state.processDocuments.userDocs.filter(
        oldUserDoc => !savedUserDocuments.find(newUserDoc => newUserDoc.id === oldUserDoc.id)
      ),
      ...savedUserDocuments,
    ];

    return {
      ...state,
      processDocuments: {
        ...state.processDocuments,
        userDocs: [...userNormalDocs, ...userAdditionalDocs],
      },
      loading: false,
      errorMessage: null,
    };
  }),

  on(userProcessActions.getDocumentsForSummary, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.getDocumentsForSummarySuccess, (state, { summaryDocuments }) => ({
    ...state,
    summaryDocuments,
    loading: false,
    errorMessage: null,
  })),
  on(userProcessActions.getDocumentsForSummaryError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(commentsActions.markDocumentCommentsAsReadSuccess, (state, { userDocumentId, userProcessId, updatedComments }) => {
    // if no myProcess loaded
    if (state.myProcess?.id !== userProcessId) {
      return state;
    }

    // if no userDocuments loaded
    if (!state.processDocuments?.userDocs?.length) {
      return state;
    }

    const updatedUserDocs = state.processDocuments.userDocs.map(userDoc => {
      if (userDoc.id !== userDocumentId) {
        return { ...userDoc };
      }

      return { ...userDoc, documentComments: updatedComments };
    });

    return {
      ...state,
      processDocuments: {
        ...state.processDocuments,
        userDocs: updatedUserDocs,
      },
    };
  }),

  on(
    commentsActions.readerAnyNewCommentsForceCheck,
    (state, { readerId, userProcessId, userDocumentId, updatedComments }) => {
      if (state.myProcess?.id !== userProcessId) {
        return { ...state };
      }

      const userDocs = state.processDocuments?.userDocs;
      if (!userDocs?.length) {
        return { ...state };
      }

      const hasNewMessages = hasForeignerUnreadCommentsInDocument(readerId, updatedComments);
      return {
        ...state,
        processDocuments: {
          ...state.processDocuments,
          userDocs: state.processDocuments.userDocs.map(userDoc => {
            return userDoc.id === userDocumentId ? { ...userDoc, hasNewMessages } : { ...userDoc };
          }),
        },
      };
    }
  ),

  on(userProcessActions.downloadForPrintAll, userProcessActions.downloadInstructions, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.downloadForPrintAllSuccess, userProcessActions.downloadInstructionsSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),

  on(userProcessActions.downloadForPrintAllError, userProcessActions.downloadInstructionsError, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),

  on(userProcessActions.saveDocumentsSigningMode, state => ({
    ...state,
    errorMessage: null,
    loading: true,
  })),
  on(userProcessActions.saveDocumentsSigningModeSuccess, (state, { userProcess }) => ({
    ...state,
    errorMessage: null,
    loading: false,
    myProcess: { ...userProcess },
  })),
  on(userProcessActions.saveDocumentsSigningModeError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userProcessActions.saveMyAvailability, state => ({
    ...state,
    errorMessage: null,
    loading: true,
  })),
  on(
    userProcessActions.saveMyAvailabilitySuccess,
    (state, { newUserProcessStatus, updatedUserProcess, userProcessId }) => ({
      ...state,
      errorMessage: null,
      loading: false,
      myProcess: {
        ...state.myProcess,
        status: newUserProcessStatus,
        availabilityForOfficeVisit: updatedUserProcess.availabilityForOfficeVisit,
      },
    })
  ),
  on(userProcessActions.saveMyAvailabilityError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),
  on(userProcessActions.confirmVoivoChange, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.confirmVoivoChangeSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),
  on(userProcessActions.confirmVoivoChangeError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userProcessActions.setReadinessForVisit, (state, { opts }) => ({
    ...state,
    loading: true,
  })),
  on(userProcessActions.setReadinessForVisitSuccess, (state, { userProcess }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    myProcess: userProcess.id === state.myProcess.id ? { ...userProcess } : state.myProcess,
  })),
  on(userProcessActions.setReadinessForVisitError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(userProcessActions.setAppointmentDateAndPlace, (state, { opts }) => ({
    ...state,
    loading: true,
  })),
  on(userProcessActions.setAppointmentDateAndPlaceSuccess, (state, { userProcess }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    myProcess: userProcess.id === state.myProcess.id ? { ...userProcess } : state.myProcess,
    myProcesses: state.myProcesses?.length
      ? state.myProcesses.map(mp => (mp.id === userProcess.id ? { ...userProcess } : mp))
      : state.myProcesses,
  })),
  on(userProcessActions.setAppointmentDateAndPlaceError, state => ({
    ...state,
    loading: false,
  }))
);

export function userProcessReducer(state: UserProcessState, action: Action): UserProcessState {
  return reducer(state, action);
}
