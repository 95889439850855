import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  ActivateAccountPayload,
  InvoiceData,
  LoginPayload,
  LoginResponsePayload,
  SetNewPasswordPayload,
  UpdateAdminselfPayload,
  UpdateMyselfPayload,
  User,
} from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAuthHttpService {
  private API_URL = environment.API_URL;
  private defaultOpts = { withCredentials: true };

  constructor(private http: HttpClient) {}

  public activateAccount(payload: ActivateAccountPayload): Observable<{ emailSent: boolean }> {
    const url = `${this.API_URL}/auth/verify-account`;
    return this.http.post<{ emailSent: boolean }>(url, payload);
  }

  public loginRequest(loginPayload: LoginPayload): Observable<LoginResponsePayload> {
    const url = `${this.API_URL}/auth/login`;
    return this.http.post<LoginResponsePayload>(url, loginPayload);
  }

  public getMyself(): Observable<User> {
    const url = `${this.API_URL}/auth/myself`;
    return this.http.get<User>(url, this.defaultOpts);
  }

  public logout(): Observable<any> {
    const url = `${this.API_URL}/auth/logout`;
    return this.http.get(url, this.defaultOpts);
  }

  public updateMyself(updateMyselfPayload: UpdateMyselfPayload): Observable<User> {
    const url = `${this.API_URL}/users/settings`;
    return this.http.post<User>(url, updateMyselfPayload, this.defaultOpts);
  }

  public updateMyInvoiceData(invoiceData: InvoiceData): Observable<InvoiceData> {
    const url = `${this.API_URL}/users/myself/invoice-data`;
    return this.http.patch<InvoiceData>(url, invoiceData);
  }

  public updateAdminself(userId: string, updateAdminselfPayload: UpdateAdminselfPayload): Observable<User> {
    const url = `${this.API_URL}/users/${userId}`;
    return this.http.patch<User>(url, updateAdminselfPayload, this.defaultOpts);
  }

  public getPasswordResetLink(email: string): Observable<{ emailResult: boolean }> {
    const url = `${this.API_URL}/auth/reset-password`;
    return this.http.post<{ emailResult: boolean }>(url, { email });
  }

  public setNewPassword(payload: SetNewPasswordPayload): Observable<{ success: boolean }> {
    const url = `${this.API_URL}/auth/set-new-password`;
    return this.http.post<{ success: boolean }>(url, payload);
  }

  public deleteAccount(password: string): Observable<{ success: boolean; message?: string }> {
    const url = `${this.API_URL}/auth/account`;
    const body = { password };
    return this.http.delete<{ success: boolean; message?: string }>(url, { body });
  }
}
