<form [formGroup]="formGroup" id="step4Form">
  <div class="step4-form-container">
    <div class="row">
      <div class="standard-form-field radio-form-field inPolandAtApplicationSubmition-field required-field">
        <label
          [class.invalid]="
            controls.inPolandAtApplicationSubmition.touched && controls.inPolandAtApplicationSubmition.invalid
          ">
          {{ 'NT.IN-POLAND-AT-APPLICATION-SUBMITION.INFIELD-LABEL' | translate }}*
          <mat-icon
            #inPolandAtApplicationSubmition="matTooltip"
            (click)="$event.preventDefault(); $event.stopPropagation(); inPolandAtApplicationSubmition.toggle()"
            matSuffix
            matTooltip="{{ 'NT.IN-POLAND-AT-APPLICATION-SUBMITION.TOOLTIP' | translate }}"
            matTooltipClass="personalDataFormTooltip">
            help_outline
          </mat-icon>
        </label>
        <mat-radio-group
          formControlName="inPolandAtApplicationSubmition"
          (keydown)="firstInputKeydown($event)"
          [required]="true">
          <mat-radio-button [value]="true" id="inPolandAtApplicationSubmition-0">
            {{ 'COMMON.YES' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" id="inPolandAtApplicationSubmition-1">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row" *ngIf="controls.inPolandAtApplicationSubmition.value === true">
      <div class="standard-form-field radio-form-field currentlyLivingInPoland-field required-field">
        <label [class.invalid]="controls.currentlyLivingInPoland.touched && controls.currentlyLivingInPoland.invalid">
          {{ 'PERSONAL_DATA_FORM.RESIDENCE-CURRENTLY-LIVING-IN-POLAND.INFIELD-LABEL' | translate }}*
        </label>
        <mat-radio-group formControlName="currentlyLivingInPoland">
          <mat-radio-button [value]="true" id="applicantCurrentlyLivingInPoland-0">
            {{ 'COMMON.YES' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" id="applicantCurrentlyLivingInPoland-1">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <ng-container
      *ngIf="
        controls.inPolandAtApplicationSubmition.value === true &&
        (controls.currentlyLivingInPoland.value === true || controls.currentlyLivingInPoland.value === false)
      ">
      <div class="row">
        <div class="standard-form-field widened-field legalBasisOfStay-field required-field">
          <label
            for="applicantLegalBasisOfStay"
            [class.invalid]="controls.legalBasisOfStay.touched && controls.legalBasisOfStay.invalid">
            {{
              controls.currentlyLivingInPoland.value === true
                ? ('PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY.MAIN-LABEL' | translate)
                : ('PERSONAL_DATA_FORM.PLANNED-LEGAL-BASIS-OF-STAY.MAIN-LABEL' | translate)
            }}
            *
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              id="applicantLegalBasisOfStay"
              panelClass="standard-select"
              placeholder="{{ 'PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY.PLACEHOLDER' | translate }}"
              formControlName="legalBasisOfStay">
              <mat-option *ngFor="let item of selectsValues.STAY_LEGAL_BASIS" [value]="item.value">
                {{ item.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controls.legalBasisOfStay.invalid">
              {{ 'PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.legalBasisOfStay) }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="standard-form-field residenceVisaExpiryDate-field required-field">
          <label
            for="applicantResidenceVisaExpiryDatePicker"
            [class.invalid]="
              (controls.residenceVisaExpiryDate.touched && controls.residenceVisaExpiryDate.invalid) ||
              formGroup.errors?.visaExpiredBeforeLastEntryDate
            ">
            {{ 'PERSONAL_DATA_FORM.RESIDENCE-VISA-EXPIRY-DATE.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline" (click)="residenceVisaExpiryDatePicker.open()">
            <input
              matInput
              id="applicantResidenceVisaExpiryDatePicker"
              #applicantResidenceVisaExpiryDatePicker
              readonly
              (focus)="residenceVisaExpiryDatePicker.open()"
              [matDatepicker]="residenceVisaExpiryDatePicker"
              [min]="currentDate"
              [max]="maxValidDocumentExpiryDate"
              placeholder="{{ 'PERSONAL_DATA_FORM.RESIDENCE-VISA-EXPIRY-DATE.PLACEHOLDER' | translate }}"
              formControlName="residenceVisaExpiryDate" />
            <mat-error
              *ngIf="
                controls.residenceVisaExpiryDate.invalid &&
                !controls.residenceVisaExpiryDate.hasError('visaExpiredBeforeLastEntryDate')
              ">
              {{ 'PERSONAL_DATA_FORM.RESIDENCE-VISA-EXPIRY-DATE.INFIELD-LABEL' | translate }}
              {{ getErrorMessage(controls.residenceVisaExpiryDate) }}
            </mat-error>
            <mat-error
              *ngIf="
                controls.residenceVisaExpiryDate.invalid &&
                controls.residenceVisaExpiryDate.hasError('visaExpiredBeforeLastEntryDate')
              ">
              {{ getErrorMessage(controls.residenceVisaExpiryDate) }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="residenceVisaExpiryDatePicker"></mat-datepicker-toggle>
            <mat-datepicker
              #residenceVisaExpiryDatePicker
              [startView]="'month'"
              [startAt]="currentDate"
              [calendarHeaderComponent]="pickerHeader"
              (closed)="applicantResidenceVisaExpiryDatePicker.blur()">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="controls.legalBasisOfStay.value === 'inna podstawa'">
        <div class="standard-form-field widened-field legalBasisOfStayComment-field required-field">
          <label
            for="applicantLegalBasisOfStayComment"
            [class.invalid]="controls.legalBasisOfStayComment.touched && controls.legalBasisOfStayComment.invalid">
            {{ 'PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY-COMMENT.MAIN-LABEL' | translate }} *
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              id="applicantLegalBasisOfStayComment"
              type="text"
              maxLength="1000"
              minLength="1"
              placeholder="{{ 'PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY-COMMENT.PLACEHOLDER' | translate }}"
              [formControl]="controls.legalBasisOfStayComment" />
            <mat-error *ngIf="controls.legalBasisOfStayComment.invalid">
              {{ 'PERSONAL_DATA_FORM.LEGAL-BASIS-OF-STAY-COMMENT.MAIN-LABEL' | translate }}
              {{ getErrorMessage(controls.legalBasisOfStayComment) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="standard-form-field widened-field lastEntryDate-field required-field">
          <label
            for="applicantLastEntryDate"
            [class.invalid]="controls.lastEntryDate.touched && controls.lastEntryDate.invalid">
            {{
              controls.currentlyLivingInPoland.value === true
                ? ('PERSONAL_DATA_FORM.LAST-ENTRY-DATE-TEMP-PREMIT.MAIN-LABEL' | translate)
                : ('PERSONAL_DATA_FORM.PLANNED-ENTRY-DATE.MAIN-LABEL' | translate)
            }}
            *
          </label>
          <mat-form-field appearance="outline" (click)="lastEntryDatePicker.open()">
            <input
              matInput
              id="applicantLastEntryDate"
              #applicantLastEntryDate
              readonly
              (focus)="lastEntryDatePicker.open()"
              [matDatepicker]="lastEntryDatePicker"
              placeholder="{{ 'PERSONAL_DATA_FORM.LAST-ENTRY-DATE-TEMP-PREMIT.PLACEHOLDER' | translate }}"
              [min]="controls.currentlyLivingInPoland.value === true ? null : currentDate"
              [max]="controls.currentlyLivingInPoland.value === true ? currentDate : null"
              formControlName="lastEntryDate" />
            <mat-error *ngIf="controls.lastEntryDate.invalid">
              {{ getErrorMessage(controls.lastEntryDate) }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="lastEntryDatePicker"></mat-datepicker-toggle>

            <mat-datepicker
              #lastEntryDatePicker
              [startAt]="currentDate"
              [startView]="'month'"
              [calendarHeaderComponent]="pickerHeader"
              (closed)="applicantLastEntryDate.blur()">
            </mat-datepicker>
          </mat-form-field>
          <mat-error *ngIf="formGroup.errors?.VISA_EXPIRED_BEFORE_LAST_ENTRY_DATE">
            {{ 'FORM-VALIDATION.VISA_EXPIRED_BEFORE_LAST_ENTRY_DATE' | translate }}
          </mat-error>
        </div>
      </div>
    </ng-container>

    <div class="row">
      <div class="standard-form-field radio-form-field noPreviousVisits-field required-field">
        <label [class.invalid]="controls.noPreviousVisits.touched && controls.noPreviousVisits.invalid">
          {{ 'PERSONAL_DATA_FORM.NO-PREVIOUS-VISITS.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group formControlName="noPreviousVisits" [required]="true">
          <mat-radio-button [value]="false"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row visits-row" *ngIf="controls.noPreviousVisits.value === false">
      <app-previous-visits-form-temp-premit
        [previousVisitsArrFc]="controls.previousVisitsArr"
        [lastEntryDateFc]="controls.lastEntryDate"
        [legalBasisOfStayFc]="controls.legalBasisOfStay"
        [legalBasisOfStayCommentFc]="controls.legalBasisOfStayComment"
        [currentlyLivingInPolandFc]="controls.currentlyLivingInPoland">
      </app-previous-visits-form-temp-premit>
    </div>
    <div class="row">
      <div class="standard-form-field radio-form-field noLastTravelsOutsidePoland-field required-field">
        <label
          [class.invalid]="controls.noLastTravelsOutsidePoland.touched && controls.noLastTravelsOutsidePoland.invalid">
          {{ 'PERSONAL_DATA_FORM.LAST-TRAVELS-OUTSIDE-POLAND.MAIN-LABEL' | translate }}*
        </label>
        <!-- IMPORTANT about noLastTravelsOutsidePoland
          there is fuckup because of different label text!
          the field is 'noLastTravelsOutsidePoland' so it would mean that
          if noLastTravelsOutsidePoland === true then user DID NOT have any travels outside poland.
          BUT! !IMPORTANT! current label for this field is: 'Czy przekraczałeś granice krajów innych niż Polska w przeciągu ostatnich 5 lat?'
          so if user marks YES then it means that we have to set our field value to `false` as user DID HAVE TRAVELS OUTSIDE POLAND
        -->
        <mat-radio-group
          formControlName="noLastTravelsOutsidePoland"
          (keydown.Tab)="
            controls.noLastTravelsOutsidePoland.value !== true ? undefined : emitLastFieldTabPressed($event)
          "
          [required]="true">
          <mat-radio-button [value]="false"> {{ 'COMMON.YES' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true"> {{ 'COMMON.NO' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <!-- If user HAD LAST TRAVELS OUTSIDE POLAND then display countries-visits-form -->
    <div class="row visits-row" *ngIf="controls.noLastTravelsOutsidePoland.value === false">
      <app-countries-visits-form-temp-premit
        [lastTravelsOutsidePoland]="controls.lastTravelsOutsidePoland"
        (lastFieldTabPressed)="emitLastFieldTabPressed($event)">
      </app-countries-visits-form-temp-premit>
    </div>
  </div>
</form>
