import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AvailableLanguages } from '@constants';
import { VoivodeshipsHttpService } from '@core/http/voivodeships.http.service';
import { City, ConsumableCountry, District, Municipality, Voivodeship } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, filter, lastValueFrom, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-mos-annex-address',
  templateUrl: './mos-annex-address.component.html',
  styleUrls: ['./mos-annex-address.component.scss'],
})
export class MOSAnnexAddressComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() fp: 'workplace_' | 'employer_' | 'place_';
  @Input() initialData: any;
  @Input() form: FormGroup<any>;
  @Input() voivodeships: Voivodeship[];
  @Input() countries: ConsumableCountry[];

  public loadingDistricts = false;
  public loadingMunicips = false;
  public loadingCities = false;
  public currLang: AvailableLanguages;

  public districts: District[] = [];
  public municips: Municipality[] = [];
  public cities: City[] = [];

  constructor(
    private readonly translateService: TranslateService,
    // Ugly workaround for voivo->district->municip->city multiple times in 1 form
    private readonly voivoHttp: VoivodeshipsHttpService
  ) {}

  ngOnInit(): void {
    this.currLang = this.translateService.currentLang as AvailableLanguages;
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang }) => {
      this.currLang = lang as AvailableLanguages;
    });
    this.handleAddressChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async handleAddressChanges(): Promise<void> {
    const voivoIdFc = this.form.controls[this.fp + 'addressVoivodeshipId'];
    const districtIdFc = this.form.controls[this.fp + 'addressDistrictId'];
    const municipIdFc = this.form.controls[this.fp + 'addressMunicipalityId'];
    const cityIdFc = this.form.controls[this.fp + 'addressCityId'];

    if (voivoIdFc.value) {
      this.fetchDistricts(voivoIdFc.value);
    }
    if (districtIdFc.value) {
      this.fetchMunicips(voivoIdFc.value, districtIdFc.value);
    }
    if (municipIdFc.value) {
      this.fetchCities(voivoIdFc.value, districtIdFc.value, municipIdFc.value);
    }

    voivoIdFc.valueChanges
      .pipe(
        filter(voivoId => !!voivoId && voivoId !== this.initialData[`${this.fp}addressVoivodeshipId`]),
        takeUntil(this.destroy$)
      )
      .subscribe(async val => {
        districtIdFc.setValue(null);
        municipIdFc.setValue(null);
        cityIdFc.setValue(null);
        this.fetchDistricts(voivoIdFc.value);
      });

    districtIdFc.valueChanges
      .pipe(
        filter(districtId => !!districtId && districtId !== this.initialData[`${this.fp}addressDistrictId`]),
        takeUntil(this.destroy$)
      )
      .subscribe(async val => {
        municipIdFc.setValue(null);
        cityIdFc.setValue(null);
        this.fetchMunicips(voivoIdFc.value, districtIdFc.value);
      });

    municipIdFc.valueChanges
      .pipe(
        filter(municipId => !!municipId && municipId !== this.initialData[`${this.fp}addressMunicipalityId`]),
        takeUntil(this.destroy$)
      )
      .subscribe(async val => {
        cityIdFc.setValue(null);
        this.fetchCities(voivoIdFc.value, districtIdFc.value, municipIdFc.value);
      });
  }

  private async fetchDistricts(voivoId: string): Promise<void> {
    this.districts = [];
    this.loadingDistricts = true;
    this.voivoHttp
      .getDistricts(voivoId)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(districts => {
        this.districts = districts;
        this.loadingDistricts = false;
      });
  }
  private async fetchMunicips(voivoId: string, districtId: string): Promise<void> {
    this.municips = [];
    this.loadingMunicips = true;
    this.voivoHttp
      .getMunicips({ voivoId, districtId })
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(municips => {
        this.municips = municips;
        this.loadingMunicips = false;
      });
  }

  private async fetchCities(voivoId: string, districtId: string, municipId: string): Promise<void> {
    this.cities = [];
    this.loadingCities = true;
    this.voivoHttp
      .getCities({ voivoId, districtId, municipId })
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(cities => {
        this.cities = cities;
        this.loadingCities = false;
      });
  }
}
