import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalOpts } from '@shared/intro-modal/intro-modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAuthFacade } from '@state/user-auth';
import { ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss'],
})
export class DeleteAccountModalComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public isPasswordVisible = false;

  public form: FormGroup<any> = this.fb.group({
    password: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(100)])],
  });

  public passwordError: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOpts,
    public dialogRef: MatDialogRef<DeleteAccountModalComponent>,
    private readonly fb: FormBuilder,
    private readonly userAuthFacade: UserAuthFacade
  ) {}

  ngOnInit(): void {
    this.userAuthFacade.deleteAccountError$.pipe(takeUntil(this.destroy$)).subscribe(({ error }) => {
      this.passwordError = error;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public confirm(): void {
    const formPassword = this.form.get('password').value;
    this.userAuthFacade.deleteAccount(formPassword);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
