export enum PROFESSION {
  ARCHITECT = 'ARCHITECT',
  ARTIST = 'ARTIST',
  BANKSTER = 'BANKSTER',
  NOPROF = 'NOPROF',
  CHEMISTRY = 'CHEMISTRY',
  BOARD_MEMBER = 'BOARD_MEMBER',
  PRIEST = 'PRIEST',
  DIPLOMAT = 'DIPLOMAT',
  JOURNALIST = 'JOURNALIST',
  RETIRED = 'RETIRED',
  SALESMAN = 'SALESMAN',
  OTHER = 'OTHER',
  DRIVER = 'DRIVER',
  SAILOR = 'SAILOR',
  MANAGER = 'MANAGER',
  BEAUTY = 'BEAUTY',
  TEACHER = 'TEACHER',
  USELESS = 'USELESS',
  POLICEMAN = 'POLICEMAN',
  POLITICAN = 'POLITICAN',
  DIPLOMATIC_ADMINISTRATION = 'DIPLOMATIC_ADMINISTRATION',
  FIZOL = 'FIZOL',
  SCIENTIST = 'SCIENTIST',
  WHITE_COLLAR = 'WHITE_COLLAR',
  SOLE_BUSINESS = 'SOLE_BUSINESS',
  PRIVATE_DIPLOMATIC_PERSONEL = 'PRIVATE_DIPLOMATIC_PERSONEL',
  FARMER = 'FARMER',
  CRAFTSMAN = 'CRAFTSMAN',
  ELECTRICIAN = 'ELECTRICIAN',
  IT = 'IT',
  STUDYING = 'STUDYING',
  TECHNICIAN = 'TECHNICIAN',
  ADMIN_OFFICIAL = 'ADMIN_OFFICIAL',
  CIVIL_SERVANT = 'CIVIL_SERVANT',
  MEDICAL = 'MEDICAL',
  SPORT = 'SPORT',
  LAWYER = 'LAWYER',
}
