import { Action, createReducer, on } from '@ngrx/store';
import { ReviewState, initialState } from './review.state';
import * as reviewActions from './review.actions';

const reducer = createReducer(
  initialState,
  on(reviewActions.getMyDocumentsSentToReview, (state, { opts: { userProcessId } }) => ({
    ...state,
    reviewItems: {},
    loadingReviewItems: true,
    userProcessId,
  })),
  on(reviewActions.getMyDocumentsSentToReviewSuccess, (state, { opts: { userAssets, userProcessId } }) => {
    const reviewItems = {} as any;
    userAssets.forEach(userAsset => {
      reviewItems[userAsset.fileOriginalName] = {
        error: null,
        loading: false,
        uploaded: true,
        userAsset,
      };
    });

    return {
      ...state,
      reviewItems,
      loadingReviewItems: false,
      userProcessId,
    };
  }),
  on(reviewActions.getMyDocumentsSentToReviewError, (state, { opts: { errorMessage, userProcessId } }) => ({
    ...state,
    errorMessage,
    loadingReviewItems: false,
    reviewItems: {},
    userProcessId,
  })),

  on(reviewActions.uploadDocumentToReview, (state, { opts: { files, userProcessId } }) => {
    const filesAsObj = {} as any;
    files.forEach(file => {
      filesAsObj[file.fileOriginalName] = {
        error: null,
        loading: true,
        uploaded: false,
        userAsset: file,
      };
    });

    return {
      ...state,
      loadingReviewItems: false,
      userProcessId,
      reviewItems: {
        ...state.reviewItems,
        ...filesAsObj,
      },
    };
  }),
  on(reviewActions.uploadDocumentToReviewSuccess, (state, { opts: { uploadedFiles, userProcessId } }) => {
    const inStateObj = {} as any;
    Object.entries(uploadedFiles).forEach(([key, fileUploadResult]) => {
      inStateObj[key] = {
        uploaded: fileUploadResult.uploaded,
        loading: false,
        error: fileUploadResult.errorMessage ? fileUploadResult.errorMessage : null,
        userAsset: fileUploadResult.userAsset ? fileUploadResult.userAsset : null,
      };
    });
    return {
      ...state,
      loadingReviewItems: false,
      userProcessId,
      reviewItems: {
        ...state.reviewItems,
        ...inStateObj,
      },
    };
  }),
  on(reviewActions.uploadDocumentToReviewError, (state, { opts: { errorMessage, userProcessId } }) => {
    return {
      ...state,
      loadingReviewItems: false,
    };
  }),

  on(reviewActions.downloadDocumentFromReview, (state, { opts: { userAssetId, fileOriginalName } }) => {
    return {
      ...state,
      downloadInProgress: fileOriginalName,
    };
  }),
  on(reviewActions.downloadDocumentFromReviewSuccess, (state, { opts: { userAssetId, fileOriginalName } }) => {
    return {
      ...state,
      downloadInProgress: null,
    };
  }),
  on(reviewActions.downloadDocumentFromReviewError, (state, { opts: { userAssetId, fileOriginalName } }) => {
    return {
      ...state,
      downloadInProgress: null,
    };
  }),

  on(reviewActions.removeMyFile, (state, { opts: { userAssetId, fileOriginalName } }) => {
    return {
      ...state,
      reviewItems: {
        ...state.reviewItems,
        [fileOriginalName]: {
          ...state.reviewItems[fileOriginalName],
          loading: true,
          uploaded: false,
        },
      },
    };
  }),
  on(reviewActions.removeMyFileSuccess, (state, { opts: { userAssetId, fileOriginalName } }) => {
    const rItems = { ...state.reviewItems };
    delete rItems[fileOriginalName];
    return {
      ...state,
      reviewItems: { ...rItems },
    };
  }),
  on(reviewActions.removeMyFileError, (state, { opts: { userAssetId, fileOriginalName } }) => {
    return {
      ...state,
      reviewItems: {
        ...state.reviewItems,
        [fileOriginalName]: {
          ...state.reviewItems[fileOriginalName],
          error: 'ERROR_REMOVING',
        },
      },
    };
  })
);

export function reviewReducer(state: ReviewState, action: Action): ReviewState {
  return reducer(state, action);
}
