import { MultiLangField } from './multi-lang-field';

export interface Product {
  id?: string;
  key: PRODUCTS_KEYS;
  productType: PRODUCT_TYPE;
  name: MultiLangField;
  description: MultiLangField;
  fullDescription: MultiLangField;
  basePrice: number; // Grosze (PLN 1 Grosz = PLN 0.01 zloty)
  showOldPrice: boolean; //
  oldPrice: number; // Grosze (PLN 1 Grosz = PLN 0.01 zloty)
  hasPromoProgram: boolean;
  promoProgramText: MultiLangField;
  hasTopLabel: boolean;
  topLabel: MultiLangField;

  isHighlighted: boolean;
  showHighlightBands: boolean;

  highlightBands: HighlightBand[];

  isActive: boolean;

  displayPosition: number;

  productsIncluded: PackageProduct[] | null;

  createdAt?: Date;
  updatedAt?: Date;
}

export interface PackageProduct {
  id?: string;
  parentPackageId?: string;
  product?: Product;
  productId: string;
  quantity: number;
  positionInList: number;
}

export interface CreateProductOpts {
  product: Omit<Product, 'id' | 'key' | 'productType' | 'createdAt' | 'updatedAt'>;
}

export interface UpdateProductOpts extends CreateProductOpts {
  productId: string;
}

export interface ProductHistory {
  id: string;
  product: Product;
  productId: string;
  beforeChanges: Product;
  afterChanges: Product;
}

export interface HighlightBand {
  text: MultiLangField;
  background: string;
  color: string;
  positionIndex: number;
}

// These keys are used by the system to define what should happen after user purchased products
// And other crucial stuff in the system (in future: system behaviour, user permissions)
// These keys are not and will not be editable by end user
export enum PRODUCTS_KEYS {
  /**
   * Only products with these keys are going to be displayed to the end-user purchasing products
   * Only These products will be editable/deletable by the administration/management
   * Purchasable products are products created by the user, which are of type PACKAGE and which are fully customizable by the end user
   */
  PURCHASABLE_PRODUCT = 'PURCHASABLE_PRODUCT',

  /**
   * This is not editable nor deletable by user
   * This is not displayed at the products list during purchase
   * This product allows to go through main process in our application
   *
   */
  APPLICATION_COMPLETION = 'APPLICATION_COMPLETION',

  /**
   * Addition to main product - Consultation with our consultatn 1v1 about user application.
   * This is not editable nor deletable by user
   * This is not displayed at the products list during purchase
   */
  SERVICE_ADDITIONAL_VERIFICATION_CONSULTATION = 'SERVICE_ADDITIONAL_VERIFICATION_CONSULTATION',

  /**
   * Addition to main product - User application will be handled with priority
   * This is not editable nor deletable by user
   * This is not displayed at the products list during purchase
   */
  SERVICE_PRIORITY = 'SERVICE_PRIORITY',

  /**
   * Addition to main product - Foreigner will be assisted by one of our Field Conultants during voivo office visit
   * This is not editable nor deletable by user
   * This is not displayed at the products list during purchase
   */
  SERVICE_OFFICE_ASSISTANT = 'SERVICE_OFFICE_ASSISTANT',

  /**
   * Addition to main product - We are doing everything for you as your proxy
   * This is not editable nor deletable by user
   * This is not displayed at the products list during purchase
   */
  SERVICE_FULL_HANDLING = 'SERVICE_FULL_HANDLING',
}

export enum PRODUCT_TYPE {
  // For system
  EXTRA_SERVICE = 'EXTRA_SERVICE',

  // For system
  STANDALONE = 'STANDALONE',

  // Purchasable, modifable, fully customizable by the end user
  PACKAGE = 'PACKAGE',
}

export const APPLICATION_COMPLETION_PRODUCT_ID = '95860577-b804-4441-b7cc-a67e40ca26d8';
