import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss'],
})
export class LoadingAnimationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LoadingAnimationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public value = 0;
  public spinner = true;
  public progressBar = false;
  public message = '';

  ngOnInit(): void {
    this.showProgressBar(1000);
    setTimeout(() => {
      this.closeDialog(this.data.time * 1000 - 1000);
      this.changeMessage(this.data.time * 1000 - 1000);
    }, 1000);
  }

  public showProgressBar(time: number): void {
    setTimeout(() => {
      this.spinner = false;
      this.progressBar = true;
    }, time);
  }

  public changeMessage(time: number): void {
    const timePerMessage = time / this.data.messages.length;
    const percentPerMessage = 100 / this.data.messages.length;

    this.message = this.data.messages[0];
    for (let i = 0; i < this.data.messages.length; i++) {
      setTimeout(() => {
        this.message = this.data.messages[i];
        this.value = percentPerMessage * (i + 1);
      }, timePerMessage * i);
    }
  }

  public closeDialog(time: number): void {
    setTimeout(() => {
      this.progressBar = false;
      this.dialogRef.close();
    }, time);
  }
}
