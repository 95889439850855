<div class="title-container">
  <h2 mat-dialog-title>
    {{
      'NT.AP_DETAILS.APPOINTMENT_DETAILS'
        | translate
          : {
              name: data.userProcessAsCalendarEvent.personalDetails.name || '',
              surname: data.userProcessAsCalendarEvent.personalDetails.surname || '-'
            }
    }}
  </h2>
  <mat-spinner *ngIf="(loading$ | async) || (loadingItemsSentForReview$ | async)" [diameter]="25"></mat-spinner>
  <button mat-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography" #dialogContent>
  <div class="section" *ngIf="data.user as user">
    <div class="section-title">{{ 'NT.AP_DETAILS.USER_DETAILS' | translate }}</div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.USER_NAME' | translate }}</div>
      <div class="value">{{ user.name }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.EMAIL' | translate }}</div>
      <div class="value">{{ user.email }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.USER_COMPANY' | translate }}</div>
      <div class="value">{{ data.user.company.name }}</div>
    </div>
  </div>

  <div class="section" *ngIf="data.userProcessAsCalendarEvent.personalDetails as pDetails">
    <div class="section-title">{{ 'NT.AP_DETAILS.APPLICANT_DETAILS' | translate }}</div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.APPLICANT_NAME_SURNAME' | translate }}</div>
      <div class="value">{{ pDetails.name }} {{ pDetails.surname }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.TELEPHONE_NUMBER' | translate }}</div>
      <div class="value">{{ pDetails.telephoneNumber }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.EMAIL' | translate }}</div>
      <div class="value">{{ data.user.email }}</div>
    </div>
  </div>

  <div class="section" *ngIf="data.userProcessAsCalendarEvent as uProcess">
    <div class="section-title">{{ 'NT.AP_DETAILS.APPLICATION_DETAILS' | translate }}</div>
    <div class="row">
      <div class="label">{{ 'NT.USER_PROCESS_MODE' | translate }}</div>
      <div class="value">
        <div class="gen-pills-container-list">
          <span class="pill {{ uProcess.mode.toLowerCase() }}">
            {{ 'NT.USER_PROCESS_MODES.' + uProcess.mode | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.APPLICATION_TYPE' | translate }}</div>
      <div class="value">{{ 'PROCESSES.' + uProcess.process.key | translate }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'APPLICATION.PROCESS-STATUS' | translate }}</div>
      <div class="value">
        <b><app-user-process-status [userProcess]="uProcess"></app-user-process-status> </b>
      </div>
    </div>
    <div class="row" *ngIf="uProcess.purposeComment?.length">
      <div class="label">{{ 'NT.AP_DETAILS.PURPOSE_COMMENT' | translate }}</div>
      <div class="value">{{ uProcess.purposeComment }}</div>
    </div>
  </div>

  <div class="section" *ngIf="data.userProcessAsCalendarEvent as uProcess">
    <ng-container
      *ngIf="uProcess.eventType === 'VOIVO_OFFICE_VISIT' || uProcess.eventType === 'VOIVO_OFFICE_VISIT_WITH_ASSISTANT'">
      <div class="section-title">{{ 'NT.AP_DETAILS.OFFICE_VISIT_DETAILS' | translate }}</div>
      <ng-container *ngIf="uProcess.selectedVoivodeshipOffice as office">
        <div class="row">
          <div class="label">{{ 'NT.AP_DETAILS.OFFICE_ADDRESS' | translate }}</div>
          <div class="value">
            <b>{{ office.city }}, {{ office.street }}, {{ office.extra ? office.extra : '' }} </b>
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="label">{{ 'NT.AP_DETAILS.OFFICE_VISIT_DATE' | translate }}</div>
        <div class="value">
          <b>{{ visitDate }}</b>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ 'NT.AP_DETAILS.OFFICE_VISIT_TIME' | translate }}</div>
        <div class="value">
          <b>{{ visitTime }}</b>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ 'NT.AP_DETAILS.RESERVATION_NUMBER' | translate }}</div>
        <div class="value">
          <b>{{ uProcess.officeVisitReservationNumber || '-' }}</b>
        </div>
      </div>
      <div class="row" *ngIf="uProcess.hasFullHandling">
        <div class="label">{{ 'NT.USER_CONFIRMED_READINESS_FOR_VISIT' | translate }}</div>
        <div class="value">
          <span
            [class.positive]="uProcess.confirmedReadinessForVisitAt"
            [class.negative]="!uProcess.confirmedReadinessForVisitAt">
            {{ (uProcess.confirmedReadinessForVisitAt ? 'COMMON.YES' : 'COMMON.NO') | translate }}
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="uProcess.eventType === 'COMPANY_OFFICE_VISIT'">
      <div class="section-title">{{ 'NT.AP_DETAILS.COMPANY_OFFICE_VISIT_DETAILS' | translate }}</div>
      <ng-container *ngIf="uProcess.eventPlace as office">
        <div class="row">
          <div class="label">{{ 'NT.AP_DETAILS.OFFICE' | translate }}</div>
          <div class="value">{{ office }}</div>
        </div>
      </ng-container>
      <div class="row">
        <div class="label">{{ 'NT.AP_DETAILS.OFFICE_VISIT_DATE' | translate }}</div>
        <div class="value">
          <b>{{ uProcess.eventDateAsString }}</b>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ 'NT.AP_DETAILS.OFFICE_VISIT_TIME' | translate }}</div>
        <div class="value">
          <b>{{ uProcess.eventTimeAsString }}</b>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="section"
    *ngIf="
      (data.userProcessAsCalendarEvent.eventType === 'VOIVO_OFFICE_VISIT' ||
        data.userProcessAsCalendarEvent.eventType === 'VOIVO_OFFICE_VISIT_WITH_ASSISTANT') &&
      data.userProcessAsCalendarEvent.visitAssistant as assistant
    ">
    <div class="section-title">{{ 'NT.AP_DETAILS.ASSIGNED_ASSISTANT' | translate }}</div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.APPLICANT_NAME_SURNAME' | translate }}</div>
      <div class="value">{{ assistant.name || '-' }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.RESPONSIBLE_EMPLOYEE_TELEPHONE_NUMBER' | translate }}</div>
      <div class="value">{{ assistant.telephoneNumber || '-' }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.RESPONSIBLE_EMPLOYEE_EMAIL' | translate }}</div>
      <div class="value">{{ assistant.email || '-' }}</div>
    </div>
  </div>

  <div class="section" *ngIf="data.user.accountCreatedByEmployee as handler">
    <div class="section-title">{{ 'NT.AP_DETAILS.RESPONSIBLE_EMPLOYEE' | translate }}</div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.RESPONSIBLE_EMPLOYEE_NAME' | translate }}</div>
      <div class="value">{{ handler.name || '-' }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.RESPONSIBLE_EMPLOYEE_TELEPHONE_NUMBER' | translate }}</div>
      <div class="value">{{ handler.telephoneNumber || '-' }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.RESPONSIBLE_EMPLOYEE_EMAIL' | translate }}</div>
      <div class="value">{{ handler.email || '-' }}</div>
    </div>
  </div>

  <div class="section" *ngIf="data.userProcessAsCalendarEvent.updatedByUser as updatedByUser">
    <div class="section-title">{{ 'NT.AP_DETAILS.LAST_UPDATER' | translate }}</div>
    <div class="row">
      <div class="label">
        <b>{{ 'NT.AP_DETAILS.UPDATED_AT' | translate }}</b>
      </div>
      <div class="value">
        <b>{{ data.userProcessAsCalendarEvent.updatedAt | date: 'dd.MM.yyyy HH:mm' }}</b>
      </div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.UPDATER' | translate }}</div>
      <div class="value">{{ updatedByUser.name || '-' }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.TELEPHONE_NUMBER' | translate }}</div>
      <div class="value">{{ updatedByUser.telephoneNumber || '-' }}</div>
    </div>
    <div class="row">
      <div class="label">{{ 'NT.AP_DETAILS.RESPONSIBLE_EMPLOYEE_EMAIL' | translate }}</div>
      <div class="value">{{ updatedByUser.email || '-' }}</div>
    </div>
  </div>

  <div class="section documents-section" *ngIf="data.userProcessAsCalendarEvent.hasFullHandling">
    <div class="section-title">{{ 'NT.DOCUMENTS_SENT_FOR_FINAL_REVIEW' | translate }}</div>
    <div class="user-doc-row" *ngFor="let userAsset of itemsSentForReview$ | async; index as userAssetIndex">
      <div class="item" [class.download-in-progress]="loading$ | async">
        <div (click)="downloadItemSentForFinalReview(userAsset)" class="file-row">
          <mat-icon svgIcon="document"></mat-icon>
          <span class="document-name">{{ userAsset.fileOriginalName | truncate: 60 : false }}</span>
        </div>
      </div>
    </div>
    <div
      class="no-user-assets"
      *ngIf="!(itemsSentForReview$ | async)?.length && (loadingItemsSentForReview$ | async) === false">
      <h2 translate="NT.USER_DID_NOT_SEND_ANY_DOCUMENTS"></h2>
    </div>
    <div class="loading-user-assets" *ngIf="(loadingItemsSentForReview$ | async) || (loading$ | async)">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="action-buttons" *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'">
    <ng-container *ngIf="isEmployee">
      <a
        (click)="close()"
        [routerLink]="[
          '/management/application-details',
          data.userProcessAsCalendarEvent.userId,
          data.userProcessAsCalendarEvent.id
        ]">
        <button mat-raised-button color="primary">
          <span>{{ 'APPLICATION.APPLICATION_DETAILS' | translate }}</span>
        </button>
      </a>
    </ng-container>
    <button
      *ngIf="
        data.userProcessAsCalendarEvent.eventType === 'VOIVO_OFFICE_VISIT' ||
        data.userProcessAsCalendarEvent.eventType === 'VOIVO_OFFICE_VISIT_WITH_ASSISTANT'
      "
      (click)="openVisitModal()"
      mat-raised-button
      color="accent">
      {{ 'NT.EDIT_VISIT' | translate }}
    </button>
    <button
      (click)="printDocs()"
      [disabled]="loadingDocsButton"
      mat-raised-button
      color="primary"
      class="print-docs-button">
      <span *ngIf="!loadingDocsButton">{{ 'USER_PROCESS.PRINT-ALL-DOCUMENTS' | translate }}</span>
      <mat-spinner *ngIf="loadingDocsButton" [diameter]="25"></mat-spinner>
    </button>
    <button mat-raised-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
  </div>
  <div class="action-buttons" *ngIf="deviceInfo.deviceTypeDetected !== 'DESKTOP'">
    <ng-container *ngIf="isEmployee">
      <a
        (click)="close()"
        [routerLink]="[
          '/management/application-details',
          data.userProcessAsCalendarEvent.userId,
          data.userProcessAsCalendarEvent.id
        ]"
        [ngClass]="{
          'full-width':
            !data.userProcessAsCalendarEvent.eventType ||
            data.userProcessAsCalendarEvent.eventType !== 'VOIVO_OFFICE_VISIT',
          'half-width': data.userProcessAsCalendarEvent.eventType === 'VOIVO_OFFICE_VISIT'
        }">
        <button mat-raised-button color="primary">
          <span>{{ 'APPLICATION.APPLICATION_DETAILS' | translate }}</span>
        </button>
      </a>
    </ng-container>
    <button
      *ngIf="
        data.userProcessAsCalendarEvent.eventType === 'VOIVO_OFFICE_VISIT' ||
        data.userProcessAsCalendarEvent.eventType === 'VOIVO_OFFICE_VISIT_WITH_ASSISTANT'
      "
      (click)="openVisitModal()"
      mat-raised-button
      color="accent"
      [ngClass]="{ 'full-width': !isEmployee, 'half-width': isEmployee }">
      {{ 'NT.EDIT_VISIT' | translate }}
    </button>
  </div>

  <div class="action-buttons" *ngIf="deviceInfo.deviceTypeDetected !== 'DESKTOP'">
    <button class="half-width" mat-raised-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
    <button
      (click)="printDocs()"
      [disabled]="loadingDocsButton"
      mat-raised-button
      color="primary"
      class="print-docs-button half-width">
      <span *ngIf="!loadingDocsButton">{{ 'USER_PROCESS.PRINT-ALL-DOCUMENTS' | translate }}</span>
      <mat-spinner *ngIf="loadingDocsButton" [diameter]="25"></mat-spinner>
    </button>
  </div>
</mat-dialog-actions>
