import { Annex, MOSSpeciality } from '@interfaces';

export type AnnexState = Readonly<{
  loading: boolean;
  annexesInUserProcess: {
    annexes: Annex[];
    userProcessId: string;
  };
  specialities: MOSSpeciality[];
  loadingSpecialities: boolean;
  errorMessage: string;
}>;

export const initialState: AnnexState = {
  loading: false,
  annexesInUserProcess: {
    annexes: [],
    userProcessId: null,
  },
  specialities: [],
  loadingSpecialities: false,
  errorMessage: null,
};
