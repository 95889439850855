<ng-container *ngFor="let menuItem of sideMenuItems">
  <div
    *ngIf="
      (deviceInfo.deviceTypeDetected === 'DESKTOP' &&
        (!isForeigner || (isForeigner && myProcess?.personalDetails && !addingNewProcess))) ||
      (deviceInfo.deviceTypeDetected !== 'DESKTOP' &&
        menuItem.showOnMobile &&
        (!isForeigner || (isForeigner && myProcess?.personalDetails && !addingNewProcess)))
    "
    [routerLink]="menuItem.isDisabled(myProcess?.status) ? null : menuItem.getRouterLink(myProcess?.id)"
    routerLinkActive="active"
    [class.item-disabled]="menuItem.isDisabled(myProcess?.status)"
    class="menu-item {{ menuItem.class }}">
    <mat-icon *ngIf="menuItem.svgIcon" class="menu-item-icon" [svgIcon]="menuItem.svgIcon"></mat-icon>
    <span>{{ menuItem.mainLabelKey | translate }}</span>
  </div>
</ng-container>
