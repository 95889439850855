import { FileOfDocument, GetUserAssetsOpts, UserAsset } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const getUserAssets = createAction('[User-Files] Get User Assets', props<{ opts: GetUserAssetsOpts }>());
export const getUserAssetsSuccess = createAction(
  '[User-Files] Get User Assets Success',
  props<{ userAssets: UserAsset[]; getOpts: GetUserAssetsOpts }>()
);
export const getUserAssetsError = createAction('[User-Files] Get User Assets Error');

export const getFilesOfUserDocuments = createAction(
  '[User-Files] Get Files of User Documents ',
  props<{ opts: GetUserAssetsOpts }>()
);
export const getFilesOfUserDocumentsSuccess = createAction(
  '[User-Files] Get Files of User Documents Success ',
  props<{ filesOfDocuments: FileOfDocument[]; getOpts: GetUserAssetsOpts }>()
);
export const getFilesOfUserDocumentsError = createAction('[User-Files] Get Files of User Documents Error');

export const downloadAsset = createAction('[User-Files] Download User Asset', props<{ userAssetId: string }>());
export const downloadAssetSuccess = createAction('[User-Files] Download User Asset Success');
export const downloadAssetError = createAction('[User-Files] Download User Asset Error');
