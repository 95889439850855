import { NzMessageService } from 'ng-zorro-antd/message';
import { ReplaySubject, filter, lastValueFrom, map, take, takeUntil } from 'rxjs';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ALLOWED_FILES_IN_CHAT, FILE_SIZE_LIMIT, allEmployeeRoles } from '@constants';
import { environment } from '@environment';
import {
  AllDeviceInfo,
  CreateSupportMessageOpts,
  ROLES_KEYS,
  SupportChat,
  SupportChatMessage,
  SupportMessageAttachment,
  UserAsset,
} from '@interfaces';

import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { RemoveTagsService } from '@shared/remove-tags/remove-tags.service';
import { SupportChatFacade } from '@state/support-chat';
import { UserAuthFacade } from '@state/user-auth';

@Component({
  selector: 'app-support-chat-window-desktop',
  templateUrl: './support-chat-window-desktop.component.html',
  styleUrls: ['./support-chat-window-desktop.component.scss'],
  animations: [
    trigger('minimized', [
      state(
        'false',
        style({
          transform: 'translateY(calc(-65vh - 40px))',
        })
      ),
      state(
        'true',
        style({
          transform: 'translateY(-80px)',
        })
      ),
      transition('true <=> false', animate('400ms ease-in-out')),
    ]),
  ],
})
export class SupportChatWindowDesktopComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('messageInput') messageInput: ElementRef;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef<HTMLElement>;

  public openSupportChat$ = this.supportChatFacade.openSupportChat$;
  public loadingOpenSupportChat$ = this.supportChatFacade.loadingOpenSupportChat$;

  public API_URL = environment.API_URL;
  public ROLES_KEYS = ROLES_KEYS;
  public employeesRoles: string[] = [...allEmployeeRoles];
  public isMinimized = false;

  public messageContent = '';
  public editingMessage: SupportChatMessage = null;

  public isEmployee: boolean;
  public myselfId: string;

  public attachmentsToAdd$ = this.supportChatFacade.openSupportChat$.pipe(
    map(openned => openned?.messageAttachmentsToAdd || {}),
    map(items => {
      if (!Object.values(items)?.length) {
        return null;
      }

      return Object.values(items).map(({ error, loading, uploaded, userAsset }) => {
        return {
          fileOriginalName: userAsset?.fileOriginalName || 'MISSING-DATA',
          error,
          loading,
          uploaded,
          userAsset,
        };
      });
    })
  );

  public newMessageContent = '';
  public messageAttachments: string[] = [];
  public existingMessageAttachments: SupportMessageAttachment[] = [];

  public deviceInfo: AllDeviceInfo;

  constructor(
    private readonly supportChatFacade: SupportChatFacade,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly confirmation: ConfirmationModalService,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly removeTagsService: RemoveTagsService,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.userAuthFacade.myself$
      .pipe(
        filter(myself => !!myself?.role?.key),
        takeUntil(this.destroy$)
      )
      .subscribe(myself => {
        this.isEmployee = allEmployeeRoles.includes(myself.role.key as ROLES_KEYS);
        this.myselfId = myself.id;
      });
  }

  ngAfterViewInit(): void {
    this.supportChatFacade.createSupportMessageSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      setTimeout(() => this.scrollToBottom('smooth', true), 50);
    });

    this.attachmentsToAdd$.pipe(takeUntil(this.destroy$)).subscribe(attachments => {
      if (!attachments?.length) {
        this.messageAttachments = [];
        return;
      }

      this.messageAttachments = attachments
        .filter(attachment => attachment.uploaded)
        .map(attachment => attachment.userAsset.id);
    });

    setTimeout(() => this.scrollToBottom('instant', false), 10);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public titleBarClicked(): void {
    if (this.isMinimized) {
      this.isMinimized = false;
    }
  }

  public minimizeWindow($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.isMinimized = !this.isMinimized;
  }

  public closeWindow($event: MouseEvent, supportChatId: string): void {
    $event.stopPropagation();
    this.supportChatFacade.closeSupportChat(supportChatId);
  }

  public async uploadFiles($event: any): Promise<void> {
    const files = $event.target.files as FileList;
    const validatedFiles = [];
    const invalidFiles = [];
    const MAX_NUMBER_OF_FILES = 10;

    const existingItems = await lastValueFrom(this.attachmentsToAdd$.pipe(take(1), takeUntil(this.destroy$)));

    if (files.length > MAX_NUMBER_OF_FILES) {
      this.nzMessage.warning(this.translateService.instant('NT.YOU_CAN_UPLOAD_MAX_10_AT_A_TIME'), { nzDuration: 4500 });
    }

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      // If user managed to upload some wierd file
      // Let him - we don't care that app is not working now - his fault.
      if (!file?.size || !file?.type) {
        this.nzMessage.error(this.translateService.instant('NT3.CANNOT_READ_FILE_CORRECTLY', { fileName: file.name }), {
          nzDuration: 6500,
          nzPauseOnHover: true,
        });
        invalidFiles.push(file);
        continue;
      }

      if (!ALLOWED_FILES_IN_CHAT.includes(file.type.toLowerCase())) {
        this.nzMessage.error(
          this.translateService.instant('NT3.FILE_NOT_ADDED_BECAUSE_INVALID', { fileName: file.name }),
          { nzDuration: 6500, nzPauseOnHover: true }
        );
        invalidFiles.push(file);
        continue;
      }

      if (file.size > FILE_SIZE_LIMIT) {
        this.nzMessage.error(
          this.translateService.instant('NT3.FILE_NOT_ADDED_BECAUSE_TOO_BIG', { fileName: file.name }),
          { nzDuration: 6500, nzPauseOnHover: true }
        );
        invalidFiles.push(file);
        continue;
      }

      if (existingItems?.length) {
        const fileWithSuchNameUploaded = existingItems.find(
          ({ fileOriginalName, uploaded }) => uploaded && fileOriginalName === file.name
        );
        if (fileWithSuchNameUploaded) {
          continue;
        }
      }
      validatedFiles.push(file);

      // max 10 at a time
      if (validatedFiles.length === 10) {
        break;
      }
    }

    if (invalidFiles.length) {
      this.nzMessage.error(this.translateService.instant('NT3.SOME_OF_THE_FILES_NOT_ADDED'), {
        nzDuration: 8500,
        nzPauseOnHover: true,
      });
    }

    (this.fileUploadInput.nativeElement as any).value = '';
    if (!validatedFiles.length) {
      return;
    }

    validatedFiles.forEach(validatedFile => {
      this.supportChatFacade.uploadMessageAttachments({
        files: [{ file: validatedFile, fileOriginalName: validatedFile.name }],
      });
    });
  }

  public sendMessage(supportChat: SupportChat, $event?: Event | null): void {
    $event?.preventDefault();

    if (($event as any)?.shiftKey) {
      return;
    }
    let content = this.messageContent.trim();
    content = this.removeTagsService.removeTags(content, ['h1', 'h2', 'h3', 'br', 'b', 'i', 'li', 'ul', 'ol']);
    if (content.length === 0 && this.messageAttachments.length === 0) {
      this.nzMessage.error(this.translateService.instant('NT3.WRONG_INPUT'), {
        nzDuration: 3000,
        nzPauseOnHover: true,
      });
      return;
    }

    if (this.editingMessage) {
      this.editExistingMessage(supportChat, content);
    } else {
      this.createNewMessage(supportChat, content);
    }
  }

  public startEditingMessage(supportMessage: SupportChatMessage): void {
    this.editingMessage = supportMessage;
    this.messageContent = supportMessage.content;
    this.existingMessageAttachments = supportMessage.attachments;
    this.messageInput.nativeElement.focus();
  }

  public stopEditingMessage(): void {
    this.editingMessage = null;
    this.messageContent = '';
    this.existingMessageAttachments = [];
  }

  public removeMessage(supportMessage: SupportChatMessage): void {
    this.confirmation
      .open({
        confirmationToTranslate: 'APPLICATION.ARE-YOU-SURE-REMOVE-MESSAGE',
        translateParams: {
          messageTrimmed: `${supportMessage.content.slice(0, 50)}...`,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.supportChatFacade.removeSupportMessage(supportMessage.supportChatId, supportMessage.id);
      });
  }

  public removeAsset(userAsset: UserAsset): void {
    this.supportChatFacade.removeAttachmentFromUploading({
      fileOriginalName: userAsset.fileOriginalName,
      userAssetId: userAsset.id,
    });
  }

  public removeExistingMessageAttachment(messageAttachment: SupportMessageAttachment): void {
    this.supportChatFacade.removeExistingMessageAttachmentSuccess$
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(({ opts: { removedAttachmentId } }) => {
        this.existingMessageAttachments = this.existingMessageAttachments.filter(
          ({ id }) => id !== removedAttachmentId
        );
      });

    this.supportChatFacade.removeExistingMessageAttachment({
      attachmentId: messageAttachment.id,
      supportChatId: messageAttachment.supportChatId,
      supportMessageId: messageAttachment.supportMessageId,
    });
  }

  public downloadMessageAttachment(message: SupportChatMessage, attachmentId: string): void {
    this.supportChatFacade.downloadAttachmentFromChat({
      attachmentId,
      messageId: message.id,
      supportChatId: message.supportChatId,
    });
  }

  private createNewMessage(supportChat: SupportChat, content: string): void {
    const opts: CreateSupportMessageOpts = {
      content,
      supportChatId: supportChat.id,
      attachments: [...this.messageAttachments],
    };

    this.supportChatFacade.createSupportMessageSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.messageContent = '';
      this.messageAttachments = [];
    });

    this.supportChatFacade.createSupportMessage(opts);
  }

  private editExistingMessage(supportChat: SupportChat, content: string): void {
    const opts: CreateSupportMessageOpts = {
      content,
      attachments: [...this.messageAttachments],
      supportChatId: supportChat.id,
    };
    this.supportChatFacade.editSupportMessageSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.messageContent = '';
      this.messageAttachments = [];
      this.existingMessageAttachments = [];
      this.editingMessage = null;
    });
    this.supportChatFacade.editSupportMessage(this.editingMessage.id, opts);
  }

  private scrollToBottom(behavior: 'auto' | 'instant' | 'smooth', sendUpdateOfReadStatus: boolean): void {
    const htmlElem = this.messagesContainer?.nativeElement as HTMLElement;
    if (!htmlElem) {
      return;
    }

    const scrollHeight = htmlElem.scrollHeight;
    htmlElem.scrollTo({ top: scrollHeight, behavior: (behavior as ScrollBehavior) || 'smooth' });
    this.messageInput?.nativeElement?.focus();

    if (sendUpdateOfReadStatus) {
      setTimeout(() => {
        this.openSupportChat$.pipe(take(1), takeUntil(this.destroy$)).subscribe(suppChat => {
          const supportChatId = suppChat.supportChat.id;
          this.supportChatFacade.debouncedMarkSupportChatAsRead(supportChatId);
        });
      }, 50);
    }
  }
}
