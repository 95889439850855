import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AvailableLanguages } from '@constants';
import { ConsumableCountry, TRAVEL_DOCUMENT_TYPE } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerHeaderComponent } from '@shared/datepicker-header/datepicker-header.component';
import { CommonsFacade } from '@state/commons';
import * as moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-perm-stay-step1',
  templateUrl: './perm-stay-step1.component.html',
  styleUrls: ['./perm-stay-step1.component.scss'],
})
export class PermStayStep1Component implements OnInit, AfterViewInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() mode: 'foreigner' | 'employee';

  @Output() lastFieldTabPressed: EventEmitter<void> = new EventEmitter<void>();

  public controls: { [key: string]: FormControl } = {};
  public currentLang: AvailableLanguages;

  public travelDocumentTypes = Object.values(TRAVEL_DOCUMENT_TYPE);

  public pickerHeader = DatepickerHeaderComponent;
  public selectsValues: { [key: string]: { label: string; value: string }[] };
  public minValidDate = moment().subtract(125, 'years');
  public maxValidDate = moment().subtract(1, 'day');
  public maxValidFutureDate = moment().subtract(1, 'day').add(30, 'years');

  public countries: ConsumableCountry[];

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly translateService: TranslateService,
    private readonly commonsFacade: CommonsFacade
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang as AvailableLanguages;
    this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;

    this.commonsFacade
      .getCountries$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(ctrs => {
        this.countries = ctrs;
      });

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
      this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;
    });

    Object.keys(this.formGroup.controls).forEach(fieldName => {
      this.controls[fieldName] = this.formGroup.controls[fieldName] as FormControl;
    });

    this.controls.citizenship.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(citizenship => {
      const country = this.countries.find(ctry => ctry.name === citizenship);
      this.controls.nationality.setValue(country?.nationality || citizenship);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.hasAnyFieldAnyValue(this.formGroup.controls)) {
        this.formGroup.markAllAsTouched();
      }
    });
  }

  ngOnDestroy(): void {
    this.controls.countryOfBirth.markAsDirty();
    this.controls.countryOfBirth.markAsTouched();
    this.controls.countryOfBirth.updateValueAndValidity();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // control.errors always contains max 1 error at a time
  // assumes that control.invalid === true and errors are {}
  public getErrorMessage(control: FormControl): string {
    const [validatorName, error]: [string, { errorKey: string; allowedVal: string }] = Object.entries(
      control.errors
    )[0];

    if (validatorName === 'maxlength') {
      return this.translateService.instant(`FORM-VALIDATION.NOT_LONGER_THAN`, {
        allowedVal: (error as any).requiredLength,
      });
    }

    if (validatorName === 'minlength') {
      return this.translateService.instant(`FORM-VALIDATION.LONGER_THAN`, {
        allowedVal: (error as any).requiredLength,
      });
    }
    if (validatorName === 'required') {
      return this.translateService.instant(`FORM-VALIDATION.IS_REQUIRED`);
    }
    if (validatorName === 'pattern') {
      return this.translateService.instant(`FORM-VALIDATION.WRONG_FORMAT`);
    }
    if (validatorName === 'wrongPesel') {
      return this.translateService.instant(`FORM-VALIDATION.WRONG_PESEL`);
    }
    if (validatorName === 'max') {
      return this.translateService.instant(`FORM-VALIDATION.VALUE_TOO_BIG`);
    }
    if (validatorName === 'min') {
      return this.translateService.instant(`FORM-VALIDATION.VALUE_TOO_SMALL`);
    }

    return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`, { allowedVal: error.allowedVal });
  }

  private hasAnyFieldAnyValue(controls: { [key: string]: AbstractControl }): boolean {
    return Object.keys(controls).some(controlName => {
      const val = controls[controlName].value;

      if (Array.isArray(val) && val.length > 0) {
        return true;
      }

      return val !== '' && val !== undefined && val !== null;
    });
  }
}
