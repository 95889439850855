import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { DocumentComment } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class CommentsHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getDocumentCommentsForeigner(userProcessId: string, userDocumentId: string): Observable<DocumentComment[]> {
    const url = `${this.API_URL}/document-comments/${userProcessId}/document/${userDocumentId}`;
    return this.http.get<DocumentComment[]>(url);
  }

  public addDocumentCommentForeigner(userProcessId: string, payload: DocumentComment): Observable<DocumentComment> {
    const url = `${this.API_URL}/document-comments/${userProcessId}/document/${payload.userDocumentId}`;
    const body = { content: payload.content };
    return this.http.post<DocumentComment>(url, body);
  }

  public getDocumentCommentsEmployee(documentId: string, userProcessId: string): Observable<DocumentComment[]> {
    const url = `${this.API_URL}/document-comments/document/${documentId}/userProcess/${userProcessId}`;
    return this.http.get<DocumentComment[]>(url);
  }

  public addDocumentCommentEmployee(payload: DocumentComment, userProcessId: string): Observable<DocumentComment> {
    const url = `${this.API_URL}/document-comments/document/${payload.userDocumentId}/userProcess/${userProcessId}`;
    const body = { content: payload.content };
    return this.http.post<DocumentComment>(url, body);
  }

  public editDocumentCommentEmployee(payload: DocumentComment, userProcessId: string): Observable<DocumentComment> {
    const url = `${this.API_URL}/document-comments/document/${payload.userDocumentId}/userProcess/${userProcessId}/${payload.id}`;
    const body = { content: payload.content };
    return this.http.patch<DocumentComment>(url, body);
  }

  public removeDocumentCommentEmployee(
    payload: DocumentComment,
    userProcessId: string
  ): Observable<{ success: boolean }> {
    const url = `${this.API_URL}/document-comments/document/${payload.userDocumentId}/userProcess/${userProcessId}/${payload.id}`;
    return this.http.delete<{ success: boolean }>(url);
  }

  public markDocumentCommentsAsRead(
    userDocumentId: string,
    userProcessId: string
  ): Observable<{ comments: DocumentComment[] }> {
    const url = `${this.API_URL}/document-comments/${userProcessId}/document/${userDocumentId}/mark-comments-read`;
    return this.http.patch<{ comments: DocumentComment[] }>(url, {});
  }
}
