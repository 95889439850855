import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Annex, MOSSpeciality, UserProcess } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { AnnexFacade } from '@state/annex';
import { CommonsFacade } from '@state/commons';
import { ManagementFacade } from '@state/management';
import { VoivodeshipsFacade } from '@state/voivodeships';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ReplaySubject, filter, map, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-annex',
  templateUrl: './annex.component.html',
  styleUrls: ['./annex.component.scss'],
})
export class AnnexComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() userProcess: UserProcess;

  public loading$ = this.annexFacade.loading$;
  public loadingV$ = this.voivoFacade.loading$;
  public loadingC$ = this.commonsFacade.loading$;
  public loadingS$ = this.annexFacade.loadingSpecialities$;

  public voivodeships$ = this.voivoFacade.getVoivodeships$().pipe(take(1), takeUntil(this.destroy$));
  public countries$ = this.commonsFacade.getCountries$().pipe(take(1), takeUntil(this.destroy$));
  public specialities$ = this.annexFacade.getSpecialities$().pipe(
    take(1),
    map(s => structuredClone(s)),
    takeUntil(this.destroy$)
  );

  public loadingManagement$ = this.managementFacade.loading$;
  public annexes: Annex[];

  public selectedAnnexIndex = 0;
  public tabNameByAnnexId: { [key: string]: string } = {};

  constructor(
    private readonly annexFacade: AnnexFacade,
    private readonly managementFacade: ManagementFacade,
    private readonly nzModal: NzModalService,
    private readonly translateService: TranslateService,
    private readonly voivoFacade: VoivodeshipsFacade,
    private readonly commonsFacade: CommonsFacade
  ) {}

  ngOnInit(): void {
    this.annexes = [];

    this.annexFacade.annexesInUserProcess$
      .pipe(
        filter(({ userProcessId }) => userProcessId === this.userProcess?.id),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(({ annexes }) => {
        this.annexes = [...annexes];
        this.annexes.forEach(annex => {
          this.tabNameByAnnexId[annex.id] = this.getAnnexName(annex);
        });
      });

    this.annexFacade.createAnnexSuccess$
      .pipe(
        filter(({ userProcessId }) => userProcessId === this.userProcess?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(({ annex }) => {
        this.annexes.push(annex);
        this.tabNameByAnnexId[annex.id] = this.getAnnexName(annex);
        this.selectedAnnexIndex = this.annexes.length - 1;
      });

    this.annexFacade.removeAnnexSuccess$
      .pipe(
        filter(({ userProcessId }) => userProcessId === this.userProcess?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(({ annexId }) => {
        const annexIndex = this.annexes.findIndex(an => an.id === annexId);
        this.annexes.splice(annexIndex, 1);
      });

    this.annexFacade.getAnnexes({ userProcessId: this.userProcess?.id });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public createAnnex(): void {
    this.annexFacade.createAnnex({ userProcessId: this.userProcess.id });
  }

  public annexChanged(annex: Partial<Annex>): void {
    this.tabNameByAnnexId[annex.id] = this.getAnnexName(annex);
  }

  private getAnnexName(annex: Partial<Annex>): string {
    const withSurname = annex.workplace_usingPersonName;
    let nameToDisplayOnTab = `${annex.workplace_name || ''}`;
    if (withSurname && annex.workplace_surname) {
      nameToDisplayOnTab = `${nameToDisplayOnTab} ${annex.workplace_surname || ''}`;
    }

    if (!nameToDisplayOnTab) {
      nameToDisplayOnTab = this.translateService.instant('NT2.NEW_ENTITY');
    }

    return nameToDisplayOnTab;
  }

  public removeAnnex(annex: Annex): void {
    const annexName = this.getAnnexName(annex);

    this.nzModal.confirm({
      nzTitle: this.translateService.instant('NT2.CONFIRM_REMOVAL_OF_ANNEX'),
      nzContent: this.translateService.instant('NT2.REALLY_REMOVE_ANNEX_WITH_NAME', { annexName }),
      nzOkText: this.translateService.instant('COMMON.YES'),
      nzCancelText: this.translateService.instant('COMMON.CANCEL'),
      nzOkDanger: true,
      nzOkType: 'primary',

      nzOnOk: () => {
        return new Promise((resolve, reject) => {
          this.annexFacade.removeAnnexSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => resolve(true));

          this.annexFacade.removeAnnexError$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => reject(true));

          this.annexFacade.removeAnnex({ annexId: annex.id, userProcessId: this.userProcess.id });
        });
      },
    });
  }
}
