import { DocumentComment, UserDocument } from '@interfaces';

/**
 * ONLY FOR FOREINGERS
 */
export const hasForeignerUnreadCommentsInDocument = (
  readerId: string,
  documentComments: DocumentComment[]
): boolean => {
  // comments empty
  if (!documentComments?.length) {
    return false;
  }

  // -> check if there is atleast 1 commment without readerId in 'readBy'
  // --> if there is; make sure that the comment is NOT authored by foreigner
  return documentComments.some(({ readBy, authorIsForeigner }) => !(readBy.includes(readerId) || authorIsForeigner));
};

/**
 * ONLY FOR EMPLOYEES
 */
export const hasEmployeeUnreadDocumentComments = (readerId: string, comments: DocumentComment[]): boolean => {
  // comments empty
  if (!comments?.length) {
    return false;
  }

  // if assigned employee (to foreigner) read every comment -> everyone should have it marked as read
  const assignedEmployeeReadAll = comments.every(comment => !!comment.readByAssignedEmployeeAt);
  if (assignedEmployeeReadAll) {
    return false;
  }

  // if assigned employee did not read all comments:
  // -> check if there is atleast 1 commment without readerId in 'readBy'
  // --> if there is; make sure that the comment is authored by foreigner
  return comments.some(({ readBy, authorIsForeigner }) => !readBy.includes(readerId) && authorIsForeigner);
};

/**
 * ONLY FOR EMPLOYEES
 */
export const hasEmployeeUnreadCommentsInProcess = (readerId: string, userDocuments: UserDocument[]): boolean => {
  if (!userDocuments?.length) {
    return false;
  }

  return userDocuments.some(({ documentComments }) => {
    if (!documentComments?.length) {
      return false;
    }

    return hasEmployeeUnreadDocumentComments(readerId, documentComments);
  });
};
