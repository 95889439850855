import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InstructionItemsState } from './instruction-items.state';

const selectInstructionItemsState = createFeatureSelector<InstructionItemsState>('instructionItems');

export const selectAllInstructionItems = createSelector(
  selectInstructionItemsState,
  state => state.allInstructionItems
);
export const selectInstructionItemsInProcess = createSelector(
  selectInstructionItemsState,
  state => state.instructionItemsInProcess
);
export const selectloadedInstructionItem = createSelector(
  selectInstructionItemsState,
  state => state.loadedInstructionItem
);

export const selectLoading = createSelector(selectInstructionItemsState, state => state.loading);
export const selectErrorMessage = createSelector(selectInstructionItemsState, state => state.errorMessage);

export const selectItemsListForCustomization = createSelector(
  selectInstructionItemsState,
  state => state.itemsListForCustomization
);
