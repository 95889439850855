export interface SystemSettings {
  id?: string;
  pin: string;
  mainNotificationRecipient: string;
  additionalNotificationsRecipients: string[];
  securityInfoRecipients: string[] | null;
  selfRegistrationActive: boolean;
  createdAt?: string;
  updatedAt?: string;
}
