import { Action, createReducer, on } from '@ngrx/store';
import { InstructionItemsState, initialState } from './instruction-items.state';
import * as instructionItemsActions from './instruction-items.actions';
import { state } from '@angular/animations';

const reducer = createReducer<InstructionItemsState>(
  initialState,

  // default loading actions
  on(
    instructionItemsActions.getAllInstructionItems,
    instructionItemsActions.getInstructionItem,
    instructionItemsActions.createInstructionItem,
    instructionItemsActions.updateInstructionItem,
    instructionItemsActions.removeInstructionItem,
    instructionItemsActions.searchItemsInProcesses,
    instructionItemsActions.updateItemsOrder,
    state => ({
      ...state,
      loading: true,
      errorMessage: null,
    })
  ),

  // default error actions
  on(
    instructionItemsActions.getAllInstructionItemsError,
    instructionItemsActions.getInstructionItemError,
    instructionItemsActions.createInstructionItemError,
    instructionItemsActions.updateInstructionItemError,
    instructionItemsActions.removeInstructionItemError,
    instructionItemsActions.searchItemsInProcessesError,
    instructionItemsActions.updateItemsOrderError,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      errorMessage,
    })
  ),

  on(instructionItemsActions.getAllInstructionItemsSuccess, (state, { instructionItems }) => ({
    ...state,
    allInstructionItems: instructionItems,
    loading: false,
    errorMessage: null,
  })),
  on(instructionItemsActions.getInstructionItemSuccess, (state, { instructionItem }) => ({
    ...state,
    loadedInstructionItem: instructionItem,
    loading: false,
    errorMessage: null,
  })),
  on(instructionItemsActions.createInstructionItemSuccess, (state, { createdInstructionItem }) => ({
    ...state,
    allInstructionItems: state.allInstructionItems?.length
      ? [...state.allInstructionItems, createdInstructionItem]
      : [createdInstructionItem],
    loading: false,
    errorMessage: null,
  })),

  on(instructionItemsActions.updateInstructionItemSuccess, (state, { updatedInstructionItem }) => ({
    ...state,
    loadedInstructionItem: updatedInstructionItem,
    allInstructionItems: state.allInstructionItems.map(instrItem =>
      instrItem.id === updatedInstructionItem.id ? updatedInstructionItem : instrItem
    ),
    loading: false,
    errorMessage: null,
  })),
  on(instructionItemsActions.removeInstructionItemSuccess, (state, { removedInstructionItemId }) => ({
    ...state,
    allInstructionItems: state.allInstructionItems.filter(({ id }) => id !== removedInstructionItemId),
    loading: false,
    errorMessage: null,
  })),
  on(instructionItemsActions.searchItemsInProcessesSuccess, (state, { itemsInProcesses }) => ({
    ...state,
    instructionItemsInProcess: itemsInProcesses,
    loading: false,
    errorMessage: null,
  })),
  on(instructionItemsActions.updateItemsOrderSuccess, (state, { itemsInProcess }) => ({
    ...state,
    instructionItemsInProcess: state.instructionItemsInProcess.map(iip => {
      const wasUpdated = itemsInProcess.find(updatedIip => iip.id === updatedIip.id);
      return wasUpdated ? wasUpdated : iip;
    }),
    loading: false,
    errorMessage: null,
  })),

  on(
    instructionItemsActions.getItemsListForCustomization,

    instructionItemsActions.resetItemsInCustomItemsList,
    (state, {}) => ({
      ...state,
      loading: true,
      itemsListForCustomization: [],
      errorMessage: null,
    })
  ),

  on(instructionItemsActions.getItemsListForCustomizationSuccess, (state, { itemsList }) => ({
    ...state,
    loading: false,
    itemsListForCustomization: itemsList,
    errorMessage: null,
  })),

  on(
    instructionItemsActions.setEnabledItemInCustomList,
    instructionItemsActions.setUseCustomList,
    instructionItemsActions.setEnabledFees,
    (state, {}) => ({
      ...state,
      loading: true,
      errorMessage: null,
    })
  ),

  on(instructionItemsActions.setEnabledItemInCustomListSuccess, (state, { updatedItem }) => ({
    ...state,
    loading: false,
    itemsListForCustomization: state.itemsListForCustomization.map(item => {
      return item.id === updatedItem.id ? updatedItem : item;
    }),
    errorMessage: null,
  })),

  on(instructionItemsActions.setEnabledFeesSuccess, (state, {}) => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),

  on(
    instructionItemsActions.getItemsListForCustomizationError,
    instructionItemsActions.setEnabledItemInCustomListError,
    instructionItemsActions.setEnabledFeesError,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      errorMessage,
    })
  )
);

export function instructionItemsReducer(state: InstructionItemsState, action: Action): InstructionItemsState {
  return reducer(state, action);
}
