import { Directive, Input, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[appMatOptionHeight]',
})
export class MatOptionHeightDirective implements OnInit {
  // eslint-disable-next-line
  @Input('appMatOptionHeightValue') appMatOptionHeightValue: number;
  constructor(public matSelect: MatSelect) {}

  ngOnInit(): void {
    (this.matSelect as any)._getItemHeight = () => {
      return Number(this.appMatOptionHeightValue) || 100;
    };
    // The value returned above must match whatever you set in css for .mat-option !
    // if you set height: 32px in css, you must return 32 here as well

    // The default is = this.matSelect._triggerFontSize * 3
    // this is equivalent to { line-height: 3em } in css
  }
}
