<div class="page-container">
  <!-- LEFT COLUMN -->
  <div class="column left" *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'">
    <div class="texts-container">
      <h1 class="main-title-text" [innerHTML]="'LOGIN_PAGE.MAIN_TITLE' | translate: {}"></h1>
      <h3 class="subtitle-text" [innerHTML]="'LOGIN_PAGE.SUBTITLE' | translate: {}"></h3>
    </div>
  </div>

  <!-- RIGHT COLUMN -->
  <div class="column right">
    <div class="utils-buttons-wrapper">
      <app-utils-buttons></app-utils-buttons>
    </div>

    <div class="logo">
      <img src="https://kartapobytu.app/img/logo.png" />
    </div>

    <!-- FORM -->
    <div class="content-column">
      <ng-container *ngIf="currentMode === MODES.SET">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <div class="form-title">{{ 'RESET_PASSWORD_PAGE.SET_NEW_PASSWORD' | translate }}</div>
          </div>
        </div>
        <form nz-form [formGroup]="setPasswordForm" id="setPasswordForm">
          <nz-form-item [class.has-hint]="!setPasswordForm.controls.password.dirty">
            <nz-form-label [nzSpan]="24" nzRequired nzFor="newPassword">
              {{ 'RESET_PASSWORD_PAGE.NEW_PASSWORD' | translate }}
            </nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="{{ 'RESET_PASSWORD_PAGE.INVALID_PASSWORD' | translate }}">
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input
                  nz-input
                  id="newPassword"
                  [type]="isPasswordVisible ? 'text' : 'password'"
                  formControlName="password"
                  placeholder="{{ 'RESET_PASSWORD_PAGE.ENTER_NEW_PASSWORD' | translate }}"
                  autocomplete="off"
                  required
                  autofocus />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <i
                  nz-icon
                  [nzType]="isPasswordVisible ? 'eye-invisible' : 'eye'"
                  (click)="isPasswordVisible = !isPasswordVisible"></i>
              </ng-template>
              <div class="hint" *ngIf="!setPasswordForm.get('password').dirty">
                {{ 'RESET_PASSWORD_PAGE.PASSWORD_HINT' | translate }}
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired nzFor="newPasswordConfirmation">
              {{ 'RESET_PASSWORD_PAGE.NEW_PASSWORD_CONFIRMATION' | translate }}
            </nz-form-label>
            <nz-form-control
              [nzSpan]="24"
              nzErrorTip="{{ 'RESET_PASSWORD_PAGE.INVALID_PASSWORD_CONFIRMATION' | translate }}">
              <nz-input-group [nzSuffix]="suffixConfirmTemplate">
                <input
                  nz-input
                  id="newPasswordConfirmation"
                  [type]="isPasswordConfirmVisible ? 'text' : 'password'"
                  formControlName="passwordConfirmation"
                  placeholder="{{ 'RESET_PASSWORD_PAGE.CONFIRM_PASSWORD' | translate }}"
                  autocomplete="off"
                  required />
              </nz-input-group>
              <ng-template #suffixConfirmTemplate>
                <i
                  nz-icon
                  [nzType]="isPasswordConfirmVisible ? 'eye-invisible' : 'eye'"
                  (click)="isPasswordConfirmVisible = !isPasswordConfirmVisible"></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <!-- SUBMIT BUTTON -->
          <div nz-row>
            <div nz-col [nzSpan]="24">
              <button
                (click)="setNewPassword()"
                id="setNewPasswordSubmitButton"
                class="submit-button"
                [disabled]="
                  setPasswordForm.pristine || (setPasswordForm.dirty && setPasswordForm.invalid) || (loading$ | async)
                "
                mat-raised-button
                color="primary">
                {{ 'RESET_PASSWORD_PAGE.SET_PASSWORD' | translate }}
              </button>
            </div>
          </div>
          <div class="loading-container" *ngIf="loading$ | async">
            <mat-spinner [diameter]="35"></mat-spinner>
          </div>

          <div
            class="error-message-container"
            *ngIf="setPasswordForm.get('passwordConfirmation')?.dirty && setPasswordForm.errors?.noMatch">
            <p>{{ 'RESET_PASSWORD_PAGE.PASSWORDS_DO_NOT_MATCH' | translate }}</p>
          </div>
          <div
            *ngIf="errorMessage$ | async as errorMessage"
            [ngSwitch]="errorMessage"
            #errorMessageContainer
            class="error-message-container">
            <div *ngSwitchCase="'ERRORS.UNAUTHORIZED'">
              <div class="main-error-message" [innerHTML]="'RESET_PASSWORD_PAGE.ERRORS.UNAUTHORIZED' | translate"></div>
              <div class="error-explanations">
                <ul>
                  <li [innerHTML]="'RESET_PASSWORD_PAGE.CHECK_EMAIL_WITH_LINK' | translate"></li>
                  <li [innerHTML]="'RESET_PASSWORD_PAGE.ACTIVATION_LINK_EXPIRATION' | translate"></li>
                  <li [innerHTML]="'RESET_PASSWORD_PAGE.YOU_CAN_REGENERATE_LINK' | translate"></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="back-to-login-link">
            <a (click)="$event.preventDefault()" [routerLink]="['/login']">
              {{ 'RESET_PASSWORD_PAGE.BACK_TO_LOGIN' | translate }}
            </a>
            &nbsp; | &nbsp;
            <a (click)="$event.preventDefault()" [routerLink]="['/reset-password']">
              {{ 'RESET_PASSWORD_PAGE.GO_TO_PASSWORD_RESET' | translate }}
            </a>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="currentMode === MODES.REQUEST">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <div class="form-title">{{ 'RESET_PASSWORD_PAGE.RESET_PASSWORD' | translate }}</div>
          </div>
        </div>
        <form nz-form [formGroup]="requestResetPasswordForm">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired nzFor="passwordResetToEmail"
              >{{ 'RESET_PASSWORD_PAGE.EMAIL' | translate }}
            </nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="emailErrors">
              <input
                nz-input
                id="passwordResetToEmail"
                type="text"
                formControlName="email"
                placeholder="{{ 'RESET_PASSWORD_PAGE.ENTER_EMAIL' | translate }}"
                autocomplete="off"
                required
                autofocus />
            </nz-form-control>
            <div class="hint mail-hint">
              {{ 'RESET_PASSWORD_PAGE.MAIL_HINT' | translate }}
            </div>
          </nz-form-item>

          <!-- SUBMIT BUTTON -->
          <div nz-row>
            <div nz-col [nzSpan]="24">
              <button
                (click)="requestPasswordReset()"
                id="requestPasswordResetSubmitButton"
                class="submit-button"
                [disabled]="
                  requestResetPasswordForm.pristine ||
                  (requestResetPasswordForm.dirty && requestResetPasswordForm.invalid) ||
                  (loading$ | async)
                "
                mat-raised-button
                color="primary">
                {{ 'RESET_PASSWORD_PAGE.RESET_PASSWORD' | translate }}
              </button>
              <div class="error-message-container" *ngIf="errorMessage$ | async as errorMessage">
                <p>{{ 'RESET_PASSWORD_PAGE.' + errorMessage | translate }}</p>
              </div>
              <div class="loading-container" *ngIf="loading$ | async">
                <mat-spinner [diameter]="35"></mat-spinner>
              </div>
              <div class="back-to-login-link">
                <a (click)="$event.preventDefault()" [routerLink]="['/login']">
                  {{ 'RESET_PASSWORD_PAGE.BACK_TO_LOGIN' | translate }}
                </a>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="currentMode === MODES.REQUEST_SUCCESS">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <div class="success-message">{{ 'RESET_PASSWORD_PAGE.WE_HAVE_SENT_EMAIL' | translate }}</div>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <div class="hint mail-hint">
              {{ 'RESET_PASSWORD_PAGE.YOU_CAN_CLOSE_THIS_PAGE' | translate }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #emailErrors let-control>
  <ng-container *ngIf="requestResetPasswordForm.get('email')?.dirty && requestResetPasswordForm.get('email')?.invalid"
    >{{ 'RESET_PASSWORD_PAGE.INVALID_EMAIL' | translate }}
  </ng-container>
</ng-template>

<app-cookie-banner></app-cookie-banner>
