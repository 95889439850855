import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllDeviceInfo, UserAsset } from '@interfaces';
import { ManagementFacade } from '@state/management';
import { ReplaySubject, lastValueFrom, take, takeUntil } from 'rxjs';
import { PopupOpts } from './review-items-modal.service';
import { AvailableLanguages } from '@constants';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { DeviceInfoService } from '@shared/device-info/device-info.service';

@Component({
  selector: 'app-review-items-modal',
  templateUrl: './review-items-modal.component.html',
  styleUrls: ['./review-items-modal.component.scss'],
})
export class ReviewItemsModalComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public currLang: AvailableLanguages;
  public comment: string;

  public loadingItemsSentForReview$ = this.managementFacade.loadingItemsSentForFinalReview$;
  public loading$ = this.managementFacade.loading$;

  public deviceInfo: AllDeviceInfo;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupOpts,
    public dialogRef: MatDialogRef<ReviewItemsModalComponent>,
    private readonly managementFacade: ManagementFacade,
    private readonly translateService: TranslateService,
    private readonly confirmation: ConfirmationModalService,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.currLang = this.translateService.currentLang as AvailableLanguages;
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(langChangeEvent => {
      this.currLang = langChangeEvent.lang as AvailableLanguages;
    });
    this.comment = this.data.userProcess.documentsReviewComment;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openFile(userAsset: UserAsset): void {
    this.managementFacade.downloadItemSentForFinalReview({
      userAssetId: userAsset.id,
      userProcessId: userAsset.userProcessId,
    });
  }

  public async saveAndNotify(comment: string): Promise<void> {
    const confirmationToTranslate = comment
      ? 'NT.UPDATE_COMMENT_AND_NOTIFY_FOREIGNER'
      : 'NT.REMOVE_COMMENT_NO_NOTIFICATION';
    const res = await lastValueFrom(
      this.confirmation.open({ confirmationToTranslate }).afterClosed().pipe(take(1), takeUntil(this.destroy$))
    );

    if (!res) {
      return;
    }

    this.managementFacade.updateDocumentsReviewCommentSuccess$
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(({ userProcess }) => {
        this.data.userProcess = { ...userProcess };
        this.confirmation.open({
          confirmationToTranslate: 'NT.REVIEW_COMMENT_SAVED',
          noButtonKey: 'COMMON.CLOSE',
          yesButtonKey: 'COMMON.OK',
        });
      });

    this.managementFacade.updateDocumentsReviewComment({
      userId: this.data.userProcess.userId,
      userProcessId: this.data.userProcess.id,
      comment,
    });
  }
}
