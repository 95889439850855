<div class="page-container">
  <div class="column left">
    <div class="texts-container">
      <h1
        class="header-text"
        [innerHTML]="'DELETE_ACCOUNT_MODAL.DELETING_ACCOUNT_FORGOTTEN_HEADER' | translate: {}"></h1>
      <h3 class="main-text" [innerHTML]="'DELETE_ACCOUNT_MODAL.DELETING_ACCOUNT_FORGOTTEN' | translate: {}"></h3>
    </div>
  </div>
</div>
