import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserProcess } from '@interfaces';

export enum PurchaseTags {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
  ICON_ONLY = 'ICON_ONLY',
}

@Component({
  selector: 'app-service-level-tag',
  templateUrl: './service-level-tag.component.html',
  styleUrls: ['./service-level-tag.component.scss'],
})
export class ServiceLevelTagComponent implements OnChanges {
  @Input() userProcess: Partial<UserProcess>;
  @Input() mode: PurchaseTags = PurchaseTags.LARGE;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userProcess?.isFirstChange()) {
      return;
    }
    this.userProcess = changes.userProcess?.currentValue;
  }

  protected readonly PurchaseTags = PurchaseTags;
}
