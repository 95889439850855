import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AllDeviceInfo, UserProcess, UserProcessMode } from '@interfaces';

import { LocalStorageService } from '@core/local-storage.service';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModeChangedModalComponent } from './mode-changed-modal.component';

export interface ModalOpts {
  userProcess: UserProcess;
}

@Injectable({
  providedIn: 'root',
})
export class ModeChangedModalService implements OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public deviceInfo: AllDeviceInfo;
  private dialogWidth = '850px';
  constructor(
    private dialog: MatDialog,
    private readonly lsService: LocalStorageService,
    private readonly deviceInfoService: DeviceInfoService
  ) {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
      if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
        this.dialogWidth = '95vw';
      }
    });

    if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
      this.dialogWidth = '95vw';
    }
  }

  public open(opts: ModalOpts): MatDialogRef<ModeChangedModalComponent> {
    this.lsService.setPostModeExplanationModalSeen(opts.userProcess.id, true);

    return this.dialog.open(ModeChangedModalComponent, {
      width: this.dialogWidth,
      maxWidth: this.dialogWidth,
      panelClass: ['mode-changed-modal'],
      data: { ...opts },
      autoFocus: false,
    });
  }

  public openIfPostModeAndNotSeen(opts: ModalOpts): MatDialogRef<ModeChangedModalComponent> | null {
    const { id: userProcessId, mode: userProcessMode } = opts.userProcess;
    const explanationSeen = this.lsService.getPostModeExplanationModalSeen(userProcessId);
    if ([UserProcessMode.SEMI_POST, UserProcessMode.UKR].includes(userProcessMode) && !explanationSeen) {
      return this.open(opts);
    }
    return null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
