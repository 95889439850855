import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { ROLES_KEYS } from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { UserAuthFacade } from '@state/user-auth';

@Injectable()
export class IsForeignerGuard implements CanActivate {
  constructor(
    private userAuthFacade: UserAuthFacade,
    private readonly notifService: SnackbarService,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const exitUrlTree = this.router.createUrlTree(['/', 'user-process']);
    return this.userAuthFacade.getMyself$(state.url).pipe(
      take(1),
      switchMap(userFromStore => {
        const isForeigner = userFromStore.role?.key === ROLES_KEYS.Foreigner;

        // Assuming that 'company' object always exists inside `userFromStore`.
        // Assuming that property `purchaseEnabled` always exists in company object
        // This is settings telling: Are foreigners assigned to company X purchase applications
        const purchaseInCompanyEnabled = userFromStore.company.purchaseEnabled;

        if (isForeigner && purchaseInCompanyEnabled) {
          return of(true);
        } else {
          this.notifService.showError('ERRORS.NOT_AUTHORIZED_TO_ENTER');
          return of(exitUrlTree);
        }
      })
    );
  }
}
