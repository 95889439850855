import { Injectable } from '@angular/core';
import { Company, GetCompanyEmployeesProcesses, GetCompanyPayload } from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import * as companyActions from './company.actions';
import * as companySelectors from './company.selectors';

@Injectable()
export class CompanyFacade {
  public companies$ = this.store.select(companySelectors.selectCompanies);
  public loadedCompany$ = this.store.select(companySelectors.selectCompany);
  public pagination$ = this.store.select(companySelectors.selectPagination);
  public loading$ = this.store.select(companySelectors.selectLoading);
  public errorMessage$ = this.store.select(companySelectors.selectErrorMessage);

  public companyEmployeesProcesses$ = this.store.select(companySelectors.selectEmployeesProcesses);
  public companyEmployeesProcessesPagination$ = this.store.select(companySelectors.selectEmployeesProcessesPagination);

  public saveSuccess$ = this.actions.pipe(
    ofType(companyActions.updateCompanySuccess, companyActions.createCompanySuccess),
    map(
      (result: { updatedCompany?: Company; createdCompany?: Company }) => result.updatedCompany || result.createdCompany
    )
  );

  public updateError$ = this.actions.pipe(ofType(companyActions.updateCompanyError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getCompanies(payload: GetCompanyPayload): void {
    this.store.dispatch(companyActions.getCompanies({ payload }));
  }

  public getCompany(companyId: string): void {
    this.store.dispatch(companyActions.getCompany({ companyId }));
  }

  public createCompany(company: Company): void {
    this.store.dispatch(companyActions.createCompany({ company }));
  }

  public updateCompany(companyId: string, company: Company): void {
    this.store.dispatch(companyActions.updateCompany({ companyId, company }));
  }

  public setIsActiveCompany(companyId: string, isActive: boolean): void {
    this.store.dispatch(companyActions.setIsActiveCompany({ companyId, isActive }));
  }

  // This one will always return Company (because of filter operator)
  // but fkin typescript and its strict null checks are the worst
  public getCompany$(companyId: string): Observable<Company | null> {
    return this.store.select(companySelectors.selectCompany).pipe(
      tap(company => {
        if (!company || company.id !== companyId) {
          this.getCompany(companyId);
        }
      }),
      filter(company => {
        if (!company || company.id !== companyId) {
          return false;
        }
        return true;
      })
    );
  }

  public getCompanyEmployeesProcesses(payload: GetCompanyEmployeesProcesses): void {
    this.store.dispatch(companyActions.getCompanyEmployeesProcesses({ opts: payload }));
  }
}
