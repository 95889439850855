import { Component, Input } from '@angular/core';
import { AvailableLanguages } from '@constants';
import { Product } from '@interfaces';

@Component({
  selector: 'app-purchase-product-card',
  templateUrl: './purchase-product-card.component.html',
  styleUrls: ['./purchase-product-card.component.scss'],
})
export class PurchaseProductCardComponent {
  @Input() product: Product;
  @Input() isSelected: boolean;
  @Input() currLang: AvailableLanguages;
  @Input() priceSuffix: string;
  @Input() pricePrefix: string;

  constructor() {}
}
