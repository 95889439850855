<div class="loading-container">
  <div class="loading-content">
    <div class="loading-logo">
      <app-company-logo></app-company-logo>
    </div>
    <div *ngIf="spinner" class="loading-spinner">
      <span class="loader"> </span>
    </div>
    <div *ngIf="progressBar" class="loading-progressBar">
      <p class="loading-text">{{ message }}</p>
      <mat-progress-bar mode="determinate" value="{{ value }}"></mat-progress-bar>
    </div>
  </div>
</div>
