import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DiscountCodesState } from './discount-codes.state';

export const selectDcState = createFeatureSelector<DiscountCodesState>('discountCodes');

export const selectLoading = createSelector(selectDcState, state => state.loading);
export const selectDiscountCodes = createSelector(selectDcState, state => state.discountCodes);
export const selectDiscountCodesPagination = createSelector(selectDcState, state => state.discountCodesPagination);
export const selectDiscountCodeDetails = createSelector(selectDcState, state => state.discountCodeDetails);
export const checkIfCodeExistsInProgress = createSelector(selectDcState, state => state.checkIfCodeExistsInProgress);
