import Step from 'node_modules/shepherd.js/src/types/step';

import { TranslateService } from '@ngx-translate/core';

interface AvailableButtons {
  counter: Step.StepOptionsButton;
  next: Step.StepOptionsButton;
  back: Step.StepOptionsButton;
  cancel: Step.StepOptionsButton;
  done: Step.StepOptionsButton;
}

const gPrefix = 'GUIDE';
const prefix = `${gPrefix}.PROCESS-INTRO`;

export const getSteps = (t: TranslateService, currLang: string): Step.StepOptions[] => {
  const buttons: AvailableButtons = {
    counter: { text: '', action(): void {}, classes: 'counter-button' },
    next: {
      text: t.instant(`${gPrefix}.NEXT`),
      action(): void {
        this.next();
      },
      classes: 'standard-next-button',
    },
    back: {
      text: t.instant(`${gPrefix}.BACK`),
      action(): void {
        this.back();
      },
      classes: 'standard-back-button',
    },
    cancel: {
      text: t.instant(`${gPrefix}.CLOSE`),
      action(): void {
        this.cancel();
      },
      classes: 'standard-cancel-button',
    },
    done: {
      text: t.instant(`${gPrefix}.DONE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-done-button',
    },
  };

  const steps: Step.StepOptions[] = [
    {
      id: 'processintro',
      arrow: false,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 31,
      // attachTo: { element: '.open-chat-icon-wrapper', on: 'bottom-start' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.processintro.TITLE`),
      text: `
        ${t.instant(`${prefix}.processintro.TEXT_1`)}
        ${t.instant(`${prefix}.processintro.TEXT_2`)}
        ${t.instant(`${prefix}.processintro.TEXT_3`)}
        ${t.instant(`${prefix}.processintro.TEXT_4`)}
     `,
    },
  ];

  const guideName = t.instant(`${prefix}.GUIDE_NAME`);
  return steps.map((step, currIndex, all) => {
    const formattedButtons: Step.StepOptionsButton[] = [
      // { ...buttons.counter, text: `${guideName} ${currIndex + 1}/${all.length}` }
    ];

    if (currIndex === 0) {
      formattedButtons.push({ ...buttons.cancel });
    } else {
      formattedButtons.push({ ...buttons.back });
    }

    // if (currIndex < all.length - 1) {
    formattedButtons.push({ ...buttons.next });
    // } else {
    // formattedButtons.push({ ...buttons.done });
    // }

    return { ...step, buttons: formattedButtons };
  });
};
