<div mat-dialog-title>
  <div class="title-wrapper">
    <span [innerHTML]="'APPLICATION.ASSIGN_TYPE_FOR_PROCESS_OF_USER' | translate: { userName: data.user.name }"></span>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
</div>
<mat-dialog-content>
  <nz-tabset [nzSize]="'small'" [nzSelectedIndex]="selectedCategoryIndex" class="process-categories-tabs">
    <nz-tab *ngFor="let category of PROCESS_CATEGORIES" [nzTitle]="'PROCESS_CATEGORY.' + category | translate">
      <ng-container *ngIf="processTypeByCategory[category]?.length; else EmptyCategory">
        <nz-radio-group [(ngModel)]="selectedProcessId" (ngModelChange)="assignPurposeComment($event)">
          <ng-container *ngFor="let process of processTypeByCategory[category]">
            <label nz-radio [nzValue]="process.id">
              {{ 'PROCESSES.' + process.key | translate }}
            </label>
          </ng-container>
        </nz-radio-group>

        <ng-container *ngIf="category === PROCESS_CATEGORY.TEMP_PREMIT">
          <nz-divider></nz-divider>
          <div nz-row nzAlign="middle" class="purpose-row">
            <div nz-col nzXs="23" nzSm="23" nzLg="23" nzXl="18" nzXXl="18">
              <div class="purpose-label">
                {{ 'MANAGEMENT.APPLICATION-DETAILS.APPLICATION_PURPOSE_COMMENT' | translate }}
              </div>
              <input nz-input [(ngModel)]="purposeComment" />
            </div>
          </div>
        </ng-container>
      </ng-container>
    </nz-tab>
  </nz-tabset>
  <ng-template #EmptyCategory>
    <nz-empty></nz-empty>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="rows-wrapper">
    <div class="row flex-end" *ngIf="data.showOptionToTurnOffNotification">
      <mat-checkbox class="notify-checkbox" [(ngModel)]="notifyForeigner" [labelPosition]="'before'">
        <span [innerHTML]="'APPLICATION.NOTIFY_FOREIGNER' | translate: { userName: data.user.name }"></span>
      </mat-checkbox>
      <!-- CHECKBOX SHOULD SYSTEM SEND EMAIL-NOTIFICATION TO FOREIGNER -->
    </div>
    <div class="row">
      <button class="action-button cancel" mat-raised-button (click)="close()">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <button
        class="action-button save"
        mat-raised-button
        color="primary"
        [disabled]="!selectedProcessId || selectedProcessId === data.currentProcess?.id"
        (click)="save()">
        {{ 'COMMON.SAVE' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
