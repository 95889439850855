<div class="countries-visits-wrapper">
  <div class="country-visit-row-wrapper" *ngFor="let formGroup of visitsArray; index as formGroupIndex">
    <div class="visit-row" [formGroup]="formGroup">
      <div class="standard-form-field required-field">
        <app-country-select
          class="country-select"
          [fieldControl]="getControl('country', formGroupIndex)"
          [fieldKey]="'visit-country-' + formGroupIndex"
          [isRequired]="true"
          [fieldLabelKey]="'VISIT-COUNTRY'">
        </app-country-select>
      </div>
      <div class="standard-form-field required-field">
        <label [class.invalid]="formGroup.get('dateEnter').touched && formGroup.get('dateEnter').invalid">
          {{ 'PERSONAL_DATA_FORM.COUNTRY-VISIT-ENTRY-DATE-LONG-RESI.MAIN-LABEL' | translate }} *
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [required]="true"
            id="country{{ formGroupIndex }}dateEnter"
            formControlName="dateEnter"
            placeholder="DD.MM.YYYY"
            autocomplete="off" />
          <mat-icon
            #dateEnter="matTooltip"
            (click)="$event.preventDefault(); $event.stopPropagation(); dateEnter.toggle()"
            class="info-suffix-icon"
            matSuffix
            matTooltip="{{ 'PERSONAL_DATA_FORM.COUNTRY-VISIT-ENTRY-DATE-LONG-RESI.FORMAT-HINT' | translate }}"
            matTooltipClass="personalDataFormTooltip"
            svgIcon="info-outline">
          </mat-icon>
          <div *ngIf="formGroup.get('dateEnter').touched && formGroup.get('dateEnter').invalid" class="mat-error">
            {{ formGroup.get('dateEnter').errors | errorsFormatingAndTranslate }}
          </div>
        </mat-form-field>
      </div>
      <div class="standard-form-field required-field">
        <label [class.invalid]="formGroup.get('dateLeave').touched && formGroup.get('dateLeave').invalid">
          {{ 'PERSONAL_DATA_FORM.COUNTRY-VISIT-LEAVE-DATE-LONG-RESI.MAIN-LABEL' | translate }} *
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [required]="true"
            id="country{{ formGroupIndex }}dateLeave"
            formControlName="dateLeave"
            placeholder="DD.MM.YYYY"
            autocomplete="off" />
          <mat-icon
            #dateLeave="matTooltip"
            (click)="$event.preventDefault(); $event.stopPropagation(); dateLeave.toggle()"
            class="info-suffix-icon"
            matSuffix
            matTooltip="{{ 'PERSONAL_DATA_FORM.COUNTRY-VISIT-LEAVE-DATE-LONG-RESI.FORMAT-HINT' | translate }}"
            matTooltipClass="personalDataFormTooltip"
            svgIcon="info-outline">
          </mat-icon>
          <div *ngIf="formGroup.get('dateLeave').touched && formGroup.get('dateLeave').invalid" class="mat-error">
            {{ formGroup.get('dateLeave').errors | errorsFormatingAndTranslate }}
          </div>
        </mat-form-field>
      </div>

      <div class="visit-actions" *ngIf="visitsArray.length > 1">
        <button mat-icon-button (click)="removeVisit(formGroupIndex)" [disabled]="showLoading" color="warn">
          <mat-icon [class]="showLoading ? 'trash-disabled' : 'trash'" svgIcon="trash"></mat-icon>
        </button>
      </div>

      <div class="visit-actions" *ngIf="visitsArray.length === 1">
        <button mat-icon-button [disabled]="true">
          <mat-icon class="trash-disabled" svgIcon="trash"> </mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="action-buttons">
    <button
      [disabled]="showLoading"
      (click)="addNewVisit()"
      id="addNewTravelButton"
      (keydown.Tab)="lastFieldTabPressed.emit($event)"
      class="standard-form-button add-visit-button">
      <mat-icon>add</mat-icon>
      {{ 'COUNTRIES_VISITS_FORM.ADD-NEW-TRAVEL' | translate }}
    </button>

    <div class="loading-wrapper" *ngIf="showLoading">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="notification-wrapper saved-notification" *ngIf="showSaved">
      <mat-icon svgIcon="check-outline"></mat-icon>
      <span class="text">{{ 'COUNTRIES_VISITS_FORM.TRAVELS-SAVED-SUCCESFULLY' | translate }} </span>
    </div>
    <div class="notification-wrapper awaiting-notification" *ngIf="!showSaved && !showLoading">
      <mat-icon svgIcon="info-outline"></mat-icon>
      <span class="text">{{ 'COUNTRIES_VISITS_FORM.AWAITING-FOR-ALL-REQUIRED-DATA' | translate }} </span>
    </div>
  </div>
</div>
