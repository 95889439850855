import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PROCESS_CATEGORY, Process, ProcessTypeByCategory } from '@interfaces';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { CommonsFacade } from '@state/commons';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PopupOpts, PopupResult } from './assign-process-type-modal.service';

@Component({
  selector: 'app-assign-process-type-modal',
  templateUrl: './assign-process-type-modal.component.html',
  styleUrls: ['./assign-process-type-modal.component.scss'],
})
export class AssignProcessTypeModalComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public PROCESS_CATEGORIES = Object.values(PROCESS_CATEGORY);
  public PROCESS_CATEGORY = PROCESS_CATEGORY;

  public loadingCommons$ = this.commonsFacade.loading$;

  public allProcessTypes: Process[];
  public processTypeByCategory: ProcessTypeByCategory;

  public selectedProcessId: string;
  public selectedCategoryIndex: number;
  public notifyForeigner: boolean = true;
  public purposeComment: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupOpts,
    public dialogRef: MatDialogRef<AssignProcessTypeModalComponent, PopupResult>,
    private readonly commonsFacade: CommonsFacade,
    private readonly confirmationModal: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.commonsFacade.availableProcessesByCategory$.pipe(takeUntil(this.destroy$)).subscribe(processByCategory => {
      this.processTypeByCategory = processByCategory;
      this.selectedProcessId = this.data.currentProcess?.id || null;
      const activeCategory = this.data.currentProcess?.category;
      if (activeCategory) {
        this.selectedCategoryIndex = this.PROCESS_CATEGORIES.findIndex(c => c === activeCategory);
      } else {
        this.selectedCategoryIndex = 0;
      }
    });

    this.commonsFacade
      .getListOfAvailableProcesses$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(allProcesses => {
        this.allProcessTypes = allProcesses;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public assignPurposeComment(processId: string): void {
    this.purposeComment = this.allProcessTypes.find(({ id }) => id === processId).comment || null;
  }

  public save(): void {
    if (this.data.currentProcess?.id === this.selectedProcessId) {
      // Process type not changed
      this.dialogRef.close(null);
      return;
    }

    const message = this.notifyForeigner
      ? 'APPLICATION.ASSIGN_TYPE_WITH_NOTIFICATION_QUESTION'
      : 'APPLICATION.ASSIGN_TYPE_WITHOUT_NOTIFICATION_QUESTION';

    // Ask for confirmation only when changed
    this.confirmationModal
      .open({ confirmationToTranslate: message })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        // Process type changed - returning selected
        const selectedProcess = this.allProcessTypes.find(({ id }) => this.selectedProcessId === id);
        this.dialogRef.close({
          selectedProcess,
          purposeComment: this.purposeComment,
          notifyForeigner: this.notifyForeigner,
        });
      });
  }
}
