import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState } from './chat.state';

const selectChatState = createFeatureSelector<ChatState>('chat');

export const selectChat = createSelector(selectChatState, state => state.chat);

export const selectChatMessages = createSelector(selectChatState, state => state.messages);

export const selectAttachmentsToAdd = createSelector(selectChatState, state => state.messageAttachmentsToAdd);

export const selectLoading = createSelector(selectChatState, state => state.loading);
export const selectLoadingAttachments = createSelector(selectChatState, state => state.loadingAttachments);

export const selectErrorMessage = createSelector(selectChatState, state => state.errorMessage);
