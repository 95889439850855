<form [formGroup]="formGroup" id="step5Form">
  <div class="step5-form-container">
    <div class="row">
      <div class="standard-form-field radio-form-field noFamilyMembersInPoland-field required-field">
        <label [class.invalid]="controls.noFamilyMembersInPoland.touched && controls.noFamilyMembersInPoland.invalid">
          {{ 'PERSONAL_DATA_FORM.HAS-FAMILY-MEMBERS-IN-POLAND.MAIN-LABEL' | translate }}*
        </label>
        <mat-radio-group
          formControlName="noFamilyMembersInPoland"
          (keydown)="firstInputKeydown($event)"
          [required]="true">
          <!-- Inverted values because of label we're using -->
          <mat-radio-button [value]="false" id="applicantNoFamilyMembersInPoland-0">
            {{ 'COMMON.YES' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="true" id="applicantNoFamilyMembersInPoland-1">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div *ngIf="controls.noFamilyMembersInPoland.value === false" class="row">
      <app-family-members-form-temp-premit
        [fieldControl]="controls.familyMembersInPoland"
        (lastFieldTabPressed)="emitLastFieldTabPressed($event)">
      </app-family-members-form-temp-premit>
    </div>
    <!-- <ng-container *ngIf="mode === 'employee'">
      <div class="row">
        <ng-container *ngIf="!userProcess?.personalDetails?.verified; else unVerifyButton">
          <button class="toggle-button toggle-button-inactive" (click)="toggleVerificationOfPersonalDetails.emit()">
            {{ 'PERSONAL_DATA_FORM.VERIFY_USER_DETAILS' | translate }}
          </button>
        </ng-container>
        <ng-template #unVerifyButton>
          <button class="toggle-button toggle-button-active" (click)="toggleVerificationOfPersonalDetails.emit()">
            {{ 'PERSONAL_DATA_FORM.UNVERIFY_USER_DETAILS' | translate }}
          </button>
        </ng-template>
      </div>
      <div class="row">
        <div class="supported-modes">
          <div class="supported-modes-label">{{ 'NT.USER_PROCESS_MODE' | translate }}:</div>
          <div class="gen-pills-container-form">
            <span
              *ngFor="let mode of UserProcessModes"
              class="pill {{ mode.toLowerCase() }}"
              (click)="setUserProcessMode(mode)"
              matTooltip="{{ 'NT.MODE_NOT_SUPPORTED_IN_SELECTED_VOIVODESHIP' | translate }}"
              matTooltipClass="universalTooltip"
              [matTooltipDisabled]="voivodeshipSupportedModes.includes(mode)"
              [class.enabled]="userProcess.mode === mode"
              [class.disallowed]="!voivodeshipSupportedModes.includes(mode)">
              {{ 'NT.USER_PROCESS_MODES.' + mode | translate }}
            </span>
          </div>
        </div>
      </div>
    </ng-container> -->
  </div>
</form>
