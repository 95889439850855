import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { AnnexHttpService } from '@core/http/annex.http.service';

import * as annexActions from './annex.actions';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AnnexEffects {
  constructor(
    private actions$: Actions,
    private http: AnnexHttpService,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService
  ) {}

  getAnnexesInUserProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(annexActions.getAnnexes),
      switchMap(({ opts }) => {
        return this.http.getAnnexes(opts).pipe(
          map(annexes => annexActions.getAnnexesSuccess({ annexes, userProcessId: opts.userProcessId })),
          catchError(error => {
            this.nzMessage.error('ERROR_FETCHING_ANNEXES1_IN_USER_PROCESS');
            return of(annexActions.getAnnexesError({ errorMessage: error }));
          })
        );
      })
    )
  );

  createAnnex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(annexActions.createAnnex),
      switchMap(({ opts }) => {
        return this.http.createAnnex(opts).pipe(
          map(annex => annexActions.createAnnexSuccess({ annex, userProcessId: opts.userProcessId })),
          catchError(error => {
            this.nzMessage.error('ERROR_CREATING_ANNEX1');
            return of(annexActions.createAnnexError({ errorMessage: error }));
          })
        );
      })
    )
  );

  updateAnnex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(annexActions.updateAnnex),
      switchMap(({ opts }) => {
        return this.http.updateAnnex(opts).pipe(
          map(annex => annexActions.updateAnnexSuccess({ annex, userProcessId: opts.userProcessId })),
          catchError((error: HttpErrorResponse) => {
            let errorKnown = false;

            if (Array.isArray(error?.error?.message)) {
              this.showErrors(error?.error?.message);
              errorKnown = true;
            }
            if (typeof error?.error?.message === 'string') {
              this.showErrors([error?.error?.message]);
              errorKnown = true;
            }
            if (!errorKnown) {
              this.nzMessage.error('ERROR_UPDATING_ANNEX1');
            }

            return of(annexActions.updateAnnexError({ errorMessage: error }));
          })
        );
      })
    )
  );

  private showErrors(errorMessages: string[]): string[] {
    return errorMessages.map(msg => {
      if (msg.includes('DVERROR---')) {
        const [prefix, propertyName, errorName] = msg.split('---');
        const fieldNameTranslated = this.translateService.instant(
          `NT2.ANNEX_PROGRAM_FIELDNAMES_TO_TRANSLATION.${propertyName}`
        );
        let mssgToShow = this.translateService.instant(`NT2.${prefix}_${errorName}`, {
          fieldName: fieldNameTranslated,
        });
        this.nzMessage.error(mssgToShow, { nzDuration: 3000 });
        return mssgToShow;
      } else {
        this.nzMessage.error('UNKNOWN_ERROR: ' + msg, { nzDuration: 3000 });
      }
    });
  }

  removeAnnex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(annexActions.removeAnnex),
      switchMap(({ opts }) => {
        return this.http.removeAnnex(opts).pipe(
          map(() => annexActions.removeAnnexSuccess({ annexId: opts.annexId, userProcessId: opts.userProcessId })),
          catchError(error => {
            this.nzMessage.error('ERROR_REMOVING_ANNEX1');
            return of(annexActions.removeAnnexError({ errorMessage: error }));
          })
        );
      })
    )
  );

  getSpecialities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(annexActions.getSpecialities),
      switchMap(() => {
        return this.http.getSpecialities().pipe(
          map(specialities => annexActions.getSpecialitiesSuccess({ specialities })),
          catchError(error => {
            this.nzMessage.error('ERROR_FETCHING_SPECIALITIES');
            return of(annexActions.getSpecialitiesError({ errorMessage: error }));
          })
        );
      })
    )
  );
}
