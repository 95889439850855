import { User } from './user-auth';

export interface VisitAssistant extends Partial<User> {}

export interface CreateVisitAssistantOpts {
  name: string;
}

export interface AssignVisitAssistantToUserProcessOptss {
  visitAssistantId: string;
  userProcessId: string;
}
