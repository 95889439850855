<mat-dialog-content>
  <div class="close-wrapper">
    <button mat-icon-button aria-label="Close button" tabindex="-1">
      <mat-icon (click)="close()">close</mat-icon>
    </button>
  </div>

  <div
    [class.logo-wrapper]="(myself$ | async)?.company?.brandingLogo"
    [class.no-logo-wrapper]="!(myself$ | async)?.company?.brandingLogo">
    <app-company-logo></app-company-logo>
  </div>

  <ng-container [ngSwitch]="data.messageKey">
    <!-- =============================================================================================================== -->
    <!-- ============================== USER WELCOME ============================== -->
    <!-- =============================================================================================================== -->
    <ng-container *ngSwitchCase="MODAL_MESSAGE.USER_WELCOME">
      <div class="message-wrapper user_welcome">
        <h1>{{ 'INTRO_MODAL.USER_WELCOME.WELCOME' | translate }}</h1>
        <h2>
          {{ 'INTRO_MODAL.USER_WELCOME.HAPPY_TO_HELP' | translate }}
        </h2>
        <h3>
          {{ 'INTRO_MODAL.USER_WELCOME.FURTHER_INSTRUCTIONS_LATER' | translate }}

          <br />
          {{ 'INTRO_MODAL.USER_WELCOME.YOU_CAN_START_MANUALLY' | translate }}
          <mat-icon class="regular-text-color" fontSet="material-icons-outlined">live_help</mat-icon>

          {{ 'INTRO_MODAL.USER_WELCOME.INSTRUCTIONS_AFTER_CLOSING' | translate }}
        </h3>
      </div>
    </ng-container>

    <!-- =============================================================================================================== -->
    <!-- ============================== Personal Details Instructions ============================== -->
    <!-- =============================================================================================================== -->
    <ng-container *ngSwitchCase="MODAL_MESSAGE.PERSONAL_DETAILS_INSTRUCTIONS">
      <div class="message-wrapper personal_details_instructions">
        <h1>{{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.WELCOME' | translate }}</h1>
        <div class="list">
          <h3 class="list-title">
            {{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.APP_COMPLETION_STAGES.TITLE' | translate }}
          </h3>

          <div class="list-items">
            <p class="list-item">
              {{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_1' | translate }}
            </p>
            <p class="list-item highlighted">
              {{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_2' | translate }}
            </p>
            <p class="list-item">
              {{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_3' | translate }}
            </p>
            <p class="list-item">
              {{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_4' | translate }}
            </p>
          </div>
        </div>
        <div class="under-list-info">
          <h3>
            {{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.CURRENT_STAGE_INFO' | translate }}
            {{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.INFORMATION_USAGE' | translate }}
          </h3>
          <br />
          <p class="after-close-text">
            {{ 'INTRO_MODAL.PERSONAL_DETAILS_INSTRUCTIONS.AFTER_WINDOW_CLOSE' | translate }}
          </p>
        </div>
      </div>
    </ng-container>

    <!-- =============================================================================================================== -->
    <!-- ============================== DOCUMENTS Instructions ============================== -->
    <!-- =============================================================================================================== -->
    <ng-container *ngSwitchCase="MODAL_MESSAGE.DOCUMENTS_INSTRUCTIONS">
      <div class="message-wrapper documents_instructions">
        <h1>{{ 'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.WELCOME' | translate }}</h1>
        <div class="list">
          <h3 class="list-title">
            {{ 'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.APP_COMPLETION_STAGES.TITLE' | translate }}
          </h3>

          <div class="list-items">
            <p class="list-item">
              {{ 'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_1' | translate }}
            </p>
            <p class="list-item">
              {{ 'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_2' | translate }}
            </p>
            <p class="list-item highlighted">
              {{ 'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_3' | translate }}
            </p>
            <p class="list-item">
              {{ 'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_4' | translate }}
            </p>
          </div>
        </div>
        <div class="under-list-info">
          <h3>
            <span [innerHTML]="'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.CURRENT_STAGE_INFO' | translate"></span>
            <span [innerHTML]="'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.EXTRA_EXPLANATION' | translate"></span>
            <span [innerHTML]="'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.INFORMATION_USAGE' | translate"></span>
          </h3>
          <br />
          <p class="after-close-text">
            {{ 'INTRO_MODAL.DOCUMENTS_INSTRUCTIONS.AFTER_WINDOW_CLOSE' | translate }}
          </p>
        </div>
      </div>
    </ng-container>

    <!-- =============================================================================================================== -->
    <!-- ============================== NOT Finished Document Step ============================== -->
    <!-- =============================================================================================================== -->
    <ng-container *ngSwitchCase="MODAL_MESSAGE.UNFINISHED_DOCUMENT_STEP">
      <div class="message-wrapper unfinished_document_step">
        <h1>{{ 'INTRO_MODAL.UNFINISHED_DOCUMENT_STEP.TITLE' | translate }}</h1>

        <div class="under-list-info">
          <h3>
            <span [innerHTML]="'INTRO_MODAL.UNFINISHED_DOCUMENT_STEP.EXPLANATION' | translate"></span>
          </h3>
          <br />
        </div>
      </div>
    </ng-container>

    <!-- =============================================================================================================== -->
    <!-- ============================== Information displayed after sending whole application for verification (documents-step) ============================== -->
    <!-- =============================================================================================================== -->
    <ng-container *ngSwitchCase="MODAL_MESSAGE.VERIFICATION_DOCS_INFO">
      <div class="message-wrapper verification_docs_info">
        <h1>{{ 'INTRO_MODAL.VERIFICATION_DOCS_INFO.TITLE' | translate }}</h1>

        <div class="under-list-info">
          <h3>
            <span [innerHTML]="'INTRO_MODAL.VERIFICATION_DOCS_INFO.EXPLANATION' | translate"></span>
          </h3>
          <br />
        </div>
      </div>
    </ng-container>

    <!-- =============================================================================================================== -->
    <!-- ============================== Summary Instructions with basic level of support ============================== -->
    <!-- =============================================================================================================== -->
    <ng-container *ngSwitchCase="MODAL_MESSAGE.SUMMARY_BASIC_INSTRUCTIONS">
      <div class="message-wrapper summary_basic_instructions">
        <h1>{{ 'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.WELCOME' | translate }}</h1>
        <div class="list">
          <h3 class="list-title">
            {{ 'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.APP_COMPLETION_STAGES.TITLE' | translate }}
          </h3>

          <div class="list-items">
            <p class="list-item">
              {{ 'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_1' | translate }}
            </p>
            <p class="list-item">
              {{ 'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_2' | translate }}
            </p>
            <p class="list-item">
              {{ 'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_3' | translate }}
            </p>
            <p class="list-item highlighted">
              {{ 'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_4' | translate }}
            </p>
          </div>
        </div>
        <div class="under-list-info">
          <h3><span [innerHTML]="'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.CURRENT_STAGE_INFO' | translate"></span></h3>
          <h3>
            <span [innerHTML]="'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.EXTRA_EXPLANATION' | translate"></span>
            <span [innerHTML]="'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.INFORMATION_USAGE' | translate"></span>
            <span
              [innerHTML]="'INTRO_MODAL.SUMMARY_BASIC_INSTRUCTIONS.SENDING_DOCS_FOR_FINAL_VERIFY' | translate"></span>
          </h3>
          <br />
          <p class="after-close-text"></p>
        </div>
      </div>
    </ng-container>

    <!-- =============================================================================================================== -->
    <!-- ============================== Summary Instructions with Assistant level of support ============================== -->
    <!-- =============================================================================================================== -->
    <ng-container *ngSwitchCase="MODAL_MESSAGE.SUMMARY_ASSISTANT_INSTRUCTIONS">
      <div class="message-wrapper summary_assistant_instructions">
        <h1>{{ 'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.WELCOME' | translate }}</h1>
        <div class="list">
          <h3 class="list-title">
            {{ 'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.APP_COMPLETION_STAGES.TITLE' | translate }}
          </h3>

          <div class="list-items">
            <p class="list-item">
              {{ 'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_1' | translate }}
            </p>
            <p class="list-item">
              {{ 'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_2' | translate }}
            </p>
            <p class="list-item">
              {{ 'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_3' | translate }}
            </p>
            <p class="list-item highlighted">
              {{ 'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.APP_COMPLETION_STAGES.STAGE_4' | translate }}
            </p>
          </div>
        </div>
        <div class="under-list-info">
          <h3>
            <span [innerHTML]="'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.CURRENT_STAGE_INFO' | translate"></span>
          </h3>
          <h3>
            <span [innerHTML]="'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.EXTRA_EXPLANATION' | translate"> </span>
            <span [innerHTML]="'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.INFORMATION_USAGE' | translate"> </span>
            <span [innerHTML]="'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.SENDING_DOCS_FOR_FINAL_VERIFY' | translate">
            </span>
          </h3>
          <h3>
            <span [innerHTML]="'INTRO_MODAL.SUMMARY_ASSISTANT_INSTRUCTIONS.ASSISTANT_INFO' | translate"></span>
          </h3>
        </div>
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions *ngIf="data.showActionButtons">
  <ng-container [ngSwitch]="data.messageKey">
    <!-- =============================================================================================================== -->
    <!-- ============================== BUTTONS FOR NOT Finished Document Step ============================== -->
    <!-- =============================================================================================================== -->
    <div class="action-buttons" *ngSwitchCase="MODAL_MESSAGE.UNFINISHED_DOCUMENT_STEP">
      <button mat-raised-button (click)="close(false)">
        {{ 'INTRO_MODAL.UNFINISHED_DOCUMENT_STEP.CLOSE_AND_OK' | translate }}
      </button>
      <button mat-raised-button class="primary-action-button" (click)="close(true)">
        {{ 'INTRO_MODAL.UNFINISHED_DOCUMENT_STEP.CLOSE_AND_TAKE_ME_BACK' | translate }}
      </button>
    </div>

    <!-- =============================================================================================================== -->
    <!-- ============================== DEFAULT single button ============================== -->
    <!-- =============================================================================================================== -->
    <div class="action-buttons" *ngSwitchDefault>
      <button mat-raised-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
    </div>
  </ng-container>
</mat-dialog-actions>
