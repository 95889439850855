import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AvailableLanguages } from '@constants';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fullDateFormat } from '@constants';
import {
  customDateComparisonValidator,
  forbiddenFutureDate,
  forbiddenDateInPast,
  customVisitDateValidator,
} from '@shared/custom-validators';
import { ManagementFacade } from '@state/management';
import { merge, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UserProcessFacade } from '@state/user-process';
import { LEGAL_BASIS_OF_STAY, PreviousVisit } from '@interfaces';
import { isEqual } from 'lodash-es';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';

@Component({
  selector: 'app-previous-visits-form-temp-premit',
  templateUrl: './previous-visits-form-temp-premit.component.html',
  styleUrls: ['./previous-visits-form-temp-premit.component.scss'],
})
export class PreviousVisitsFormTempPremitComponent implements OnInit, OnDestroy {
  public destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @Input() previousVisitsArrFc: FormControl;
  @Input() currentlyLivingInPolandFc: FormControl;
  @Input() lastEntryDateFc: FormControl;
  @Input() legalBasisOfStayFc: FormControl;
  @Input() legalBasisOfStayCommentFc: FormControl;

  public currentLang: AvailableLanguages;
  public previousVisitsArray: FormGroup[] = [];

  public showLoading = false;
  public showSaved = false;

  public savedSuccess$ = merge(
    this.userProcessFacade.updatePersonalDetailsSuccess$,
    this.managementFacade.updatePersonalDetailsSuccess$
  );
  public saveError$ = merge(
    this.userProcessFacade.updatePersonalDetailsError$,
    this.managementFacade.updatePersonalDetailsError$
  );

  public oldPreviousVisitsValue: PreviousVisit[] | null;
  public currentlyIndex: number = null;

  private groupsValueChangesSubscriptions: Subscription[] = [];
  public LEGAL_BASIS_OF_STAY = LEGAL_BASIS_OF_STAY;

  public legalBasisOfStayList = [
    {
      labelKey: 'TEMPORARY_RESIDENCE_PERMIT',
      value: LEGAL_BASIS_OF_STAY.TEMP_PERMIT,
    },
    {
      labelKey: 'VISA_FREE_REGIME',
      value: LEGAL_BASIS_OF_STAY.VISA_FREE,
    },
    {
      labelKey: 'VISA',
      value: LEGAL_BASIS_OF_STAY.VISA,
    },
    {
      labelKey: 'DOCUMENT_ENTITLING_ENTRY_AND_STAY_ISSUED_BY_ANOTHER_SCHENGEN_STATE',
      value: LEGAL_BASIS_OF_STAY.SCHENGEN_COUNTRY_ISSUED_DOC,
    },
    {
      labelKey: 'OTHER_BASIS',
      value: LEGAL_BASIS_OF_STAY.OTHER,
    },
  ];

  constructor(
    private readonly translateService: TranslateService,
    private readonly fb: FormBuilder,
    private readonly managementFacade: ManagementFacade,
    private readonly userProcessFacade: UserProcessFacade,
    private readonly snackService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang as AvailableLanguages;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
    });

    const previousVisits: PreviousVisit[] | null = this.previousVisitsArrFc.value;
    this.oldPreviousVisitsValue = previousVisits;

    const lastEntryDateValue = this.lastEntryDateFc.value;
    const legalBasisValue = this.legalBasisOfStayFc.value;

    // if no visits
    // if no lastEntryDate provided
    // add 1 empty group
    if (!previousVisits?.length && !lastEntryDateValue) {
      const { group, groupSubscription } = this.getNewVisitGroup();
      this.previousVisitsArray.push(group);
      this.groupsValueChangesSubscriptions.push(groupSubscription);
    }

    if (previousVisits?.length) {
      previousVisits.forEach(previousVisit => {
        const { group, groupSubscription } = this.getNewVisitGroup(previousVisit);
        this.previousVisitsArray.push(group);
        this.groupsValueChangesSubscriptions.push(groupSubscription);
      });
    }

    if (lastEntryDateValue) {
      this.handleLastEntryDateChange();
    }
    if (legalBasisValue) {
      this.handleLegalBasisInCurrentStayChange();
    }

    this.legalBasisOfStayFc.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleLegalBasisInCurrentStayChange();
    });
    this.legalBasisOfStayCommentFc.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.handleLegalBasisInCurrentStayChange();
    });
    this.lastEntryDateFc.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleLastEntryDateChange();
    });
    this.currentlyLivingInPolandFc.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentlyLivingInPolandValue => {
        this.currentlyLivingInPolandAutoFilling(currentlyLivingInPolandValue);
      });

    if (!lastEntryDateValue && this.currentlyLivingInPolandFc.value) {
      const artefactVisit = this.previousVisitsArray.find(
        visit => visit.value.leaveDate?.toString()?.trim()?.toLowerCase()?.includes('obecnie')
      );
      if (artefactVisit) {
        artefactVisit.controls.leaveDate.setValue(null);
      }
    }

    this.saveVisits();
  }

  handleLastEntryDateChange(): void {
    let lastEntryDateValue = this.lastEntryDateFc.value;
    if (lastEntryDateValue?._isAMomentObject) {
      lastEntryDateValue = lastEntryDateValue.format(fullDateFormat);
    }
    // handleLastEntryDate only when length is correct!
    if (lastEntryDateValue?.length && [4, 7, 10].includes(lastEntryDateValue.length)) {
      this.setCurrentStayInVisits(lastEntryDateValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public currentlyChanged(changeEvent: MatCheckboxChange, formGroupIndex: number): void {
    const { checked } = changeEvent;
    // check if there was before row with marked 'currently'
    // if was -> uncheck it and nullify leaveDate
    const currentlyMarkedAt = this.previousVisitsArray.findIndex((fGroup: FormGroup) => {
      return fGroup?.controls?.leaveDate?.value?.toString()?.trim()?.toLowerCase()?.includes('obecnie');
    });

    if (currentlyMarkedAt !== -1 && currentlyMarkedAt !== formGroupIndex) {
      this.previousVisitsArray.at(currentlyMarkedAt).controls.leaveDate.enable();
      this.previousVisitsArray.at(currentlyMarkedAt).controls.leaveDate.setValue('');
    }

    this.currentlyIndex = formGroupIndex;
    const toMarkCurrentControls = this.previousVisitsArray.at(formGroupIndex).controls;
    if (checked) {
      toMarkCurrentControls.leaveDate.setValue('OBECNIE');
      toMarkCurrentControls.leaveDate.disable();
    } else {
      toMarkCurrentControls.leaveDate.enable();
      toMarkCurrentControls.leaveDate.setValue('');
      this.currentlyIndex = null;
    }
  }

  private handleLegalBasisInCurrentStayChange(): void {
    const legalBasis = this.legalBasisOfStayFc.value;
    if (!legalBasis || !this.currentlyLivingInPolandFc.value) {
      return;
    }

    const legalBasisEnum = this.legalBasisToEnum(legalBasis);

    // 1. check if there is already X-OBECNIE in previousVisitsArray
    const existsGroup = this.previousVisitsArray.find((fGroup: FormGroup, fIndex: number) => {
      const includes = fGroup?.controls?.leaveDate?.value?.toString()?.trim()?.toLowerCase()?.includes('obecnie');
      if (includes) {
        this.currentlyIndex = fIndex;
      }
      return includes;
    });

    // 2. if there is - modify it
    if (existsGroup) {
      existsGroup.controls.legalBasis.setValue(legalBasisEnum);
      existsGroup.controls.legalBasisComment.setValue(this.legalBasisOfStayCommentFc.value);
      this.saveVisits();
    }

    // 3. if there isnt - idgaf
    return;
  }

  private setCurrentStayInVisits(lastEntryDate: string): void {
    if (!this.currentlyLivingInPolandFc.value) {
      return;
    }

    // 1. check if there is already X-OBECNIE in previousVisitsArray
    const existsGroup = this.previousVisitsArray.find((fGroup: FormGroup, fIndex: number) => {
      const includes = fGroup?.controls?.leaveDate?.value?.toString()?.trim()?.toLowerCase()?.includes('obecnie');
      if (includes) {
        this.currentlyIndex = fIndex;
      }
      return includes;
    });

    const legalBasis = this.legalBasisOfStayFc.value;
    let legalBasisAsEnum = legalBasis ? this.legalBasisToEnum(legalBasis) : null;

    // 2. if there is - modify it
    if (existsGroup) {
      existsGroup.controls.entryDate.setValue(lastEntryDate);
      existsGroup.controls.leaveDate.disable();
      if (legalBasisAsEnum) {
        existsGroup.controls.legalBasis.setValue(legalBasisAsEnum);
        existsGroup.controls.legalBasisComment.setValue(this.legalBasisOfStayCommentFc.value);
      }
      this.saveVisits();
      return;
    }

    // 2'. if not, create newGroup and add it
    const visitGroup: PreviousVisit = {
      legalBasis: legalBasisAsEnum || null,
      legalBasisComment: null,
      entryDate: lastEntryDate,
      leaveDate: this.currentlyLivingInPolandFc.value === true ? 'OBECNIE' : '',
    };
    //  `${lastEntryDate}-OBECNIE`;

    const { group, groupSubscription } = this.getNewVisitGroup(visitGroup);

    if (
      this.currentlyLivingInPolandFc.value &&
      group.controls.leaveDate.value?.toString()?.trim()?.toLowerCase()?.includes('obecnie')
    ) {
      group.controls.leaveDate.disable();
    }
    this.previousVisitsArray.unshift(group);
    this.currentlyIndex = 0;
    this.groupsValueChangesSubscriptions.unshift(groupSubscription);
    this.saveVisits();
    return;
  }

  public getControl(name: string, groupIndex: number): FormControl {
    return this.previousVisitsArray[groupIndex].get(name) as FormControl;
  }

  public addNewVisit(): void {
    this.showSaved = false;
    const { group, groupSubscription } = this.getNewVisitGroup();
    this.previousVisitsArray.push(group);
    this.groupsValueChangesSubscriptions.push(groupSubscription);
  }

  private getNewVisitGroup(previousVisit?: PreviousVisit): { group: FormGroup; groupSubscription: Subscription } {
    const visitDateValidatorsArray = [
      Validators.required,
      customVisitDateValidator,
      forbiddenDateInPast({ specificDate: '01.01.2015' }),
      forbiddenFutureDate(),
    ];

    const group: FormGroup<any> = this.fb.group(
      {
        entryDate: [null, Validators.compose([...visitDateValidatorsArray])],
        leaveDate: [null, Validators.compose([...visitDateValidatorsArray])],
        legalBasis: [null, Validators.compose([Validators.maxLength(200)])],
        legalBasisComment: [null, Validators.compose([Validators.maxLength(2000)])],
      },
      { validators: customDateComparisonValidator('entryDate', 'leaveDate') }
    );

    if (previousVisit) {
      group.setValue({
        entryDate: previousVisit.entryDate,
        leaveDate: previousVisit.leaveDate,
        legalBasis: previousVisit.legalBasis,
        legalBasisComment: previousVisit.legalBasisComment,
      });

      if (!this.currentlyLivingInPolandFc.value) {
        this.replaceOBECNIE(group);
      }
    }

    const groupSubscription = group.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(() => {
      this.showSaved = false;
      if (group.controls.entryDate.getRawValue() || group.controls.leaveDate.getRawValue()) {
        group.markAllAsTouched();
      }

      if (!group.valid) {
        return;
      }
      this.saveVisits();
    });
    return { group, groupSubscription };
  }

  public removeVisit(groupIndex: number): void {
    // Not possible to remove last visit
    if (this.previousVisitsArray.length === 1) {
      return;
    }
    this.showSaved = false;
    this.previousVisitsArray.splice(groupIndex, 1);
    const sub = this.groupsValueChangesSubscriptions[groupIndex];
    sub.unsubscribe();
    this.groupsValueChangesSubscriptions.splice(groupIndex, 1);
    this.saveVisits();
  }

  public allVisitsCorrect(): boolean {
    return this.previousVisitsArray?.every(fg => fg.valid);
  }

  public saveVisits(): void {
    if (!this.allVisitsCorrect()) {
      return;
    }

    this.showLoading = true;
    this.showSaved = false;

    const rawPreviousVisits = this.previousVisitsArray.map(prevVisitGroup => prevVisitGroup.getRawValue());
    const changesMade = !isEqual(this.oldPreviousVisitsValue, rawPreviousVisits);

    if (changesMade) {
      this.savedSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
        this.showLoading = false;
        this.showSaved = true;
      });
      this.saveError$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
        this.showLoading = false;
        this.showSaved = false;
        this.snackService.showError('NT2.UNKNOWN_ERROR_SAVING_STAYS');
      });
    } else {
      this.showLoading = false;
      this.showSaved = true;
    }

    this.previousVisitsArrFc.setValue(rawPreviousVisits);
    this.oldPreviousVisitsValue = structuredClone(rawPreviousVisits);
  }

  private legalBasisToEnum(legalBasisOfStayString: string): LEGAL_BASIS_OF_STAY {
    const m: { [key: string]: LEGAL_BASIS_OF_STAY } = {
      'ruchu bezwizowego': LEGAL_BASIS_OF_STAY.VISA_FREE,
      wizy: LEGAL_BASIS_OF_STAY.VISA,
      'zezwolenia na pobyt czasowy': LEGAL_BASIS_OF_STAY.TEMP_PERMIT,
      'dokumentu uprawniającego do wjazdu i pobyt wydanego przez inne państwo obszaru schengen':
        LEGAL_BASIS_OF_STAY.SCHENGEN_COUNTRY_ISSUED_DOC,
      'inna podstawa': LEGAL_BASIS_OF_STAY.OTHER,
    };
    const key = legalBasisOfStayString.toString().toLowerCase().trim() as any;
    return m[key];
  }

  public currentlyLivingInPolandAutoFilling(currentlyLivingInPolandValue: boolean): void {
    if (currentlyLivingInPolandValue === false && this.previousVisitsArray?.length === 1 && this.hasAnyOBECNIE()) {
      this.previousVisitsArray = [];
      this.groupsValueChangesSubscriptions = [];
      return;
    }
    if (currentlyLivingInPolandValue === false && this.previousVisitsArray?.length > 1) {
      for (let visit of this.previousVisitsArray) {
        this.replaceOBECNIE(visit);
      }
      return;
    }
  }

  public hasAnyOBECNIE(): boolean {
    return !!this.previousVisitsArray.find(
      visit => visit.value.leaveDate?.toString()?.trim()?.toLowerCase()?.includes('obecnie')
    );
  }

  public replaceOBECNIE(group: FormGroup): void {
    if (group.controls.leaveDate.value?.toString()?.trim()?.toLowerCase()?.includes('obecnie')) {
      group.controls.leaveDate.enable();
      group.controls.leaveDate.setValue('');
    }
  }
}
