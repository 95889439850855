<h1 mat-dialog-title>
  {{ 'FOLLOW_UP.FOLLOW_UP_HEADER' | translate }} - <b>{{ this.data.supportChat.supportedUser.name }}</b> ({{
    this.data.supportChat.supportedUser.language.name
  }})
</h1>
<div mat-dialog-content>
  <p>{{ 'FOLLOW_UP.FOLLOW_UP_CHOOSE_LANGUAGE' | translate }}</p>
  <mat-form-field appearance="legacy" *ngIf="languages$ | async as languages">
    <mat-select [(value)]="selectedLang" (selectionChange)="onChangeLanguage($event.value)">
      <mat-option *ngFor="let language of languages" [value]="language.key">
        {{ 'LANGUAGE.' + language.key.toUpperCase() | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="dialog_follow_up">
    <mat-label>{{ 'FOLLOW_UP.FOLLOW_UP_WHAT_SEND' | translate }}</mat-label>
    <textarea matInput [(ngModel)]="dialogMessage"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button class="dialog_follow_up_cancel" (click)="closeDialog()">
    {{ 'FOLLOW_UP.FOLLOW_UP_CANCEL' | translate }}
  </button>
  <button mat-button class="dialog_follow_up_send" (click)="sendMessege(dialogMessage)">
    {{ 'FOLLOW_UP.FOLLOW_UP_SEND' | translate }}
  </button>
</div>
