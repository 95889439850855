import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PurchaseState } from './purchase.state';

export const selectState = createFeatureSelector<PurchaseState>('purchase');

export const selectLoadingProducts = createSelector(selectState, state => state.loadingAvailableProducts);
export const selectLoadingPurchase = createSelector(selectState, state => state.loadingPurchase);
export const selectCreatingPurchaseInProgress = createSelector(selectState, state => state.creatingPurchaseInProgress);
export const selectAvailableProducts = createSelector(selectState, state => state.availableProducts);
export const selectLoadingPaymentMethods = createSelector(selectState, state => state.loadingPaymentMethods);
export const selectAvailablePaymentMethods = createSelector(selectState, state => state.availablePaymentMethods);
export const selectMyPurchase = createSelector(selectState, state => state.myPurchase);
export const selectErrorMessage = createSelector(selectState, state => state.errorMessage);
export const selectPurchaseList = createSelector(selectState, state => state.purchaseList);
export const selectLoadingPurchaseList = createSelector(selectState, state => state.loadingPurchaseList);
