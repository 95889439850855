import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { CommonsFacade } from '@state/commons';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ProcessesGuard implements CanActivate {
  constructor(
    private router: Router,
    private commonsFacade: CommonsFacade,
    private readonly notifService: SnackbarService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const exitUrlTree = this.router.createUrlTree(['/']);
    return this.commonsFacade.getListOfAvailableProcesses$().pipe(
      take(1),
      map(processes => {
        if (!processes?.length) {
          this.notifService.showError('MISSING_AVAILABLE_PROCESSES');
          return exitUrlTree;
        }
        return true;
      })
    );
  }
}
