import { Component, EventEmitter, OnDestroy, Output, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface PillSwitchOption {
  label: string;
  value: any;
}

@Component({
  selector: 'app-switch-option-pills',
  templateUrl: './switch-option-pills.component.html',
  styleUrls: ['./switch-option-pills.component.scss'],
})
export class SwitchOptionPillsComponent implements OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @Input() options: PillSwitchOption[];
  @Input() selected?: PillSwitchOption['value'];
  @Input() disabled?: boolean;
  @Output() selectionChanged: EventEmitter<PillSwitchOption> = new EventEmitter();

  constructor() {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public pillClicked(option: any): void {
    this.selectionChanged.emit(option);
    this.selected = option.value;
  }
}
