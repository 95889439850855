import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserAuthFacade } from '@state/user-auth';
import { ModalOpts } from './mode-changed-modal.service';

@Component({
  selector: 'app-mode-changed-modal',
  templateUrl: './mode-changed-modal.component.html',
  styleUrls: ['./mode-changed-modal.component.scss'],
})
export class ModeChangedModalComponent implements OnInit {
  public additionalMessage = '';

  public checkedProcessSteps: string[] = [];

  public myself$ = this.userAuthFacade.myself$;

  public hasAssistantService: boolean;
  public hasFullHandling: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOpts,
    public dialogRef: MatDialogRef<ModeChangedModalComponent>,
    private readonly userAuthFacade: UserAuthFacade
  ) {}

  public ngOnInit(): void {
    this.hasAssistantService = this.data.userProcess.hasAssistantService;
    this.hasFullHandling = this.data.userProcess.hasFullHandling;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
