import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  CreateDiscountCodeOpts,
  DiscountCode,
  EditDiscountCodeOpts,
  PaginatedResponse,
  SearchDiscountCodesOpts,
} from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DiscountCodesHttpService {
  private API_URL = environment.API_URL;
  private DISCOUNT_CTRL_URL = `${this.API_URL}/discount-codes`;

  constructor(private http: HttpClient) {}

  public createDiscountCode(opts: CreateDiscountCodeOpts): Observable<DiscountCode> {
    const url = `${this.DISCOUNT_CTRL_URL}/management`;
    return this.http.post<DiscountCode>(url, opts);
  }

  public editDiscountCode(opts: EditDiscountCodeOpts): Observable<DiscountCode> {
    const url = `${this.DISCOUNT_CTRL_URL}/management/${opts.id}`;
    return this.http.patch<DiscountCode>(url, opts);
  }

  public searchDiscountCodes(opts: SearchDiscountCodesOpts): Observable<PaginatedResponse<DiscountCode>> {
    const url = `${this.DISCOUNT_CTRL_URL}/management/search-codes`;
    const { all, count, ...body } = opts;
    return this.http.post<PaginatedResponse<DiscountCode>>(url, body);
  }

  public setActiveDiscountCode(discountCodeId: string, setActive: 'deactivate' | 'activate'): Observable<DiscountCode> {
    const url = `${this.DISCOUNT_CTRL_URL}/management/${discountCodeId}/${setActive}`;
    return this.http.patch<DiscountCode>(url, {});
  }

  public getDiscountCodeDetails(discountCodeId: string): Observable<DiscountCode> {
    const url = `${this.DISCOUNT_CTRL_URL}/management/${discountCodeId}`;
    return this.http.get<DiscountCode>(url);
  }

  public checkIfCodeExists(code: string): Observable<boolean> {
    const url = `${this.DISCOUNT_CTRL_URL}/management/code-exists/${encodeURIComponent(code)}`;
    return this.http.get<boolean>(url);
  }
}
