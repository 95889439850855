import { Injectable } from '@angular/core';
import { GetAdminsPayload, GetOnlineUsersPayload, User } from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CreateProductOpts, UpdateProductOpts } from 'src/app/_interfaces/product';
import * as systemSettingsActions from './system-settings.actions';
import * as systemSettingsSelectors from './system-settings.selectors';

@Injectable()
export class SystemSettingsFacade {
  public systemSettings$ = this.store.select(systemSettingsSelectors.selectSystemSettings);
  public loading$ = this.store.select(systemSettingsSelectors.selectLoading);
  public errorMessage$ = this.store.select(systemSettingsSelectors.selectErrorMessage);
  public loadingAdmins$ = this.store.select(systemSettingsSelectors.selectLoadingAdmins);
  public selfRegisterActive$ = this.store.select(systemSettingsSelectors.selectSelfRegisterActive);
  public products$ = this.store.select(systemSettingsSelectors.selectProducts);
  public productHistory$ = this.store.select(systemSettingsSelectors.selectProductHistory);
  public loadingOnlineUsers$ = this.store.select(systemSettingsSelectors.selectLoadingOnlineUsers);

  public paginationAdmins$ = this.store.select(systemSettingsSelectors.selectLoadedAdminsPagination);
  public admins$ = this.store.select(systemSettingsSelectors.selectLoadedAdmins);
  public loadedAdmin$ = this.store.select(systemSettingsSelectors.selectLoadedAdminData);

  public selfRegistrationStatusChanged$ = this.actions.pipe(
    ofType(systemSettingsActions.setSelfRegistrationStatusSuccess)
  );
  public paginationOnlineUsers$ = this.store.select(systemSettingsSelectors.selectLoadedAdminsPagination);
  public onlineUsers$ = this.store.select(systemSettingsSelectors.selectLoadedOnlineUsers);

  public saveSuccess$ = this.actions.pipe(
    ofType(
      systemSettingsActions.changePINSuccess,
      systemSettingsActions.changeMainNotificationsRecipientSuccess,
      systemSettingsActions.changeAdditionalRecipientsSuccess,
      systemSettingsActions.changeSecurityRecipientsSuccess,
      systemSettingsActions.setSelfRegistrationStatusSuccess
    )
  );

  public resendSuccess$ = this.actions.pipe(ofType(systemSettingsActions.resendAdminActivationSuccess));
  public saveAdminSuccess$ = this.actions.pipe(
    ofType(systemSettingsActions.createAdminSuccess, systemSettingsActions.updateAdminSuccess),
    map((result: { updatedAdmin?: User; createdAdmin?: User }) => result.updatedAdmin || result.createdAdmin)
  );

  public updateProductSuccess$ = this.actions.pipe(ofType(systemSettingsActions.updateProductSuccess));
  public createProductSuccess$ = this.actions.pipe(ofType(systemSettingsActions.createProductSuccess));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getSystemSettings(): void {
    this.store.dispatch(systemSettingsActions.getSystemSettings());
  }

  public setSelfRegistrationStatus(isActive: boolean): void {
    this.store.dispatch(systemSettingsActions.setSelfRegistrationStatus({ isActive }));
  }

  public getSelfRegistrationStatus(): void {
    this.store.dispatch(systemSettingsActions.getSelfRegistrationStatus());
  }

  public changePIN(newPIN: string): void {
    this.store.dispatch(systemSettingsActions.changePIN({ newPIN }));
  }

  public changeMainNotificationsRecipient(newEmail: string): void {
    this.store.dispatch(systemSettingsActions.changeMainNotificationsRecipient({ newEmail }));
  }

  public changeAdditionalRecipients(recipientsEmails: string[]): void {
    this.store.dispatch(systemSettingsActions.changeAdditionalRecipients({ recipientsEmails }));
  }

  public changeSecurityRecipients(recipientsEmails: string[]): void {
    this.store.dispatch(systemSettingsActions.changeSecurityRecipients({ recipientsEmails }));
  }

  public getOnlineUsers(payload: GetOnlineUsersPayload): void {
    this.store.dispatch(systemSettingsActions.getOnlineUsers({ payload }));
  }

  public getAdmins(payload: GetAdminsPayload): void {
    this.store.dispatch(systemSettingsActions.getAdmins({ payload }));
  }

  public fetchAdmin(adminId: string): void {
    this.store.dispatch(systemSettingsActions.getAdmin({ adminId }));
  }

  public getAdmin$(adminId: string): Observable<User | null> {
    return this.store.select(systemSettingsSelectors.selectLoadedAdminData).pipe(
      tap(admin => {
        if (!admin || admin.id !== adminId) {
          this.fetchAdmin(adminId);
        }
      }),
      filter(admin => {
        return !(admin === null || admin.id !== adminId);
      })
    );
  }

  public createAdmin(payload: Partial<User>): void {
    this.store.dispatch(systemSettingsActions.createAdmin({ payload }));
  }

  public updateAdmin(payload: Partial<User>, userId: string): void {
    this.store.dispatch(systemSettingsActions.updateAdmin({ payload, userId }));
  }

  public removeAdmin(userId: string): void {
    this.store.dispatch(systemSettingsActions.removeAdmin({ userId }));
  }

  public resendAdminActivation(email: string): void {
    this.store.dispatch(systemSettingsActions.resendAdminActivation({ email }));
  }

  public recreateAvatarsForAllUsers(): void {
    this.store.dispatch(systemSettingsActions.recreateAvatarsForAllUsers());
  }

  public getProducts(): void {
    this.store.dispatch(systemSettingsActions.getProducts());
  }
  public createProduct(opts: CreateProductOpts): void {
    this.store.dispatch(systemSettingsActions.createProduct({ opts }));
  }
  public updateProduct(opts: UpdateProductOpts): void {
    this.store.dispatch(systemSettingsActions.updateProduct({ opts }));
  }
  public getProductHistory(productId: string): void {
    this.store.dispatch(systemSettingsActions.getProductHistory({ productId }));
  }
}
