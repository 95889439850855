import { createAction, props } from '@ngrx/store';
import { Company, GetCompanyEmployeesProcesses, GetCompanyPayload, PaginatedResponse, UserProcess } from '@interfaces';

export const getCompanies = createAction('[Company] Get Companies', props<{ payload: GetCompanyPayload }>());
export const getCompaniesSuccess = createAction(
  '[Company] Get Companies Success',
  props<{ companies: PaginatedResponse<Company> }>()
);
export const getCompaniesError = createAction('[Company] Get Companies Error', props<{ errorMessage: any }>());

export const getCompany = createAction('[Company] Get Company', props<{ companyId: string }>());
export const getCompanySuccess = createAction('[Company] Get Company Success', props<{ company: Company }>());
export const getCompanyError = createAction('[Company] Get Company Error', props<{ errorMessage: any }>());

export const createCompany = createAction('[Company] Create Company', props<{ company: Company }>());
export const createCompanySuccess = createAction(
  '[Company] Create Company Success',
  props<{ createdCompany: Company }>()
);
export const createCompanyError = createAction('[Company] Create Company Error', props<{ errorMessage: any }>());

export const updateCompany = createAction('[Company] Update Company', props<{ companyId: string; company: Company }>());
export const updateCompanySuccess = createAction(
  '[Company] Update Company Success',
  props<{ updatedCompany: Company }>()
);
export const updateCompanyError = createAction('[Company] Update Company Error', props<{ errorMessage: any }>());

// export const removeCompany = createAction('[Company] Remove Company', props<{ companyId: string }>());
// export const removeCompanySuccess = createAction('[Company] Remove Company Success', props<{ removedCompanyId: string }>());
// export const removeCompanyError = createAction('[Company] Remove Company Error', props<{ errorMessage: any }>());

export const setIsActiveCompany = createAction(
  '[Company] Change isActive Company',
  props<{ companyId: string; isActive: boolean }>()
);
export const setIsActiveCompanySuccess = createAction(
  '[Company] Change isActive Company Success',
  props<{ updatedCompany: Company }>()
);
export const setIsActiveCompanyError = createAction(
  '[Company] Change isActive Company Error',
  props<{ errorMessage: string }>()
);

export const getCompanyEmployeesProcesses = createAction(
  '[Company Manager] Get Company Employees Processes',
  props<{ opts: GetCompanyEmployeesProcesses }>()
);
export const getCompanyEmployeesProcessesSuccess = createAction(
  '[Company Manager] Get Company Employees Processes Success',
  props<{ employeesProcesses: PaginatedResponse<Partial<UserProcess>> }>()
);
export const getCompanyEmployeesProcessesError = createAction(
  '[Company Manager] Get Company Employees Processes Error',
  props<{ errorMessage: string }>()
);
