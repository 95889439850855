import { AvailableLanguages } from '@constants';

export interface Language {
  id: string;
  name: string;
  key: string;
  flagFile: null | string;
}

export type CachedTranslations = {
  [key in AvailableLanguages]: string;
};
