import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './user-assets.actions';
import { UserAssetsState, initialState } from './user-assets.state';

const reducer = createReducer(
  initialState,
  on(actions.downloadMyAsset, (state, { userAssetId }) => ({
    ...state,
    downloadingFile: userAssetId,
    loading: true,
  })),
  on(actions.downloadMyAssetSuccess, state => ({
    ...state,
    downloadingFile: null,
    loading: false,
  })),
  on(actions.downloadMyAssetError, state => ({
    ...state,
    downloadingFile: null,
    loading: false,
  })),
  on(actions.removeMyAsset, state => ({
    ...state,
    loading: true,
  })),
  on(actions.uploadPreSurveyAttachments, state => ({
    ...state,
    loading: true,
  })),
  on(
    actions.uploadPreSurveyAttachmentsSuccess,
    actions.uploadPreSurveyAttachmentsError,
    actions.removeMyAssetSuccess,
    actions.removeMyAssetError,
    state => ({
      ...state,
      loading: false,
    })
  )
);

export function userAssetsReducer(state: UserAssetsState, action: Action): UserAssetsState {
  return reducer(state, action);
}
