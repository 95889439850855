import {
  AddFileToMyDocOpts,
  AddNewUserProcessOpts,
  AnswerToMyDocQuestionOpts,
  AvailabilityForOfficeVisit,
  PersonalDetails,
  PreSurvey,
  ProcessDocumentsList,
  SaveDocumentsSigningModeOpts,
  SaveUserAdditionalDocuments,
  SaveUserDocumentPayload,
  SetAppointmentDateAndPlaceOpts,
  SummaryDocuments,
  UpdatePreSurveyOpts,
  UserDocument,
  UserProcess,
  UserProcessStatus,
  ValidatePersonalDetailsResponse,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const getMyProcesses = createAction('[User-Process] Get My Processes');
export const getMyProcessesSuccess = createAction(
  '[User-Process] Get My Processes Success',
  props<{ myProcesses: UserProcess[] }>()
);
export const getMyProcessesError = createAction(
  '[User-Process] Get My Processes Error',
  props<{ errorMessage: string }>()
);

export const getMyProcessDetails = createAction(
  '[User-Process] Get My Process Details',
  props<{ userProcessId: string }>()
);
export const getMyProcessDetailsSuccess = createAction(
  '[User-Process] Get My Process Details Success',
  props<{ myProcess: UserProcess }>()
);
export const getMyProcessDetailsError = createAction(
  '[User-Process] Get My Process Details Error',
  props<{ errorMessage: string }>()
);

export const addNewUserProcess = createAction(
  '[User-Process] Add New User Process',
  props<{ payload: AddNewUserProcessOpts }>()
);
export const addNewUserProcessSuccess = createAction(
  '[User-Process] Add New User Process Success',
  props<{ addedUserProcess: UserProcess }>()
);
export const addNewUserProcessError = createAction(
  '[User-Process] Add New User Process Error',
  props<{ errorMessage: string }>()
);

export const getUserProcess = createAction('[User-Process] Get current user process');
export const getUserProcessSuccess = createAction(
  '[User-Process] Get current user process succes',
  props<{ userProcess: UserProcess }>()
);
export const getUserProcessError = createAction(
  '[User-Process] Get current user process error',
  props<{ errorMessage: string }>()
);

export const pickProcess = createAction(
  '[User-Process] Pick process type',
  props<{ userProcessId: string; processId: string }>()
);

export const pickProcessSuccess = createAction(
  '[User-Process] Pick process type Success',
  props<{ userProcess: UserProcess }>()
);

export const pickProcessError = createAction(
  '[User-Process] Pick process type Error',
  props<{ errorMessage: string }>()
);

export const updatePreSurvey = createAction('[User-Process] Update Pre Survey', props<{ opts: UpdatePreSurveyOpts }>());
export const updatePreSurveySuccess = createAction(
  '[User-Process] Update Pre Survey Success',
  props<{ preSurvey: PreSurvey; userProcessId: string; userId: string }>()
);
export const updatePreSurveyError = createAction('[User-Process] Update Pre Survey Error');

export const sendPreSurveyForVerification = createAction(
  '[User-Process] Send Pre Survey for verificaion',
  props<{ opts: UpdatePreSurveyOpts }>()
);
export const sendPreSurveyForVerificationSuccess = createAction(
  '[User-Process] Send Pre Survey for verificaion Success',
  props<{ userProcess: UserProcess }>()
);
export const sendPreSurveyForVerificationError = createAction('[User-Process] Send Pre Survey for verificaion Error');

export const updatePersonalDetails = createAction(
  '[User-Process] Update Personal Details',
  props<{ userProcessId: string; personalDetails: PersonalDetails }>()
);
export const updatePersonalDetailsSuccess = createAction(
  '[User-Process] Update Personal Details Success',
  props<{ userProcessId: string; updatedUserProcess: UserProcess }>()
);
export const updatePersonalDetailsError = createAction(
  '[User-Process] Update Personal Details Error',
  props<{ errorMessage: string }>()
);

export const confirmPersonalDetails = createAction(
  '[User-Process] Confirm Personal Details',
  props<{ userProcessId: string }>()
);
export const confirmPersonalDetailsSuccess = createAction(
  '[User-Process] Confirm Personal Details Success',
  props<{ userProcessId: string; validationResult: ValidatePersonalDetailsResponse }>()
);
export const confirmPersonalDetailsError = createAction(
  '[User-Process] Confirm Personal Details Error',
  props<{ errorMessage: string }>()
);

export const startCompletingDocuments = createAction(
  '[User-Process] Starte completing documents',
  props<{ userProcessId: string }>()
);
export const startCompletingDocumentsSuccess = createAction(
  '[User-Process] Starte completing documents Success',
  props<{ updatedUserProcess: UserProcess }>()
);
export const startCompletingDocumentsError = createAction(
  '[User-Process] Starte completing documents Error',
  props<{ errorMessage: string }>()
);

export const getDocumentsList = createAction(
  '[User-Process-Documents] Get Documents List',
  props<{ userProcessId: string }>()
);
export const getDocumentsListSuccess = createAction(
  '[User-Process-Documents] Get Documents List Success',
  props<{ documentsList: ProcessDocumentsList }>()
);
export const getDocumentsListError = createAction(
  '[User-Process-Documents] Get Documents List Error',
  props<{ errorMessage: string }>()
);

export const addFilesToMyDocument = createAction(
  '[User-Process-Documents] Add Files to My Document',
  props<{ opts: AddFileToMyDocOpts }>()
);
export const addFilesToMyDocumentSuccess = createAction(
  '[User-Process-Documents] Add Files to My Document Success',
  props<{ userDocument: UserDocument }>()
);
export const addFilesToMyDocumentError = createAction('[User-Process-Documents] Add Files to My Document Error');

export const answerToMyDocQuestion = createAction(
  '[User-Process-Documents] Answer to my document question',
  props<{ opts: AnswerToMyDocQuestionOpts }>()
);
export const answerToMyDocQuestionSuccess = createAction(
  '[User-Process-Documents] Answer to my document question Success',
  props<{ userDocument: UserDocument }>()
);
export const answerToMyDocQuestionError = createAction('[User-Process-Documents] Answer to my document question Error');

export const saveUserDocument = createAction(
  '[User-Process-Documents] Save User Document',
  props<{
    saveDocumentPayload: SaveUserDocumentPayload;
    userDocId?: string;
  }>()
);
export const saveUserDocumentSuccess = createAction(
  '[User-Process-Documents] Save User Document Success',
  props<{ userDocument: UserDocument }>()
);

export const createNewAdditionalDocumentSuccess = createAction(
  '[User-Process-Documents] Create Additional Document Success',
  props<{ userDocument: UserDocument }>()
);

export const saveUserDocumentError = createAction(
  '[User-Process-Documents] Save User Document Error',
  props<{ errorMessage: string }>()
);

export const downloadFile = createAction(
  '[User-Process-Documents] Download Uploaded File In UserProcess',
  props<{ userProcessId: string; userDocumentId: string; filename: string | null }>()
);
export const downloadFileSuccess = createAction(
  '[User-Process-Documents] Download Uploaded File In UserProcess Success'
);
export const downloadFileError = createAction('[User-Process-Documents] Download Uploaded File In UserProcess Error');

export const removeUserDoc = createAction(
  '[User-Process-Documents] Remove user document',
  props<{ userProcessId: string; userDocumentId: string }>()
);
export const removeUserDocSuccess = createAction(
  '[User-Process-Documents] Remove user document success',
  props<{ removedUserDocId: string }>()
);
export const removeUserDocError = createAction(
  '[User-Process-Documents] Remove user document error',
  props<{ errorMessage: string }>()
);

export const removeFileOfUserDoc = createAction(
  '[User-Process-Documents] Remove file of user document',
  props<{ userProcessId: string; userDocumentId: string; filename: string }>()
);
export const removeFileOfUserDocSuccess = createAction(
  '[User-Process-Documents] Remove file of user document success',
  props<{ updatedUserDoc: UserDocument }>()
);
export const removeFileOfUserDocError = createAction(
  '[User-Process-Documents] Remove file of user document error',
  props<{ errorMessage: string }>()
);

export const saveAdditionalDocs = createAction(
  '[User-Process-Documents] Save Additional Documents',
  props<{ additionalDocs: SaveUserAdditionalDocuments }>()
);
export const saveAdditionalDocsSuccess = createAction(
  '[User-Process-Documents] Save Additional Documents Success',
  props<{ savedUserDocuments: UserDocument[] }>()
);
export const saveAdditionalDocsError = createAction(
  '[User-Process-Documents] Save Additional Documents Error',
  props<{ errorMessage: string }>()
);

export const sendAppToReview = createAction(
  '[User-Process] Send Application To Review',
  props<{ userProcessId: string }>()
);
export const sendAppToReviewSuccess = createAction(
  '[User-Process] Send Application To Review Success',
  props<{ updatedUserProcess: UserProcess }>()
);
export const sendAppToReviewError = createAction(
  '[User-Process] Send Application To Review Error',
  props<{ errorMessage: string }>()
);

export const getDocumentsForSummary = createAction(
  '[User-Process] Get Documents For Summary',
  props<{ userProcessId: string }>()
);

export const getDocumentsForSummarySuccess = createAction(
  '[User-Process] Get Documents For Summary Success',
  props<{ summaryDocuments: SummaryDocuments }>()
);

export const getDocumentsForSummaryError = createAction(
  '[User-Process] Get Documents For Summary Error',
  props<{ errorMessage: string }>()
);

export const downloadForPrintAll = createAction(
  '[User-Process-Documents] Download All Files for Print All',
  props<{ userProcessId: string }>()
);

export const downloadForPrintAllSuccess = createAction(
  '[User-Process-Documents] Download All Files for Print All Success'
);

export const downloadForPrintAllError = createAction('[User-Process-Documents] Download All Files for Print All Error');

export const downloadInstructions = createAction(
  '[User-Process] Download Instructions',
  props<{ userProcessId: string }>()
);
export const downloadInstructionsSuccess = createAction('[User-Process] Download Instructions Success');
export const downloadInstructionsError = createAction('[User-Process] Download Instructions Error');

export const saveDocumentsSigningMode = createAction(
  '[User-Process] Save My Documents Signing Mode',
  props<{ userProcessId: string; opts: SaveDocumentsSigningModeOpts }>()
);
export const saveDocumentsSigningModeSuccess = createAction(
  '[User-Process] Save My Documents Signing Mode Success',
  props<{ userProcess: UserProcess }>()
);
export const saveDocumentsSigningModeError = createAction(
  '[User-Process] Save My Documents Signing Mode Error',
  props<{ errorMessage: any; userProcessId: string }>()
);

export const saveMyAvailability = createAction(
  '[User-Process] Save my availability',
  props<{ userProcessId: string; availability: AvailabilityForOfficeVisit }>()
);
export const saveMyAvailabilitySuccess = createAction(
  '[User-Process] Save my availability Success',
  props<{ userProcessId: string; newUserProcessStatus: UserProcessStatus; updatedUserProcess: UserProcess }>()
);
export const saveMyAvailabilityError = createAction(
  '[User-Process] Save my availability Error',
  props<{ userProcessId: string; errorMessage: any }>()
);

export const setReadinessForVisit = createAction(
  '[User-Process] Set Readiness For Visit',
  props<{ opts: { userProcessId: string; ready: boolean } }>()
);
export const setReadinessForVisitSuccess = createAction(
  '[User-Process] Set Readiness For Visit Success',
  props<{ userProcess: UserProcess }>()
);
export const setReadinessForVisitError = createAction(
  '[User-Process] Set Readiness For Visit Error',
  props<{ errorMessage: any }>()
);

export const confirmVoivoChange = createAction(
  '[User-Process] Confirm changing voivodeship',
  props<{ userProcessId: string }>()
);
export const confirmVoivoChangeSuccess = createAction(
  '[User-Process] Confirm changing voivodeship Success',
  props<{ userProcessId: string }>()
);
export const confirmVoivoChangeError = createAction(
  '[User-Process] Confirm changing voivodeship Error',
  props<{ userProcessId: string; errorMessage: any }>()
);

export const setAppointmentDateAndPlace = createAction(
  '[User-Process] Set Immigration Office Appointment Date And Place',
  props<{ opts: SetAppointmentDateAndPlaceOpts }>()
);

export const setAppointmentDateAndPlaceSuccess = createAction(
  '[User-Process] Set Immigration Office Appointment Date And Place Success',
  props<{ userProcess: UserProcess }>()
);

export const setAppointmentDateAndPlaceError = createAction(
  '[User-Process] Set Immigration Office Appointment Date And Place Error'
);
