import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './purchase.actions';
import { PurchaseState, initialState } from './purchase.state';

const reducer = createReducer(
  initialState,

  on(actions.getAvailableProducts, state => ({
    ...state,
    loading: true,
    errorMessage: null,
    availableProducts: [],
  })),
  on(actions.getAvailableProductsSuccess, (state, { products }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    availableProducts: products,
  })),
  on(actions.getAvailableProductsError, state => ({
    ...state,
    loading: false,
    errorMessage: 'ERROR_FETCHING_AVAILABLE_PRODUCTS',
  })),

  on(actions.getAvailablePaymentMethods, state => ({
    ...state,
    loadingPaymentMethods: true,
    errorMessage: null,
  })),
  on(actions.getAvailablePaymentMethodsSuccess, (state, { paymentMethods }) => ({
    ...state,
    loadingPaymentMethods: false,
    errorMessage: null,
    availablePaymentMethods: paymentMethods,
  })),
  on(actions.getAvailablePaymentMethodsError, state => ({
    ...state,
    loadingPaymentMethods: false,
    availablePaymentMethods: null,
    errorMessage: 'ERROR_FETCHING_AVAILABLE_PAYMENT_METHODS_FROM_P24',
  })),

  on(actions.getMyPurchase, (state, {}) => ({
    ...state,
    loadingPurchase: true,
    myPurchase: null,
    errorMessage: null,
  })),
  on(actions.validatePurchase, (state, {}) => ({
    ...state,
    loadingPurchase: true,
    // myPurchase: null,
    errorMessage: null,
  })),
  on(
    actions.validatePurchaseSuccess,
    actions.newPurchaseSuccess,
    actions.getMyPurchaseSuccess,
    (state, { purchase }) => ({
      ...state,
      loadingPurchase: false,
      errorMessage: null,
      creatingPurchaseInProgress: false,
      myPurchase: structuredClone(purchase),
    })
  ),
  on(actions.getMyPurchaseError, (state, {}) => ({
    ...state,
    loadingPurchase: false,
    myPurchase: null,
    creatingPurchaseInProgress: false,
    errorMessage: 'GETTING_PURCHASE_ERROR',
  })),
  on(actions.validatePurchaseError, (state, {}) => ({
    ...state,
    loadingPurchase: false,
    // myPurchase: null, -> at validation lets do nothing with purchase. User wont be able to proceed and we dont want to reset his cart completely
    creatingPurchaseInProgress: false,
    errorMessage: 'VALIDATING_PURCHASE_ERROR',
  })),
  on(actions.newPurchase, state => ({
    ...state,
    creatingPurchaseInProgress: true,
  })),
  on(actions.newPurchaseError, (state, {}) => ({
    ...state,
    loadingPurchase: false,
    creatingPurchaseInProgress: false,
    errorMessage: 'ERROR_CREATING_PURCHASE',
  })),
  on(actions.setPaymentStarted, state => ({
    ...state,
    loadingPurchase: true,
    errorMessage: null,
  })),
  on(actions.setPaymentStartedSuccess, (state, { updatedPurchase }) => ({
    ...state,
    loadingPurchase: false,
    myPurchase: { ...state.myPurchase, status: updatedPurchase.status, updatedAt: updatedPurchase.updatedAt },
    errorMessage: null,
  })),
  on(actions.setPaymentStartedError, (state, { errorMessage }) => ({
    ...state,
    loadingPurchase: false,
    errorMessage,
  })),
  on(actions.verifyPayment, state => ({
    ...state,
    loadingPurchase: true,
    errorMessage: null,
  })),
  on(actions.verifyPaymentSuccess, (state, { updatedPurchase }) => ({
    ...state,
    loadingPurchase: false,
    myPurchase: { ...state.myPurchase, ...updatedPurchase },
    errorMessage: null,
  })),
  on(actions.verifyPaymentError, (state, { errorMessage }) => ({
    ...state,
    loadingPurchase: false,
    errorMessage,
  })),
  on(actions.getMyPurchaseList, actions.getPurchaseListOfUser, state => ({
    ...state,
    loadingPurchaseList: true,
    errorMessage: null,
  })),
  on(actions.getMyPurchaseListSuccess, actions.getPurchaseListOfUserSuccess, (state, { purchaseList }) => ({
    ...state,
    loadingPurchaseList: false,
    purchaseList: structuredClone(purchaseList),
    errorMessage: null,
  })),
  on(actions.getMyPurchaseListError, actions.getPurchaseListOfUserError, state => ({
    ...state,
    loadingPurchase: false,
    purchaseList: [],
    errorMessage: 'CANNOT_FETCH_PURCHASE_LIST',
  }))
);

export function purchaseReducer(state: PurchaseState | undefined, action: Action): PurchaseState {
  return reducer(state, action);
}
