export enum QueueRequestType {
  DOCUMENTS_FILL = 'DOCUMENTS_FILL',
  IMAGES_TRANSFORM = 'IMAGES_TRANSFORM',
  MERGING_PDFS = 'MERGING_PDFS',
  SENDING_TO_INPOL = 'SENDING_TO_INPOL',
  SENDING_TO_MOS = 'SENDING_TO_MOS',
}

export enum GenerationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
}

export const enum ExternalSystemName {
  INPOL = 'INPOL',
  MOS = 'MOS',
}

export interface JobGenerationOpts {
  withExternalSystem: boolean;
  externalSystemName: ExternalSystemName;
  withCredentials?: ExternalSystemCredentials;
}

export interface JobGenerationStatus {
  jobType: QueueRequestType;
  status: GenerationStatus;
  startedAt?: string;
  finishedAt?: string;
  error?: any;
}
export interface PdfsGenerationJob {
  userProcessId: string;
  wholeJobCompleted: boolean;
  [QueueRequestType.DOCUMENTS_FILL]?: JobGenerationStatus;
  [QueueRequestType.IMAGES_TRANSFORM]?: JobGenerationStatus;
  [QueueRequestType.MERGING_PDFS]?: JobGenerationStatus;
  [QueueRequestType.SENDING_TO_INPOL]?: JobGenerationStatus;
}

export interface DocumentsGenerationStatus {
  availableStatuses: JobGenerationStatus[];
  allRequiredFinished: boolean;
}

export interface ExternalSystemCredentials {
  email: string;
  password: string;
}

export interface SendToInpolRequestOpts {
  userId: string;
  userProcessId: string;
  withCredentials?: ExternalSystemCredentials;
}

export interface SendToMosRequestOpts {
  userId: string;
  userProcessId: string;
  withCredentials?: ExternalSystemCredentials;
}
