import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from '@state';
import {
  InAppNotification,
  InAppNotificationType,
  MaintenanceInfo,
  MarkAllOfTypeAsReadBulkOpts,
  MarkAllOfTypeAsReadOpts,
  Pagination,
} from '@interfaces';
import * as notificationsActions from './notifications.actions';
import * as notificationsSelectors from './notifications.selectors';
import { filter, Observable, tap, map } from 'rxjs';

@Injectable()
export class InAppNotificationsFacade {
  public loading$ = this.store.select(notificationsSelectors.selectLoading);
  public loadingMore$ = this.store.select(notificationsSelectors.selectLoadingMore);
  public errorMessage$ = this.store.select(notificationsSelectors.selectErrorMessage);

  public shortlist$ = this.store.select(notificationsSelectors.selectShortlist);
  public shortlistPagination$ = this.store.select(notificationsSelectors.selectShortlistPagination);
  public unreadCount$ = this.store.select(notificationsSelectors.selectUnreadCount);

  public markAllOfTypeAsRead$ = this.actions.pipe(ofType(notificationsActions.markAllOfTypeAsRead));
  public markAsReadSuccess$ = this.actions.pipe(ofType(notificationsActions.markAsReadSuccess));
  public markAsReadError$ = this.actions.pipe(ofType(notificationsActions.markAsReadError));
  public markAsUnreadSuccess$ = this.actions.pipe(ofType(notificationsActions.markAsUnreadSuccess));
  public markAsUnreadError$ = this.actions.pipe(ofType(notificationsActions.markAsUnreadError));
  public markAllAsReadSuccess$ = this.actions.pipe(ofType(notificationsActions.markAllAsReadSuccess));
  public markAllOfTypeAsReadSuccess$ = this.actions.pipe(ofType(notificationsActions.markAllOfTypeAsReadSuccess));

  public newNotificationReceived$ = this.actions.pipe(ofType(notificationsActions.newNotificationReceived));

  public maintenanceInfo$ = this.store.select(notificationsSelectors.selectMaintenanceInfo);
  public loadingMaintenance$ = this.store.select(notificationsSelectors.selectLoadingMaintenance);
  public setMaintenanceSuccess$ = this.actions.pipe(ofType(notificationsActions.setMaintenanceSuccess));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getUnreadCount(): void {
    this.store.dispatch(notificationsActions.getUnreadCount());
  }

  public getUnreadCountOfType(notificationType: InAppNotificationType, userId?: string): void {
    this.store.dispatch(notificationsActions.getUnreadCountOfType({ notificationType, relatedForeignerId: userId }));
  }

  public getUnreadCountOfType$(notifType: InAppNotificationType, userId?: string): Observable<number> {
    return this.store.select(notificationsSelectors.selectUnreadCountOfType).pipe(
      tap(({ notificationType, unreadCount, relatedForeignerId }) => {
        if (userId && userId !== relatedForeignerId) {
          this.getUnreadCountOfType(notifType, userId);
        }

        if (!notificationType || notificationType !== notifType || unreadCount === null) {
          this.getUnreadCountOfType(notifType);
        }
      }),
      filter(({ notificationType, unreadCount, relatedForeignerId }) => {
        if (userId && userId !== relatedForeignerId) {
          return false;
        }
        if (!notificationType || notificationType !== notifType || unreadCount === null) {
          return false;
        }
        return true;
      }),
      map(({ unreadCount }) => unreadCount)
    );
  }

  public getShortlist(showOnlyUnread?: boolean): void {
    this.store.dispatch(notificationsActions.getShortlist({ showOnlyUnread: showOnlyUnread || false }));
  }
  public getShortlistMore(pagination: Pagination, showOnlyUnread?: boolean): void {
    this.store.dispatch(
      notificationsActions.getShortlistMore({
        showOnlyUnread: showOnlyUnread || false,
        pagination,
      })
    );
  }

  public addNewNotification(notification: InAppNotification): void {
    this.store.dispatch(notificationsActions.newNotificationReceived({ notification }));
  }

  public markAsRead(notificationsIds: string[]): void {
    this.store.dispatch(notificationsActions.markAsRead({ notificationsIds }));
  }
  public markAsUnRead(notificationsIds: string[]): void {
    this.store.dispatch(notificationsActions.markAsUnread({ notificationsIds }));
  }
  public markAllAsRead(): void {
    this.store.dispatch(notificationsActions.markAllAsRead());
  }

  public markAllOfTypeAsRead(opts: MarkAllOfTypeAsReadOpts): void {
    this.store.dispatch(notificationsActions.markAllOfTypeAsRead({ ...opts }));
  }
  public markAllOfTypeAsReadBulk(opts: MarkAllOfTypeAsReadBulkOpts): void {
    this.store.dispatch(notificationsActions.markAllOfTypeAsReadBulk({ ...opts }));
  }

  public getMaintenanceInfo(): void {
    this.store.dispatch(notificationsActions.getMaintenanceInfo());
  }

  public setMaintenanceInfo(opts: MaintenanceInfo): void {
    this.store.dispatch(notificationsActions.setMaintenanceInfo({ opts }));
  }

  public addReceivedMaintenanceInfo(result: MaintenanceInfo): void {
    this.store.dispatch(notificationsActions.addReceivedMaintenanceInfo({ result }));
  }
}
