import { FileOfDocument, UserAsset } from '@interfaces';

export type UserFilesState = Readonly<{
  loading: boolean;
  // used in list of uploaded scans
  filesOfDocuments: FileOfDocument[];

  // used in list of all of user assets
  userAssets: UserAsset[];

  sort: {
    sortBy: 'name' | 'date';
    sortDir: 'asc' | 'desc';
  };

  downloadingFile: string;
  upload: {
    // file.name -> name on the computer of uploader -> we will make sure that no duplicates are allowed
    [key: string]: {
      error: any;
      loading: boolean;
      uploaded: boolean;
      userAsset: UserAsset;
    };
  };
}>;

export const initialState: UserFilesState = {
  loading: false,
  downloadingFile: null,
  filesOfDocuments: [],
  userAssets: [],
  upload: {},
  sort: { sortBy: 'date', sortDir: 'desc' },
};
