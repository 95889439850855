import { createAction, props } from '@ngrx/store';
import {
  City,
  District,
  Municipality,
  ProcessDocument,
  SearchDocument,
  UpdateDocumentEnabled,
  UpdateDocumentsBulk,
  UpdateVoivodeshipOpts,
  Voivodeship,
  VoivodeshipOffice,
} from '@interfaces';

export const getVoivodeships = createAction('[Voivodeships] Get Available Voivodeships');
export const getVoivodeshipsSuccess = createAction(
  '[Voivodeships] Get Available Voivodeships Success',
  props<{ voivodeships: Voivodeship[] }>()
);
export const getVoivodeshipsError = createAction(
  '[Voivodeships] Get Available Voivodeships Error',
  props<{ errorMessage: any }>()
);

export const getVoivoByZipcode = createAction('[Voivodeships] Get Voivo by zip', props<{ zipcode: string }>());
export const getVoivoByZipcodeSucces = createAction(
  '[Voivodeships] Get Voivo by zip Success',
  props<{ voivodeship: Voivodeship }>()
);
export const getVoivoByZipcodeError = createAction(
  '[Voivodeships] Get Voivo by zip Error',
  props<{ errorMessage: string }>()
);

export const updateVoivodeship = createAction(
  '[Voivodeships] Update voivodeship',
  props<{ voivoId: string; opts: UpdateVoivodeshipOpts }>()
);

export const updateVoivodeshipSuccess = createAction(
  '[Voivodeships] Update voivodeship SUCCESS',
  props<{ updatedVoivodeship: Voivodeship }>()
);

export const updateVoivodeshipError = createAction(
  '[Voivodeships] Update voivodeship ERROR',
  props<{ errorMessage: string }>()
);

export const addVoivodeshipOffice = createAction(
  '[Voivodeships] Add voivodeship office',
  props<{ voivoId: string; opts: VoivodeshipOffice }>()
);
export const addVoivodeshipOfficeSuccess = createAction(
  '[Voivodeships] Add voivodeship office SUCCESS',
  props<{ voivoId: string; addedOffice: VoivodeshipOffice }>()
);
export const addVoivodeshipOfficeError = createAction(
  '[Voivodeships] Add voivodeship office ERROR',
  props<{ voivoId: string; errorMessage: string }>()
);

export const editVoivodeshipOffice = createAction(
  '[Voivodeships] Edit voivodeship office',
  props<{ voivoId: string; opts: VoivodeshipOffice }>()
);
export const editVoivodeshipOfficeSuccess = createAction(
  '[Voivodeships] Edit voivodeship office SUCCESS',
  props<{ voivoId: string; updatedOffice: VoivodeshipOffice }>()
);
export const editVoivodeshipOfficeError = createAction(
  '[Voivodeships] Edit voivodeship office ERROR',
  props<{ voivoId: string; errorMessage: string }>()
);

export const removeVoivodeshipOffice = createAction(
  '[Voivodeships] Remove voivodeship office',
  props<{ voivoId: string; officeId: string }>()
);
export const removeVoivodeshipOfficeSuccess = createAction(
  '[Voivodeships] Remove voivodeship office SUCCESS',
  props<{ voivoId: string; officeId: string }>()
);
export const removeVoivodeshipOfficeError = createAction(
  '[Voivodeships] Remove voivodeship office ERROR',
  props<{ voivoId: string; officeId: string; errorMessage: string }>()
);

export const searchDocuments = createAction('[Voivodeships] Search documents', props<{ opts: SearchDocument }>());
export const searchDocumentsSuccess = createAction(
  '[Voivodeships] Search documents SUCCESS',
  props<{ documents: ProcessDocument[] }>()
);
export const searchDocumentsError = createAction(
  '[Voivodeships] Search documents ERROR',
  props<{ opts: SearchDocument; errorMessage: string }>()
);

export const updateDocEnabled = createAction(
  '[Voivodeships] Update doc enabled',
  props<{ opts: UpdateDocumentEnabled }>()
);
export const updateDocEnabledBulk = createAction(
  '[Voivodeships] Update doc enabled bulk',
  props<{ opts: UpdateDocumentsBulk }>()
);
export const updateDocEnabledSuccess = createAction(
  '[Voivodeships] Update doc enabled SUCCESS',
  props<{ updatedDoc: ProcessDocument }>()
);
export const updateDocEnabledBulkSuccess = createAction(
  '[Voivodeships] Update doc enabled bulk SUCCESSS',
  props<{ updatedDocs: ProcessDocument[] }>()
);
export const updateDocEnabledError = createAction(
  '[Voivodeships] Update doc enabled ERROR',
  props<{ errorMessage: string }>()
);

export const getDistricts = createAction('[Voivodeships] Get Districts', props<{ opts: { voivoId: string } }>());
export const getDistrictsSuccess = createAction(
  '[Voivodeships] Get Districts Success',
  props<{ voivoId: string; districts: District[] }>()
);
export const getMunicips = createAction(
  '[Voivodeships] Get Municips',
  props<{ opts: { voivoId: string; districtId: string } }>()
);
export const getMunicipsSuccess = createAction(
  '[Voivodeships] Get Municips Success',
  props<{ districtId: string; municips: Municipality[] }>()
);
export const getCities = createAction(
  '[Voivodeships] Get Cities',
  props<{ opts: { voivoId: string; districtId: string; municipId: string } }>()
);
export const getCitiesSuccess = createAction(
  '[Voivodeships] Get Cities Success',
  props<{ municipId: string; cities: City[] }>()
);
export const downloadVoivodeshipFileInstruction = createAction(
  '[Voivodeships] Download Voivodeship File Instruction',
  props<{ opts: { voivoKey: string; lang: string } }>()
);
export const downloadVoivodeshipFileInstructionSuccess = createAction(
  '[Voivodeships] Download Voivodeship File Instruction Success'
);
export const downloadVoivodeshipFileInstructionError = createAction(
  '[Voivodeships] Download Voivodeship File Instruction Error'
);

export const uploadVoivodeshipFileInstruction = createAction(
  '[Voivodeships] Upload Voivodeship File Instruction',
  props<{ opts: { voivoKey: string; lang: string; file: File } }>()
);
export const uploadVoivodeshipFileInstructionSuccess = createAction(
  '[Voivodeships] Upload Voivodeship File Instruction Success',
  props<{ availableVoivodeships: Voivodeship[] }>()
);
export const uploadVoivodeshipFileInstructionError = createAction(
  '[Voivodeships] Upload Voivodeship File Instruction Error'
);
