<mat-dialog-content>
  <div class="wrapper">
    <div class="hour-column" [class.focused]="currFocus === 'HH'">
      <div class="arrow-up-row" (click)="stepUp('HH')"><mat-icon>expand_less</mat-icon></div>
      <div class="value-row">
        {{ HH }}
      </div>
      <div class="arrow-down-row" (click)="stepDown('HH')"><mat-icon>expand_more</mat-icon></div>
    </div>

    <div class="separator-column">:</div>

    <div class="minute-column" [class.focused]="currFocus === 'MM'">
      <div class="arrow-up-row" (click)="stepUp('MM')"><mat-icon>expand_less</mat-icon></div>
      <div class="value-row">
        {{ MM }}
      </div>
      <div class="arrow-down-row" (click)="stepDown('MM')"><mat-icon>expand_more</mat-icon></div>
    </div>
  </div>
</mat-dialog-content>
