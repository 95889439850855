import {
  InAppNotification,
  InAppNotificationType,
  MaintenanceInfo,
  MarkAllOfTypeAsReadBulkOpts,
  MarkAllOfTypeAsReadOpts,
  PaginatedResponse,
  Pagination,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const newNotificationReceived = createAction(
  '[Notifications] New notification received',
  props<{ notification: InAppNotification }>()
);

export const getUnreadCount = createAction('[Notifications] Get unread count');

export const getUnreadCountSuccess = createAction(
  '[Notifications] Get unread count Success',
  props<{ unreadCount: number }>()
);
export const getUnreadCountError = createAction(
  '[Notifications] Get unread count Error',
  props<{ errorMessage: string }>()
);

export const getUnreadCountOfType = createAction(
  '[Notifications] Get unread count of type',
  props<{ notificationType: InAppNotificationType; relatedForeignerId?: string }>()
);
export const getUnreadCountOfTypeSuccess = createAction(
  '[Notifications] Get unread count of type Success',
  props<{ unreadCount: number; notificationType: InAppNotificationType; relatedForeignerId?: string }>()
);
export const getUnreadCountOfTypeError = createAction(
  '[Notifications] Get unread count of type Error',
  props<{ errorMessage: string; notificationType: InAppNotificationType; relatedForeignerId?: string }>()
);

export const getShortlist = createAction('[Notifications] Get Shortlist', props<{ showOnlyUnread: boolean }>());
export const getShortlistSuccess = createAction(
  '[Notifications] Get Shortlist Success',
  props<{ shortlist: PaginatedResponse<InAppNotification> }>()
);
export const getShortlistError = createAction('[Notifications] Get Shortlist Error', props<{ errorMessage: string }>());

export const getShortlistMore = createAction(
  '[Notifications] Get Shortlist More',
  props<{ showOnlyUnread: boolean; pagination: Pagination }>()
);
export const getShortlistMoreSuccess = createAction(
  '[Notifications] Get Shortlist More Success',
  props<{ shortlist: PaginatedResponse<InAppNotification> }>()
);
export const getShortlistMoreError = createAction(
  '[Notifications] Get Shortlist More Error',
  props<{ errorMessage: string }>()
);

export const markAsRead = createAction(
  '[Notifications] Mark Notifications As Read',
  props<{ notificationsIds: string[] }>()
);
export const markAsReadSuccess = createAction(
  '[Notifications] Mark Notifications As Read Success',
  props<{ updatedNotifications: InAppNotification[] }>()
);
export const markAsReadError = createAction(
  '[Notifications] Mark Notifications As Read Error',
  props<{ errorMessage: string; notificationsIds: string[] }>()
);

export const markAsUnread = createAction(
  '[Notifications] Mark Notifications As Unread',
  props<{ notificationsIds: string[] }>()
);
export const markAsUnreadSuccess = createAction(
  '[Notifications] Mark Notifications As Unread Success',
  props<{ updatedNotifications: InAppNotification[] }>()
);
export const markAsUnreadError = createAction(
  '[Notifications] Mark Notifications As Unead Error',
  props<{ errorMessage: string; notificationsIds: string[] }>()
);

export const markAllOfTypeAsRead = createAction(
  '[Notifications] Mark All Notifications Of Type As Read',
  props<MarkAllOfTypeAsReadOpts>()
);
export const markAllOfTypeAsReadSuccess = createAction(
  '[Notifications] Mark All Notifications Of Type As Read Success',
  props<{ unreadCount: number }>()
);
export const markAllOfTypeAsReadError = createAction(
  '[Notifications] Mark All Notifications Of Type As Read Error',
  props<{ errorMessage: string }>()
);

export const markAllOfTypeAsReadBulk = createAction(
  '[Notifications] Mark All Notifications Of Type As Read Bulk',
  props<MarkAllOfTypeAsReadBulkOpts>()
);

export const markAllAsRead = createAction('[Notifications] Mark All Notifications As Read');
export const markAllAsReadSuccess = createAction(
  '[Notifications] Mark All Notifications As Read Success',
  props<{ updatedShortlist: PaginatedResponse<InAppNotification> }>()
);
export const markAllAsReadError = createAction(
  '[Notifications] Mark All Notifications As Read Error',
  props<{ errorMessage: string }>()
);

export const setMaintenanceInfo = createAction(
  '[Notifications] Set Maintenance Info',
  props<{ opts: MaintenanceInfo }>()
);
export const setMaintenanceSuccess = createAction(
  '[Notifications] Set Maintenance Info Success',
  props<{ result: MaintenanceInfo }>()
);
export const setMaintenanceError = createAction(
  '[Notifications] Set Maintenance Info Error',
  props<{ errorMessage: string }>()
);

export const getMaintenanceInfo = createAction('[Notifications] Get Maintenance Info');
export const getMaintenanceInfoSuccess = createAction(
  '[Notifications] Get Maintenance Info Success',
  props<{ result: MaintenanceInfo }>()
);
export const getMaintenanceInfoError = createAction(
  '[Notifications] Get Maintenance Info Error',
  props<{ errorMessage: string }>()
);

export const addReceivedMaintenanceInfo = createAction(
  '[Notifications] Add New Maintenance Info Received by WS',
  props<{ result: MaintenanceInfo }>()
);
