import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InAppNotificationsState } from './notifications.state';

export const selectNotificationsState = createFeatureSelector<InAppNotificationsState>('inAppNotifications');

export const selectLoading = createSelector(selectNotificationsState, state => state.loading);

export const selectLoadingMore = createSelector(selectNotificationsState, state => state.loadingMore);

export const selectErrorMessage = createSelector(selectNotificationsState, state => state.errorMessage);

export const selectShortlist = createSelector(selectNotificationsState, state => state.shortlist);

export const selectShortlistPagination = createSelector(selectNotificationsState, state => state.shortlistPagination);

export const selectUnreadCount = createSelector(selectNotificationsState, state => ({
  unreadCount: state.unreadCount,
}));

export const selectUnreadCountOfType = createSelector(selectNotificationsState, state => state.unreadCountOfType);

export const selectMaintenanceInfo = createSelector(selectNotificationsState, state => state.maintenance);
export const selectLoadingMaintenance = createSelector(selectNotificationsState, state => state.loadingMaintenance);
