<mat-dialog-content>
  <div class="close-wrapper">
    <button mat-icon-button aria-label="Close button" tabindex="-1">
      <mat-icon (click)="close()">close</mat-icon>
    </button>
  </div>
  <div class="text-wrapper">
    <p class="text" *ngIf="!data.confirmationTranslated && !data.confirmationToTranslate">
      {{ 'CONFIRMATION_MODAL.ARE-YOU-SURE' | translate }}
    </p>
    <p class="text" *ngIf="data.confirmationTranslated">
      <span [innerHTML]="data.confirmationTranslated | linebreaks"></span>
    </p>
    <p class="text" *ngIf="data.confirmationToTranslate">
      <span innerHTML="{{ data.confirmationToTranslate | translate: data.translateParams }}"></span>
    </p>
  </div>

  <div class="additional-message-wrapper" *ngIf="data.additionalMessage">
    <mat-form-field appearance="legacy">
      <!-- <mat-label>{{ 'APPLICATION.COMMENTS' | translate }}</mat-label> -->
      <input matInput [(ngModel)]="additionalMessage" />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="action-buttons">
    <button mat-raised-button class="cancel-button" (click)="close()">
      {{ (data.noButtonKey ? data.noButtonKey : 'COMMON.CANCEL') | translate }}
    </button>
    <button
      mat-raised-button
      class="confirm-button"
      color="primary"
      [disabled]="data.additionalMessage && !additionalMessage.length"
      (click)="save()">
      {{ (data.yesButtonKey ? data.yesButtonKey : 'COMMON.YES') | translate }}
    </button>
  </div>
</mat-dialog-actions>
