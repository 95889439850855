import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserProcessState } from './user-process.state';

export const selectUserProcessState = createFeatureSelector<UserProcessState>('userProcess');

export const selectMyProcesses = createSelector(selectUserProcessState, state => state.myProcesses);

export const selectMyProcess = createSelector(selectUserProcessState, state => state.myProcess);

export const selectProcessDocuments = createSelector(selectUserProcessState, state => state.processDocuments);

export const selectLoading = createSelector(selectUserProcessState, state => state.loading);

export const selectErrorMessage = createSelector(selectUserProcessState, state => state.errorMessage);

export const selectSummaryDocuments = createSelector(selectUserProcessState, state => state.summaryDocuments);

export const selectDownloadingUserDocumentFile = createSelector(
  selectUserProcessState,
  state => state.downloadingUserDocumentFile
);
