import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import {
  AddFileToMyDocOpts,
  AddNewUserProcessOpts,
  AnswerToMyDocQuestionOpts,
  AvailabilityForOfficeVisit,
  PersonalDetails,
  PreSurvey,
  ProcessDocumentsList,
  SaveDocumentsSigningModeOpts,
  SaveUserDocumentPayload,
  SetAppointmentDateAndPlaceOpts,
  SummaryDocuments,
  UpdatePreSurveyOpts,
  UserDocument,
  UserProcess,
  UserProcessStatus,
  ValidatePersonalDetailsResponse,
} from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserProcessHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getMyProcesses(): Observable<UserProcess[]> {
    const url = `${this.API_URL}/user-processes/my-processes`;
    return this.http.get<UserProcess[]>(url);
  }

  public getMyProcessDetails(userProcessId: string): Observable<UserProcess> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}`;
    return this.http.get<UserProcess>(url);
  }

  public addNewUserProcess(addNewUserProcessOpts: AddNewUserProcessOpts): Observable<UserProcess> {
    const url = `${this.API_URL}/user-processes/my-processes/new`;
    return this.http.post<UserProcess>(url, { ...addNewUserProcessOpts });
  }

  public pickProcess(userProcessId: string, processId: string): Observable<UserProcess> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/pick-process`;
    const body = { processId };
    return this.http.post<UserProcess>(url, body);
  }

  public getMyPreSurvey(preSurveyId: string): Observable<PreSurvey> {
    const url = `${this.API_URL}/my-pre-survey/${preSurveyId}`;
    return this.http.get<PreSurvey>(url);
  }

  public updatePreSurvey(opts: UpdatePreSurveyOpts): Observable<PreSurvey> {
    const { preSurveyId, userId, userProcessId, ...preSurvey } = opts;
    const url = `${this.API_URL}/my-pre-survey/${preSurveyId}`;
    const body = { userId, userProcessId, ...preSurvey };
    return this.http.patch<PreSurvey>(url, body);
  }

  public sendPreSurveyForVerification(opts: UpdatePreSurveyOpts): Observable<UserProcess> {
    const { preSurveyId, userId, userProcessId, ...preSurvey } = opts;
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/set-pre-survey-ready-for-verification`;
    const body = { userId, userProcessId, ...preSurvey };
    return this.http.patch<UserProcess>(url, body);
  }

  public updatePersonalDetails(userProcessId: string, personalDetails: PersonalDetails): Observable<UserProcess> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/update-personal-details`;
    return this.http.patch<UserProcess>(url, personalDetails);
  }

  public confirmDetails(userProcessId: string): Observable<ValidatePersonalDetailsResponse> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/confirm-personal-details`;
    return this.http.get<ValidatePersonalDetailsResponse>(url);
  }

  // Confirmation of finishing personal-details and willingles to start cmpleting documents
  public startCompletingDocuments(userProcessId: string): Observable<UserProcess> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/start-completing-documents`;
    return this.http.get<UserProcess>(url);
  }

  // throws 400 NO_ACTIVE_PROCESS_FOR_USER when user dosnt have process with state !== ARCHIVED
  public getDocumentsList(userProcessId: string): Observable<ProcessDocumentsList> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/documents-list`;
    return this.http.get<ProcessDocumentsList>(url);
  }

  // Confirmation of finishing completing documents and sending whole application for review
  public sendApplicationToReview(userProcessId: string): Observable<UserProcess> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/ready-for-review`;
    return this.http.get<UserProcess>(url);
  }

  public confirmVoivoChange(userProcessId: string): Observable<boolean> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/confirm-voivo-change`;
    return this.http.get<boolean>(url);
  }

  // throws 400 NO_ACTIVE_PROCESS_FOR_USER when user dosnt have process with state !== ARCHIVED
  public getDocumentsForSummary(userProcessId: string): Observable<SummaryDocuments> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/summary-documents`;
    return this.http.get<SummaryDocuments>(url);
  }

  public saveDocumentsSigningMode(userProcessId: string, opts: SaveDocumentsSigningModeOpts): Observable<UserProcess> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/documents-signing-mode`;
    const body = { ...opts };
    return this.http.post<UserProcess>(url, body);
  }

  public saveMyAvailability(
    userProcessId: string,
    availability: AvailabilityForOfficeVisit
  ): Observable<{
    updatedUserProcess: UserProcess;
    newUserProcessStatus: UserProcessStatus;
  }> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/save-my-availability`;
    const body = { availabilityForOfficeVisit: availability };
    return this.http.post<{
      updatedUserProcess: UserProcess;
      newUserProcessStatus: UserProcessStatus;
    }>(url, body);
  }

  public setReadinessForVisit(opts: { userProcessId: string; ready: boolean }): Observable<UserProcess> {
    const url = `${this.API_URL}/user-processes/my-processes/${opts.userProcessId}/set-office-visit-readiness`;
    const body = { ready: opts.ready };
    return this.http.post<UserProcess>(url, body);
  }

  // ---------------------- DOCUMENTS MODULE

  public addDocument(saveUserDocumentPayload: SaveUserDocumentPayload): Observable<UserDocument> {
    const url = `${this.API_URL}/user-documents`;
    const { documentId, willNotUploadDocument, userProcessId } = saveUserDocumentPayload;
    return this.http.post<UserDocument>(url, { documentId, willNotUploadDocument, userProcessId });
  }

  public downloadFile(userProcessId: string, userDocumentId: string, filename: string | null): Observable<any> {
    let url = `${this.API_URL}/user-documents/${userProcessId}/${userDocumentId}/download`;
    if (filename) {
      url += `/${encodeURIComponent(filename)}`;
    }
    return this.http.get(url, { responseType: 'blob' });
  }

  public addFilesToMyDocument(opts: AddFileToMyDocOpts): Observable<UserDocument> {
    const { userProcessId, userDocumentId } = opts;
    const url = `${this.API_URL}/user-documents/${userProcessId}/${userDocumentId}/files`;
    const data = this.getAddFilesFormData(opts);
    return this.http.put<UserDocument>(url, data);
  }

  public answerToMyDocQuestion(opts: AnswerToMyDocQuestionOpts): Observable<UserDocument> {
    const { userProcessId, userDocumentId, questionAnswer } = opts;
    const url = `${this.API_URL}/user-documents/${userProcessId}/${userDocumentId}/answer`;
    return this.http.put<UserDocument>(url, questionAnswer);
  }

  // throws 404 USER_DOC_WITH_ID_NOT_EXISTS when userDocId does not exist in DB
  // throws 400 FILE_FIELDNAME_NOT_EQUAL_DOCUMENT_ID when file fieldname wrongly named
  public updateDocument(
    userDocumentId: string,
    saveUserDocumentPayload: SaveUserDocumentPayload
  ): Observable<UserDocument> {
    const { userProcessId } = saveUserDocumentPayload;
    const url = `${this.API_URL}/user-documents/${userProcessId}/${userDocumentId}`;
    const data = this.createDocumentFormData(saveUserDocumentPayload);
    return this.http.patch<UserDocument>(url, data);
  }

  // throws 404 USER_DOC_WITH_ID_NOT_EXISTS when userDocId does not exist in DB
  public removeFileOfUserDoc(
    userProcessId: string,
    userDocumentId: string,
    filename: string
  ): Observable<{ updatedUserDoc: UserDocument }> {
    const url = `${this.API_URL}/user-documents/${userProcessId}/${userDocumentId}/file/${encodeURIComponent(
      filename
    )}`;
    return this.http.delete<{ updatedUserDoc: UserDocument }>(url);
  }

  // throws 404 USER_DOC_WITH_ID_NOT_EXISTS when userDocId does not exist in DB
  public removeDocument(userProcessId: string, userDocumentId: string): Observable<void> {
    const url = `${this.API_URL}/user-documents/${userProcessId}/${userDocumentId}`;
    return this.http.delete<void>(url);
  }

  public downloadForPrintAll(userProcessId: string): Observable<any> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/print-merged-documents`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public downloadInstructions(userProcessId: string): Observable<any> {
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/print-instructions`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public setAppointmentDateAndPlace(opts: SetAppointmentDateAndPlaceOpts): Observable<UserProcess> {
    const { userProcessId, ...body } = opts;
    const url = `${this.API_URL}/user-processes/my-processes/${userProcessId}/appointment-date-and-place`;

    return this.http.patch<UserProcess>(url, body);
  }

  private createDocumentFormData(saveUserDocumentPayload: SaveUserDocumentPayload): FormData {
    const { documentId, answers, comments, files, willNotUploadDocument } = saveUserDocumentPayload;
    const data = new FormData();
    data.set('documentId', documentId);
    if (files?.length) {
      files.forEach((file, fileIndex) => {
        if (file?.size) {
          data.append(`${documentId}__file-${fileIndex}`, file, file.name);
          // data.set(`${documentId}`, file, file.name);
        }
      });
    }
    if (comments !== undefined && comments !== null) {
      data.set('comments', comments);
    }
    if (answers?.length) {
      answers.forEach((answer, index) => {
        data.append(`answers[${index}][documentQuestionId]`, answer.documentQuestionId);
        data.append(`answers[${index}][answer]`, answer.answer);
      });
    }
    data.set('willNotUploadDocument', willNotUploadDocument as any);
    return data;
  }

  private getAddFilesFormData(opts: AddFileToMyDocOpts): FormData {
    const data = new FormData();
    const { userDocumentId, files } = opts;
    if (!files?.length) {
      console.error('FILES_EMPTY');
      return data;
    }

    data.set('userDocumentId', userDocumentId);
    files.forEach((file, fileIndex) => {
      if (file?.size) {
        data.append(`${userDocumentId}__file-${fileIndex}`, file, file.name);
      }
    });
    return data;
  }
}
