import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalOpts, ModalResultOpts } from './credentials-modal.service';

@Component({
  selector: 'app-credentials-modal',
  templateUrl: './credentials-modal.component.html',
  styleUrls: ['./credentials-modal.component.scss'],
})
export class CredentialsModalComponent implements OnInit {
  public additionalMessage = '';

  public sys: 'MOS' | 'INPOL';

  public email: string;
  public password: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOpts,
    public dialogRef: MatDialogRef<CredentialsModalComponent, ModalResultOpts>
  ) {}

  ngOnInit(): void {
    this.sys = this.data.externalSystemName;
  }

  public close(): void {
    this.dialogRef.close({});
  }

  public submitCredentials(): void {
    if (!this.email || !this.password) {
      return;
    }
    this.dialogRef.close({ credentials: { email: this.email.trim(), password: this.password.trim() } });
  }
}
