<div class="pill-switch-options-container">
  <div
    *ngFor="let option of options"
    class="pill-switch-option"
    [class.selected]="selected === option.value"
    [class.disabled]="disabled"
    (click)="disabled ? null : pillClicked(option)">
    <span class="pill-switch-option-label">{{ option.label | translate }}</span>
  </div>
</div>
