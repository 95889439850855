export class InvoiceData {
  isCompany: boolean;
  name: string;
  surname: string;
  streetName: string;
  houseNumber: string;
  apartmentNumber: string;
  zipcode: string;
  city: string;
  euVatNr: string;
}
