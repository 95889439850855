<div class="visits-wrapper">
  <div class="visit-row-wrapper" *ngFor="let formGroup of previousVisitsArray; index as formGroupIndex">
    <div class="visit-row" [formGroup]="formGroup">
      <div class="left-column">
        <div class="visit-row">
          <div class="standard-form-field required-field">
            <label [class.invalid]="formGroup.get('entryDate').touched && formGroup.get('entryDate').invalid">
              {{ 'PERSONAL_DATA_FORM.POLAND-VISIT-ENTRY-DATE-LONG-RESI.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                [required]="true"
                id="polandVisit{{ formGroupIndex }}entryDate"
                placeholder="DD.MM.YYYY"
                formControlName="entryDate"
                autocomplete="off" />
              <mat-icon
                #entryDate="matTooltip"
                (click)="$event.preventDefault(); $event.stopPropagation(); entryDate.toggle()"
                class="info-suffix-icon"
                matSuffix
                matTooltip="{{ 'PERSONAL_DATA_FORM.POLAND-VISIT-ENTRY-DATE-LONG-RESI.FORMAT-HINT' | translate }}"
                matTooltipClass="personalDataFormTooltip"
                svgIcon="info-outline">
              </mat-icon>
              <div *ngIf="formGroup.get('entryDate').touched && formGroup.get('entryDate').invalid" class="mat-error">
                {{ formGroup.get('entryDate').errors | errorsFormatingAndTranslate }}
              </div>
            </mat-form-field>
          </div>
          <div class="standard-form-field required-field">
            <label [class.invalid]="formGroup.get('leaveDate').touched && formGroup.get('leaveDate').invalid">
              {{ 'PERSONAL_DATA_FORM.POLAND-VISIT-LEAVE-DATE-LONG-RESI.MAIN-LABEL' | translate }} *
            </label>
            <mat-form-field appearance="outline">
              <input
                *ngIf="formGroup.get('leaveDate').value !== 'OBECNIE'"
                matInput
                [required]="true"
                id="polandVisit{{ formGroupIndex }}leaveDate"
                formControlName="leaveDate"
                placeholder="DD.MM.YYYY"
                autocomplete="off" />

              <input
                *ngIf="formGroup.get('leaveDate').value === 'OBECNIE'"
                matInput
                [required]="false"
                [disabled]="true"
                autocomplete="off" />

              <mat-icon
                *ngIf="currentlyIndex !== formGroupIndex"
                #leaveDate="matTooltip"
                (click)="$event.preventDefault(); $event.stopPropagation(); leaveDate.toggle()"
                class="info-suffix-icon"
                matSuffix
                matTooltip="{{ 'PERSONAL_DATA_FORM.POLAND-VISIT-LEAVE-DATE-LONG-RESI.FORMAT-HINT' | translate }}"
                matTooltipClass="personalDataFormTooltip"
                svgIcon="info-outline">
              </mat-icon>
              <div *ngIf="formGroup.get('leaveDate').touched && formGroup.get('leaveDate').invalid" class="mat-error">
                {{ formGroup.get('leaveDate').errors | errorsFormatingAndTranslate }}
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="visit-row">
          <div class="standard-form-field widened-field legalBasis-field required-field">
            <label>{{ 'NT2.LEGAL_BASIS' | translate }} *</label>
            <mat-form-field appearance="outline">
              <mat-select
                [panelClass]="['standard-select', 'legal-basis-select']"
                placeholder="{{ 'NT2.SELECT_BASIS' | translate }}"
                formControlName="legalBasis">
                <mat-option *ngFor="let item of legalBasisOfStayList" [value]="item.value">
                  {{ 'SELECTS_VALUES.STAY_LEGAL_BASIS_NEW.' + item.labelKey | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="visit-actions" *ngIf="previousVisitsArray.length > 1">
          <div class="currently-checkbox-wrapper" *ngIf="currentlyLivingInPolandFc.value !== false">
            <label class="checkbox-label">
              {{ 'PERSONAL_DATA_FORM.POLAND-VISIT-LEAVE-DATE.CURRENTLY' | translate }}
            </label>
            <mat-checkbox
              class="currently-checkbox"
              id="currentlyCheckbox{{ formGroupIndex }}"
              matTooltip="{{ 'PERSONAL_DATA_FORM.POLAND-VISIT-LEAVE-DATE.CURRENTLY-HINT' | translate }}"
              matTooltipClass="personalDataFormTooltip"
              [matTooltipDisabled]="currentlyIndex === formGroupIndex"
              [checked]="formGroup.get('leaveDate').value === 'OBECNIE'"
              (change)="currentlyChanged($event, formGroupIndex)">
            </mat-checkbox>
          </div>
          <button
            mat-icon-button
            id="removeVisit{{ formGroupIndex }}Button"
            [disabled]="showLoading"
            (click)="removeVisit(formGroupIndex)"
            *ngIf="formGroup.get('leaveDate').value !== 'OBECNIE'">
            <mat-icon [class]="showLoading ? 'trash-disabled' : 'trash'" svgIcon="trash"></mat-icon>
          </button>
        </div>
        <div class="visit-actions" *ngIf="previousVisitsArray.length === 1">
          <div class="currently-checkbox-wrapper" *ngIf="currentlyLivingInPolandFc.value !== false">
            <label class="checkbox-label">
              {{ 'PERSONAL_DATA_FORM.POLAND-VISIT-LEAVE-DATE.CURRENTLY' | translate }}
            </label>
            <mat-checkbox
              class="currently-checkbox"
              id="currentlyCheckbox{{ formGroupIndex }}"
              matTooltip="{{ 'PERSONAL_DATA_FORM.POLAND-VISIT-LEAVE-DATE.CURRENTLY-HINT' | translate }}"
              matTooltipClass="personalDataFormTooltip"
              [matTooltipDisabled]="currentlyIndex === formGroupIndex"
              [checked]="formGroup.get('leaveDate').value === 'OBECNIE'"
              (change)="currentlyChanged($event, formGroupIndex)">
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="action-buttons">
    <button
      [disabled]="showLoading || allVisitsCorrect() === false"
      id="addNewVisitInPolandButton"
      (click)="addNewVisit()"
      class="standard-form-button add-visit-button">
      <mat-icon>add</mat-icon>
      {{ 'COUNTRIES_VISITS_FORM.ADD-NEW-VISIT' | translate }}
    </button>

    <div class="loading-wrapper" *ngIf="showLoading">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="notification-wrapper saved-notification" *ngIf="showSaved">
      <mat-icon svgIcon="check-outline"></mat-icon>
      <span id="personalDetailsPreviousVisitsSavingDone" class="text"
        >{{ 'COUNTRIES_VISITS_FORM.STAYS-SAVED-SUCCESFULLY' | translate }}
      </span>
    </div>
    <div class="notification-wrapper awaiting-notification" *ngIf="!showSaved && !showLoading">
      <mat-icon svgIcon="info-outline"></mat-icon>
      <span id="personalDetailsPreviousVisitsWaiting" class="text"
        >{{ 'COUNTRIES_VISITS_FORM.AWAITING-FOR-ALL-REQUIRED-DATA' | translate }}
      </span>
    </div>
  </div>
</div>
