import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import {
  CreateInstructionItemOpts,
  CustomInstructionItem,
  InstructionItem,
  InstructionItemInProcess,
  SearchItemsInProcessesOpts,
  ToggleItemsInProcessOpts,
  UpdateInstructionItemOpts,
  UpdateItemInProcessOpts,
  UpdateItemsOrderOpts,
} from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class InstructionItemHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getAllItems(): Observable<InstructionItem[]> {
    const url = `${this.API_URL}/instruction-items`;
    return this.http.get<InstructionItem[]>(url);
  }

  public getItem(id: string): Observable<InstructionItem> {
    const url = `${this.API_URL}/instruction-items/${id}`;
    return this.http.get<InstructionItem>(url);
  }

  public createInstructionItem(opts: CreateInstructionItemOpts): Observable<InstructionItem> {
    const url = `${this.API_URL}/instruction-items`;
    return this.http.post<InstructionItem>(url, opts);
  }

  public updateInstructionItemName(opts: UpdateInstructionItemOpts): Observable<InstructionItem> {
    const { id, ...body } = opts;
    const url = `${this.API_URL}/instruction-items/${id}`;
    return this.http.patch<InstructionItem>(url, body);
  }

  public removeInstructionItem(id: string): Observable<void> {
    const url = `${this.API_URL}/instruction-items/${id}`;
    return this.http.delete<void>(url);
  }

  public toggleItemsInProcess(opts: ToggleItemsInProcessOpts): Observable<InstructionItemInProcess[]> {
    const url = `${this.API_URL}/instruction-items/toggle-items-in-process`;
    return this.http.post<InstructionItemInProcess[]>(url, opts);
  }

  public updateItemInProcess(opts: UpdateItemInProcessOpts): Observable<InstructionItemInProcess> {
    const { id, ...body } = opts;
    const url = `${this.API_URL}/instruction-items/item-in-process/${id}`;
    return this.http.patch<InstructionItemInProcess>(url, body);
  }

  public updateItemsOrder(opts: UpdateItemsOrderOpts[]): Observable<InstructionItemInProcess[]> {
    const url = `${this.API_URL}/instruction-items/update-items-order`;
    return this.http.post<InstructionItemInProcess[]>(url, opts);
  }

  public searchItemsInProcesses(opts: SearchItemsInProcessesOpts): Observable<InstructionItemInProcess[]> {
    const url = `${this.API_URL}/instruction-items/search-items-in-process`;
    return this.http.post<InstructionItemInProcess[]>(url, opts);
  }

  public getItemsListForCustomization(
    userProcessId: string
  ): Observable<CustomInstructionItem[] | InstructionItemInProcess[]> {
    const url = `${this.API_URL}/custom-instruction-items/${userProcessId}`;
    return this.http.get<CustomInstructionItem[] | InstructionItemInProcess[]>(url);
  }

  public setUseCustomList(
    userProcessId: string,
    useCustomList: boolean
  ): Observable<CustomInstructionItem[] | InstructionItemInProcess[]> {
    const url = `${this.API_URL}/custom-instruction-items/${userProcessId}/use-custom-items-list`;
    const body = { useCustomList };
    return this.http.patch<CustomInstructionItem[] | InstructionItemInProcess[]>(url, body);
  }

  public resetItemsInCustomItemsList(
    userProcessId: string
  ): Observable<CustomInstructionItem[] | InstructionItemInProcess[]> {
    const url = `${this.API_URL}/custom-instruction-items/${userProcessId}/reset-items-custom-list`;
    return this.http.post<CustomInstructionItem[] | InstructionItemInProcess[]>(url, {});
  }

  public setEnabledItemInCustomList(opts: {
    userProcessId: string;
    itemId: string;
    enabled: boolean;
  }): Observable<CustomInstructionItem> {
    const { userProcessId, itemId, enabled } = opts;
    const url = `${this.API_URL}/custom-instruction-items/${userProcessId}/set-enabled-item-in-custom-list/${itemId}`;
    const body = { enabled };
    return this.http.patch<CustomInstructionItem>(url, body);
  }

  public setEnabledFees(opts: {
    userProcessId: string;
    enabled: boolean;
  }): Observable<{ showFeesOnInstructionItemsList: boolean }> {
    const { userProcessId, enabled } = opts;
    const url = `${this.API_URL}/custom-instruction-items/${userProcessId}/set-enabled-fees-in-user-process`;
    const body = { enabled };
    return this.http.patch<{ showFeesOnInstructionItemsList: boolean }>(url, body);
  }
}
