import { ShepherdService } from 'angular-shepherd';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { getStepsByGuideName } from '@guide';
import { AllDeviceInfo, GUIDE_NAME, GuideFinishedOpts, InfoBarState, PROCESS_CATEGORY } from '@interfaces';
import Step from 'node_modules/shepherd.js/src/types/step';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public showProgressBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public progressPercentage$: BehaviorSubject<number> = new BehaviorSubject(0);
  public showBottomFixedBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showOpenChatButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public infoBarState$: BehaviorSubject<InfoBarState> = new BehaviorSubject<InfoBarState>({
    show: false,
    type: 'info',
  });
  public scrollContentTo$: Subject<ScrollToOptions> = new Subject();
  public scrollIntoView$: Subject<{ htmlElemID: string }> = new Subject();

  public deviceInfo: AllDeviceInfo;

  constructor(private readonly shepherdService: ShepherdService) {}

  public openInfoBar(opts: Omit<InfoBarState, 'show'>): void {
    // if no type provided assume info
    // if user mistakenly provided show:false -> override
    this.infoBarState$.next({ type: 'info', ...opts, show: true });
  }

  public closeInfoBar(): void {
    this.infoBarState$.next({ show: false });
  }

  public updateInfoBar(opts: Partial<InfoBarState>): void {
    const currState = this.infoBarState$.getValue();
    const state = { ...currState, ...opts };
    this.infoBarState$.next(state);
  }

  public setupGuideDefaultOpts(defaultStepOptions?: Step.StepOptions): void {
    const def: Step.StepOptions = {
      classes: 'standard-guide-step',
      scrollTo: false,
      cancelIcon: { enabled: true },
      canClickTarget: false,
    };

    this.shepherdService.defaultStepOptions = defaultStepOptions ? { ...defaultStepOptions } : { ...def };
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
  }

  public startGuide(
    guideName: GUIDE_NAME,
    tService: TranslateService,
    processCategory?: PROCESS_CATEGORY
  ): Observable<GuideFinishedOpts> {
    return new Observable<GuideFinishedOpts>(subscriber => {
      if (processCategory && processCategory in getStepsByGuideName[guideName]) {
        this.shepherdService.addSteps(getStepsByGuideName[guideName][processCategory](tService, tService.currentLang));
      } else {
        this.shepherdService.addSteps(getStepsByGuideName[guideName]['default'](tService, tService.currentLang));
      }

      this.shepherdService.tourObject.once('cancel', () => {
        subscriber.next({ guideName, cancelled: true, completed: false });
        this.shepherdService.tourObject.off('complete');
      });

      this.shepherdService.tourObject.once('complete', () => {
        subscriber.next({ guideName, cancelled: false, completed: true });
        this.shepherdService.tourObject.off('cancel');
      });

      this.shepherdService.start();

      setTimeout(() => {
        const overlay = document.getElementsByClassName('shepherd-modal-overlay-container')?.item(0);
        if (!overlay) {
          console.warn('Overlay not accessible and tour started');
          return;
        }

        overlay.addEventListener('click', () => {
          this.shepherdService.cancel();
          overlay.removeAllListeners('click');
        });
      }, 10);
    });
  }
}
