import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { defaultLanguages, patterns } from '@constants';
import { LocalStorageService } from '@core/local-storage.service';
import { AllDeviceInfo, Language } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { CommonsFacade } from '@state/commons';
import { RouterFacade } from '@state/router';
import { UserAuthFacade } from '@state/user-auth';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

const passwordsValidator = (formControl: AbstractControl): ValidationErrors | null => {
  const psswd = formControl.get('password')?.value;
  const confirmed = formControl.get('passwordConfirmation')?.value;
  return psswd === confirmed ? null : { noMatch: true };
};

const passwordConfirmationValidator = (formControl: AbstractControl): ValidationErrors | null => {
  const psswdFc = formControl.get('password');
  const confirmFc = formControl.get('passwordConfirmation');
  const psswd = psswdFc?.value;
  const confirmed = confirmFc?.value;
  const passwordsMatch = psswd === confirmed;

  if (!psswd?.length || !confirmed?.length) {
    return null;
  }

  if (!passwordsMatch) {
    confirmFc.markAsDirty();
    confirmFc.setErrors({ noMatch: true });
  }

  return passwordsMatch ? null : { noMatch: true };
};

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('errorMessageContainer') errorMessageContainer: ElementRef;

  public loading$ = this.userAuthFacade.loading$;
  public activateSuccess$ = this.userAuthFacade.activateAccountSuccess$;
  public errorMessage$ = this.userAuthFacade.errorMessage$;
  public currentUserLang: Language;

  public isPasswordVisible = false;
  public isPasswordConfirmVisible = false;

  public APIAvailable = true;
  public deviceInfo: AllDeviceInfo;

  public pinRequired = false;
  public form: FormGroup<any> = this.fb.group(
    {
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100),
          Validators.pattern(patterns.HasUpperCaseLetter),
          Validators.pattern(patterns.HasSpecialCharacter),
        ]),
      ],
      passwordConfirmation: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100),
          Validators.pattern(patterns.HasUpperCaseLetter),
          Validators.pattern(patterns.HasSpecialCharacter),
        ],
        updateOn: 'change',
      }),
      activation_token: ['', Validators.compose([Validators.required])],
    },
    { validators: [passwordConfirmationValidator] }
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly snackService: SnackbarService,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly router: RouterFacade,
    private readonly commonsFacade: CommonsFacade,
    private readonly ls: LocalStorageService,
    private readonly translateService: TranslateService,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    const token = this.route.snapshot.queryParamMap.get('token');
    if (!token || token.length < 20) {
      this.snackService.showError('INVALID_ACTIVATION_TOKEN');
      this.form.disable();
      return;
    }

    const pinRequired = this.route.snapshot.queryParamMap.get('pinRequired');
    if (pinRequired) {
      this.pinRequired = true;
      this.form.addControl(
        'pin',
        new FormControl('', {
          validators: [Validators.required, Validators.minLength(4), Validators.maxLength(100)],
        })
      );
    }

    this.form.patchValue({
      activation_token: token,
    });

    this.commonsFacade.lastAPIPingSuccessfull$.pipe(takeUntil(this.destroy$)).subscribe(APIPingSuccess => {
      if (!APIPingSuccess) {
        this.setupUserLang([...defaultLanguages]);
        this.APIAvailable = false;
        return;
      }

      this.APIAvailable = true;
    });

    this.commonsFacade
      .getLanguages$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(availableLanguages => {
        this.setupUserLang([...availableLanguages]);
      });
  }

  ngOnDestroy(): void {
    this.userAuthFacade.resetErrorMessage();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public submitForm(): void {
    if (this.form.invalid) {
      return;
    }

    const psswdsValid = passwordsValidator(this.form);
    if (psswdsValid !== null) {
      this.form.setErrors(psswdsValid);
      return;
    }

    this.activateSuccess$.pipe(take(1)).subscribe(() => {
      this.snackService.showInfo('ACTIVATE_ACCOUNT_PAGE.ACCOUNT_ACTIVATED_YOU_CAN_NOW_LOGIN_USING_YOUR_PASSWORD');
      this.router.changeRoute({ linkParams: ['login'] });
    });

    this.errorMessage$.pipe(take(1)).subscribe(errorMessage => {
      if (!errorMessage) {
        return;
      }
      setTimeout(
        () => (this.errorMessageContainer?.nativeElement as HTMLElement)?.scrollIntoView({ behavior: 'smooth' }),
        200
      );
    });

    this.userAuthFacade.activateAccount(this.form.value);
    return;
  }

  private setupUserLang(langs: Language[]): void {
    const langKey = this.route.snapshot.queryParamMap.get('langKey');

    // lang from query params is most important
    const langInQueryParams = langs.find(({ key }) => key === langKey);

    // if no lang in query params then localStorage
    const langInLS = this.ls.getUserLang();

    // if no lang in Query & no in LS use default
    const defaultLang = langs.find(({ key }) => key === 'en');

    // 1. langInQuery 2. langInLS 3. defaultLang
    const langToUse = langInQueryParams?.key ? langInQueryParams : langInLS?.key ? langInLS : defaultLang;

    this.ls.setUserLang(langToUse);
    this.translateService.use(langToUse.key);
    this.currentUserLang = langToUse;
  }
}
